import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { GameSingletons } from "@game/app/GameSingletons";
import { GlowFilterService } from "@game/ui/fx/GlowFilterService";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";

export class ForgeTrainSlices extends Container {
  loco?: Sprite;
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly glwoFilter: GlowFilterService = new GlowFilterService();

  constructor() {
    super();
  }

  /**
   * Adds the grey train background underneath the slices.
   */
  addTrainBackground(): void {
    //// Locomotive
    this.loco = this.factory.createSprite("ui-market-window-forge/train-gray.png");
    this.loco.anchor.x = 0.5;
    this.loco.scale.set(1.5);
    this.loco.position.set(932, 400);
    this.addChild(this.loco);
  }

  /**
   * Sets all slices at the provided indices to be shown, and all the rest the hidden.
   */
  setHighlightedSlices(sliceIndices: number[]): void {
    if (this.loco)
      for (let sliceIndex of sliceIndices) {
        const slice = this.factory.createSprite(
          "ui-market-window-forge/blueprint-slices/slice-" + sliceIndex.toString() + ".png"
        );
        slice.scale.set(0.68);
        slice.tint = 0x1dd4e4;
        slice.position.set(-342, 0);
        slice.alpha = 0.7;
        this.loco.addChild(slice);
      }
  }

  /**
   * Adds or removes the glow around the grey train background.
   */
  setGlowEnabled(enabled: boolean): void {
    if (enabled && this.loco) this.glwoFilter.addFilter(this.loco);
    else {
      this.glwoFilter.clear();
    }
  }
}
