import { globalTime } from "@game/asorted/global.time";
import { Renderer } from "@pixi/core";
import { Container } from "@pixi/display";
import { createShowHideService } from "@sdk-pixi/animation/createShowHideService";
import { createObservableFunction } from "@sdk/pixi/enchant/core/ObservableFunction";

export class WorldLayer extends Container {
  public readonly onEnterFrame = createObservableFunction.call(this);

  public readonly showHideService = createShowHideService({
    initialVisibility: 0.0,
    onUpdate: alpha => {
      this.alpha = alpha;
    },
  });

  public get shouldShow(): boolean {
    return this.showHideService.shouldShow;
  }
  public set shouldShow(value: boolean) {
    this.showHideService.shouldShow = value;
  }

  render(renderer: Renderer) {
    this.showHideService.update(globalTime.deltaSeconds);

    this.interactiveChildren = this.shouldShow;

    this.visible = this.alpha > 0.01;
    this.onEnterFrame.enabled = this.worldVisible && this.renderable;

    if (this.worldVisible) {
      super.render(renderer);
    }
  }

  fastForwardAlphaUpdate() {
    this.alpha = this.shouldShow ? 1.0 : 0.0;
  }
}
