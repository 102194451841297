import { onKeyPress } from "@debug/utils/onKeyPress";
import { onRightMouseButtonClick } from "@debug/utils/onRightMouseButtonClick";
import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { nextFrame } from "@sdk/utils/promises";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";

const padding = {
  top: 150,
  right: 50,
  bottom: 50,
  left: 50,
};

export class __ResourceExplorer extends SafeScrollbox {
  private readonly context;
  private readonly area;

  private readonly background: Graphics;
  private readonly tweee = new TemporaryTweeener(this);

  public readonly refresh: () => unknown;
  public showNavMenu?: () => unknown;

  public maxThumbHeight?: number = 200;

  constructor() {
    const context = GameSingletons.getGameContext();
    const rect = new Rectangle(
      padding.left,
      padding.top,
      context.viewSize.width - padding.left - padding.right,
      context.viewSize.height - padding.top - padding.bottom
    );

    super({
      noTicker: true,
      boxWidth: rect.width,
      boxHeight: rect.height,
      overflowX: "none",
      divWheel: context.app.view,
      stopPropagation: true,
      // ticker: context.ticker,
    });

    this.context = context;
    this.area = rect;

    this.x = rect.x;
    this.y = rect.y;
    this.content.sortableChildren = true;

    //// Add black dark via Pixi Graphics
    this.background = new Graphics();
    this.background.beginFill(0x000000, 0.81);
    this.background.drawRect(0, 0, rect.width, rect.height);
    this.background.endFill();
    this.background.interactive = true;
    this.addChildAt(this.background, 0);

    //// Add buttons bar at the top
    const pairs = [...Object.entries(this.context.assets.textures)];
    this.refresh = () => this.fill(pairs);

    context.events.on({ resize: this.onResize.bind(this) });

    this.tweee.from(this, {
      pixi: { pivotY: 200, alpha: 0 },
      duration: 0.175,
      ease: `power.out`,
      onComplete: () => void this.refresh(),
    });

    onKeyPress("Escape", () => this.destroy());

    onRightMouseButtonClick(this, () => this.showNavMenu?.());
  }

  onResize() {
    const { viewSize } = this.context;

    this.area.x = padding.left;
    this.area.y = padding.top;
    this.area.width = viewSize.width - padding.left - padding.right;
    this.area.height = viewSize.height - padding.top - padding.bottom;

    this.background.clear();
    this.background.beginFill(0x000000, 0.9);
    this.background.drawRect(0, 0, this.area.width, this.area.height);
    this.background.endFill();

    this.boxWidth = this.area.width;
    this.boxHeight = this.area.height;

    this.update();
  }

  async fill(pairs: [string, Texture][]) {
    this.content.removeChildren();

    this.showNavMenu = () => {
      this.context.main.hud.contextMenu.setCurrentMenu(
        groups.map(({ groupName, groupY }) => {
          return {
            disabled: false,
            text: groupName,
            onClick: () => {
              this.tweee.to(this, { scrollTop: groupY, duration: 0.33, ease: "power.inOut" });
            },
          };
        }),
        {
          boxHeight: 20,
          buttonFontSize: 16,
          buttonHoverColor: 0xffff00,
        }
      );
    };

    let x = 0;
    let y = 0;

    const appendHeader = (text: string) => {
      const HEIGHT = 50;
      const sprite = new Sprite(Texture.WHITE);
      sprite.tint = 0xffff00;
      sprite.width = this.area.width;
      sprite.height = HEIGHT;
      sprite.position.set(0, y);
      this.content.addChild(sprite);

      const header = new Text(text, { fontSize: 32, fill: 0x0, fontWeight: "bolder" });
      header.anchor.set(0.0, 0.5);
      header.position.set(50, y + HEIGHT / 2);
      this.content.addChild(header);

      sprite.interactive = true;
      sprite.buttonMode = true;
      sprite.on("pointerdown", () => this.showNavMenu?.());

      y += HEIGHT + 10;
    };

    const appendContent = (pairs: [string, Texture][]) => {
      let maxHeight = 0;
      const newLine = () => {
        x = 0;
        y += maxHeight + 10;
        maxHeight = 0;
      };

      for (const [textureId, texture] of pairs) {
        if (textureId.endsWith(`json_image`)) continue;
        if (textureId.startsWith(`assets/atlases-png/atlas-`)) continue;

        const subject = new Thumb(texture, textureId, this.maxThumbHeight);
        if (subject.sprite.width > this.area.width) {
          subject.scale.set(this.area.width / subject.sprite.width);
        }
        this.content.addChild(subject);

        ////

        if (x + subject.width > this.area.width) {
          newLine();
        }

        subject.x = x;
        subject.y = y;

        maxHeight = Math.max(maxHeight, subject.height);
        x += subject.width;
      }
      newLine();
    };

    const extractGroupName = (textureId: string) => {
      if (textureId.includes(`.basis`)) return "basis";
      const pathParts = textureId.split(`/`);
      if (pathParts.length <= 1) return ".";
      if (!pathParts[0]) return ".";
      if (pathParts[0] == "assets") return "аssets";
      return pathParts[0];
    };
    const grouped = pairs.reduce((acc, [textureId, texture]) => {
      const folder = extractGroupName(textureId);
      if (!folder) throw new Error(`Invalid textureId: ${textureId}`);
      if (!acc[folder]) acc[folder] = [];
      acc[folder].push([textureId, texture]);
      return acc;
    }, {} as Record<string, [string, Texture][]>);

    const groups = [...Object.entries(grouped)]
      .map(([groupName, groupPairs]) => {
        return {
          groupName,
          groupPairs: groupPairs.sort((a, b) => a[0].localeCompare(b[0])),
          groupY: 0,
        };
      })
      .sort((a, b) => a.groupName.localeCompare(b.groupName));

    for (const group of groups) {
      group.groupY = y;
      appendHeader(group.groupName);
      appendContent(group.groupPairs);
      y += 80;
      await nextFrame();
    }
  }
}

class Thumb extends Container {
  public readonly sprite;

  constructor(texture: Texture, textureId: string, maxHeight?: number) {
    super();

    const sprite = new Sprite(texture);
    if (maxHeight) sprite.scale.set(Math.min(maxHeight / sprite.height, 1));

    const border = new Graphics();
    border.lineStyle(2, 0xffff00, 0.5);
    border.drawRect(sprite.x, sprite.y, sprite.width, sprite.height);
    this.addChild(sprite);

    const label = new Text(textureId, { fontSize: 12, fill: 0xffffff });
    label.position.set(2, 2);

    const labelPad = new Sprite(Texture.WHITE);
    labelPad.width = label.width + 4;
    labelPad.height = label.height + 4;
    labelPad.tint = 0x000000;
    labelPad.alpha = 0.6;
    labelPad.zIndex = -1;

    this.interactive = true;
    this.buttonMode = true;

    this.on("mouseover", () => {
      this.addChild(border, labelPad, label);
      this.zIndex = 1000;
    });
    this.on("mouseout", () => {
      this.removeChild(border, labelPad, label);
      this.zIndex = 0;
    });
    this.on("click", () => {
      console.log(textureId, [texture.width, texture.height]);
      window.navigator.clipboard.writeText(textureId);
    });

    this.sprite = sprite;
  }
}
