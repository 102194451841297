import { makeDraggable } from "@debug/utils/makeDraggable";
import { FontIcon } from "@game/constants/FontIcon";
import { ThemeColors } from "@game/constants/ThemeColors";
import { Container } from "@pixi/display";
import { Point } from "@pixi/math";
import { EventBus, EventBusKey } from "@sdk/core/EventBus";
import { ribbon } from "d3";
import { StationPopup } from "./bases/StationPopup";
import { StationPopupBackgroundType } from "./components/StationPopupBackgroundType";
import { PopupPlacementFunction } from "./util/PopupPlacementFunction";

type StationEditTrainLoadoutPopupEvents = EventBus<
  EventBusKey<StationPopup["events"]> & {
    onClick_EditTrain: () => void;
  }
>;

export class StationEditTrainLoadoutPopup extends StationPopup {
  public readonly placementFunc: PopupPlacementFunction = PopupPlacementFunction.sidewaysSmart;

  public declare readonly events: StationEditTrainLoadoutPopupEvents;

  async fillContent_Default() {
    const { station } = this;
const scale = 1.225;
    if (!station) {
      throw new Error("StationDashboardPopup: station is not set");
    }

    const { main } = this.context;

    const train = main.selection.selectedTrain;
    if (!train) {
      return console.error(`No train selected at station ${station.name}.`);
    }

    this.setPadBackground(StationPopupBackgroundType.TRAIN_LOADOUT);
    if (this.context.main.selection.selectedDestination) {
      const floatyText = `Departure`;
      const floaty = this.componentFactory.createFloatyTitle(floatyText, this.padWidth);
      this.addChild(floaty);
    }

    {
      const titleText = station.name;
      const title = this.componentFactory.createTitle(titleText, this.padWidth);
      this.addChild(title);
    }

    {
      const ribbonText = [`${FontIcon.LocoLegacy}  ${train.name}`, `Rail Cars`] as const;
      const ribbon = this.componentFactory.createInfoRibbon(ribbonText);
      ribbon.updateText(ribbonText, 0.7);
      ribbon.position.set(this.padWidth * 0.5, (162*scale)-2);
      ribbon.scale.set(scale*.9);
      this.addChild(ribbon);
    }

    {
      let weightRibbon: Container | null = null;
      const updateRibbon = () => {
        weightRibbon?.destroy();

        const train = this.context.main.cards.changes.unsavedTrain;
        const fontColor =
          train && train.currentTotalWeight > train.maxWeight ? ThemeColors.DANGER_COLOR.toInt() : 0xffffff;
        const glow = train?.locomotiveStats?.distanceBoost != undefined;
        weightRibbon = this.componentFactory.createInfoRibbon_PotentialRunStats(
          [{ text: `Haul Weight:` }, { text: train ? `${train.currentTotalWeight}/${train.maxWeight}` : `N/A`, glow }],
          `ui-popups/bg-lvl1-btm-info.png`,
          0.7,
          fontColor,
          true
        );
        weightRibbon.position.set(this.padWidth * 0.5, 514);
        this.addChild(weightRibbon);
      };
      updateRibbon();

      const cleanup = main.cards.events.on({
        hypotheticalTrainChange: () => updateRibbon(),
      });
      this.onDestroy(cleanup);
    }

    //// STATS ////

    {
      const scrollbox = this.componentFactory.addTrainLoadoutScrollBox(217, 472);
      scrollbox.position.set(this.padWidth * 0.5-(scrollbox.width/2)-18,217);
      scrollbox.scale.set(.9875);
      scrollbox.updateContent(this.context.main.cards.changes.unsavedTrain);

      const cleanup = main.cards.events.on({
        hypotheticalTrainChange: hypo => scrollbox.updateContent(this.context.main.cards.changes.unsavedTrain),
      });
      this.onDestroy(cleanup);
    }

    //// BUTTONS ////

    {
      const buttonText = `Edit Train`;
      const button = this.componentFactory.createBottomButton("fill", buttonText, 75);
      button.scale.set(.5);
      button.y -= 2;
      button.setEnabled(true);
      button.onClick = () => this.events.emit("onClick_EditTrain");
      this.onDestroy(() => button.removeAllListeners());
      this.addChild(button);
    }

    //// X Close Button
    this.componentFactory.addXCloseButton();

    await this.fadeChildrenIn();
  }
}
