import * as sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { env } from "@game/app/global";
import { __urlParams__ } from "@game/app/__urlParams__";

export function setupSentryLogging() {
  sentry.init({
    dsn: "https://50318c627cc34cdbaa8d130f95438bf6@o1070824.ingest.sentry.io/6067190",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,

    release: "toc@" + env.APP_PACKAGE_VERSION,
    environment: env.ENVIRONMENT,
  });

  sentry.setTag("blockchain", env.BLOCKCHAIN);
  // sentry.setTag('commit_hash', env.COMMIT_HASH);
  // sentry.setTag('app_build_time', env.APP_BUILD_TIME);

  __urlParams__.debug && sentry.setTag("debug", __urlParams__.debug);
  __urlParams__.edit && sentry.setTag("edit", __urlParams__.edit);

  return sentry;
}
