import "./index.scss";

import { assurePasswordGateUnlocked } from "./dev-gate/assurePasswordGateUnlocked";
import { setupLoadingSpinnerSplash } from "./loading/loading";
import { setupRailroaderCreationAndLogin } from "@sdk-integration/setupRailroaderCreationAndLogin";
import { setupLoadingFavicon } from "./loading/dynamicFavicon";
import { setupSentryLogging } from "./analytics/setupSentryLogging";
import { createGame } from "@game/app/app";
import { WaxContractActionError } from "@sdk-integration/contracts/WaxContractActionService";

function handleError(error: Error | unknown) {
  if (error instanceof WaxContractActionError) {
    console.error(error);
  } else {
    alert(`An error accurred during the login process.\nPlease refresh and try again.\nError: ` + String(error));
  }
}

async function launch() {
  const sentry = setupSentryLogging();
  const analytics = {
    log(things: Error | string) {
      if (things instanceof Error) {
        sentry.captureException(things);
      } else if (typeof things === "string") {
        sentry.captureMessage(things);
      } else {
        throw new Error("Unsupported type of things");
      }
    },
  };

  const loadingSpinnerService = setupLoadingSpinnerSplash();
  setupLoadingFavicon(loadingSpinnerService.reasonsToShow);

  const { waxService, ual } = await setupRailroaderCreationAndLogin({ loadingSpinnerService });

  sentry.setUser({ id: waxService.currentUserName });
  waxService.events.addListener("error", analytics.log);

  const context = createGame({
    waxContractsService: waxService,
    loadingSpinnerService,
    analytics,
    logout: () => {
      ual.logoutUser();
      sentry.setUser(null);
      location.reload();
    },
  });
  document.body.appendChild(context.app.view);
}

try {
  assurePasswordGateUnlocked();
} catch (error) {
  console.error(error);
}

launch().catch(handleError);
