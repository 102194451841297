import { env } from "@game/app/global";
import { getRpcEndpointHost } from "@sdk-integration/configuration";

interface Config {
  url_rpc: string;
  url_atomicAssets: string;

  simpleAssetId_Diesel: string;
  simpleAssetId_Coal: string;
}

const cfgTestnet: Config = {
  url_rpc: "https://api.waxtest.alohaeos.com:443",
  url_atomicAssets: "https://test.wax.api.atomicassets.io",

  simpleAssetId_Coal: "100000000013157",
  simpleAssetId_Diesel: "100000000013158",
};

const cfgMainnet: Config = {
  url_rpc: `https://${getRpcEndpointHost()}:443`,
  url_atomicAssets: "https://wax.api.atomicassets.io",

  simpleAssetId_Coal: "100000018619355",
  simpleAssetId_Diesel: "100000018619367",
};

export function getConfigurationForBlockchain(blockchain: string = env.BLOCKCHAIN): Config {
  switch (blockchain) {
    case "testnet":
      return cfgTestnet;
    case "mainnet":
      return cfgMainnet;
    default:
      throw new Error(`Unknown blockchain ${blockchain}`);
  }
}
