import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { getDecorationScalesByTextureId } from "@game/world/layer_operations/util/getDecorationScalesByTextureId";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { buttonizeInstance } from "@sdk-ui/buttonize";
import { Color } from "@sdk/utils/color/Color";
import { randomBetween } from "@sdk/utils/random";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { viewBankModal } from "@game/ui/buildings/bank/bankModal";
import { buildingPanel } from "@game/ui/buildings/building-panel";
import { InteractionData } from "@pixi/interaction";

const decorationSpriteScale = 2.4;

export function makeDecorations(objectsContainer: Container) {
  const context = GameSingletons.getGameContext();
  const { assets, mapData } = context;

  const decorations = new Array<Sprite>();
  const objects = [...mapData.decorations, ...mapData.specialObjects];
  for (const { x, y, textureId, flipped } of objects) {
    const sprite = Object.assign(new Sprite(assets.textures[textureId]), {
      flipped,
    });

    const scale = getDecorationScalesByTextureId(textureId);

    sprite.position.set(x, y);
    sprite.anchor.set(0.5, 0.75);
    sprite.scale.x *= sprite.flipped ? -1 : 1;
    sprite.zIndex = sprite.y;

    if (textureId.includes("Landscape")) {
      const scaleRandomizer = randomBetween(0.8, 1.1);
      sprite.scale.set(decorationSpriteScale * scale * scaleRandomizer);
      const tint = Color.lerp(0xd0d0d0, 0xffffff, Math.random()).toInt();
      sprite.tint = tint;
    } else {
      sprite.scale.set(decorationSpriteScale * scale);
    }
 
//Choo Choo Draw
    if (textureId.includes("James Park/Circus Tent Shadow")) {
      const hoverSign = new Sprite(assets.getTexture("chooChooDraw"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 10.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger: () => {
          const chooChooDrawUrl = `https://draw.trains.cards/`;
          window.open(chooChooDrawUrl, "_blank")?.focus();
        },
      });
    }

    //Bank
/*     if (textureId.includes("Trevithick Pines/8-pheights-bank")) {
     
      const hoverSign = new Sprite(assets.getTexture("bankSign"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 10.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger: () => {
          
          const context = GameSingletons.getGameContext();
          const popup = new viewBankModal();
          context.stageContainers._modals.addChild(popup);
          popup.position.set(
            context.viewSize.width / 2 - popup.width / 2,
            context.viewSize.height / 2 - popup.height / 2
          );
        },
      });
    } */

    //Library
    if (textureId.includes("Centuryville/Library.png")) {
      const hoverSign = new Sprite(assets.getTexture("librarySign"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 10.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger:  (edata: InteractionData) => {
          const context = GameSingletons.getGameContext();
          const { input } = context;

          //Building Objects
          let [bldg] = [
            { buildingName: "Centuryville Library",
              buildingId:1,
              buildingSprite: "Centuryville/Library.png",
              buildingRegionId:1,
              buildingImage: "library-bldg-img.png",
              buildingDesc: "The Centuryville Library features tens of books & \ndigital resources. Hosts events & programs for \nall interests. A community hub & gathering place!",
              textureId: "decoration-sprites/Buildings/Centuryville/Library.png",
              x: -7098,
              y: 296,
              dotColor: 16765502,
              flipped: false,
          },];
          input.emit("clickOnBuilding", bldg, edata);
   
          
            const panel = new buildingPanel(bldg.buildingName,bldg.buildingSprite,bldg.buildingRegionId,bldg.buildingImage,bldg.buildingDesc);
            context.stageContainers._bldg.addChild(panel);
            panel.position.set(
            context.viewSize.width / 2 - panel.width / 2,
            context.viewSize.height - panel.height
            );
          
          
        },
      });
    }
    //Arcade
    if (textureId.includes("Trevithick Pines/3-cville-arcade.png")) {
      const hoverSign = new Sprite(assets.getTexture("arcadeSign"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 10.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger:  (edata: InteractionData) => {
          const context = GameSingletons.getGameContext();
          const { input } = context;

          //Building Objects
          let [bldg] = [
            { 
            buildingName: "Choopy McBoopers",
            buildingId:2,
            buildingSprite: "Trevithick Pines/3-cville-arcade.png",
            buildingRegionId:1,
            buildingImage:"choopy.png",
            buildingDesc:"Ready to get your beeps booped? Get your Mountain Choo \nfueled buns in here! Choopy McBoopers is the best \nplace to play the hottest arcade games in the Centuryverse!",
              textureId: "decoration-sprites/Buildings/Trevithick Pines/3-cville-arcade.png",
              x: -1558,
              y: -211,
              dotColor: 16765502,
              flipped: false,
          },];
          input.emit("clickOnBuilding", bldg, edata);
   
          
            const panel = new buildingPanel(bldg.buildingName,bldg.buildingSprite,bldg.buildingRegionId,bldg.buildingImage,bldg.buildingDesc);
            context.stageContainers._bldg.addChild(panel);
            panel.position.set(
            context.viewSize.width / 2 - panel.width / 2,
            context.viewSize.height - panel.height
            );
          
          
        },
      });
    }

    //Museum
  /*   if (textureId.includes("Trevithick Pines/13-tpines-museum.png")) {
      const hoverSign = new Sprite(assets.getTexture("museumSign"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 20.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger:  (edata: InteractionData) => {
          const context = GameSingletons.getGameContext();
          const { input } = context;
         
          //Building Objects
          let [bldg] = [
            { buildingName: "Museum of Locomotion",
              buildingId:1,
              buildingSprite: "Trevithick Pines/13-tpines-museum.png",
              buildingRegionId:3,
              buildingImage: "museum.png",
              buildingDesc: "The Richard Trevithick Museum of Locomotion showcases\nsome of the most beautiful Choo Choo Trains of history.\nGrab a coffee from the cafe and awe at the Choo Choos.",
              textureId: "decoration-sprites/Buildings/Trevithick Pines/13-tpines-museum.png",
              x: 3273,
              y: -17059,
              dotColor: 16765502,
              flipped: false,
          },];

            const panel = new buildingPanel(bldg.buildingName,bldg.buildingSprite,bldg.buildingRegionId,bldg.buildingImage,bldg.buildingDesc);
            context.stageContainers._bldg.addChild(panel);
            panel.position.set(
            context.viewSize.width / 2 - panel.width / 2,
            context.viewSize.height - panel.height
            );
         
          input.emit("clickOnBuilding", bldg, edata);
        },
      });
    }
 */

    //Le Clothier
    if (textureId.includes("Trevithick Pines/7-pheights-clothes.png")) {
      const hoverSign = new Sprite(assets.getTexture("leClothier"));
      hoverSign.zIndex = Number.MAX_SAFE_INTEGER;
      hoverSign.anchor.set(0.0, 1.0);
      hoverSign.scale.set(0);
      objectsContainer.addChild(hoverSign);

      const btn = buttonizeInstance(sprite);
      btn.behavior.on({
        hoverIn: () => {
          hoverSign.position.set(sprite.x, sprite.y - sprite.height);
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 10.0 },
            duration: 1.22,
            ease: "elastic.out",
            overwrite: true,
          });
        },
        hoverOut: () => {
          context.animator.tween.to(hoverSign, {
            pixi: { scale: 0.0 },
            duration: 0.33,
            ease: "back.in",
            overwrite: true,
          });
        },
        trigger: () => {
          const chooChooDrawUrl = `https://shop.trains.cards/`;
          window.open(chooChooDrawUrl, "_blank")?.focus();
        },
      });
    }
    
    decorations.push(sprite);
  }
  
  const secObjects = [...mapData.superSpecial];
  // Create an array of clickable superSpecial decoration sprites
  const clickableSuperSpecials = mapData.superSpecial.filter(obj => obj.clickable);

    for (const { x, y, textureId, flipped, clickable, url } of secObjects) {
       const spriteSec = Object.assign(new Sprite(assets.textures[textureId]), {
         flipped,
       });
   
       
       const scale = getDecorationScalesByTextureId(textureId);
   
       spriteSec.position.set(x, y);
       spriteSec.cursor = "pointer";
       spriteSec.anchor.set(0.5, 0.75);
       spriteSec.scale.x *= spriteSec.flipped ? -1 : 1;
       spriteSec.zIndex = spriteSec.y;
   
       if (textureId.includes("Landscape")) {
         const scaleRandomizer = randomBetween(0.8, 1.1);
         spriteSec.scale.set(decorationSpriteScale * scale * scaleRandomizer);
         const tint = Color.lerp(0xd0d0d0, 0xffffff, Math.random()).toInt();
         spriteSec.tint = tint;
       } else {
         spriteSec.scale.set(decorationSpriteScale * scale);
       }
 
  if (clickableSuperSpecials.length > 0) {
    for (const { x: superSpecialX, y: superSpecialY, url } of clickableSuperSpecials) {
      if (x === superSpecialX && y === superSpecialY) {
        const btn = buttonizeInstance(spriteSec);
        btn.behavior.on({
          trigger: () => {
              window.open(url, "_blank")?.focus();
          },
        });

        break;
      }
    }
  }
        decorations.push(spriteSec);
      }

  const decorationsContainer = Object.assign(objectsContainer, { decorations, objects });
  decorationsContainer.addChild(...decorations);
  decorationsContainer.sortChildren();

  return decorationsContainer;
}
