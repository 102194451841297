import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Text } from "@pixi/text";
import { createValueAnimator_Counter } from "../../common/createValueAnimator_Counter";
import { createHUDElementContainer } from "./hud-utils";

export function createUserFuelIndicatorsList() {
  const { userData } = GameSingletons.getGameContext();

  const container = createHUDElementContainer({ x: 0.0, y: 1.0 }, { x: 25, y: 40 });

  //// Construct and initialize indicators

  function addIndicator(description: string, getCurrentValue: () => number) {
    const tf = new Text("", {
      fontFamily: FontFamily.Default,
      fontSize: 18,
      fill: 0xffffff,

      stroke: "#080808",
      strokeThickness: 1,

      dropShadow: true,
      dropShadowAngle: 1.57079632679,
      dropShadowColor: 0x010101,
      dropShadowDistance: 2,
      dropShadowAlpha: 0.75,
    });
    tf.alpha = 0.55;
    tf.x = -container.screenAnchor.x * (container.width + tf.width);
    tf.y = -container.screenAnchor.y * (container.height + tf.height);
    container.addChild(tf);

    const updateText = (screenValue: number) => {
      // tf.text = `${description.toUpperCase()}: ${screenValue.toFixed(4)}`
      tf.text = `${description.toUpperCase()}: ${Math.round(screenValue)}`;
    };
    updateText(getCurrentValue());

    const animator = createValueAnimator_Counter(getCurrentValue, updateText);
    animator.animationDuration = 1.7783;

    return Object.assign(tf, { animator });
  }

  addIndicator("Coal", () => userData.fuel.coal || 0);
  addIndicator("Diesel", () => userData.fuel.diesel || 0);

  return container;
}

export type UserFuelIndicatorsList = ReturnType<typeof createUserFuelIndicatorsList>;
