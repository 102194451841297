import * as WAX from "@sdk-integration/contracts";
import { ExplorerApi } from "atomicassets";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { AssetId } from "./internal/datamodels";
import { AtomicAssetsEndpointPlaceholder, customRoundRobinFetch } from "./utils/atomicassetsRoundRobinFetch";

const AtomicAssetsEndpoint = "https://" + AtomicAssetsEndpointPlaceholder;
const AtomicAssetsNamespace = "atomicassets";
const RRCollectionName = "centurytrain";

const validCardSchemaNames: Record<string, true> = {
  locomotive: true,
  conductor: true,
  railcar: true,
  commodity: true,
  passengercar: true,
  passenger: true,
};

function convertToCardData(asset: IAsset) {
  const asset_id = asset.asset_id as WAX.CardAssetId;
  const owner = asset.owner as WAX.AccountName;
  const card_schema_type = asset.schema.schema_name as WAX.CardAssetData.SchemaType;
  const card_template = asset.template;
  return {
    ...trimObject(asset.data as any),
    asset_schema_type: card_schema_type,
    asset_template_id: card_template?.template_id,
    asset_template: card_template,
    asset_id,
    owner,
  };
}

export class WaxAssetsService {
  private readonly api = new ExplorerApi(AtomicAssetsEndpoint, AtomicAssetsNamespace, {
    fetch: customRoundRobinFetch,
  });

  constructor(public readonly ownerAccountName: WAX.AccountName) {}

  async getSingleStationAsset(stationAssetId: WAX.StationAssetId) {
    const asset = await this.api.getAsset(stationAssetId);
    const asset_id = asset.asset_id as WAX.StationAssetId;
    const owner = asset.owner as WAX.AccountName;
    return {
      ...(asset.data as Omit<WAX.StationAssetData, "asset_id" | "owner">),
      asset_id,
      owner,
    };
  }

  async getAllStationAssets() {
    const assets = await this.api.getAssets({
      collection_name: RRCollectionName,
      schema_name: "station",
      limit: 1000,
    });

    console.log(`⚛ Stations`, { assets });

    return assets.map<WAX.StationAssetData>(asset => {
      const asset_id = asset.asset_id as WAX.StationAssetId;
      const owner = asset.owner as WAX.AccountName;
      return {
        ...(asset.data as Omit<WAX.StationAssetData, "asset_id" | "owner">),
        asset_id,
        owner,
      };
    });
  }

  async getAccountAssets() {
    const results = new Array<IAsset>();
    let page = 1;
    let lastResultCount = 0;
    do {
      const r = await this.api.getAssets(
        {
          owner: this.ownerAccountName,
          collection_name: RRCollectionName,
          limit: 1000,
        },
        page++
      );
      lastResultCount = r.length;
      results.push(...r);
    } while (lastResultCount);

    console.log(`⚛ Cards`, { assets: results });

    return {
      cards: results
        .filter(asset => validCardSchemaNames[asset.schema.schema_name])
        .map<WAX.CardData>(convertToCardData),
      redeemables: results
        .filter(asset => asset.schema.schema_name === "redeemable")
        .map<WAX.RedeemableData>(a => {
          return { asset_id: a.asset_id as AssetId, data: a.data as any };
        }),
      modifiers: results.filter(asset => asset.schema.schema_name === "modifier"),
    };
  }

  async *iterateCardAssets(cardAssetIds: WAX.CardAssetId[]) {
    for await (const id of cardAssetIds) {
      const asset = await this.api.getAsset(id);
      const isValid = validCardSchemaNames[asset.schema.schema_name];
      if (isValid) {
        yield convertToCardData(asset);
      }
    }
  }

  async getSingleCardAsset(cardAssetId: WAX.CardAssetId) {
    const asset = await this.api.getAsset(cardAssetId);

    console.log(`⚛ Card`, { asset });

    return convertToCardData(asset);
  }

  async getAssetTemplateData<T extends { [key: string]: any } = { [key: string]: any }>(
    templateId: WAX.AssetTemplateId
  ) {
    const r = await this.api.getTemplate(RRCollectionName, templateId);
    return r.immutable_data as T;
  }

  async getAssetTemplatesData<T extends { [key: string]: any } = { [key: string]: any }>(
    templateIds: WAX.AssetTemplateId[]
  ) {
    const r = await this.api.getTemplates({
      ids: templateIds.join(","),
      collection_name: RRCollectionName,
      limit: 1000,
    });
    return r.map<T>(template => {
      return {
        ...(template.immutable_data as T),
        asset_template_id: template.template_id as WAX.AssetTemplateId,
        asset_schema_type: template.schema.schema_name as WAX.CardAssetData.SchemaType,
      };
    });
  }

  async getTemplatesBySchemaName(schemaName: string) {
    const r = await this.api.getTemplates({
      schema_name: schemaName,
      collection_name: RRCollectionName,
      limit: 1000,
    });
    return r;
  }
}

/** Function that trims all string property values in an object recursively */
function trimObject<T extends string | any[] | {}>(obj: T): T {
  if (typeof obj === "string") {
    return obj.trim() as T;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => trimObject(item)) as T;
  }

  if (typeof obj === "object") {
    const result = {} as T;
    for (const [key, value] of Object.entries(obj) as [keyof T, T[keyof T]][]) {
      // @ts-ignore
      result[key] = trimObject(value);
    }
    return result;
  }

  return obj;
}
