import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";

export class RunModMiniPin extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  constructor(iconTexture: string) {
    super();
    const runModMiniPin = this.factory.createSprite("/assets/images/ui-runmods-pin-sidebar/circle.png");
    const runModMiniPinIcon = this.factory.createSprite(iconTexture);
    runModMiniPin.anchor.set(0.5);
    runModMiniPinIcon.anchor.set(0.5);
    runModMiniPin.scale.set(1.25);
    runModMiniPinIcon.scale.set(1.25);
    runModMiniPinIcon.position.set(runModMiniPin.x, runModMiniPin.y);

    this.addChild(runModMiniPin, runModMiniPinIcon);
  }
}
