import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { AchievementBadgeData } from "@game/ui/social/achievements/AchievementBadgeData";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { AchievementsRRDashUserListRow } from "./AchievementsRRDashSidePanelUserListRow";
import { AchievementsRRDashUserListSidePanelDataService } from "./AchievementsRRDashUserListSidePanelDataService";

export class AchievementsRRDashUserList extends SafeScrollbox {
  protected readonly context: GameContext = GameSingletons.getGameContext();
  protected readonly dataService: AchievementsRRDashUserListSidePanelDataService =
    new AchievementsRRDashUserListSidePanelDataService();
  private readonly badge: AchievementBadgeData;

  constructor(badge: AchievementBadgeData) {
    super({
      noTicker: true,
      boxWidth: 450,
      boxHeight: 580,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflowX: "none",
    });
    this.badge = badge;
  }

  async initialize() {}

  async fillUserList(userNameHolder: string[]) {
    this.clearData();

    await this.addRows(userNameHolder, name => {
      const rowContainer = new AchievementsRRDashUserListRow();

      rowContainer.applyUserData(
        {
          username: name,
          socialLink: name,
        },
        255
      );

      this.update();

      return rowContainer;
    });
  }

  private async addRows<T>(rowsData: string[], constructRow: (rowData: string, index: number) => Container) {
    let startY = 0;

    for (let index in rowsData) {
      const rowData = rowsData[index];

      const userOwnedAchievements: AchievementBadgeData[] = await this.dataService.getAchievements(rowData);

      for (let usersBadge of userOwnedAchievements) {
        if (usersBadge.achievementId == this.badge.achievementId) {
          const row = constructRow(rowData, +index);
          this.content.addChild(row);
          row.position.y = startY;
          startY += row.height + 5;

          await this.context.ticker.nextFrame();
          this.update();
        }
      }

      this.content.addChild(this.addInvisibleBox(426));
      this.update();
    }
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth;
    box.height = px;
    return box;
  }

  clearData() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
