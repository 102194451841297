import { GameSingletons } from "@game/app/GameSingletons";
import { StakingAddonType } from "@game/asorted/StakingType";
import { AssetRarity, StationAssetId } from "@sdk-integration/contracts";
import { CardEntity } from "../entities/CardEntity";
import { StakingAddonStatusData } from "./models";
import { StakingAddonDataService } from "./StakingAddonDataService";

const schemaToAddonType = {
  locomotive: StakingAddonType.RailYard,
  conductor: StakingAddonType.ConductorLounge,
} as Partial<Record<CardEntity["schemaType"], StakingAddonType>>;

export module StakingAddonDataHelper {
  export function getCardCommissionRate(card: CardEntity) {
    const { gameConfigData } = GameSingletons.getGameContext();

    const addonType = schemaToAddonType[card.schemaType];
    if (!addonType) {
      throw new Error(`No commission rate found for card type ${card.schemaType}`);
    }

    const commissionRatesPerRarity = gameConfigData.staking[addonType].assetCommissionRates;
    const cardRarity = card.rarityString.toLowerCase() as AssetRarity;
    const commissionRate = commissionRatesPerRarity[cardRarity];

    return commissionRate;
  }

  export async function getBothStakingAddonsData(stationId: StationAssetId) {
    const railYardDataService = new StakingAddonDataService(StakingAddonType.RailYard);
    const conductorLoungeDataService = new StakingAddonDataService(StakingAddonType.ConductorLounge);

    return {
      railYardData: await railYardDataService.getAddonStatusData(stationId),
      conductorLoungeData: await conductorLoungeDataService.getAddonStatusData(stationId),
    };
  }

  export function isUserOnVIPWhitelist(data: StakingAddonStatusData) {
    const { contracts } = GameSingletons.getGameContext();
    if (!data.unlocked) return false;
    if (!data.vipWhitelist.some(row => row.railroader === contracts.currentUserName)) return false;
    return true;
  }
}
