import { GameSingletons } from "@game/app/GameSingletons";
import { CardType } from "@game/constants/CardType";
import type * as WAX from "@sdk-integration/contracts";
import type { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";
import { CardEntity } from "./CardEntity";
import type { RailRunEntity } from "./RailRunEntity";

export declare module TrainEntity {
  export type Name = WAX.TrainName;

  export type PerkType = string;
  export type Perk = {
    type: PerkType;
    boost: number;
  };
}

module TrainSpecs {
  export function getMaxDistanceSpecs(train: TrainEntity): MaxDistanceSpecs {
    const locomotiveBase = train.locomotiveStats?.distanceBase || 0;
    const locomotiveBoost = train.locomotiveStats?.distanceBoost || 0;
    const total = locomotiveBase + locomotiveBoost;
    return { locomotiveBase, locomotiveBoost, total };
  }
  type MaxDistanceSpecs = {
    locomotiveBase: number;
    locomotiveBoost: number;
    total: number;
  };

  export function getHaulingPowerSpecs(train: TrainEntity): HaulingPowerSpecs {
    const locomotiveBase = train.locomotiveStats?.haulingPowerBase || 0;
    const locomotiveBoost = train.locomotiveStats?.haulingPowerBoost || 0;
    const conductorBoost = train.conductorStats?.perkBoost_HaulingPower || 0;
    const totalMultiplier = 1 + 0.01 * conductorBoost;
    const total = totalMultiplier * (locomotiveBase + locomotiveBoost);
    return { locomotiveBase, locomotiveBoost, conductorBoost, total };
  }
  type HaulingPowerSpecs = {
    locomotiveBase: number;
    locomotiveBoost: number;
    conductorBoost: number;
    total: number;
  };

  export function getTrainSpeedSpecs(train: TrainEntity): TrainSpeedSpecs {
    const locomotiveBase = train.locomotiveStats?.speedBase || 0;
    const locomotiveBoost = train.locomotiveStats?.speedBoost || 0;
    const conductorBoost = train.conductorStats?.perkBoost_Speed || 0;
    const totalMultiplier = 1 + 0.01 * conductorBoost;
    const total = totalMultiplier * (locomotiveBase + locomotiveBoost);
    return { locomotiveBase, locomotiveBoost, conductorBoost, total };
  }
  type TrainSpeedSpecs = {
    locomotiveBase: number;
    locomotiveBoost: number;
    conductorBoost: number;
    total: number;
  };

  export function getLuckSpecs(train: TrainEntity): LuckSpecs {
    const { conductorLuckBasePercent = 2, conductorLuckMultiplier = 1 } =
      GameSingletons.getDataHolders().gameConfigData.gameplay;
    const locomotiveBoost = train.locomotiveStats?.luckBoost || 0;
    const conductorBoost = train.conductorStats?.perkBoost_Luck || 0;
    const total: number = conductorLuckBasePercent + locomotiveBoost + conductorBoost * conductorLuckMultiplier;
    return { globalBase: conductorLuckBasePercent, locomotiveBoost, conductorBoost, total };
  }
  type LuckSpecs = {
    globalBase: number;
    locomotiveBoost: number;
    conductorBoost: number;
    total: number;
  };

  export function getMaxWeightSpecs(train: TrainEntity): MaxWeightSpecs {
    const globalHaulingPowerMultiplierPercent = GameSingletons.getDataHolders().gameConfigData.vars.hp_m || 1;
    const globalHaulingPowerMultiplier = globalHaulingPowerMultiplierPercent * 0.01;
    const haulingPower = getHaulingPowerSpecs(train);
    const total = Math.floor(haulingPower.total * globalHaulingPowerMultiplier);
    return { globalHaulingPowerMultiplierPercent, globalHaulingPowerMultiplier, haulingPower, total };
  }
  type MaxWeightSpecs = {
    globalHaulingPowerMultiplierPercent: number;
    globalHaulingPowerMultiplier: number;
    haulingPower: {
      locomotiveBase: number;
      locomotiveBoost: number;
      conductorBoost: number;
      total: number;
    };
    total: number;
  };
}

export class TrainEntity {
  public readonly extraSlots = {
    rc: 0,
  };

  public orderIndex = 0;

  constructor(
    public readonly waxData: WAX.TrainData,
    public readonly $get: Readonly<{
      getCard<T extends WAX.CardData = WAX.CardData>(assetId: CardEntity.AssetId): CardEntity<T>;
      getOngoingTrainRun(trainName: WAX.TrainName): RailRunEntity | null;
    }>
  ) {}

  //// Basic Properties ////

  /**
   * Floating point number from 0 to 1.
   *
   * If the number is below .9, the train needs to be repaired to achieve its full speed.
   */
  public get conditionFraction(): number {
    // return this.orderIndex * .43 % 1;

    const maxCondition = GameSingletons.getGameContext().gameConfigData.vars.max_cond;
    return this.conditionPoints / maxCondition;

    return Math.random();
  }

  public get conditionPoints(): number {
    const maxCondition = GameSingletons.getGameContext().gameConfigData.vars.max_cond;
    return this.waxData.condition ?? maxCondition;
  }

  public get name(): WAX.TrainName {
    return this.waxData.train;
  }

  public get maxWeight(): number {
    return TrainSpecs.getMaxWeightSpecs(this).total;
  }

  public get maxDistance(): number {
    return this.locomotiveStats?.distance || 0;
  }

  public get isTampered(): boolean {
    return !!this.waxData.tampered;
  }

  public get currentOngoingRun() {
    return this.$get.getOngoingTrainRun(this.name);
  }

  public get currentStationId() {
    return this.waxData.current_station || null;
  }

  public get currentDestinationStationId() {
    return this.currentOngoingRun?.destination || null;
  }

  public get unlockedExtraRailCarSlotsCount() {
    return this.extraSlots.rc;
  }

  public get unlockedTotalRailCarSlotsCount() {
    return this.extraSlots.rc + 1;
  }

  public getSpecs() {
    return {
      maxDistance: TrainSpecs.getMaxDistanceSpecs(this),
      haulingPower: TrainSpecs.getHaulingPowerSpecs(this),
      speed: TrainSpecs.getTrainSpeedSpecs(this),
      luck: TrainSpecs.getLuckSpecs(this),
      maxWeight: TrainSpecs.getMaxWeightSpecs(this),
    };
  }

  //// Cards ////

  public get locomotive(): CardEntity<WAX.CardAssetData_Locomotive> | null {
    const assetId = this.waxData.locomotives?.[0];
    if (!assetId) return null;
    const card = this.$get.getCard(assetId);
    return card as CardEntity<WAX.CardAssetData_Locomotive>;
  }

  public set locomotive(card: CardEntity<WAX.CardAssetData_Locomotive> | null) {
    this.waxData.locomotives = card ? [card.assetId] : [];
  }

  public get locomotives(): CardEntity<WAX.CardAssetData_Locomotive>[] {
    return this.waxData.locomotives?.map(assetId => this.$get.getCard<WAX.CardAssetData_Locomotive>(assetId)) || [];
  }

  public get conductor(): CardEntity<WAX.CardAssetData_Conductor> | null {
    const assetId = this.waxData.conductors?.[0];
    if (!assetId) return null;
    const card = this.$get.getCard(assetId);
    return card as CardEntity<WAX.CardAssetData_Conductor>;
  }

  public set conductor(card: CardEntity<WAX.CardAssetData_Conductor> | null) {
    this.waxData.conductors = card ? [card.assetId] : [];
  }

  public get conductors(): CardEntity<WAX.CardAssetData_Conductor>[] {
    return this.waxData.conductors?.map(assetId => this.$get.getCard<WAX.CardAssetData_Conductor>(assetId)) || [];
  }

  public get railCars(): Readonly<CardEntity<WAX.CardAssetData_Wagon>[]> {
    return this.waxData.load?.map(load => this.$get.getCard<WAX.CardAssetData_Wagon>(load.railcar_asset_id)) || [];
  }

  public addRailCar(card: CardEntity<WAX.CardAssetData_Wagon>) {
    this.waxData.load = this.waxData.load || [];
    this.waxData.load.push({
      railcar_asset_id: card.assetId,
      load_ids: [],
    });
  }

  public removeRailCar(card: CardEntity<WAX.CardAssetData_Wagon>) {
    this.waxData.load = this.waxData.load || [];
    this.waxData.load = this.waxData.load.filter(load => load.railcar_asset_id !== card.assetId);
  }

  get conductorStats() {
    const waxData = this.conductor?.data;
    if (!waxData) return null;

    const perks = [
      { type: waxData.perk, boost: waxData.perk_boost },
      { type: waxData.perk2, boost: waxData.perk_boost2 },
    ].filter(perk => !!perk.type);
    const perksMap = perks.reduce((acc, { type, boost }) => {
      return {
        ...acc,
        [type]: boost,
      };
    }, {} as Partial<Record<TrainEntity.PerkType, number>>);

    return {
      gearThreshold: waxData.conductor_level,
      perkTypes: [waxData.perk, waxData.perk2].filter(perk => perk != null),
      perkBoosts: [waxData.perk_boost, waxData.perk_boost2].filter(perk => perk != null),
      perkBoostsSum: (waxData.perk_boost || 0) + (waxData.perk_boost2 || 0),

      perkBoost_HaulingPower: perksMap["Hauling Power"] || 0,
      perkBoost_Luck: perksMap["Luck"] || 0,
      perkBoost_Speed: perksMap["Speed"] || 0,

      name: waxData.name,
    };
  }

  get locomotiveStats() {
    const waxData = this.locomotive?.data;
    if (!waxData) return null;
    return {
      composition: waxData.composition,
      gearLevel: +waxData.conductor_threshold,
      fuel: waxData.fuel,

      distance: +waxData.distance + (waxData.distance_boost ?? 0),
      haulingPower: +waxData.hauling_power + (waxData.haul_boost ?? 0),
      speed: +waxData.speed + (waxData.speed_boost ?? 0),

      distanceBase: +waxData.distance,
      haulingPowerBase: +waxData.hauling_power,
      speedBase: +waxData.speed,

      distanceBoost: waxData.distance_boost,
      haulingPowerBoost: waxData.haul_boost,
      speedBoost: waxData.speed_boost,
      luckBoost: waxData.luck_boost,
    };
  }

  get rcCombinedStats() {
    const rcs = this.railCars;

    const haulCapacity = rcs.reduce((acc, rc) => {
      if (rc.data.asset_schema_type === "passengercar") return acc;
      return acc + rc.data.capacity;
    }, 0);

    const seats = rcs.reduce((acc, rc) => {
      if (rc.data.asset_schema_type === "railcar") return acc;
      return acc + rc.data.seats;
    }, 0);

    function filterUniqueAndNonNullish<T>(arr: T[]): T[] {
      return arr.filter((item, index, self) => item != null && index === self.indexOf(item));
    }
    const commodityTypes = filterUniqueAndNonNullish(
      new Array<string>().concat(
        ...rcs.map(rc =>
          rc.data.asset_schema_type === "railcar" ? [rc.data.commodity_type, rc.data.commodity_type2] : []
        )
      )
    );

    return {
      haulCapacity,
      seats,
      commodityTypes,
    };
  }

  public get currentTotalWeight(): number {
    return this.railCars.reduce((acc, rc) => {
      return acc + this.getRailCarWeight(rc);
    }, 0);
  }

  /**
   * @param rc Either ref to the rail car card, or a rail car index
   */
  getRailCarLoadStats(rc: CardEntity<WAX.CardAssetData_Wagon> | number) {
    if (typeof rc === "number") {
      rc = this.railCars[rc];
    }

    if (CardEntity.isTypeCommodityWagon(rc)) {
      const commodities = [...this.iterateLoadedCommodities(rc)] as CommodityLoadableCard[];
      const capacityUtilized = commodities.reduce((acc, commodity) => acc + +commodity.stats.volume, 0);
      const capacityMax = +rc.stats.capacity;
      const weight = commodities.reduce((acc, commodity) => acc + +commodity.stats.weight, 0);

      return {
        wagonType: rc.schemaType,
        rcStats: rc.stats,
        rcRarity: rc.rarity,
        totalWeight: weight,
        capacityUtilized,
        capacityMax,
        commodities,
      };
    }

    if (CardEntity.isTypePassengerWagon(rc)) {
      const passengers = [...this.iterateLoadedCommodities(rc)] as PassengerLoadableCard[];

      return {
        wagonType: rc.schemaType,
        rcStats: rc.stats,
        rcRarity: rc.rarity,
        totalWeight: rc.stats.weight,
        seatsUtilized: passengers.length,
        seatsMax: rc.stats.seats,
        passengers,
      };
    }

    throw new Error(`Unknown wagon type: ${rc.schemaType}`);
  }

  getRailCarWeight(rc: CardEntity<WAX.CardAssetData_Wagon> | number) {
    if (typeof rc === "number") {
      rc = this.railCars[rc];
    }

    if (CardEntity.isTypePassengerWagon(rc)) {
      return +rc.stats.weight;
    }

    if (CardEntity.isTypeCommodityWagon(rc)) {
      const loadedCommodities = [...this.iterateLoadedCommodities(rc)] as CommodityLoadableCard[];
      return loadedCommodities.reduce((acc, commodity) => acc + +commodity?.stats.weight, 0) || 0;
    }

    throw new Error(`Unknown wagon type: ${rc.schemaType}`);
  }

  get fuelTypeLowerCase() {
    return (this.locomotive?.data.fuel?.toLowerCase() as "coal" | "diesel" | "unknown" |undefined) || null;
  }

  getInvalidReason(): string | false {
    if (!this.conductor) {
      return "You must select a conductor for a valid train composition.";
    }

    if (!this.locomotive) {
      return "You must select a locomotive for a valid train composition.";
    }

    if (this.locomotive.data.conductor_threshold > this.conductor.data.conductor_level) {
      return `Conductor is Level ${this.conductor.data.conductor_level} but your chosen Locomotive requires ${
        this.locomotive!.data.conductor_threshold
      }`;
    }

    if (this.currentTotalWeight > this.maxWeight) {
      return `Train can haul ${this.maxWeight} but your chosen commodities weigh ${this.currentTotalWeight}`;
    }

    const maxRailCars = this.extraSlots.rc + 1;
    if (this.railCars.length > maxRailCars) {
      return `Train can haul ${maxRailCars} rail cars but you have attached ${this.railCars.length}`;
    }

    for (const railcarIndex in this.railCars) {
      const railcarNumber = parseInt(railcarIndex) + 1;
      const railStats = this.getRailCarLoadStats(+railcarIndex);

      if (railStats.wagonType == "railcar") {
        if (railStats.capacityUtilized > railStats.capacityMax!) {
          return `Railcar ${railcarNumber} can haul ${railStats.capacityMax!} volume but your chosen commodities' volume is ${
            railStats.capacityUtilized
          }`;
        }
      }

      if (railStats.wagonType == "passengercar") {
        if (railStats.seatsUtilized > railStats.seatsMax) {
          return `Railcar ${railcarNumber} has ${railStats.seatsMax} seats but your have loaded ${railStats.seatsUtilized} passengers.`;
        }
      }
    }

    return false;
  }

  public *iterateEquippedCards(type: CardType) {
    for (const card of this.iterateAllEquippedCards(false)) {
      if (card.type === type) {
        yield card;
      }
    }
  }

  public *iterateAllEquippedCards(excludeCommodities: boolean = true) {
    const getCardEntity = this.$get.getCard.bind(this.$get);
    for (const asset_id of this.waxData.locomotives || []) {
      yield getCardEntity(asset_id);
    }
    for (const asset_id of this.waxData.conductors || []) {
      yield getCardEntity(asset_id);
    }
    for (const { railcar_asset_id, load_ids } of this.waxData.load || []) {
      yield getCardEntity(railcar_asset_id);
      if (excludeCommodities) {
        continue;
      }
      for (const commodity_asset_id of load_ids || []) {
        yield getCardEntity(commodity_asset_id);
      }
    }
  }

  public iterateLoadedCommodities(
    railCar: CardEntity<WAX.CardAssetData_PassengerWagon>
  ): Generator<Readonly<CardEntity<WAX.CardAssetData_PassengerLoadable>>>;
  public iterateLoadedCommodities(
    railCar: CardEntity<WAX.CardAssetData_CommodityWagon>
  ): Generator<Readonly<CardEntity<WAX.CardAssetData_CommodityLoadable>>>;
  public iterateLoadedCommodities(
    railCar: CardEntity<WAX.CardAssetData_Wagon>
  ): Generator<Readonly<CardEntity<WAX.CardAssetData_Loadable>>>;

  public *iterateLoadedCommodities(railCar: CardEntity<WAX.CardAssetData_Wagon>) {
    const getCardEntity = this.$get.getCard.bind(this.$get);
    const loadData = this.waxData.load.find(load => load.railcar_asset_id === railCar.assetId);
    if (loadData) {
      for (const commodity_asset_id of loadData.load_ids) {
        yield getCardEntity(commodity_asset_id) as LoadableCard;
      }
    } else {
      console.error("Rail car not found in train", railCar);
    }
  }

  public *iterateAllLoadedCommodities() {
    for (const railCar of this.railCars) {
      if (CardEntity.isTypeCommodityWagon(railCar)) {
        yield* this.iterateLoadedCommodities(railCar);
      }
    }
  }

  public loadCommodity(
    railCar: CardEntity<WAX.CardAssetData_Wagon>,
    commodity: CardEntity<WAX.CardAssetData_Loadable>,
    atIndex?: number | undefined
  ) {
    const loadData = this.waxData.load.find(load => load.railcar_asset_id === railCar.assetId);
    if (!loadData) {
      console.error("Rail car not found in train", railCar);
      return false;
    }
    if (atIndex == null) {
      loadData.load_ids.push(commodity.assetId);
    } else {
      loadData.load_ids.splice(atIndex, 0, commodity.assetId);
    }
    return true;
  }

  public unloadCommodity(
    railCar: CardEntity<WAX.CardAssetData_Wagon>,
    commodity: CardEntity<WAX.CardAssetData_Loadable>
  ) {
    const loadData = this.waxData.load.find(load => load.railcar_asset_id === railCar.assetId);
    if (!loadData) {
      console.error("Rail car not found in train", railCar);
      return false;
    }
    const index = loadData.load_ids.indexOf(commodity.assetId);
    if (index === -1) {
      console.error("Commodity not found in train", commodity);
      return false;
    }
    loadData.load_ids.splice(index, 1);
    return true;
  }

  public hasEquippedCard(card: CardEntity<WAX.CardData>): boolean {
    return [...this.iterateAllEquippedCards(card.type != CardType.Loadable)].some(c => c.assetId === card.assetId);
  }

  ////----////----////----////----////----////----////----////

  public clone(this: TrainEntity): TrainEntity {
    return TrainEntity.clone(this);
  }

  public static compare(a: ReadonlyObjectDeep<TrainEntity> | null, b: ReadonlyObjectDeep<TrainEntity> | null): boolean {
    return JSON.stringify(a?.waxData || null) !== JSON.stringify(b?.waxData || null);
  }

  public static clone(train: ReadonlyObjectDeep<TrainEntity>): TrainEntity {
    const waxDataCopy = JSON.parse(JSON.stringify(train.waxData));
    const clone = new TrainEntity(waxDataCopy, train.$get);
    clone.extraSlots.rc = train.extraSlots.rc;
    return clone;
  }

  public static copyDataFromTo(from: ReadonlyObjectDeep<TrainEntity>, to: TrainEntity): TrainEntity {
    Object.assign(to.waxData, JSON.parse(JSON.stringify(from.waxData)));
    return to;
  }

  public static overrideData(train: ReadonlyObjectDeep<TrainEntity>, data: Partial<WAX.TrainData>) {
    return Object.assign(train.waxData, data);
  }
}

export type RailCarLoadStats = ReturnType<TrainEntity["getRailCarLoadStats"]>;

type LoadableCard = Readonly<CardEntity<WAX.CardAssetData_Loadable>>;
type WagonCard = Readonly<CardEntity<WAX.CardAssetData_Loadable>>;
type CommodityLoadableCard = Readonly<CardEntity<WAX.CardAssetData_CommodityLoadable>>;
type CommodityWagonCard = Readonly<CardEntity<WAX.CardAssetData_CommodityWagon>>;
type PassengerLoadableCard = Readonly<CardEntity<WAX.CardAssetData_PassengerLoadable>>;
type PassengerWagonCard = Readonly<CardEntity<WAX.CardAssetData_PassengerWagon>>;
