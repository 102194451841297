import { GameSingletons } from "@game/app/GameSingletons";
import { AchievementBadgeData } from "./AchievementBadgeData";

export async function displayUnreadAchievementModals(allAchievements: AchievementBadgeData[]): Promise<void> {
  const { firebase, modals, ticker } = GameSingletons.getGameContext();
  const unreadAchievements = await getUnreadAchievement(allAchievements);

  function markAchiementAsRead(achievement: AchievementBadgeData) {
    return firebase.updateUserMainData({
      readAchievements: {
        [achievement.achievementId]: true,
      },
    });
  }

  const showDismissAllButton = unreadAchievements.length > 1;
  let dismissRemaining = false;
  for (const achievement of unreadAchievements) {
    if (!dismissRemaining) {
      dismissRemaining = await modals.achievementNotification(achievement, showDismissAllButton);
      markAchiementAsRead(achievement);
      await ticker.delay(0.25);
    } else {
      markAchiementAsRead(achievement);
      await ticker.nextFrame();
    }
  }
}

export async function getUnreadAchievement(allAchievements: AchievementBadgeData[]) {
  const { firebase } = GameSingletons.getGameContext();
  const { readAchievements = {} } = await firebase.getUserMainData();
  const unreadAchievements = allAchievements.filter(achievement => !readAchievements[achievement.achievementId]);
  return unreadAchievements;
}
