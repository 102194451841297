export enum CardType {
  Conductor = 0,
  Locomotive = 1,
  RailCar = 2,
  Loadable = 3,
}

export module CardType {
  const PRETTY_NAMES = {
    [CardType.Conductor]: "Conductor",
    [CardType.Locomotive]: "Locomotive",
    [CardType.RailCar]: "Rail Car",
    [CardType.Loadable]: "Commodity",
  };
  export function stringify(cardType: CardType): string | undefined {
    return PRETTY_NAMES[cardType];
  }
}
