const glsl = (x: TemplateStringsArray) => x.join("");
export default glsl`

precision mediump float;

varying vec2 vUvs;

uniform sampler2D uSamplerMapGround;
uniform sampler2D uSamplerMapWater;
uniform sampler2D uSamplerDiffuseMud;
uniform sampler2D uSamplerDiffuseGrass;
uniform sampler2D uSamplerDiffuseWater;
uniform sampler2D uSamplerNoises; // R: Harsh, G: Grass Dissolve, B: Soft

uniform float time;
uniform vec2 resolution;

uniform vec2 offset;

uniform float grassScale;
uniform float globalScaleFactor;
uniform float minimapScaleFactor;
uniform float minimapOffset;

uniform float alpha;

void main() {
  vec2 pos = resolution * (vUvs + offset) + minimapOffset;
  vec2 diffusePos = globalScaleFactor * grassScale * pos;

  //// //// //// //// //// //// 
  //// //// DRAW GROUND //// //// 
  //// //// //// //// //// //// 

  vec4 mudDark = texture2D(uSamplerDiffuseMud, diffusePos);
  vec4 grassDark = texture2D(uSamplerDiffuseGrass, diffusePos);
  vec4 groundNoise = texture2D(uSamplerNoises, diffusePos);

  vec4 mapGround = texture2D(uSamplerMapGround, minimapScaleFactor * pos);

  //// Do the mud
  gl_FragColor = mudDark;
  //// Add the grass
  gl_FragColor = mix(gl_FragColor, grassDark, mapGround.g); //  * (groundNoise.g * 0.5 + 0.75)
  //// Lighten the grass/mud at some parts
  gl_FragColor.rgb += mapGround.r;

  //// //// //// //// //// //// 
  //// //// DRAW WATER //// ////
  //// //// //// //// //// ////  

  vec2 tiltedPos = vec2(.175 * pos.x, .525 * pos.y);
  vec2 timeOffset = vec2(.02 * time, .005 * time);
  vec4 wavyNoise = mix(
    texture2D(uSamplerNoises, -tiltedPos - timeOffset),
    texture2D(uSamplerNoises, tiltedPos - timeOffset), 
    .5
  );
  vec2 wavyPosDiff = vec2(wavyNoise.b - 0.5, wavyNoise.b - 0.5);

  float sineWave = sin(-time + (pos.y));
  vec2 diffusePosWater = 
    .25 * pos + 
    .31 * .05 * sineWave + 
    .11 * wavyPosDiff;

  vec4 diffuseWater = texture2D(uSamplerDiffuseWater, diffusePosWater);

  vec4 mapWater = texture2D(uSamplerMapWater, minimapScaleFactor * pos + .005 * wavyPosDiff); // posWavy2);

  //// Darken the shores
  // float dk = pow(mapWater.b, .5);
  // gl_FragColor.rgb *= (1. - pow(mapWater.b, .5)); // *= (1. - mapWater.b);
  gl_FragColor.rgb -= mapWater.b * .35; // *= (1. - mapWater.b);

  //// Add the water
  gl_FragColor = mix(gl_FragColor, diffuseWater, mapWater.b);
  //// Dim the water at some parts, to add the illusion of depth
  gl_FragColor.rgb -= mapWater.r;
  //// Lighten the water at the edges for a nice shore effect
  gl_FragColor.rgb += mapWater.g * wavyNoise.r * .55;

  gl_FragColor *= alpha;
}
`;
