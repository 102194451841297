export const RPC_ENDPOINT_HOST_OPTIONS = [
  "waxapi.ledgerwise.io",
  "wax.greymass.com",
  "api.wax.alohaeos.com",
  "wax.pink.gg",
  "wax.eosphere.io",
  "wax.eoseoul.io",
  "wax.eosdac.io",
  "wax-public2.neftyblocks.com",
  "wax.api.eosnation.io",
];
const q = new URLSearchParams(location.search);

export function getRpcEndpointHost(): string {
  return q.get("rpc") || q.get("RPC") || localStorage.getItem("rpc") || RPC_ENDPOINT_HOST_OPTIONS[0];
}

export function setRpcEndpointHost(host: string): void {
  localStorage.setItem("rpc", host);
}
