import { getRpcEndpointHost } from "@sdk-integration/configuration";
import type { Chain } from "universal-authenticator-library";

export const testnetChain = {
  chainId: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12",
  name: "WAX",
  rpcEndpoints: [
    {
      protocol: "https",
      host: "api.waxtest.alohaeos.com",
      port: 443,
    },
  ],
} as Chain;

export const mainnetChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  name: "WAX",
  rpcEndpoints: [
    {
      protocol: "https",
      host: getRpcEndpointHost(),
      port: 443,
    },
  ],
} as Chain;

export default { testnetChain, mainnetChain };
