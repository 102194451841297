import { GameSingletons } from "@game/app/GameSingletons";
import { Base64Goodies } from "@game/assets/base64";
import { MIPMAP_MODES, WRAP_MODES } from "@pixi/constants";
import { Texture } from "@pixi/core";
import { map } from "@sdk/helpers/objects";
import { enchantInstance } from "@sdk/pixi/enchant/enchantInstance";
import { EnchantmentGlobals } from "@sdk/pixi/enchant/EnchantmentGlobals";
import { makeQuadMesh } from "../core/shadedLayer";
import fragmentSrc from "./glsl/ground.frag";
import vertexSrc from "./glsl/simple.vert";

const GRAY = Texture.from(Base64Goodies.gray);
 
export function makeOperationsLayerGroundQuad() {
  const { viewport, viewSize, assets } = GameSingletons.getGameContext();

  const texturePaths = {
    //DAY
     uSamplerMapGround: "assets/images/worldmap/grass.png",
     uSamplerMapWater: "assets/images/worldmap/water.png",
     uSamplerDiffuseGrass: "assets/images-webp/world/grass.webp",
     uSamplerDiffuseMud: "assets/images-webp/world/mud.webp",
     uSamplerDiffuseWater: "assets/images-webp/world/water.webp", 
     uSamplerNoises: "assets/images/world/noises.png", 
   };

  type TextureId = keyof typeof texturePaths;

  const uniforms = {
    time: 0,
    timeScale: 1.0,

    alpha: 1,

    ...map(texturePaths, () => GRAY),

    offset: [0, 0],
    resolution: [100, 100],

    grassScale: 1.0,
    globalScaleFactor: 1.0,
    minimapScaleFactor: 0.0125,
    minimapOffset: 1,
  };

  const textureIdsToMirror: TextureId[] = ["uSamplerMapGround", "uSamplerMapWater"];

  async function load() {
    async function loadTextureIndex(id: TextureId, path: string) {
      const texture = await assets.assureTextureLoaded(path);
      texture.baseTexture.mipmap = MIPMAP_MODES.OFF;
      texture.baseTexture.wrapMode = textureIdsToMirror.includes(id) ? WRAP_MODES.MIRRORED_REPEAT : WRAP_MODES.REPEAT;
      Object.assign(uniforms, { [id]: texture });
    }

    await Promise.all(Object.entries(texturePaths).map(([key, path], i) => loadTextureIndex(key as TextureId, path)));

    uniforms.grassScale *= 256 / uniforms.uSamplerDiffuseGrass.height;

    // for (const i in textureURLs) {
    //   await ticker.delay(1)
    //   await loadTextureIndex(+i);
    // }
  }
  load();

  const quad = enchantInstance(makeQuadMesh(1024, [0, 0], vertexSrc, fragmentSrc, uniforms));

  quad.onEnterFrame.add(() => {
    const timeDelta = EnchantmentGlobals.timeDelta;
    uniforms.time += uniforms.timeScale * timeDelta;

    uniforms.offset[0] = -viewport.x / 1024 / (viewSize.width / 1024);
    uniforms.offset[1] = -viewport.y / 1024 / (viewSize.height / 1024);

    const zoom = 1.25 / viewport.scaled1440p;
    uniforms.resolution = [(zoom * viewSize.width) / 1024, (zoom * viewSize.height) / 1024];

    uniforms.minimapOffset = 0.5 / uniforms.minimapScaleFactor;

    uniforms.alpha = quad.worldAlpha;
  });

  return Object.assign(quad, { uniforms });
}
