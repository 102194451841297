import { GameSingletons } from "@game/app/GameSingletons";
import { StakingAddonTier, StakingAddonType } from "@game/asorted/StakingType";
import { AccountName, AssetRarity, CardAssetId, ContractName, StationAssetId } from "@sdk-integration/contracts";
import { WaxContractActionParams } from "@sdk-integration/contracts/WaxContractActionService";
import { StakingAddonStatusData, VIPWhitelistTableRowData } from "./models";

// 1099521667660

// 1099521667568

const configurations = {
  [StakingAddonType.RailYard]: {
    tableName_Tiers: "rytiers",
    tableName_Assets: "ryassets",
    assetSchema: "locomotive",
  },
  [StakingAddonType.ConductorLounge]: {
    tableName_Tiers: "ltiers",
    tableName_Assets: "lassets",
    assetSchema: "conductor",
  },
};

type StakedAssetsInfo = Awaited<ReturnType<StakingAddonDataService["getContractData_StakedAssetsInfo"]>>[number];

export class StakingAddonDataService {
  private readonly context = GameSingletons.getGameContext();
  private readonly actions;
  private readonly tables;

  constructor(private readonly addonType: StakingAddonType) {
    const { contracts } = GameSingletons.getGameContext();

    const { actions, tables } = contracts;
    this.actions = actions;
    this.tables = tables;
  }

  private readonly tableName_Tiers = configurations[this.addonType].tableName_Tiers;
  private readonly tableName_Assets = configurations[this.addonType].tableName_Assets;
  private readonly assetSchema = configurations[this.addonType].assetSchema;

  ////

  async getContractData_StationInfo(stationId: StationAssetId) {
    const { contracts } = this.context;
    const stationRows = await this.tables.loadRows<{
      rarity: string;
      asset_id: string;
      railyard_tier: StakingAddonTier | 0;
      lounge_tier: StakingAddonTier | 0;
      railyard_comm: number;
      lounge_comm: number;
      railyard_comm_vip: number;
      lounge_comm_vip: number;
    }>(
      "stations",
      {
        scope: contracts.currentCenturyName,
        limit: 1,
        lower_bound: stationId,
        upper_bound: stationId,
      },
      ContractName.S
    );
    return stationRows[0];
  }

  async getContractData_StakedAssetsInfo(stationId: StationAssetId) {
    const tableName = this.tableName_Assets;
    const stakedAssetsRows = await this.tables.loadRows<{
      asset_id: any;
      owner: AccountName;
      template_id: number;
      rarity: string;
      staked_time: number;
      last_claimed: number;
      expire_time: number;
      commission: number;
    }>(
      tableName,
      {
        scope: stationId,
        limit: 1000,
      },
      ContractName.S
    );
    return stakedAssetsRows;
  }

  ////

  async getContractData_TiersInfo() {
    const { contracts } = this.context;
    const tableName = this.tableName_Tiers;
    const tierInfoRows = await this.tables.loadRows<{
      tier: number;
      vip_spots: number;
      public_spots: number;
      costs: string[];
      rarities: string[];
    }>(
      tableName,
      {
        scope: contracts.currentCenturyName,
        limit: 10,
      },
      ContractName.S
    );
    return tierInfoRows;
  }

  async getContractData_TierInfo(tier: StakingAddonTier) {
    const tierInfoRows = await this.getContractData_TiersInfo();
    const nextTierRow = tierInfoRows.find(row => row.tier === tier);
    if (!nextTierRow) {
      throw new Error(`Could not find tier ${tier}`);
    }
    return nextTierRow;
  }

  async getContractData_VipWhitelist(stationId: StationAssetId) {
    const stakedAssetsRows = await this.tables.loadRows<VIPWhitelistTableRowData>(
      "vip",
      {
        scope: stationId,
        limit: 1000,
      },
      ContractName.S
    );
    return stakedAssetsRows;
  }

  async getAddonStatusData(stationId: StationAssetId): Promise<StakingAddonStatusData> {
    const stationInfo = await this.getContractData_StationInfo(stationId);
    const tier = this.addonType === StakingAddonType.RailYard ? stationInfo.railyard_tier : stationInfo.lounge_tier;

    if (tier === 0) {
      const tierInfo = await this.getContractData_TierInfo(tier + 1);
      return {
        type: this.addonType,
        nextCost: tierInfo.costs[0],
        unlocked: false,
      };
    }

    const { vipSpotsUsed, publicSpotsUsed } = await this.getAllStakedCardsData(stationId);
    const tierInfo = await this.getContractData_TierInfo(tier);
    const openSpots = tierInfo.vip_spots - vipSpotsUsed.length + tierInfo.public_spots - publicSpotsUsed.length;
    const commissionRate = this.addonType === "railYard" ? stationInfo.railyard_comm : stationInfo.lounge_comm;
    const commissionRateVip =
      this.addonType === "railYard" ? stationInfo.railyard_comm_vip : stationInfo.lounge_comm_vip;

    const vipWhitelist = await this.getContractData_VipWhitelist(stationId);

    return {
      type: this.addonType,
      tier: tier,
      openSpots: openSpots,
      vipSpotsUsed: vipSpotsUsed.length,
      vipSpotsMax: tierInfo.vip_spots,
      publicSpotsUsed: publicSpotsUsed.length,
      publicSpotsMax: tierInfo.public_spots,
      commissionRate: commissionRate,
      commissionRateVip: commissionRateVip,
      publicStakedAssets: publicSpotsUsed,
      vipStakedAssets: vipSpotsUsed,
      vipWhitelist: vipWhitelist,
      unlocked: true,
    };
  }

  async getAllStakedCardsData(stationId: StationAssetId) {
    const stakedAssetsInfo = await this.getContractData_StakedAssetsInfo(stationId);
    const vipSpotsUsed = [] as StakedAssetsInfo[];
    const publicSpotsUsed = [] as StakedAssetsInfo[];

    function isExpired(asset: StakedAssetsInfo) {
      return asset.expire_time * 1000 < new Date().getTime();
    }

    console.log({ stakedAssetsInfo }, this.addonType);

    for (const stakedAsset of stakedAssetsInfo) {
      if (stakedAsset.expire_time == 0) {
        vipSpotsUsed.push(stakedAsset);
      } else if (!isExpired(stakedAsset)) {
        publicSpotsUsed.push(stakedAsset);
      }
    }

    return { vipSpotsUsed, publicSpotsUsed };
  }

  async getStakedCardsData(stationId: StationAssetId, vip: boolean) {
    const { vipSpotsUsed, publicSpotsUsed } = await this.getAllStakedCardsData(stationId);
    const stakedAssets = vip ? vipSpotsUsed : publicSpotsUsed;
    return stakedAssets;
  }

  async getAssetCommissionRates() {
    const { contracts } = this.context;
    const rateRows = await this.tables.loadRows<{
      schema: string;
      payouts: { key: string; value: number }[];
    }>(
      "rates",
      {
        scope: contracts.currentCenturyName,
        limit: 1000,
      },
      ContractName.S
    );
    const rowForThisAddon = rateRows.find(row => row.schema == this.assetSchema);
    if (!rowForThisAddon) {
      throw new Error(`Could not find commission rates for ${this.assetSchema}`);
    }
    return rowForThisAddon.payouts.reduce((acc, { key, value }) => {
      acc[key.toLowerCase() as AssetRarity] = value * 0.0001;
      return acc;
    }, {} as { [key: AssetRarity]: number });
  }

  async getUnclaimedTociumAmount() {
    // const tableName = "balances";
    // const claimableTocium = await this.tables.loadRows<{
    //   account: string;
    //   owed: number;
    // }>(
    //   tableName,
    //   {
    //     scope: ContractName.S,
    //     lower_bound: this.context.userData.name,
    //     upper_bound: this.context.userData.name,
    //     limit: 1,
    //   },
    //   ContractName.S
    // );
    // return claimableTocium[0].owed;
  }

  /// -- /// -- ///

  async unlockStakingAddon(stationId: StationAssetId) {
    const stationInfo = await this.getContractData_StationInfo(stationId);
    const currentTier = this.addonType === "railYard" ? stationInfo.railyard_tier : stationInfo.lounge_tier;
    const nextTier = (currentTier + 1) as StakingAddonTier;

    const { contracts } = this.context;

    const schema = configurations[this.addonType].assetSchema;
    const nextTierRow = await this.getContractData_TierInfo(nextTier);

    const { tier, costs } = nextTierRow;

    const userName = this.context.contracts.currentUserName;

    await this.actions.performActionTransactions([
      [
        "transfer",
        {
          from: userName,
          to: ContractName.M,
          quantity: costs[0],
          memo: "DEPOSIT|" + userName,
        },
        ContractName.Toc,
      ],
      [
        "unlocktier",
        {
          owner: userName,
          century: contracts.currentCenturyName,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }

  async upgradeStakingAddon(stationId: StationAssetId) {
    const stationInfo = await this.getContractData_StationInfo(stationId);
    const currentTier = this.addonType === "railYard" ? stationInfo.railyard_tier : stationInfo.lounge_tier;
    const nextTier = (currentTier + 1) as StakingAddonTier;

    const { contracts } = this.context;

    const schema = this.assetSchema;
    const nextTierRow = await this.getContractData_TierInfo(nextTier);

    const { tier, costs } = nextTierRow;

    const userName = this.context.contracts.currentUserName;

    return await this.actions.performActionTransactions([
      [
        "transfer",
        {
          from: userName,
          to: ContractName.M,
          quantity: costs[0],
          memo: "DEPOSIT|" + userName,
        },
        ContractName.Toc,
      ],
      [
        "unlocktier",
        {
          owner: userName,
          century: contracts.currentCenturyName,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }

  async setCommissionRates(commissionRate: number, commissionRateVip: number, stationId: StationAssetId) {
    const schema = this.assetSchema;
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;

    return await this.actions.performActionTransactions([
      [
        "setcomm",
        {
          owner: userName,
          century: century,
          schema: schema,
          station_id: stationId,
          rate: commissionRate,
          vip_comm: false,
        },
        ContractName.S,
      ],
      [
        "setcomm",
        {
          owner: userName,
          century: century,
          schema: schema,
          station_id: stationId,
          rate: commissionRateVip,
          vip_comm: true,
        },
        ContractName.S,
      ],
    ]);
  }

  async stakeCard(cardAssetId: CardAssetId, stationId: StationAssetId, vip: boolean) {
    const { mapData } = this.context;
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;

    const actions: WaxContractActionParams[] = [
      [
        "transfer",
        {
          from: userName,
          to: ContractName.S,
          asset_ids: [cardAssetId],
          memo: `${stationId}|${century}|${vip ? 1 : 0}`,
        },
        ContractName.AtomicAssets,
      ],
    ];

    if (vip) {
      const stationOwnerName = mapData.stations.get(stationId)?.ownerName;
      const isStationMine = stationOwnerName === userName;
      if (isStationMine) {
        let myVipSpots = 0;

        const vipRows = await this.getContractData_VipWhitelist(stationId);
        const myRow = vipRows.find(row => row.railroader === userName);
        if (myRow) {
          myVipSpots = this.addonType === "railYard" ? myRow.railyard_spots : myRow.lounge_spots;
        }

        myVipSpots++;

        const schema = this.assetSchema;
        actions.unshift([
          "setvip",
          {
            owner: userName,
            railroader: userName,
            century: century,
            schema: schema,
            station_id: stationId,
            spots: myVipSpots,
          },
          ContractName.S,
        ]);
      }
    }

    return await this.actions.performActionTransactions(actions);
  }

  async unstakeCard(cardAssetId: CardAssetId, railroader?: string) {
    const { contracts } = this.context;

    railroader ||= contracts.currentUserName;

    return await this.actions.performActionTransactions([
      [
        "unstake",
        {
          railroader: railroader,
          asset_id: cardAssetId,
        },
        ContractName.S,
      ],
    ]);
  }

  async getVIPWhitelist(stationId: StationAssetId) {
    const tableName = "vip";
    const tableRows = await this.tables.loadRows<{
      railroader: string;
      lounge_spots: number;
      railyard_spots: number;
    }>(
      tableName,
      {
        scope: stationId,
        limit: 1000,
      },
      ContractName.S
    );

    const stakedAssetsInfo = await this.getContractData_StakedAssetsInfo(stationId);

    const result = tableRows.map(row => {
      const spotsUsed = stakedAssetsInfo.filter(o => o.owner === row.railroader && o.expire_time == 0);
      return {
        railroader: row.railroader,
        spotsUsed: spotsUsed.length,
        spotsAllowed: this.addonType === "railYard" ? row.railyard_spots : row.lounge_spots,
        hourlyCommission: NaN,
      };
    });

    return result;
  }

  async setToVIPWhitelistSpots(railroaderName: string, stationId: StationAssetId, spotsCount: number) {
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;
    const schema = this.assetSchema;
    return await this.actions.performActionTransactions([
      [
        "setvip",
        {
          owner: userName,
          railroader: railroaderName,
          century: century,
          schema: schema,
          station_id: stationId,
          spots: spotsCount,
        },
        ContractName.S,
      ],
    ]);
  }

  async removeUserFromVIPWhitelist(railroaderName: string, stationId: StationAssetId) {
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;
    const schema = this.assetSchema;

    return await this.actions.performActionTransactions([
      [
        "kickvip", // name owner, name railroader, name century, name schema, uint64_t station_id
        {
          owner: userName,
          railroader: railroaderName,
          century: century,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }

  async removeCardFromVIPWhitelist(cardAssetId: CardAssetId, railroaderName: string, stationId: StationAssetId) {
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;
    const schema = this.assetSchema;

    return await this.actions.performActionTransactions([
      [
        "kickvipasset",
        {
          asset_id: cardAssetId,
          owner: userName,
          railroader: railroaderName,
          century: century,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }

  async claimStationOwnerTociumEarns(stationId: StationAssetId) {
    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;
    const schema = this.assetSchema;

    return await this.actions.performActionTransactions([
      [
        "soclaim",
        {
          owner: userName,
          railroader: userName,
          century: century,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }

  async claimStakerTociumEarns(stationId: StationAssetId) {
    const station = this.context.mapData.stations.get(stationId);
    if (!station) {
      throw new Error("Station not found");
    }

    const century = this.context.contracts.currentCenturyName;
    const userName = this.context.contracts.currentUserName;
    const schema = this.assetSchema;

    return await this.actions.performActionTransactions([
      [
        "rrclaim",
        {
          railroader: userName,
          station_owner: station.ownerName,
          century: century,
          schema: schema,
          station_id: stationId,
        },
        ContractName.S,
      ],
    ]);
  }
}

export type IStakeHubDataService = Readonly<StakingAddonDataService>;

export function getStakingAddonDataService(addonType: StakingAddonType): IStakeHubDataService {
  return new StakingAddonDataService(addonType);
}
