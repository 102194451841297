import {GameContext} from "@game/app/app";
import {copycat} from "@game/asorted/copycat";
import {Container} from "@pixi/display";
import {makeScreenLayer} from "@sdk/pixi/augmentations/makeScreenLayer";
import {WorldLayer} from "../WorldLayer";
import {makeRegionLabels} from "./content/makeRegionLabels";
import {makeRiverLabels} from "./content/makeRiverLabels";
import {makeRailPaths} from "./content/makeRailPaths";
import {makeThomasRegionMarkers} from "./content/makeThomasRegionMarkers";
import {makeRegionsLayerGroundQuad} from "./makeRegionsLayerGroundQuad";
import {makeOngoingRunTracks} from "./content/makeOngoingRunTracks";
import {GameSingletons} from "@game/app/GameSingletons";
import {makeCulpritRegionMarkers} from "./content/makeCulpritRegionMarkers";


export function makeRegionsWorldLayer() {
  const {mapData, userData} = GameSingletons.getGameContext();


  if (!mapData) {
    throw new Error("no map data");
  }

  const layer = new WorldLayer();
  layer.name = "WorldLayer//2";

  const isLayerFullyyVisible = () => layer.alpha >= 0.9999;

  // // //// //// //// ////
  // // Make Ground Layer ////
  // // //// //// //// ////

  const ground = makeRegionsLayerGroundQuad();
  makeScreenLayer(ground);
  copycat(ground, layer, ["alpha", "visible", "renderable"]);

  layer.addChild(ground);



  // // //// //// //// //// ////
  // // Make Thomas Heads ////
  // // //// //// //// //// ////

  const thomasRegionMarkers = makeThomasRegionMarkers();
  layer.addChild(thomasRegionMarkers);
  thomasRegionMarkers.enchantments.watch.array(() => userData.thomasActiveRegions, thomasActiveRegions => {
    console.log(`Thomas Active Regions change detected: ${thomasActiveRegions}`);
    thomasRegionMarkers.removeThomasHeads();
    if (thomasActiveRegions.length) {
      thomasRegionMarkers.addThomasHeads(thomasActiveRegions);
      if (isLayerFullyyVisible()) {
        thomasRegionMarkers.playShowAnimation();
      } else {
        thomasRegionMarkers.instantlyHideAllHeads();
      }
    }
  }, true);
  thomasRegionMarkers.enchantments.watch(isLayerFullyyVisible, fullyVisible => {
    if (fullyVisible) {
      thomasRegionMarkers.playShowAnimation();
    } else {
      thomasRegionMarkers.playHideAnimation();
    }
  });

  // // //// //// //// //// ////
  // // Make Culprit Heads ////
  // // //// //// //// //// ////

  const culpritRegionMarkers = makeCulpritRegionMarkers();
  layer.addChild(culpritRegionMarkers);
  culpritRegionMarkers.enchantments.watch.array(() => userData.culpritActiveRegions, culpritActiveRegions => {
    console.log(`culprit Active Regions change detected: ${culpritActiveRegions}`);
    culpritRegionMarkers.removeCulpritHeads();
    if (culpritActiveRegions.length) {
      culpritRegionMarkers.addCulpritHeads(culpritActiveRegions);
      if (isLayerFullyyVisible()) {
        culpritRegionMarkers.playShowAnimation();
      } else {
        culpritRegionMarkers.instantlyHideAllHeads();
      }
    }
  }, true);
  culpritRegionMarkers.enchantments.watch(isLayerFullyyVisible, fullyVisible => {
    if (fullyVisible) {
      culpritRegionMarkers.playShowAnimation();
    } else {
      culpritRegionMarkers.playHideAnimation();
    }
  });

  // // //// //// //// //// ////
  // // Make Rail Paths ////
  // // //// //// //// //// ////

  const riverLablesContainer = makeRiverLabels();
  layer.addChild(riverLablesContainer);

  // // //// //// //// //// ////
  // // Make Region Labels ////
  // // //// //// //// //// ////

  const regionLablesContainer = makeRegionLabels();
  layer.addChild(regionLablesContainer);
  copycat(regionLablesContainer, layer, ["alpha", "visible", "renderable"]);

  // // //// //// //// //// ////
  // // Ongoing Run Tracks ////
  // // //// //// //// //// ////

  const ongoingRunTracks = makeOngoingRunTracks();
  layer.addChild(ongoingRunTracks);

  // // //// //// //// //// ////
  // // //// //// //// //// ////
  // // //// //// //// //// ////

  return Object.assign(layer, {
    ground,
    // railPaths,
    thomasRegionMarkers,
    culpritRegionMarkers,
    regionLablesContainer,
    riverLablesContainer,
    uiElementsContainer: layer.addChild(new Container())
  });
}
