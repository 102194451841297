import { BuildingEntity } from "@game/data/entities/BuildingEntity";
import { CardEntity } from "@game/data/entities/CardEntity";
import { StationEntity } from "@game/data/entities/StationEntity";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { GameContext } from "../app";
import { GameSingletons } from "../GameSingletons";

export class SelectionManager {
  private readonly context = GameSingletons.getGameContext();

  //// Trains

  selectedTrain = null as null | TrainEntity;

  //// Buildings
  selectedBuilding = null as null | BuildingEntity;

  //// Stations

  selectedStation = null as null | StationEntity;

  selectedDestination = null as null | StationEntity;

  hoverStation = null as null | StationEntity;

  get selectedTrainCurrentStation() {
    if (!this.selectedTrain) return null;
    if (!this.selectedTrain.currentStationId) return null;
    return this.context.mapData.stations.get(this.selectedTrain.currentStationId) || null;
  }

  isViablelDestination(station: StationEntity | StationEntity["assetId"]) {
    if (typeof station !== "string" && typeof station !== "number") {
      station = station.assetId;
    }
    return !!this.selectedStation?.getLinkTo(station);
  }

  hypotheticalTrainEntity = null as null | TrainEntity;

  //// Cards (for edit train & stuff)

  selectedCard = null as null | CardEntity;
  hoverCard = null as null | CardEntity;

  constructor() {
    this.context.stage.enchantments.watch.array(
      () => [this.selectedTrain, this.hoverCard] as const,
      ([selectedTrain, hoverCard,]) => {

        if (selectedTrain && hoverCard) {
          this.hypotheticalTrainEntity = TrainEntity.clone(selectedTrain);
          // equipTrainCard(state.hypotheticalTrainEntity, hoverCard);
        } else {
          this.hypotheticalTrainEntity = null;
        }
      },
      true
    );
  }

  clear() {
    
    this.hoverStation = null;
    this.selectedStation = null;
    this.selectedDestination = null;
    this.selectedTrain = null;
  }
}
