import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { CullingArea } from "../core/cullingArea";
import { WorldLayer } from "../WorldLayer";

export function makeOperationsLayerCullingService(layer: WorldLayer) {
  const { viewport, viewSize } = GameSingletons.getGameContext();

  const TOLERANCE_BASE = 0;
  const cullingArea = new CullingArea();
  const cullingAreaMaxSize = 32_000;
  const updateCullingAreaAccordingToViewport = () => {
    const viewBounds = viewport.getVisibleBounds1440p();
    const vmax = viewBounds.width > viewBounds.height ? viewBounds.width : viewBounds.height;
    const varea = viewBounds.width * viewBounds.height;
    if (varea > cullingAreaMaxSize * cullingAreaMaxSize) {
      const scale = cullingAreaMaxSize / vmax;
      viewBounds.x += (viewBounds.width * (1 - scale)) / 2;
      viewBounds.y += (viewBounds.height * (1 - scale)) / 2;
      viewBounds.width *= scale;
      viewBounds.height *= scale;
      cullingArea.tolerance = [0, 0, 0, 0];
    } else {
      const TOLERANCE = TOLERANCE_BASE / viewport.scaled;
      cullingArea.tolerance = [TOLERANCE, TOLERANCE, TOLERANCE, TOLERANCE];
    }
    cullingArea.updateArea(viewBounds);
  };
  layer.onEnterFrame.add(updateCullingAreaAccordingToViewport);

  function registerContainerForChildrenCulling(container: Container) {
    const onEnterFrame = () => {
      if (!container.visible) return;
      if (container.worldAlpha <= 0) return;
      for (const child of container.children) {
        child.visible = !service.enabled || cullingArea.isInView(child);
      }
    };
    layer.onEnterFrame.add(onEnterFrame);
  }

  const service = {
    enabled: true,
    cullingArea,
    registerContainerForChildrenCulling,
  };

  return service;
}
