import { AirtableRowData } from "./AirtableRowData";

const url = "https://api.airtable.com/v0/appUU7HZ663bxMgam/New%20NPC%20Cinematics";
const token = "keyxdhyQgb707ovJy";

let cached: null | AirtableRowData[] = null;

export async function loadAirtableStuff() {
  if (cached == null) {
    const r = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const airtableData = (await r.json()) as { records: AirtableRowData[] };
    cached = airtableData.records;
  }
  return cached;
}

export async function loadCinematicsList() {
  const records = await loadAirtableStuff();
  const cinematicNames = records.map(o => {
    const { manifest, cinematicData } = extractManifestAndCinematicData(o);
    return {
      id: o.id,
      name: o.fields.Name,
      manifest,
      cinematicData,
    };
  });
  return cinematicNames;
}

export async function loadCinematicDataByName(name: string) {
  const records = await loadAirtableStuff();
  const record = records.find(rec => rec.fields.Name == name);
  if (!record) throw new Error(`Record not found: ${name}`);
  return extractManifestAndCinematicData(record);
}

export async function loadCinematicDataById(id: string) {
  const records = await loadAirtableStuff();
  const record = records.find(rec => rec.id == id);
  if (!record) throw new Error(`Record not found: ${name}`);
  return extractManifestAndCinematicData(record);
}

function extractManifestAndCinematicData(record: AirtableRowData) {
  const rowData = record.fields;

  const cinematicData = JSON.parse(String(rowData.JSON));

  const manifest = { assetsNamed: {} as Record<string, string> };
  for (const attachment of rowData.Attachments) {
    const filename = attachment.filename as string;
    const url = attachment.url;
    manifest.assetsNamed[filename] = url;
  }

  return {
    manifest,
    cinematicData,
  };
}
