import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Color } from "@sdk/utils/color/Color";
import { AchievementBadgeData } from "./AchievementBadgeData";

export class AchievementBadge extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  private badge?: Sprite;

  info?: AchievementBadgeData;

  setImage(textureId: string) {
    this.badge?.destroy();
    this.badge = this.factory.createSprite(textureId);
    this.addChild(this.badge);
  }

  setBrightness(brightness: number) {
    if (this.badge) {
      const color = new Color(0xffffff);
      color.r *= brightness;
      color.g *= brightness;
      color.b *= brightness;
      this.badge.tint = color.toInt();
    }
  }
}

export module AchievementBadge {
  export function create(info: AchievementBadgeData) {
    const achievementBadge = new AchievementBadge();
    achievementBadge.setImage(info.textureId);
    achievementBadge.info = info;
    return achievementBadge;
  }
}
