import {GameSingletons} from "@game/app/GameSingletons";
import {ViewSize} from "@game/app/services/ViewSize";
import {EnchantedContainer} from "@game/core/enchanted-classes";
import {IPointData} from "@pixi/math";
import {DisplayObject} from "@pixi/display";
import {lerp} from "@sdk/utils/math";

const dimensions: {[key:string]:{width:number,
  scale:number}} = {
    "1920": {
      width: 1920,
      scale: 1.125
    },
    "1750": {
      width: 1750,
      scale: 1
    },
    "1680": {
      width: 1680,
      scale: .975
    },
    "1575": {
      width: 1575,
      scale: .915
    },
    "1540": {
      width: 1540,
      scale: .9
    },
    "1500": {
      width: 1500,
      scale: .875
    },
    "1440": {
      width: 1440,
      scale: 0.835
    },
    "1400": {
      width: 1440,
      scale: 0.8
    },
    "1368": {
      width: 1368,
      scale: 0.7975
    },
    "1280": {
      width: 1280,
      scale: 0.75
    },
    "1250": {
      width: 1250,
      scale: 0.73
    },
    "1180": {//iPad Air
      width: 1180,
      scale: 0.6875
    }, 
    "1150": {
      width: 1150,
      scale: 0.68
    },
    "1080": {
      width: 1080,
      scale: 0.66
    },
    "1024": {//iPad Mini
      width: 1024,
      scale: .5975
    },
    "980": {
      width: 980,
      scale: 0.585
    },
    "915": { //Galaxy S20 Ultra
      width: 915,
      scale: 0.5325
    },
    "896": { //iPhone XR
      width: 896,
      scale: 0.519
    },
    "851": { //pixel 5
      width: 851,
      scale: 0.495
    },
    "844": { //iPhone 12 Pro
      width: 840,
      scale: 0.49
    },
    "740": { //Galaxy S8+
      width: 740,
      scale: 0.429
    },
    "720": { //Surface Duo
      width: 720,
      scale: 0.4175
    },
    "667": { //iPhone SE
      width: 667,
      scale: 0.39
    },
    "560": {
      width: 720,
      scale: 0.325
    }
  }

export function createItemContainer(screenAnchor: IPointData, screenMargin: IPointData, spriteWidth: number, spriteHeight ?:number, fixed ?:boolean) {
  const {events, viewSize, ticker} = GameSingletons.getGameContext();

  const container = new EnchantedContainer();
  container.zIndex = 100;
 
  

  function updateIndicatorPositions({width, height, vmin} : ViewSize, fixed ?:boolean) {


    let closestWidth = 0;
    let closestWidthDiff = Infinity;

    for (const key in dimensions) {
      const currentWidth = Number(key);
      const diff = Math.abs(width - currentWidth);
      if (diff < closestWidthDiff) {
        closestWidth = currentWidth;
        closestWidthDiff = diff;
      }
    }

    const dims = dimensions[closestWidth];
    // If its not a fixed item
    if (fixed !== true) {
      if (! spriteHeight) {
        container.position.set(((screenMargin.x *dims.scale) + (width - (screenMargin.x *dims.scale) - (screenMargin.x *dims.scale)) * screenAnchor.x) - ((spriteWidth * dims.scale) / 2), (screenMargin.y + (height - screenMargin.y - screenMargin.y) * screenAnchor.y));
      } else {
        container.position.set(((screenMargin.x *dims.scale) + (width - (screenMargin.x *dims.scale) - (screenMargin.x *dims.scale)) * screenAnchor.x) - ((spriteWidth * dims.scale) / 2), (screenMargin.y + (height - screenMargin.y - screenMargin.y) * screenAnchor.y) - (spriteHeight * dims.scale) / 2);
      }

      const scale = dims.scale;
      container.scale.set(scale);
    } else {
      container.position.set((screenMargin.x + screenAnchor.x), (screenMargin.y + screenAnchor.y));

      const scale = dims.scale;
      container.scale.set(scale);
    }
  }
  
  events.on({resize: updateIndicatorPositions});
  updateIndicatorPositions(viewSize, fixed);

  return Object.assign(container, {screenAnchor, screenMargin, updateIndicatorPositions});
}

export function scaleItem() {
  const {events, viewSize} = GameSingletons.getGameContext();

  const container = new EnchantedContainer();
  container.zIndex = 100;
 

  function updateScale({width, height, vmin} : ViewSize, fixed ?:boolean) {

    let closestWidth = 0;
    let closestWidthDiff = Infinity;

    for (const key in dimensions) {
      const currentWidth = Number(key);
      const diff = Math.abs(width - currentWidth);
      if (diff < closestWidthDiff) {
        closestWidth = currentWidth;
        closestWidthDiff = diff;
      }
    }
    const dims = dimensions[closestWidth];
    // If its not a fixed item
      const scale = dims.scale;
      container.scale.set(scale);
  
  }

  events.on({resize: updateScale});
  updateScale(viewSize);

  return Object.assign(container, {updateScale});
}

export function scaleArcade(xValue:number, yValue:number) {
  const {events, viewSize} = GameSingletons.getGameContext();

  const container = new EnchantedContainer();
  container.zIndex = 100;
 
container.position.set(xValue,yValue);
  function updateScale({width, height, vmin} : ViewSize, fixed ?:boolean) {

    let closestWidth = 0;
    let closestWidthDiff = Infinity;

    for (const key in dimensions) {
      const currentWidth = Number(key);
      const diff = Math.abs(width - currentWidth);
      if (diff < closestWidthDiff) {
        closestWidth = currentWidth;
        closestWidthDiff = diff;
      }
    }
    const dims = dimensions[closestWidth];
    // If its not a fixed item
      const scale = dims.scale;
      container.scale.set(scale);
      container.position.set(xValue*scale,yValue*scale);
    
  }

  events.on({resize: updateScale});
  updateScale(viewSize);

  return Object.assign(container, {updateScale});
}



export function addPairedPointerdownListeners(items: {name:string, itemSprite:DisplayObject}[], callback: (itemSprites: DisplayObject[]) => void) {
  const pairedItems: {name:string,
    itemSprites:DisplayObject[]}[] = [];

  items.forEach(item => {
    const pairedItem = pairedItems.find(pairedItem => pairedItem.name === item.name);
    if (pairedItem) {
      pairedItem.itemSprites.push(item.itemSprite);
    } else {
      pairedItems.push({
        name: item.name,
        itemSprites: [item.itemSprite]
      });
    }
  });

  pairedItems.forEach(pairedItem => {
    pairedItem.itemSprites.forEach(itemSprite => {
      itemSprite.interactive = true;
      itemSprite.buttonMode = true;
      itemSprite.on("pointerdown", () => callback(pairedItem.itemSprites));
    });
  });
}
