export const MockGameConfiguration = {
  trainPriceBase: 5000,
  trainPriceCap: 500000,
  simpleAssetsStatsTable: {
    RAD: {
      id: "100000000073082",
      supplyAmount: 25,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Radiator",
        img: "",
      },
      tableData: {
        supply: "25 RAD",
        max_supply: "100000000000000 RAD",
        issuer: "m.century",
        id: "100000000073082",
        authorctrl: 1,
        data: '{"name":"Radiator","img":""}',
      },
    },
    AMP: {
      id: "100000000074430",
      supplyAmount: 1215,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Anomatic Particle",
        img: "",
      },
      tableData: {
        supply: "1215 AMP",
        max_supply: "100000000000000 AMP",
        issuer: "m.century",
        id: "100000000074430",
        authorctrl: 1,
        data: '{"name":"Anomatic Particle","img":""}',
      },
    },
    COAL: {
      id: "100000000013157",
      supplyAmount: 93186.7774,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Coal",
        img: "",
      },
      tableData: {
        supply: "93186.7774 COAL",
        max_supply: "100000000000000.0000 COAL",
        issuer: "m.century",
        id: "100000000013157",
        authorctrl: 1,
        data: '{"name":"Coal","img":""}',
      },
    },
    BATT: {
      id: "100000000074433",
      supplyAmount: 3,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Batteries",
        img: "",
      },
      tableData: {
        supply: "3 BATT",
        max_supply: "100000000000000 BATT",
        issuer: "m.century",
        id: "100000000074433",
        authorctrl: 1,
        data: '{"name":"Batteries","img":""}',
      },
    },
    DRIVE: {
      id: "100000000074436",
      supplyAmount: 6,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Drive Shaft",
        img: "",
      },
      tableData: {
        supply: "6 DRIVE",
        max_supply: "100000000000000 DRIVE",
        issuer: "m.century",
        id: "100000000074436",
        authorctrl: 1,
        data: '{"name":"Drive Shaft","img":""}',
      },
    },
    TRUCK: {
      id: "100000000074441",
      supplyAmount: 2,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Truck Frame",
        img: "",
      },
      tableData: {
        supply: "2 TRUCK",
        max_supply: "100000000000000 TRUCK",
        issuer: "m.century",
        id: "100000000074441",
        authorctrl: 1,
        data: '{"name":"Truck Frame","img":""}',
      },
    },
    FTANK: {
      id: "100000000074434",
      supplyAmount: 3,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Fuel Tank",
        img: "",
      },
      tableData: {
        supply: "3 FTANK",
        max_supply: "100000000000000 FTANK",
        issuer: "m.century",
        id: "100000000074434",
        authorctrl: 1,
        data: '{"name":"Fuel Tank","img":""}',
      },
    },
    TURBO: {
      id: "100000000074445",
      supplyAmount: 0,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Turbo Charger",
        img: "",
      },
      tableData: {
        supply: "0 TURBO",
        max_supply: "100000000000000 TURBO",
        issuer: "m.century",
        id: "100000000074445",
        authorctrl: 1,
        data: '{"name":"Turbo Charger","img":""}',
      },
    },
    ALTER: {
      id: "100000000074438",
      supplyAmount: 2,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Main Alternator",
        img: "",
      },
      tableData: {
        supply: "2 ALTER",
        max_supply: "100000000000000 ALTER",
        issuer: "m.century",
        id: "100000000074438",
        authorctrl: 1,
        data: '{"name":"Main Alternator","img":""}',
      },
    },
    POWER: {
      id: "100000000074446",
      supplyAmount: 87,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Power Rectifier",
        img: "",
      },
      tableData: {
        supply: "87 POWER",
        max_supply: "100000000000000 POWER",
        issuer: "m.century",
        id: "100000000074446",
        authorctrl: 1,
        data: '{"name":"Power Rectifier","img":""}',
      },
    },
    ENGINE: {
      id: "100000000074447",
      supplyAmount: 0,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Engine",
        img: "",
      },
      tableData: {
        supply: "0 ENGINE",
        max_supply: "100000000000000 ENGINE",
        issuer: "m.century",
        id: "100000000074447",
        authorctrl: 1,
        data: '{"name":"Engine","img":""}',
      },
    },
    DIESEL: {
      id: "100000000013158",
      supplyAmount: 164801.3035,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Diesel",
        img: "",
      },
      tableData: {
        supply: "164801.3035 DIESEL",
        max_supply: "100000000000000.0000 DIESEL",
        issuer: "m.century",
        id: "100000000013158",
        authorctrl: 1,
        data: '{"name":"Diesel","img":""}',
      },
    },
    RADFAN: {
      id: "100000000074431",
      supplyAmount: 2,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Radiator Fan",
        img: "",
      },
      tableData: {
        supply: "2 RADFAN",
        max_supply: "100000000000000 RADFAN",
        issuer: "m.century",
        id: "100000000074431",
        authorctrl: 1,
        data: '{"name":"Radiator Fan","img":""}',
      },
    },
    TRACMO: {
      id: "100000000074442",
      supplyAmount: 2,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Traction Motor",
        img: "",
      },
      tableData: {
        supply: "2 TRACMO",
        max_supply: "100000000000000 TRACMO",
        issuer: "m.century",
        id: "100000000074442",
        authorctrl: 1,
        data: '{"name":"Traction Motor","img":""}',
      },
    },
    WHEELS: {
      id: "100000000074439",
      supplyAmount: 3,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Wheels",
        img: "",
      },
      tableData: {
        supply: "3 WHEELS",
        max_supply: "100000000000000 WHEELS",
        issuer: "m.century",
        id: "100000000074439",
        authorctrl: 1,
        data: '{"name":"Wheels","img":""}',
      },
    },
    AUXALT: {
      id: "100000000074443",
      supplyAmount: 4,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Auxiliary Alternator",
        img: "",
      },
      tableData: {
        supply: "4 AUXALT",
        max_supply: "100000000000000 AUXALT",
        issuer: "m.century",
        id: "100000000074443",
        authorctrl: 1,
        data: '{"name":"Auxiliary Alternator","img":""}',
      },
    },
    AIRREZ: {
      id: "100000000074435",
      supplyAmount: 8,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Air Reservoirs",
        img: "",
      },
      tableData: {
        supply: "8 AIRREZ",
        max_supply: "100000000000000 AIRREZ",
        issuer: "m.century",
        id: "100000000074435",
        authorctrl: 1,
        data: '{"name":"Air Reservoirs","img":""}',
      },
    },
    ELECTRO: {
      id: "100000000074440",
      supplyAmount: 1,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Electronic Controls",
        img: "",
      },
      tableData: {
        supply: "1 ELECTRO",
        max_supply: "100000000000000 ELECTRO",
        issuer: "m.century",
        id: "100000000074440",
        authorctrl: 1,
        data: '{"name":"Electronic Controls","img":""}',
      },
    },
    AIRCOMP: {
      id: "100000000074444",
      supplyAmount: 0,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Air Compressor",
        img: "",
      },
      tableData: {
        supply: "0 AIRCOMP",
        max_supply: "100000000000000 AIRCOMP",
        issuer: "m.century",
        id: "100000000074444",
        authorctrl: 1,
        data: '{"name":"Air Compressor","img":""}',
      },
    },
    BETABAR: {
      id: "100000000073095",
      supplyAmount: 5,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Beta Barrel",
        img: "",
      },
      tableData: {
        supply: "5 BETABAR",
        max_supply: "100000000000000 BETABAR",
        issuer: "m.century",
        id: "100000000073095",
        authorctrl: 1,
        data: '{"name":"Beta Barrel","img":""}',
      },
    },
    MOTBLOW: {
      id: "100000000074437",
      supplyAmount: 3,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Motor Blower",
        img: "",
      },
      tableData: {
        supply: "3 MOTBLOW",
        max_supply: "100000000000000 MOTBLOW",
        issuer: "m.century",
        id: "100000000074437",
        authorctrl: 1,
        data: '{"name":"Motor Blower","img":""}',
      },
    },
    SANDBOX: {
      id: "100000000074432",
      supplyAmount: 3,
      maxSupplyAmount: 100000000000000,
      data: {
        name: "Sand Box",
        img: "",
      },
      tableData: {
        supply: "3 SANDBOX",
        max_supply: "100000000000000 SANDBOX",
        issuer: "m.century",
        id: "100000000074432",
        authorctrl: 1,
        data: '{"name":"Sand Box","img":""}',
      },
    },
  },
  marketItemDiscountPercent: 40,
  billboardResetTime: 604800,
  features: {
    stationPopups: {
      stakingHubs: true,
      gifts: false,
      commodityRates: true,
    },
    stationDash: {
      tabs: {
        gifts: false,
        billboard: true,
        rates: false,
        settings: false,
        staking: true,
        visitors: true,
        tocium: true,
      },
      enabled: true,
    },
    railRunsWindow: {
      tabs: {
        history: true,
        trains: true,
      },
      enabled: true,
    },
    hud: {
      balanceIndicators: {
        fuelCoal: true,
        animaticParticles: true,
        fuelDiesel: true,
        tocium: true,
      },
    },
    railroaderDash: {
      tabs: {
        story: true,
        events: false,
        staking: true,
        achievements: false,
        leaderboards: true,
        inventory: true,
        settings: true,
        claimCenter: true,
        goldenRuns: true,
      },
      enabled: true,
    },
    market: {
      tabs: {
        fuel: true,
        centuryTrainParts: true,
        upgrades: {
          unlockTrains: true,
          portalPasses: false,
          railCarSlots: true,
          compositions: true,
        },
        emporium: true,
      },
      enabled: true,
    },
  },
  gameplay: {
    conductorLuckMultiplier: 1,
    conductorLuckBasePercent: 2,
  },
  vars: {
    hp_m: 1000,
    toc_m: 19,
    toc_b: 1,
    fuel_m: 18750,
    fuel_b: 18750,
    time_m: 100,
    time_b: 60,
    backtrack_m: 200,
  },
  staking: {
    railYard: {
      tiers: [
        {
          tier: 1,
          vip_spots: 13,
          public_spots: 5,
          costs: ["100000.0000 TOCIUM"],
          rarities: ["common", "uncommon", "rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 2,
          vip_spots: 20,
          public_spots: 10,
          costs: ["110000.0000 TOCIUM"],
          rarities: ["uncommon", "rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 3,
          vip_spots: 24,
          public_spots: 16,
          costs: ["125000.0000 TOCIUM"],
          rarities: ["rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 4,
          vip_spots: 26,
          public_spots: 24,
          costs: ["145000.0000 TOCIUM"],
          rarities: ["epic", "legendary", "mythic"],
        },
        {
          tier: 5,
          vip_spots: 36,
          public_spots: 44,
          costs: ["170000.0000 TOCIUM"],
          rarities: ["legendary", "mythic"],
        },
        {
          tier: 6,
          vip_spots: 42,
          public_spots: 49,
          costs: ["200000.0000 TOCIUM"],
          rarities: ["mythic"],
        },
      ],
      assetCommissionRates: {
        common: 1.25,
        epic: 10,
        legendary: 20,
        mythic: 40,
        rare: 5,
        uncommon: 2.5,
      },
    },
    conductorLounge: {
      tiers: [
        {
          tier: 1,
          vip_spots: 13,
          public_spots: 5,
          costs: ["100000.0000 TOCIUM"],
          rarities: ["common", "uncommon", "rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 2,
          vip_spots: 20,
          public_spots: 10,
          costs: ["110000.0000 TOCIUM"],
          rarities: ["uncommon", "rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 3,
          vip_spots: 24,
          public_spots: 16,
          costs: ["125000.0000 TOCIUM"],
          rarities: ["rare", "epic", "legendary", "mythic"],
        },
        {
          tier: 4,
          vip_spots: 26,
          public_spots: 24,
          costs: ["145000.0000 TOCIUM"],
          rarities: ["epic", "legendary", "mythic"],
        },
        {
          tier: 5,
          vip_spots: 36,
          public_spots: 44,
          costs: ["170000.0000 TOCIUM"],
          rarities: ["legendary", "mythic"],
        },
        {
          tier: 6,
          vip_spots: 42,
          public_spots: 49,
          costs: ["200000.0000 TOCIUM"],
          rarities: ["mythic"],
        },
      ],
      assetCommissionRates: {
        common: 1.25,
        epic: 10,
        legendary: 20,
        mythic: 40,
        rare: 5,
        uncommon: 2.5,
      },
    },
  },
};
