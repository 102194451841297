/**
 * # Base64 encoded goodies
 *
 * (tiny solid and gradient textures)
 */

export const Base64Goodies = {
  gray: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAE0lEQVR42mOs/8+AFzCOKhhJCgAWPhfxw+FRRQAAAABJRU5ErkJggg==",

  black:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAE0lEQVR42mNk+M+AFzCOKhhJCgBrLxABLz0PwwAAAABJRU5ErkJggg==",

  // gradDown: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADBQTFRF////////////////////////////////////////////////////////////////Tq+4QAAAABB0Uk5T//bq3My7qJR/a1dEMyMVCUWNcMYAAAA7SURBVHicNcHBAAAhAADBVUghhRRSSCGFFFJIIYVTOIUUUqjPNgNPEFEkkUURVTTRxRBTfOIXS2xxdAGVlT/BTZcr7gAAAABJRU5ErkJggg==",

  gradUpBlack:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADBQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWj8cCwAAABB0Uk5TCRUjM0RXa3+UqLvM3Or2//+JQAcAAAA7SURBVHicNcHBAAAhAADBVUghhRRSSCGFFFJIIYVTOIUUUqjPNgNPEFEkkUURVTTRxRBTfOIXS2xxdAGVlT/BTZcr7gAAAABJRU5ErkJggg==",

  gradDownBlack:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADBQTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWj8cCwAAABB0Uk5T//bq3My7qJR/a1dEMyMVCUWNcMYAAAA7SURBVHicNcHBAAAhAADBVUghhRRSSCGFFFJIIYVTOIUUUqjPNgNPEFEkkUURVTTRxRBTfOIXS2xxdAGVlT/BTZcr7gAAAABJRU5ErkJggg==",

  // gradDownBlackDeep: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAC1QTFRFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwaAoPgAAAA90Uk5T//369e3h0r+pkHZcQSgS//uYtgAAADlJREFUeJxlwTEBABAAAMGvoIIKKqigggoqqKCCCiqooIIMlt/cwSeIKJLIoogqmuhiiCmW2OKIqwd7kzfJ7+N+cgAAAABJRU5ErkJggg==",

  // SHADE_TEXTURE_TOP: Texture.from(
  //   "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALYAAAAEBAMAAADR3NhJAAAAHlBMVEUVFRUAAAAAAAAAAAAAAAAAAAASEhIAAAAAAAAAAACRWY21AAAACnRSTlPrJEsbDgRpQR8XxOQ4WQAAACZJREFUGNNjCGOgFUhlcCxSog1QF2EIaRSkDZBwZQiZbEwbYOkKAH3FJuugSkw8AAAAAElFTkSuQmCC"
  // );
};
