import { debuggingPane } from "@debug/tweakpane";
import { makeOperationsLayerGroundQuad } from "@game/world/layer_operations/makeOperationsLayerGroundQuad";
import { makeRegionsLayerGroundQuad } from "@game/world/layer_regions/makeRegionsLayerGroundQuad";
import { Color } from "@sdk/utils/color/Color";
import { gsap } from "gsap";

export async function debugShadedLayer_Ground_ZoomTier1({
  uniforms,
}: ReturnType<typeof makeOperationsLayerGroundQuad>) {
  const animated = Object.keys(uniforms).reduce((acc, key) => {
    return Object.defineProperty(acc, key, {
      get: () => {
        return (uniforms as any)[key];
      },
      set: (value: any) => {
        gsap.to(uniforms, { [key]: value, overwrite: "auto", duration: 0.39 });
      },
    });
  }, {} as typeof uniforms);

  const pg = debuggingPane.addFolder({
    title: "🌎 Shady Business",
    expanded: false,
  });

  pg.addSeparator();
  pg.addInput(animated, "timeScale", { label: "⏱ speed" });
  pg.addInput(animated, "grassScale", { label: "🌎 scale" });
  pg.addSeparator();
}

export async function debugShadedLayer_Ground_ZoomTier2({ uniforms }: ReturnType<typeof makeRegionsLayerGroundQuad>) {
  const kolors = ["groundColor", "groundColorShade", "waterColor", "waterColorShade"];

  const animated = Object.keys(uniforms).reduce((acc, key) => {
    return Object.defineProperty(acc, key, {
      get: () => {
        return (uniforms as any)[key];
      },
      set: (value: any) => {
        gsap.to(uniforms, { [key]: value, overwrite: "auto", duration: 0.39 });
      },
    });
  }, {} as typeof uniforms);

  const store = kolors.reduce((acc, key) => {
    const u = uniforms as any;
    Object.defineProperty(acc, key, {
      set: function (value: number) {
        u[key] = new Color(value).toRgbArrayNormalized();
      },
      get: function (): number {
        const c = new Color(0);
        c.r = ~~(u[key][0] * 255);
        c.g = ~~(u[key][1] * 255);
        c.b = ~~(u[key][2] * 255);
        return c.toInt();
      },
    });
    return acc;
  }, {} as any);

  const pg = debuggingPane.addFolder({
    title: "🌎 Shady Regions",
    expanded: false,
  });

  for (const k of kolors) {
    pg.addInput(store, k, { view: "color", label: k });
  }
  pg.addInput(animated, "boundsFalloff", { min: 0.0 });
}
