export function createStorageProxy<T extends {} = any, TDefaults extends Partial<T> = {}>(
  prefix: string,
  $defaults: TDefaults = {} as TDefaults,
  storage: Pick<typeof localStorage, "setItem" | "getItem"> = localStorage
) {
  const getStorageKey = (key: string | symbol) => `${prefix}${String(key)}`;
  return new Proxy({ ...$defaults } as Partial<T> & TDefaults, {
    set: (_, prop, value) => {
      const storageKey = getStorageKey(prop);
      try {
        storage.setItem(storageKey, String(value));
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    get: (_, prop) => {
      const storageKey = getStorageKey(prop);
      return storage.getItem(storageKey) || $defaults[prop as keyof TDefaults];
    },
  });
}
