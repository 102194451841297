import { GameSingletons } from "@game/app/GameSingletons";
import { ViewSize } from "@game/app/services/ViewSize";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { IPointData } from "@pixi/math";

export function createHUDElementContainer(screenAnchor: IPointData, screenMargin: IPointData) {
  const { events, viewSize } = GameSingletons.getGameContext();

  const container = new EnchantedContainer();
  container.zIndex = 100;

  function updateIndicatorPositions({ width, height, vmin }: ViewSize) {
    container.position.set(
      screenMargin.x + (width - screenMargin.x - screenMargin.x) * screenAnchor.x,
      screenMargin.y + (height - screenMargin.y - screenMargin.y) * screenAnchor.y
    );

    const scale = Math.min(1, vmin / 720);
    container.scale.set(scale);
  }

  events.on({ resize: updateIndicatorPositions });
  updateIndicatorPositions(viewSize);

  return Object.assign(container, { screenAnchor, screenMargin, updateIndicatorPositions });
}
