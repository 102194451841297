import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { createEnchantedFrameLoop } from "@game/asorted/createEnchantedFrameLoop";
import { assignEnchantedFrameLoop } from "@game/asorted/enchant/assignEnchantedFrameLoop";
import { FontFamily } from "@game/constants/FontFamily";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { performTrainRepair } from "@game/gameplay/repair/performTrainRepair";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { ReadonlyDeep } from "type-fest";
import { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";
import { TrainStatusPanel } from "../train-status-panel/TrainStatusPanel";
import { TrainStatusPanelParams } from "../train-status-panel/TrainStatusPanelParams";
import { getLocomotiveCircularPreview } from "./getLocomotiveCircularPreview";
import { TrainStatusPin } from "./TrainStatusPin";

export class TrainStatusPinsSidebar extends SafeScrollbox {
  private readonly context: GameContext = GameSingletons.getGameContext();
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  private readonly onEnterFrame = createEnchantedFrameLoop(this);
  private readonly tweeener = new TemporaryTweeener(this);

  private readonly pins: ReturnType<TrainStatusPinsSidebar["createTrainPin"]>[] = [];

  private currentPanel: TrainStatusPanel | null = null;
  private currentTrain: ReadonlyObjectDeep<TrainEntity> | null = null;

  constructor(public readonly dashboardContainer: Container) {
    super({
      noTicker: true,
      boxWidth: 150,
      boxHeight: 690,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });

    this.dashboardContainer.addChild(this);
    this.onEnterFrame.add(() => {
      const { vmin, height } = this.context.viewSize;
      const scale = vmin / 1440;
      this.scale.set(scale);
    });

    this.onEnterFrame.watch(() => this.context.userData.trainsArray.length, this.populatePins.bind(this), true);
    for (let train of this.context.userData.trainsArray) {
      this.onEnterFrame.watch(
        () => train.currentOngoingRun && train.currentOngoingRun.isReadyToClaim,
        this.populatePins.bind(this),
        true
      );
    }
    this.addUpDownArrows();
  }

  populatePins() {
    for (const pin of this.pins) pin.destroy();
    this.pins.length = 0;

    for (const [index, train] of this.context.userData.trainsArray.entries()) {
      const trainPin = this.createTrainPin(train);
      const trainPinClickArea = new Sprite(Texture.WHITE);
      trainPinClickArea.alpha = 0;
      trainPinClickArea.width = 64;
      trainPinClickArea.height = 128;

      trainPin.position.set(0, 175 * index);
      trainPinClickArea.position.set(65, 85);
      trainPinClickArea.anchor.set(0.5);

      buttonizeDisplayObject(trainPinClickArea, async () => {
        if (!train.locomotive) {
          this.editTrain(train);
        } else {
          this.setSelectedTrain(train === this.currentTrain ? null : train);
        }
      });
      trainPin.addChild(trainPinClickArea);
      this.content.addChild(trainPin);
      this.pins.push(trainPin);
    }

    this.content.addChild(this.addInvisibleBox(526));
  }

  async editTrain(train: ReadonlyObjectDeep<TrainEntity>) {
    this.setSelectedTrain(null);
    await this.context.ticker.delay(0.35);
    await this.context.world.moveViewportTo(train as TrainEntity);
    await this.context.ticker.delay(0.15);
    this.context.input.emit("enterEditTrainView", train as TrainEntity);
  }

  setSelectedTrain(train: ReadonlyObjectDeep<TrainEntity> | null) {
    if (this.currentTrain === train) return;

    this.closeTrainStatusPanel();


    this.currentTrain = train;

    if (train) {
      this.openTrainStatusPanel(train);

      this.tweeener.to(this.pins, {
        x: -250,
        stagger: 0.045,
        duration: 0.15,
        ease: "power1.in",
      });
    } else {
      this.hideTrainPinsAnimation();
    }
  }

  async playShowAnimation() {
    await this.tweeener.from(this.pins, {
      x: -250,
      delay: 1.1,
      stagger: 0.085,
      duration: 0.35,
      ease: "back.out",
    });
  }

  private closeTrainStatusPanel(): void {
    const currentPanel = this.currentPanel;
    if (!currentPanel) return;

    this.currentPanel = null;
    this.currentTrain = null;

    currentPanel.playHideAnimation().then(() => currentPanel.destroy());
  }

  public hideTrainPinsAnimation(){
    this.tweeener.to(this.pins, {
      x: 0,
      delay: 0.41,
      stagger: 0.065,
      duration: 0.31,
      ease: "back.out",
    });
  }

  private openTrainStatusPanel(train: ReadonlyObjectDeep<TrainEntity>): void {
    if (!train.locomotive) {
      return console.log(`Train "${train.name}" has no locomotive`);
    }

    this.context.sfx.play("bubble");
    this.context.sfx.play("pUp");
    const { modals } = this.context;

    const repairPanel = new TrainStatusPanel();
    repairPanel.scale.set(0.75);
    repairPanel.position.set(30 + repairPanel.width * 0.5, this.y + this.context.viewSize.height*.04);

 

    this.context.events.on({
      resize: () => {
        const scale = this.context.viewSize.vmin / 1440;
        repairPanel.position.set(30 + repairPanel.width * 0.5, ((this.context.viewSize.height/2)-((this.height*scale)/2.25)) + this.context.viewSize.height*.04);
      }
    });

    // const scaleFactor = this.context.viewSize.vmin / 1440;
    // repairPanel.scale.set(0.6 * scaleFactor);
    // repairPanel.position.set(scaleFactor * (this.boxWidth + 200), this.y + scaleFactor * 100);

    if (train.currentOngoingRun) {
      repairPanel.addFooterText(`Transporting`);
    } else if (train.conditionFraction > 0.99) {
      repairPanel.addFooterText(`Top Condition!`);
    } else {
      repairPanel.addFooterRepairButton(async () => {
        const choice = await modals.confirmRepair(train);
        if (choice) {
          await performTrainRepair(train);
          repairPanel.update(TrainStatusPanelParams.fromTrain(train));
        }
      });
    }

    repairPanel.addBackArrow(() => this.setSelectedTrain(null));
    repairPanel.addEditButton(() => this.gotoEditTrainView(train as TrainEntity));
    repairPanel.addLocateButton(() => this.locateTrain(train as TrainEntity));

    this.currentTrain = train;
    this.currentPanel = repairPanel;

    repairPanel.update(TrainStatusPanelParams.fromTrain(train));

    const username = this.context.userData.name; // "vqrxc.wam"
    const trainname = train.name; // "axalon"
    this.context.historyDataCtrl.api.getRailroaderStats(username, undefined, trainname).then(data => {
      repairPanel.updateRunsAndDistance({
        runs: data?.total_transports ?? 0,
        distance: data?.total_distance ?? 0,
      });
    });

    this.addChild(repairPanel);

    repairPanel.playShowAnimation();
  }

  createTrainPin(train: ReadonlyDeep<TrainEntity>) {
    const container = assignEnchantedFrameLoop(new Container());

    //// Side bar
    const pipe = this.factory.createSprite("ui-repair/multi-train-map-view/pipe.png");
    container.addChild(pipe);

    const pipeFlipped = this.factory.createSprite("ui-repair/multi-train-map-view/pipe.png");
    pipeFlipped.scale.set(-1, 1);
    container.addChild(pipeFlipped);

    //// Train pin circle
    let trainPin: TrainStatusPin;
    const locoPreviewTextureId = getLocomotiveCircularPreview(train.locomotive)!;
    if (train.currentOngoingRun != null && train.currentOngoingRun.isReadyToClaim != true) {
      trainPin = new TrainStatusPin(locoPreviewTextureId, train.conditionFraction, train.currentOngoingRun);
    } else {
      trainPin = new TrainStatusPin(locoPreviewTextureId, train.conditionFraction);
    }
    trainPin.position.set(65, 75);
    trainPin.scale.set(0.4);

    ////Train Nameplate
    const pinNameplate = this.factory.createSprite("trainPinNameplate");
    const pinNameplateLabel = new Text(train.name.toUpperCase(), {
      fontFamily: FontFamily.Default,
      fontSize: 12,
      fill: 0xffffff,
    });

    pinNameplate.anchor.set(0.5);
    pinNameplateLabel.anchor.set(0.5);

    pinNameplate.position.set(trainPin.x, trainPin.y + trainPin.height / 2);
    pinNameplateLabel.position.set(pinNameplate.x, pinNameplate.y);

    container.addChild(trainPin, pinNameplate, pinNameplateLabel);

    container.onEnterFrame.watch(
      () => train.conditionFraction,
      condition => trainPin.percentageBar.setTargetFraction(condition)
    );

    container.onEnterFrame.watch(
      () => train.locomotive,
      locomotive => (trainPin.locomotivePreview.texture = Texture.from(getLocomotiveCircularPreview(locomotive)!))
    );

    return Object.assign(container, { train });
  }

  async gotoEditTrainView(train: TrainEntity) {
    const stations = this.context.mapData.stations;
    const station = stations.get(train.currentStationId!) || null;
    if (!station) {
      throw new Error(`Station ${train.currentStationId} not found`);
    }

    this.context.input.emit("closeRailRunsWindow");
    await this.context.ticker.delay(0.35);
    await this.context.world.moveViewportTo(train);
    await this.context.ticker.delay(0.15);
    this.context.input.emit("enterEditTrainView", train);
  }

  async locateTrain(train: TrainEntity) {
    await this.context.input.emit("locateOnMap", train);
    
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  addUpDownArrows() {
    const textureId = "ui-station-dashboard/staking/manage-tab/manage-home/btn-back.png";
    const texture = this.context.assets.getTexture(textureId);

    const addArrow = (x: number, direction: -1 | 1) => {
      const sprite = new Sprite(texture);
      sprite.angle = -90 * direction;
      sprite.anchor.set(0.5);
      sprite.position.set(x, this.boxHeight + 40);
      buttonizeDisplayObject(sprite, () => {
        const scrollTop = this.scrollTop + direction * this.boxHeight;
        this.tweeener.to(this, { scrollTop, duration: 0.67, ease: "power3.out", overwrite: "auto" });
      });
      return this.addChild(sprite);
    };

    const down = addArrow(40, 1);
    const up = addArrow(80, -1);

    this.onEnterFrame.add(() => {
      up.visible = !this.currentTrain && this.scrollTop > 0;
      down.visible = !this.currentTrain && this.scrollTop + this.boxHeight < this.scrollHeight;
    });
  }
}
