import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { AdvancedBloomFilter } from "@pixi/filter-advanced-bloom";
import { CRTFilter } from "@pixi/filter-crt";
import { GlitchFilter } from "@pixi/filter-glitch";
import { RGBSplitFilter } from "@pixi/filter-rgb-split";
import { addTemporaryFilter } from "@sdk/pixi/fx/addTemporaryFilter";

const randSigned = () => 2.0 * (Math.random() - 0.5);

const DURATION = 1.5;

export class EffectsController {
  public applyChromaticAbberation(target: Container = GameSingletons.getGameContext().app.stage, resolution = 1) {
    return addTemporaryFilter(RGBSplitFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter, targets }) => {
        const v = Math.sin(progress * Math.PI) ** 1;
        const rando = () => randSigned() * 6 * v;
        filter.red = [rando(), 0];
        filter.green = [rando(), rando()];
        filter.green = [0, 0];
        filter.blue = [rando(), 0];
      },
      filterPadding: 50,
    });
  }

  public applyChromaticAbberation2(target: Container = GameSingletons.getGameContext().app.stage, resolution = 1) {
    //const angle = Math.random() * Math.PI * 2;
    const angle = 0.25 * Math.PI * 2;
    const maxOffset = { x: Math.cos(angle), y: Math.sin(angle) };
    return addTemporaryFilter(RGBSplitFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter, properties }) => {
        const vx = 8 * maxOffset.x * Math.sin(progress * Math.PI) ** 1;
        const vy = 8 * maxOffset.y * Math.sin(progress * Math.PI) ** 1;
        filter.red = [vx, vy];
        filter.green = [0, 0];
        filter.blue = [-vx, -vy];
      },
      filterPadding: 50,
    });
  }

  public applyBloom(target: Container = GameSingletons.getGameContext().app.stage, resolution = 1) {
    return addTemporaryFilter(AdvancedBloomFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter, targets }) => {
        const v = Math.sin(progress * Math.PI) ** 1;
        filter.threshold = 1 - v;
        //filter.brightness = v;
        filter.blur = v * 5;
        filter.bloomScale = v;
      },
      filterPadding: 50,
      filterConstructorParameters: {
        resolution,
      },
    });
  }

  public applyGlitch1(target: Container = GameSingletons.getGameContext().app.stage) {
    return addTemporaryFilter(GlitchFilter, target, {
      duration: DURATION,
      onUpdate: ({ filter, progress }) => {
        const v = Math.sin(progress * Math.PI) ** 2;
        filter.offset = 16 * v;
        filter.refresh();
      },
      filterPadding: 50,
      filterConstructorParameters: {
        slices: 20,
        fillMode: GlitchFilter.MIRROR,
      },
    });
  }

  public applyGlitch2(target: Container = GameSingletons.getGameContext().app.stage, direction = 90) {
    const RGB_MAX_OFFEST = 12;
    const SLICES_MAX_OFFSET = 12;
    const SLICES_MAX_COUNT = 12;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        const v = Math.pow(Math.sin(progress * Math.PI), 0.5);
        filter.slices = ~~(SLICES_MAX_COUNT * v);
        filter.offset = SLICES_MAX_OFFSET * v;
        filter.red = [v * randSigned() * RGB_MAX_OFFEST, 0];
        filter.blue = [v * randSigned() * RGB_MAX_OFFEST, 0];
        filter.refresh();
      },
      filterPadding: 50,
      filterConstructorParameters: {
        offset: 8,
        slices: 30,
        direction: Math.round(direction),

        // average: true,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  }

  public applyGlitch3(target: Container = GameSingletons.getGameContext().app.stage) {
    const RGB_MAX_OFFEST = 24;
    const SLICES_MAX_OFFSET = 4;
    const SLICES_MAX_COUNT = 32;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        const v = Math.sin(progress * Math.PI);
        filter.slices = ~~(SLICES_MAX_COUNT * v);
        filter.offset = SLICES_MAX_OFFSET * v;
        filter.red = [0, v * randSigned() * RGB_MAX_OFFEST];
        filter.blue = [0, v * randSigned() * RGB_MAX_OFFEST];
        filter.refresh();
      },
      filterPadding: 50,
      filterConstructorParameters: {
        offset: 8,
        slices: 30,
        direction: 90,

        average: true,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  }

  public applyGlitch4(target: Container = GameSingletons.getGameContext().app.stage) {
    const RGB_MAX_OFFEST = 2;
    const GLITCH_MAX_OFFEST = 20;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        const v = Math.sin(progress * Math.PI) ** 0.5;
        filter.offset = GLITCH_MAX_OFFEST * v;

        const vx = 0;
        const vy = RGB_MAX_OFFEST * Math.sin(progress * Math.PI) ** 1;
        filter.red = [vx, vy];
        filter.green = [-vx, -vy];
        filter.blue = [vx, vy];

        filter.refresh();
      },
      filterPadding: 50,
      filterConstructorParameters: {
        slices: 20,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  }

  public applyGlitch5(target: Container = GameSingletons.getGameContext().app.stage) {
    const RGB_MAX_OFFEST = 3;
    const GLITCH_MAX_OFFEST = 20;

    const context = GameSingletons.getGameContext() as GameContext;
    const chance = 10 / context.ticker.FPS;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        if (Math.random() < chance) {
          filter.offset = GLITCH_MAX_OFFEST;
          const vx = 0;
          const vy = RGB_MAX_OFFEST * Math.sin(progress * Math.PI) ** 1;
          filter.red = [vx, vy];
          filter.green = [-vx, -vy];
          filter.blue = [vx, vy];
          filter.refresh();
        }
      },
      filterPadding: 50,
      filterConstructorParameters: {
        slices: 20,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  }

  public applyCRT(target: Container = GameSingletons.getGameContext().app.stage) {
    return addTemporaryFilter(CRTFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        const v = Math.sin(progress * Math.PI);
        const vSqrt = Math.pow(v, 0.5);
        filter.seed = v;
        filter.time += 0.1;
        filter.noise = Math.random() * vSqrt;
        filter.lineContrast = 0.5 * vSqrt;
        filter.lineWidth = 5 - 4 * Math.pow(v, 0.5);
        filter.vignettingAlpha = vSqrt;
      },
      filterPadding: 50,
      filterConstructorParameters: {
        curvature: 1,
        lineWidth: 4,
        lineContrast: 0.5,
        noise: 0.5,
        verticalLine: true,
        // vignetting: 0,
        // vignettingAlpha: 0,
        // vignettingBlur: 0,
        seed: 1000,
      },
    });
  }

  public applyCRT2(target: Container = GameSingletons.getGameContext().app.stage) {
    return addTemporaryFilter(CRTFilter, target, {
      duration: DURATION,
      onUpdate: ({ progress, filter }) => {
        const v = Math.sin(progress * Math.PI);
        const vSqrt = Math.pow(v, 0.5);
        filter.seed = v;
        filter.time += 0.01;
        filter.noise = 0.2;
        filter.lineContrast = 0.25 * vSqrt;
        filter.lineWidth = 5 - 4 * Math.pow(v, 0.5);
        filter.vignetting = 0;
        filter.vignettingAlpha = 0;
        filter.vignettingBlur = 0;
      },
      filterPadding: 50,
      filterConstructorParameters: {
        curvature: 1,
        lineWidth: 4,
        lineContrast: 0.5,
        noise: 0.5,
        verticalLine: true,
        // vignetting: 0,
        // vignettingAlpha: 0,
        // vignettingBlur: 0,
        seed: 1000,
      },
    });
  }
}

// __window__.fx = new EffectsController();
