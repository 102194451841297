import * as WAX from "@sdk-integration/contracts";

import { StakingAddonType } from "@game/asorted/StakingType";
import { CenturyTrainPartData, CenturyTrainPartTokenSymbol } from "@game/ui/windows/market/pages/ct-parts/data/models";
import { AssetRarity, ContractName } from "@sdk-integration/contracts";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { FirebaseServicesWrapper } from "@sdk-integration/firebase/FirebaseServicesWrapper";
import { RemoteConfiguration } from "@sdk-integration/firebase/types/RemoteConfiguration";
import { ReadonlyDeep } from "type-fest/source/readonly-deep";

import allPartsData from "@game/ui/windows/market/pages/ct-parts/ctPartsData.json";
import { CenturyTrainPartEntity } from "@game/ui/windows/market/pages/ct-parts/data/CenturyTrainPartEntity";
import { ITemplate } from "atomicassets/build/API/Explorer/Objects";

type TierConstants = Readonly<{
  tier: number;
  vip_spots: number;
  public_spots: number;
  costs: string[];
  rarities: string[];
}>;

type AssetCommissionRates = Record<AssetRarity, number>;

type StakingConstants = {
  tiers: TierConstants[];
  assetCommissionRates: AssetCommissionRates;
};

type GameDataServices = {
  contracts: ReadonlyDeep<WaxContractsGateway>;
  firebase: FirebaseServicesWrapper;
};

type SimpleAssetStats = {
  id: string;
  supplyAmount: number;
  maxSupplyAmount: number;
  data: { name: string; img: string };
  tableData: SimpleAssetStatsTableData;
};

type SimpleAssetStatsTableData = {
  supply: `${number} ${string}`;
  max_supply: `${number} ${string}`;
  issuer: string;
  id: string;
  data: string;
};

type CTPartFusionImpacts = {
  distance_boost: number;
  haul_boost: number;
  speed_boost: number;
  luck_boost: number;
};

type RunModMarketData = {
  template_id: string;
  priceString: string;
  priceAmount: number;

  isSaleActive: () => boolean;
  getNextSaleStartSeconds: () => number;

  rowData: any;
};

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export class GameConfigurationData {
  public vars!: WAX.GameMathVars;
  public staking!: Record<StakingAddonType, StakingConstants>;
  public trainPriceBase: number = NaN;
  public trainPriceCap: number = 500000;

  public features!: RemoteConfiguration.Features;
  public gameplay!: RemoteConfiguration.Gameplay;

  public simpleAssetsStatsTable: Record<string, SimpleAssetStats> = {};

  public marketItemDiscountPercent = 0;
  public readonly marketItemPrices = new Map<CenturyTrainPartTokenSymbol, number>();

  public readonly runModsMarketData = new Map<number, RunModMarketData>();
  public readonly runModsTemplates = new Map<number, ITemplate>();

  public readonly ctPartsFusionImpacts = new Map<CenturyTrainPartTokenSymbol, CTPartFusionImpacts>();
  public readonly ctPartsData = allPartsData as Record<CenturyTrainPartTokenSymbol, CenturyTrainPartData>;

  public billboardResetTime = 0;

  get backtrackingFuelCostMultiplier() {
    const percent = this.vars?.backtrack_m || 100;
    return 0.01 * percent;
  }

  async load({ contracts, firebase }: GameDataServices) {
    //// REMOTE CONFIG (FIREBASE) ////

    this.features = await firebase.getFeaturesConfiguration();
    this.gameplay = await firebase.getGameplayConfiguration();

    //// WAX CONTRACT VARS ////

    this.vars = await contracts.getGameVars();

    //// SIMPLE ASSETS ////

    {
      const rows = await contracts.tables.loadRows<SimpleAssetStatsTableData>(
        "stat",
        { scope: WAX.ContractName.M, limit: 400 },
        WAX.ContractName.SimpleAssets
      );
      this.simpleAssetsStatsTable = rows.reduce((acc, row) => {
        try {
          const [supplyAmount, key] = row.supply.split(" ");
          const [maxSupplyAmount, maxKey] = row.max_supply.split(" ");
          if (key !== maxKey) {
            throw new Error(`SimpleAssets: ${key} !== ${maxKey}`);
          } else {
            acc[key] = {
              id: row.id,
              supplyAmount: Number(supplyAmount),
              maxSupplyAmount: Number(maxSupplyAmount),
              data: JSON.parse(row.data),
              tableData: row,
            };
          }
        } finally {
          return acc;
        }
      }, {} as Record<string, SimpleAssetStats>);
    }

    /// MARKET PRICES ///

    {
      const [configRow] = await contracts.tables.loadRows<{
        discount_m: WAX.integer;
      }>("config", { scope: WAX.ContractName.M, limit: 1000 }, WAX.ContractName.M);
      const discountedMultiplierPercent = configRow?.discount_m ?? 0;
      this.marketItemDiscountPercent = 100 - discountedMultiplierPercent;

      const pricingRows = await contracts.tables.loadRows<{
        symbol: `${number},${CenturyTrainPartTokenSymbol}`;
        price: `${number} ${string}`;
      }>("prices", { scope: contracts.currentCenturyName, limit: 1000 }, WAX.ContractName.M);
      for (const row of pricingRows) {
        const [, tokenSymbol] = row.symbol.split(",") as [precision: string, tokenSymbol: CenturyTrainPartTokenSymbol];
        const [priceAmount] = row.price.split(" ") as [priceAmount: string, priceCurrency: string];
        this.marketItemPrices.set(tokenSymbol, +priceAmount);
      }
    }

    //// RUN MOD PRICES ////

    {
      /**
       * Calculate if the given input (in unix seconds) is within the specified periodic range.
       */
      function isInRange(input: number, t_offset: number, t_period: number, t_duration: number) {
        const inputWithOffset = input - t_offset;
        const chunkProgress = inputWithOffset % t_period;
        const isNowWithinTheSetDuration = chunkProgress < t_duration;
        return isNowWithinTheSetDuration;
      }

      const rows = await contracts.tables.loadRows<{
        template_id: string;
        price: string;
        period: number;
        duration: number;
        offset: number;
      }>("saleevents", { scope: WAX.ContractName.M, limit: 1000 }, WAX.ContractName.M);

      for (const rowData of rows) {
        const { template_id, price, period, duration, offset } = rowData;
        const dataObject = {
          template_id,
          priceString: price,
          priceAmount: +price.split(" ")[0],
          isSaleActive: () => isInRange(Date.now() / 1000, offset, period, duration),
          getNextSaleStartSeconds: () => {
            const now = Date.now() / 1000;
            const nextSaleStart = offset + Math.floor((now - offset) / period) * period + period;
            return nextSaleStart;
          },
          rowData,
        };
        this.runModsMarketData.set(+template_id, dataObject);
      }

      const SCHEMA_NAME = "modifier";
      const rawResults = await contracts.assets.getTemplatesBySchemaName(SCHEMA_NAME);
      for (const rawResult of rawResults) {
        this.runModsTemplates.set(+rawResult.template_id, rawResult);
      }
    }

    //// STATIONS CONFIGURATION ////

    {
      const [row] = await contracts.tables.loadRows<{ bb_reset: number }>(
        "config",
        { scope: WAX.ContractName.S, limit: 1 },
        WAX.ContractName.S
      );

      this.billboardResetTime = row?.bb_reset ?? 0;

      console.log(`🖋 Billboard reset time: ${this.billboardResetTime}`);
    }

    // #region STAKING ////
    const stakingContractKeys = {
      [StakingAddonType.RailYard]: {
        tableName_Tiers: "rytiers",
        assetSchema: "locomotive",
      },
      [StakingAddonType.ConductorLounge]: {
        tableName_Tiers: "ltiers",
        assetSchema: "conductor",
      },
    };

    type Payout = { key: string; value: number };
    const assetCommissionRateRows = await contracts.tables.loadRows<{ schema: string; payouts: Payout[] }>(
      "rates",
      { scope: contracts.currentCenturyName, limit: 1000 },
      ContractName.S
    );

    async function getAssetCommissionRates(addonType: StakingAddonType) {
      const assetSchema = stakingContractKeys[addonType].assetSchema;
      const rowForThisAddon = assetCommissionRateRows.find(row => row.schema == assetSchema);

      if (!rowForThisAddon) {
        throw new Error(`Could not find commission rates for ${assetSchema}`);
      }

      return rowForThisAddon.payouts.reduce((acc, { key, value }) => {
        acc[key.toLowerCase() as AssetRarity] = value * 0.0001;
        return acc;
      }, {} as { [key: AssetRarity]: number });
    }

    async function getTierConstants(addonType: StakingAddonType) {
      return await contracts.tables.loadRows<{
        tier: number;
        vip_spots: number;
        public_spots: number;
        costs: string[];
        rarities: string[];
      }>(
        stakingContractKeys[addonType].tableName_Tiers,
        { scope: contracts.currentCenturyName, limit: 10 },
        ContractName.S
      );
    }

    async function getFullStatingAddonConstants(addonType: StakingAddonType) {
      return {
        tiers: await getTierConstants(addonType),
        assetCommissionRates: await getAssetCommissionRates(addonType),
      };
    }

    this.staking = {
      [StakingAddonType.RailYard]: await getFullStatingAddonConstants(StakingAddonType.RailYard),
      [StakingAddonType.ConductorLounge]: await getFullStatingAddonConstants(StakingAddonType.ConductorLounge),
    };
    // #endregion

    // #region UPGRADES ////

    {
      const [pricingRow] = await contracts.tables.loadRows<{
        upgrade_type: string;
        base_price: number;
        tier_m: number;
        limit: number;
      }>("upgrades", { scope: ContractName.M, limit: 1, upper_bound: "train", lower_bound: "train" }, ContractName.M);
      this.trainPriceBase = pricingRow.base_price * 0.0001;
    }

    {
      const rows = await contracts.tables.loadRows<{
        symbol: `${number},${CenturyTrainPartTokenSymbol}`;
        boosts: { type: keyof CTPartFusionImpacts; boost: number }[];
      }>(
        "fusion",
        {
          scope: contracts.currentCenturyName,
          limit: 1000,
        },
        WAX.ContractName.M
      );
      console.log({ rows });

      for (const { symbol, boosts } of rows) {
        const [, tokenSymbol] = symbol.split(",") as [precision: string, tokenSymbol: CenturyTrainPartTokenSymbol];
        const fusionImpacts = boosts.reduce(
          (acc, { type, boost }) => {
            acc[type] = boost;
            return acc;
          },
          { distance_boost: 0, haul_boost: 0, speed_boost: 0, luck_boost: 0 }
        );

        this.ctPartsFusionImpacts.set(tokenSymbol, fusionImpacts);

        const data: Writeable<CenturyTrainPartData> = this.ctPartsData[tokenSymbol];
        data.distance = fusionImpacts.distance_boost;
        data.haulingPower = fusionImpacts.haul_boost;
        data.speed = fusionImpacts.speed_boost;
        data.luck = fusionImpacts.luck_boost;
      }
    }

    // #endregion
  }

  getCenturyPartMarketData(
    tokenSymbol: CenturyTrainPartTokenSymbol,
    mods?: ConstructorParameters<typeof CenturyTrainPartEntity>[1]
  ) {
    const partData: Writeable<CenturyTrainPartData> = this.ctPartsData[tokenSymbol];

    if (partData == null) {
      console.warn(`Unknown part: ${tokenSymbol}`);
      return null;
    }

    const livePrice = this.marketItemPrices.get(tokenSymbol);
    partData.cost = livePrice ?? NaN;

    return new CenturyTrainPartEntity(partData, {
      forceActive: true,
      discountPercent: this.marketItemDiscountPercent,
      ...mods,
    });
  }

  getStakedAssetEaringnRateByRarity(addonType: StakingAddonType, rarity: AssetRarity) {
    return this.staking[addonType].assetCommissionRates[rarity];
  }
}
