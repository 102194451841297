const glsl = (x: TemplateStringsArray) => x.join("");
export default glsl`

precision mediump float;

varying vec2 vUvs;

uniform sampler2D uSamplerMapGround;
uniform sampler2D uSamplerMapWater;
uniform sampler2D uSamplerNoises; // R: Harsh, G: Grass Dissolve, B: Soft

const vec2 bounds = vec2(5., 75.);

uniform float time;
uniform vec2 resolution;

uniform float alpha;

uniform vec2 offset;

uniform float minimapScaleFactor;
uniform float minimapOffset;

uniform float waterThreshold;

uniform vec3 groundColor;
uniform vec3 groundColorShade;
uniform vec3 waterColor;
uniform vec3 waterColorShade;

uniform float groundDetail;
uniform float waterDetail;

uniform float boundsFalloff;

uniform float colorMapAlpha;

uniform float shoreFeather;

float blend(float bg, float fg) {
  return bg < 0.5 ? (2.0 * bg * fg) : (1.0 - 2.0 * (1.0 - bg) * (1.0 - fg));
}

void main() {
  vec2 pos = resolution * (vUvs + offset) + minimapOffset;
  vec2 diffusePos = .02 * pos;

  vec4 mapWater = texture2D(uSamplerMapWater, minimapScaleFactor * pos);
  vec4 mapGround = texture2D(uSamplerMapGround, minimapScaleFactor * pos);
  vec4 noise = texture2D(uSamplerNoises, diffusePos);

  gl_FragColor.rgb = mix(
    mix(groundColorShade, groundColor, mapGround.b), 
    mix(waterColor, waterColorShade, mapGround.b), 
    mapWater.b
  );

  vec2 b = bounds + minimapOffset - 40.;
  float falloff = 1.0;
  if (pos.x < b[0]) falloff *= 1. - smoothstep(b[0], b[0] - boundsFalloff, pos.x);
  if (pos.x > b[1]) falloff *= 1. - smoothstep(b[1], b[1] + boundsFalloff, pos.x);
  if (pos.y < b[0]) falloff *= 1. - smoothstep(b[0], b[0] - boundsFalloff, pos.y);
  if (pos.y > b[1]) falloff *= 1. - smoothstep(b[1], b[1] + boundsFalloff, pos.y);
  falloff = clamp(1.0 - falloff, 0., 1.);
  gl_FragColor.rgb *= 1. - falloff;

  gl_FragColor.a = 1.;
  
  // gl_FragColor *= alpha;
  gl_FragColor *= clamp(alpha + alpha * noise.b * 3., 0., 1.);
  // gl_FragColor *= alpha < blend(noise.b, 1. - mapGround.b) ? 0.0 : 1.0;
  // gl_FragColor *= alpha < noise.b ? 0.0 : 1.0;
  // gl_FragColor *= blend(blend(blend(alpha, noise.b), noise.b), mapGround.b);

  //gl_FragColor *= blend(alpha, noise.b);
  // gl_FragColor *= blend(alpha, mapGround.b);
}

`;
