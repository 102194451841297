import { onKeyPressWithAlt } from "@debug/utils/onKeyPress";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { DropdownMenu, DropdownMenuButtonOptions } from "@game/ui/popups/components/DropdownMenu";
import { DropdownStyleOptions } from "@game/ui/popups/components/DropdownMenuButton";
import { InteractionManager } from "@pixi/interaction";
import { testAutomaticViewportNavigation } from "./testAutomaticViewportNavigation";
import { addLineAndBarChartsToStage } from "./testLineAndBarCharts";
import { testTheModals } from "./testTheModals";

/**********************************************\
| Test Context Menu Creation and functionality |
\**********************************************/
export function testContextMenu() {
  const context: GameContext = GameSingletons.getGameContext();
  const { app, viewSize } = context;

  const interaction = app.renderer.plugins.interaction as InteractionManager;
  const mousePosition = interaction.mouse.global;

  const styles: Partial<DropdownStyleOptions> = {
    buttonBGColor: 0x9c13e5,
  };
  const styles2: Partial<DropdownStyleOptions> = {
    buttonBGColor: 0xebebeb,
  };
  const styles3: Partial<DropdownStyleOptions> = {
    buttonBGColor: 0x17870e,
  };
  const buttons: DropdownMenuButtonOptions[] = [
    {
      text: "Test the modals Global Purple BG",
      disabled: false,
      onClick: () => testTheModals(),
    },
    {
      text: "Go to Next Train Light Grey BG",
      disabled: false,
      onClick: () => testAutomaticViewportNavigation(context),
      style: styles2,
    },
    {
      text: "Add line and bar charts Global Purple BG",
      disabled: false,
      onClick: () => addLineAndBarChartsToStage(app.stage),
    },
    {
      text: "Go to Next Train Green BG",
      disabled: false,
      onClick: () => testAutomaticViewportNavigation(context),
      style: styles3,
    },
  ];

  onKeyPressWithAlt("b", () => {
    const tol = new DropdownMenu(buttons, styles);
    tol.position.copyFrom(mousePosition);

    context.stageContainers._debug.addChild(tol);
  });
}
