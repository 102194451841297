import { Loader, LoaderResource } from "@pixi/loaders";

export type ResourcesMinifest = {
  assetsNamed?: Record<string, string>;
  assetsUnnamed?: string[];
};

export function loadResourcesFromManifest(manifest: ResourcesMinifest) {
  const { assetsNamed, assetsUnnamed } = manifest;

  const loader = new Loader();

  if (assetsNamed) {
    for (const key in assetsNamed) {
      const path = assetsNamed[key];
      loader.add(key, path);
    }
  }

  if (assetsUnnamed) {
    for (const path of assetsUnnamed) {
      loader.add(path);
    }
  }

  const promise = new Promise<Record<string, LoaderResource>>(resolve => {
    loader.load((loader, resource) => {
      resolve(resource);
    });
  });

  return promise;
}
