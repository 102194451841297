import {DisplayObject} from "@pixi/display";
import {ColorMatrixFilter} from "@pixi/filter-color-matrix";
import {gsap} from "gsap";

export function flickeringLight(context : any, item : {
  name : string,
  sprite : DisplayObject,
  minAlpha : number,
  maxAlpha : number,
  frequency : number
}) { // Set up variables for the flickering effect
  let elapsedTime = 0;
  let currentAlpha = item.minAlpha;
  let alphaDirection = 1;

  // Update the flickering effect each frame
  context.app.ticker.add((delta : number) => { // Update the elapsed time
    elapsedTime += delta;

    // If the elapsed time is greater than the frequency, update the alpha value and reset the elapsed time
    if (elapsedTime >= item.frequency) {
      elapsedTime = 0;
      currentAlpha += alphaDirection;

      // If the alpha value has reached the minimum or maximum, change the direction
      if (currentAlpha <= item.minAlpha || currentAlpha >= item.maxAlpha) {
        alphaDirection *= -1;
      }

      // Update the alpha value of the item's sprite
      item.sprite.alpha = currentAlpha;

      // Add a random value to the frequency to vary the flickering effect
      item.frequency += Math.random() * 5 - 25;
    }
  });
}

export function brightnessOsc(context : any, item : {
  sprite : DisplayObject,
  minBrightness : number,
  maxBrightness : number,
  frequency : number
}) { // Create a color matrix filter
  const colorMatrix = new ColorMatrixFilter();
  item.sprite.filters = [colorMatrix];

  let elapsedTime = 0;
  let currentBrightness = item.minBrightness;
  let brightnessDirection = 1;

  // Update the sunlight effect each frame
  context.app.ticker.add((delta : number) => {
    elapsedTime += delta;

    // Update the filter's properties at a regular interval
    if (elapsedTime >= item.frequency) {
      elapsedTime = 0;
      currentBrightness += brightnessDirection;

      // Change direction when it reach the minimum or maximum
      if (currentBrightness <= item.minBrightness || currentBrightness >= item.maxBrightness) {
        brightnessDirection *= -1;
      }

      // Update the brightness of the filter
      colorMatrix.uniforms.brightness = currentBrightness;

      // Add a random value to the frequency to vary the sunlight effect
      item.frequency += Math.random() * 5 - 2.5;
    }
  });
}
