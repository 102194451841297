import type * as WAX from "@sdk-integration/contracts";

import { Rarity } from "../../constants/Rarity";

import { __DEBUG__ } from "@debug/__DEBUG__";
import { __DEBUG_SETTINGS__ } from "@debug/__DEBUG_SETTINGS__";
import { StakingAddonTier } from "@game/asorted/StakingType";
import { RegionIntegerId } from "@game/constants/RegionId";

export class StationEntity {
  constructor(
    public readonly waxRRContractData: WAX.StationData_RRCentury,
    public readonly waxSContractData: Partial<WAX.StationData_SCentury>,
    public readonly waxAssetData: WAX.StationAssetData,
    public readonly extraData: ExtraData.StationEntity
  ) {}

  public assetId: StationEntity.AssetId = this.waxRRContractData.station_id;

  public links = this.waxRRContractData.connected_stations.map<StationEntity.StationLink>(connected_station => ({
    assetId: connected_station.station_id,
    distance: connected_station.distance,
  }));

  public getLinkTo(station: StationEntity | StationEntity.AssetId) {
    const stationId = typeof station === "string" ? station : station.assetId;
    return this.links.find(link => link.assetId === stationId) || null;
  }

  public getDistanceTo(destination: StationEntity) {
    return this.getLinkTo(destination)?.distance;
  }

  public get name() {
    if (__DEBUG__ && __DEBUG_SETTINGS__["Station-Ids-Instead-Of-Names"]) {
      return this.waxAssetData.asset_id;
    }
    return this.waxAssetData.station_name;
  }

  public get rarity() {
    return this.waxRRContractData.rarity.trim().toLowerCase() as Rarity;
    // return this.waxAssetData.rarity.trim().toLowerCase() as Rarity;
  }

  /** Rarity, provided as a number from 1 to 6 (incl.) */
  public get rarityLevel() {
    return Rarity.toNumberFromOne(this.rarity) as 1 | 2 | 3 | 4 | 5 | 6;
  }

  public get region() {
    return this.waxAssetData.region.trim().toLowerCase();
  }

  public get regionId() {
    return RegionIntegerId.fromRegionName(this.region);
  }

  public get x() {
    return this.extraData.x;
  }

  public get y() {
    return this.extraData.y;
  }

  public get ownerName() {
    return this.waxAssetData.owner;
  }

  public get billboardIPFSHash() {
    return this.waxAssetData.billboard;
  }

  public get addonTier_RailYard(): StakingAddonTier | null {
    return this.waxSContractData.railyard_tier || null;
  }

  public get addonTier_ConductorLounge(): StakingAddonTier | null {
    return this.waxSContractData.lounge_tier || null;
  }

  ////

  public static getDistanceBetween(stationA: StationEntity, stationB: StationEntity) {
    const link = stationA.getLinkTo(stationB) || stationB.getLinkTo(stationA);
    return link?.distance || 0;
  }
}

export declare module StationEntity {
  export type AssetId = WAX.StationAssetId;

  export type StationLink = {
    assetId: WAX.StationAssetId;
    distance: number;
  };
}

//// TODO: Move out
export declare module ExtraData {
  export type StationEntity = {
    // name: string;
    x: number;
    y: number;
  };
}
