import { __window__ } from "../__";

import { markRect } from "@debug/utils/markBounds";
import { onKeyPress } from "@debug/utils/onKeyPress";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { makeTextureFromSVGElementAsync } from "@sdk/pixi/utils/makeTextureFromSVGElement";
import { drawBarChart as drawBarChartSVG } from "../../game/ui/svg-charts/drawBarChartSVG";
import { drawLineChart as drawLineChartSVG } from "../../game/ui/svg-charts/drawLineChartSVG";
import { StationDashboardChartsDataAdapter } from "@game/ui/popups/data/StationDashboardChartsDataAdapter";
import { range } from "@sdk/utils/range";
import { lipsum } from "@debug/utils/lipsum";
import { debugShowHTMLElement } from "@debug/html/debugShowHTMLElement";
import { GameSingletons } from "@game/app/GameSingletons";

interface ChartDataPoint {
  x: any;
  y: any;
}

const SIZE_WE_WANT_BOTH_CHARTS_TO_BE = {
  width: 600,
  height: 300,
};
const POSITION_FOR_CHART_1 = {
  x: 50,
  y: 50,
};
const POSITION_FOR_CHART_2 = {
  x: 50,
  y: 400,
};

export function makeTempBackground(width: number, height: number) {
  /**
   * This is just a 16x16 pixel pure white texture that PixiJs has
   * built in for some of its core functions.
   */
  const texture = Texture.WHITE;

  /**
   * We make an actual "DisplayObject" with that texture slapped onto it.
   */
  const sprite = new Sprite(texture);

  /**
   * Apply the given size options.
   */
  sprite.width = width;
  sprite.height = height;

  /**
   * Make the thing very dark gray, instead of white.
   *
   * For ref:
   *
   * https://pixijs.download/dev/docs/PIXI.Sprite.html#tint
   *
   * https://scottmcdonnell.github.io/pixi-examples/index.html?s=demos&f=tinting.js&title=Tinting
   */
  sprite.tint = 0x202030;

  sprite.alpha = 0.9;

  return sprite;
}

export async function addLineAndBarChartsToStage(stage: Container) {
  //// Add the backgrounds, so we can see where we want the charts to fit for sure ////

  const bg1 = makeTempBackground(SIZE_WE_WANT_BOTH_CHARTS_TO_BE.width, SIZE_WE_WANT_BOTH_CHARTS_TO_BE.height);
  bg1.position.set(POSITION_FOR_CHART_1.x, POSITION_FOR_CHART_1.y);
  stage.addChild(bg1);

  const bg2 = makeTempBackground(SIZE_WE_WANT_BOTH_CHARTS_TO_BE.width, SIZE_WE_WANT_BOTH_CHARTS_TO_BE.height);
  bg2.position.set(POSITION_FOR_CHART_2.x, POSITION_FOR_CHART_2.y);
  stage.addChild(bg2);

  //// Add them charts ////

  function makeLineChartOutOfData(data: ChartDataPoint[]) {
    return drawLineChartSVG(
      data,
      SIZE_WE_WANT_BOTH_CHARTS_TO_BE.width,
      SIZE_WE_WANT_BOTH_CHARTS_TO_BE.height,
      "#ff00ff"
    );
  }
  const chart_Line = makeChartService(makeLineChartOutOfData);
  chart_Line.container.position.set(POSITION_FOR_CHART_1.x, POSITION_FOR_CHART_1.y);
  stage.addChild(chart_Line.container);

  function makeBarChartOutOfData(data: ChartDataPoint[]) {
    const xMax = Math.max(...data.map(d => d.x));
    return drawBarChartSVG(data, SIZE_WE_WANT_BOTH_CHARTS_TO_BE.width, SIZE_WE_WANT_BOTH_CHARTS_TO_BE.height, xMax, 10);
  }
  const chart_Bar = makeChartService(makeBarChartOutOfData);
  chart_Bar.container.position.set(POSITION_FOR_CHART_2.x, POSITION_FOR_CHART_2.y);
  stage.addChild(chart_Bar.container);

  //// Update the charts with some data ////

  function refreshCharts(hr: number) {
    chart_Line.update(makeRandomDataForLineChart(hr));
    chart_Bar.update(makeRandomDataForBarChart(hr).sort((a, b) => a.x - b.x));
  }
  refreshCharts(24);

  /**
   * Press [SPACE] to update the charts with new randomized data.
   */
  // onKeyPress(" ", () => refreshCharts(24));

  ////

  __window__.chartsDataAdapter = new StationDashboardChartsDataAdapter();

  __window__.chart_Line = chart_Line;
  __window__.chart_Bar = chart_Bar;
  __window__.refreshCharts = refreshCharts;
  __window__.makeRandomDataForLineChart = makeRandomDataForLineChart;
  __window__.makeRandomDataForBarChart = makeRandomDataForBarChart;

  __window__.foo = () => {
    range(10).forEach(i => {
      setTimeout(() => {
        const data = makeRandomDataForBarChart(10 ** i, i + 1);
        data.sort((a, b) => a.x - b.x);
        chart_Bar.update(data);
      }, i * 250);
    });
  };
  onKeyPress(" ", () => __window__.foo());
}

export function makeRandomDataForLineChart(hr: number = 10) {
  console.log("makeRandomDataForLineChart", hr);

  hr = 12;
  var d = Math.floor(Math.random() * 10);

  function randomValue() {
    return Math.random() * 100;
  }

  return range(hr).map(i => {
    var date = new Date();
    var randDate = date.getDate() + (i + d);
    date.setDate(randDate);
    d++;
    return {
      x: date,
      y: randomValue(),
    };
  });
}

export function makeRandomDataForBarChart(hr: number = 10, users: number = 5) {
  console.log("makeRandomDataForBarChart", hr);

  hr ||= 999;

  function randomValue() {
    return Math.random() * 10 * hr;
  }

  return lipsum.userNames.slice(0, users).map(username => {
    return {
      x: randomValue(),
      y: username,
    };
  });

  return [
    {
      x: 100,
      y: "johm",
    },

    {
      x: 29,
      y: "Ham414",
    },
    {
      x: 7,
      y: "Ham32",
    },
    {
      x: 9,
      y: "Ham424",
    },
    {
      x: 7,
      y: "Ham322",
    },

    {
      x: 27,
      y: "Ham3122",
    },
    {
      x: 17,
      y: "Ham3322",
    },
    {
      x: 23,
      y: "Ham31122",
    },
  ];
}

function makeChartService(dataToChartSvgFunc: (data: ChartDataPoint[]) => SVGSVGElement) {
  const container = new Container();

  function clear() {
    const children = container.children;
    for (const child of children) {
      child.destroy({ children: true, texture: true });
    }
    container.removeChildren();
  }

  async function update(data: ChartDataPoint[]) {
    clear();

    const svg = dataToChartSvgFunc(data);
    const texture = await makeTextureFromSVGElementAsync(svg);
    const sprite = new Sprite(texture);
    container.addChild(sprite);

    debugShowHTMLElement(svg);

    // For debugging, mark the bounds of the sprite
    // which is the same thing as the bounds of the svg texture
    // They should fit the dark rectangle backgrounds we made above
    markRect(container, { x: 0, y: 0, width: texture.width, height: texture.height }, undefined, 2);
  }

  return {
    container,
    update,
    clear,
  };
}
