import { __window__ } from "@debug";
import { Application } from "@pixi/app";
import { BLEND_MODES } from "@pixi/constants";
import { Filter, Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { BulgePinchFilter } from "@pixi/filter-bulge-pinch";
import { TwistFilter } from "@pixi/filter-twist";
import { Sprite } from "@pixi/sprite";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { EnchantmentGlobals } from "@sdk/pixi/enchant/EnchantmentGlobals";
import { CallbackList } from "@sdk/utils/callbacks/CallbackList";
import { unlerp, unlerpClamped } from "@sdk/utils/math";
import { gsap } from "gsap";

let w = 100;
let h = 100;

export function createLoadingVortexAnimation(app: Application) {
  const onEnterFrameCallbacks = new CallbackList<(dt: number) => unknown>();
  const factory = new LoadingVortexAnimationComponentFactory({
    register(target) {
      const oef = (dt: number) => {
        if (!target.parent) return;
        if (target.destroyed) stopFrameLoop();
        else target.advanceTime(dt);
      };
      const stopFrameLoop = onEnterFrameCallbacks.add(oef);
      oef(0);
      return target;
    },
  });

  const container = new Container();
  const tweeener = new TemporaryTweeener(container);

  let state = { isOn: true, speed: 1 };

  const twistedContainer = factory.createTwistedContainer(state);
  container.addChild(twistedContainer);

  async function loadVortexAnimationResources() {
    //await tweeener.delayedCall(0.3, console.log, [`fake loading complete..`]);
    return {
      nebulaWave: await Texture.fromURL("assets/images/nebula/oa.jpg"),
      blackWave: await Texture.fromURL("assets/images/nebula/oo.png"),
      vignette: await Texture.fromURL("assets/images/nebula/strong-vignette.png"),
      centerBlob: await Texture.fromURL("assets/images/nebula/blob.png"),
      particlesA: await Texture.fromURL("assets/images/nebula/dash.png"),
      particlesB: await Texture.fromURL("assets/images/nebula/dash.png"),
      particlesC: await Texture.fromURL("assets/images/nebula/dash.png"),
      particlesA2: await Texture.fromURL("assets/images/nebula/rotated-blink-b.png"),
      particlesB2: await Texture.fromURL("assets/images/nebula/rotated-blink-b.png"),
    };
  }

  function initializeVortexAnimation(textures: Awaited<ReturnType<typeof loadVortexAnimationResources>>) {
    const tl = tweeener.createTimeline();

    const spaceWaves = factory.createWavyLayer(
      textures.nebulaWave,
      {
        blendMode: BLEND_MODES.ADD,
        alpha: 0.5,
      },
      {
        velocity: 2.5,
        alphaIncr: 0,
        randRotation: true,
        period: 80,
      }
    );

    const blackWaves = factory.createWavyLayer(
      textures.blackWave,
      {
        blendMode: BLEND_MODES.MULTIPLY,
        alpha: 0.4,
      },
      {
        velocity: 2.5,
        alphaIncr: 0,
        scale: 4,
      }
    );

    const twistedVignette = factory.createFullScreenLayer({
      texture: textures.vignette,
    });

    const centerBlob = factory.createFullScreenLayer({
      scale: 0.35,
      texture: textures.centerBlob,
      fit: true,
    });

    const particlesContainer = new Container();

    const particlesA = factory.createParticleLayer(
      textures.particlesA2,
      { blendMode: BLEND_MODES.ADD },
      { velocity: 0.8, scaleX: 12, scaleY: 12, lean: 1 / 3 }
    );
    const particlesB = factory.createParticleLayer(
      textures.particlesB2,
      { blendMode: BLEND_MODES.ADD },
      { velocity: 2.4, scaleX: 16, scaleY: 4, lean: 2 / 3 }
    );
    const particlesC = factory.createParticleLayer(
      textures.particlesC,
      { blendMode: BLEND_MODES.ADD },
      { velocity: 2.9, scaleX: 0.2, scaleY: 2.5, maxPeriod: 40.4 }
    );

    tl.call(() => void twistedContainer.addChild(spaceWaves));
    tl.call(() => void twistedContainer.addChild(blackWaves));
    tl.call(() => void twistedContainer.addChild(particlesContainer));
    tl.call(() => void twistedContainer.addChild(centerBlob));
    tl.call(() => void twistedContainer.addChild(twistedVignette));
    tl.call(() => void particlesContainer.addChild(particlesA), undefined, 0.7);
    tl.call(() => void particlesContainer.addChild(particlesB), undefined, 1.1);
    tl.call(() => void particlesContainer.addChild(particlesC), undefined, 1.5);
    return tl.play();
  }

  const loadingPromise = loadVortexAnimationResources();
  loadingPromise.then(initializeVortexAnimation);

  const screenVignette = factory.createSpot(Texture.from("assets/images/nebula/whitespot.jpg"));
  container.addChild(screenVignette);

  const logo = factory.createTrainOfTheCenturyLogo(state);
  container.addChild(logo);

  const dimmer = factory.createDimmer();
  container.addChild(dimmer);

  const result = Object.assign(container, {
    logo,
    loadingPromise: loadingPromise,
    isOn() {
      return state.isOn;
    },
    turnOff() {
      state.isOn = false;
      const tl = tweeener.createTimeline();
      tl.to(state, { speed: 0.3, duration: 0.6, ease: "power2.out" });
      tl.to(state, { speed: 12.0, duration: 0.5, ease: "power2.in" });
      tl.to(dimmer, { alpha: 1.0, duration: 0.3, ease: "power.in" });
      return tl.play();
    },
    turnOn() {
      state.isOn = true;
      gsap.fromTo(dimmer, { alpha: 1.0 }, { alpha: 0.0, duration: 1.4, ease: "power.inOut" });
      gsap.fromTo(state, { speed: 2.0 }, { speed: 1.0, duration: 1.4, ease: "power.inOut" });
    },
    onEnterFrame() {
      if (document.visibilityState !== "visible") return;

      w = app.view.width / app.renderer.resolution;
      h = app.view.height / app.renderer.resolution;

      const dt = 50 * Math.min(EnchantmentGlobals.timeDelta, 0.05);
      onEnterFrameCallbacks.callAll(state.speed * dt);
    },
  });

  return result;
}

type ITimeAdvancer = {
  register<
    T extends {
      advanceTime(dt: number): unknown;
      destroyed: boolean;
      parent?: unknown | null;
    }
  >(
    target: T
  ): T;
};
export class LoadingVortexAnimationComponentFactory extends Container {
  constructor(public readonly ticker: ITimeAdvancer) {
    super();
  }

  createTwistedContainer(state: any) {
    type _TwistFilter = TwistFilter & { angleMul?: number; scale: number; cover?: boolean };
    type _PinchFilter = BulgePinchFilter & { scale: number; cover?: boolean };
    const twistedContainer = new Container();
    twistedContainer.filters = [
      //Object.assign(new BulgePinchFilter({ strength: -1 }), { scale: 0.4, cover: true }),
      //Object.assign(new TwistFilter({ angle: 1 }), { scale: 0.9, cover: true }),
      Object.assign(new TwistFilter(), { angleMul: 3.0, scale: 0.9 }),
      // Object.assign(new TwistFilter(), { angleMul: 1.0, scale: 0.5, cover: false }),
      // Object.assign(new TwistFilter(), { angleMul: 1.0, scale: 0.6, cover: false }),
    ];
    const result = Object.assign(twistedContainer, {
      twistMul: 1,
      advanceTime() {
        twistedContainer.position.set(0.5 * w, 0.5 * h);
        for (const filter of twistedContainer.filters as (Filter | _TwistFilter | _PinchFilter)[]) {
          filter.resolution = 0.5;

          if ("offset" in filter) {
            filter.offset.set(0.5 * w, 0.5 * h);
          }

          if ("angle" in filter) {
            const { angleMul = 1.0 } = filter;
            const speedMul = 1.0 - 0.25 * unlerpClamped(1, 12, state.speed);
            const ratio = Math.min(w / h, h / w);
            filter.angle = speedMul * angleMul * result.twistMul * ratio;
          }

          if ("center" in filter) {
            filter.strength = -10;
            filter.center = [0.5 * w, 0.5 * h];
          }

          if ("radius" in filter) {
            if (filter.cover) {
              filter.radius = filter.scale * Math.max(w, h);
            } else {
              filter.radius = filter.scale * Math.min(w, h);
            }
          }
        }
      },
    });

    return this.ticker.register(result);
  }

  createFullScreenLayer({
    tint = 0xffffff,
    scale = 1,
    texture = Texture.WHITE as Texture,
    blendMode = BLEND_MODES.NORMAL,
    anchor = 0.5,
    fit = false,
  }) {
    const bg = new Sprite(texture);
    bg.anchor.set(anchor);
    bg.tint = tint;
    bg.blendMode = blendMode;
    const result = Object.assign(bg, {
      advanceTime() {
        if (fit) {
          const vmax = Math.max(scale * w, scale * h);
          bg.width = vmax;
          bg.height = vmax;
        } else {
          bg.width = scale * w;
          bg.height = scale * h;
        }
      },
    });

    return this.ticker.register(result);
  }

  createSpot(spotTexture: Texture) {
    const spot = new Sprite(spotTexture);
    spot.anchor.set(0.5);
    spot.blendMode = BLEND_MODES.MULTIPLY;
    const result = Object.assign(spot, {
      advanceTime() {
        const tw = spotTexture.width;
        const ratio = 1.0 - Math.min(w / h, h / w);
        spot.position.set(w / 2, h / 2);

        const scaleA = (1.5 * Math.max(w, h)) / tw;
        const scaleB = (1.3 * Math.min(w, h)) / tw;
        spot.scale.set(Math.max(scaleA, scaleB));
      },
    });
    return this.ticker.register(result);
  }

  createWavyLayer(
    texture: Texture,
    mods: Partial<Sprite>,
    { velocity = 1, alphaIncr = 0.0003, rotationIncr = 0.0, randRotation = false, period = 75, scale = 1 }
  ) {
    const container = new Container();

    const maxProgress = 3.5;
    const spawnWave = () => {
      const o = new Sprite(texture);
      o.anchor.set(0.5);
      if (randRotation) {
        o.angle = Math.random() * 360;
      }
      Object.assign(o, mods);

      let time = 0;
      let a = o.alpha;

      o.alpha = 0;

      return this.ticker.register(
        Object.assign(o, {
          advanceTime(dt: number) {
            time += dt * velocity;
            const progress = 0.005 * time;
            a += alphaIncr;

            o.scale.set(scale * progress ** 2);
            o.rotation += rotationIncr * dt;
            o.alpha = a;

            if (progress > maxProgress) {
              o.destroy();
            }
          },
        })
      );
    };

    let time = 0;
    let next = 0;

    return this.ticker.register(
      Object.assign(container, {
        advanceTime(dt: number) {
          time += dt * velocity;
          if (time > next) {
            next += period;
            const wave = spawnWave();
            container.addChild(wave);
          }
        },
      })
    );
  }

  createParticleLayer(
    texture: Texture,
    mods: Partial<Sprite>,
    { velocity = 1, scaleX = 1, scaleY = 1, maxPeriod = 21, randColor = true, lean = 1 }
  ) {
    const container = new Container();

    const spawnParticle = () => {
      const o = new Sprite(texture);
      o.anchor.set(0.0, 0.5);
      o.scale.set(4);
      container.addChild(o);
      Object.assign(o, mods);

      if (randColor) {
        o.tint = ~~(Math.random() * 0xffffff);
      }

      const angle = Math.random() * Math.PI * 2;
      o.rotation = angle;

      const direction = {
        x: Math.cos(angle),
        y: Math.sin(angle),
      };
      let time = 0;

      return this.ticker.register(
        Object.assign(o, {
          advanceTime(dt: number) {
            time += dt * velocity;
            const progress = 0.003 * time;

            o.alpha = Math.pow(progress * velocity, lean);
            o.scale.set(scaleX * progress, scaleY * progress);
            o.position.set(1000 * progress * direction.x, 1000 * progress * direction.y);

            if (progress > 2.0) {
              o.destroy();
            }
          },
        })
      );
    };

    let time = 0;
    let next = 0;

    return this.ticker.register(
      Object.assign(container, {
        advanceTime(dt: number) {
          time += dt * velocity;
          if (time > next) {
            next += Math.random() * maxPeriod;
            spawnParticle();
          }
        },
      })
    );
  }

  createTrainOfTheCenturyLogo(state: { speed: number }) {
    const container = new Container();

    const loadTextures = async () => {
      const textureInner = await Texture.fromURL("assets/images/nebula/toc1-inner.png");
      const textureOuter = await Texture.fromURL("assets/images/nebula/toc1-outer.png");
      return { textureInner, textureOuter };
    };

    loadTextures().then(({ textureInner, textureOuter }) => {
      const partInner = new Sprite(textureInner);
      const partOuter = new Sprite(textureOuter);

      const color = 0x3a4a8a;
      partInner.tint = color;
      partOuter.tint = color;

      partInner.anchor.set(0.5);
      partOuter.anchor.set(0.5);

      container.addChild(partOuter, partInner);

      result.advanceTime = dt => {
        partOuter.rotation -= 0.05 * dt;
        container.position.set(w * 0.5, h * 0.5);

        //const scale = con.alpha * 0.2 + 0.05 * Math.sin(0.5 * partOuter.rotation);
        const scale = 0.05 + state.speed * 0.1 + 0.05 * Math.sin(0.5 * partOuter.rotation);
        container.scale.set(scale);
        partOuter.scale.set(0.9 + 0.1 * Math.pow(state.speed, 4));

        partOuter.alpha = 2 - state.speed;
        partInner.alpha = 4 - state.speed;
      };

      result.advanceTime(0);
      result.onLoaded?.();
    });

    const result = Object.assign(container, {
      onLoaded: null as null | (() => void),
      advanceTime(_: number) {},
    });

    return this.ticker.register(result);
  }

  createDimmer() {
    const sprite = new Sprite(Texture.WHITE);
    sprite.tint = 0x0;

    return this.ticker.register(
      Object.assign(sprite, {
        advanceTime() {
          sprite.width = w;
          sprite.height = h;
        },
      })
    );
  }
}
