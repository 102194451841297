import { Sprite } from "@pixi/sprite";
import { TextureId } from "@game/constants/paths/TextureId";
import { GameSingletons } from "@game/app/GameSingletons";

//// Just because it's nice to have a nice vignette.
export function addVignette(): void {
  const context = GameSingletons.getGameContext();
  const {
    app: { stage },
    assets,
    ticker,
    animator,
    viewSize,
  } = context;
  assets.assureTextureLoaded(TextureId.Vignette).then(texture => {
    const vignette = new Sprite(texture);
    vignette.name = "vignette";
    vignette.tint = 0x0;
    vignette.alpha = 0.6969;
    context.stageContainers._worldVignette.addChild(vignette);
    ticker.add(() => {
      vignette.width = viewSize.width;
      vignette.height = viewSize.height;
    });
  });
}
