import { TrainCondition } from "./TrainCondition";

export module TrainConditionColors {
  export const LOW = 0x6e1919;
  export const MEDIUM = 0xffc600;
  export const HIGH = 0x1dd4e4;

  export function getColorForTrainCondition(conditionFraction: number): number {
    if (conditionFraction < TrainCondition.LOW_LEVEL) {
      return LOW;
    } else if (conditionFraction < TrainCondition.OK_LEVEL) {
      return MEDIUM;
    } else {
      return HIGH;
    }
  }
}
