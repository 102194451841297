import { Container } from "@pixi/display";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { LightDot } from "./LightDot";

export class LightDotSlideShow extends Container {
  public onDotClick?: (dotIndex: number) => void;

  constructor(numberOfSlides: number) {
    super();
    let startY = 0;
    for (let i = 0; i < numberOfSlides; i++) {
      const container = this.createDotContainer(i);
      this.addChild(container);
      container.position.set(0, startY);
      startY += 25;
    }
  }

  createDotContainer(index: number) {
    const container = new LightDot();
    container.inactive();
    container.scale.set(0.65);

    buttonizeDisplayObject(container, () => this.onDotClick?.(index));

    return container;
  }

  activeDot(dotIndex: number) {
    for (const [index, dot] of this.children.entries()) {
      (dot as LightDot).inactive();
      if (index == dotIndex) {
        (dot as LightDot).active();
      }
    }
  }
}
