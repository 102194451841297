import type * as WAX from "@sdk-integration/contracts";

import type { StationEntity } from "@game/data/entities/StationEntity";
import type { TrainEntity } from "@game/data/entities/TrainEntity";
import type { ReadonlyDeep } from "type-fest";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";
import { UserDataHolder } from "../UserDataHolder";
import { GameSingletons } from "@game/app/GameSingletons";
import { getLocomotiveCardAttributes } from "@game/asorted/data/getLocomotiveCardAttributes";
import { TrainCondition } from "@game/constants/TrainCondition";

export class PotentialRailRunStats {
  private readonly vars: ReadonlyDeep<WAX.GameMathVars>;
  private readonly userData: ReadonlyDeep<UserDataHolder>;

  distance: number = NaN;
  fuelCost: number = NaN;
  duration: number = NaN;
  isBacktracking: boolean = false;

  readonly errors: string[] = [];

  constructor(
    public train?: ReadonlyDeep<TrainEntity>,
    public departureStation?: ReadonlyDeep<StationEntity>,
    public destinationStation?: StationEntity
  ) {
    const { gameConfigData, userData } = GameSingletons.getDataHolders();
    this.vars = gameConfigData.vars;
    this.userData = userData;

    this.update();
  }

  addError(msg: string) {
    this.errors.unshift(msg);
  }

  update(train = this.train, departureStation = this.departureStation, destinationStation = this.destinationStation) {
    /// Reset all values
    this.distance = NaN;
    this.fuelCost = NaN;
    this.duration = NaN;
    this.errors.length = 0;

    const addError = this.addError.bind(this);

    this.train = train;
    this.departureStation = departureStation;
    this.destinationStation = destinationStation;
    if (!train) {
      return addError(`Train is ${train}`);
    }
    if (!departureStation) {
      return addError(`Departure station is ${departureStation}`);
    }
    if (!destinationStation) {
      return addError(`Destination station is ${destinationStation}`);
    }

    const vars = this.vars;
    if (vars == null) {
      return addError("Game Vars not loaded from contracts.century contract");
    }

    if (!train.locomotive || !train.locomotiveStats) {
      return addError("Train has no locomotive");
    }
    if (!train.conductor || !train.conductorStats) {
      return addError("Train has no conductor");
    }

    const waxConnectedStationStats = departureStation.getLinkTo(destinationStation.assetId);
    if (waxConnectedStationStats == null) {
      return addError("Stations are not connected to each other via a valid rail road.");
    }

    const distance = waxConnectedStationStats.distance;

    //// Calculate the fuel cost

    const isBacktracking = train.waxData.previous_station === destinationStation.assetId;
    const potentialBacktrackingMultiplier = isBacktracking ? 0.01 * vars.backtrack_m : 1;

    const trainTotalCommoditiesWeight = train.waxData.weight;
    const fuelCostPrecise = Math.floor(
      vars.fuel_b * distance + trainTotalCommoditiesWeight * distance * vars.fuel_m * 0.01
    );

    const validFuelTypes = ["coal", "diesel", "unknown"];
    const fuelType = train.fuelTypeLowerCase;
   
    //Century Train Fuel Conditional
    let fuelCost;

    if (fuelType == "unknown" ) {
      fuelCost = 0;
    } else { fuelCost = potentialBacktrackingMultiplier * fuelCostPrecise * 0.0001;  }

    //// Calculate the duration
    const locoConditionMultiplier = TrainCondition.calculateLocomotiveSpeedMultiplier(train);
    const locoSpeed = getLocomotiveCardAttributes(train.locomotive).speed.total;
    const conductorSpeedPerk = train.conductorStats.perkBoost_Speed;
    const conductorMultiplier = 1 + conductorSpeedPerk / 100;
    const trainTotalSpeed = locoSpeed * conductorMultiplier * locoConditionMultiplier;
    const duration = (vars.time_b * distance) / (trainTotalSpeed * vars.time_m * 0.01);

    //// Set the values

    this.distance = distance;
    this.fuelCost = fuelCost;
    this.duration = duration;
    this.isBacktracking = isBacktracking;

    const maxTrainDistance = train.locomotiveStats?.distance ?? 0;
    if (maxTrainDistance < distance) {
      addError(`Train has max distance of ${maxTrainDistance} but this run requires ${distance}`);
    }

   
    if (fuelType == null || !validFuelTypes.includes(fuelType)) {
      return addError(`Train fuel type is invalid (${fuelType}).\n"diesel" or "coal" expected.`);
    }




    const fuelData = this.userData.fuel[fuelType];

   

    if (fuelData == undefined) {
      return addError(`Account has no fuel of type "${fuelType}"`);
    }
    const availableFuel = fuelData ?? 0;
    if (availableFuel < fuelCost) {
      addError(
        `Account has ${formatToMaxDecimals(availableFuel)} fuel but this run requires ${formatToMaxDecimals(fuelCost)}`
      );
    }
 
  }
}
