import { GameSingletons } from "@game/app/GameSingletons";
import { TrainCondition } from "@game/constants/TrainCondition";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { ReadonlyDeep } from "type-fest";
import * as WAX from "@sdk-integration/contracts";

export async function performTrainRepair(train: ReadonlyDeep<TrainEntity>) {
  const { userDataCtrl, gameConfigData, contracts, ticker, spinner } = GameSingletons.getGameContext();

  const costAmount = TrainCondition.calculateRepairPrice(train);
  const costField = `${costAmount.toFixed(4)} TOCIUM`;
  const promise = contracts.actions.performActionTransactions([
    [
      WAX.ActionName.Transfer,
      {
        from: contracts.currentUserName,
        to: WAX.ContractName.M,
        quantity: costField,
        memo: "DEPOSIT|" + contracts.currentUserName,
      },
      WAX.ContractName.Toc,
    ],
    [
      "repair",
      {
        railroader: contracts.currentUserName,
        train: train.name,
      },
      WAX.ContractName.RR,
    ],
  ]);
  await spinner.showDuring(promise);
  await ticker.delay(0.15);

  // Update the train optimistically to avoid unnecessary wait for data refresh
  TrainEntity.overrideData(train, { condition: gameConfigData.vars.max_cond as WAX.integer });

  // Refresh the data in the background just in case though
  userDataCtrl.updateAll();
}
