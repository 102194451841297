import { GameSingletons } from "@game/app/GameSingletons";
import { createUserPreferencesFirebaseStorageProxy } from "@game/ui/railroader-dash/panels/settings/createUserPreferencesFirebaseStorageProxy";
import { WaxContractActionService } from "@sdk-integration/contracts/WaxContractActionService";

export async function claimAllRailRuns() {
  const WAX = await import("@sdk-integration/contracts");
  const remotePrefs = await createUserPreferencesFirebaseStorageProxy();
  const { contracts, userDataCtrl, userData, mapData, spinner, modals } = GameSingletons.getGameContext();

  type TransactionParams = Parameters<WaxContractActionService["performActionTransaction"]>;
  const transactions = new Array<TransactionParams>();
  for (const train of userData.trainsArray) {
    if (train.getInvalidReason()) continue;

    if (!train.currentOngoingRun) continue;
    if (!train.currentOngoingRun.isReadyToClaim) continue;

    const run = train.currentOngoingRun;
    const station = mapData.stations.get(run.destination);

    transactions.push([
      WAX.ActionName.ClaimRailRunReward,
      {
        railroader: contracts.currentUserName,
        train: train.name,
        owner: station!.ownerName,
        auto_repair: remotePrefs.autorepairEnabled,
      },
      WAX.ContractName.RR,
    ]);
  }

  if (!transactions.length) return;

  const promise = contracts.actions.performActionTransactions(transactions);
  const transactionsResult = await spinner.showDuring(promise);

  console.log(`transactionsResult`, transactionsResult);

  const rewards = transactionsResult.transaction?.processed?.action_traces
    ?.map((trace: any) => [
      trace?.act?.data?.train,
      trace?.inline_traces?.find((o: any) => o.act.account == "bank.century")?.act?.data?.quantity,
    ])
    .filter((o: any) => o[0] && o[1]) as [string, string][];

  console.log(`rewards`, rewards);

  if (!rewards || !rewards.length) return;

  await Promise.all([
    modals.alert({
      title: `Total Reward`,
      content: rewards.map(([train, reward]) => `${train.toUpperCase()}: ${reward}`).join("\n"),
    }),
    userDataCtrl.updateAll(),
  ]);
}
