import { GameSingletons } from "@game/app/GameSingletons";

const SCHEMA_NAME = "modifier";

function isTemplateEssenceType(raw: any): boolean {
  return raw.immutable_data?.type.toLowerCase() == "essence";
}

function isTemplateSummonType(raw: any): boolean {
  return raw.immutable_data?.type.toLowerCase() == "summon";
}

export class RunModDataService {
  async getSummonsData() {
    const { contracts, gameConfigData } = GameSingletons.getGameContext();
    const rawResults = await contracts.assets.getTemplatesBySchemaName(SCHEMA_NAME);

    return rawResults
      .filter(isTemplateSummonType)
      .map(raw => {
        const ipfs = raw.immutable_data?.img;

        const effectTarget = raw.immutable_data?.affects || "?";
        const effect = `Summons ${effectTarget}`;

        const cfg = gameConfigData.runModsMarketData.get(+raw.template_id);

        console.warn(raw.template_id, cfg);

        const active = !!(cfg?.isSaleActive() && cfg?.priceAmount);

        return {
          templateId: +raw.template_id,

          name: raw.immutable_data?.name,
          description: raw.immutable_data?.description,

          minMint: +(raw.issued_supply || 0),
          nftURL: `https://atomichub-ipfs.com/ipfs/${ipfs}`,

          price: cfg?.priceAmount ?? NaN,

          effect,

          burnt: "?",

          saleActive: active,
          nextSaleStart: cfg?.getNextSaleStartSeconds() || NaN,
        };
      })
      .sort((a, b) => Number(b.saleActive) - Number(a.saleActive));
  }

  async getEssencesData() {
    const { contracts, gameConfigData } = GameSingletons.getGameContext();
    const rawResults = await contracts.assets.getTemplatesBySchemaName(SCHEMA_NAME);

    return rawResults
      .filter(isTemplateEssenceType)
      .map(raw => {
        const ipfs = raw.immutable_data?.img;

        const effectPercentage = raw.immutable_data?.boost ?? "?";
        const effectStat = (raw.immutable_data?.affects || "?").toLowerCase();
        const effect = `+${effectPercentage}% ${effectStat}`;

        const cfg = gameConfigData.runModsMarketData.get(+raw.template_id);

        console.warn(raw.template_id, cfg);

        return {
          templateId: +raw.template_id,

          name: raw.immutable_data?.name,
          description: raw.immutable_data?.description,

          minMint: +(raw.issued_supply || 0),
          nftURL: `https://atomichub-ipfs.com/ipfs/${ipfs}`,

          price: cfg?.priceAmount ?? NaN,

          effect,

          burnt: "?",

          saleActive: cfg?.isSaleActive() || false,
          nextSaleStart: cfg?.getNextSaleStartSeconds() || NaN,
        };
      })
      .sort((a, b) => Number(b.saleActive) - Number(a.saleActive));
  }
}
