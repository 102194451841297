import { StakingAddonType } from "@game/asorted/StakingType";
import { PotentialRailRunStats } from "@game/data/entities/PotentialRailRunStats";
import { RailRunEntity } from "@game/data/entities/RailRunEntity";
import { StationEntity } from "@game/data/entities/StationEntity";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { RailroaderDashPanelType } from "@game/ui/railroader-dash/panels/models";
import { InteractionData } from "@pixi/interaction";
import { EventBus } from "@sdk/core/EventBus";
import { BuildingEntity } from "@game/data/entities/BuildingEntity";
import { bldgHouse } from "@game/ui/buildings/bldgHouse";

export function makeInputService() {
  return new EventBus<{
    clickOnStation: (station: StationEntity, event: InteractionData) => unknown;

    
    clickOnBuilding: (building: BuildingEntity, event:InteractionData ) => unknown;
    /* selectBuilding: (building: BuildingEntity | null, detailed?: boolean) => unknown; */
    enterBuilding:  (bldgHouse: BuildingEntity) => unknown;
    locateBuilding: (building: BuildingEntity | null) => unknown;
    immersedMode: (event?:InteractionData) => unknown;
    restoreHUD: (buildingName:string) => unknown;
    returnToMap: () => unknown;

    nightModeToggle: () => unknown;

    enterEditTrainView: (train: TrainEntity) => unknown;
    enterLoadingDockView: (train: TrainEntity) => unknown;
    enterNextStopView: (train: TrainEntity) => unknown;
    enterDispatchView: (train: TrainEntity, destination: StationEntity) => unknown;
    toggleInstantTransmissionView: (train: TrainEntity) => unknown;

    selectTrain: (train: TrainEntity | null) => unknown;
    selectStation: (station: StationEntity | null, detailed?: boolean) => unknown;
    selectDestination: (station: StationEntity | null) => unknown;
    resetSelection: () => unknown;

    openStationDashboard: (station: StationEntity) => unknown;
    openStakingPopup: (station: StationEntity, addonType: StakingAddonType) => unknown;

    startRun: (runStats: PotentialRailRunStats) => unknown;
    startInstantTransmission: () => unknown;
    claimRunRewards: (run: RailRunEntity) => unknown;

    toggleCardsDrawer: () => unknown;
    closeCardsDrawer: () => unknown;

    toggleMarketWindow: () => unknown;
    openMarketWindow: (options: any) => unknown;
    closeMarketWindow: () => unknown;

    toggleRailRunsWindow: () => unknown;
    openRailRunsWindow: () => unknown;
    closeRailRunsWindow: () => unknown;

    toggleRailRoaderDashboard: () => unknown;
    openRailRoaderDashboard: (panel: RailroaderDashPanelType | null) => unknown;
    closeRailRoaderDashboard: () => unknown;

    openSocialProfilePanel: (username?: string) => unknown;
    closeSocialProfilePanel: () => unknown;

    closeEverything: () => unknown;
    locateOnMap: (station: StationEntity | TrainEntity) => unknown;

    clearAndVerifyTrain: (train: TrainEntity) => unknown;

    confirm: () => unknown;

    logOut: () => unknown;
  }>();
}

export type InputService = ReturnType<typeof makeInputService>;
