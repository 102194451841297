import { GameSingletons } from "@game/app/GameSingletons";
import { StationEntity } from "@game/data/entities/StationEntity";
import { StationAssetId } from "@sdk-integration/contracts";

export function getShortestRouteBetweenStations(
  startStation: StationEntity | StationAssetId,
  finalStation: StationEntity | StationAssetId,
  maxDistance: number
) {
  const context = GameSingletons.getGameContext();
  const { mapData } = context;

  if (typeof startStation === "string") startStation = mapData.stations.get(startStation)!;
  if (typeof finalStation === "string") finalStation = mapData.stations.get(finalStation)!;

  return getRoute(startStation, finalStation);

  function getRoute(startStation: StationEntity, finalStation: StationEntity) {
    //// Format Data
    const newG = createGraph(maxDistance);
    //// Get route
    const result = path(newG, startStation.assetId, finalStation.assetId);
    return result;
  }

  function path(graph: any, startNode: StationAssetId, endNode: StationAssetId) {
    // track distances from the start node using a hash object
    let distances: any = {};
    distances[endNode] = "Infinity";
    distances = Object.assign(distances, graph[startNode]);
    // track paths using a hash object
    let parents: any = { endNode: null };
    for (let child in graph[startNode]) {
      parents[child] = startNode;
    }

    // collect visited nodes
    let visited: any = [];
    // find the nearest node
    let node = shortestDistanceNode(distances, visited);

    // for that node:
    while (node) {
      // find its distance from the start node & its child nodes
      let distance = distances[node];
      let children = graph[node];

      // for each of those child nodes:
      for (let child in children) {
        // make sure each child node is not the start node
        if (String(child) === String(startNode)) {
          continue;
        } else {
          // save the distance from the start node to the child node
          let newdistance = distance + children[child];
          // if there's no recorded distance from the start node to the child node in the distances object
          // or if the recorded distance is shorter than the previously stored distance from the start node to the child node
          if (!distances[child] || distances[child] > newdistance) {
            // save the distance to the object
            distances[child] = newdistance;
            // record the path
            parents[child] = node;
          }
        }
      }
      // move the current node to the visited set
      visited.push(node);
      // move to the nearest neighbor node
      node = shortestDistanceNode(distances, visited);
    }

    // using the stored paths from start node to end node
    // record the shortest path
    let shortestPath = [endNode];
    let parent = parents[endNode];
    while (parent) {
      shortestPath.push(parent);
      parent = parents[parent];
    }
    shortestPath.reverse();

    //this is the shortest path
    let results = {
      distance: distances[endNode],
      path: shortestPath,
    };
    // return the shortest path & the end node's distance from the start node
    return results;
  }

  function shortestDistanceNode(distances: any, visited: any) {
    // create a default value for shortest
    let shortest = null;

    // for each node in the distances object
    for (let node in distances) {
      // if no node has been assigned to shortest yet
      // or if the current node's distance is smaller than the current shortest
      let currentIsShortest = shortest === null || distances[node] < distances[shortest];
      // and if the current node is in the unvisited set
      if (currentIsShortest && !visited.includes(node)) {
        // update shortest to be the current node
        shortest = node;
      }
    }
    return shortest;
  }

  function createGraph(maxDistance: number) {
    let graph: Record<StationAssetId, Record<StationAssetId, number>> = {};
    for (let station of context.mapData.stations) {
      let obj: Record<StationAssetId, number> = {};
      for (let link of station[1].links) {
        if (maxDistance > link.distance) {
          obj[link.assetId] = link.distance;
        }
      }
      graph[station[0]] = obj;
    }
    return graph;
  }
}
