import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { EventBannersGallery } from "../banner/EventBannersGallery";
import { getEventBannersGalleryData } from "../banner/EventBannersGalleryData";
import { getRailroaderDashTabsConfiguration } from "./railroaderDashSidemenuConfigurations";

export class RailroaderDashSideMenuLarge extends Container {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);

  constructor(public readonly setTab: (index: number) => void) {
    super();

    this.init();
  }

  init() {
    //// Add background
    const bg = this.context.simpleFactory.createSprite("ui-railroader-dashboard/left-panel-bg.png", {
      x: 0,
      y: 75,
    });
    bg.scale.set(0.7);
    this.addChild(bg);

    //// Add selection tabs
    this.addChild(this.addSelectionTabs());

    //// Add Promotional Event Banner
    this.addPromoBanner();
  }

  async addPromoBanner() {
    const adaptedData = await getEventBannersGalleryData();

    if (!adaptedData || !adaptedData.length) return;

    const bannerGallery = new EventBannersGallery(adaptedData);
    this.addChild(bannerGallery);
  }

  addSelectionTabs(): Container {
    const container = new Container();
    let startY = 550;
    let startX = 25;

    const tabsCfg = getRailroaderDashTabsConfiguration();
    for (const [tabKey, { largeMenuTexture, disabled }] of Object.entries(tabsCfg)) {
      const sprite = this.context.simpleFactory.createSprite(largeMenuTexture, {
        x: startX,
        y: startY,
      });
      sprite.scale.set(0.7);
      if (!disabled) {
        buttonizeDisplayObject(sprite, () => this.setTab(+tabKey - 1));
      } else {
        sprite.tint = 0x7e7e7e;
      }
      container.addChild(sprite);
      startX += sprite.width + 12.5;

      if (+tabKey % 3 == 0) {
        startX = 25;
        startY += 100;
      }
    }
    return container;
  }

  playShowAnimation() {
    return this.tweeener.fromTo(
      this.pivot,
      {
        x: 500,
      },
      {
        delay: 0.2,
        x: 0,
        duration: 1.3,
        ease: "bounce.out",
      }
    );
  }

  playHideAnimation() {
    return this.tweeener.fromTo(
      this.pivot,
      {
        x: 0,
      },
      {
        x: 500,
        duration: 0.2,
        ease: "power2.in",
      }
    );
  }
}
