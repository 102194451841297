import { testTheModals } from "@debug/experiments/testTheModals";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { formatTimeDurationHumanReadable } from "@game/asorted/formatTimeDurationHumanReadable";
import { FontFamily } from "@game/constants/FontFamily";
import { RailRunEntity } from "@game/data/entities/RailRunEntity";
import { DropdownMenuButtonOptions } from "@game/ui/popups/components/DropdownMenu";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { ContractName } from "@sdk-integration/contracts";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { Contract } from "ethers";
import { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";
import { RunModMiniPin } from "./RunModsMiniPin";
import { TrainPinConditionCircle } from "./TrainPinConditionCircle";

export interface RunModMenuItem {
  iconUrl: string;
  name: string;
  count: number;
}

// export const mockRunModInventory = [
//   {
//     iconUrl: "https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png",
//     name: "Big Giant Pikachu Summon Card of Destiny Is This Long Enough?",
//     count: 3,
//   },
//   {
//     iconUrl: "https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png",
//     name: "Small Tiny Pikachu Summon Card",
//     count: 1,
//   },
//   {
//     iconUrl: "https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png",
//     name: "Mini Pikachu",
//     count: 8,
//   },
// ];

export class TrainStatusPin extends Container {
  private readonly factory = GameSingletons.getSimpleObjectFactory();

  public locomotivePreview;
  public percentageBar;
  context: GameContext = GameSingletons.getGameContext();

  constructor(locoTextureId: string, fraction: number, currentOngoingRun?: ReadonlyObjectDeep<RailRunEntity>) {
    super();

    //// Background circle
    const bgCircle = new Graphics();
    bgCircle.beginFill(0x0);
    bgCircle.drawCircle(0, 0, 120);
    this.addChild(bgCircle);

    //// Fill circle
    this.percentageBar = new TrainPinConditionCircle(115);
    this.percentageBar.setTargetFraction(fraction);
    this.addChild(this.percentageBar);

    //// Loco image
    this.locomotivePreview = this.factory.createSprite(locoTextureId);
    this.locomotivePreview.anchor.set(0.5);
    this.addChild(this.locomotivePreview);

    //// Rim
    const frame: Sprite = this.factory.createSprite("ui-repair/multi-train-map-view/circle.png");
    frame.anchor.set(0.5);
    this.addChild(frame);

    this.initRunModsElements(currentOngoingRun);
  }

  initRunModsElements(currentOngoingRun?: ReadonlyObjectDeep<RailRunEntity>) {
    //// Run Mods

    const showRunModOptions = currentOngoingRun != undefined;
    // const showRunModOptions = true;

    if (showRunModOptions) {
      const { contracts, input, userData, modals, gameConfigData, spinner } = this.context;
      const secondsLeft = currentOngoingRun?.secondsLeft ?? 0;

      const timerMiniPin = this.factory.createSprite("/assets/images/ui-runmods-pin-sidebar/circle.png");
      const runModMenuMiniPin = new RunModMiniPin("/assets/images/ui-runmods-pin-sidebar/plus-btn.png");
      timerMiniPin.anchor.set(0.5);
      runModMenuMiniPin.pivot.set(0.5);
      timerMiniPin.scale.set(1.25);
      timerMiniPin.position.set(135, 56);
      runModMenuMiniPin.position.set(135, -76);

      const timerLabel = new Text(formatTimeDurationHumanReadable(secondsLeft), {
        fill: 0xffffff,
        fontFamily: FontFamily.DefaultThin,
      });
      timerLabel.anchor.set(0.5);
      timerLabel.scale.set(1.16);
      timerLabel.position.set(timerMiniPin.x, timerMiniPin.y);

      buttonizeDisplayObject(runModMenuMiniPin, async () => {
        const data = userData.getRailRunModifiersGrouped();

        const btnOptions = Object.entries(data).map(([templateId, group]) => {
          const templateData = gameConfigData.runModsTemplates.get(+templateId)!;

          const iconIPFS = templateData.immutable_data.icon || "QmXWAEKMqLPgDMX5zU1t5TsMuaFMWyuaAtkanXxdnugYto";
          const iconTextureUrl = `https://atomichub-ipfs.com/ipfs/${iconIPFS}`;
          const iconTexture = Texture.from(iconTextureUrl);
          const icon = new Sprite(iconTexture);
          icon.anchor.set(0.5);

          const buttonOption: DropdownMenuButtonOptions = {
            text: templateData.immutable_data.name,
            onClick: async () => {
              console.log("clicked", templateId);
              const nft = group[0];
              const choice = await modals.confirm({
                title: `Use ${templateData.immutable_data.name}?`,
                content: `Are you sure you want to use ${templateData.immutable_data.name} on ${currentOngoingRun.trainName}?`,
                acceptButtonText: "Apply Run Mod",
                cancelButtonText: "Cancel",
                cornerDetailType: null,
              });
              if (!choice) return;
              else {
                const promise = contracts.actions.performActionTransactions([
                  [
                    "transfer",
                    {
                      from: contracts.currentUserName,
                      to: ContractName.M,
                      asset_ids: [nft.asset_id],
                      memo: currentOngoingRun?.trainName ?? "",
                    },
                    ContractName.AtomicAssets,
                  ],
                ]);
                const tx = await spinner.showDuring(promise);

                console.warn("tx", tx);
              }
            },
            disabled: false,
            icon: icon,
            extraText: "x" + group.length,
          };
          return buttonOption;
        });
        if (userData.modifiers.length < 1) {
          const choice = await modals.confirm({
            title: "No Run Mods",
            content: "It looks like you are out of Run Modifiers. Would you like to go to the Mods Market?",
            acceptButtonText: "Go Shopping",
            cancelButtonText: "Cancel",
            cornerDetailType: null,
          });
          if (!choice) return;
          else {
            input.emit("toggleMarketWindow");
          }
        } else {
          this.context.main.hud.contextMenu.setCurrentMenu(btnOptions);
        }
      });

      this.addChild(timerMiniPin, timerLabel, runModMenuMiniPin);

      Object.assign(this, {
        onEnterFrame() {
          timerLabel.text = formatTimeDurationHumanReadable(secondsLeft);
        },
      });
    }
  }
}
