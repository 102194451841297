import { CARD_ASSET_IPFS_HASH_TO_HUMANNAME } from "@game/cards/CARD_ASSET_IMG_HASH_TO_HUMANNAME";
import { CardEntity } from "@game/data/entities/CardEntity";
import { CardAssetData_Locomotive } from "@sdk-integration/contracts";

const LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME: Record<string, string> = {
  "Locomotives/0-armed-spirit.png": "armed-spirit",
  "Locomotives/0-big-boy.png": "big-boy",
  "Locomotives/0-scrf.png": "scrf",
  "Locomotives/1-illinois.png": "illinois-temrinal",
  "Locomotives/1-kodiak.png": "kodiak",
  "Locomotives/1-mantis.png": "mantis",
  "Locomotives/2-bomber.png": "blue-bomber",
  "Locomotives/2-olympic.png": "olympic",
  "Locomotives/2-piedmont.png": "piedmont-service",
  "Locomotives/3-dirty-sav.png": "sav",
  "Locomotives/3-raven.png": "raven",
  "Locomotives/4-interstate.png": "interstate",
  "Locomotives/4-mastodon.png": "mastodon",
  "Locomotives/5-evolution.png": "evolution",
  "Locomotives/5-heritage.png": "heritage",
  "Locomotives/Modern-Century-Train.png": "ct",

  // Upgraded, Common
  "Locomotives Upgraded/carbon-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/carbon-big-boy.png": "big-boy",
  "Locomotives Upgraded/carbon-scrf-75.png": "scrf",
  "Locomotives Upgraded/steel-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/steel-big-boy.png": "big-boy",
  "Locomotives Upgraded/steel-scrf-75.png": "scrf",
  "Locomotives Upgraded/tungsten-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/tungsten-big-boy.png": "big-boy",
  "Locomotives Upgraded/tungsten-scrf-75.png": "scrf",

  // Upgraded, Uncommon
  "Locomotives Upgraded/carbon-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/carbon-kodiak.png": "kodiak",
  "Locomotives Upgraded/carbon-mantis.png": "mantis",
  "Locomotives Upgraded/steel-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/steel-kodiak.png": "kodiak",
  "Locomotives Upgraded/steel-mantis.png": "mantis",
  "Locomotives Upgraded/tungsten-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/tungsten-kodiak.png": "kodiak",
  "Locomotives Upgraded/tungsten-mantis.png": "mantis",

  // Upgraded, Rare
  "Locomotives Upgraded/carbon-bomber.png": "blue-bomber",
  "Locomotives Upgraded/carbon-olympic.png": "olympic",
  "Locomotives Upgraded/carbon-piedmont.png": "piedmont-service",
  "Locomotives Upgraded/steel-bomber.png": "blue-bomber",
  "Locomotives Upgraded/steel-olympic.png": "olympic",
  "Locomotives Upgraded/steel-piedmont.png": "piedmont-service",
  "Locomotives Upgraded/tungsten-bomber.png": "blue-bomber",
  "Locomotives Upgraded/tungsten-olympic.png": "olympic",
  "Locomotives Upgraded/tungsten-piedmont.png": "piedmont-service",

  // Upgraded, Epic
  "Locomotives Upgraded/steel-dirty-sav.png": "sav",
  "Locomotives Upgraded/steel-raven.png": "raven",
  "Locomotives Upgraded/carbon-dirty-sav.png": "sav",
  "Locomotives Upgraded/carbon-raven.png": "raven",
  "Locomotives Upgraded/tungsten-dirty-sav.png": "sav",
  "Locomotives Upgraded/tungsten-raven.png": "raven",

  // Upgraded, Legendary
  "Locomotives Upgraded/steel-interstate.png": "interstate",
  "Locomotives Upgraded/steel-mastodon.png": "mastodon",
  "Locomotives Upgraded/carbon-interstate.png": "interstate",
  "Locomotives Upgraded/carbon-mastodon.png": "mastodon",
  "Locomotives Upgraded/tungsten-interstate.png": "interstate",
  "Locomotives Upgraded/tungsten-mastodon.png": "mastodon",

  // Upgraded, Mythic
  "Locomotives Upgraded/carbon-evolution.png": "evolution",
  "Locomotives Upgraded/carbon-heritage.png": "heritage",
  "Locomotives Upgraded/steel-evolution.png": "evolution",
  "Locomotives Upgraded/steel-heritage.png": "heritage",
  "Locomotives Upgraded/tungsten-evolution.png": "evolution",
  "Locomotives Upgraded/tungsten-heritage.png": "heritage",
};

export function getLocomotiveCircularPreview(cardOrIPFS: CardEntity | string | null) {
  if (cardOrIPFS == null) {
    return `ui-repair/multi-train-map-view/locos/default.png`;
  }

  if (cardOrIPFS instanceof CardEntity) {
    cardOrIPFS = cardOrIPFS.imageIPFSHash;
  }

  const humanName = CARD_ASSET_IPFS_HASH_TO_HUMANNAME[cardOrIPFS];
  const filename = LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME[humanName];
  if (filename) {
    return `ui-repair/multi-train-map-view/locos/${filename}.png`;
  }

  console.warn(`Locomotive circular preview texture not found for ${humanName}!`);

  return null;
}
