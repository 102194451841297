import { GameSingletons } from "@game/app/GameSingletons";
import { loadRailroaderAchievementsData } from "@game/ui/social/achievements/loadRailroaderAchievements";
import { DataTimeframe } from "../leaderboard/LeaderboardsDataService";

const cache = {
  railroaders: {} as Record<
    DataTimeframe,
    {
      name: string;
      total_transports: number;
      total_distance: number;
      total_reward: number;
      avg_reward: number;
      total_weight: number;
      avg_weight: number;
      total_coal: number;
      avg_coal: number;
      total_diesel: number;
      avg_diesel: number;
      visited_stations: { [station: string]: number };
    }[]
  >,
};

function getISOTimeFromHoursAgo(hoursAgo: number | undefined) {
  if (hoursAgo == undefined) {
    return undefined;
  }

  const date = new Date();
  date.setHours(date.getHours() - hoursAgo);
  return date.toISOString();
}

export class AchievementsRRDashUserListSidePanelDataService {
  async getAchievements(username: string) {
    const achievements = await loadRailroaderAchievementsData(username); // `brhig.wam`

    return achievements;
  }

  async getUserList() {
    const { historyDataCtrl } = GameSingletons.getGameContext();
    const userListData = await historyDataCtrl.api.getRailroaders({ limit: 600 });

    return userListData;
  }
}
