import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { SocialProfileDataService } from "../SocialProfileDataService";

export class AchievementsProgress extends Container {
  private dataService: SocialProfileDataService = new SocialProfileDataService();
  public progressData;

  constructor(user: string) {
    super();
    this.progressData = this.getUserAchievementsProgress(user);
  }

  async initialize() {
    const progressBar = new Graphics();
    progressBar.clear();
    progressBar.beginFill(0x3a3b3c);
    progressBar.drawRect(0, 0, 245, 15);
    progressBar.endFill();

    if (
      (await this.progressData).percentage > 100 ||
      (await this.progressData).percentage < 0 ||
      (await this.progressData).percentage == undefined
    )
      throw new Error("Percentage data missing or corrupted.");

    const progressPercentAsWidth = ((await this.progressData).percentage / 100) * 245;
    const progressBarFill = new Graphics();
    progressBarFill.clear();
    progressBarFill.beginFill(0x1ed3e3);
    progressBarFill.drawRect(0, 0, progressPercentAsWidth, 15);
    progressBarFill.endFill();

    this.addChild(progressBar, progressBarFill);
    return (await this.progressData).percentage;
  }

  async getUserAchievementsProgress(user: string) {
    const achievementBadgesData = await this.dataService.getAchievements(user);
    const countUnlocked = achievementBadgesData.length;
    const countTotal = 78;
    const percentage = Math.round((countUnlocked / countTotal) * 100);
    percentage.toFixed(0);
    return {
      percentage,
      countUnlocked,
      countTotal,
    };
  }
}
