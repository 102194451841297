import {GameSingletons} from "@game/app/GameSingletons";
import {TemporaryTweeener} from "@sdk/pixi/animations/TemporaryTweener";
import {Container, DisplayObject} from "@pixi/display";
import {SimpleObjectsFactory} from "@game/app/services/SimpleObjectsFactory";
import {Sprite} from "@pixi/sprite";
import {buttonizeDisplayObject} from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import {createItemContainer, addPairedPointerdownListeners, scaleItem} from '@game/ui/buildings/bldgUtility';
import {Texture} from "@pixi/core";
import {FontFamily} from "@game/constants/FontFamily";
import {EnchantedContainer} from "@game/core/enchanted-classes";
import {miniGameData} from "../data/miniGameData"
import {bldgHouse} from "../bldgHouse";
import {TilingSpriteDimmer, TilingSpriteDimmerTemplates} from "@game/ui/common/TilingSpriteDimmer";
import { playMinigame } from "@game/ui/buildings/util/playArcadeGames";
import { Graphics } from "@pixi/graphics";

export class miniGameModal extends EnchantedContainer {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private bannerImage : Sprite;
  private screenShot : Sprite;
  public bgContainer : any;
  public textContainer : any;
  public bg : Sprite;
  public playButton : Sprite;
  public buildingName : string;
  public miniGameData = miniGameData;
  public dimmer : TilingSpriteDimmer;


  constructor(public id : number, parent : bldgHouse) {
    super();
    this.id = id;
    this.tweeener = new TemporaryTweeener(this);
    this.buildingName = parent.name;
    this.bg = new Sprite();
    this.playButton = new Sprite();
    this.screenShot = new Sprite();
    this.bannerImage = new Sprite();

    
    this.dimmer = new TilingSpriteDimmer({
      ...TilingSpriteDimmerTemplates.SCANLINES
    });
    this.addChild(this.dimmer);
    
    this.dimmer.show();

    const miniGame = this.miniGameData.find(game => game.gameId === id);
    if (! miniGame) {
      console.error(`Game with id "${id}" not found in miniGame data.`);
      return;
    }
    const {assets} = this.context;

    

    // Empty Container behind modal, so no clickthrough
    const fullScreenContainer = this.factory.createSprite(Texture.EMPTY, {
      x: 0,
      y: 0
    });
    fullScreenContainer.width = this.context.viewSize.width;;
    fullScreenContainer.height = this.context.viewSize.height;;
    fullScreenContainer.interactive = true;
    this.addChild(fullScreenContainer);

    const {viewSize} = parent.context;

    // bg
    this.bg = new Sprite(assets.getTexture("mgBg"));
    this.bg.scale.set(.9);
    // Create a container for the item
    this.bgContainer = createItemContainer({
      x: .25,
      y: .5
    }, {
      x: 0,
      y: 0
    }, this.bg.width, this.bg.height);
    this.bgContainer.addChild(this.bg);
    this.bgContainer.updateIndicatorPositions(this.context.viewSize);
    this.addChild(this.bgContainer);

    // Banner Image
    this.bannerImage = new Sprite(assets.getTexture(miniGame.bannerImage));
    this.bannerImage.scale.set(.8);
    this.bannerImage.position.set((this.bg.width/2)-(this.bannerImage.width/2), this.bg.y-(this.bannerImage.height/8));
    this.bgContainer.addChild(this.bannerImage);

     // Screenshot
     this.screenShot = new Sprite(assets.getTexture(miniGame.screenShot));
     this.screenShot.scale.set(.85);
     this.screenShot.position.set((this.bg.width/2)-(this.screenShot.width/2), (this.bg.height/2)-(this.screenShot.height/1.3)-(this.bannerImage.height/8));
     
     this.bgContainer.addChild(this.screenShot);

    // Text Container
    this.textContainer = createItemContainer({
      x: .25,
      y: .52
    }, {
      x: this.bg.width/5,
      y: 20
    }, this.bg.width, this.bg.height);
    this.addChild(this.textContainer);

//Description
const gameDesc = this.factory.createText(miniGame.description, 
  { fontSize: 20,lineHeight:26, fontFamily: FontFamily.DefaultThin},{ x: this.bg.x+20, y: this.bg.y + this.bannerImage.height+this.screenShot.height});
   this.textContainer.addChild(gameDesc); 

  // Play Button
  this.playButton = new Sprite(assets.getTexture("playBtn"));
  this.playButton.scale.set(.75);
  this.playButton.position.set((this.bg.width/2)-(this.playButton.width/2), this.bg.height-(this.playButton.height*2));

  buttonizeDisplayObject(this.playButton, async () => {
   
    playMinigame(miniGame.playAction);
  
  });

  this.bgContainer.addChild(this.playButton);


   //Developer
     const dev = this.factory.createText("Developer: "+miniGame.developer, 
      { fontSize: 18,lineHeight:20,fill:343641, fontFamily: FontFamily.Default},{ x: this.bg.x+20, y: this.bg.height-80});
       this.textContainer.addChild(dev); 

    // Empty Dismisal
    const empty = this.factory.createSprite(Texture.EMPTY, {
      x: this.bg.x,
      y: this.bg.y
    });
    empty.width = 60;
    empty.height = 60;

    buttonizeDisplayObject(empty, async () => {
      this.destroy();
    });
    this.bgContainer.addChild(empty);
  }
};


/* export class playScreen extends EnchantedContainer {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  public buildingName : string;

  public game: string;
  public dimmer : TilingSpriteDimmer;

  constructor(game:string) {
    super();
    this.game = game;
    this.tweeener = new TemporaryTweeener(this);
    this.buildingName = parent.name;
  this.width = this.context.viewSize.width;
  this.height = this.context.viewSize.height;

    this.dimmer = new TilingSpriteDimmer({
      ...TilingSpriteDimmerTemplates.SCANLINES
    });
    this.addChild(this.dimmer);
    this.dimmer.show();

    const gradientBackground = new Graphics();

    // Draw a rectangle with a black-to-dark gray gradient fill
    gradientBackground.beginFill(0x000000);
    gradientBackground.lineStyle(0);
    gradientBackground.drawRect(0, 0, this.width, this.height);
    gradientBackground.endFill();

    // Add the gradientBackground to the playScreen container
    this.addChild(gradientBackground);

    const {viewSize,assets} = this.context; 

   const quitButton = new Sprite(assets.getTexture("quitBtn"));
    quitButton.position.set(50,50);
    quitButton.zIndex = 1005;
    quitButton.scale.set(1);
    this.addChild(quitButton);

    console.log("quit btn",quitButton);
    console.log('this',this);

   buttonizeDisplayObject(quitButton, async () => {
     this.destroy();
   });

    this.startGame(game);
  
  }
  async startGame(game:string){
   await playMinigame(game);  
  }
}; */