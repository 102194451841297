import { env } from "@game/app/global";

export function assurePasswordGateUnlocked() {
  /**
   * If env.APP_PASSWORD is truthy
   */
  if (!!env.APP_PASSWORD) {
    if (localStorage.getItem("__devkey__") !== atob(env.APP_PASSWORD)) {
      if (btoa(prompt(`Who goes there?`) ?? "") !== atob(env.APP_PASSWORD)) {
        throw new Error(
          `Please wait for the official release, or if you're part of the beta program please ask the devs for proper credentials.`
        );
      }
      localStorage.setItem("__devkey__", atob(env.APP_PASSWORD));
    }
  }
}
