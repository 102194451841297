import { Base64Goodies } from "@game/assets/base64";
import { EMPTY_CARD_TEXTURE_PATHS } from "@game/assets/cards";
import { TextureId } from "@game/constants/paths/TextureId";
import { StationPopupBackgroundType } from "@game/ui/popups/components/StationPopupBackgroundType";

// const PREFIX_ATLAS = "assets/atlases-png/";
const PREFIX_ATLAS = "assets/atlases-webp/";
// const PREFIX_ATLAS = "assets/atlases-dds/";

const PREFIX_IMG_PNG = "assets/images/";
const PREFIX_IMG = "assets/images-webp/";

export const assetsNamed = {
  Font_ToC_Icons: "assets/fonts/ToC_Icons.ttf",
  Font_CaviarDreams: "assets/fonts/CaviarDreams.ttf",
  Font_CaviarDreams_Bold: "assets/fonts/CaviarDreams_Bold.ttf",
  Font_Croogla: "assets/fonts/croogla.otf",
  Font_DanielBlack: "assets/fonts/DanielBlack_Fixed.ttf",
  Font_Story: "assets/fonts/Story.ttf",
  Font_Orson: "assets/fonts/orson.ttf",
  Font_Tommy: "assets/fonts/tommy.ttf",
  Font_Shayan: "assets/fonts/shayan.ttf",
  Font_Ash: "assets/fonts/ash.ttf",
  Font_Jame: "assets/fonts/jame.ttf",
  Font_Missy: "assets/fonts/missy.ttf",
  Font_Paloma: "assets/fonts/paloma.ttf",
  Font_Osipov: "assets/fonts/osipov.ttf",
  Font_Gutshot: "assets/fonts/gutshot.ttf",
  Font_Kc: "assets/fonts/kc.ttf",
  Font_MostraLight: "assets/fonts/mostraLight.otf",
  Font_MostraBold: "assets/fonts/mostraBold.otf",

  BitmapFont_Celestial: "assets/fonts-bitmap/Celestial Bitmap.fnt",

  ...Base64Goodies,

  cardSpinner: "assets/images/cardSpinner.svg",

  vignette: TextureId.Vignette,
 
  uSamplerMapGround: "assets/images/worldmap/grass.png",
  uSamplerMapWater: "assets/images/worldmap/water.png",
  uSamplerDiffuseWater: "assets/images-webp/world/water.webp",
  uSamplerDiffuseGrass: "assets/images-webp/world/grass.webp",
  uSamplerDiffuseDirt: "assets/images-webp/world/mud.webp",
  uSamplerNoises: "assets/images/world/noises.png",

  worldMapRiverNames: "assets/images/worldmap/river-names.png",

  tocIconPartInner: "assets/images/ui-icons/toc-logo/part-inner.png",
  tocIconPartOuter: "assets/images/ui-icons/toc-logo/part-outer.png",

  cardGlow: "assets/images/fx/card-glow.png",
  cardShade: "assets/images/fx/card-shade.png",

  rays: "assets/images/fx/rays.png",
  perlin: "assets/images/fx/perlin.png",

  cardsBg: "assets/images/ui-cards-loadout/bg.png",

  //building signs
  chooChooDraw: "assets/images/promo/choo-choo-draw.png",
  bankSign: "assets/images/ui-hover/bank-sign.png",
  leClothier: "assets/images/ui-hover/clothes-sign.png",
  librarySign: "assets/images/ui-hover/library-sign.png",
  museumSign: "assets/images/ui-hover/museum-sign.png",
  arcadeSign: "assets/images/ui-hover/arcade-sign.png",



  mapPoint: "assets/images/worldmap-markers/map-point.png",
  mapPointGlow: "assets/images/worldmap-markers/map-point-highlight.png",

  railTrackDirt: "assets/images/tracks/track-dirt-ld.png",
  railTrack: "assets/images/tracks/track-combined.png",
  railTrackBridge: "assets/images/tracks/track-bridge.png",
  railTrackBridgeBase: "assets/images/tracks/track-bridge-base.png",
  railTrackAbstract: "assets/images/tracks/track-abstract.png",
  railTrackSpot: "assets/images/tracks/track-spot.png",

  // npc_thomas: "assets/audio/music/npc-thomas.mp3",
  // npc_stranger: "assets/audio/music/npc-stranger.mp3",
  // npc_otto: "assets/audio/music/npc-otto.mp3",
  // npc_culprit: "assets/audio/music/npc-culprit.mp3",
  // npc_story_01: "assets/audio/music/story-stranger-a.mp3",

  railroaderDashboardPanelBackgroundWithBase: "assets/images/ui-railroader-dashboard/page-common/panel-with-base.png",
  railroaderDashboardPanelBackgroundNoBase: "assets/images/ui-railroader-dashboard/page-common/panel-no-base.png",
  skippy: PREFIX_IMG + "/cinematics/skip-btn.webp",
  vortexBackground: PREFIX_IMG + "/cinematics/timeline-vortex.webp",
  meshPad: "assets/images/ui-main/mesh-pad.png",
  newMeshPad: "assets/images/ui-main/New_View_Label.png",
  newMeshPadColorRing: "assets/images/ui-main/inner-color.png",
  newMeshPadTypeLabelPad: "assets/images/ui-main/transport-type-label.png",

  windowGrunge: PREFIX_IMG + "/ui-windows/grunge.webp",
  windowFrame: PREFIX_IMG + "/ui-windows/frame.webp",
  windowListPadBig: "assets/images/ui-windows/middle-big.png",
  windowListPadLong: "assets/images/ui-windows/middle-long.png",

  stationDashboardBackgroundConductorLounge: PREFIX_IMG + "/ui-station-dashboard/bg-conductor-lounge.webp",
  stationDashboardBackgroundRailYard: PREFIX_IMG + "/ui-station-dashboard/bg-rail-yard.webp",

  mutedSoundIcon: "assets/images/ui-icons/sound-off.png",
  playingSoundIcon: "assets/images/ui-icons/sound-on.png",

  trainPinNameplate: "assets/images/ui-repairPinsSidebar/name_box.png",

  thomasIntro: PREFIX_IMG + "/cinematics/thomas-intro.webp",

    //Entering Buildings

        arrowBtn1: PREFIX_IMG + "buildings/arrow1.png",
        lBtn: PREFIX_IMG + "buildings/lBtn.webp",
        rBtn: PREFIX_IMG + "buildings/rBtn.webp",
      //Library
        //Rooms
        library1: PREFIX_IMG + "buildings/library/lib-1.webp",
        library2: PREFIX_IMG + "buildings/library/lib-2-orig.webp",
        library2Items: PREFIX_IMG + "buildings/library/lib-2.webp",
        library3: PREFIX_IMG + "buildings/library/lib-3.webp",
        //Layers
        clouds: PREFIX_IMG + "buildings/library/bgbuild/cloudbg.webp",
        bldgTrees: PREFIX_IMG + "buildings/library/bgbuild/bldgTrees.webp",
        
        //Items
        item1: PREFIX_IMG + "buildings/library/chandelier.webp",
        booksL: PREFIX_IMG + "buildings/library/booksL.webp",
        booksM: PREFIX_IMG + "buildings/library/booksM.webp",
        booksR: PREFIX_IMG + "buildings/library/booksR.webp",
        lights: PREFIX_IMG + "buildings/library/lights.png",
        bell: PREFIX_IMG + "buildings/library/bell.webp",

      //Museum
        //Rooms
           //Outside
            m1sky: PREFIX_IMG + "buildings/museum/m1sky.webp",
            m1main: PREFIX_IMG + "buildings/museum/m1main.webp",
            m1fx: PREFIX_IMG + "buildings/museum/m1fx.webp",

            //Inside
            m2main: PREFIX_IMG + "buildings/museum/mInside1.webp",
            mTrain: PREFIX_IMG + "buildings/museum/train.webp",

    //Arcade
        //Rooms
           //Outside room 1
           a1Sky: PREFIX_IMG + "buildings/arcade/aSky.webp",
           a1Main: PREFIX_IMG + "buildings/arcade/aMain.webp",

           //inside room 2
           a2bg: PREFIX_IMG + "buildings/arcade/a2bg.webp",
           a2sign: PREFIX_IMG + "buildings/arcade/choopy-int-sign.webp",
           a2signLayer2: PREFIX_IMG + "buildings/arcade/arcade.webp",

           //3 machines room 3
           a3bg: PREFIX_IMG + "buildings/arcade/a3bg.webp",
           machineL: PREFIX_IMG + "buildings/arcade/machine-l.webp",
           machineM: PREFIX_IMG + "buildings/arcade/machine-m.webp",
           machineR: PREFIX_IMG + "buildings/arcade/machine-r.webp",
       
           //wanted board
           a4bg: PREFIX_IMG + "buildings/arcade/a4bg.webp",
           devsNote: PREFIX_IMG + "buildings/arcade/note-devs.webp",
           wantedHeader: PREFIX_IMG + "buildings/arcade/wantedHeader.png",
           wantedPaper: PREFIX_IMG + "buildings/arcade/wantedPaper.png",

      //MiniGames
            mgBg: PREFIX_IMG + "buildings/arcade/mini-games/mgBg.webp",
            playBtn: PREFIX_IMG + "buildings/arcade/mini-games/playBtn.webp",
            quitBtn: PREFIX_IMG + "buildings/arcade/mini-games/quitbtn.png",

        //Banner Images
            bannerDefender: PREFIX_IMG + "buildings/arcade/mini-games/banner-defender.webp",
            bannerSurvive: PREFIX_IMG + "buildings/arcade/mini-games/banner-survive.webp",
            bannerAnomatic: PREFIX_IMG + "buildings/arcade/mini-games/banner-anomatic.webp",

        //Screenshots
            ssDefender: PREFIX_IMG + "buildings/arcade/mini-games/ss-defender.webp",
            ssSurvive: PREFIX_IMG + "buildings/arcade/mini-games/ss-survive.webp",
            ssAnomatic: PREFIX_IMG + "buildings/arcade/mini-games/ss-anomatic.webp",
};

export const assetsUnnamed = [
  "assets/images/ui-railroader-dashboard/choo-choo-l-panel.png",

  PREFIX_ATLAS + "atlas-hud-common.json",
  PREFIX_ATLAS + "atlas-hud-cards-drawer.json",
  PREFIX_ATLAS + "atlas-map-decoration-sprites.json",
  PREFIX_ATLAS + "atlas-map-extra-decoration-sprites.json",
  PREFIX_ATLAS + "atlas-map-station-sprites.json",
  PREFIX_ATLAS + "atlas-station-dashboard.json",
  PREFIX_ATLAS + "atlas-station-dashboard-staking.json",
  PREFIX_ATLAS + "atlas-station-popup-elements.json",
  PREFIX_ATLAS + "atlas-station-billboard.json",
  PREFIX_ATLAS + "atlas-npc-encounters-mysterious-stranger.json",
  PREFIX_ATLAS + "atlas-npc-encounters-otto.json",
  PREFIX_ATLAS + "atlas-npc-encounters-otto-intro.json",
  PREFIX_ATLAS + "atlas-npc-encounters-thomas.json",
  PREFIX_ATLAS + "atlas-npc-encounters-the-culprit.json",
  PREFIX_ATLAS + "atlas-npc-encounters-story-chapter-01.json",
  PREFIX_ATLAS + "atlas-century-vials.json",
  PREFIX_ATLAS + "atlas-ui-asorted.json",
  PREFIX_ATLAS + "atlas-ui-railroader-dashboard-asorted.json",
  PREFIX_ATLAS + "atlas-ui-railroader-dashboard-panel.json",
  PREFIX_ATLAS + "atlas-ui-railroader-dashboard-extra.json",
  PREFIX_ATLAS + "atlas-ui-repair.json",
  PREFIX_ATLAS + "atlas-ui-staking-hub-conductor-lounge.json",
  PREFIX_ATLAS + "atlas-ui-staking-hub-rail-yard.json",
  PREFIX_ATLAS + "atlas-ui-staking-previews-conductors.json",
  PREFIX_ATLAS + "atlas-ui-staking-previews-locomotives.json",
  PREFIX_ATLAS + "atlas-ui-staking-requests.json",
  PREFIX_ATLAS + "atlas-ui-market-window.json",
  PREFIX_ATLAS + "atlas-ui-market-window-character.json",
  PREFIX_ATLAS + "atlas-ui-market-window-compositions.json",
  PREFIX_ATLAS + "atlas-ui-market-window-ct-parts.json",
  PREFIX_ATLAS + "atlas-ui-market-window-unlock-trains.json",
  PREFIX_ATLAS + "atlas-ui-market-window-emporium.json",
  PREFIX_ATLAS + "atlas-ui-market-window-forge.json",
  PREFIX_ATLAS + "atlas-ui-railruns-window.json",
  PREFIX_ATLAS + "atlas-ui-golden-runs.json",
  PREFIX_ATLAS + "atlas-ui-social-asorted.json",
  PREFIX_ATLAS + "atlas-ui-social-panel.json",
  PREFIX_ATLAS + "atlas-ui-arcade.json",
  PREFIX_ATLAS + "atlas-ui-achievements.json",
  PREFIX_ATLAS + "atlas-ui-ethereum-integration.json",
  PREFIX_ATLAS + "atlas-ui-updates.json",
  PREFIX_ATLAS + "atlas-ui-ct-bg.json",
  PREFIX_ATLAS + "atlas-ui-entering-buildings.json",
  PREFIX_ATLAS + "atlas-ui-journals.json",
  PREFIX_ATLAS + "atlas-ui-journal-cards-1.json",
  PREFIX_ATLAS + "atlas-ui-journal-cards-2.json",
  PREFIX_ATLAS + "atlas-ui-journal-tales-1.json",
  PREFIX_ATLAS + "atlas-ui-journal-tales-2.json",
  PREFIX_ATLAS + "atlas-ui-journal-paper-2.json",
  PREFIX_ATLAS + "atlas-ui-journal-paper-1.json",
  PREFIX_ATLAS + "atlas-ui-journal-paper-0.json",
 
  "assets/images/ui-staking-hub/bg-rim.png",

  "assets/images/ui-common/dropdown-pad.png",
  "assets/images/ui-common/btn-blue.png",
  "assets/images/ui-common/btn-green.png",
  "assets/images/ui-common/btn-red.png",

  "assets/images/fx/purple-flare.png",

  ...StationPopupBackgroundType.getAllTexturesPaths(),
  ...EMPTY_CARD_TEXTURE_PATHS,
];

export const assetsAudio = {
  onClickBit: "assets/audio/sfx/onClickBit.mp3",
  outClickBit: "assets/audio/sfx/outClickBit.mp3",
  onClickLow: "assets/audio/sfx/onClickLow.mp3",
  outClickLow: "assets/audio/sfx/outClickLow.mp3",
  clickRegular: "assets/audio/sfx/click-regular.mp3",
  clickOff: "assets/audio/sfx/click-off.mp3",
  clickRegularDown: "assets/audio/sfx/click-regular-down.wav",
  clickRegularUp: "assets/audio/sfx/click-regular-up.wav",
  clickTiny: "assets/audio/sfx/click-tiny.wav",
  chooChoo: "assets/audio/sfx/choo-choo.wav",
  cogWheelActivate: "assets/audio/sfx/cog-wheel-activate.wav",
  cogWheelRRDash: "assets/audio/sfx/cog-wheel-rr-dash.wav",
  paper: "assets/audio/sfx/paper.wav",
  boop: "assets/audio/sfx/boop.wav",
  newAchievement: "assets/audio/sfx/new-story.mp3",
  justGears: "assets/audio/sfx/justGears.mp3",
  tinyGears: "assets/audio/sfx/tiny-gears.mp3",
  tinyGears2: "assets/audio/sfx/tiny-gears2.mp3",
  pUp: "assets/audio/sfx/pUp.mp3",
  pDown: "assets/audio/sfx/pDown.mp3",


  //Building SFX
  chain: "assets/audio/sfx/chain.mp3",
  bookBump: "assets/audio/sfx/book-bump.mp3",
  bookOpen: "assets/audio/sfx/book-open.mp3",
  running: "assets/audio/sfx/running.mp3",
  walking: "assets/audio/sfx/walking.mp3",
  crystal: "assets/audio/sfx/crystal.mp3",
  field: "assets/audio/sfx/field.mp3",
  woosh: "assets/audio/sfx/woosh.mp3",
  woosh2: "assets/audio/sfx/woosh2.mp3",
  wooshOut: "assets/audio/sfx/woosh-out.mp3",
  locate: "assets/audio/sfx/locate.mp3",
  locateClick: "assets/audio/sfx/locateClick.mp3",
  locateClickTiny: "assets/audio/sfx/locateClickTiny.mp3",
  bubble: "assets/audio/sfx/bubble.mp3",
  bell: "assets/audio/sfx/bell.mp3",
  choopy8bit: "assets/audio/sfx/choopy.mp3",


  ottoCinematicRiser: "assets/audio/sfx/npc-cinematic-otto/transition-riser.wav",
  ottoCinematicWarp: "assets/audio/sfx/npc-cinematic-otto/transition-warp.wav",
  thomasCinematicRunning: "assets/audio/sfx/npc-cinematic-thomas/running.wav",
  culpritCinematicMmm: "assets/audio/sfx/npc-cinematic-culprit/culprit-mmm.wav",
  mechanicCinematicSigh: "assets/audio/sfx/npc-cinematic-mechanic/sigh.wav",
  mechanicCinematicSteps: "assets/audio/sfx/npc-cinematic-mechanic/footsteps.wav",
  mechanicCinematicRepair: "assets/audio/sfx/npc-cinematic-mechanic/repair_fx.wav",

  newStory: "assets/audio/sfx/npc-cinematic-stories/new-story.wav",
  ticking: "assets/audio/sfx/npc-cinematic-stories/ticking.wav",
};
