import { TrainConditionColors } from "@game/constants/TrainConditionColors";
import { Color } from "@sdk/utils/color/Color";
import { lerp } from "@sdk/utils/math";
import { SemiCircle } from "./SemiCircle";

const COLOR_BELOW_50 = TrainConditionColors.LOW;
const COLOR_AT_70 = TrainConditionColors.MEDIUM;
const COLOR_ABOVE_90 = TrainConditionColors.HIGH;

export class TrainPinConditionCircle extends SemiCircle {
  public targetFraction: number = 0;
  public animationDamping: number = 0.05;

  constructor(radius: number) {
    super(radius);
  }

  setTargetFraction(fraction: number) {
    this.targetFraction = fraction;
  }

  fastForwardFractionAnimation() {
    this.setShownFraction(this.targetFraction);
  }

  protected onEnterFrame() {
    const animatedFraction = lerp(this.fraction, this.targetFraction, this.animationDamping) || 0;
    this.setShownFraction(animatedFraction);
  }

  private setShownFraction(fraction: number) {
    if (this.fraction === fraction) return;
    this.setFraction(fraction);
    this.tint = this.getLerpedColorForFraction(fraction);
  }

  private getLerpedColorForFraction(fraction: number): number {
    if (fraction < 0.5) return COLOR_BELOW_50;

    if (fraction > 0.9) return COLOR_ABOVE_90;

    function getLerpParams(): [colorA: number, colorB: number, lerpAmount: number] {
      if (fraction < 0.7) return [COLOR_BELOW_50, COLOR_AT_70, (fraction - 0.5) * 5];
      return [COLOR_AT_70, COLOR_ABOVE_90, (fraction - 0.7) * 5];
    }

    return Color.lerp(...getLerpParams()).toInt();
  }
}
