// @ts-ignore
import html from "./loading.htm";

import { MultipleReasons, ReasonIdentifier } from "@sdk/core/MultipleReasons";
import { createSimpleTicker } from "@sdk/time/SimpleTicker";
import { createSimpleTweener } from "@sdk/time/SimpleTweener";

function greateLoadingDom(id: string, parentElement: HTMLElement) {
  let dom = document.getElementById(id) as HTMLDivElement | null;
  if (!dom) {
    dom = document.createElement("div");
    dom.id = id;
    dom.innerHTML = html;
    parentElement.appendChild(dom);
  }
  return dom;
}

export function setupLoadingSpinnerSplash() {
  const reasonsToShow = new MultipleReasons();
  const loadingElParent = document.body;
  const loadingEl = greateLoadingDom("loading", loadingElParent);

  const sticker = createSimpleTicker();
  const stweener = createSimpleTweener(sticker);

  const service = {
    get alpha() {
      return (loadingEl && parseFloat(loadingEl.style.opacity)) || 0;
    },
    set alpha(value: number) {
      loadingEl && (loadingEl.style.opacity = `${value}`);
    },

    reasonsToShow,
    async showDuring<T>(promise: Promise<T>, reasonName?: ReasonIdentifier) {
      reasonName = reasonName || Symbol();
      try {
        reasonsToShow.add(reasonName);
        return await promise;
      } finally {
        reasonsToShow.remove(reasonName);
      }
    },
    bindSpinner<T extends (...args: any[]) => Promise<unknown>>(fn: T, $this: any = null): T {
      // @ts-ignore
      return (...args: any[]) => {
        const promise = fn.apply($this, args);
        return this.showDuring(promise);
      };
    },
  };

  const ANIMATION_DURATION = 0.6;
  reasonsToShow.on({
    empty: () => {
      document.body.classList.remove("loading");
      for (const el of loadingEl.getElementsByClassName("loading-with-fadein")) {
        el.classList.remove("loading-with-fadein");
      }
      stweener
        .fromTo(service.alpha, 0, v => (service.alpha = v), { duration: ANIMATION_DURATION })
        .then(() => void loadingEl.remove());
    },
    notEmpty: () => {
      document.body.classList.add("loading");
      loadingElParent.appendChild(loadingEl);
      stweener.fromTo(service.alpha, 1, v => (service.alpha = v), { duration: ANIMATION_DURATION });
    },
  });

  // onKeyPress('l', () => reasonsToShow.has('L') ? reasonsToShow.remove('L') : reasonsToShow.add('L'));

  return service;
}

export type LoadingService = ReturnType<typeof setupLoadingSpinnerSplash>;
