import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { EventBannersSlideData } from "../../railroader-dash/banner/EventBannersGalleryData";

export class ReadMoreModalDataScrollbox extends SafeScrollbox {
  private readonly dataColumn;

  constructor(data: EventBannersSlideData) {
    super({
      noTicker: true,
      boxWidth: 620,
      boxHeight: 460,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });

    this.dataColumn = data;
  }

  async initialize() {
    const createUpdatesDataContainer = async () => {
      this.clearList();
      const { simpleFactory, ticker } = GameSingletons.getGameContext();
      const dataContainer = new Container();
      this.content.addChild(dataContainer);

      //// Add Event Title Card
      const image = simpleFactory.createSprite(this.dataColumn.textureId, { x: 312, y: -44 });
      image.scale.set(0.6);
      image.anchor.set(0.5);

      //// Add Event Title text
      const title = simpleFactory.createText(this.dataColumn.title, {
        fontFamily: FontFamily.Default,
        fontSize: 35,
        fill: 0xffffff,
      });
      title.anchor.set(0.5);
      title.position.set(312, image.y + 125);
      dataContainer.addChild(title);

      //// Add Event Body text
      const description = simpleFactory.createText(this.dataColumn.articleText, {
        fontFamily: FontFamily.DefaultThin,
        fontSize: 21,
        fill: 0xffffff,
        wordWrap: true,
        wordWrapWidth: 515,
        lineHeight: 30,
      });

      description.anchor.set(0.5, 0);
      description.position.set(312, title.y + title.height + 25);
      dataContainer.addChild(description);

      this.addChild(image);
      await ticker.nextFrame();

      this.content.addChild(this.addInvisibleBox(426));
      this.update();
    };

    createUpdatesDataContainer();
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
