import * as WAX from "@sdk-integration/contracts";

import { ajaxIntoNewDiv } from "@debug/integration/ajax";
import { Wax } from "@eosdacio/ual-wax";
import type { LoadingService } from "@launcher/loading/loading";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { Anchor } from "ual-anchor";
import { UALJs } from "ual-plainjs-renderer";
import type { UALJsRenderConfig } from "ual-plainjs-renderer/UALJs";
import type { Authenticator, User as UALUser } from "universal-authenticator-library";
import { mainnetChain, testnetChain } from "./contracts/constants/blockchains";
import type { WalletUser } from "./contracts/typing/WalletUser";

import { __window__ } from "@debug/__";
import { env } from "@game/app/global";

const appName: string = "toc";

const parentDomId = "login-splash";

export async function setupRailroaderCreationAndLogin(externals: { loadingSpinnerService: LoadingService }) {
  __window__.externals = externals as any;

  externals.loadingSpinnerService.reasonsToShow.removeAll();
  const withSpinner = externals.loadingSpinnerService.showDuring;

  //////////////////////////////////////////////////////////////////////////////

  function greateLoginSplashParent(id: string) {
    let parent = document.getElementById(id) as HTMLDivElement | null;
    if (!parent) {
      parent = document.createElement("div");
      parent.id = id;
      document.body.appendChild(parent);
    }
    return parent;
  }

  function initialize(parentDom: HTMLDivElement) {
    const loginDom = document.createElement("div");
    loginDom.id = "ual-div";
    parentDom.appendChild(loginDom);

    const styleEl = document.createElement("style");
    styleEl.innerHTML = `
      #ual-div {
        z-index: 100;
        overflow: visible;
      }
    `;
    loginDom.append(styleEl);

    const blockchain = env.BLOCKCHAIN === "testnet" ? testnetChain : mainnetChain;
    const auth_Anchor = new Anchor([blockchain], { appName });
    const auth_Wax = new Wax([blockchain], {});

    return new Promise<[UALJs, UALUser, typeof loginDom]>((resolve, reject) => {
      try {
        type ExtraParams = [
          appName: string,
          authenticators: Authenticator[],
          renderConfig?: UALJsRenderConfig | undefined
        ];
        const params: ExtraParams | undefined = {
          ["testnet"]: [appName, [auth_Anchor], { containerElement: loginDom }] as ExtraParams,
          ["mainnet"]: [appName, [auth_Wax, auth_Anchor], { containerElement: loginDom }] as ExtraParams,
        }[env.BLOCKCHAIN];

        if (params == undefined) {
          throw new Error(`Unrecognized blockchain id: ${env.BLOCKCHAIN}. Please check the env vars.`);
        }

        const ual = new UALJs(
          users => {
            users.length === 0 ? reject(new Error("No users found")) : resolve([ual, users[0], loginDom]);
          },
          [blockchain],
          ...params
        );

        ual.init(); // show ual login

        {
          const btn = document.getElementById("ual-button");
          if (btn) {
            btn.innerText = "Login";
          }
        }

        requestAnimationFrame(() => {
          const auth = ual.getAuthenticators().autoLoginAuthenticator;
          console.log({
            autoLoginAuthenticator: auth,
            shouldAutoLogin: auth?.shouldAutoLogin(),
            isAutologin: ual.isAutologin,
          });
          if (auth) {
            if (auth.shouldAutoLogin()) {
              if (auth instanceof Anchor) {
                console.warn("Anchor auto login is not supported yet");
                ual.loginUser(auth);
              }
            } else {
              // if (auth instanceof Wax) {
              //   ual.logoutUser();
              // }
            }
          }
        });
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }

  /**
   * Get or create a new railroader data object for the logged in user.
   */
  async function greateRegisteredRailroaderData(waxService: WaxContractsGateway) {
    const userAccountName = waxService.currentUserName;

    let registeredRailroaderData = await withSpinner(waxService.getRegisteredRailroaderData(userAccountName));

    if (!registeredRailroaderData) {
      const choice = confirm(
        `Welcome, ${userAccountName}, to Train of the Century!\nIt looks like you're not yet a registered railroader with us.\nLet's fix that right away!`
      );
      if (!choice) {
        throw new Error("User canceled registration");
      }
    }

    while (!registeredRailroaderData) {
      await withSpinner(waxService.registerRailroader(userAccountName));
      registeredRailroaderData = await withSpinner(waxService.getRegisteredRailroaderData(userAccountName));
      if (!registeredRailroaderData) {
        const tryAgain = prompt(`
          Failed to register you as a new RailRoader.
          Would you like to try again?`);
        if (!tryAgain) {
          throw new Error("Failed to register account as a RailRoader, or the user refused.");
        }
      }
    }

    return registeredRailroaderData;
  }

  async function handleTermsAndConditionsState(parentDom: HTMLDivElement, waxService: WaxContractsGateway) {
    const waxUserData = await withSpinner(greateRegisteredRailroaderData(waxService));
    const [shouldShowTermsAndConditions, latestTermsAndConditionsVersion, reasonToShowTermsAndConditions] =
      await withSpinner(waxService.getTermsAndConditionsState(waxUserData));
    console.log({
      shouldShowTermsAndConditions,
      latestTermsAndConditionsVersion,
      reasonToShowTermsAndConditions,
    });

    if (reasonToShowTermsAndConditions) {
      const termsAndConditionsDom = await withSpinner(ajaxIntoNewDiv("html/terms-and-conditions.htm"));

      if (!termsAndConditionsDom) {
        throw new Error("Could not load terms and conditions");
      }

      parentDom.appendChild(termsAndConditionsDom);

      const [btnAccept] = termsAndConditionsDom.getElementsByClassName("btn-accept");
      await new Promise((resolve, reject) => {
        try {
          btnAccept.addEventListener("click", resolve);
        } catch (e) {
          reject(e);
        }
      });

      termsAndConditionsDom.remove();

      await withSpinner(waxService.acceptTermsAndConditions(latestTermsAndConditionsVersion));
    }
  }

  async function greateExistingRaileoaderTrain(parentDom: HTMLDivElement, waxService: WaxContractsGateway) {
    const waxUserData = await withSpinner(greateRegisteredRailroaderData(waxService));
    const trains = await withSpinner(waxService.getRailroaderTrains());
    if (trains.length === 0) {
      const railroaderName = waxUserData.railroader;
      const trainName = prompt(
        `Welcome, Railroader ${railroaderName}!\nIt's time to choose a name for your first train!\n Make sure:` +
          `\n - train name is more than 2 characters but less than 13` +
          `\n - train name cannot contain capital letters or spaces` +
          `\n - only numbers 1 - 5 are acceptable` +
          `\n - no special characters other than periods`
      ) as WAX.TrainName | null;
      if (!trainName) {
        throw new Error("User did not want to create a train. I don`t know what to do .... 😢");
      }
      console.log({ trainName });
      await withSpinner(waxService.createRailroaderTrain(trainName));
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  const splashDom = greateLoginSplashParent(parentDomId);

  const videoUrl = "https://train-of-the-century-media.web.app/splash/bg.mp4";
  splashDom.innerHTML = `
    <video width="320" height="240" video autobuffer autoplay loop>
      <source src="${videoUrl}" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <h1 id="page-title"><img src="/assets/splash/title.png" style="max-width: 90%;"></h1>
  `;

  const [ual, loggedInUser, loginDom] = await initialize(splashDom);
  for (const el of document.getElementsByClassName("anchor-link-active")) {
    el.remove();
  }

  console.log(`💙 Successfully logged in!`, { ual, loggedInUser });

  const loggedInUserName = await withSpinner(loggedInUser.getAccountName() as Promise<WAX.AccountName>);
  console.log(`💙 Welcome, ${loggedInUserName}!`);

  const waxService = new WaxContractsGateway(loggedInUser as WalletUser, "modern");
  __window__["contracts"] = waxService;

  loginDom && (loginDom.style.display = "none");

  await greateRegisteredRailroaderData(waxService);

  await handleTermsAndConditionsState(splashDom, waxService);

  await greateExistingRaileoaderTrain(splashDom, waxService);

  // await __waitForKeyPress();

  splashDom.remove();

  return {
    ual,
    loggedInUser,
    loggedInUserName,
    waxService,
  };
}
