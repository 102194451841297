import { HistoryApiResponseErrorDetails } from "./HistoryApiResult";

/**
 * Error class for the History API Service
 */
export class HistoryApiResponseError extends Error {
  constructor(public readonly response: Response, public readonly details: null | HistoryApiResponseErrorDetails) {
    super(
      [
        "",
        response.ok ? "Status = OK" : `Status = ${response.status} ${response.statusText}`,
        details ? `Details: ` + JSON.stringify(details, null, 2) : "No details",
        "",
      ].join("\n")
    );

    this.name = "HistoryApiServiceError";
  }
}
