import { GameSingletons } from "@game/app/GameSingletons";
import { BitmapFontName } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { BitmapText } from "@pixi/text-bitmap";

export function createDistanceLayerFactory() {
  const { mapData } = GameSingletons.getGameContext();
  const overrides: Record<number, number> = {
    [0]: 0.5,
    [27]: 0.4,
    [42]: 0.45,
    [46]: 0.4,
    [74]: 0.4,
    [83]: 0.45,
    [245]: 0.55,
    [342]: 0.45,
    [375]: 0.45,
    [489]: 0.45,
    [533]: 0.55,
    [650]: 0.55,
    [794]: 0.65,
    [823]: 0.45,
    [865]: 0.45,
  };

  let distanceLabelsLayer = createDistanceLabels();

  function createDistanceLabels() {
    let distanceLabelsContainer = new Container();

    for (const [index, railPath] of mapData.railPaths.entries()) {
      const distanceLabel = new BitmapText(railPath.stationsLink!.distance.toString(), {
        fontSize: 504,
        fontName: BitmapFontName.CelestialTypeface,
        tint: 0xffffff,
      });
      distanceLabel.anchor.set(0.5);
      const percentageAlongTheRail = overrides[index] ?? 0.5;
      const distanceLabelPosition = railPath.getPointLocationAtLength(railPath.segmentsCount * percentageAlongTheRail);

      distanceLabel.position.set(distanceLabelPosition.x, distanceLabelPosition.y);

      distanceLabelsContainer.addChild(distanceLabel);
    }

    return distanceLabelsContainer;
  }

  return {
    show() {
      const { world } = GameSingletons.getGameContext();
      distanceLabelsLayer = createDistanceLabels();
      world.zoomLayers.operationsLayer.addChild(distanceLabelsLayer);
    },
    hide() {
      distanceLabelsLayer.destroy();
    },
  };
}
