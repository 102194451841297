import * as WAX from "@sdk-integration/contracts";

import { WaxContractActionError, WaxContractActionObject, WaxContractActionService } from "./WaxContractActionService";
import { WaxContractTableService } from "./WaxContractTableService";

import { WaxAssetsService } from "./WaxAssetsService";
import { WaxContractMarketService } from "./WaxContractMarketService";
import { WaxContractMyStationService } from "./WaxContractMyStationService";
import { TransactionResult } from "./typing/TransactonResult";
import type { WalletUser } from "./typing/WalletUser";

import { JsonRpc } from "eosjs";
import { env } from "@game/app/global";
import { EventBus } from "@sdk/core/EventBus";
import { getConfigurationForBlockchain } from "@sdk-integration/contracts/constants/configuration";
import { findTransactionActionTrace } from "@sdk-integration/contracts/utils/findTransactionActionTrace";
import { TraceData_NPCEncounter, TraceData_PassengerTips, TraceData_TokenTransfer } from "./internal/TraceData.model";
import { AnchorUser } from "ual-anchor";
import { WaxUser } from "@eosdacio/ual-wax";
import { __logFunctionCalls } from "@debug/decorators/__logFunctionCalls";
import { createUserPreferencesFirebaseStorageProxy } from "@game/ui/railroader-dash/panels/settings/createUserPreferencesFirebaseStorageProxy";

const blockchainConfig = getConfigurationForBlockchain(env.BLOCKCHAIN);


// @__logFunctionCalls
export class WaxContractsGateway {
  public readonly events = new EventBus<{
    error: (error: Error | string, data?: any) => unknown;
    trainTampered: (train: WAX.TrainName, error: Error) => unknown;
  }>();

  public readonly currentUser: WalletUser;
  public readonly currentUserName: WAX.AccountName;
  public readonly currentAuthProvider: "wax" | "anchor";

  public readonly currentCenturyName: WAX.CenturyName;

  protected readonly scopes: {
    global: string;
    century: string;
    railroader: string;
  };

  public readonly actions: WaxContractActionService;
  public readonly tables: WaxContractTableService;

  public readonly assets: WaxAssetsService;
  public readonly market: WaxContractMarketService;
  public readonly myStation: WaxContractMyStationService;

  public showError = (error: any) => {
    console.error(error);
    alert(error);
  };

  constructor(loggedInUser: WalletUser, century: WAX.CenturyName) {
    this.currentUser = loggedInUser;
    this.currentUserName = loggedInUser.accountName;
    this.currentCenturyName = century;

    this.currentAuthProvider = "wax" in loggedInUser ? "wax" : "anchor";

    const $this = this;
    this.scopes = {
      global: WAX.ContractName.RR,
      get century() {
        return $this.currentCenturyName;
      },
      get railroader() {
        return $this.currentUserName;
      },
    };

    loggedInUser.rpc ??= new JsonRpc(blockchainConfig.url_rpc);

    const onActionTransactionError = (error: WaxContractActionError, actionObjects: WaxContractActionObject[]) => {
      console.error(`⚠`, error, `\n`, actionObjects);
      this.events.emit("error", error, actionObjects);

      if (error.messageIncludes("tampered", "verify")) {
        const trainName = actionObjects[0].data.train;
        if (typeof trainName === "string") {
          this.events.emit("trainTampered", trainName as WAX.TrainName, error);
          return;
        }
      }

      if (error.messageIncludes("unable to open popup window")) {
        return this.showError(
          `⚠\nPlease allow this page to open popups from the little icon in the browser's address bar and then refresh the page for this to work.`
        );
      }

      return this.showError(`❌ Transaction failed with the following error:\n${error}`);
    };

    this.tables = new WaxContractTableService(loggedInUser.rpc);
    this.actions = new WaxContractActionService(loggedInUser);
    this.actions.onTransactionError = onActionTransactionError;

    this.assets = new WaxAssetsService(this.currentUserName);

    this.market = new WaxContractMarketService(loggedInUser, this);
    this.market.actions.onTransactionError = onActionTransactionError;

    this.myStation = new WaxContractMyStationService(loggedInUser, this);
    this.myStation.actions.onTransactionError = onActionTransactionError;
  }

  public async clearAndVerifyTrain(train: WAX.TrainName) {
    await this.actions.performActionTransaction(
      WAX.ActionName.ClearTrain,
      {
        train,
        railroader: this.currentUserName,
      },
      WAX.ContractName.RR
    );
    await this.actions.performActionTransaction(
      WAX.ActionName.VerifyTrain,
      {
        train,
        railroader: this.currentUserName,
      },
      WAX.ContractName.RR
    );
  }

  //// User - Registraion And Login

  public async getRegisteredRailroaderData(userAccountName: string = this.currentUserName) {
    {
      const [existingRailroaderInfo] = await this.tables.loadRows<WAX.RailroaderData>(
        WAX.TableName.RailRoaders,
        {
          scope: this.scopes.global, // Account that owns the data
          limit: 1, // Maximum number of rows that we want to get
          lower_bound: userAccountName,
          upper_bound: userAccountName,
        },
        WAX.ContractName.RR
      );

      return existingRailroaderInfo || null;
    }
  }

  public async registerRailroader(userAccountName: string = this.currentUserName) {
    return await this.actions.performActionTransaction(
      WAX.ActionName.CreateRailroader,
      {
        railroader: userAccountName,
      },
      WAX.ContractName.RR
    );
  }

  public async getTermsAndConditionsState(
    railroaderData: WAX.RailroaderData
  ): Promise<
    readonly [
      shouldShowTermsAndConditions: boolean,
      latestTermsAndConditionsVersion: WAX.SemanticVersion,
      reason: "never-accepted" | "version-changed" | null
    ]
  > {
    const [waxConfig] = await this.tables.loadRows<WAX.GameConfiguration>(
      WAX.TableName.GlobalConfiguration,
      {
        scope: this.scopes.global,
        limit: 1,
      },
      WAX.ContractName.RR
    );

    if (!waxConfig) {
      throw new Error("No game configuration found in table.");
    }

    const latestTermsAndConditionsVersion = waxConfig.terms_version;

    if (!railroaderData.terms_accepted) {
      const reason = "never-accepted" as const;
      return [true, latestTermsAndConditionsVersion, reason];
    }

    const waxUserData = await this.getRegisteredRailroaderData(this.currentUserName);

    if (!waxUserData) {
      throw new Error("No railroader data found in table.");
    }

    const latestAcceptedTermsAndConditionsVersion = waxUserData.terms_version;
    if (latestTermsAndConditionsVersion != latestAcceptedTermsAndConditionsVersion) {
      const reason = "version-changed" as const;
      return [true, latestTermsAndConditionsVersion, reason];
    }

    return [false, latestTermsAndConditionsVersion, null];
  }

  public async acceptTermsAndConditions(version: WAX.SemanticVersion) {
    const userAccountName = this.currentUserName;

    await this.actions.performActionTransaction(
      WAX.ActionName.AcceptTermsAndConditions,
      {
        railroader: userAccountName,
        version: version,
      },
      WAX.ContractName.RR
    );

    console.log(`💛 Accepted terms of service version ${version}`);
  }

  //// User - Data

  public async getRailroaderFuelStatus() {
    const [row_Coal] = await this.tables.loadRows<WAX.RailroaderSimpleAssets>(
      "accounts",
      {
        scope: this.scopes.railroader,
        limit: 1,
        lower_bound: blockchainConfig.simpleAssetId_Coal,
        upper_bound: blockchainConfig.simpleAssetId_Coal,
      },
      WAX.ContractName.SimpleAssets
    );
    const [row_Diesel] = await this.tables.loadRows<WAX.RailroaderSimpleAssets>(
      "accounts",
      {
        scope: this.scopes.railroader,
        limit: 1,
        lower_bound: blockchainConfig.simpleAssetId_Diesel,
        upper_bound: blockchainConfig.simpleAssetId_Diesel,
      },
      WAX.ContractName.SimpleAssets
    );

    const rows = [row_Coal, row_Diesel] as [
      Readonly<WAX.RailroaderSimpleAssets> | undefined,
      Readonly<WAX.RailroaderSimpleAssets> | undefined
    ];

    function isValidFuelType(key: any): key is WAX.MarketFuelType {
      return Object.values(WAX.MarketFuelType).includes(key);
    }

    const result = rows.reduce(
      (acc, row) => {
        if (row) {
          const [fuelAmount, fuelType] = row.balance.split(" ");
          if (!isValidFuelType(fuelType)) {
            console.warn(`Unknown fuel type: ${fuelType}`);
          } else {
            const parsedFuelAmount = parseFloat(fuelAmount);
            if (isNaN(parsedFuelAmount)) {
              console.error(`Invalid fuel amount string: ${fuelAmount}`);
            } else {
              acc[fuelType] = parsedFuelAmount;
            }
          }
        }
        return acc;
      },
      {
        [WAX.MarketFuelType.Diesel]: 0,
        [WAX.MarketFuelType.Coal]: 0,
      } as Record<WAX.MarketFuelType, number>
    );

    console.log(`💚 Fuel status: `, result);

    return result;
  }

  public async getRailroaderCurrentTocium() {
    const rows = await this.tables.loadRows<WAX.RailroaderTociumAccount>(
      "accounts",
      {
        scope: this.scopes.railroader,
        code: WAX.ContractName.Toc,
        limit: 1,
      },
      WAX.ContractName.RR
    );

    if (rows.length === 0) {
      return 0;
    }

    const [balanceAmountString, balanceCurrencyString] = rows[0].balance.split(" ");
    if (balanceAmountString == undefined || balanceCurrencyString !== "TOCIUM") {
      throw new Error("Invalid tocium balance record: " + rows[0].balance);
    }

    return parseFloat(balanceAmountString);
  }

  //// Gameplay - Train Composition

  public async getRailroaderTrains() {
    const trains = await this.tables.loadRows<WAX.TrainData>(
      WAX.TableName.Trains,
      {
        scope: this.scopes.railroader, // Account that owns the data
        limit: 1000,
      },
      WAX.ContractName.RR
    );

    return trains;
  }

  public async getRailroaderTrainUpgrades() {
    const upgrades = await this.tables.loadRows<WAX.TrainUpgradesData>(
      WAX.TableName.Upgrades,
      {
        scope: this.scopes.railroader, // Account that owns the data
        limit: 1000,
      },
      WAX.ContractName.RR
    );

    return upgrades;
  }

  public async createRailroaderTrain(trainName: WAX.TrainName) {
    await this.actions.performActionTransaction(
      WAX.ActionName.CreateTrain,
      {
        century: this.currentCenturyName,
        railroader: this.currentUserName,
        train: trainName,
      },
      WAX.ContractName.RR
    );
  }

  /**
   * Assigns NFTs to the passed in Train.
   *
   * @param trainName Name of the train to assign the NFTs to.
   * @param locomotives Must be an array of one or more NFTs that are of type Locomotive Card.
   * @param conductors Must be an array of one or more NFTs that are of type Conductor Card.
   * @param loads Must be either an empty array, or an array of TrainLoadData objects, each specifying one NFT of type Rail Car Card and zero or more NFTs that are of type Commodity Card.
   */
  public async updateRailroaderTrainComposition(
    trainName: WAX.TrainName,
    locomotives: WAX.CardAssetId[],
    conductors: WAX.CardAssetId[],
    loads: WAX.TrainLoadData[]
  ) {
    await this.actions.performActionTransaction(
      WAX.ActionName.SetTrain,
      {
        railroader: this.currentUserName,
        train: trainName,
        locomotives,
        conductors,
        loads,
      },
      WAX.ContractName.RR
    );
  }

  /**
   * Removes all NFTs from the given Train.
   */
  public async clearRailroaderTrainComposition(trainName: WAX.TrainName) {
    await this.actions.performActionTransaction(
      WAX.ActionName.ClearTrain,
      {
        railroader: this.currentUserName,
        train: trainName,
      },
      WAX.ContractName.RR
    );
  }

  /**
   * This is the only way to clear the tampered flag of the Train. Will clear the tampered flag given 2 scenarios:
   *
   * 1. If the railroader owns all the assets current assigned to the Train
   *
   * 2. If the given Train is empty
   *
   * @param trainName
   */
  public async verifyRailroaderTrainAssets(trainName: WAX.TrainName) {
    await this.actions.performActionTransaction(
      WAX.ActionName.VerifyTrain,
      {
        railroader: this.currentUserName,
        train: trainName,
      },
      WAX.ContractName.RR
    );
  }

  //// Gameplay - Rail Runs

  public async getOngoingRailRun(trainName: WAX.TrainName) {
    const [run] = await this.tables.loadRows<WAX.RailRunData>(
      WAX.TableName.RailRuns,
      {
        scope: this.scopes.railroader,
        lower_bound: trainName,
        upper_bound: trainName,
        limit: 1,
      },
      WAX.ContractName.RR
    );
    return run;
  }

  public async getOngoingRailRuns() {
    return await this.tables.loadRows<WAX.RailRunData>(
      WAX.TableName.RailRuns,
      {
        scope: this.scopes.railroader,
        limit: 1000,
      },
      WAX.ContractName.RR
    );
  }

  /**
   * Starts a transport with the given Train and consumes the necessary fuel from the railroader.
   *
   * @param trainName
   */
  public async startRailRun(trainName: WAX.TrainName, destinationStationId: WAX.StationAssetId) {
    return await this.actions.performActionTransaction(
      WAX.ActionName.Transport,
      {
        railroader: this.currentUserName,
        train: trainName,
        arriving: destinationStationId,
      },
      WAX.ContractName.RR
    );
  }

  /**
   * Claims the Tocium for the Railroader and the Station Owner.
   *
   * Call this when the user clicks on a station, where rewards from a finished rail run are waiting to be claimed.
   *
   * @param trainName
   */
  public async claimRailRunRewards(trainName: WAX.TrainName, destinationStationId: WAX.StationAssetId) {
    const station = await this.assets.getSingleStationAsset(destinationStationId);
    const remotePrefs = await createUserPreferencesFirebaseStorageProxy();
    const results = await this.actions.performActionTransaction(
      WAX.ActionName.ClaimRailRunReward,
      {
        railroader: this.currentUserName,
        train: trainName,
        owner: station.owner,
        auto_repair: remotePrefs.autorepairEnabled,
      },
      WAX.ContractName.RR
    );

    //// ---- ////

    function processRewardString(rewardString: WAX.RewardString) {
      const [amount, type] = rewardString.split(" ");
      return {
        amount: parseFloat(amount),
        type: type as WAX.MarketCurrencyTicker,
        originalString: rewardString,
      };
    }

  


    //// Rewards ////

    const transaction: TransactionResult = results.transaction;
    const trace_Claim = findTransactionActionTrace.byName(transaction, WAX.ActionName.ClaimRailRunReward);
   /*  console.log(JSON.stringify(trace_Claim)); */
    const trace_Claim_AMP = findTransactionActionTrace.byName(transaction, WAX.ActionName.Issue);
    if (trace_Claim == undefined) {
      throw new Error("No trace found for action: " + WAX.ActionName.ClaimRailRunReward);
    }
    const trace_Transfer = trace_Claim.inline_traces.find(
      t =>
        t.act.name === WAX.ActionName.XTransfer &&
        t.act.data.to === this.currentUserName &&
        t.act.data.memo === "transporting reward"
    );
  
    let trace_TransferData;
    let trace_TransferAmpData;

    if (trace_Transfer == undefined) {

    //Century Train
          const trace_TransferAMP = trace_Claim.inline_traces.find(
            t =>
              t.act.name === WAX.ActionName.IssueAmp &&
              t.act.data.railroader === this.currentUserName 
              );
          
          if (trace_TransferAMP == undefined) {
              throw new Error("No trace found for action: " + WAX.ActionName.IssueAmp);
              }
          trace_TransferAmpData = trace_TransferAMP.act.data.quantity;
    /////////////

        } else {
          trace_TransferData = trace_Transfer.act.data as TraceData_TokenTransfer;
        }

    //// Passenger Tips ////
    const processPassengerTipsData = (data: TraceData_PassengerTips) => {
      const preTipsBalance = 0.0001 * data.before_tips;
      const totalPercentage = data.total_tips;
      const totalFraction = 0.01 * totalPercentage;

      return {
        balanceBeforeTips: preTipsBalance,
        balanceAfterTips: preTipsBalance * (1.0 + totalFraction),

        totalTipPercentage: totalPercentage,
        totalTipAmount: preTipsBalance * totalFraction,

        tips: data.tips.map(tipData => {
          const percentage = tipData.tip;
          const fraction = 0.01 * tipData.tip;
          const amount = preTipsBalance * fraction;
          const criterion = tipData.criterion;
          const passengerTemplateId = tipData.template_id;
          return { percentage, amount, criterion, passengerTemplateId };
        }),
      };
    };
    const trace_PassengerTips = findTransactionActionTrace.byName(transaction, "logtips");
    const trace_PassengerTipsData = trace_PassengerTips && (trace_PassengerTips.act.data as TraceData_PassengerTips);

    const passengerTips = trace_PassengerTipsData;

    //// NPC Encounter ////

    const trace_NPCEncounter = findTransactionActionTrace.byName(transaction, "npcencounter");
    const trace_NPCEncounterData = trace_NPCEncounter && (trace_NPCEncounter.act.data as TraceData_NPCEncounter);

    //// ---- ////

    return {
      
      reward: processRewardString(trace_TransferData == undefined ? trace_TransferAmpData as any : trace_TransferData.quantity as any),
      
      npcEncounter: trace_NPCEncounterData && {
        train: trace_NPCEncounterData.train,
        character: trace_NPCEncounterData.npc,
        reward: processRewardString(trace_NPCEncounterData.reward as any),
      },
      passengerTips: trace_PassengerTipsData && processPassengerTipsData(trace_PassengerTipsData),
    };

    
  }

  public async claimSpecialRewards(rewardId = 0) {
    const results = await this.actions.performActionTransaction(
      WAX.ActionName.ClaimSpecialReward,
      {
        railroader: this.currentUserName,
        id: rewardId,
      },
      WAX.ContractName.RR
    );

    console.log({
      results,
    });
  }



  //// WAX Marketplace

  // public async market: RRContractMarketplaceService = new RRContractMarketplaceService(this.contracts, this.currentUserName);

  //// Map Data

  public async getStations() {
    const stations = await this.tables.loadRows<WAX.StationData_RRCentury>(
      WAX.TableName.Stations,
      {
        scope: this.scopes.century,
        limit: 400,
      },
      WAX.ContractName.RR
    );

    return stations;
  }

  public async getGameVars() {
    const [vars] = await this.tables.loadRows<WAX.GameMathVars>(
      WAX.TableName.Variables,
      {
        scope: this.scopes.global,
        limit: 1,
      },
      WAX.ContractName.RR
    );

    return vars;
  }

  public async getUserPublicKeys() {
    const user = this.currentUser as any;
    if (user instanceof AnchorUser) {
      return [user.session.publicKey?.toString()];
    }
    if (user instanceof WaxUser) {
      return await user.getKeys();
    }
    return null;
  }
}

export type PassengerTipsData = NonNullable<
  Awaited<ReturnType<WaxContractsGateway["claimRailRunRewards"]>>["passengerTips"]
>;
