export const CARD_ASSET_IPFS_HASH_TO_HUMANNAME = {
  "Qmcy69EoFyLas7MhtgyKRPBmfEFZ3gC8bZKGfBLPzWR3G4": "Locomotives/0-armed-spirit.png",
  "Qmdr38dQQ2n1cGsPdc2t348qaSX35n9ED1URxF34idV3qF": "Locomotives/1-mantis.png",
  "QmWyDibM9oGXNuqamUL8i249p6Wiv36s6bRSEGNjAhWhAf": "Locomotives/1-kodiak.png",
  "QmXEtNxUGaGzc5SypWvoW7rxDgGZRRxvgg7JN9vVUzTBkf": "Locomotives/2-olympic.png",
  "QmP65UgP3MCriwBGYBXubrPDbLQHgvcLtPxYGa7GamTrXS": "Locomotives/2-bomber.png",
  "QmTs7sEishgjCkWdQTNRXVV7QRgZFPWM5GErufgN8rWRC5": "Locomotives/5-heritage.png",
  "QmWbJGyZGkDEk73FQu3FgcApjPpesAoaMZYGMobj3E9BzZ": "Locomotives/4-mastodon.png",
  "QmUFyYVEJLzHDw3jPjysnQqrRGyEngDnGKfeuKiGYZJ3md": "Locomotives/4-interstate.png",
  "QmSGBSrPz3uSqmNZMY1K9HM96FYiAkHo9aN5fs2o2UC2Eh": "Locomotives/3-raven.png",
  "QmQxft6UgcFFoiktvXnqP5Fhv2fTqbQK7uydCSMwjpwNRg": "Locomotives/0-big-boy.png",
  "QmPYjFGuJez4KFXWmMfAEQqnr6gLWAcEz77YrQWwCccfV7": "Locomotives/3-dirty-sav.png",
  "Qmdp8dwHUJ9Lwkohi7kcKA33Se6q9HtGCMgZ5qx9DrxR3f": "Locomotives/5-evolution.png",
  "QmZmMwqSj84K1sgRbG8nYLCZzpQDb7SLQuvZEknE23wjno": "Locomotives/0-scrf.png",
  "QmZXnYwJjKa3dxs97c8rL8HU1E3ywBTKdGZg83vD6La7jk": "Locomotives/2-piedmont.png",
  "QmWrwP34XwGXScfqaFzULh3pLTvmhHYZnjQmXojET2yt2N": "Locomotives/1-illinois.png",
  "QmWErzJuBpJvs5o7miKPx9tZxZFqNtRcs5Y9eN8JmmXNFm": "Locomotives/1-illinois.png",
  "QmSqZyXqrvDdvnZ2Pz9tTz22xvDVfQGbGkhL7aqUkiXxAT": "Locomotives/Modern-Century-Train.png",


  "QmZ58cy1psYtcC2KZvmJFD5d72AYzVA1kGbWAYYUMFsUWA": "Conductors/5-kc.png",
  "QmXGoVqigmGHbwSLhmqFvb66e5mKHrVTZPy86kTrzkMDQ8": "Conductors/2-paloma.png",
  "QmcixdcU3QLtWgV3LKBuQx2kkewv1pLRbMQeFcFY7yXQK4": "Conductors/5-gutshot.png",
  "QmUsetmtRxeWZJ2Fof4W2VuLW9PVUTDbWs8hkw1NS4DzBx": "Conductors/4-wesley.png",
  "Qme915HMHsx6pmFeNLEEMmHjQBGW2s6gV2YFxceJFXZDqF": "Conductors/4-johnny.png",
  "QmZPRp24Pui8GSbaXpvvgGVN6X6LrJMhLrZrTwk6WjmcEN": "Conductors/3-osipov.png",
  "QmXdKJzkdiR8axdCwwT8aayK3dXBceXtVGmvSwDNaUJxy3": "Conductors/3-tobias.png",
  "QmdeqQnWgLZJRc91KfCcK4xPhzPqMtBWZ6dXhT55AS8xXL": "Conductors/2-missy.png",
  "QmRFQsA3bsNaejerk3VLVYseeDQ97unvRLy7K5dLymWS3j": "Conductors/2-jame.png",
  "QmVYDeawq9awofZWpBzuEFUPCmX6x347Rx44KK74jaBGxS": "Conductors/1-billy.png",
  "QmYskX9GZmbEs8eEA9GSH6DKTmmdgZzkUzWxxPSxAiMiSY": "Conductors/1-ash.png",
  "QmZ4rrKusTu7vp3tzd43JBA8WTZNumezbobJuvJb9paTHK": "Conductors/0-tommy.png",
  "QmcqXCY6N3PhUdm7nPvUTcPvQGQeTSoZ6DSRHWiofnjx18": "Conductors/0-ned.png",
  "QmSyXqF5FRg1SbPgxw1oQPj31H8TosfTNcoNUD1g54odUo": "Conductors/0-orson.png",
  "QmaW5RtoDTgUSof1ksjTgwNn2s542BqPY6HcBhwrizhtto": "Conductors/1-shayan.png",

  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Armored-MD.png": "Rail Cars/5-armored.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Auto-LG.png": "Rail Cars/5-auto.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Centerbeam-LG.png": "Rail Cars/5-centerbeam.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Armored-SM.png": "Rail Cars/4-armored.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Auto-MD.png": "Rail Cars/4-auto.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Centerbeam-MD.png": "Rail Cars/4-centerbeam.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Flat-LG.png": "Rail Cars/4-flat-car.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Auto-SM.png": "Rail Cars/3-auto.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Center-SM.png": "Rail Cars/3-centerbeam.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Flat-MD.png": "Rail Cars/3-flat-car.png",
  "QmRvChBbY3aSK2Sr7D4SbmNVUELxGo7WE9N6ZDXL7ZNFwE/railcars/Reefer-LG.png": "Rail Cars/3-reefer.png",
  "QmZgjPY9JVHLQgN3XViM6ZX9oJsYbPmXn7YwxQWLnzVTFE": "Rail Cars/3-covered-hopper.png",
  "QmUd8G3utWAkAYa9xGbNQ1JR7RT3wEJV2L9Pk61gsAAs41": "Rail Cars/2-flat-car.png",
  "QmbVLnuxRNr5JHGqr2W2wb2f6mQBXbBM5xvQxaNebC4hEZ": "Rail Cars/2-reefer.png",
  "QmcJGVi9qKvMAUuCGtUHJMf4vjc6pp6YUuATRaUE6N7DmM": "Rail Cars/2-covered-hopper.png",
  "QmYU88ACFfFgpG3PjXnzLdPz8PeHEHomVucpgepJHxvigV": "Rail Cars/2-open-top.png",
  "QmSdc5EdrzYYAkij3Vnr7fVVekw4F9Q6xkxUGhc3VLWKqG": "Rail Cars/2-tank.png",
  "QmPqBfjawJAx3DxJrkqUocdpZnES49CbTfrnnxP4HBrzbt": "Rail Cars/2-boxcar.png",
  "Qmdy1Frnfkn4HBzqcz6JJzVF8Pj5UGu9JdZMX9Wn9QivEy": "Rail Cars/1-reefer.png",
  "QmZq3rc6D957JK1qKRmSW6RExWkJgJq9bK434iNVFVLGmg": "Rail Cars/1-covered-hopper.png",
  "QmWVHeRmUCDMpG2mrWBWhqDXuyjci8kcpRpTDcAtCfFh64": "Rail Cars/1-open-top.png",
  "QmUYmBvXu6EMrEpqhCMUyrLRMuSwCnCcAgPExQHcXnEJuh": "Rail Cars/1-tank.png",
  "QmRAmRSms6x6jy4rv7zzSfpaPG5zCc8b13m3b3t512itUF": "Rail Cars/1-boxcar.png",
  "QmQFffcvP73QZFjS7QiEkhQ3hPHTZNt5RUeKC6ZPsdL66W": "Rail Cars/0-open-top.png",
  "QmQBCNetVmti17ywYnvpLES8fu9WGMSiyb8owUd4KKoyhq": "Rail Cars/0-tank.png",
  "QmbWXrQspj9jtPdLemaeKbDyhAdbvn313A2jDq88PjxWwL": "Rail Cars/0-boxcar.png",
  "QmU814K5b1K1cLM42K5c7RfuSbpmsKoKm4Nj7Hbmc8rx95": "Rail Cars/0-auto.png",

  "QmVRbQ6BxYfyx1VcjBh1MU6EryeUDpgoVjgysonRsvdoeA": "Commodities/5-TopSecret-Entity9-ToC.png",
  "QmfCzXjZmaXAjJrQftSRAordtwsnNqivNZ7y1q7xUiCUgr": "Commodities/5-TopSecret-Photo1077-ToC.png",
  "QmZ8K2npx16HQjaLuTf6gjevqLK9kkvhJuKgcBcrBTpGPJ": "Commodities/5-TopSecret-Photo115-ToC.png",
  "QmWf4QToe4tnCe2rmJduN3527isTB5Y3wxGi2qZBbZD2SR": "Commodities/5-Automobiles-Sports-ToC.png",
  "QmW6gv4N1AzsQjneskaHPE6ygVnSHq2Y221QY9MYHyNnvY": "Commodities/5-Building-Steel-ToC.png",
  "QmZuhMgUpfLCM5UdJUKBFbg19xhK55PCE1Td8xGZNDeGtj": "Commodities/5-Perishable-Beef-ToC.png",
  "QmWWNbVHLmDTffCQ3XYtA3pWNuvKcc9SnMboyMAzvjpN2y": "Commodities/5-Grain-Quinoa-ToC.png",
  "QmNUm3BPftq5kD1XvgHfrHN6RoJ4xa2HApFrSofZsJzZ8k": "Commodities/5-Ore-Stibnite-ToC.png",
  "QmUFsiu2Di6UDhuZQ2RkBzC1gjTYVdznTrEoSyk7teXMDo": "Commodities/5-Gas-Xenon-ToC.png",
  "QmdiBo8bs3TsjiPLJ7e6PkRPYgGacMxsj5D9vvNSjiPgjA": "Commodities/5-Crate-Antiques-ToC.png",
  "QmfHxMpLmXhKbQvfVWUn15kEQoAC773ttDXPoyVkTKD9kC": "Commodities/5-Pallet-Cream-ToC.png",
  "QmZZvTnwoMuEBmHjyMoreGeeUYpLF43mXn2S9SJhPAvoxr": "Commodities/4-TopSecret-Item147-ToC.png",
  "QmW8s7bsRkhQpeEbLKFPdfhtXDFoCBVmz8rbHcsQyzsMA1": "Commodities/4-TopSecret-Item203-ToC.png",
  "QmQ4pFeuX4wVYZB5K6SRx2NBuc5jG3idprEmwyVj2aDJxn": "Commodities/4-TopSecret-Item632-ToC.png",
  "QmQV8QdhR12TtxyCsmrzFCoksU4MbQDzqBaGWURwKtg1nc": "Commodities/4-Automobiles-Truck-ToC.png",
  "Qmcs8tzmGvCZP9QTgGjQ1SQX6qX1URtrijFBmUZPiDdesD": "Commodities/4-Building-Lumber-ToC.png",
  "QmZDyp15psknLeWtpKBWvJuiP8RCiJS5xffiUL17N59ScH": "Commodities/4-Oversized-Tank-ToC.png",
  "QmeadU7jiNWAbtg27T6rPSSAZHfqJM6rP2LnxtKj8BRbG2": "Commodities/4-Perishable-Insulin-ToC.png",
  "QmYGCprrLDv7QbdARbFpNggckgeiMjusTEXbWhtMHPQkdw": "Commodities/4-Granule-Soda-ToC.png",
  "QmVD4GpfeqP13MpVxAghnR36uKM39DJJJf7tiVGeqUPwhY": "Commodities/4-Ore-Magnetite-ToC.png",
  "QmaJNc15uixduTq14FUMuYgJXSgRmJT1M1SgcEoGJZmDXj": "Commodities/4-Liquid-JetFuel-ToC.png",
  "QmXGSMzw3HeejZfvYcWEnLXv7j5ikGxbjpG8LP8AScX17J": "Commodities/4-Crate-Whiskey-ToC.png",
  "QmWgdcPEqRkqPSZ3yYfZxPby8pNR4QuRTgcoH1wT64Gbmi": "Commodities/4-Pallet-Toys-ToC.png",
  "QmWzgibGcTdR6g2sFhtYJ2gLcNyzUkTtbvx4C5fNeU593B": "Commodities/3-Automobiles-SUV-ToC.png",
  "QmRg45YmMDPkpSE4o6yE2ZDFmpze6E7wpjwbwDheAM4J5T": "Commodities/3-Building-Plywood-ToC.png",
  "QmWvX48hkdkn6dwdJ9GGhMyUSygQnabk7Vzbb6FvFdmf11": "Commodities/3-Oversized-Bulldozer-ToC.png",
  "QmdBJZGwVkHCbNKMVvuP2uAjkh417Xy5oM7fxELzJtYjQZ": "Commodities/3-Perishable-Fish-ToC.png",
  "Qma3zMQYZRMRPPbr1YbJ7YsMqmfhrMGiYumJcZuvUiRDHs": "Commodities/3-Granule-Sugar-ToC.png",
  "QmXwumecoukVKvhK8qe87xQtisud8kYacxwbshkeJkoNQN": "Commodities/3-Ore-Halite-ToC.png",
  "QmXoaGPSuuzsy852e3R4epxqs1tStj51KDBJG61Ufy4SCh": "Commodities/3-Gas-Nitrous-ToC.png",
  "QmadN9xEHgSQb89thcpUyodRsgy2Nsw1tMmHrHAxscZLZC": "Commodities/3-Crate-Laptops-ToC.png",
  "QmPmCTMjtXSjsBNGoaS1rEn13GVYUfyC4Hu9V3Pio5hD5u": "Commodities/3-Pallet-Jeans-ToC.png",
  "Qmaf6qECyMtvY2NoJSz7xyXNT4VEYgXNNhp2oyGhwMVJsj": "Commodities/2-Automobiles-Coupe-ToC.png",
  "QmexBXQcwHCEz2Tf2KFUPVT4aLBTvbm8abNxT31PC4Q8Tb": "Commodities/2-Oversized-Tractor-ToC.png",
  "QmXtJk5jj8tSqPFr5cKEaCZJSkMF4MoeBfrANoRTCX8Wtx": "Commodities/2-Perishable-Icecream-ToC.png",
  "QmUzZGbAqcKV85ab5mMXPpjGRBzmg1WXtdHYFQUUj8FZDg": "Commodities/2-Grain-Buckwheat-ToC.png",
  "QmfSrSfP9YufmQPWiKvwaGRmVoXzK3oPu4tUSvAAJXUffm": "Commodities/2-Aggregate-RiverRock-ToC.png",
  "QmXJnGAsxvGgxpAp4aq9MPsfiqpp2w9Lanf8KB3ggPM9Fg": "Commodities/2-Liquid-Water-ToC.png",
  "QmWxUkYH2zpWf48HdjWmvAAWYf1ytTDaSNSFMUNLvZoC1X": "Commodities/2-Crate-Cigars-ToC.png",
  "QmXfhT8UJyoejCdq3FkbA3vUPfPCF6twdk7eZ1AMuM7aTN": "Commodities/2-Pallet-Puppy-Food-ToC.png",
  "QmQQJFRCwMRCovueqcoGEy8andT1yXkZT4mhXtDnKmcjiJ": "Commodities/1-Automobiles-Minivan-ToC.png",
  "QmQUAa3RjuUgWtNjNMt8ybJzwPJnzXcvRy9UzZZ2BufpDn": "Commodities/1-Perishable-Apples-ToC.png",
  "QmU82iu5zQ6X826MxkebD8b2sc44oirxm6yb22hKS6fKkZ": "Commodities/1-Granule-Sand-ToC.png",
  "QmPKxE4MYyQFSTxVgHxQFzboVFZgVeQuKTZ4pc5P3pyjfB": "Commodities/1-Aggregate-Gravel-ToC.png",
  "QmcrKrnhCW9sHZvQYgcLQttxx7zMBVRnyjGoEyL9eUKDE9": "Commodities/1-Gas-Propane-ToC.png",
  "Qmd3D9aqEUqteyNPSbq6AdtfdTtNVQnvWVJJcRKFVXMsfi": "Commodities/1-Crate-Vitamins-ToC.png",
  "QmSzSfwKt4WpwJvBWmoLtfoCup9QgVQDyTw7zoMTvmiQjd": "Commodities/1-Pallet-Soup-ToC.png",
  "Qmc9YY4tB6CXzNkNZHTqoSXmJ91e3NcHqMcoZJgvxgQmG7": "Commodities/0-Automobiles-Sedan-ToC.png",
  "QmQC31HgFvvnJbLZUiWb1nzJPb3BCYHz23La9YitMgYA7D": "Commodities/0-Perishable-Milk-ToC.png",
  "QmTmQfmBjWT47xitKCzZsazf8oW3wT7vYqvS7TtLuwcn6u": "Commodities/0-Grain-Rice-ToC.png",
  "QmWvap9n7NokfSX8H76NsCqUwNdydLr4UPwGyP1gSKnx5q": "Commodities/0-Aggregate-Woodchips-ToC.png",
  "QmW3NexoWjZ2Gtd7zTpBxgtwGSd4KzDvUfcAXxKnDgAFJz": "Commodities/0-Liquid-Petroleum-ToC.png",
  "QmaNVUXfQQHtBoyFNRqSNdAYj7brzsuMmMrxTAMXyut9js": "Commodities/0-Crate-Books-ToC.png",
  "QmSJfyQSjfhpp9yXy3Vwb4TrLrNo4xX4QULHBRUY51TXVE": "Commodities/0-Pallet-Bars-ToC.png",
  "QmPURRJPJX29X5cN7RDqtsZ7qabenzuVNVwH9ZGi8QFBsw": "Commodities/4-Pallet-Pumpkin.png",

/*Christmas 2022*/
  "QmTDYABhM8ccs5BcJQLSdpqoH4yps3CexYj4yzN8uboDPB": "Commodities/3-Christmas-Trees.png",
  "QmY9MPy1ZT2JYPBk5ELZbVVC3hn9mWzDiktJucMfX9BQSE": "Commodities/2-Santa-Sleigh.png",
  "Qmb7yiBQwbAEUR2KaUtwjvJwG5NDCePa5nmGRQUz7ivNzY": "Commodities/4-Reindeer-Polish.png",

  /*Christmas 2023*/
  "QmVV76thcNUARrLAZVp7rKTptHWGRutL8bf6zwe9WV2eAT": "Commodities/4-Santa-Dozer.png",
  "QmUGPjknSKQeAvQGefCmQV49Ke6EQdoZQ9nf65jcBiwu81": "Commodities/5-Eggnog-Ice-Cream.png",
  "QmRLAGxxWcDGTtYDCVgyPYJjnbUTKyQW4CTGiFif4PWAcY": "Commodities/3-Nativity-Figurines.png",
  "QmSsGw5fSmNBddRiQxkJz8iH3D4fP4RX1jgZyFENpCWKP5": "Commodities/2-Christmas-Wreaths.png",

  /*JPCC*/
  "QmTUqdLvE8qqZrQoAyJVyCMNaUeGWBbFSwCgmThc3yNSMp":  "Commodities/4-Confetti.png",
  "QmSe1WKY1zb7WKiwdm3ew1acYMehkxqc8YqzfXX3JvLDjK":  "Commodities/4-Hydrogen.png",
  "QmVN3USKXTQCxufJLrxBKnRDDpAHoS6X5DLWj2QYSkQkzA":  "Commodities/3-Limestone.png",

  "QmUnuZc7joySGkU63r6WGJ1fA2sMLaUnxHxR9XV8iWijrh": "Passenger Cars/0-Coach-Car.png",
  "QmQCKGqqzKHngAmQX18enENT9rXsQ3qeWG7EAeULBLHD61": "Passenger Cars/1-Corridor-Car.png",
  "Qme1v3bxWZtCJ4wA2SYZ1pYQo9VqPaZvpGLPZVGCWoxfCr": "Passenger Cars/2-Dining-Car.png",
  "QmStctU9F2dHRtRqnLnwerWDScNTZMHawUxWcXcKLAfczS": "Passenger Cars/3-Dome-Car.png",
  "QmdUaWxNHeJDueiwq9YGrJyMdWxhhPN8pd7nTsCkNk21Dv": "Passenger Cars/4-Bilevel-Car.png",
  "QmQWkXP6M5wNaw8rYrdhHeJefhwe6HzAgJmsQpBpUHQNjf": "Passenger Cars/5-Combo-Car.png",

  "Qmd2CyHjK5A7PYcSf28TTFKvSKg5B8miA61cRCiNHiGYxe": "Passengers/0-frankie.png",
  "Qmd8ATxqqkV6Pp2idFQmttQuGbFRUPzJ3cvrHrUwXi52sH": "Passengers/0-Lulu.png",
  "QmSg8pwj7hjtSHXKNkNeH69h6EWhbACkQfCFsKV5SnpX9P": "Passengers/0-shakia.png",
  "QmTeeSF6GSJ7vKrHZg1esWQQiwrZDBMA1VKiVr22B6gPzV": "Passengers/1-Beth.png",
  "QmcaBu3KiP76SNZ7bD5n7xzJxHgsWZnMQeWM2yVoPcA7Uq": "Passengers/1-Connor.png",
  "QmZqHjFRageoQCy78CXUdMavS3G5KKuNYYN74F5VNn28dA": "Passengers/1-Raul.png",
  "QmV99DBwudMV8SK5tdi9GXW4D3QVJTmanE1qYJrMroMWSc": "Passengers/2-Aparna.png",
  "QmVXXQG4yj54aUQzLW36eTgotDfk2n92DhkD8tZrbUYPXa": "Passengers/2-Dansby.png",
  "QmUjAhMpRfaxA3P28C11137FLPaLrLj4JghLWHqjfiV2bm": "Passengers/2-Jasper.png",
  "QmYLtwohkrFxDMo64MBfjP3gFcF8c7spadq2eVzgSgVY9v": "Passengers/3-Demarcus.png",
  "QmSe9xMMUns2qjroosDnwrgjeTe7g9o126yWuJv6ZyXi9M": "Passengers/3-Shadow.png",
  "QmacpDnD1jWykt1oBy5n8f1SCGiFbtAkb8yfQTtFs9CVSQ": "Passengers/4-Andrea.png",
  "QmaVmkdW1iTFE3GJ9oCzwkLgBWGvG6zVXdQBgWNLrLKgEm": "Passengers/4-PNLOP.png",
  "QmQ2MQV8ak1tUxdB91HtY4Dx51D2pfURiRiCwYx2A49UFo": "Passengers/5-Felix.png",
  "QmYiHyptQsAjxjJoTzCLnQh7PwFqWFwzEprqcHA9KHrdmg": "Passengers/5-Marisol.png",

  // Upgraded, Common
  "QmU5H4oz6BJHVc8MmLNJiQkhrbqHoUdjY5tX5K1Eh3mj84": "Locomotives Upgraded/steel-armed-spirit.png",
  "QmbUtt3LWeDQb2RCFLRBdyQg5VHGTdRVbf2YgT6Z66NVYi": "Locomotives Upgraded/steel-big-boy.png",
  "QmcALxJA1at48CkkwBEtxiadBXwQNnQGaLjy1P8pKUCJZq": "Locomotives Upgraded/steel-scrf-75.png",
  "QmcAyZFEXEHbrcwsywZwQ28B5mLhUX2GXrdHY1TqYH6rrh": "Locomotives Upgraded/carbon-armed-spirit.png",
  "QmNjYwL32iq4kwJHm1mxMqYhiEdhH8i7Fs2URrUKuX4dGa": "Locomotives Upgraded/carbon-big-boy.png",
  "QmR8DuFRmdLD6BUrTyJ5trn1cApCwRnMCsij28xwvKWB6p": "Locomotives Upgraded/carbon-scrf-75.png",
  "QmYuqBSkwGarvQoTNNukXk4SGNongTMqKck73asaPeQ76s": "Locomotives Upgraded/tungsten-armed-spirit.png",
  "Qmd6W1xtRiHLYWApomcB3AjWTiQYQJesi7T11CXs7MpKur": "Locomotives Upgraded/tungsten-big-boy.png",
  "QmdP4qQP33QyJ4pxg3daJL59wYXGCFxNeg66ohd3NYXoax": "Locomotives Upgraded/tungsten-scrf-75.png",

  // Upgraded, Uncommon
  "QmZBcgm4dpVDABNGcvAaP9GTyUVNTeMAGJ6G3Z4EyGpG7e": "Locomotives Upgraded/steel-illinois.png",
  "QmYSF4J38Ro1Qq6foqZfsT7RAiKwjbx8YcR8AbhXrd2Nat": "Locomotives Upgraded/steel-mantis.png",
  "QmY76DDTRzx9RGUbX2Kwg86DZZhCG7MpPUHwGNACzooRV3": "Locomotives Upgraded/steel-kodiak.png",
  "QmZwJGP1pPcF3HMnub2n386S7xnccd9wXRn9Std4j19pEb": "Locomotives Upgraded/carbon-illinois.png",
  "QmPFKxD6SY9MGt4Pc2zPci9d7c8qbCNE4DQFGitLU83L8f": "Locomotives Upgraded/carbon-mantis.png",
  "QmV7TKHzgXYHYtAAf7YtstGwDrVELVL5GtNHuyEMYNC3i8": "Locomotives Upgraded/carbon-kodiak.png",
  "QmY1Hp38nmyWK7sSV1kS6WJxMfGmyEox1haPpinsW1kQae": "Locomotives Upgraded/tungsten-illinois.png",
  "QmRcyMLdbdGXiEYCrvMur9oD6cjBn9utgBuqcWC4ysYUGq": "Locomotives Upgraded/tungsten-mantis.png",
  "Qmc96Tvb5TUDrSxmmZyZxFC2PwmT2tH6YwEx5MVzfUGGXe": "Locomotives Upgraded/tungsten-kodiak.png",

  // Upgraded, Rare
  "QmQDW1CbD41v4pidT8smzwGkq1f7ChaP7WaR3TSKxNaxHj": "Locomotives Upgraded/steel-bomber.png",
  "QmdKD6CFMxvZ6YwpdfkCv7oh7G7xMeDt7xUyNH8iiYHThV": "Locomotives Upgraded/steel-olympic.png",
  "QmQqbAhdiRv8RwSnunZ1WDMCjzShQ9eUNWwBb1U3QLTPrP": "Locomotives Upgraded/steel-piedmont.png",
  "QmVi1MYnhiJHPrjLuQgJt7fcxYxZ2FqETy5Pdd1Xcpu4UC": "Locomotives Upgraded/carbon-bomber.png",
  "QmbssfJykfdqbDmRq43X6etVKaDuDHeT4tA7fRFxTQhQvn": "Locomotives Upgraded/carbon-olympic.png",
  "QmTseR7iUWfbYS5R6uy3onvuDopD5tPz2qof53w2f7nhLG": "Locomotives Upgraded/carbon-piedmont.png",
  "QmYbCUX1YpYGQcu3pZVhodrUrtJ7eCUPiaHMvZ9YzMpLpf": "Locomotives Upgraded/tungsten-bomber.png",
  "QmVz36D1DAQyefton7AoZCT9WQvhgr3wFVCviY6bkf35V8": "Locomotives Upgraded/tungsten-olympic.png",
  "QmNQAMnCGqzAk7q17G5mNewZHRaC5fa4ry7PPVfWCLyEoo": "Locomotives Upgraded/tungsten-piedmont.png",

  // Upgraded, Epic
  "QmY9Ayy8cR1TTmmBCPprvshGvZsC3sksBb45Jt8RWE26Qw": "Locomotives Upgraded/steel-dirty-sav.png",
  "QmS56R2af8piajopXxDn6LfWGabfnFgvdmE2wkAvj12tVc": "Locomotives Upgraded/steel-raven.png",
  "QmYnG6qEhiaMu1zFhBXhFeiAm7Dtkf3s4ZzvUTZFMDujaF": "Locomotives Upgraded/steel-interstate.png",
  "QmQT4AhRJwbPQhjQLpNSKiw5Jb2Pnx5cFJRGoM2t3oDPKa": "Locomotives Upgraded/steel-mastodon.png",
  "QmU1F3wFzMhQXLek4ggzmZYhTnZHQtgP2e9E8f1kdAS4pG": "Locomotives Upgraded/steel-evolution.png",
  "QmeY4ovr56VECSek2QFsvmfbCXFMkCGUBgqopNuCBC5AhD": "Locomotives Upgraded/steel-heritage.png",

  // Upgraded, Legendary
  "QmSbk9FhBdpciH6RLrVFQ8yWRCkJ2C6K1PYFQ3amkp9HoE": "Locomotives Upgraded/carbon-dirty-sav.png",
  "QmduebBvpqBn6LEDNeexuVHnn9mGVvHfuZoEyC6hgS264S": "Locomotives Upgraded/carbon-raven.png",
  "QmRorRVmkiK7iEynQQjiJkU4vzuvCsf3qj2ozQ4MYF2ipn": "Locomotives Upgraded/carbon-interstate.png",
  "QmRrzdUWfymVuvKPi4aFxq2LYuv6CUeDt8UAXFqHhCEKe7": "Locomotives Upgraded/carbon-mastodon.png",
  "QmctpesudKPhy3UD5XBRw2ZHxmC8qCC6G8M54YC6k2pfcv": "Locomotives Upgraded/carbon-evolution.png",
  "QmcLRyxJTFTxAqQ7oQhPWUGhqiKjBnR6o7brtYoW7hiHw7": "Locomotives Upgraded/carbon-heritage.png",

  // Upgraded, Mythic
  "QmTN7MZe3zzNpm6DmiJGriaSfKKWjPUF8rj8RifPJR8YPE": "Locomotives Upgraded/tungsten-dirty-sav.png",
  "QmbTzrcnDZiVsMNZaYuUCqnx6RRZwq45vfpFBWPP9G8LqT": "Locomotives Upgraded/tungsten-raven.png",
  "QmPjVKFCx2QAi9MG8PKu7A9X9J2RBaK19b7EYFVbavyzph": "Locomotives Upgraded/tungsten-interstate.png",
  "Qme2T5j9vggd4LMJL8MQnxQyacTGk1TVyNNcGJpJyBqqQY": "Locomotives Upgraded/tungsten-mastodon.png",
  "QmSF3v1NTBqCEJ6c48kPVtbj4CHSNFkHnvTCx2pdconip6": "Locomotives Upgraded/tungsten-evolution.png",
  "QmVjWTqG5oqWH4pMe6jvpTDv3SkbH7LX7RRDFa2JY6Qtj1": "Locomotives Upgraded/tungsten-heritage.png",

  // Deprecated Cards
  "QmPZ1KMp8HQbhoockFG9ckP8ytXrnHwStdETpjWL7MbMwL": "Locomotives Upgraded/tungsten-armed-spirit.png",
  "QmW83tU2yg9oBnYYg1wATXf3xPFJMQXAYcprrK4E4j7oTg": "Locomotives Upgraded/tungsten-big-boy.png",
  "QmWhp9kyNdLD8EK2Bunm1CHLzJmswgVryXHLutjuda5X6z": "Locomotives Upgraded/tungsten-scrf-75.png",
  "QmSgpV7yZGBfuLgQPnnyP4QXzVcir2vMxskkH57zD3GyaR": "Locomotives Upgraded/steel-illinois.png",
  "QmUEt3D4vYoePbHfFNdrh5HZcvJV3itjLp76Nja2oTqwR4": "Locomotives Upgraded/carbon-illinois.png",
  "QmQMtKxXHQcurxfDZtXjTYxHpVggoH6YFmg5mWAeCbR7qv": "Locomotives Upgraded/tungsten-illinois.png",

  // Test Cards
  "QmYZFwMLLsHQX4GzXQDaw69uNCBNJTnrNQK5eNHAC1ZAhg": "Locomotives Upgraded/steel-mastodon.png",
  "QmcgQEjTspbr5kmcvyCNqRGw98J8KqX6X13Rw4ZLDQHGa1": "Locomotives Upgraded/carbon-mastodon.png",
  "QmadDfiLFBNjRfndwAujxtDsy5MQiW5sMNHubLE2Rm8AP7": "Locomotives Upgraded/tungsten-mastodon.png",
  "QmZBp8mGR5524BscwToxjUMHgFf8h6yEQ53nP8owTLUpkJ": "Passenger Cars/2-Dining-Car.png",
} as Record<string, string>;
