import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { AvatarBadgeElements } from "@game/ui/social/avatar/AvatarBadgeElements";
import { SimplifiedAvatarBadge } from "@game/ui/social/avatar/SimplifiedAvatarBadge";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Text } from "@pixi/text";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";
import { getRandomItemFrom } from "@sdk/helpers/arrays";

const commonLabelStyle = {
  fill: "#FFFFFF",
  fontFamily: FontFamily.DefaultThin,
};

export class LeaderboardListRow extends Container {
  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  applyRailroaderData({ placement = "", username = "", value = 0, socialLink = "" } = {}, width: number) {
    this._width = width;
    this._height = 60;

    const fontSize = 16;
    const centerY = this._height / 2;
    const rowXCoordinates = [55, 100, 140, 525];

    /// Placement Spot Text
    const placementLabel = new Text("" + placement, {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    placementLabel.position.set(rowXCoordinates[0], centerY);
    placementLabel.anchor.set(1, 0.5);
    this.addChild(placementLabel);

    /// Simplified avatar badge
    const avatar = new SimplifiedAvatarBadge();
    SimplifiedAvatarBadge.applyUserData(avatar, username);
    SimplifiedAvatarBadge.showWhenFullyLoaded(avatar);
    avatar.scale.set(0.07);
    avatar.position.set(rowXCoordinates[1], centerY);
    this.addChild(avatar);

    /// Name Text
    const accountLabel = new Text(username, { ...commonLabelStyle, fontSize: fontSize });
    accountLabel.position.set(rowXCoordinates[2], centerY);
    accountLabel.anchor.set(0, 0.5);
    this.addChild(accountLabel);

    /// Tocium Amount Text
    const tociumLabelString = formatToMaxDecimals(Math.round(value), 1, true);
    const tociumLabel = new Text(tociumLabelString, { ...commonLabelStyle, fontSize: fontSize });
    tociumLabel.position.set(rowXCoordinates[3], centerY);
    tociumLabel.anchor.set(1, 0.5);
    this.addChild(tociumLabel);

    /// Row Underline
    const graphics = new Graphics()
      .lineStyle(1.25, 0xffffff)
      .moveTo(placementLabel.x - 20, this._height)
      .lineTo(rowXCoordinates[3] + 10, this._height);
    this.addChild(graphics);

    /// Open social profile on click
    if (socialLink) {
      GameSingletons.getMainInstance().social.registerForQuickViewOnClick(accountLabel, socialLink);
    }
  }

  applyStationData({ placement = "", stationName = "", value = 0, socialLink = "" } = {}, width: number) {
    this._width = width;
    this._height = 40;

    const fontSize = 16;
    const centerY = this._height / 2;
    const rowXCoordinates = [55, 100, 140, 525];

    /// Placement Spot Text
    const placementLabel = new Text("" + placement, {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    placementLabel.position.set(rowXCoordinates[0], centerY);
    placementLabel.anchor.set(1, 0.5);
    this.addChild(placementLabel);

    /// Simplified avatar badge
    const station =
      GameSingletons.getGameContext().mapData.stationsArray.find(s => s.name === stationName) ||
      getRandomItemFrom(GameSingletons.getGameContext().mapData.stationsArray);
    if (station) {
      const backgroundImage = AvatarBadgeElements.Backgrounds_Stations[station.rarityLevel - 1];
      const avatar = new SimplifiedAvatarBadge();
      SimplifiedAvatarBadge.applyPreferences(avatar, { backgroundImage });
      SimplifiedAvatarBadge.showWhenFullyLoaded(avatar);
      avatar.scale.set(0.045);
      avatar.position.set(rowXCoordinates[1], centerY);
      this.addChild(avatar);
    }

    /// Name Text
    const accountLabel = new Text(station.name, {
      // const accountLabel = new Text(stationName, {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    accountLabel.position.set(rowXCoordinates[2], centerY);
    accountLabel.anchor.set(0, 0.5);
    this.addChild(accountLabel);

    /// Tocium Amount Text
    const tociumLabel = new Text(formatToMaxDecimals(Math.round(value), 1, true), {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    tociumLabel.position.set(rowXCoordinates[3], centerY);
    tociumLabel.anchor.set(1, 0.5);
    this.addChild(tociumLabel);

    /// Row Underline
    const graphics = new Graphics()
      .lineStyle(1.25, 0xffffff)
      .moveTo(placementLabel.x - 20, this._height)
      .lineTo(rowXCoordinates[3] + 10, this._height);
    this.addChild(graphics);

    /// Open social profile on click
    if (socialLink) {
      GameSingletons.getMainInstance().social.registerForQuickViewOnClick(accountLabel, socialLink);
    }
  }

  applyStationData_({ placement = "", stationName = "", value = 0, socialLink = "" } = {}, width: number) {
    this._width = width;
    this._height = 30;

    const fontSize = 16;
    const centerY = this._height / 2;
    const rowXCoordinates = [55, 110, 525];

    /// Placement Spot Text
    const placementLabel = new Text("" + placement, {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    placementLabel.position.set(rowXCoordinates[0], centerY);
    placementLabel.anchor.set(1, 0.5);
    this.addChild(placementLabel);

    /// Name Text
    const stationLabel = new Text(stationName, {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    stationLabel.position.set(rowXCoordinates[1], centerY);
    stationLabel.anchor.set(0, 0.5);
    this.addChild(stationLabel);

    /// Tocium Amount Text
    const tociumLabel = new Text(formatToMaxDecimals(Math.round(value), 1, true), {
      ...commonLabelStyle,
      fontSize: fontSize,
    });
    tociumLabel.position.set(rowXCoordinates[2], centerY);
    tociumLabel.anchor.set(1, 0.5);
    this.addChild(tociumLabel);

    /// Row Underline
    const graphics = new Graphics()
      .lineStyle(1.25, 0xffffff)
      .moveTo(placementLabel.x - 20, this._height)
      .lineTo(rowXCoordinates[2] + 10, this._height);
    this.addChild(graphics);

    /// Open social profile on click
    if (socialLink) {
      GameSingletons.getMainInstance().social.registerForQuickViewOnClick(stationLabel, socialLink);
    }
  }
}
