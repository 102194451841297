import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { BuildingEntity } from "@game/data/entities/BuildingEntity";
import { StationEntity } from "@game/data/entities/StationEntity";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { addVignette } from "@game/overlays/addVignette";
import { TilingSpriteDimmer, TilingSpriteDimmerTemplates } from "@game/ui/common/TilingSpriteDimmer";
import { Container } from "@pixi/display";
import { StationAssetId } from "@sdk-integration/contracts";
import { addCloudsToOperationsLayer } from "./layer_operations/fx/addCloudsToOperationsLayer";
import { makeOperationsWorldLayer } from "./layer_operations/makeOperationsWorldLayer";
import { makeInstantTransmissionOverlay } from "./layer_regions/content/makeInstantTransmissionOverlay";
import { makeRegionsWorldLayer } from "./layer_regions/makeRegionsWorldLayer";
import { StationVisual } from "./visuals/StationVisual";


export enum WorldZoomLevel {
  OPERATIONS = "operations",
  REGIONS = "regions",
}

export class World extends EnchantedContainer {
  private readonly context: GameContext = GameSingletons.getGameContext();
  private readonly viewport = this.context.viewport;

  public readonly zoomLayers;
  public readonly persistentLayers;

  public readonly dimmer = new TilingSpriteDimmer(TilingSpriteDimmerTemplates.STRIPES);

  constructor() {
    super();

    /**
     * Casting this as "const" is a shorthand for assigning all properties
     * to the object as read-only for stricter typing.
     */
    this.zoomLayers = {
      operationsLayer: this.addChild(makeOperationsWorldLayer()),
      regionsLayer: this.addChild(makeRegionsWorldLayer()),
    } as const;

    this.persistentLayers = {
      instantTransmission: this.addChild(makeInstantTransmissionOverlay()) as Container,
    } as const;

    addVignette();
  }

  async initialize() {
    this.context.stageContainers._worldVignette.addChild(this.dimmer);

    const zoomTier = this.viewport.getCurrentZoomTier();
    this.zoomLayers.operationsLayer.shouldShow = zoomTier <= 1;
    this.zoomLayers.regionsLayer.shouldShow = zoomTier > 1;
    this.zoomLayers.operationsLayer.fastForwardAlphaUpdate();
    this.zoomLayers.regionsLayer.fastForwardAlphaUpdate();

    this.onEnterFrame.add(() => {
      this.x = this.viewport.x;
      this.y = this.viewport.y;

      this.scale.set(this.viewport.scaled1440p);

      const zoomTier = this.viewport.getCurrentZoomTier();
      this.zoomLayers.operationsLayer.shouldShow = zoomTier <= 1;
      this.zoomLayers.regionsLayer.shouldShow = zoomTier > 1;
    });

    addCloudsToOperationsLayer(this.zoomLayers.operationsLayer);
  }

  ////// ////// ////// ////// //////

  getStationVisuals() {
    return this.zoomLayers.operationsLayer.stationsContainer.stations;
  }

  getStationVisual(stationDataOrId: StationEntity | StationAssetId): StationVisual | null {
    if (typeof stationDataOrId !== "string") {
      stationDataOrId = stationDataOrId.assetId;
    }
    return this.zoomLayers.operationsLayer.stationsContainer.stations.get(stationDataOrId) || null;
  }

  getCurrentZoomLevel() {
    /**
     * Zoom tier is just a numerid representation of the zoom level.
     */
    const tier = this.viewport.getCurrentZoomTier();
    if (tier <= 1) {
      return WorldZoomLevel.OPERATIONS;
    }
    return WorldZoomLevel.REGIONS;
  }

  getZoom() {
    return this.viewport.scaled;
  }

  setInteractionEnabled(enabled: boolean) {
    this.zoomLayers.operationsLayer.stationsContainer.interactiveChildren = enabled;
    this.zoomLayers.operationsLayer.tracksContainer.interactiveChildren = enabled;
  }

  public async moveViewportTo(target: StationEntity | TrainEntity | { x: number; y: number }) {
    if (target instanceof TrainEntity) {
      const stationId = target.currentDestinationStationId ?? target.currentStationId!;
      const station = this.context.mapData.stations.get(stationId);
      if (station == null) {
        throw new Error(`Station ${stationId} not found`);
      }
      target = station;
    }
    this.context.viewport.moveTo(target.x, target.y, 0.65);
    await this.context.ticker.delay(0.65);
  }

  public async moveViewportBldg(target: BuildingEntity) {
    
    this.context.viewport.moveTo(target.x, target.y, 0.65);
    await this.context.ticker.delay(0.65);
  }
}
