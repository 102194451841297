import { Graphics } from "@pixi/graphics";

export class SemiCircle extends Graphics {
  public fraction = 0;

  constructor(public radius: number) {
    super();
  }

  setFraction(fraction: number, color: number = 0xffffff) {
    this.clear();

    this.fraction = fraction;

    const fi = Math.PI * fraction;
    const deg90 = Math.PI / 2;

    this.beginFill(color);
    this.arc(0, 0, this.radius, deg90 - fi, deg90 + fi);
    this.endFill();
  }
}
