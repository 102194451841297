import { GameSingletons } from "@game/app/GameSingletons";
import { modifyPivotWithoutChangingPosition } from "@game/asorted/centerPivotWithoutChangingPosition";
import { Texture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { nextFrame } from "@sdk/utils/promises";
import { AchievementBadge } from "../../achievements/AchievementBadge";
import { AchievementBadgeData } from "../../achievements/AchievementBadgeData";

export class AchievementsList extends SafeScrollbox {
  boxHeight: number;

  constructor(boxHeight: number) {
    super({
      noTicker: true,
      boxWidth: 625,
      boxHeight: boxHeight,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });
    this.boxHeight = boxHeight;
  }

  async addAchievements(data: Array<AchievementBadgeData>) {
    const tweeener = new TemporaryTweeener(this);

    let startX = 35;
    let startY = 5;
    for (let [index, info] of data.entries()) {
      const badge = AchievementBadge.create(info);
      badge.scale.set(0.25);
      badge.position.set(startX, startY);
      this.content.addChild(badge);

      if (info.name) {
        const { tooltips } = GameSingletons.getGameContext();
        tooltips.registerTarget(badge, info.name);
      }

      const columnIndex = index % 6;

      modifyPivotWithoutChangingPosition(badge, { x: 0.5, y: 0.5 });
      tweeener.from(badge, {
        pixi: { scale: 0 },
        duration: 0.85,
        ease: "elastic.out",
        delay: columnIndex * 0.033,
      });

      startX += 100;
      if (columnIndex == 5) {
        startY += 75;
        startX = 35;
        await nextFrame();
      }
    }
    this.content.addChild(this.addInvisibleBox(this.boxHeight + 1));
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clear() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
