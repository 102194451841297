import { FontIcon } from "@game/constants/FontIcon";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";
import { MarketCurrencyTicker } from "../internal/datamodels";

const currencyIconsByTicker = {
  [MarketCurrencyTicker.Tocium]: FontIcon.Tocium,
  [MarketCurrencyTicker.AMP]: FontIcon.AnomaticParticles,
};

export function parsePriceString(s: `${number} ${string}`) {
  const [amount, currency] = s.split(" ") as [`${number}`, string];
  const formattedAmount = formatToMaxDecimals(amount, 4, true);
  const icon = currency in currencyIconsByTicker ? currencyIconsByTicker[currency as MarketCurrencyTicker] : null;
  return {
    amount: +amount,
    currency: currency,
    fullString: s,
    icon: icon,
    formattedAmount: formattedAmount,
    formatted: `${icon}${formattedAmount}`,
  };
}
