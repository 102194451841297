import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { FontFamily } from "@game/constants/FontFamily";
import { ThemeColors } from "@game/constants/ThemeColors";
import { createUpdatesTypeModal } from "@game/ui/asorted/updatesModal/createUpdatesModalBase";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { gsap } from "gsap/all";
import { EventBannersSlideData } from "./EventBannersGalleryData";
import { LightDotSlideShow } from "./LightDotSlideShow";

const delaySlideTime = 5;

/**
 * Featured events banner slideshow gallery component
 */
export class EventBannersGallery extends Container {
  private readonly simpleFactory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly slideshow = gsap.timeline({ repeat: -1, delay: 5, repeatDelay: 5 });
  private readonly dots: LightDotSlideShow;

  constructor(slides: EventBannersSlideData[]) {
    super();

    //// Dots
    this.dots = new LightDotSlideShow(slides.length);
    this.dots.position.set(445, 200);
    this.dots.onDotClick = (index: number) => {
      this.slideshow.currentLabel(index.toString());
    };
    this.addChild(this.dots);

    //// Set initial slide
    this.setSlide(slides[0]);
    this.dots.activeDot(0);

    for (const [index, slide] of slides.entries()) {
      this.slideshow.addLabel(index.toString(), index * delaySlideTime);
      this.slideshow.add(() => {
        this.clearContent();
        this.setSlide(slide);
        this.dots.activeDot(index);
      }, index * delaySlideTime);
    }
    this.slideshow.play();
  }

  setSlide(slide: EventBannersSlideData) {
    //// Add Event Title Card
    const image = this.simpleFactory.createSprite(slide.textureId, { x: 0, y: 115 });
    image.scale.set(0.7);
    this.addChildAt(image, 0);

    //// Add Event Title text
    const title = this.simpleFactory.createText(slide.title, {
      fontFamily: FontFamily.Default,
      fontSize: 35,
      fill: 0xffffff,
    });
    title.position.set(25, 360);
    this.addChild(title);

    //// Add Event Body text
    const description = this.simpleFactory.createText(slide.summary, {
      fontFamily: FontFamily.DefaultThin,
      fontSize: 20,
      fill: 0xffffff,
      wordWrap: true,
      wordWrapWidth: 400,
      lineHeight: 30,
    });
    description.position.set(25, 415);
    this.addChild(description);

    //// Add Event Footer text
    const footerText = slide.articleText ? slide.altFooter : slide.footer;
    const footer = this.simpleFactory.createText(footerText, {
      fontFamily: FontFamily.Default,
      fontSize: 20,
      fill: 0xf0b503,
      align: "right",
    });
    footer.anchor.set(1, 0);
    footer.position.set(400, 475);
    this.addChild(footer);

    //// Add underline
    const underline = new Graphics()
      .lineStyle(1.25, ThemeColors.ACTIVE_COLOR.toInt())
      .moveTo(25, 525)
      .lineTo(image.width - 25, 525);
    this.addChild(underline);

    //// Add On-Click events
    const onClick = slide.articleText ? () => createUpdatesTypeModal(true, slide) : slide.function;
    if (onClick != undefined) {
      buttonizeDisplayObject(image, onClick);
      buttonizeDisplayObject(footer, onClick);
    }

    //// Animation

    let i = 0;
    for (const child of [image, title, description, footer]) {
      const tweeener = new TemporaryTweeener(child);
      tweeener.from(child, { alpha: 0, duration: 0.65, delay: (i += 0.1) });
    }

    this.addChild(this.dots);
  }

  clearContent() {
    const children = [...this.children];
    for (const child of children) {
      if (child == this.dots) continue;
      const tweeener = new TemporaryTweeener(child);
      tweeener.to(child, { alpha: 0, duration: 0.35 }).then(() => child.destroy({ children: true }));
    }
  }
}
