export const CARD_HUMANNAME_TO_HASHED_FILE_PATH = {
  "Conductors/0-ned.png": "b70cf3a0811c5d81f3fc3f8497aecd7e",
  "Conductors/0-orson.png": "1fbc7f6a29dfb3c6470c96e10402daa6",
  "Conductors/0-tommy.png": "40ee2df953271206f8f734c03cd18b76",
  "Conductors/1-ash.png": "5406b282096ce7a4e1ffce1dc681ce73",
  "Conductors/1-billy.png": "c422b8a9d7ba93e495d36746bd2b91c6",
  "Conductors/1-shayan.png": "020e71efebc5181d82a3ee7a225e0025",
  "Conductors/2-jame.png": "8f9cbb8caf539044ee2223c55b60439d",
  "Conductors/2-missy.png": "72c57d426002134f9eade3c1e25da93d",
  "Conductors/2-paloma.png": "6c4ed5699bde8afe2141a078b8c75a40",
  "Conductors/3-osipov.png": "7f454bed035ba397b62de6fd8986eb68",
  "Conductors/3-tobias.png": "db7838efb68581ecce6b61affd4cea16",
  "Conductors/4-johnny.png": "ede14cac5e868bd3dfcb54adedfd90b5",
  "Conductors/4-wesley.png": "baa5736a504360c66f67c245ac420328",
  "Conductors/5-gutshot.png": "08e96a6367a1224571475a9dc2b7e530",
  "Conductors/5-kc.png": "5d37e99c71e79a84932031e76c4603a0",

  "Locomotives/0-armed-spirit.png": "3632a87f923a2df28e477a38016302d7",
  "Locomotives/0-big-boy.png": "3f2f5b788a9b8529bf481d63cd4904a9",
  "Locomotives/0-scrf.png": "8c66c81f7466bf03a8386c5e5e1f8d9d",
  "Locomotives/1-illinois.png": "d065e3934f0bdc59457b4be855cb21a9",
  "Locomotives/1-kodiak.png": "631333a1cfb670d30ebdad36e4ceeefd",
  "Locomotives/1-mantis.png": "e7813f34ef664d113dc2d9b86d626413",
  "Locomotives/2-bomber.png": "30b9435e94cf38769e434a9f76b2c1f0",
  "Locomotives/2-olympic.png": "a6e9d750bc7795a62c841f781f4565d6",
  "Locomotives/2-piedmont.png": "7db373d6d2ddb67c32b9e6fb87d81910",
  "Locomotives/3-dirty-sav.png": "bc7c14d5ed6de7e22ab8b2ccc0456950",
  "Locomotives/3-raven.png": "2f41c26b03a86f412b36abf220f5cd16",
  "Locomotives/4-interstate.png": "8bcdaaca4d32c1caaa752f2a16ca2744",
  "Locomotives/4-mastodon.png": "f096b5f5145ac54084b94fb26b9efb10",
  "Locomotives/5-evolution.png": "60f594f69e67016f65ef1086c2156616",
  "Locomotives/5-heritage.png": "dc24af5209ec7757919059d0e163e390",
  "Locomotives/Modern-Century-Train.png": "6555c41d24d01b6f93fbde11bd1bba08",

  "Rail Cars/0-auto.png": "a916c219a692a7eae25b60e2a494e3aa",
  "Rail Cars/0-boxcar.png": "d4e72fef9cf0ccd70883e2c0ed5a4dbd",
  "Rail Cars/0-open-top.png": "ab5d895771633570680886c3ce81a0f5",
  "Rail Cars/0-tank.png": "27d5cbc2fed9483792af425dbcf89512",
  "Rail Cars/1-boxcar.png": "53f5095079b241ccb2ffb76474fb236d",
  "Rail Cars/1-covered-hopper.png": "a53ccacebb167fbb3a18b4d4f86d9dc6",
  "Rail Cars/1-open-top.png": "8eaa9bd6cdd558c7ea5eec7e62d1c96f",
  "Rail Cars/1-reefer.png": "e5360481e69d4bfb996a2f9a142fbf08",
  "Rail Cars/1-tank.png": "3cf64f7b32c16183a28ebd77fc32c683",
  "Rail Cars/2-boxcar.png": "a1574ac62f4b80a96816413fee5512a2",
  "Rail Cars/2-covered-hopper.png": "22949916749d4de5bfd4bc90692a2a23",
  "Rail Cars/2-flat-car.png": "03231baf17483ec99f416b237f3c9bd7",
  "Rail Cars/2-open-top.png": "d1edbf0f367dd3aa53b378bee7dba123",
  "Rail Cars/2-reefer.png": "2ce83f9beedb5e6b266fdfc327060b7c",
  "Rail Cars/2-tank.png": "483cb95099e46fd1e7ce93f6437cb14f",
  "Rail Cars/3-auto.png": "dc570edc28a2be2bac26b1a3f31783aa",
  "Rail Cars/3-centerbeam.png": "df92037526c5a3963ed2825b593dc2ce",
  "Rail Cars/3-covered-hopper.png": "a713101573f5c8a69d5b39e4fc45b0f6",
  "Rail Cars/3-flat-car.png": "08c4c13c6b23d0cba89c29b91e8b0825",
  "Rail Cars/3-reefer.png": "42ab3c3e16eecbe51d89fdd208b83d98",
  "Rail Cars/4-armored.png": "625c344f302d2ea8120a63f66de89051",
  "Rail Cars/4-auto.png": "48bf9c14dacbb5c80679254e07c86665",
  "Rail Cars/4-centerbeam.png": "cb155fc7f02401f06d5bc63c359f4362",
  "Rail Cars/4-flat-car.png": "bcd2911f0ffb194ac325498e24839330",
  "Rail Cars/5-armored.png": "d40edb77a3d86905cea6119d820a9603",
  "Rail Cars/5-auto.png": "a4cc515c016978bec6acf56c5a5b84f8",
  "Rail Cars/5-centerbeam.png": "083d39e9268fe0bcc487717ab7d449bc",

  "Commodities/0-Aggregate-Woodchips-ToC.png": "006540e2577bb4d2edd7d22402645155",
  "Commodities/0-Automobiles-Sedan-ToC.png": "488e8e6c226122706c54709497299a22",
  "Commodities/0-Crate-Books-ToC.png": "41b5fb5d2803094867bfbf230b39faf1",
  "Commodities/0-Grain-Rice-ToC.png": "acf5d08b1abe1e1c5a6121188615dd15",
  "Commodities/0-Liquid-Petroleum-ToC.png": "ba3453bba087ab9a83baf296783ce18f",
  "Commodities/0-Pallet-Bars-ToC.png": "830f38203ac5b8d43a96ebe881a15bac",
  "Commodities/0-Perishable-Milk-ToC.png": "c3b8f0a9366aac787abe2c4dbc9608c9",
  "Commodities/1-Aggregate-Gravel-ToC.png": "73d29bf86df5f6744d82fe60b7a844dc",
  "Commodities/1-Automobiles-Minivan-ToC.png": "ad2a08107de4674d006b4fa422e1a40c",
  "Commodities/1-Crate-Vitamins-ToC.png": "f758a7e319450672929a1c735705a7ab",
  "Commodities/1-Gas-Propane-ToC.png": "4277dac372b38a60247b9496f4665a92",
  "Commodities/1-Granule-Sand-ToC.png": "8ce2db26dec5106790b22d1972b099e8",
  "Commodities/1-Pallet-Soup-ToC.png": "bec3bed65fd79d4f6bd99cfaa2d4dc1e",
  "Commodities/1-Perishable-Apples-ToC.png": "a5dc881b0eca9b2d46dd0811e83e822c",
  "Commodities/2-Aggregate-RiverRock-ToC.png": "70ca06f87f3d6483d73969a85fe45bab",
  "Commodities/2-Automobiles-Coupe-ToC.png": "e0ab7035964992fab5775b8950ef3f2c",
  "Commodities/2-Crate-Cigars-ToC.png": "421fae7fa8ff4f82f3bc5e24ea8f6afc",
  "Commodities/2-Grain-Buckwheat-ToC.png": "b9fbaf71da4d6fdec75760b4549849dd",
  "Commodities/2-Liquid-Water-ToC.png": "ec82a42ece3471106d5b8091d1bfa223",
  "Commodities/2-Oversized-Tractor-ToC.png": "91d2f757e93453b4eb737bb09995135e",
  "Commodities/2-Pallet-Puppy-Food-ToC.png": "2286162380b5be6c7f3c14618e955709",
  "Commodities/2-Perishable-Icecream-ToC.png": "af54fcad65fb40598b9b1f6ee123fb61",
  "Commodities/3-Automobiles-SUV-ToC.png": "6cf67e6055748f328c54ead9aeb29ea5",
  "Commodities/3-Building-Plywood-ToC.png": "9bb2a702adb70749d6f44aca56638ac1",
  "Commodities/3-Crate-Laptops-ToC.png": "76c30bd4aa61d7f4d0dccade5b91f6d7",
  "Commodities/3-Gas-Nitrous-ToC.png": "2778a85aea300237b64e13b1c681bb38",
  "Commodities/3-Granule-Sugar-ToC.png": "e943f1d3a56a783165e6daba4887fc7f",
  "Commodities/3-Ore-Halite-ToC.png": "381386755c9a3c7717479aad57b728c4",
  "Commodities/3-Oversized-Bulldozer-ToC.png": "ba34ebb75262d469a3efca46063a638f",
  "Commodities/3-Pallet-Jeans-ToC.png": "59d2fead58dd4e4a7db1d0c787540f16",
  "Commodities/3-Perishable-Fish-ToC.png": "d46e3e3eea661c74725afc7e4054bef0",
  "Commodities/4-Automobiles-Truck-ToC.png": "fce7a8717e08e760038eabf575f43d6a",
  "Commodities/4-Building-Lumber-ToC.png": "1ff58605d3075a7d3f1e2a4316850b0f",
  "Commodities/4-Crate-Whiskey-ToC.png": "f239ffc9c42de54d6683beab9cd9d64b",
  "Commodities/4-Granule-Soda-ToC.png": "c03105e05e865f41eece5eb25d38cc2a",
  "Commodities/4-Liquid-JetFuel-ToC.png": "57d5d3facc0042be520ff7cc2884fbe2",
  "Commodities/4-Ore-Magnetite-ToC.png": "93b07c0f74368870aa48f6d45916e490",
  "Commodities/4-Oversized-Tank-ToC.png": "1b19ae250436d346fdcec05f21988cde",
  "Commodities/4-Pallet-Toys-ToC.png": "3d287facb28c5e75d3fe21eb57727372",
  "Commodities/4-Perishable-Insulin-ToC.png": "da81d4afe2e345952d1450d96759d536",
  "Commodities/4-TopSecret-Item147-ToC.png": "c72568be9bda49bfdaef5948c83122de",
  "Commodities/4-TopSecret-Item203-ToC.png": "e746489c62322d6425abc07a00a61311",
  "Commodities/4-TopSecret-Item632-ToC.png": "11c961f5b01fe9a63901a543a29b222f",
  "Commodities/5-Automobiles-Sports-ToC.png": "12ed5509a187c051ca290e2ce33f9f71",
  "Commodities/5-Building-Steel-ToC.png": "dd9ece1fe9b5caf19e3692e1982c3312",
  "Commodities/5-Crate-Antiques-ToC.png": "0792cd27f0ee4b0bec5ade2491b9436c",
  "Commodities/5-Gas-Xenon-ToC.png": "6f8df3e613e74d7f5a0b9f01ba1c45f8",
  "Commodities/5-Grain-Quinoa-ToC.png": "6f9834a22536e69fb6403a4d33dd553d",
  "Commodities/5-Ore-Stibnite-ToC.png": "b09a50006358a4f7d0b230a61a13af67",
  "Commodities/5-Pallet-Cream-ToC.png": "fd0f8b7830997d4111dd984cee4f5b4e",
  "Commodities/5-Perishable-Beef-ToC.png": "ad21159f815d332ccb8aa4f66768b080",
  "Commodities/5-TopSecret-Entity9-ToC.png": "dd0a878d58971aedbe105a5ebf594884",
  "Commodities/5-TopSecret-Photo1077-ToC.png": "2019fa310a4594cf6160ef1a570168f9",
  "Commodities/5-TopSecret-Photo115-ToC.png": "2bfe4e4f0b7a7e820ee703c312e07dd8",
  "Commodities/4-Pallet-Pumpkin.png": "5def3ea0973521c5dde8788744340db8",
/*Christmas 2022*/
  "Commodities/3-Christmas-Trees.png": "8c9dac5a7597ca2b461b4f6b26b480d5",
  "Commodities/2-Santa-Sleigh.png": "494e7285dd7706f7f419b4d927abb93a",
  "Commodities/4-Reindeer-Polish.png": "186b1cae576239856097ef430d6ffe00",

  /*Christmas 2023*/
  "Commodities/4-Santa-Dozer.png": "837c2e65fc1459f739d8656cd5798ae3",
  "Commodities/5-Eggnog-Ice-Cream.png": "e14b98ddb77d8dde29c4171a76aff6b2",  
  "Commodities/3-Nativity-Figurines.png": "5dde2240ce462f976a01fa02fc464f80",
  "Commodities/2-Christmas-Wreaths.png": "69084a37c0934097efcfcbc28c73bc3f",

  /*JPCC*/
  "Commodities/4-Confetti.png": "97a517c0bd74f2b8cee498ff24fb3748",
  "Commodities/4-Hydrogen.png":"81e2226542d43fe7911f524ae9a8cb49",
  "Commodities/3-Limestone.png":"90ce7743ffdc6eccb240559e79c03583",

  "Passengers/0-Lulu.png": "8939ccb5924a0375fc1d181e6b3a51eb",
  "Passengers/0-frankie.png": "eada761e219ba041d3dff5a642546c40",
  "Passengers/0-shakia.png": "a9f74fbad9078990ea443b9c08eddfbd",
  "Passengers/1-Beth.png": "8e14536d1babd0df88b35093c54bc180",
  "Passengers/1-Connor.png": "6bd0336e732c866db551e3e6f78b82c5",
  "Passengers/1-Raul.png": "2f637730a1d5f6dde38189946f95744c",
  "Passengers/2-Aparna.png": "6637a168b5795adbf3abb98d11d10484",
  "Passengers/2-Dansby.png": "9f3a979bc3848b744c8b5bd3cfa87638",
  "Passengers/2-Jasper.png": "0c722eaccba63152183aede8ca3d22e6",
  "Passengers/3-Demarcus.png": "e8ff1f6569ae421e0c44b1d8d836dba9",
  "Passengers/3-Shadow.png": "67615c5baad79d2b5e5da7d04b3a5607",
  "Passengers/4-Andrea.png": "af7afa31990219a7e87cbcb63bf287e6",
  "Passengers/4-PNLOP.png": "6a81b7953a9506a61daa4f773bce5433",
  "Passengers/5-Felix.png": "78ccadf82225fb9a1e87bf7241bb22bd",
  "Passengers/5-Marisol.png": "bcd5c2e0fd9d07aaf091aeee32f6d52f",

  "Passenger Cars/0-Coach-Car.png": "c0e4b91de6ea77c1e9dbd05aac34ef94",
  "Passenger Cars/1-Corridor-Car.png": "ffc843178c5b9f0a22906cc36979a2c7",
  "Passenger Cars/2-Dining-Car.png": "2c0f9d60ae45b27bc7bd7df3660f1488",
  "Passenger Cars/3-Dome-Car.png": "c0082bb9450744b5e418ed2ef4ecf0bc",
  "Passenger Cars/4-Bilevel-Car.png": "1acc2d31341311afefb0deb7b28d500e",
  "Passenger Cars/5-Combo-Car.png": "0fa32d27c408b874bbe1b4757ed9c0fe",

  // Upgraded, Common
  "Locomotives Upgraded/carbon-armed-spirit.png": "2a83303a7cad79b5fcb320826aeb35fd",
  "Locomotives Upgraded/carbon-big-boy.png": "1b4dfc2204fd3665bf73dba40322d526",
  "Locomotives Upgraded/carbon-scrf-75.png": "4fce22bdca531fe5ac98bd8e030e9aab",
  "Locomotives Upgraded/steel-armed-spirit.png": "2490d68be8f352432dc39ccfb6721a97",
  "Locomotives Upgraded/steel-big-boy.png": "28bfc1ebf2b0072daa14bef2936ce829",
  "Locomotives Upgraded/steel-scrf-75.png": "e82319a97a511c7c1e46ab80e8f15bca",
  "Locomotives Upgraded/tungsten-armed-spirit.png": "f197b528c118fe57fcb3d8a0c48c1a56",
  "Locomotives Upgraded/tungsten-big-boy.png": "7c32ac16c799a3b1d6ea49b778bc25b6",
  "Locomotives Upgraded/tungsten-scrf-75.png": "f7612dea40adafb8207aff63ab32fa21",

  // Upgraded, Uncommon
  "Locomotives Upgraded/carbon-illinois.png": "74d9f4a3e9d44def56f8c54ceae8c1b6",
  "Locomotives Upgraded/carbon-kodiak.png": "1a1fea041c93cf6299e5b2b6c84feef0",
  "Locomotives Upgraded/carbon-mantis.png": "ad15a37a55d2817f5a216c02da08a3aa",
  "Locomotives Upgraded/steel-illinois.png": "a4e56f79da96d0f2ffaea1ce9ea2b6ba",
  "Locomotives Upgraded/steel-kodiak.png": "525131f0eeeee047f26fd868653a8ea0",
  "Locomotives Upgraded/steel-mantis.png": "ca2c3ce37ce36b40978ad4b36ac82fff",
  "Locomotives Upgraded/tungsten-illinois.png": "2c380df238c457e7ed7bac369b74ba66",
  "Locomotives Upgraded/tungsten-kodiak.png": "f406af2936539d20a2c485a439fbb18f",
  "Locomotives Upgraded/tungsten-mantis.png": "90eee6ef84753a760916915a1674ac1a",

  // Upgraded, Rare
  "Locomotives Upgraded/carbon-bomber.png": "4df84a1804ee560ecea0a94e5c1ac09a",
  "Locomotives Upgraded/carbon-olympic.png": "9155acfe5a2cc9fe2680f1959bc55536",
  "Locomotives Upgraded/carbon-piedmont.png": "882cf313851063165d00f975a2024acc",
  "Locomotives Upgraded/steel-bomber.png": "5957b5d56d47fd19358d6db09b66a810",
  "Locomotives Upgraded/steel-olympic.png": "65b01ec025853b8460a6d1e982d633d1",
  "Locomotives Upgraded/steel-piedmont.png": "1e36973214f96bf66daa3da280cd761c",
  "Locomotives Upgraded/tungsten-bomber.png": "c0c3ad5cab2eee8ec447808b68f9bd9c",
  "Locomotives Upgraded/tungsten-olympic.png": "4e77a579c2510dc5e54e6055658af6a3",
  "Locomotives Upgraded/tungsten-piedmont.png": "281a27815a7ff1cba151514234aa7f96",

  // Upgraded, Epic
  "Locomotives Upgraded/steel-dirty-sav.png": "10fa2eb33aa8a8fd40ccd570a5a8e9c0",
  "Locomotives Upgraded/steel-raven.png": "c1c34d802ceadfbaacef1cd6ab1aa5fe",
  "Locomotives Upgraded/carbon-dirty-sav.png": "5db8f3eabb568863ca20d28451ce1df7",
  "Locomotives Upgraded/carbon-raven.png": "9779e9179530dafbb4bd8f792f95b25c",
  "Locomotives Upgraded/tungsten-dirty-sav.png": "a26e21c702324aebefa95ac39b644029",
  "Locomotives Upgraded/tungsten-raven.png": "5bd5650a0fe9d38bc7bafe7abb9a8726",

  // Upgraded, Legendary
  "Locomotives Upgraded/steel-interstate.png": "36e66708bf0c722375d914df7da117af",
  "Locomotives Upgraded/steel-mastodon.png": "c976e86ab73faf8aefd0e43c4342b1b8",
  "Locomotives Upgraded/carbon-interstate.png": "98a830a7b517c3e58c638fd37b13a9bf",
  "Locomotives Upgraded/carbon-mastodon.png": "5ce7eff2939c044f4b5a212a13c12104",
  "Locomotives Upgraded/tungsten-interstate.png": "5154e5d751ab83d1e98d93d0df0b4731",
  "Locomotives Upgraded/tungsten-mastodon.png": "aff538aaef1f7d9773a9460dadebadf6",

  // Upgraded, Mythic
  "Locomotives Upgraded/carbon-evolution.png": "0450d5bf259423486397b04a84d05ea6",
  "Locomotives Upgraded/carbon-heritage.png": "8a5439376c63aa00e7537b3d712f516c",
  "Locomotives Upgraded/steel-evolution.png": "6f90c396999e8facd05535c3a6f61c52",
  "Locomotives Upgraded/steel-heritage.png": "c1d4763bf4bbf4e86ba54ba8e128eabc",
  "Locomotives Upgraded/tungsten-evolution.png": "58e662fe16dea69ab8ef6030114cb1fb",
  "Locomotives Upgraded/tungsten-heritage.png": "e7ffe4f1dda9d06235611aef49b7147f",
} as Record<string, string>;
