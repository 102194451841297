import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { FontFamily } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { fitObjectInRectangle } from "@sdk-pixi/layout/fitObjectInRectangle";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { AchievementBadge } from "../../achievements/AchievementBadge";
import { AchievementBadgeData } from "../../achievements/AchievementBadgeData";

const badgePositions = [
  { x: 225, y: 145 },
  { x: 325, y: 145 },
  { x: 425, y: 145 },
  { x: 525, y: 145 },
  { x: 625, y: 145 },
  { x: 275, y: 210 },
  { x: 375, y: 210 },
  { x: 475, y: 210 },
  { x: 575, y: 210 },
];

export class SelectedFeaturedAchievementsView extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private railroaderTag: Text = this.factory.createText("", { fontFamily: FontFamily.DanielBlack, fontSize: 28 });
  badgeContaners: Container[] = [];
  selectedBadges: Map<AchievementBadgeData, AchievementBadge> = new Map();
  isDesktop: boolean = true;

  onChange?: () => void;
  onBadgeClicked?: (achievementData: AchievementBadgeData) => void;
  
  constructor() {
    super();

    //// Add background
    const bgTexureId = "ui-railroader-dashboard/page-featured-achievements/featured-bg-top.png";
    const bg = this.factory.createSprite(bgTexureId);
    this.addChild(bg);

    //// Add title
    const titleTextureId = "ui-railroader-dashboard/page-featured-achievements/feat-title.png";
    const title = this.factory.createSprite(titleTextureId, { x: 150, y: 50 });
    title.scale.set(1.25);
    this.addChild(title);

    //// Add badge tag
    this.addUpBadgeTagText();

    //// Add achievements slots
    this.addBadgePlaceholders();

    //// Add bottom text
    this.addHint("(CLICK TO REMOVE)");
  }

  addHint(string: string) {
    const hint = this.factory.createText(string, { fill: 0x4f5253, fontSize: 20 }, { x: 425, y: 270 });
    hint.anchor.set(0.5);
    this.addChild(hint);

    if (this.isDesktop) {
      this.interactive = true;
      this.on("mouseover", () => {
        hint.visible = true;
      });
      this.on("mouseout", () => {
        hint.visible = false;
      });
      hint.visible = false;
    }
  }

  addUpBadgeTagText() {
    const tagBg = this.factory.createSprite("ui-social/badge-tag.png", { x: 285, y: -30 });
    this.addChild(tagBg);

    fitObjectInRectangle(this.railroaderTag, { x: 350, y: -65, width: 180, height: 120 });
    this.addChild(this.railroaderTag);
  }

  addBadgePlaceholders() {
    const container = new Container();
    for (const badge of badgePositions) {
      const badgeContainer = this.createBadgeContainer(badge.x, badge.y);
      this.badgeContaners.push(badgeContainer);
      container.addChild(badgeContainer);
    }
    this.addChild(container);
  }

  createBadgeContainer(x: number, y: number) {
    const badgeContainer = new Container();
    const badgeSprite = this.factory.createSprite("ui-railroader-dashboard/page-featured-achievements/plus.png");
    badgeContainer.position.set(x, y);
    badgeSprite.anchor.set(0.5);
    badgeContainer.addChild(badgeSprite);
    badgeContainer.scale.set(1.25);
    buttonizeDisplayObject(badgeContainer, () => {
      const info = (badgeContainer.children[0] as AchievementBadge).info;
      if (!info) console.log("Badge slot is empty");
      else {
        this.onBadgeClicked?.(info);
        this.onChange?.();
      }
    });
    return badgeContainer;
  }

  addAchievement(achievementInfo: AchievementBadgeData, position: number = -1) {
    if (position != -1) {
      const badge = AchievementBadge.create(achievementInfo);
      badge.scale.set(0.25);
      badge.position.set(-30, -35);
      this.badgeContaners[position].removeChildAt(0);
      this.badgeContaners[position].addChild(badge);
      this.selectedBadges.set(achievementInfo, badge);
    } else {
      //// Add badge texture to the first empty slot
      for (const container of this.badgeContaners) {
        if (this.checkIfContainerIsEmpty(container)) {
          const badge = AchievementBadge.create(achievementInfo);
          badge.scale.set(0.25);
          badge.position.set(-30, -35);
          container.removeChildAt(0);
          container.addChild(badge);
          this.selectedBadges.set(achievementInfo, badge);
          break;
        }
      }
    }
  }

  removeAchievement(achievementInfo: AchievementBadgeData) {
    const achv = this.selectedBadges.get(achievementInfo);
    if (!achv) throw Error("Achievement not found");
    const parentContainer = achv.parent;
    this.selectedBadges.delete(achievementInfo);

    const plusSprite = this.factory.createSprite("ui-railroader-dashboard/page-featured-achievements/plus.png");
    plusSprite.anchor.set(0.5);
    //// Destroy existing badge sprite
    const child = parentContainer.removeChildAt(0);
    child.destroy();
    //// Add the placeholder sprite
    parentContainer.addChild(plusSprite);
  }

  checkIfContainerIsEmpty(container: Container) {
    let check = false;
    if (
      (container.children[0] as Sprite).texture &&
      (container.children[0] as Sprite).texture.textureCacheIds[0] ==
        "ui-railroader-dashboard/page-featured-achievements/plus.png"
    ) {
      check = true;
    }

    return check;
  }

  clear() {
    for (const badge of this.selectedBadges.values()) {
      const badgeSprite = this.factory.createSprite("ui-railroader-dashboard/page-featured-achievements/plus.png");
      badgeSprite.anchor.set(0.5);
      badge.parent.addChild(badgeSprite);
      badge.destroy();
    }
    this.selectedBadges.clear();
  }
}
