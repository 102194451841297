import { Container, DisplayObject } from "@pixi/display";
import { CRTFilter } from "@pixi/filter-crt";
import { GlitchFilter } from "@pixi/filter-glitch";
import { addTemporaryFilter } from "@sdk/pixi/fx/addTemporaryFilter";

const DURATION = 1.1;

export async function applyPreNPCEncounterScreenEffect(stage: DisplayObject) {
  return Promise.all([npcEncoutnerScreenEffects.applyGlitch(stage), npcEncoutnerScreenEffects.applyCRT(stage)]);
}

export const npcEncoutnerScreenEffects = {
  applyFastGlitch(target: DisplayObject, duration = DURATION) {
    const RGB_MAX_OFFEST = 4;
    const GLITCH_MAX_OFFEST = 20;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: duration,
      onUpdate: ({ progress, filter }) => {
        filter.offset = GLITCH_MAX_OFFEST;
        const vx = 0;
        const vy = RGB_MAX_OFFEST * Math.random();
        filter.red = [vx, 0];
        filter.green = [-vx, -0];
        filter.blue = [vx, 0];
        filter.refresh();
      },
      filterPadding: 50,
      filterConstructorParameters: {
        slices: 20,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  },

  applyGlitch(target: DisplayObject, duration = DURATION) {
    const RGB_MAX_OFFEST = 3;
    const GLITCH_MAX_OFFEST = 20;

    // const context = GameSingletons.getGameContext() as GameContext;
    const fps = 240;
    const chance = 20 / fps;
    return addTemporaryFilter(GlitchFilter, target, {
      duration: duration,
      onUpdate: ({ progress, filter }) => {
        if (Math.random() < chance) {
          filter.offset = GLITCH_MAX_OFFEST;
          const vx = 0;
          const vy = RGB_MAX_OFFEST * Math.sin(progress * Math.PI) ** 1;
          filter.red = [vx, vy];
          filter.green = [-vx, -vy];
          filter.blue = [vx, vy];
          filter.refresh();
        }
      },
      filterPadding: 50,
      filterConstructorParameters: {
        slices: 20,
        fillMode: GlitchFilter.MIRROR,

        red: [4, 0],
        green: [0, 0],
        blue: [-4, 0],
      },
    });
  },

  applyCRT(target: DisplayObject, duration = DURATION) {
    return addTemporaryFilter(CRTFilter, target, {
      duration: duration,
      onUpdate: ({ progress, filter }) => {
        const v = Math.sin(progress * Math.PI);
        const vSqrt = Math.pow(v, 0.5);
        filter.seed = v;
        filter.time += 0.1;
        filter.noise = 0.6 * Math.random() * vSqrt;
        filter.lineContrast = 0.25 * vSqrt;
        filter.lineWidth = 5 - 4 * Math.pow(v, 0.5);
        //filter.vignettingAlpha = vSqrt;
      },
      filterPadding: 50,
      filterConstructorParameters: {
        curvature: 1,
        lineWidth: 4,
        lineContrast: 0.5,
        noise: 0.5,
        verticalLine: false,
        vignetting: 0,
        // vignettingAlpha: 0,
        // vignettingBlur: 0,
        seed: 1000,
      },
    });
  },
};
