import * as WAX from "@sdk-integration/contracts";
import { CenturyName } from "./internal/datamodels";
import { WaxContractActionService } from "./WaxContractActionService";
import { WaxContractTableService } from "./WaxContractTableService";
import type { WalletUser } from "./typing/WalletUser";

export type FuelProductPriceInformation = {
  price: number;
  currency: WAX.MarketCurrencyTicker;
  precision: number;
};

export type UpgradePriceInformation = {
  basePrice: number;
  tierMultiplier: number;
  limit: number;
};

export class WaxContractMarketService {
  readonly currentCenturyName;
  readonly actions;
  readonly tables;

  private _fuelPricesInformation: null | Record<WAX.MarketFuelType, FuelProductPriceInformation> = null;
  private _upgradePricingInformation: null | Record<WAX.MarketTrainUpgradeType, UpgradePriceInformation> = null;

  constructor(
    public readonly user: WalletUser,
    services: {
      actions: WaxContractActionService;
      tables: WaxContractTableService;
      currentCenturyName: CenturyName;
    }
  ) {
    const { actions, tables, currentCenturyName } = services;
    this.currentCenturyName = currentCenturyName;
    this.actions = actions;
    this.tables = tables;
  }

  async greateUpgradePricesInformation() {
    if (this._upgradePricingInformation == null) {
      const rows = await this.tables.loadRows<WAX.MarketRailCarUpgradesInfo>(
        "upgrades",
        {
          scope: "m.century",
          limit: 1000,
        },
        WAX.ContractName.M
      );

      this._upgradePricingInformation = rows.reduce((acc, row) => {
        acc[row.upgrade_type as WAX.MarketTrainUpgradeType] = {
          basePrice: +row.base_price,
          tierMultiplier: +row.tier_m,
          limit: +row.limit,
        };
        return acc;
      }, {} as Record<WAX.MarketTrainUpgradeType, UpgradePriceInformation>);
    }

    console.log({
      _upgradePricingInformation: this._upgradePricingInformation,
    });

    return this._upgradePricingInformation;
  }

  async greateFuelPricesInformation() {
    if (this._fuelPricesInformation == null) {
      const rows = await this.tables.loadRows<WAX.MarketProductPricingData>(
        "prices",
        {
          scope: this.currentCenturyName,
          limit: 1000,
        },
        WAX.ContractName.M
      );

      this._fuelPricesInformation = rows.reduce((acc, row) => {
        const [precision, productType] = row.symbol.split(",");
        const [price, currency] = row.price.split(" ");
        acc[productType as WAX.MarketFuelType] = {
          price: parseFloat(price),
          currency: currency as WAX.MarketCurrencyTicker,
          precision: parseInt(precision),
        };
        return acc;
      }, {} as NonNullable<WaxContractMarketService["_fuelPricesInformation"]>);
    }

    console.log({ _fuelPricesInformation: this._fuelPricesInformation });

    return this._fuelPricesInformation;
  }

  private async getProductPrice(productType: WAX.MarketFuelType) {
    const pricesInformation = await this.greateFuelPricesInformation();
    return pricesInformation[productType];
  }

  public calculateUpgradeSlotPrice(upgradeType: WAX.MarketTrainUpgradeType, slotIndex: number) {
    const { basePrice, tierMultiplier } = this._upgradePricingInformation![upgradeType];
    return 0.0001 * basePrice * (1.0 + slotIndex * tierMultiplier * 0.01);
  }

  /**
   * @param fuelType
   * @param productAmount
   */
  async purchaseFuel(fuelType: WAX.MarketFuelType, productAmount: number) {
    const {
      price: productPricePerUnit,
      currency: productPriceCurrency,
      precision: productPricePrecision,
    } = await this.getProductPrice(fuelType);

    const precisionMultiplier = 10 ** productPricePrecision;
    const productAmountWithPrecision = Math.floor(productAmount * precisionMultiplier);

    const memo = [productPricePrecision, fuelType, productAmountWithPrecision, this.currentCenturyName].join("|");

    const paymentSum = ((productPricePerUnit * productAmountWithPrecision) / precisionMultiplier).toFixed(
      productPricePrecision
    );
    const paymentString = `${paymentSum} ${productPriceCurrency}`;

    const userAccountName = await this.user.getAccountName();
    await this.actions.performActionTransaction(
      "transfer",
      {
        from: userAccountName,
        to: WAX.ContractName.M,
        quantity: paymentString,
        memo: memo,
      },
      WAX.ContractName.Toc
    );
  }

  async purchaseUpgrade(trainName: WAX.TrainName, upgradeType: WAX.MarketTrainUpgradeType, slotNumber: number) {
    await this.greateUpgradePricesInformation();

    const cost = this.calculateUpgradeSlotPrice(upgradeType, slotNumber - 1);
    const productPriceCurrency = `TOCIUM`;

    const memo = [`UPGRADE`, upgradeType, slotNumber, trainName].join("|");

    const paymentSum = cost.toFixed(4);
    const paymentString = `${paymentSum} ${productPriceCurrency}`;

    const userAccountName = await this.user.getAccountName();
    await this.actions.performActionTransaction(
      "transfer",
      {
        from: userAccountName,
        to: WAX.ContractName.M,
        quantity: paymentString,
        memo: memo,
      },
      WAX.ContractName.Toc
    );
  }

  async getCompositionUpgradesInfo() {
    const rows = await this.tables.loadRows<WAX.MarketCompositionUpgradesInfo>(
      "compositions",
      {
        scope: "m.century",
        limit: 1000,
      },
      WAX.ContractName.M
    );

    return rows;

    // return rows.reduce((acc, row) => {
    //   /**
    //    * Get only the first input's tamplate id (it's going to be arrays of one element for now anyway)
    //    * and use that as keys for our new data, for easy reference.
    //    */
    //   const [input] = row.inputs;
    //   acc[input] = row;
    //   return acc;
    // }, {} as Record<WAX.AssetTemplateId, WAX.MarketCompositionUpgradesInfo>);
  }
}
