import type * as WAX from "@sdk-integration/contracts";

export class RailRunEntity {
  constructor(public readonly waxData: WAX.RailRunData) {}

  get trainName(): WAX.TrainName {
    return this.waxData.train;
  }

  get origin(): WAX.StationAssetId {
    return this.waxData.depart_station;
  }

  get destination(): WAX.StationAssetId {
    return this.waxData.arrive_station;
  }

  get secondsLeft(): number {
    const timeNow = currentDateTime.totalSeconds;
    const timeEnd = this.waxData.run_complete;
    return Math.max(0, Math.ceil(timeEnd - timeNow));
  }

  get isReadyToClaim(): boolean {
    return this.secondsLeft <= 0;
  }
}

class CurrentDateTime {
  public totalSeconds = 0;
  public deltaSeconds = 0;

  constructor() {
    this.onEnterFrame();
  }

  #onEnterFrame() {
    const now = new Date().getTime() * 0.001;
    this.deltaSeconds = now - this.totalSeconds;
    this.totalSeconds = now;
    requestAnimationFrame(this.onEnterFrame);
  }

  private readonly onEnterFrame = this.#onEnterFrame.bind(this);
}

const currentDateTime = new CurrentDateTime();
