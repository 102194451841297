import { Texture } from "@pixi/core";
import { makeURLsFromIPFSHash } from "./makeURLsFromIPFSHash";

export async function getTextureFromIPFSHash(ipfsHash: string) {
  const urls = makeURLsFromIPFSHash(ipfsHash);
  for (const url of urls) {
    try {
      const texture = await Texture.fromURL(url);
      if (texture) {
        return texture;
      }
    } catch (e) {
      continue;
    }
  }
  return null;
}
