import { GameSingletons } from "@game/app/GameSingletons";
import { assignOnEnterFrameFunction } from "@game/asorted/assignOnEnterFrameFunction";
import { FontFamily } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { Point } from "@pixi/math";
import { Text } from "@pixi/text";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";

export function createRadioDisplayFactory() {
  let radioDisplayLayer: Container | null = null;

  function createRadioDisplay() {
    const { assets, main, simpleFactory, viewSize, app } = GameSingletons.getGameContext();
    const radioContainer = new Container();
    const musicText = main.mainMusic.getCurrentTitle().toUpperCase();
    const musicTitle = new Text(musicText, {
      fontFamily: FontFamily.Default,
      fontSize: 18,
      fill: 0xffffff,
    });
    const musicNoteIcon = simpleFactory.createSprite(assets.getTexture("ui-radio/music.png"));
    const skipIcon = simpleFactory.createSprite(assets.getTexture("ui-radio/skip.png"));
    const screenAnchor = new Point(0.5, 1.0);
    const screenMargin = new Point(10, 40);

    const updatePositionAndScale = () => {
      radioContainer.position.set(
        screenMargin.x + (viewSize.width - screenMargin.x - screenMargin.x) * screenAnchor.x,
        screenMargin.y + (viewSize.height - screenMargin.y - screenMargin.y) * screenAnchor.y
      );

      const scale = Math.min(1, viewSize.vmin / 720);
      radioContainer.scale.set(scale);

      radioContainer.visible = !main.hud.reasonsToHideRadio.hasAny();
    };

    musicNoteIcon.scale.set(0.2);
    skipIcon.scale.set(0.2);

    musicTitle.alpha = 0.35;
    musicNoteIcon.alpha = 0.35;
    skipIcon.alpha = 0.35;

    musicNoteIcon.anchor.set(0.5);
    skipIcon.anchor.set(0.5);
    musicTitle.anchor.set(0.5);

    musicTitle.position.copyFrom(screenAnchor);
    musicNoteIcon.position.set(musicTitle.x - musicTitle.width / 2 - 30, musicTitle.y);
    skipIcon.position.set(musicTitle.x + (musicTitle.width / 2 + 30), musicTitle.y);

    radioContainer.addChild(musicNoteIcon, musicTitle, skipIcon);

    function updateRadioPosition({ width, height }: { width: number; height: number }) {
      musicTitle.position.copyFrom(screenAnchor);
      musicNoteIcon.position.set(musicTitle.x - musicTitle.width / 2 - 30, musicTitle.y);
      skipIcon.position.set(musicTitle.x + (musicTitle.width / 2 + 30), musicTitle.y);
    }

    main.mainMusic.events.on({
      trackChanged: trackInfo => {
        musicTitle.text = trackInfo.title.toUpperCase();
        updateRadioPosition(app.screen);
      },
    });

    buttonizeDisplayObject(skipIcon, () => main.mainMusic.playNextTrack());

    assignOnEnterFrameFunction(radioContainer, updatePositionAndScale);

    updatePositionAndScale();

    return radioContainer;
  }

  return {
    show() {
      if (!radioDisplayLayer) {
        const { stageContainers } = GameSingletons.getGameContext();
        radioDisplayLayer = createRadioDisplay();
        stageContainers._hud.addChild(radioDisplayLayer);
      }
    },
    hide() {
      radioDisplayLayer?.destroy();
      radioDisplayLayer = null;
    },
  };
}
