import { PotentialRailRunStats } from "@game/data/entities/PotentialRailRunStats";
import { EventBus, EventBusKey } from "@sdk/core/EventBus";
import { StationPopup } from "./bases/StationPopup";
import { StationPopupBackgroundType } from "./components/StationPopupBackgroundType";
import { PopupPlacementFunction } from "./util/PopupPlacementFunction";

type StationInstantTransmissionDestinationPopupEvents = EventBus<
  EventBusKey<StationPopup["events"]> & {
    onClick_Dispatch: () => void;
  }
>;

const COST_PER_NODE = 3;

export class StationInstantTransmissionDestinationPopup extends StationPopup {
  public readonly placementFunc: PopupPlacementFunction = PopupPlacementFunction.sidewaysFar;

  public declare readonly events: StationInstantTransmissionDestinationPopupEvents;

  public potentialRunStats: PotentialRailRunStats | null = null;

  async fillContent_Default() {
    const { station } = this;
    const scale = 1.2;

    if (!station) {
      throw new Error("StationDashboardPopup: station is not set");
    }

    const { assets, main, simpleFactory } = this.context;

    this.setPadBackground(StationPopupBackgroundType.IDLE_TRAIN_CLICK);

    {
      const floatyText = `Destination`;
      const floaty = this.componentFactory.createFloatyTitle(floatyText, this.width*scale);
      this.addChild(floaty);
    }

    {
      const titleText = station.name;
      const title = this.componentFactory.createTitle(titleText, this.width);
      this.addChild(title);
    }

    const NODES = main.instantTransmission.pathStationIds.length;
    const COST = NODES * COST_PER_NODE;

    try {
      const { train, trainStation: departureStation } = main.faq.getSelectedTrainAndStation();
      const destinationStation = station;
      this.potentialRunStats = new PotentialRailRunStats(train, departureStation, destinationStation);

      if (this.potentialRunStats.isBacktracking) {
        this.componentFactory.addWarning_BackTracking();
      }

      {
        const innerTitleString = `Transport Details`;
        const innerTitle = this.componentFactory.createLabel(innerTitleString, this.width);
        innerTitle.anchor.set(0.5);
        innerTitle.style.fontSize = 16;
        innerTitle.style.align = "center";
        innerTitle.position.set(this.centerX, 200*scale);
        this.addChild(innerTitle);

        const ampSprite = simpleFactory.createSprite("ui-popups/instant-transmission/cv.png");
        ampSprite.name = "ampSprite";
        ampSprite.anchor.set(0.5);
        ampSprite.scale.set(0.6);
        this.addChild(ampSprite);

        const ampDetailsString = `COST:\n${COST} AMP`;
        const ampDetails = this.componentFactory.createLabel(ampDetailsString, this.width);
        ampDetails.name = "ampDetails";
        ampDetails.style.fontSize = 14;
        ampDetails.position.set(this.centerX, 190*scale);
        this.addChild(ampDetails);

        const pathSprite = simpleFactory.createSprite("ui-popups/instant-transmission/stations-impacted-image.png");
        pathSprite.name = "pathSprite";
        pathSprite.anchor.set(0.5);
        pathSprite.scale.set(0.6);
        this.addChild(pathSprite);

        const pathDetailsString = `${NODES} STATIONS\nRECEIVE 1 AMP\n& 100 TOCIUM`;
        const pathDetails = this.componentFactory.createLabel(pathDetailsString, this.width);
        pathDetails.name = "pathDetails";
        pathDetails.style.fontSize = 12;
        pathDetails.position.set(this.centerX, 190*scale);
        this.addChild(pathDetails);

        ampSprite.position.set(85*scale, 259*scale);
        ampDetails.position.set(150*scale, 236*scale);

        pathSprite.position.set(85*scale, 319*scale);
        pathDetails.position.set(150*scale, 290*scale);
      }

      {
        try {
          const potentialRunStatsText = "Owned by: " + station.ownerName;
          const potentialRunStatsRibbon = this.componentFactory.createInfoRibbon(
            potentialRunStatsText,
            `ui-popups/bg-lvl1-btm-info.png`
          );
          potentialRunStatsRibbon.position.set(this.centerX, 198);
          this.addChild(potentialRunStatsRibbon);
        } catch (e) {
          console.error(e);
        }
      }

      //// BUTTON ////

      {
        const buttonText = `Instant Transmission`;
        const button = this.componentFactory.createBottomButton("fill", buttonText,75);
        button.scale.set(.5);
        button.setEnabled(!train.getInvalidReason());
        button.onClick = () => this.events.emit("onClick_Dispatch");
        this.onDestroy(() => button.removeAllListeners());
        this.addChild(button);
      }
    } catch (e) {
      console.error(e);
    }

    //// X Close Button
    this.componentFactory.addXCloseButton();

    await this.fadeChildrenIn();
  }
}
