import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { CullingArea } from "@game/world/core/cullingArea";
import { AdvancedRope } from "@game/world/visuals/AdvancedRope";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { RailwayTrackVisual } from "./RailwayTrackVisual";

export function makeRailwayTracks(cullingArea: CullingArea) {
  const context = GameSingletons.getGameContext();
  const { mapData } = context;

  const tracksContainer = new EnchantedContainer();
  const tracksLayers: AdvancedRope[] = [];
  const tracks: RailwayTrackVisual[] = [];

  const tweeener = new TemporaryTweeener(tracksContainer);

  for (const railPath of mapData.railPaths) {
    const track = new RailwayTrackVisual(railPath, tweeener);
    tracksLayers.push(...track.layers);
    tracks.push(track);
  }

  for (const track of tracksLayers) {
    track.visible = false;
  }

  const updateTracks = () => {
    if (tracksContainer.worldAlpha > 0) {
      for (const track of tracks) {
        const isInView = cullingArea.isInView(track);
        for (const layer of track.layers) {
          layer.visible = isInView;
        }
      }
    }
  };
  tracksContainer.onEnterFrame.add(updateTracks);

  // const interaction: InteractionManager = context.app.renderer.plugins.interaction;
  // const globalMousePosition = interaction.mouse.global;
  // const updateInteraction = () => {
  //   const mousePosition = tracksContainer.toLocal(globalMousePosition);
  //   if (tracksContainer.worldAlpha > 0) {
  //     for (const track of tracks) {
  //       if (track.topLayer.visible) {
  //         track.pointerOverEnabled = track.bounds.contains(mousePosition.x, mousePosition.y);
  //       }
  //     }
  //   }
  // };
  // tracksContainer.onEnterFrame.add(updateInteraction);

  tracksContainer.addChild(...tracksLayers);
  tracksContainer.sortChildren();

  return Object.assign(tracksContainer, { tracks, tracksLayers });
}
