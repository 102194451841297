import { MockEmporiumDataService } from "@debug/mock-data/MockEmporiumDataService";
import { lipsum } from "@debug/utils/lipsum";
import { GameConfigurationData } from "@game/data/GameConfigurationData";
import { MapDataLoader } from "@game/data/loadMapData";
import { UserDataController } from "@game/data/UserDataController";
import { InventoryDataService } from "@game/ui/railroader-dash/panels/inventory/InventoryDataService";
import { SocialProfileDataService } from "@game/ui/social/SocialProfileDataService";
import { CenturyTrainPartsDataService } from "@game/ui/windows/market/pages/ct-parts/CTPartsDataService";
import { CenturyTrainPartEntity } from "@game/ui/windows/market/pages/ct-parts/data/CenturyTrainPartEntity";
import { CenturyTrainPartTokenSymbol } from "@game/ui/windows/market/pages/ct-parts/data/models";
import { EmporiumDataService } from "@game/ui/windows/market/pages/emporium/EmporiumDataService";
import { WaxAssetsService } from "@sdk-integration/contracts/WaxAssetsService";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { EthereumNFTDataService } from "@sdk-integration/ethereum/EthereumNFTDataService";
import { FirebaseServicesWrapper } from "@sdk-integration/firebase/FirebaseServicesWrapper";
import { delay } from "@sdk/utils/promises";
import { randomBetween } from "@sdk/utils/random";
import { getMockEthereumNFTs } from "./MockEthereumNFTData";
import { MockGameConfiguration } from "./MockGameConfiguration";
import { ContractStationsData_RR, ContractStationsData_S } from "./MockMapData";
import { MockProfileDataService } from "./MockProfileDataService";
import { MockStationsAssetsData } from "./MockStationAssetsData";

function later<T>(result: T | Promise<T>) {
  result = result instanceof Promise ? result : Promise.resolve(result);
  return async () => {
    await delay(0.5);
    return result;
  };
}

function laterfn<T extends (...args: any[]) => Promise<any>>(this: any, fn: T) {
  return async () => {
    await delay(0.5);
    return fn.call(this);
  };
}

export function overrideDataServices() {
  {
    const proto = InventoryDataService.prototype;
    proto.getRedeemableAssets = () => [
      { assetId: "0" as any, name: "test", textureId: "ui-railroader-dashboard/inventory/Redeemables/1-cv.png" },
      { assetId: "0" as any, name: "test", textureId: "ui-railroader-dashboard/inventory/Redeemables/100-fuel.png" },
      { assetId: "0" as any, name: "test", textureId: "ui-railroader-dashboard/inventory/Redeemables/2-cv.png" },
      { assetId: "0" as any, name: "test", textureId: "ui-railroader-dashboard/inventory/Redeemables/1-cv.png" },
    ];
  }

  {
    const proto = EmporiumDataService.prototype;
    proto.getPurchasableItems = later([...MockEmporiumDataService.getPurchasableItems(100)]);
    proto.getMyListedItems = later([...MockEmporiumDataService.getPurchasableItems(10)]);
    proto.getMyInventoryParts = later([...MockEmporiumDataService.getPurchasableItems(100, 0)]);
    proto.getLowestListingPrice = later(MockEmporiumDataService.getLowestListingPrice());
  }

  {
    const proto = CenturyTrainPartsDataService.prototype;
    proto.getMyOwnedPartsList = later(
      [...MockEmporiumDataService.getPurchasableItems(100, 0)].map(
        o =>
          new CenturyTrainPartEntity(o.ctPartData, {
            inventoryCount: ~~randomBetween(0, 25),
            forceActive: true,
          })
      )
    );
    proto.getMyDiscoveredPartsList = later(
      [...MockEmporiumDataService.getPurchasableItems(100, 0)].map(
        o =>
          new CenturyTrainPartEntity(o.ctPartData, {
            purchasableCount: 1,
            discountPercent: 40,
            forceActive: true,
          })
      )
    );
  }

  {
    const proto = WaxContractsGateway.prototype;
    proto.getRegisteredRailroaderData = async function () {
      return {
        railroader: "thechoephix1" as any,
        starting_station: "1099521667452" as any,
        terms_accepted: 1,
        terms_version: "1.1.0",
        credits: "640.0000 TOCIUM",
        t_regions: [
          {
            key: "modern",
            value: 2,
          },
        ],
        c_regions: [
          {
            key: "modern",
            value: 2,
          },
        ],
      };
    };
    proto.getTermsAndConditionsState = async function () {
      return [false, "1.1.0", null];
    };
    proto.getRailroaderTrains = async function () {
      return [
        {
          train: "albatrox",
          locomotives: ["1099519961438"],
          conductors: ["1099528480428"],
          load: [
            {
              railcar_asset_id: "1099519961441",
              load_ids: ["1099519961459", "1099519961457", "1099519961447", "1099519961442"],
            },
            {
              railcar_asset_id: "1099520773553",
              load_ids: ["1099529526490", "1099529526489"],
            },
            {
              railcar_asset_id: "1099520773549",
              load_ids: [
                "1099519961443",
                "1099519961444",
                "1099519961445",
                "1099519961446",
                "1099519961448",
                "1099519961449",
                "1099519961450",
                "1099519961451",
                "1099519961452",
              ],
            },
            {
              railcar_asset_id: "1099525251577",
              load_ids: ["1099529415727", "1099525251588", "1099525251584"],
            },
            {
              railcar_asset_id: "1099529658422",
              load_ids: ["1099529526440", "1099529526435"],
            },
            {
              railcar_asset_id: "1099529526370",
              load_ids: [],
            },
          ],
          weight: 474,
          locked: 0,
          last_run_time: 1650565877,
          last_run_tx: "4f4e578545331885b97ed383154d4b16aaecff11f418390f65ca5e8492ca7fe0",
          current_station: "1099521667557",
          previous_station: "1099521667576",
          century: "modern",
          tampered: 0,
        } as any,
      ];
    };
  }

  {
    GameConfigurationData.prototype.load = async function () {
      Object.assign(this, MockGameConfiguration);

      this.vars = {
        backtrack_m: 200,
        fuel_b: 18750,
        fuel_m: 18750,
        hp_m: 1000,
        time_b: 60,
        time_m: 100,
        toc_b: 1,
        toc_m: 19,
        max_cond: 500000000,
        repair_price: 10,
      };

      const tokenSymbols = Object.keys(this.ctPartsData) as CenturyTrainPartTokenSymbol[];
      for (const tokenSymbol of tokenSymbols) {
        const cfg = this.ctPartsData[tokenSymbol];
        this.marketItemPrices.set(tokenSymbol, +cfg.cost);
        this.ctPartsFusionImpacts.set(tokenSymbol, {
          distance_boost: +(cfg.distance || 0),
          haul_boost: +(cfg.haulingPower || 0),
          speed_boost: +(cfg.speed || 0),
          luck_boost: +(cfg.luck || 0),
        });
      }
    };
  }

  {
    const proto = FirebaseServicesWrapper.prototype;
    // proto.load = async function () {
    //   Object.assign(this, MockGameConfiguration);
    // };
  }

  {
    const proto = SocialProfileDataService.prototype;
    //proto.getAchievements = later(MockProfileDataService.getRandomAchievements());
    proto.getHighlightedMedals = laterfn(MockProfileDataService.getHighlightedMedals);
    proto.getProfileInfoData = laterfn(MockProfileDataService.getProfileInfoData);
    proto.getMiscProfilePreferences = laterfn(MockProfileDataService.getMiscProfilePreferences);
    proto.getMyAvatarBadgePreferences = laterfn(MockProfileDataService.getMyAvatarBadgePreferences);
    proto.getAvatarBadgePreferences = laterfn(MockProfileDataService.getAvatarBadgePreferences);
    proto.getEthereumNFTs = later(getMockEthereumNFTs());
  }

  {
    const proto = EthereumNFTDataService.prototype;
    proto.getSavedEthereumAddress = later("0xd095e0f8c72e22319846b643c4bac0cac1f67006");
  }

  {
    WaxAssetsService.prototype.getAllStationAssets = async function () {
      return MockStationsAssetsData as any;
    };

    // @ts-ignore
    MapDataLoader.prototype.loadStationsTableData = async function () {
      return ContractStationsData_RR as any;
    };

    // @ts-ignore
    MapDataLoader.prototype.leadStationsTableData_SCentury = async function () {
      return ContractStationsData_S as any;
    };
  }

  {
    const proto = UserDataController.prototype;
    proto.updateAll = async function () {
      const userData = this.userData;
      userData.name = lipsum.userName() as any;
      userData.tocium = randomBetween(0, 1_000_000_000);
      userData.anomaticParticles = randomBetween(0, 1_000);
      userData.fuel.coal = randomBetween(0, 1_000_000_000);
      userData.fuel.diesel = randomBetween(0, 1_000_000_000);
    };
  }
}
