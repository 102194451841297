import {GameSingletons} from "@game/app/GameSingletons";
import {TemporaryTweeener} from "@sdk/pixi/animations/TemporaryTweener";
import {Container, DisplayObject} from "@pixi/display";
import {SimpleObjectsFactory} from "@game/app/services/SimpleObjectsFactory";
import {Rectangle} from "@pixi/math";
import {Sprite} from "@pixi/sprite";
import {Text} from "@pixi/text";
import {buttonizeDisplayObject} from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import {flickeringLight} from '../effects';
import {createItemContainer, addPairedPointerdownListeners, scaleItem} from '@game/ui/buildings/bldgUtility';
import {InteractionEvent} from "@pixi/interaction";
import {wiggle} from "../actions";
import {IPointData} from "@pixi/math";
import {Texture} from "@pixi/core";
import {FontFamily} from "@game/constants/FontFamily";
import {EnchantedContainer} from "@game/core/enchanted-classes";
import {Conductor, conductorData} from "../data/conductorData"
import {ConductorStoryScrollbox} from "../data/storyClass";
import { bldgHouse } from "../bldgHouse";

// for createItemContainer setting the intitial position to one object, like bg, will make all the screenAnchor properties relative to that (bg).

export class TalesModal extends EnchantedContainer {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private conductorCards : Map < string, Sprite >;
  private journalImage : Map < string,Sprite >;
  private journalCornerImage : Map < string,Sprite >;
  private paperImage : Map < string,Sprite >;
  private conductorTeasers : Map < string, Text >;
  private conductorTitles : Map < string, Text >;
  private conductorStories : Map < string,Text >;
  private headerImage : Sprite;
  private teaserContainer : any;
  private cardContainer : any;
  public bgContainer : any; 
  public storyContainer : any;
  public gridView : Container;
  public bg : Sprite;
  public storyOpen: boolean = false;
  public buildingName: string;


  constructor(public id : number, parent : bldgHouse) {
    super();

    this.id = id - 2;
    this.conductorCards = new Map();
    this.journalImage = new Map();
    this.journalCornerImage = new Map();
    this.paperImage = new Map();
    this.conductorTeasers = new Map();
    this.conductorStories = new Map();
    this.conductorTitles = new Map();
    this.gridView = new Container;
    this.tweeener = new TemporaryTweeener(this);
    this.buildingName = parent.name;
   

    // Empty Container behind modal, so no clickthrough
    const fullScreenContainer = this.factory.createSprite(Texture.EMPTY, {
      x: 0,
      y: 0
    });
    fullScreenContainer.width = this.context.viewSize.width;;
    fullScreenContainer.height = this.context.viewSize.height;;
    fullScreenContainer.interactive = true;
    this.addChild(fullScreenContainer);

    const {viewSize} = parent.context;
    //Back to HUD Back button
    const backButton = this.factory.createSprite("arrow-change-location.png");
    backButton.interactive = true;
    backButton.buttonMode = true;
    backButton.on("pointerdown", () => {
      this.context.sfx.play("clickTiny");
    });
   
    const scaledContainer = scaleItem();
    scaledContainer.position.set(25,parent.context.viewSize.height/2)
    scaledContainer.addChild(backButton);
    this.addChild(scaledContainer);
   


    // Buttonize the back button
    buttonizeDisplayObject(backButton, {
      onTrigger: () => {
        if (this.storyOpen===false){
        this.destroy();
        this.context.input.emit("restoreHUD", this.buildingName);


        }else{
          this.hideTitle();
            this.removeChild(this.storyContainer);
            this.addChild(this.gridView);
            this.storyOpen=false;
        }
      }
    });
    // bg
    this.bg = this.factory.createSprite("modal-bg.png");
    this.bg.scale.set(.75);
    // Create a container for the item
    this.bgContainer = createItemContainer({
      x: .65,
      y: .5
    }, {
      x: 0,
      y: 50
    }, this.bg.width, this.bg.height);
    this.bgContainer.addChild(this.bg);
    this.bgContainer.updateIndicatorPositions(this.context.viewSize);
    this.addChild(this.bgContainer);


    // Header Image
    this.headerImage = this.factory.createSprite("h-default.png");
    this.headerImage.scale.set(.75);
    this.addChild(this.headerImage);
    this.bgContainer.addChild(this.headerImage);

    // Tales Logo
    const logo = this.factory.createSprite("tales-logo.png");
    logo.scale.set(.75);

    const logoContainer = createItemContainer({
      x: .2,
      y: .15
    }, {
      x: 0,
      y: 50
    }, logo.width, logo.height);
    logoContainer.updateIndicatorPositions(this.context.viewSize);
    logoContainer.addChild(logo);
    this.addChild(logoContainer);

    // Conductor Card Container
    this.cardContainer = createItemContainer({
      x: .225,
      y: .5
    }, {
      x: 0,
      y: 50
    }, 350,350);
    this.cardContainer.updateIndicatorPositions(this.context.viewSize);
    // Add the teaser container to the TalesModal object
    this.addChild(this.cardContainer);

     // Story Teaser Container
     this.teaserContainer = createItemContainer({
      x: .225,
      y: .875
    }, {
      x: 0,
      y: 50
    }, 350,200);
    this.teaserContainer.updateIndicatorPositions(this.context.viewSize);
    // Add the teaser container to the TalesModal object
    this.addChild(this.teaserContainer);

    // Volume Header
    let whichVol: string = "";

    const vol = ['ONE', 'TWO', 'THREE'];

    if (this.id === 0) {
      whichVol = vol[this.id];
    } else if (this.id === 1) {
      whichVol = vol[this.id];
    } else if (this.id === 2) {
      whichVol = vol[this.id];
    }

    const volHeader = this.factory.createText("VOLUME " + whichVol, {
      fontSize: 46,
      fontFamily: FontFamily.Default,
      dropShadow: true
    }, {
      x: this.bg.x,
      y: this.bg.y
    });


    // Volume Header Container
    const volContainer = createItemContainer({
      x: .65,
      y: .15
    }, {
      x: 0,
      y: 50
    }, volHeader.width, volHeader.height);
    volContainer.addChild(volHeader);
    volContainer.updateIndicatorPositions(this.context.viewSize);
    this.addChild(volContainer);

    // StoryContainer
    this.storyContainer = createItemContainer({
      x: .65,
      y: .5
    }, {
      x: 0,
      y: 50
    }, this.bg.width, this.bg.height);
    this.storyContainer.updateIndicatorPositions(this.context.viewSize);


    this.gridView.addChild(volContainer);
    this.addChild(this.gridView);

    this.showJournalGrid();

  }


  private getConductorData(conductorNameOrIndex : string | number, volume : number): Conductor {
    if (typeof conductorNameOrIndex === "string") {
      return conductorData.find(conductor => conductor.name === conductorNameOrIndex)!;
    } else {
      return conductorData[conductorNameOrIndex];
    }
  }

  private showJournalGrid() { // Calculate the dimensions of each journal image


    const gridWidth = this.bg.width * .75;
    const gridHeight = this.bg.height * .75;
    const journalWidth = gridWidth / 3;
    const journalHeight = gridHeight / 3.75;

    // Create the grid container
    const grid = new Container();
    grid.scale.set(.75);

    // Add the journal images to the grid and position them
    let index = 0;
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (index >= conductorData.length) {
          break;
        }
        const conductor = conductorData[index];
        const journal = this.factory.createSprite(conductor.journalImage);

        //Check if theres a story for the volume
        if(conductor.stories[this.id]){
        
        journal.interactive = true;
        journal.buttonMode = true;
        
        journal.on("pointerover", (event : InteractionEvent) => {
          this.tweeener.to(journal, {
            pixi: {
              scale: .4
            },
            duration: 0.25,
            ease: "back.out",
            overwrite: true
          });
          this.showConductorTeaser(conductor.name, this.id);
        });

        journal.on("pointerout", (event : InteractionEvent) => {

          this.hideConductorTeaser();
          this.tweeener.to(journal, {
            pixi: {
              scale: .35
            },
            duration: 0.4,
            ease: "back.out",
            overwrite: true
          });
        });

        journal.on("pointerdown", () => {
          this.showConductorStory(conductor.name, this.id);
          this.context.sfx.play("bookOpen");
        });

      }else{
        journal.tint = 0x666666;
      }

        this.journalImage.set(conductor.name, journal);
        
        journal.scale.set(.35);
        // Calculate the x and y coordinates for the current journal image
        const x = j * journalWidth;
        const y = i * journalHeight;

        // Set the position of the journal image
        journal.position.set(x, y);

        grid.addChild(journal);
        index++;
      }

    }

    const gridContainer = createItemContainer({
      x: .65,
      y: .6
    }, {
      x: 0,
      y: 50
    }, grid.width, grid.height);
    gridContainer.addChild(grid);
    gridContainer.updateIndicatorPositions(this.context.viewSize);
    this.gridView.addChild(gridContainer);
  }


  private showConductorTeaser(conductorName : string, volumeNumber : number) {
    const conductor = this.getConductorData(conductorName, volumeNumber);
    if (!this.conductorTeasers.has(conductorName)) { // Check if the conductor has a story in the specified volume
      if (conductor.stories[volumeNumber]) { // Get the teaser for the story in the specified volume
        const teaser = conductor.stories[volumeNumber].teaser;
        // Create a text object to display the teaser
        const teaserText = this.factory.createText(teaser, {
          fontSize: 18,
          fontFamily: FontFamily.Default
        }, {
          x: 0,
          y: 0
        });
        teaserText.visible = false;
        this.conductorTeasers.set(conductorName, teaserText);
        this.addChild(teaserText);
      }

      // Card Image
      const cardTexture = this.factory.createSprite(conductor.playingCard);
      cardTexture.scale.set(.6);
      cardTexture.visible = false;
      this.conductorCards.set(conductorName, cardTexture);
      this.addChild(cardTexture);
    }
    // Get the teaser text object
    const teaserText = this.conductorTeasers.get(conductorName);
    if (teaserText) {
      teaserText.visible = true;
      // Add the teaser text to the teaser container
      this.teaserContainer.addChild(teaserText);
      this.teaserContainer.updateIndicatorPositions(this.context.viewSize);
      this.addChild(this.teaserContainer);

    }

    // Get the card object
    const cardTexture = this.conductorCards.get(conductorName);
    if (cardTexture) {
      cardTexture.visible = true;
      // Add the teaser text to the teaser container
      this.cardContainer.addChild(cardTexture);
      this.cardContainer.updateIndicatorPositions(this.context.viewSize);
      this.addChild(this.cardContainer);

    }
  }


  private hideConductorTeaser() {
    this.conductorTeasers.forEach(teaser => {
      teaser.visible = false;
    });
    this.conductorCards.forEach(playingCard => {
      playingCard.visible = false;
    });
  }
  private hideTitle() {
    this.conductorTitles.forEach(title => {
      title.visible = false;
    });
    this.journalCornerImage.forEach(title => {
      title.visible = false;
    });
    this.paperImage.forEach(title => {
      title.visible = false;
    });
  }

  private showConductorStory(conductorName : string, volumeNumber : number) {
    this.storyOpen = true;
    const bgWidth = this.bg.width;
    const bgHeight = this.bg.height;
    const conductor = this.getConductorData(conductorName, volumeNumber);
    const storyText = conductor.stories[volumeNumber].text;   // Get Story
    const storyImage = this.factory.createSprite(conductor.headerImage); // Header Image
    const titleConcat = conductorName + " #" + ( this.id + 1); // Create the title string
    const conductorStoryScrollBox = new ConductorStoryScrollbox(conductor.name, this.id, storyText); // Create the ConductorStoryScrollBox object

  

    //Add Stuff
    this.addChild(this.storyContainer);

    // Remove the grid of journals
    this.removeChild(this.gridView);

    //Modifications
      //Story Image Header
      storyImage.scale.set(.75);
      storyImage.visible = true;

// Title
if (!this.conductorTitles.has(conductorName) && !this.journalCornerImage.has(conductorName) && !this.paperImage.has(conductorName)) { 
  const paper = this.factory.createSprite(conductor.paperImage);
  paper.visible = false;

  const journal = this.factory.createSprite(conductor.journalImage);
      journal.scale.set(.5);
      const jWidth = journal.width*.5;
      const jHeight = journal.height*.55;
      journal.position.set(bgWidth - (jWidth*1.75),jHeight/6)
      journal.visible=false;
  const title = this.factory.createText(titleConcat, {
    fontSize: 20,
    fontFamily: FontFamily.Default,
    dropShadow: true
  }, {
    x: (bgWidth/6),
    y: (bgHeight/1.618/1.618/1.618)+16
  });
  title.visible = false;
  this.paperImage.set(conductorName, paper);
  this.journalCornerImage.set(conductorName, journal);
  this.conductorTitles.set(conductorName, title);
}  
//check if its the right title
const paper = this.paperImage.get(conductorName);
const title = this.conductorTitles.get(conductorName);
const journal = this.journalCornerImage.get(conductorName);
if (title && journal && paper){
  paper.visible = true;
  title.visible = true;
  journal.visible = true;

  //Initialize
  conductorStoryScrollBox.initialize();
  conductorStoryScrollBox.position.set(((bgWidth - 620) / 2), bgHeight * .4);

  this.storyContainer.addChild(storyImage,paper,conductorStoryScrollBox,title,journal);
}

    
   
    //For the Map
    const story = new Text(conductor.stories[volumeNumber].text, {
      fontSize: 24,
      fill: 0xffffff,
      align: "left"
    });

    this.conductorStories.set(conductorName, story);


  }


  async playShowAnimation(duration : number) {
    await Promise.all([
      this ?. playShowAnimation(1.5),
      /*   ... items.map(item => item.playShowAnimation()), */
    ]);
  }

  async playHideAnimation() {
    await Promise.all([
      this ?. playHideAnimation(),
    ]);
  }


};
