//// Primitive types

import { StakingAddonTier } from "@game/asorted/StakingType";

export type AccountName = string & { __AccountName: never };

export type TrainName = string & { __TrainName: never };

export type AssetId = string & { __AssetId: never };
export type AssetTemplateId = string & { __AssetTemplateID: never };
export type StationAssetId = AssetId & { __StationAssetId: never };
export type CardAssetId = AssetId & { __CardAssetId: never };

export type AssetRarity = string & { __AssetRarity: never };

export type TransactionHash = string & { __TransactionHash: never };

export type Timestamp = number & { __Timestamp: never };

export type SemanticVersion = `${number}.${number}.${number}`;

export type IntBool = 0 | 1;

export type integer = number & { __int: never };

//// Enum-ish types

export type CenturyName = "modern";

export type RegionName = string & { __RegionName: never };

export type CommodityType =
  | "pallet"
  | "crate"
  | "liquid"
  | "gas"
  | "ore"
  | "aggregate"
  | "grain"
  | "granule"
  | "perishable"
  | "oversized"
  | "building_materials"
  | "automobile"
  | "top_secret";

//// Data model types

export interface GameConfiguration {
  version: SemanticVersion;
  centuries: CenturyName[];
  locked: IntBool;
  terms_version: SemanticVersion;
  train_limit: integer;
  loco_limit: integer;
  cond_limit: integer;
  railcar_limit: integer;
  use_whitelist: IntBool;
}

export interface GameMathVars {
  backtrack_m: number;
  fuel_b: number;
  fuel_m: number;
  hp_m: number;
  time_b: number;
  time_m: number;
  toc_b: number;
  toc_m: number;
  max_cond: number;
  repair_price: number;
}

export interface RailroaderData {
  railroader: AccountName;
  starting_station: StationAssetId;
  t_regions: any;
  c_regions: any;
  terms_accepted: IntBool;
  terms_version: SemanticVersion;
}

export interface StationData_SCentury {
  rarity: string;
  asset_id: string;
  railyard_tier: StakingAddonTier | 0;
  lounge_tier: StakingAddonTier | 0;
  railyard_comm: number;
  lounge_comm: number;
  railyard_comm_vip: number;
  lounge_comm_vip: number;
}

export interface StationData_RRCentury {
  station_id: StationAssetId;
  connected_stations: StationLinkData[];
  type_rates: CommodityTypeRateData[];
  rarity: AssetRarity;
  region: RegionName;
  multiplier: integer;
}

export interface StationLinkData {
  station_id: StationAssetId;
  distance: integer;
}

export interface CommodityTypeRateData {
  type: CommodityType;
  multiplier: integer;
}

export interface TrainData {
  train: TrainName;
  locomotives: CardAssetId[];
  conductors: CardAssetId[];
  load: TrainLoadData[];
  weight: integer;
  condition?: integer;
  locked: IntBool;
  last_run_time: Timestamp;
  last_run_tx: TransactionHash;
  current_station: StationAssetId;
  previous_station: StationAssetId;
  century: CenturyName;
  tampered: IntBool;
}

export interface TrainLoadData {
  railcar_asset_id: CardAssetId;
  load_ids: CardAssetId[];
}

export interface TrainUpgradesData {
  train: TrainName;
  extra_rc_slot: integer;
  extra_loco_slot: integer;
  extra_cond_slot: integer;
  staked: integer;
  last_staked_time: integer;
  misc: any;
}

export interface RailRunData {
  train: TrainName;
  run_start: Timestamp;
  run_complete: Timestamp;
  depart_station: StationAssetId;
  arrive_station: StationAssetId;
}

//// Data model types (Century Market)

export enum MarketCurrencyTicker {
  Tocium = "TOCIUM",
  AMP = "AMP",
}

export type MonetaryAmountString = `${number}.${number} ${MarketCurrencyTicker}` & { __MonetaryAmountString: never };

export enum MarketFuelType {
  Coal = "COAL",
  Diesel = "DIESEL",
}

export enum MarketTrainUpgradeType {
  RailCar = "rc",
  Locomotive = "locomotive",
  Conductor = "conductor",
}

export interface MarketProductPricingData {
  symbol: `${number},${MarketFuelType}`;
  price: `${number} ${MarketCurrencyTicker}`;
}

export interface MarketRailCarUpgradesInfo {
  upgrade_type: string;
  base_price: number;
  tier_m: number;
  limit: number;
}

export interface MarketCompositionUpgradesInfo {
  target: AssetTemplateId;
  inputs: AssetTemplateId[];
  costs: MonetaryAmountString[];
}

export interface RailroaderSimpleAssets {
  id: number | string;
  author: "m.century";
  balance: `${number} ${string}`;
}

export interface RailroaderTociumAccount {
  balance: `${number} ${MarketCurrencyTicker}`;
}

//// Rewards & NPC Encounters

export enum RewardType {
  Tocium = "TOCIUM",
}

export type RewardString = MonetaryAmountString;
