// export class EntityToDisplayObjectMapper<TK, TV> {
//   private readonly _map: Map<TK, TV> = new Map();

//   constructor(
//     private readonly instantiateDisplayObject: (key: TK, index: number) => TV,
//     private readonly removeDisplayObject: (value: TV, key: TK, index: number) => void,
//   ) {
//   }

//   update(keys: TK[]) {
//     let instantiateIndex = 0;
//     for (const key of keys) {
//       if (!this._map.has(key)) {
//         const value = this.instantiateDisplayObject(key, instantiateIndex++);
//         this._map.set(key, value);
//       }
//     }

//     let removeIndex = 0;
//     for (const [key, value] of this._map) {
//       if (!keys.includes(key)) {
//         this.removeDisplayObject(value, key, removeIndex++);
//         this._map.delete(key);
//       }
//     }
//   }

//   clear() {
//     return this.update([]);
//   }
// }

export class EntityToDisplayObjectMapper<TK, TV, TO extends {} = never> {
  private readonly _map: Map<TK, TV> = new Map();

  constructor(
    private readonly instantiateDisplayObject: (key: TK, index: number, options: TO) => TV,
    private readonly removeDisplayObject: (value: TV, key: TK, index: number, options: TO) => void,
    private readonly defaultOptions?: TO
  ) {}

  update(keys: TK[], options?: Partial<TO>) {
    console.log({ keys, options });

    const fullOptions = Object.assign({}, this.defaultOptions || {}, (options as any) || {}) as TO;

    console.log({ keys, fullOptions });

    let instantiateIndex = 0;
    for (const key of keys) {
      if (!this._map.has(key)) {
        const value = this.instantiateDisplayObject(key, instantiateIndex++, fullOptions);
        this._map.set(key, value);
      }
    }

    let removeIndex = 0;
    for (const [key, value] of this._map) {
      if (!keys.includes(key)) {
        this.removeDisplayObject(value, key, removeIndex++, fullOptions);
        this._map.delete(key);
      }
    }
  }

  clear(options?: Partial<TO>) {
    return this.update([], options);
  }
}
