import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { ModPlate } from "./ModPlate";

export class ModList extends SafeScrollbox {
  constructor() {
    super({
      noTicker: true,
      boxWidth: 1500,
      boxHeight: 700,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });
  }

  initList(mods: ModPlate[]) {
    let startX = 0;
    let startY = 250;

    for (const [index, mod] of mods.entries()) {
      this.content.addChild(mod);
      mod.position.set(startX, startY);
      startX += 500;
      if ((index + 1) % 3 == 0) {
        startX = 0;
        startY += 800;
      }
    }
    this.update();
    this.content.addChild(this.addInvisibleBox(700 + 1));
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
