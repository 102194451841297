import { GameSingletons } from "@game/app/GameSingletons";
import { assignOnEnterFrameFunction } from "@game/asorted/assignOnEnterFrameFunction";
import { Container } from "@pixi/display";
import { Point } from "@pixi/math";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { EventBannersSlideData } from "../../railroader-dash/banner/EventBannersGalleryData";
import { populateUpdateTypeModal } from "./populateUpdatesTypeModal";

export function createUpdatesTypeModal(readMoreModalBoolean: boolean, data?: EventBannersSlideData) {
  const { simpleFactory, stageContainers, viewSize } = GameSingletons.getGameContext();
  const modalContainer = new Container();
  const modalBase = simpleFactory.createSprite("ui-updates/bg-isolated.png");
  const modalTopImage = simpleFactory.createSprite("ui-updates/image-isolated.png");
  const modalTopLabel = simpleFactory.createSprite("ui-updates/updates-title.png");
  const closeButton = simpleFactory.createSprite("ui-railroader-dashboard/staking/staked-sub-tab/btn-close.png");
  const screenAnchor = new Point(0.5, 0.5);
  //const modalConfetti = simpleFactory.createSprite("ui-updates/confetti.png");
  const backButton = simpleFactory.createSprite("ui-updates/back-arrow.png");
  const screenMargin = new Point(10, 10);

  const updatePositionAndScale = () => {
    modalContainer.position.set(
      screenMargin.x + (viewSize.width - screenMargin.x - screenMargin.x) * screenAnchor.x,
      screenMargin.y + (viewSize.height - screenMargin.y - screenMargin.y) * screenAnchor.y
    );

    const scale = Math.min(1, viewSize.vmin / 720);
    modalContainer.scale.set(scale);

    modalContainer.scale.set(scale * 1);
  };

  modalBase.scale.set(0.8);
  modalTopImage.scale.set(0.8);
  modalTopLabel.scale.set(0.8);

  modalBase.anchor.set(0.5);
  modalTopImage.anchor.set(0.5);
  modalTopLabel.anchor.set(0.5);
  closeButton.anchor.set(0.5);

  modalBase.position.copyFrom(screenAnchor);
  modalTopImage.position.set(modalBase.x - 5, modalBase.y - modalBase.height / 3 - 10);
  modalTopLabel.position.set(modalBase.x - 180, modalBase.y - modalBase.height / 3 - 10);
  closeButton.position.set(-338, -371);

  buttonizeDisplayObject(closeButton, () => {
    modalContainer.destroy();
  });

  modalContainer.addChild(modalBase, modalTopImage, closeButton);

  if (!readMoreModalBoolean) {
    modalContainer.addChild(modalTopLabel);
    populateUpdateTypeModal(modalContainer, false);
  } else {
    modalTopImage.tint = 0x707070;
    /* modalConfetti.scale.set(0.8);
    modalConfetti.anchor.set(0.5);
    modalConfetti.position.set(modalBase.x - 5, modalBase.y - modalBase.height / 3 + 35);*/
    backButton.anchor.set(0.5);
    backButton.position.set(-13, 331);

    buttonizeDisplayObject(backButton, () => {
      modalContainer.destroy();
      createUpdatesTypeModal(false);
    });

    if (!data) throw new Error("AirTable Data not found.");

    // modalContainer.addChild(modalConfetti);
    populateUpdateTypeModal(modalContainer, true, data);
    modalContainer.addChild(backButton);
  }

  stageContainers._hud.addChild(modalContainer);
  assignOnEnterFrameFunction(modalContainer, updatePositionAndScale);

  updatePositionAndScale();
}
