export type MiniGame = {
  name: string;
  gameId: number;
  bannerImage: string;
  screenShot: string;
  description: string;
  developer: string;
  playAction: string;
};

export const miniGameData: MiniGame[] = [
  {
    name: "Rail Yard Defender",
    gameId: 1,
    bannerImage: "bannerDefender",
    screenShot: "ssDefender",
    description: "Drones coming in hot! Use your Rail Yard's mounted turrets \nto blast them away. You may get lucky and have a drone miss\n one of your locomotives. Three locos down and it's and game over.",
    developer:"Oso Interactive, 2022",
    playAction: "https://train-of-the-century-minigames.web.app/rail-yard-defender"
  }, {
    name: "Survive the Century",
    gameId: 2,
    bannerImage: "bannerSurvive",
    screenShot: "ssSurvive",
    description: "Survive the Century is an action-packed mini game where you \ntake on the role of a choo choo train in outer space. Your mission \nis to blast your way through hordes of Thomases, Ottos,\nand Maureens. As you progress, you'll encounter power-ups that will \nhelp you in your quest to survive the century. ",
    developer:"Oso Interactive, 2022",
    playAction: "https://train-of-the-century-minigames.web.app/survive-the-century"
  }, {
    name: "Anomatic Vibes",
    gameId: 3,
    bannerImage: "bannerAnomatic",
    screenShot: "ssAnomatic",
    description: "Absorb smaller anomatic particles, dodge larger ones.\nSounds easy enough, but the Reasearcher has some\ntricks up his sleeve!",
    developer:"Oso Interactive, 2022",
    playAction: "https://train-of-the-century-minigames.web.app/anomatic-survival"
  },

]
