import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { WithFadingAnimation } from "@game/mixi/WithFadingAnimation";
import { Point } from "@pixi/math";
import { Text, TextStyle } from "@pixi/text";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { DropdownMenuButtonOptions } from "../../popups/components/DropdownMenu";
import { RailroaderDashPanelType } from "../../railroader-dash/panels/models";
import { createHUDElementContainer } from "./hud-utils";
import { openMainDropdownMenu } from "./openMainDropdownMenu";

const FadingText = WithFadingAnimation(Text);

export function createUserAccountIndicator() {
  const { sfx, contracts, music, input, viewSize } = GameSingletons.getGameContext();

  const container = createHUDElementContainer({ x: 1.0, y: 0.0 }, { x: 20, y: 35 });

  function addTextLine($text: string | (() => string), styleOverrides?: Partial<TextStyle>) {
    const tf = new FadingText(``, {
      fontFamily: FontFamily.Default,
      fontSize: 26,
      fontWeight: "bold",
      fill: 0xffffff,

      stroke: "#080808",
      strokeThickness: 1,

      // dropShadow: true,
      // dropShadowAngle: 1.57079632679,
      // dropShadowColor: 0x010101,
      // dropShadowDistance: 2,
      // dropShadowAlpha: 0.75,

      ...styleOverrides,
    });
    tf._alphaBase = tf.alpha = 0.4;
    container.addChild(tf);

    function updateText(text: string) {
      tf.text = text.trim().toUpperCase();
      tf.x = -container.screenAnchor.x * tf.width;
      tf.y = -container.screenAnchor.y * tf.height;
      container.updateIndicatorPositions(viewSize);
    }

    if (typeof $text === "function") {
      container.enchantments.watch($text, updateText, true);
    } else if (typeof $text === "string") {
      updateText($text.toUpperCase());
    }

    return tf;
  }

  addTextLine(() => (contracts.currentUserName || "") + "  ☰");

  buttonizeDisplayObject(container, () => openMainDropdownMenu(container.x, container.y));

  return container;
}

export type UserAccountIndicator = ReturnType<typeof createUserAccountIndicator>;
