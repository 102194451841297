import { GameSingletons } from "@game/app/GameSingletons";
import { deleteUndefinedObjectProperties } from "@sdk/helpers/objects";
import { debounce } from "@sdk/utils/callbacks/debounce";

export async function createUserPreferencesFirebaseStorageProxy() {
  const { firebase } = GameSingletons.getGameContext();
  const firebaseData = (await firebase.getUserMainData())?.preferences;
  const defaults: NonNullable<Required<typeof firebaseData>> = {
    minigamesEnabled: true,
    autorepairEnabled: false,
  };

  const preferences = deleteUndefinedObjectProperties<typeof defaults>({
    ...defaults,
    ...firebaseData,
  });

  const updateFirestore = debounce(preferences => firebase.updateUserMainData({ preferences }));

  if (firebaseData == undefined) {
    await updateFirestore(preferences);
  }

  return new Proxy(preferences, {
    set(target, prop: keyof typeof preferences, value) {
      Object.assign(target, { [prop]: value });
      updateFirestore(target);
      return true;
    },
  });
}
