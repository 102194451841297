import { FontIcon } from "@game/constants/FontIcon";
import { MarketCurrencyTicker } from "@sdk-integration/contracts";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";

const currencyIconsByTicker = {
  [MarketCurrencyTicker.Tocium]: FontIcon.Tocium,
  [MarketCurrencyTicker.AMP]: FontIcon.AnomaticParticles,
};

export type MarketPriceConstituent = {
  amount: number;
  currency: MarketCurrencyTicker;
  fullString: string;
  icon: string;

  formatted: string;
  formattedAmount: string;
};

export module MarketPriceConstituent {
  export function fromString(s: string): MarketPriceConstituent {
    const [amount, currency] = s.split(" ") as [`${number}`, MarketCurrencyTicker];
    const formattedAmount = formatToMaxDecimals(amount, 4, true);
    const icon = currencyIconsByTicker[currency];
    return {
      amount: +amount,
      currency: currency,
      fullString: s,
      icon: icon,
      formattedAmount: formattedAmount,
      formatted: `${icon}${formattedAmount}`,
    };
  }
}
