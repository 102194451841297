import type { StationAssetId } from "@sdk-integration/contracts";
import { deleteUndefinedObjectProperties as deleteUndefinedPropertiesFromObject } from "@sdk/helpers/objects";
import { range } from "@sdk/utils/range";
import type { HistoryApiResultData } from "./HistoryApiResultData";
import type { HistoryApiService } from "./HistoryApiService";

export class HistoryDataController {
  constructor(public readonly api: HistoryApiService) {}

  // wax_cache: Record<StationAssetId, HistoryApiResultData.RailRunDatum[]> = {};

  async getStationRailRunsStats(station: StationAssetId, hrTimeframe: number) {
    const after: string | undefined =
      hrTimeframe > 0 ? new Date(Date.now() - hrTimeframe * 60 * 60 * 1000).toISOString() : undefined;

    const options = deleteUndefinedPropertiesFromObject({
      arrive_station: station,
      after,
    });

    const runs = await this.api.getRailRunsStats(options);

    if (!runs) {
      throw new Error(`No runs found for station ${station} after ${after}`);
    }

    const result = this.processRailRunsData(runs, hrTimeframe);

    return result;
  }

  processRailRunsData(runs: HistoryApiResultData.RailRunDatum[], hrTimeframe: number) {
    const commissionsPerUser = {} as { [railroader: string]: number };
    const commissionsPerHour = {} as { [hour: number]: number };
    const commissionsPerDay = {} as { [day: string]: number };
    let commissionsTotal = 0;

    const visitsPerUser = {} as { [railroader: string]: number };
    const visitsPerHour = {} as { [hour: number]: number };
    const visitsPerDay = {} as { [day: number]: number };
    let visitsTotal = 0;

    function dateToUnixHour(date: Date) {
      return Math.floor(date.getTime() / (1000 * 60 * 60));
    }

    function dateToUnixDay(date: Date) {
      return Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
    }

    const now = new Date();
    const unixHourNow = dateToUnixHour(now);
    const unixDayNow = dateToUnixDay(now);
    for (const deltaHours of range(hrTimeframe)) {
      const unixHour = unixHourNow - deltaHours;
      commissionsPerHour[unixHour] = 0;
      visitsPerHour[unixHour] = 0;
    }
    const daysTimeframe = Math.ceil(hrTimeframe / 24);
    for (const deltaDays of range(daysTimeframe)) {
      const unixDay = unixDayNow - deltaDays;
      commissionsPerDay[unixDay] = 0;
      visitsPerDay[unixDay] = 0;
    }

    for (const run of runs!) {
      const { station_owner_reward, railroader, block_time, run_complete } = run;

      // const timedata = new Date(run_complete);
      const timedata = new Date(block_time);

      commissionsPerUser[railroader] = (commissionsPerUser[railroader] || 0) + station_owner_reward;
      visitsPerUser[railroader] = (visitsPerUser[railroader] || 0) + 1;

      const unixHour = Math.floor(timedata.getTime() / 1000 / 60 / 60);
      commissionsPerHour[unixHour] = (commissionsPerHour[unixHour] || 0) + station_owner_reward;
      visitsPerHour[unixHour] = (visitsPerHour[unixHour] || 0) + 1;

      const unixDay = Math.floor(unixHour / 24);
      commissionsPerDay[unixDay] = (commissionsPerDay[unixDay] || 0) + station_owner_reward;
      visitsPerDay[unixDay] = (visitsPerDay[unixDay] || 0) + 1;

      commissionsTotal += station_owner_reward;
      visitsTotal += 1;
    }

    const TOCIUM_MUL = 1e-4;

    return {
      commissionsTotal: commissionsTotal * TOCIUM_MUL,
      commissionsPerUser: Object.entries(commissionsPerUser).map(([railroader, commission]) => [
        railroader,
        commission * TOCIUM_MUL,
      ]) as [string, number][],
      commissionsPerHour: Object.entries(commissionsPerHour).map(([unixHour, commission]) => [
        unixHourToDate(+unixHour),
        commission * TOCIUM_MUL,
      ]) as [Date, number][],
      commissionsPerDay: Object.entries(commissionsPerDay).map(([unixDay, commission]) => [
        unixDayToDate(+unixDay),
        commission * TOCIUM_MUL,
      ]) as [Date, number][],

      visitsTotal: visitsTotal,
      visitsPerUser: Object.entries(visitsPerUser).map(([railroader, visits]) => [railroader, visits]) as [
        string,
        number
      ][],
      visitsPerHour: Object.entries(visitsPerHour).map(([unixHour, visits]) => [unixHourToDate(+unixHour), visits]) as [
        Date,
        number
      ][],
      visitsPerDay: Object.entries(visitsPerDay).map(([unixDay, visits]) => [unixDayToDate(+unixDay), visits]) as [
        Date,
        number
      ][],

      // runs,
      // runsBlockTimes: runs!.map((run) => run.block_time),
      // runsCompleteTimes: runs!.map((run) => run.run_complete),
    };
  }
}

function unixHourToDate(unixHour: number) {
  return new Date(unixHour * 1000 * 60 * 60);
}

function unixDayToDate(unixDay: number) {
  return new Date(unixDay * 1000 * 60 * 60 * 24);
}
