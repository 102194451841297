import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Text } from "@pixi/text";

export class RouletteTemp extends Container {
  gameList: Text[] = [];
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private graphics: Graphics = new Graphics();

  public selectedGame?: Text;

  constructor() {
    super();
    this.addChild(this.graphics);
  }

  createSelection(gameNames: Array<string>, y: number) {
    let startY = y;
    for (const name of gameNames) {
      const gameName = this.factory.createText(name, { fontSize: 42 }, { y: startY });
      gameName.anchor.set(0.5);
      this.gameList.push(gameName);
      this.addChild(gameName);

      startY += 150;
    }
  }

  setHighlightedItemIndex(i: number) {
    this.graphics.clear();
    this.selectedGame = this.gameList[i];
    this.graphics.beginFill(0x025d79);
    this.graphics.drawRect(-585, this.selectedGame.y - this.selectedGame.height, 1170, 98);
  }

  setSelectedItemIndex(i: number) {
    this.graphics.clear();
    this.selectedGame = this.gameList[i];
    this.graphics.beginFill(0x1ed3e4);
    this.graphics.drawRect(-585, this.selectedGame.y - this.selectedGame.height, 1170, 98);
  }

  clearHighlights() {
    this.graphics.clear();
  }
}
