import { CardEntity } from "@game/data/entities/CardEntity";
import { CARD_ASSET_IPFS_HASH_TO_HUMANNAME } from "./CARD_ASSET_IMG_HASH_TO_HUMANNAME";

const LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME: Record<string, string> = {
  "Locomotives/0-armed-spirit.png": "armed-spirit",
  "Locomotives/0-big-boy.png": "big-boy",
  "Locomotives/0-scrf.png": "scrf",
  "Locomotives/1-illinois.png": "illinois-temrinal",
  "Locomotives/1-kodiak.png": "kodiak",
  "Locomotives/1-mantis.png": "mantis",
  "Locomotives/2-bomber.png": "blue-bomber",
  "Locomotives/2-olympic.png": "olympic",
  "Locomotives/2-piedmont.png": "piedmont-service",
  "Locomotives/3-dirty-sav.png": "sav",
  "Locomotives/3-raven.png": "raven",
  "Locomotives/4-interstate.png": "interstate",
  "Locomotives/4-mastodon.png": "mastodon",
  "Locomotives/5-evolution.png": "evolution",
  "Locomotives/5-heritage.png": "heritage",
  "Locomotives/Modern-Century-Train.png": "ct",

  // Upgraded, Common
  "Locomotives Upgraded/carbon-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/carbon-big-boy.png": "big-boy",
  "Locomotives Upgraded/carbon-scrf-75.png": "scrf",
  "Locomotives Upgraded/steel-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/steel-big-boy.png": "big-boy",
  "Locomotives Upgraded/steel-scrf-75.png": "scrf",
  "Locomotives Upgraded/tungsten-armed-spirit.png": "armed-spirit",
  "Locomotives Upgraded/tungsten-big-boy.png": "big-boy",
  "Locomotives Upgraded/tungsten-scrf-75.png": "scrf",

  // Upgraded, Uncommon
  "Locomotives Upgraded/carbon-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/carbon-kodiak.png": "kodiak",
  "Locomotives Upgraded/carbon-mantis.png": "mantis",
  "Locomotives Upgraded/steel-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/steel-kodiak.png": "kodiak",
  "Locomotives Upgraded/steel-mantis.png": "mantis",
  "Locomotives Upgraded/tungsten-illinois.png": "illinois-temrinal",
  "Locomotives Upgraded/tungsten-kodiak.png": "kodiak",
  "Locomotives Upgraded/tungsten-mantis.png": "mantis",

  // Upgraded, Rare
  "Locomotives Upgraded/carbon-bomber.png": "blue-bomber",
  "Locomotives Upgraded/carbon-olympic.png": "olympic",
  "Locomotives Upgraded/carbon-piedmont.png": "piedmont-service",
  "Locomotives Upgraded/steel-bomber.png": "blue-bomber",
  "Locomotives Upgraded/steel-olympic.png": "olympic",
  "Locomotives Upgraded/steel-piedmont.png": "piedmont-service",
  "Locomotives Upgraded/tungsten-bomber.png": "blue-bomber",
  "Locomotives Upgraded/tungsten-olympic.png": "olympic",
  "Locomotives Upgraded/tungsten-piedmont.png": "piedmont-service",

  // Upgraded, Epic
  "Locomotives Upgraded/steel-dirty-sav.png": "sav",
  "Locomotives Upgraded/steel-raven.png": "raven",
  "Locomotives Upgraded/carbon-dirty-sav.png": "sav",
  "Locomotives Upgraded/carbon-raven.png": "raven",
  "Locomotives Upgraded/tungsten-dirty-sav.png": "sav",
  "Locomotives Upgraded/tungsten-raven.png": "raven",

  // Upgraded, Legendary
  "Locomotives Upgraded/steel-interstate.png": "interstate",
  "Locomotives Upgraded/steel-mastodon.png": "mastodon",
  "Locomotives Upgraded/carbon-interstate.png": "interstate",
  "Locomotives Upgraded/carbon-mastodon.png": "mastodon",
  "Locomotives Upgraded/tungsten-interstate.png": "interstate",
  "Locomotives Upgraded/tungsten-mastodon.png": "mastodon",

  // Upgraded, Mythic
  "Locomotives Upgraded/carbon-evolution.png": "evolution",
  "Locomotives Upgraded/carbon-heritage.png": "heritage",
  "Locomotives Upgraded/steel-evolution.png": "evolution",
  "Locomotives Upgraded/steel-heritage.png": "heritage",
  "Locomotives Upgraded/tungsten-evolution.png": "evolution",
  "Locomotives Upgraded/tungsten-heritage.png": "heritage",
};

const CONDUCTOR_CARD_HUMANNAME_TO_PREVIEW_FILENAME: Record<string, string> = {
  "Conductors/0-ned.png": "ned",
  "Conductors/0-orson.png": "orson",
  "Conductors/0-tommy.png": "tommy",
  "Conductors/1-ash.png": "ash",
  "Conductors/1-billy.png": "billy",
  "Conductors/1-shayan.png": "shayan",
  "Conductors/2-jame.png": "jame",
  "Conductors/2-missy.png": "missy",
  "Conductors/2-paloma.png": "paloma",
  "Conductors/3-osipov.png": "osipov",
  "Conductors/3-tobias.png": "tobia",
  "Conductors/4-johnny.png": "johnny",
  "Conductors/4-wesley.png": "wesley",
  "Conductors/5-gutshot.png": "gutshot",
  "Conductors/5-kc.png": "kc",
};

export function getCardPreviewTextureUrl(cardOrIPFS: CardEntity | string | null) {
  if (cardOrIPFS === null) {
    return null;
  }

  if (cardOrIPFS instanceof CardEntity) {
    cardOrIPFS = cardOrIPFS.imageIPFSHash;
  }

  const humanName = CARD_ASSET_IPFS_HASH_TO_HUMANNAME[cardOrIPFS];

  if (LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME[humanName]) {
    return `locomotive-previews/${LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME[humanName]}.png`;
  }

  if (CONDUCTOR_CARD_HUMANNAME_TO_PREVIEW_FILENAME[humanName]) {
    return `conductor-previews/${CONDUCTOR_CARD_HUMANNAME_TO_PREVIEW_FILENAME[humanName]}.png`;
  }

  return null;
}

// __window__.foo = async function () {
//   const { GameSingletons } = await import("@game/app/GameSingletons");
//   const assets = GameSingletons.getAssets();
//   const app = GameSingletons.getPixiApplication();
//   for (const key in {
//     ...CONDUCTOR_CARD_HUMANNAME_TO_PREVIEW_FILENAME,
//     ...LOCOMOTIVE_CARD_HUMANNAME_TO_PREVIEW_FILENAME,
//   }) {
//     const id = getCardPreviewTextureUrl(key);
//     if (!id) {
//       console.log(`💢  Missing card preview texture for ${key}`);
//       continue;
//     }
//     const texture = assets.getTexture(id);
//     const sprite = new Sprite(texture);
//     app.stage.addChild(sprite);
//   }
// };
