import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { Text } from "@pixi/text";
import { BitmapText, IBitmapTextStyle } from "@pixi/text-bitmap";
import { fitObjectInRectangle } from "@sdk-pixi/layout/fitObjectInRectangle";
import { createXButton, XButton } from "../../components/createXButton";
import { AvatarBadge } from "../avatar/AvatarBadge";
import { FeaturedAchievementsSection } from "./FeaturedAchievementsSection";
import { SocialProfileDataService } from "../SocialProfileDataService";
import { AchievementsProgress } from "../panel-view-profile/AchievementsProgress";
import { makeDraggable } from "@debug/utils/makeDraggable";
import { init } from "@sentry/browser";

const labelStyle = {
  fontName: "Celestial Typeface",
  align: "center",
} as IBitmapTextStyle;

export class SocialProfileQuickView extends Container {
  private readonly factory = GameSingletons.getSimpleObjectFactory();

  public background;
  public avatar: AvatarBadge;
  public railroaderTag: Text;
  public closeButton?: XButton;

  private readonly dataService = new SocialProfileDataService();

  constructor(private viewProfileClick?: () => void) {
    super();
    this.background = this.factory.createSprite("ui-social/right-panel/expanded/base.png");
    this.avatar = new AvatarBadge();
    this.railroaderTag = this.factory.createText(
      "",
      { fontFamily: FontFamily.DanielBlack, fontSize: 28 },
      { x: 275, y: 580 }
    );
  }
  async init(username: string) {
    //// Add background

    this.addChild(this.background);

    const box = this.factory.createSprite("ui-social/right-panel/expanded/achievement-base.png");
    box.position.set(55, 275);
    this.addChild(box);

    //// Add avatar

    this.avatar.scale.set(0.35);
    this.avatar.position.set(105, 50);
    this.addChild(this.avatar);

    //// Add bottom label

    this.railroaderTag.anchor.set(0.5);
    this.addChild(this.railroaderTag);

    ////Ach Icon
    const achIcon = this.factory.createSprite("assets/images/ui-social/ach-icon.png");
    achIcon.alpha = 0.8;
    achIcon.scale.set(0.52);
    achIcon.anchor.set(0.5);
    achIcon.position.set(140, 576);
    this.addChild(achIcon);

    ////Progress Bar
    const progressBar = new AchievementsProgress(username);
    progressBar.initialize();
    progressBar.pivot.set(0.5);
    progressBar.position.set(achIcon.x + 25, achIcon.y - 11);
    this.addChild(progressBar);

    ////Progress Labels
    const percentageLabel = new Text((await progressBar.progressData).percentage.toFixed(0) + "%", {
      fontSize: 16,
      fontFamily: FontFamily.Default,
      fill: 0xd3d3d3,
    });
    const numberOfAchievementsEarned = new Text((await progressBar.progressData).countUnlocked.toFixed(0), {
      fontSize: 16,
      fontFamily: FontFamily.Default,
      fill: 0xffffff,
    });
    numberOfAchievementsEarned.anchor.set(0.5);
    percentageLabel.anchor.set(0.5);
    numberOfAchievementsEarned.position.set(progressBar.x + 10, progressBar.y + 25);
    percentageLabel.position.set(progressBar.x + 235, progressBar.y + 25);
    this.addChild(percentageLabel, numberOfAchievementsEarned);

    //// Add view profile button
    const viewProfileButton = this.factory.createSprite("ui-social/right-panel/expanded/btn.png");
    viewProfileButton.tint = 0x023438;
    const viewBtnText = new BitmapText("view profile", labelStyle);
    viewBtnText.scale.set(0.75);
    viewBtnText.position.set(75, 0);
    viewProfileButton.addChild(viewBtnText);
    viewProfileButton.position.set(85, 625);
    viewProfileButton.interactive = true;
    viewProfileButton.buttonMode = true;
    viewProfileButton.on("pointerdown", () => this.viewProfileClick?.());
    this.addChild(viewProfileButton);
  }

  public addCloseButton(onClick: () => unknown) {
    const button = (this.closeButton = createXButton());
    button.scale.set(0.8);
    button.position.set(45, 22);
    button.behavior.on({
      trigger: () => {
        onClick();
        button?.destroy({ children: true });
      },
    });
    return this.addChild(button);
  }

  async applyUserData(username: string) {
    await AvatarBadge.applyUserData(this.avatar, username);

    AvatarBadge.showWhenFullyLoaded(this.avatar);

    const data = await this.dataService.getHighlightedMedals(username);
    for (let [index, element] of data.entries()) {
      this.avatar.railroaderArc.setHighlight(element, index);
    }

    const { tagline } = await this.dataService.getMiscProfilePreferences(username);
    if (tagline) {
      this.setRailroaderTagline(tagline);
    }

    //// Add achievements
    const featuredAchievementsSection = new FeaturedAchievementsSection();
    const achievements = await this.dataService.getFeaturedAchievements(username);
    featuredAchievementsSection.populate(achievements);
    featuredAchievementsSection.position.set(90, 400);
    this.addChild(featuredAchievementsSection);
  }

  private setRailroaderTagline(text: string) {
    this.railroaderTag.text = text;
    fitObjectInRectangle(this.railroaderTag, { x: 137, y: 560, width: 256, height: 40 });
  }
}
