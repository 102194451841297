import * as WAX from "@sdk-integration/contracts";

import type { TrainEntity } from "@game/data/entities/TrainEntity";
import type { CardEntity } from "@game/data/entities/CardEntity";
import type { RailRunEntity } from "./entities/RailRunEntity";
import type { StationEntity } from "./entities/StationEntity";
import { CardType } from "@game/constants/CardType";
import { RegionIntegerId } from "@game/constants/RegionId";
import { MarketCurrencyTicker } from "@sdk-integration/contracts";

export class UserDataHolder {
  name = "N/A" as WAX.AccountName;

  tocium = 0;
  anomaticParticles = 0;

  fuel = {
    coal: 0,
    diesel: 0,
    unknown:100000000000,
  };

  trains = new Map<TrainEntity.Name, TrainEntity>();
  trainsArray = new Array<TrainEntity>();

  cards = new Map<CardEntity.AssetId, CardEntity>();
  invalidCards = new Map<CardEntity.AssetId, CardEntity>();

  modifiers = new Array<any>();

  redeemableAssets = new Array<WAX.RedeemableData>();

  trainsOngoingRuns = new Map<TrainEntity.Name, RailRunEntity>();
  stationsIncomingRuns = new Map<StationEntity.AssetId, RailRunEntity>();

  thomasActiveRegions = new Array<RegionIntegerId>();
  culpritActiveRegions = new Array<RegionIntegerId>();

  getBalance(ticker: MarketCurrencyTicker) {
    switch (ticker) {
      case MarketCurrencyTicker.Tocium:
        return this.tocium;
      case MarketCurrencyTicker.AMP:
        return this.anomaticParticles;
    }
    console.error(`Unknown ticker: ${ticker}`);
    return 0;
  }

  getCard<T extends WAX.CardData>(cardAssetId: WAX.CardAssetId) {
    return (
      (this.cards.get(cardAssetId) as CardEntity<T> | undefined) ||
      (this.invalidCards.get(cardAssetId) as CardEntity<T> | undefined)! ||
      null
    );
  }

  getOngoingTrainRun(trainName: WAX.TrainName) {
    return this.trainsOngoingRuns.get(trainName) || null;
  }

  getTrainEquippedWithCard(card: CardEntity): TrainEntity | null {
    const trains = this.trains;
    for (const [, train] of trains) {
      const excludeCommodities = card.type !== CardType.Loadable;
      for (const trainCard of train.iterateAllEquippedCards(excludeCommodities)) {
        if (card.assetId === trainCard.assetId) {
          return train;
        }
      }
    }
    return null;
  }

  *iterateUnequippedCards(type?: CardType) {
    for (const [, card] of this.cards) {
      if (type != undefined && card.type !== type) {
        continue;
      }
      const train = this.getTrainEquippedWithCard(card);
      if (!train) {
        yield card;
      }
    }
  }

  *iterateAllCards<T extends WAX.CardData = WAX.CardData>(
    type?: CardType,
    filter?: (card: CardEntity<any>) => boolean
  ) {
    for (const [, card] of this.cards) {
      if (type == undefined || card.type === type) {
        if (filter == undefined || filter(card)) {
          yield card as CardEntity<T>;
        }
      }
    }
  }

  getRailRunModifiersGrouped() {
    const mods = this.modifiers;

    const totals = {} as { [templateId: string]: any[] };

    for (const mod of mods) {
      const templateId = mod.template.template_id;
      const total = totals[templateId] || (totals[templateId] = []);
      total.push(mod);
    }

    return totals;
  }
}
