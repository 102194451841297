import { AchievementBadgeData } from "./AchievementBadgeData";

export type AchievementBadgeDataBlueprint = Omit<AchievementBadgeData, "id">;

const ACHIEVEMENT_BADGE_BLUEPRINTS: Record<number, AchievementBadgeDataBlueprint> = {
  [1]: {
    achievementId: 1,
    name: "Beta Badge",
    textureId: "ui-achievements/misc-badges/0-beta.png",
    description: `Must have travelled at least 100 Distance during the beta phase of the game.`,
  },
  [2]: {
    achievementId: 2,
    name: "Golden Railroader",
    textureId: "ui-achievements/misc-badges/5-grs.png",
    description: `The first railroader to make a Conductor's Golden Run may bare this coveted badge.`,
  },
  [3]: {
    achievementId: 3,
    name: "Golden Runner",
    textureId: "ui-achievements/misc-badges/5-grs.png",
    description: `Must have made at least one of each conductor’s golden runs.`,
  },
  [4]: {
    achievementId: 4,
    name: "Centuryville Connoisseur",
    textureId: "ui-achievements/region-badges/centuryville.png",
    description: `Visited every station in Centuryville`,
  },
  [5]: {
    achievementId: 5,
    name: "T Pines Traveler",
    textureId: "ui-achievements/region-badges/t-pines.png",
    description: `Visited every station in Trevithick Pines.`,
  },
  [6]: {
    achievementId: 6,
    name: "Pemberton Nights",
    textureId: "ui-achievements/region-badges/p-heights.png",
    description: `Visited every station in Pemberton Heights.`,
  },
  [7]: {
    achievementId: 7,
    name: "James Park Juggalo",
    textureId: "ui-achievements/region-badges/james-park.png",
    description: `Visited every station in James Park.`,
  },
  [8]: {
    achievementId: 8,
    name: "Paw Paw Pro",
    textureId: "ui-achievements/region-badges/ppp.png",
    description: `Visited every station in Paw Paw Plains.`,
  },
  [9]: {
    achievementId: 9,
    name: "Modern Master",
    textureId: "ui-achievements/misc-badges/5-modern-master.png",
    description: `Visited every station in the Modern Century.`,
  },
  [10]: {
    achievementId: 10,
    name: "Otto’s Fellow",
    textureId: "ui-achievements/otto/lvl1-fellow.png",
    description: `Had 1 interaction with Otto`,
  },
  [11]: {
    achievementId: 11,
    name: "Otto’s Colleague",
    textureId: "ui-achievements/otto/lvl2-colleague.png",
    description: `Had 5 interactions with Otto`,
  },
  [12]: {
    achievementId: 12,
    name: "Otto’s Friend",
    textureId: "ui-achievements/otto/lvl3-friend.png",
    description: `Had 10 interactions with Otto`,
  },
  [13]: {
    achievementId: 13,
    name: "Otto’s Enemy",
    textureId: "ui-achievements/otto/lvl4-enemy.png",
    description: `Had 17 interactions with Otto`,
  },
  [14]: {
    achievementId: 14,
    name: "Otto's Bro",
    textureId: "ui-achievements/otto/lvl5-bro.png",
    description: `Had 18 interactions with Otto`,
  },
  [15]: {
    achievementId: 15,
    name: "7 Day Streak",
    textureId: "ui-achievements/streaks/7day.png",
    description: `Made a rail run, 7 days in a row.`,
  },
  [16]: {
    achievementId: 16,
    name: "30 Day Streak",
    textureId: "ui-achievements/streaks/30day.png",
    description: `Made a rail run, 30 days in a row.`,
  },
  [17]: {
    achievementId: 17,
    name: "90 Day Streak",
    textureId: "ui-achievements/streaks/90day.png",
    description: `Made a rail run, 90 days in a row.`,
  },
  [18]: {
    achievementId: 18,
    name: "180 Day Streak",
    textureId: "ui-achievements/streaks/180day.png",
    description: `Made a rail run, 180 days in a row.`,
  },
  [19]: {
    achievementId: 19,
    name: "365 Day Streak",
    textureId: "ui-achievements/streaks/365day.png",
    description: `Made a rail run, 365 days in a row.`,
  },
  [20]: {
    achievementId: 20,
    name: "Train Maestro",
    textureId: "ui-achievements/misc-badges/train-maestro.png",
    description: `Unlocked 10 trains (9 additional after first)`,
  },
  [21]: {
    achievementId: 21,
    name: "Full Train ",
    textureId: "ui-achievements/misc-badges/full-train.png",
    description: `Unlocked all Rail Car slots for one train`,
  },
  [22]: {
    achievementId: 22,
    name: "Pallet Pusher",
    description: `Hauled palleted commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-pallet.png",
  },
  [23]: {
    achievementId: 23,
    name: "Pallet Pusher",
    description: `Hauled palleted commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-pallet.png",
  },
  [24]: {
    achievementId: 24,
    name: "Pallet Pusher",
    description: `Hauled palleted commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-pallet.png",
  },
  [25]: {
    achievementId: 25,
    name: "Pallet Pusher",
    description: `Hauled palleted commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-pallet.png",
  },
  [26]: {
    achievementId: 26,
    name: "Pallet Pusher",
    description: `Hauled palleted commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-pallet.png",
  },
  [27]: {
    achievementId: 27,
    name: "Crate Carrier",
    description: `Hauled crated commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-crate.png",
  },
  [28]: {
    achievementId: 28,
    name: "Crate Carrier",
    description: `Hauled crated commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-crate.png",
  },
  [29]: {
    achievementId: 29,
    name: "Crate Carrier",
    description: `Hauled crated commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-crate.png",
  },
  [30]: {
    achievementId: 30,
    name: "Crate Carrier",
    description: `Hauled crated commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-crate.png",
  },
  [31]: {
    achievementId: 31,
    name: "Crate Carrier",
    description: `Hauled crated commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-crate.png",
  },
  [32]: {
    achievementId: 32,
    name: "Liquid Lifter",
    description: `Hauled liquid commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-liquid.png",
  },
  [33]: {
    achievementId: 33,
    name: "Liquid Lifter",
    description: `Hauled liquid commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-liquid.png",
  },
  [34]: {
    achievementId: 34,
    name: "Liquid Lifter",
    description: `Hauled liquid commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-liquid.png",
  },
  [35]: {
    achievementId: 35,
    name: "Liquid Lifter",
    description: `Hauled liquid commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-liquid.png",
  },
  [36]: {
    achievementId: 36,
    name: "Liquid Lifter",
    description: `Hauled liquid commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-liquid.png",
  },
  [37]: {
    achievementId: 37,
    name: "Mr. Gas",
    description: `Hauled gas commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-gas.png",
  },
  [38]: {
    achievementId: 38,
    name: "Mr. Gas",
    description: `Hauled gas commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-gas.png",
  },
  [39]: {
    achievementId: 39,
    name: "Mr. Gas",
    description: `Hauled gas commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-gas.png",
  },
  [40]: {
    achievementId: 40,
    name: "Mr. Gas",
    description: `Hauled gas commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-gas.png",
  },
  [41]: {
    achievementId: 41,
    name: "Mr. Gas",
    description: `Hauled gas commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-gas.png",
  },
  [42]: {
    achievementId: 42,
    name: "Woodchip King",
    description: `Hauled aggregate commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-aggregate.png",
  },
  [43]: {
    achievementId: 43,
    name: "Woodchip King",
    description: `Hauled aggregate commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-aggregate.png",
  },
  [44]: {
    achievementId: 44,
    name: "Woodchip King",
    description: `Hauled aggregate commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-aggregate.png",
  },
  [45]: {
    achievementId: 45,
    name: "Woodchip King",
    description: `Hauled aggregate commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-aggregate.png",
  },
  [46]: {
    achievementId: 46,
    name: "Woodchip King",
    description: `Hauled aggregate commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-aggregate.png",
  },
  [47]: {
    achievementId: 47,
    name: "Rock Hustler",
    description: `Hauled ore commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-ore.png",
  },
  [48]: {
    achievementId: 48,
    name: "Rock Hustler",
    description: `Hauled ore commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-ore.png",
  },
  [49]: {
    achievementId: 49,
    name: "Rock Hustler",
    description: `Hauled ore commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-ore.png",
  },
  [50]: {
    achievementId: 50,
    name: "Rock Hustler",
    description: `Hauled ore commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-ore.png",
  },
  [51]: {
    achievementId: 51,
    name: "Rock Hustler",
    description: `Hauled ore commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-ore.png",
  },
  [52]: {
    achievementId: 52,
    name: "Sugar Daddy",
    description: `Hauled granule commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-granule.png",
  },
  [53]: {
    achievementId: 53,
    name: "Sugar Daddy",
    description: `Hauled granule commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-granule.png",
  },
  [54]: {
    achievementId: 54,
    name: "Sugar Daddy",
    description: `Hauled granule commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-granule.png",
  },
  [55]: {
    achievementId: 55,
    name: "Sugar Daddy",
    description: `Hauled granule commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-granule.png",
  },
  [56]: {
    achievementId: 56,
    name: "Sugar Daddy",
    description: `Hauled granule commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-granule.png",
  },
  [57]: {
    achievementId: 57,
    name: "Grainasaurus Rex",
    description: `Hauled grain commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-grain.png",
  },
  [58]: {
    achievementId: 58,
    name: "Grainasaurus Rex",
    description: `Hauled grain commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-grain.png",
  },
  [59]: {
    achievementId: 59,
    name: "Grainasaurus Rex",
    description: `Hauled grain commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-grain.png",
  },
  [60]: {
    achievementId: 60,
    name: "Grainasaurus Rex",
    description: `Hauled grain commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-grain.png",
  },
  [61]: {
    achievementId: 61,
    name: "Grainasaurus Rex",
    description: `Hauled grain commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-grain.png",
  },
  [62]: {
    achievementId: 62,
    name: "Icicle Jones",
    description: `Hauled perishable commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-perishable.png",
  },
  [63]: {
    achievementId: 63,
    name: "Icicle Jones",
    description: `Hauled perishable commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-perishable.png",
  },
  [64]: {
    achievementId: 64,
    name: "Icicle Jones",
    description: `Hauled perishable commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-perishable.png",
  },
  [65]: {
    achievementId: 65,
    name: "Icicle Jones",
    description: `Hauled perishable commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-perishable.png",
  },
  [66]: {
    achievementId: 66,
    name: "Icicle Jones",
    description: `Hauled perishable commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-perishable.png",
  },
  [67]: {
    achievementId: 67,
    name: "Big Shit Express",
    description: `Hauled oversized commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-oversized.png",
  },
  [68]: {
    achievementId: 68,
    name: "Big Shit Express",
    description: `Hauled oversized commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-oversized.png",
  },
  [69]: {
    achievementId: 69,
    name: "Big Shit Express",
    description: `Hauled oversized commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-oversized.png",
  },
  [70]: {
    achievementId: 70,
    name: "Big Shit Express",
    description: `Hauled oversized commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-oversized.png",
  },
  [71]: {
    achievementId: 71,
    name: "Big Shit Express",
    description: `Hauled oversized commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-oversized.png",
  },
  [72]: {
    achievementId: 72,
    name: "Rob the Builder",
    description: `Hauled building material commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-building.png",
  },
  [73]: {
    achievementId: 73,
    name: "Rob the Builder",
    description: `Hauled building material commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-building.png",
  },
  [74]: {
    achievementId: 74,
    name: "Rob the Builder",
    description: `Hauled building material commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-building.png",
  },
  [75]: {
    achievementId: 75,
    name: "Rob the Builder",
    description: `Hauled building material commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-building.png",
  },
  [76]: {
    achievementId: 76,
    name: "Rob the Builder",
    description: `Hauled building material commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-building.png",
  },
  [77]: {
    achievementId: 77,
    name: "OttoMobile",
    description: `Hauled automobile commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-auto.png",
  },
  [78]: {
    achievementId: 78,
    name: "OttoMobile",
    description: `Hauled automobile commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-auto.png",
  },
  [79]: {
    achievementId: 79,
    name: "OttoMobile",
    description: `Hauled automobile commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-auto.png",
  },
  [80]: {
    achievementId: 80,
    name: "OttoMobile",
    description: `Hauled automobile commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-auto.png",
  },
  [81]: {
    achievementId: 81,
    name: "OttoMobile",
    description: `Hauled automobile commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-auto.png",
  },
  [82]: {
    achievementId: 82,
    name: "Entity 9’s BFF",
    description: `Hauled top secret commodities over 5,000 distance.`,
    textureId: "ui-achievements/commodities/lvl1-secret.png",
  },
  [83]: {
    achievementId: 83,
    name: "Entity 9’s BFF",
    description: `Hauled top secret commodities over 10,000 distance.`,
    textureId: "ui-achievements/commodities/lvl2-secret.png",
  },
  [84]: {
    achievementId: 84,
    name: "Entity 9’s BFF",
    description: `Hauled top secret commodities over 20,000 distance.`,
    textureId: "ui-achievements/commodities/lvl3-secret.png",
  },
  [85]: {
    achievementId: 85,
    name: "Entity 9’s BFF",
    description: `Hauled top secret commodities over 35,000 distance.`,
    textureId: "ui-achievements/commodities/lvl4-secret.png",
  },
  [86]: {
    achievementId: 86,
    name: "Entity 9’s BFF",
    description: `Hauled top secret commodities over 50,000 distance.`,
    textureId: "ui-achievements/commodities/lvl5-secret.png",
  },
};

const ACHIEVEMENT_BADGE_BLUEPRINTS_COUNT = Object.keys(ACHIEVEMENT_BADGE_BLUEPRINTS).length;

export function getAchievementBadgeDataBlueprint(achievementId: number): AchievementBadgeDataBlueprint {
  return { ...ACHIEVEMENT_BADGE_BLUEPRINTS[achievementId] };
}

export function getAchievementBadgeDataBlueprintsCount(): number {
  return ACHIEVEMENT_BADGE_BLUEPRINTS_COUNT;
}
