import { onKeyPress } from "@debug/utils/onKeyPress";
import { __window__ } from "@debug/__";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { NPCEncounterCinematic_Story } from "@game/cinematics/NPCEncounterCinematic_Story";
import { presentUserDialogueChoiceOptions } from "@game/cinematics/story/presentUserDialogueChoiceOptions";
import { dynamicChoiceActionFunctions } from "@game/cinematics/utils/dynamicChoiceActionFunctions";
import { dynamicSpecialEffectFunctions } from "@game/cinematics/utils/dynamicSpecialEffectFunctions";
import { createDynamicStoryTextService } from "@game/cinematics/utils/dynamicStoryContentFunctions";

export function testMockCampaigns(context: GameContext = GameSingletons.getGameContext()) {
  async function playMysteriousStrangerCinematic(cinematicDataKey: string) {
    const { userData, firebase, spinner } = context;
    const getCinematicsData = spinner.bindSpinner(firebase.getCinematicsData);
    const cinematicData = await getCinematicsData(cinematicDataKey as any);
    const cinematic = new NPCEncounterCinematic_Story(cinematicData, () => context.ticker.delay(0.5));
    const train = context.HACKS.__lastSelectedTrain || userData.trains.values().next().value;
    cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
    cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
    cinematic.dynamicSpecialEffectsService = dynamicSpecialEffectFunctions();
    cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
    // cinematic.shouldShowIntroModal = false;

    __window__.cinematic = context.stageContainers._cinematic.addChild(cinematic);
    await cinematic.play();
  }

  const service = {
    playInteractive: () => playMysteriousStrangerCinematic("debug_encounter_interactive"),
    playShort: () => playMysteriousStrangerCinematic("debug_encounter_short"),

    playChapter1: () => playMysteriousStrangerCinematic("mock_campaign_encounter_1"),
    playChapter2: () => playMysteriousStrangerCinematic("mock_campaign_encounter_2"),
    playChapter3: () => playMysteriousStrangerCinematic("mock_campaign_encounter_3"),
  };

  onKeyPress("1", service.playChapter1);
  onKeyPress("2", service.playChapter2);
  onKeyPress("3", service.playChapter3);

  return service;
}
