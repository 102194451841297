import { onKeyPress } from "@debug/utils/onKeyPress";
import { __window__ } from "@debug/__";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { StationAssetId } from "@sdk-integration/contracts";
import { IAnimateOptions } from "pixi-viewport";

const TEST_SCREEN_POSITION_OFFSET_X = 0;
const TEST_SCREEN_POSITION_OFFSET_Y = 0;

/**
 *
 * @param stationId
 * @param screenPositionX
 * @param screenPositionY
 */
function gotoStation(
  stationId: string,
  //pass in -0.5 up to 0.5
  screenPositionX: number = TEST_SCREEN_POSITION_OFFSET_X,
  screenPositionY: number = TEST_SCREEN_POSITION_OFFSET_Y
) {
  //// TEMP //// TEMP //// TEMP //// TEMP //// TEMP ////
  const context: GameContext = GameSingletons.getGameContext();
  //// TEMP //// TEMP //// TEMP //// TEMP //// TEMP ////

  const { mapData, viewport, viewSize } = context;
  const screenViewWidth = viewSize.width;
  const screenViewHeight = viewSize.height;
  const offsetX = (viewSize.width * (screenPositionX - 0.5)) / 0.085;
  const offsetY = (viewSize.height * (screenPositionY - 0.5)) / 0.085;
  const station = mapData.stations.get(stationId as StationAssetId);
  if (station === undefined) {
    throw new Error("station not found");
  }
  // TODO: Animate to station
  const point = {
    x: station.x - offsetX,
    y: station.y - offsetY,
  };
  const animateSnapTo: Partial<IAnimateOptions> = {
    position: point,
    scale: 0.085,
    time: 1000,
  };
  viewport.animate(animateSnapTo);
}

export function testAutomaticViewportNavigation(context: GameContext) {
  const { mapData, userData, viewport } = context;

  viewport.boundsEnabled = false;

  // Center viewport and animate movement //

  /**
   * Let's first get a list of all the user's trains.
   * userData.trains is an instance of js's built in Map.
   * Its .values() function returns what's called an iterator of only the elements on the right side of the map.
   * An Iterator is a special kind of object that can be iterated over like an array.
   * In the same way all our class instances are all different enriched types of Object,
   * a javascript array is a type of iterator. All arrays are iterators, but not all iterators are arrays.
   * Since we actually *do* want to get an array of trains out if this whole deal,
   * there's a dead simple way to produce an array out of any iterator.   *
   * ```js
   * const array = [...iterator]; // The three dots is called "spread operator" or "spread syntax"
   * ```
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
   *
   * Side-note: The above is a common way to convert an iterator to a brand new array.
   * Technically that iterator can even be another array, which is why you'll see people sometimes
   * copy their arrays to make new arrays like this:
   * ```js
   * const arrayClone = [...array];
   * ```
   **/

  const stations = mapData.stations;
  const myTrains = [...userData.trains.values()];
  const myTrainStationId: any[] | null = [];
  for (let i = 0; i < myTrains.length; i++) {
    myTrainStationId[i] = stations.get(myTrains[i].currentStationId as StationAssetId);
  }

  /**
   * Same shtick, but for all stations owned by the user.
   */
  // const myStations = [...mapData.stations.values()].filter((station) => station.ownerName === userData.name);

  /**
   * Let's test with hard-coded stationIds for now though, so that we can control our testing data.
   */

  const myStations = [
    mapData.stations.get("1099521667660" as StationAssetId),
    mapData.stations.get("1099521667355" as StationAssetId),
    mapData.stations.get("1099521667585" as StationAssetId),
  ];

  //***TEST PASS IN VALUES***\\
  /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///
  __window__.gotoStation = gotoStation(myTrainStationId![0].assetId, 0.5, 0.5);
  /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// /// ///

  let nextStationIndex = 0;
  function centerOnMyNextOwnedStation() {
    const point = {
      x: myStations[nextStationIndex]!.x,
      y: myStations[nextStationIndex]!.y,
    };

    const animateSnapTo: Partial<IAnimateOptions> = {
      position: point,
      scale: 0.085,
      time: 1000,
    };

    viewport.animate(animateSnapTo);

    if (nextStationIndex == myStations!.length - 1) {
      nextStationIndex = 0;
    } else {
      nextStationIndex++;
    }
  }
  onKeyPress("z", centerOnMyNextOwnedStation);

  //// //// //// //// //// //// //// ////
  let nextTrainIndex = 0;
  function centerOnMyNextTrain() {
    if (!myTrainStationId) {
      throw new Error("station not found");
    }

    const point = {
      x: myTrainStationId[nextTrainIndex].x,
      y: myTrainStationId[nextTrainIndex].y,
    };

    const animateSnapTo: Partial<IAnimateOptions> = {
      position: point,
      scale: 0.085,
      time: 1000,
    };

    viewport.animate(animateSnapTo);

    if (nextTrainIndex == myTrainStationId!.length - 1) {
      nextTrainIndex = 0;
    } else {
      nextTrainIndex++;
    }
  }
  onKeyPress("x", centerOnMyNextTrain);
}
