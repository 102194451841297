import { makeDraggable } from "@debug/utils/makeDraggable";
import { onKeyPress } from "@debug/utils/onKeyPress";
import { __window__ } from "@debug/__";
import { GameContext } from "@game/app/app";
import { Container, DisplayObject } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { InteractionEvent, InteractionManager } from "@pixi/interaction";
import { Point, Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { EventBus } from "@sdk/core/EventBus";

import type * as WAX from "@sdk-integration/contracts";

import { Rarity } from "@game/constants/Rarity";
import { ExtraData, StationEntity } from "@game/data/entities/StationEntity";
import { shuffle } from "@sdk/helpers/arrays";
import { range } from "@sdk/utils/range";
import { Texture } from "@pixi/core";

import DATA from "@debug/editor/raw/hash.json";
import { GameSingletons } from "@game/app/GameSingletons";

type StationData = {
  index: number;
  x: number;
  y: number;
  name: string;
  level: number;
  region: number;
};
type Connection = [stationIndexA: number, stationIndexB: number];

type ShortDataItem = [x: number, y: number, name: string, rarityLevel: number, links: number[], region?: number];

const ev = new EventBus<{
  change: () => void;
}>();

type RegionNumber = 0 | 1 | 2 | 3 | 4 | 5;
let selectedRegion = 0 as RegionNumber;

//// ///// /////

const getCurrentUrlHash = () => decodeURIComponent(location.hash?.substr(1) || "");

const adaptShortData = (data: ShortDataItem[]) => {
  const stationsData = [] as StationData[];
  const connections = [] as Connection[];

  for (const [index, [x, y, name, level, links, region]] of data.entries()) {
    stationsData.push({ index, x, y, name, level, region: region ?? 0 });

    for (const link of links) {
      const existingConnections = getExistingConnectionsBetweenStations.call(connections, index, link);
      if (!existingConnections?.length) {
        connections.push([index, link]);
      }
    }
  }

  return {
    stationsData,
    connections,
  };
};

function getStationsData() {
  const hash = getCurrentUrlHash();
  return adaptShortData(hash ? JSON.parse(hash) : (DATA as any as ShortDataItem[]));
}

function getExistingConnectionsBetweenStations(this: Connection[], stationIndexA: number, stationIndexB: number) {
  const existingConnections = this.filter(
    ([a, b]) => (a === stationIndexA && b === stationIndexB) || (a === stationIndexB && b === stationIndexA)
  );
  return existingConnections;
}

function toast(text: string) {
  const toast = document.createElement("div");
  toast.classList.add("toast");
  toast.innerText = text;
  document.body.appendChild(toast);
  setTimeout(() => {
    toast.remove();
  }, 3000);
}

function getStationsDistance(stationA: StationData, stationB: StationData) {
  const DISTANCE_FACTOR = 1 / 120;
  const length = Math.round(Math.hypot(stationA.x - stationB.x, stationA.y - stationB.y) * DISTANCE_FACTOR);
  return length;
}

//// ///// /////
//// ///// /////
//// ///// /////

function addStationDots(stationsData: StationData[]) {
  const { app, assets, mapData, ticker, input, viewport, viewSize } = GameSingletons.getGameContext();

  const c = new Container();
  c.name = `world-debug // container`;

  const textures = [
    "assets/debug/map-points/1-common.png",
    "assets/debug/map-points/2-uncommon.png",
    "assets/debug/map-points/3-rare.png",
    "assets/debug/map-points/4-epic.png",
    "assets/debug/map-points/5-legendary.png",
    "assets/debug/map-points/6-mythic.png",
  ];

  const stations = stationsData.map(stationData => {
    const texturePath = textures[stationData.level - 1];
    const texture = Texture.from(texturePath);
    const station = Object.assign(new Sprite(texture), { data: stationData });

    const R = 200;
    station.hitArea = new Rectangle(-R, -R, R * 2, R * 2);

    station.anchor.set(0.5);
    station.position.set(stationData.x, stationData.y);

    c.addChild(station);

    makeDraggable(station, () => 0.05 / viewport.scaled, {
      onDragMove: () => {
        stationData.x = station.x;
        stationData.y = station.y;
      },
      onDragEnd: () => {
        ev.emit("change");
        stationData.x = station.x;
        stationData.y = station.y;
      },
    });

    return station;
  });

  ticker.add(() => {
    for (const station of stations) {
      station.scale.set(0.05 / viewport.scaled);
      station.alpha = selectedRegion == 0 || station.data.region == selectedRegion ? 1 : 0.35;
    }
  });

  return Object.assign(c, { stations });
}

function drawConnections(stationsData: StationData[], connections: Connection[]) {
  const { viewport } = GameSingletons.getGameContext();

  const g = new Graphics();
  g.name = `world-debug // graphics`;

  function lengthToColor(length: number) {
    if (length > 80) {
      return 0x000000;
    }
    if (length > 60) {
      return 0xff0000;
    }
    if (length > 50) {
      return 0xff9900;
    }
    if (length > 40) {
      return 0x9900ff;
    }
    if (length > 30) {
      return 0x0099ff;
    }
    if (length > 20) {
      return 0x00b020;
    }
    return 0x808080;
  }

  ev.addListener("change", () => {
    g.clear();

    for (const [stationIndexA, stationIndexB] of connections) {
      const stationA = stationsData[stationIndexA];
      const stationB = stationsData[stationIndexB];

      const ghostify = !(
        selectedRegion == 0 ||
        (stationA.region == selectedRegion && stationB.region == selectedRegion)
      );
      const alpha = ghostify ? 0.1 : 1.0;
      const STROKE_W = (ghostify ? 3 : 6) / viewport.scaled;

      const length = getStationsDistance(stationA, stationB);
      g.lineStyle(1.6 * STROKE_W, 0x0, 0.66 * alpha);
      g.moveTo(stationA.x, stationA.y);
      g.lineTo(stationB.x, stationB.y);
      g.lineStyle(STROKE_W, lengthToColor(length), alpha);
      g.moveTo(stationA.x, stationA.y);
      g.lineTo(stationB.x, stationB.y);
    }
  });

  return g;
}

//// ///// /////
//// ///// /////
//// ///// /////

function penTool(stationsData: StationData[], connections: Connection[]) {
  const context = GameSingletons.getGameContext();
  const { app, assets, mapData, ticker, input, viewport, viewSize } = context;

  const gg = new Graphics();
  gg.name = `world-debug // pen`;

  ticker.add(() => {
    gg.clear();
    if (drag.drag.active) {
      const { from, to } = drag.drag;
      gg.lineStyle(100, 0xffffff, 0.5);
      gg.moveTo(from.x, from.y);
      gg.lineTo(to.x, to.y);
    }
  });

  function getStationClosestTo(x: number, y: number) {
    const station = stationsData.reduce((a, b) => {
      const dA = Math.hypot(x - a.x, y - a.y);
      const dB = Math.hypot(x - b.x, y - b.y);
      return dA < dB ? a : b;
    });
    return station;
  }

  const drag = new DragManager(context.app.stage, context.world);
  drag.events.on({
    end: () => {
      const { from, to } = drag.drag;
      const stationA = getStationClosestTo(from.x, from.y);
      const stationB = getStationClosestTo(to.x, to.y);
      if (stationA == stationB) {
        return;
      }
      const existingConnections = getExistingConnectionsBetweenStations.call(
        connections,
        stationA.index,
        stationB.index
      );
      if (existingConnections.length) {
        for (const link of existingConnections) {
          connections.splice(connections.indexOf(link), 1);
        }
      } else {
        connections.push([stationA.index, stationB.index]);

        const distance = getStationsDistance(stationA, stationB);
        toast(`${stationA.name} - ${stationB.name} (${distance})`);
      }

      ev.emit("change");
    },
  });

  ev.on({ change: () => (drag.enabled = selectedRegion == 0) });

  return gg;
}

function regTool(stationsData: StationData[], connections: Connection[]) {
  const context = GameSingletons.getGameContext();
  for (const n of range.fromToIncluding(0, 5)) {
    onKeyPress(n.toString(), () => {
      selectedRegion = n as RegionNumber;
      ev.emit("change");
    });
  }

  const { ticker } = context;
  const interaction = context.app.renderer.plugins.interaction as InteractionManager;

  const gg = new Graphics();
  gg.name = `world-debug // pen`;

  const drag = new DragManager(context.app.stage, context.world);
  drag.events.on({
    end: () => {
      const selectionBounds = drag.getRectAbsolute();
      const del = interaction.mouse.originalEvent.altKey;

      for (const station of stationsData) {
        if (selectionBounds.contains(station.x, station.y)) {
          station.region = del ? 0 : selectedRegion;
        }
      }

      ev.emit("change");
    },
  });

  ticker.add(() => {
    gg.clear();
    if (drag.drag.active) {
      const { from, to } = drag.drag;
      gg.lineStyle(80, 0xffffff, 0.5);
      gg.beginFill(0xffffff, 0.15);
      gg.drawRect(from.x, from.y, to.x - from.x, to.y - from.y);
      gg.endFill();
    }
  });

  ev.on({ change: () => (drag.enabled = selectedRegion > 0) });

  return gg;
}

//// ///// /////

export async function editStationPlacement() {
  const context = GameSingletons.getGameContext();
  const parent: Container = context.world;
  context.world.zoomLayers.operationsLayer.removeChildren();
  context.world.zoomLayers.regionsLayer.removeChildren();

  const { stationsData, connections } = getStationsData();

  const links = drawConnections(stationsData, connections);
  const dots = addStationDots(stationsData);
  const pen = penTool(stationsData, connections);
  const reg = regTool(stationsData, connections);
  parent.addChild(links, dots, pen, reg);

  ev.addListener("change", () => {
    const a = dots.stations.map(
      o => [Math.round(o.x), Math.round(o.y), o.data.name, o.data.level, [], o.data.region] as ShortDataItem
    );
    for (const [stationIndexA, stationIndexB] of connections) {
      a[stationIndexA][4].push(stationIndexB);
      a[stationIndexB][4].push(stationIndexA);
    }
    location.hash = JSON.stringify(a);
  });

  ev.emit("change");

  onKeyPress(" ", () => {
    const data = getCurrentUrlHash();
    navigator.clipboard.writeText(data);
  });

  onKeyPress("e", () => {
    try {
      const hash = getCurrentUrlHash();
      const data = JSON.parse(hash) as ShortDataItem[];
      const firstId = parseInt(prompt("First station id", "1") ?? "0") || 0;
      const getId = (index: number) => "" + (firstId + index);

      const stationRowsForTheContractTable = data.map<WAX.StationData_RRCentury>(
        ([, , , rarityLevel, connections, region], indexA) => {
          return {
            station_id: getId(indexA) as WAX.StationAssetId,
            multiplier: 1 as WAX.integer,
            region: `region_${region}` as WAX.RegionName,
            rarity: Rarity.fromNumberFromOne(rarityLevel) as WAX.AssetRarity,
            type_rates: [],
            connected_stations: connections.map(indexB => {
              return {
                station_id: getId(indexA) as WAX.StationAssetId,
                distance: getStationsDistance(stationsData[indexB], stationsData[indexA]) as WAX.integer,
              };
            }),
          };
        }
      );

      const stationAssetsMetadata = data.map<WAX.StationAssetData>(([x, y, name, rarityLevel, , region], indexA) => {
        return {
          asset_id: getId(indexA) as WAX.StationAssetId,
          owner: "centurytrain" as WAX.AccountName,

          name: name as WAX.StationAssetData.Name,
          station_name: name as WAX.StationAssetData.Name,

          rarity: Rarity.fromNumberFromOne(rarityLevel) as WAX.AssetRarity,
          region: `region_${region ?? 0}` as WAX.RegionName,
          region_id: 1,
          desc: "",
          billboard: "",
        };
      });

      const jsonDataForTheClientApp = data.map<ExtraData.StationEntity>(([x, y], indexA) => {
        return {
          assetId: getId(indexA) as WAX.StationAssetId,
          x,
          y,
        };
      });

      const stats = {
        stationsByConnectionCount: range(10).reduce((a, c) => ({ ...a, [c]: 0 }), {} as Record<number, number>),
        // connectionsByDistance: range(10).reduce((a, c) => ({ ...a, [c]: 0 }), {} as Record<number, number>),
      };
      for (const o of data) {
        stats.stationsByConnectionCount[o[4].length]++;
      }
      // for (const link of connections) {
      //   const dist = Math.floor(.1 * getStationsDistance(stationsData[link[0]], stationsData[link[1]]));
      //   stats.connectionsByDistance[dist]++;
      // }

      const output = {
        stationRowsForTheContractTable,
        stationAssetsMetadata,
        jsonDataForTheClientApp,
        urlHashData: data,
        stats,
      };
      const outputJson = JSON.stringify(output, null, 2);
      console.warn(output);
      navigator.clipboard.writeText(outputJson);
    } catch (e) {
      alert("Error:\n:" + e);
    }
  });

  function screenshot(obj: DisplayObject, fileName: string) {
    context.app.renderer.plugins.extract.canvas(obj).toBlob((blob: any) => {
      var a = document.createElement("a");
      document.body.append(a);
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.click();
      a.remove();
    });
  }
  onKeyPress("p", e => screenshot(context.app.stage, `capture`));

  __window__.editor = { connections, stationsData, dots, links, pen };
}

class DragManager {
  public enabled = true;

  public readonly events = new EventBus<{
    start: (drag: any) => unknown;
    move: (drag: any) => unknown;
    end: (drag: any) => unknown;
  }>();

  public readonly drag = {
    active: false,
    from: new Point(),
    to: new Point(),
  };

  public getRectAbsolute() {
    const x = Math.min(this.drag.from.x, this.drag.to.x);
    const y = Math.min(this.drag.from.y, this.drag.to.y);
    const w = Math.abs(this.drag.from.x - this.drag.to.x);
    const h = Math.abs(this.drag.from.y - this.drag.to.y);
    return new Rectangle(x, y, w, h);
  }

  constructor(private readonly target: DisplayObject, b: DisplayObject) {
    target.interactive = true;

    const { drag, events } = this;

    const activeMouseButton = 2;
    const eventPrefix = "pointer";
    const eventPrefix2 = "pointer";

    const onDown = (e: InteractionEvent) => {
      if (!this.enabled) {
        return;
      }
      if (e.data.button !== activeMouseButton) {
        return;
      }

      target.buttonMode = true;

      events.emit("start", drag);

      e.data.getLocalPosition(b, drag.from);
      drag.to.copyFrom(drag.from);
      drag.active = true;

      const onMove = (e: InteractionEvent) => {
        e.data.getLocalPosition(b, drag.to);
        events.emit("move", drag);
      };
      target.addListener(eventPrefix + "move", onMove);

      const onUp = () => {
        events.emit("end", drag);
        cleanUp();
        target.buttonMode = false;
        drag.active = false;
      };
      document.addEventListener(eventPrefix2 + "up", onUp);
      document.addEventListener(eventPrefix2 + "out", onUp);
      document.addEventListener(eventPrefix2 + "cancel", onUp);

      // const unspy = spy.json(() => drag);

      const cleanUp = () => {
        target.removeListener(eventPrefix + "move", onMove);
        document.removeEventListener(eventPrefix2 + "up", onUp);
        document.removeEventListener(eventPrefix2 + "out", onUp);
        document.removeEventListener(eventPrefix2 + "cancel", onUp);
      };
    };

    target.addListener(eventPrefix + "down", onDown);
  }
}
