import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { TextureId } from "@game/constants/paths/TextureId";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { ITextStyle, Text } from "@pixi/text";
import { clamp, lerp } from "@sdk/utils/math";
import { createValueAnimator_OutInViaTimeline } from "./common/createValueAnimator_OutInViaTimeline";

export module ScreenTitle {
  export const TITLE_ACCENT_COLOR_DEFAULT = 0x9f6d40;
  export const UNDERTITLE_ACCENT_COLOR_DEFAULT = 0x9f6d40;

  export const TITLE_ACCENT_COLOR_NEXT_STOP_CLASSIC = 0xdd4e4;
  export const UNDERTITLE_ACCENT_COLOR_NEXT_STOP_CLASSIC = 0xdd4e4;

  export const TITLE_ACCENT_COLOR_INSTANT_TRANSMISSION = 0xf37121;
  export const UNDERTITLE_ACCENT_COLOR_INSTANT_TRANSMISSION = 0xffb45b;
}

type ScreenTitleState = {
  titleText: string;
  titleAccentColor?: number;
  underTitleText?: string;
  underTitleAccentColor?: number;
};

export function createScreenTitleVisual() {
  const { app, events, viewSize, assets } = GameSingletons.getGameContext();

  const paddingX = 30;
  const paddingY = 10;

  const container = new Container();
  const padTexture = assets.getTexture(TextureId.newMeshPad);
  const padColorRingTexture = assets.getTexture(TextureId.newMeshPadColorRing);
  const nextStopTypeTexture = assets.getTexture(TextureId.newMeshPadTypeLabelPad);
  const pad = new Sprite(padTexture);
  const padColorRing = new Sprite(padColorRingTexture);
  const extraLabelPad = new Sprite(nextStopTypeTexture);

  pad.zIndex = 99;

  pad.name = "Screen Title";

  const labelStyle = {
    fontSize: 25,
    fill: "#FFFFFF",
    stroke: "#080808",
    strokeThickness: 3,
    fontFamily: FontFamily.Default,

    dropShadow: true,
    dropShadowAlpha: 0.65,
    dropShadowColor: "#000000",
    dropShadowBlur: 1,
    dropShadowAngle: Math.PI / 2,
    dropShadowDistance: 4,
  } as Partial<ITextStyle>;
  const label = new Text("", labelStyle);

  const extraLabelStyle = {
    fontSize: 18,
    fill: "#FFFFFF",
    stroke: "#080808",
    strokeThickness: 1.5,
    fontFamily: FontFamily.Default,

    dropShadow: true,
    dropShadowAlpha: 0.65,
    dropShadowColor: "#000000",
    dropShadowBlur: 1,
    dropShadowAngle: Math.PI / 2,
    dropShadowDistance: 2,
  } as Partial<ITextStyle>;
  const extraLabel = new Text("", extraLabelStyle);

  label.pivot.y -= 0.5 * labelStyle.dropShadowDistance! * Math.sin(labelStyle.dropShadowAngle!);
  label.anchor.set(0.5);

  label.x = 0.5 * pad.width + 2;
  label.y = 0.4625 * pad.height;

  padColorRing.x += 27;
  padColorRing.y += 15;
  padColorRing.tint = 0x9f6d40;

  extraLabelPad.tint = 0x1e4164;
  extraLabel.anchor.set(0.5);

  padColorRing.width = pad.width - paddingX - 16;
  padColorRing.height = pad.height - 38;

  extraLabelPad.anchor.set(0.5);
  extraLabelPad.position.set(pad.x + pad.width * 0.5, pad.y + pad.height * 0.95);
  extraLabelPad.zIndex = 95;
  extraLabelPad.x = pad.x + pad.width * 0.5;
  extraLabelPad.y = pad.y + pad.height * 0.95;

  extraLabelPad.addChild(extraLabel);
  pad.addChild(padColorRing, label);
  container.addChild(extraLabelPad, pad);

  container.pivot.x = pad.width * 0.5;

  const updateSizeAndPosition = function () {
    container.scale.set(viewSize.vmin / 1536);
    container.x = 0.5 * viewSize.width;
    container.y = 30;
  };
  events.on({ resize: updateSizeAndPosition });
  updateSizeAndPosition();

  const ani = createValueAnimator_OutInViaTimeline(
    {
      howToApplyValue(state: ScreenTitleState | null) {
        if (state == null) {
          state = { titleText: "" };
        }

        const { titleText, titleAccentColor, underTitleText, underTitleAccentColor } = state;

        label.text = titleText.trim().toUpperCase();
        label.updateText(false);
        label.style.fontSize = 24 * lerp(1, clamp(240 / label.texture.width, 0.5, 1.5), 0.5);

        padColorRing.tint = titleAccentColor ?? ScreenTitle.TITLE_ACCENT_COLOR_DEFAULT;

        extraLabelPad.visible = underTitleText != null;

        if (underTitleText == null) return;
        extraLabel.text = underTitleText.trim().toUpperCase();
        extraLabelPad.tint = underTitleAccentColor ?? ScreenTitle.UNDERTITLE_ACCENT_COLOR_DEFAULT;

        updateSizeAndPosition();
      },
      howToShow(tl) {
        tl.to(container, {
          pixi: { alpha: 1, pivotY: 0 },
          duration: 0.4,
          ease: "back.out",
        });
      },
      howToHide(tl) {
        tl.to(container, {
          pixi: { alpha: 0.0, pivotY: 95 },
          duration: 0.15,
          ease: "power2.in",
        });
      },
    },
    null
  );

  const service = {
    component: container,
    /**
     * Actual title text, as it should be,
     * even if the label is currently showing different,
     * because of ongoing animation or something.
     */
    get currentText() {
      return ani.getCurrentValue();
    },

    setState(state: ScreenTitleState | null) {
      ani.setValue(state);
    },
  };

  return service;
}

export type ScreenTitle = ReturnType<typeof createScreenTitleVisual>;
