const glsl = (x: TemplateStringsArray) => x.join("");
export const vertexSrc = glsl`

precision mediump float;

attribute vec2 aVert;
attribute vec2 aUvs;

uniform mat3 translationMatrix;
uniform mat3 projectionMatrix;

varying vec2 vUvs;

void main() {
  vUvs = aUvs;
  gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVert, 1.0)).xy, 0.0, 1.0);
}

`;
