import { __DEBUG__ } from "@debug";
import { combineOttoSlidesBasedOnFriendLevel as combineOttoSlidesBasedOnEncountersCount } from "@debug/experiments/__AssetEncounters";
import { GameSingletons } from "@game/app/GameSingletons";
import { __urlParams__ } from "@game/app/__urlParams__";
import { loadCinematicDataByName, loadCinematicsList } from "@game/cinematics/airtable/loadAirtableStuff";
import { loadResourcesFromManifest } from "@game/cinematics/airtable/loadResourcesFromManifest";
import { NPCEncounterCinematic_FromAirtable } from "@game/cinematics/airtable/NPCEncounterCinematic_FromAirtable";
import { NPCEncounterCinematic_Culprit } from "@game/cinematics/NPCEncounterCinematic_Culprit";
import { NPCEncounterCinematic_Mechanic } from "@game/cinematics/NPCEncounterCinematic_Mechanic";
import { NPCEncounterCinematic_MysteriousStranger } from "@game/cinematics/NPCEncounterCinematic_MysteriousStranger";
import { NPCEncounterCinematic_Otto } from "@game/cinematics/NPCEncounterCinematic_Otto";
import { NPCEncounterCinematic_Thomas } from "@game/cinematics/NPCEncounterCinematic_Thomas";
import { presentUserDialogueChoiceOptions } from "@game/cinematics/story/presentUserDialogueChoiceOptions";
import { NPCEncounterCinematicData } from "@game/cinematics/types/NPCEncounterCinematicData";
import { NPCEncounterCinematicID } from "@game/cinematics/types/NPCEncounterCinematicID";
import { dynamicChoiceActionFunctions } from "@game/cinematics/utils/dynamicChoiceActionFunctions";
import {
  dynamicSpecialEffectFunctions,
  SUPER_QUICK_AND_DIRTY_GLOBAL_PARAMS_STORE,
} from "@game/cinematics/utils/dynamicSpecialEffectFunctions";
import { createDynamicStoryTextService } from "@game/cinematics/utils/dynamicStoryContentFunctions";
import { RailRunEntity } from "@game/data/entities/RailRunEntity";
import { displayUnreadAchievementModals } from "@game/ui/social/achievements/displayUnreadAchievementModals";
import { setOttoFriendshipLevel } from "@game/ui/social/achievements/ottoAchievements";
import { SocialProfileDataService } from "@game/ui/social/SocialProfileDataService";
import { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";

export async function claimRailRunRewards(run: ReadonlyObjectDeep<RailRunEntity>) {
  const context = GameSingletons.getGameContext();
  const { mapData, userData, userDataCtrl, contracts, spinner, modals, firebase } = context;

  const station = mapData.stations.get(run.destination);
  if (!station) {
    throw new Error(`Station ${run.destination} not found`);
  }
  await modals.claimRailRunTocium(run.trainName, station.name);

  const results = await spinner.showDuring(
    contracts.claimRailRunRewards(run.trainName, station.assetId),
    "Claiming Rail Run rewards"
  );

  console.log(`🌟`, results);

  async function potentiallyCreateNPCEncounterCinematic(cinematicCharacter: string | undefined) {
    if (!cinematicCharacter) {
      return null;
    }

    try {
      const getCinematicsData = spinner.bindSpinner(firebase.getCinematicsData);

      let cinematicDataKey: string | Promise<any>;
      let cinematicData: NPCEncounterCinematicData;

      if (cinematicCharacter != NPCEncounterCinematicID.Otto) {
        cinematicDataKey = "asset_encounter_" + cinematicCharacter;
        cinematicData = await getCinematicsData(cinematicDataKey as any);
      } else {
        const currentEncountersCount = await getOttoEncountersCount();
        const newEncountersCount = currentEncountersCount + 1;
        SUPER_QUICK_AND_DIRTY_GLOBAL_PARAMS_STORE.ottoEncountersCount = newEncountersCount;
        setOttoEncountersCount(newEncountersCount);

        console.log(`🌟 Otto encounters count: ${currentEncountersCount} -> ${newEncountersCount}`);

        const rewardTicker = results.npcEncounter!.reward.type;
        cinematicData = await combineOttoSlidesBasedOnEncountersCount(newEncountersCount, rewardTicker);
      }

      const train = userData.trains.get(run.trainName)!;
      const airtableCinematicsList = await loadCinematicsList();

      function buildCinematicContainer() {
        if (cinematicCharacter === NPCEncounterCinematicID.MysteriousStanger) {
          return new NPCEncounterCinematic_MysteriousStranger(cinematicData, () => contracts.claimSpecialRewards());
        }

        if (cinematicCharacter === NPCEncounterCinematicID.Otto) {
          return new NPCEncounterCinematic_Otto(cinematicData, async () => void 0);
        }

        if (cinematicCharacter === NPCEncounterCinematicID.Thomas) {
          return new NPCEncounterCinematic_Thomas(cinematicData, async () => void 0);
        }

        if (cinematicCharacter === NPCEncounterCinematicID.Culprit) {
          return new NPCEncounterCinematic_Culprit(cinematicData, async () => void 0);
        }

        if (cinematicCharacter === NPCEncounterCinematicID.Mechanic) {
          return new NPCEncounterCinematic_Mechanic(cinematicData, async () => void 0, train);
        }

        for (const { name, manifest, cinematicData } of airtableCinematicsList) {
          if (name.toLocaleLowerCase() === cinematicCharacter!.toLowerCase()) {
            console.log(`🌟 Found cinematic data for ${cinematicCharacter}`);
            return new NPCEncounterCinematic_FromAirtable(cinematicData, manifest);
          }
        }

        throw new Error(`Unknown NPCEncounterCinematicID: ${cinematicCharacter}`);
      }

      const cinematic = buildCinematicContainer();
      cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
      cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
      cinematic.dynamicSpecialEffectsService = dynamicSpecialEffectFunctions();
      cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
      cinematic.loadAssets();
      context.stageContainers._cinematic.addChild(cinematic);

      return cinematic;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  if (__DEBUG__ && !results.npcEncounter?.character && __urlParams__.encounter) {
    results.npcEncounter = { character : __urlParams__.encounter as string } as any;
  }

  console.log(`----`, __DEBUG__, results.npcEncounter, !results.npcEncounter?.character, __urlParams__.encounter);

  const cinematicPromise = potentiallyCreateNPCEncounterCinematic(results.npcEncounter?.character);

  //// Start the data update async task, so that it can
  //// run in the background while the user is viewing the
  //// rail run report modal.
  const updateDataPromise = userDataCtrl.updateAll();
  await modals.railRunReport({
    ...run,
    reward: results.reward.amount,
  });
  //// After the modal is closed, if the data hasn't finished
  //// updating, then wait for that to finish and show a
  //// proper loading spinner in the meantime.
  await spinner.showDuring(updateDataPromise, "Updating data after Rail Run report...");
  const cinematic = await cinematicPromise;
  try {
    if (cinematic) {
      const train = userDataCtrl.userData.trains.get(run.trainName)!;
      context.HACKS.__CONDUCTOR_NAME = train.conductorStats?.name;
      await cinematic.play(results.npcEncounter!.reward);
    }
  } catch (error) {
    console.error(error);
  } finally {
    console.log("🌟 Cinematic finished");
    const dataService = new SocialProfileDataService();
    const achievements = await dataService.getAchievements(userData.name);
    await displayUnreadAchievementModals(achievements);
    console.log("🌟 Unread achievements displayed");
  }

  if (results.passengerTips) {
    await modals.claimPassengerTips(results.passengerTips);
  }

  userDataCtrl.updateAll();
}

export async function getOttoEncountersCount() {
  const { firebase } = GameSingletons.getGameContext();
  const userData = await firebase.getUserMainData();
  const encountersCount = userData.encountersCount_Otto || 0;
  return encountersCount;
}

export async function setOttoEncountersCount(value: number) {
  const { firebase } = GameSingletons.getGameContext();
  const friendshipLevel = convertEncountersCountToFriendshipLevel(value);
  await Promise.all([
    firebase.updateUserMainData({ encountersCount_Otto: value }),
    setOttoFriendshipLevel(friendshipLevel),
  ]);
}

function convertEncountersCountToFriendshipLevel(encountersCount: number) {
  if (encountersCount >= 19) return 5;
  if (encountersCount >= 18) return 4;
  if (encountersCount >= 10) return 3;
  if (encountersCount >= 5) return 2;
  if (encountersCount >= 1) return 1;
  return 0;
}
