import type * as WAX from "@sdk-integration/contracts";

import { CardEntity } from "@game/data/entities/CardEntity";
import { loadPixiAssets } from "@sdk/pixi/loadPixiAssets";
import { deferredPromise } from "@sdk/utils/promises";
import { TextureCache } from "@pixi/utils";
import { Texture } from "@pixi/core";
import { CARD_ASSET_IPFS_HASH_TO_HUMANNAME } from "@game/cards/CARD_ASSET_IMG_HASH_TO_HUMANNAME";
import { CARD_HUMANNAME_TO_HASHED_FILE_PATH } from "@game/cards/CARD_HUMANNAME_TO_HASHED_FILE_PATH";
import { EnchantmentGlobals } from "@sdk/pixi/enchant/EnchantmentGlobals";

//// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
//// PLACEHOLDER TEXTURES
//// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

type PartialCardData = Pick<CardEntity, "rarity" | "type">;

export const EMPTY_CARD_TEXTURE_PATHS = [
  "assets/images-webp/empty-cards/1-common-cond.webp",
  "assets/images-webp/empty-cards/1-common-loco.webp",
  "assets/images-webp/empty-cards/1-common-rc.webp",
  "assets/images-webp/empty-cards/2-uncommon-cond.webp",
  "assets/images-webp/empty-cards/2-uncommon-loco.webp",
  "assets/images-webp/empty-cards/2-uncommon-rc.webp",
  "assets/images-webp/empty-cards/3-rare-cond.webp",
  "assets/images-webp/empty-cards/3-rare-loco.webp",
  "assets/images-webp/empty-cards/3-rare-rc.webp",
  "assets/images-webp/empty-cards/4-epic-cond.webp",
  "assets/images-webp/empty-cards/4-epic-loco.webp",
  "assets/images-webp/empty-cards/4-epic-rc.webp",
  "assets/images-webp/empty-cards/5-legendary-cond.webp",
  "assets/images-webp/empty-cards/5-legendary-loco.webp",
  "assets/images-webp/empty-cards/5-legendary-rc.webp",
  "assets/images-webp/empty-cards/6-mythic-cond.webp",
  "assets/images-webp/empty-cards/6-mythic-loco.webp",
  "assets/images-webp/empty-cards/6-mythic-rc.webp",
];

export function getPlaceholderCardSpriteTextureId({ rarity, type }: PartialCardData): string {
  const rarityIndex = +rarity - 1 || 0;
  const typeIndex = Math.min(+type, 2);
  const assetIndex = rarityIndex * 3 + typeIndex;
  return EMPTY_CARD_TEXTURE_PATHS[assetIndex];
}

export function getPlaceholderTexture(data: PartialCardData | null) {
  if (data == null) {
    data = { rarity: 1, type: 0 };
  }
  return Texture.from(getPlaceholderCardSpriteTextureId(data));
}

//// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
//// PROPER TEXTURES
//// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

export const CARD_TEXTURE_URL_BASE_WEBP = "https://train-of-the-century-media.web.app/cards-webp/";

export function getProperTextureURL(data: CardEntity | string) {
  if (data == undefined) {
    throw new Error("data is undefined");
  }

  if (data instanceof CardEntity) {
    if (data.__texturePathOverride) {
      return data.__texturePathOverride;
    } else {
      if (data.imageIPFSHash == undefined) {
        throw new Error(`Card ${data.assetId} has data, but its 'imageIPFSHash' is ${data.imageIPFSHash}`);
      }
      data = data.imageIPFSHash;
    }
  }
  const humanReadableTextureName = CARD_ASSET_IPFS_HASH_TO_HUMANNAME[data];
  const realPath = CARD_HUMANNAME_TO_HASHED_FILE_PATH[humanReadableTextureName];
  if (realPath == undefined) {
    throw new Error(`No texture found for ${data}`);
  }

  const base = CARD_TEXTURE_URL_BASE_WEBP;
  const extension = ".webp";
  return base + realPath + extension;
}

export type CardImageIPFSHash = WAX.CardAssetData.AssetImagePath;
export const cardTextureCache = new Map<CardImageIPFSHash, Texture>();
export const loadingCache = new Map<CardImageIPFSHash, Promise<Texture>>();

export function getProperTextureFromCache(cardImageId: CardImageIPFSHash): Texture | undefined {
  return cardTextureCache.get(cardImageId);
}

export async function getProperTexture(cardImageId: CardImageIPFSHash): Promise<Texture> {
  async function load(map: Map<CardImageIPFSHash, Texture>, textureURL: string) {
    const { promise, resolve } = deferredPromise<Texture>();
    loadingCache.set(cardImageId, promise);

    const { resources } = await loadPixiAssets([textureURL]);
    const texture = resources[textureURL].texture;
    if (!texture) {
      throw new Error(`Could not load texture from ${textureURL}`);
    }
    fixCardTextureFrameAndResolution(texture);

    map.set(cardImageId, texture);
    !TextureCache[textureURL] && Texture.addToCache(texture, textureURL);

    resolve(texture);
    loadingCache.delete(cardImageId);

    return texture;
  }

  const map = cardTextureCache;
  if (map.has(cardImageId)) {
    // console.log(`Already loaded ${cardImageId}.`);
    return map.get(cardImageId)!;
  }

  const loadingPromise = loadingCache.get(cardImageId);
  if (loadingPromise) {
    // console.log(`Waiting for ${cardImageId} to load...`);
    return await loadingPromise;
  }

  console.log(`Loading ${cardImageId}... (frame ${EnchantmentGlobals.framesTotal})`);
  return await load(map, getProperTextureURL(cardImageId));
}

function fixCardTextureFrameAndResolution(
  texture: Texture<any> & {
    baseTexture: Texture["baseTexture"] & { __fixed?: boolean };
  }
) {
  if (!texture.baseTexture.__fixed) {
    const [frameWidth, frameHeight] = [512, 717];

    texture.baseTexture.setResolution(texture.baseTexture.width / frameWidth);
    texture.baseTexture.update();

    texture.frame.x = 0.5 * (texture.baseTexture.width - frameWidth);
    texture.frame.y = 0.5 * (texture.baseTexture.height - frameHeight);
    texture.frame.width = frameWidth;
    texture.frame.height = frameHeight;
    texture.noFrame = false;
    texture.updateUvs();

    texture.baseTexture.__fixed = true;
  }

  return texture;
}

Object.assign(window, {
  CARDS: { cardTextureCache, loadingCache },
});
