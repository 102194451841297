import { Container } from "@pixi/display";
import { EventBannersSlideData } from "../../railroader-dash/banner/EventBannersGalleryData";
import { ReadMoreModalDataScrollbox } from "./ReadMoreModalDataScrollbox";
import { UpdatesModalDataRowsScrollbox } from "./UpdateModalDataRowsScrollbox";

export function populateUpdateTypeModal(
  container: Container,
  readMoreBoolean: boolean,
  dataRow?: EventBannersSlideData
) {
  let dataScrollbox: UpdatesModalDataRowsScrollbox | ReadMoreModalDataScrollbox;

  if (!readMoreBoolean) {
    dataScrollbox = new UpdatesModalDataRowsScrollbox();
    dataScrollbox.initialize();
    dataScrollbox.position.set(-315, -190);
  } else {
    if (!dataRow) throw new Error("AirTable Data not found");
    dataScrollbox = new ReadMoreModalDataScrollbox(dataRow);
    dataScrollbox.initialize();
    dataScrollbox.position.set(-315, -190);
  }

  container.addChild(dataScrollbox);
}
