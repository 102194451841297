import { Texture } from "@pixi/core";
import { AirtableDataService } from "@sdk-integration/airtable/AirtableDataService";

export type EventBannersSlideData = {
  title: string;
  summary: string;
  footer?: string;
  textureId: string;
  altFooter: string;
  articleText: string | undefined;
  function?: () => void;
};

//// Since the data isn't likely to change during a single session
//// we can cache it here until the game is restarted.
let adaptedData: null | EventBannersSlideData[] = null;

export async function getEventBannersGalleryData() {
  if (!adaptedData) adaptedData = await prepareEventBannersGalleryData();
  return adaptedData || null;
}

export async function prepareEventBannersGalleryData() {
  const dataService = new AirtableDataService();
  adaptedData = await dataService.getRRDashEventBannersGalleryData();

  //// Make sure this promise doesn't resolve until the textures
  //// for the banners have also either been loaded or have failed.
  for (const slide of adaptedData || []) {
    await Texture.fromURL(slide.textureId).catch(console.warn);
  }

  return adaptedData || null;
}
