import { GameSingletons } from "@game/app/GameSingletons";
import { formatTimeDurationHumanReadable } from "@game/asorted/formatTimeDurationHumanReadable";
import { FontIcon } from "@game/constants/FontIcon";
import { ThemeColors } from "@game/constants/ThemeColors";
import { Container } from "@pixi/display";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";
import { ModPlateContent } from "./ModPlateContent";

export type ModInfo = {
  templateId: number;
  name: string;
  effect: string;
  description: string;
  minMint: number;
  burnt: number | string;
  price: number;
  nftURL: string;
};

export class ModPlate extends Container {
  private readonly assets = GameSingletons.getResources();
  private readonly simpleFactory = GameSingletons.getSimpleObjectFactory();

  private infoPanel: ModPlateContent = new ModPlateContent();
  private clicked: boolean = false;
  private info: ModInfo = {
    templateId: 1,
    name: "",
    effect: "",
    description: "",
    minMint: 0,
    burnt: 0,
    price: 0,
    nftURL: "",
  };

  public onPurchaseClick: () => void = () => {};

  constructor() {
    super();

    this.infoPanel.position.set(20, -240);
    this.addChild(this.infoPanel);
  }

  private onInfoClick() {
    if (!this.clicked) {
      this.infoPanel.showLastChild(false);
      this.infoPanel.setInfo(this.info);
    } else {
      this.infoPanel.clearLastChild();
      this.infoPanel.showLastChild(true);
    }
  }

  addBackground() {
    //// Add background
    const bg = this.simpleFactory.createSprite("ui-market-window-emporium/sell/base.png");
    this.addChild(bg);
    this.setChildIndex(bg, 0);
  }

  addCostPlaque(cost: string, muted: boolean = false) {
    //// Add plaque
    const plaque = this.simpleFactory.createSprite("ui-market-window-emporium/buy/item-assets/tocium-cost.png", {
      x: 174.735,
      y: 375,
    });
    plaque.anchor.set(0.5);
    this.addChild(plaque);

    //// Add price
    const price = this.simpleFactory.createText(`${FontIcon.Tocium} ` + cost, {}, { x: 0, y: 0 });
    price.anchor.set(0.5);
    plaque.addChild(price);

    if (muted) {
      plaque.tint = 0x909090;
      price.tint = 0x909090;
    }

    return plaque;
  }

  private addButton(
    btnTexture: string,
    btnX: number,
    btnY: number,
    calledFunction: () => void,
    labelName: string,
    clickedTexture: string,
    clickedLabelColor: number = 0xffffff,
    isToggle: boolean = false
  ) {
    //// Create button
    const button = this.simpleFactory.createSprite(btnTexture, { x: btnX, y: btnY });
    this.addChild(button);
    button.interactive = true;
    button.buttonMode = true;

    //// Create label
    const label = this.simpleFactory.createText(labelName.toUpperCase(), { fontSize: 24 }, { x: 78.5, y: 40 });
    label.anchor.set(0.5);
    button.addChild(label);
    this.addChild(button);

    //// Add button events and texture switch
    buttonizeDisplayObject(button, () => {
      calledFunction();

      if (!isToggle) return;

      if (this.clicked) {
        button.texture = this.assets.getTexture(btnTexture);
        label.tint = 0xffffff;
      } else {
        button.texture = this.assets.getTexture(clickedTexture);
        label.tint = clickedLabelColor;
      }
      this.clicked = !this.clicked;
    });
    return button;
  }

  addInfoButton(infoData: ModInfo) {
    this.info = infoData;
    return this.addButton(
      "ui-market-window-emporium/buy/item-assets/btn-l.png",
      17.5,
      265,
      () => this.onInfoClick(),
      "info",
      "ui-market-window-emporium/buy/item-assets/btn-clicked.png",
      ThemeColors.HIGHLIGHT_COLOR.toInt(),
      true
    );
  }

  addBuyButton(onClick: () => void) {
    return this.addButton(
      "ui-market-window-emporium/buy/item-assets/btn-r.png",
      175,
      265,
      onClick,
      "buy",
      "ui-market-window-emporium/buy/item-assets/btn-r.png"
    );
  }

  addInactiveButton() {
    return this.addButton(
      "ui-market-window-emporium/buy/item-assets/btn-r.png",
      175,
      265,
      () => void 0,
      FontIcon.Duration,
      "ui-market-window-emporium/buy/item-assets/btn-r.png"
    );
  }

  addNftImage(textureUrl: string) {
    this.infoPanel.setNftImage(textureUrl);
  }
}

export module ModPlate {
  export function createPurchaseableItem(itemData: ModInfo, onPurchaseClick: () => unknown) {
    const marketData = GameSingletons.getGameContext().gameConfigData.runModsMarketData.get(itemData.templateId);

    const itemPanel: ModPlate = new ModPlate();
    itemPanel.addBackground();

    itemPanel.addInfoButton(itemData);

    if (marketData?.isSaleActive()) {
      itemPanel.addBuyButton(onPurchaseClick);
      itemPanel.addCostPlaque(formatToMaxDecimals(itemData.price, 4, true).toUpperCase());
    } else {
      const btn = itemPanel.addInactiveButton();
      const plaque = itemPanel.addCostPlaque("INACTIVE", true);

      let hintText = `This item is not available for purchase at this time.`;
      const nextSaleStart = marketData?.getNextSaleStartSeconds();
      if (nextSaleStart) {
        const now = Date.now() / 1000;
        const seconds = nextSaleStart - now;
        hintText += `\nCome back in ${formatTimeDurationHumanReadable(seconds)}`;
      }
      GameSingletons.getGameContext().tooltips.registerTarget(plaque, hintText);
      GameSingletons.getGameContext().tooltips.registerTarget(btn, hintText);
    }

    itemPanel.addNftImage(itemData.nftURL);

    return itemPanel;
  }
}
