import {StakingAddonType} from "@game/asorted/StakingType";
import {RailRunEntity} from "@game/data/entities/RailRunEntity";
import {StationEntity} from "@game/data/entities/StationEntity";
import {TrainEntity} from "@game/data/entities/TrainEntity";
import {checkForStoryProgress} from "@game/gameplay/checkForStoryProgress";
import {claimRailRunRewards} from "@game/gameplay/claimRailRunRewards";
import {resolveMinigameMoment} from "@game/minigames/resolveMinigameMoment";
import {startNewRailRun} from "@game/railruns/startNewRailRun";
import {openStationContextMenu} from "@game/ui/asorted/openStationContextMenu";
import {ScreenTitle} from "@game/ui/createScreenTitleVisual";
import {HUD} from "@game/ui/hud/HUD";
import {createUserPreferencesFirebaseStorageProxy} from "@game/ui/railroader-dash/panels/settings/createUserPreferencesFirebaseStorageProxy";
import {CogWheelMenuMode} from "@game/ui/wheel/CogWheelMenu";
import {WorldZoomLevel} from "@game/world/World";
import {ReadonlyObjectDeep} from "type-fest/source/readonly-deep";
import {GameSingletons} from "./GameSingletons";
import {GameViewMode} from "./main";
import {Container} from "@pixi/display";
import {testTheModals} from "@debug/experiments/testTheModals";
import {BuildingEntity} from "@game/data/entities/BuildingEntity";
import {buildingPanel} from "@game/ui/buildings/building-panel";
import {bldgHouse} from "@game/ui/buildings/bldgHouse";
import {SfxManager} from "./sound/sfxManager";
import {AdjustmentFilter} from "@pixi/filter-adjustment";

/**
 * Input reactor
 */
export function handleInputEvents() {
  const context = GameSingletons.getGameContext();
  const {
    input,
    sfx,
    main,
    mapData,
    stage,
    stageContainers,
    userData,
    userDataCtrl,
    contracts,
    ticker,
    spinner,
    modals
  } = context;

  input.on({
    enterEditTrainView(train) {
      main.selection.selectedTrain = train;
      main.selection.selectedStation = main.faq.getTrainCurrentStation(train);
      main.setViewMode(GameViewMode.EDIT_TRAIN) || main.setViewMode(GameViewMode.NORMAL);
    },

    enterLoadingDockView(train) {
      main.selection.selectedTrain = train;
      main.selection.selectedStation = main.faq.getTrainCurrentStation(train);
      main.setViewMode(GameViewMode.LOADING_DOCK) || main.setViewMode(GameViewMode.NORMAL);
    },

    enterNextStopView(train) {
      main.selection.selectedTrain = train;
      main.selection.selectedStation = main.faq.getTrainCurrentStation(train);
      main.setViewMode(GameViewMode.NEXT_STOP) || main.setViewMode(GameViewMode.NORMAL);
    },

    enterDispatchView(train, destination) {
      main.selection.selectedTrain = train;
      main.selection.selectedStation = main.faq.getTrainCurrentStation(train);
      main.selection.selectedDestination = destination;
      main.setViewMode(GameViewMode.DISPATCH);
    },

    toggleInstantTransmissionView(train) {
      const nextViewMode = main.viewMode === GameViewMode.INSTANT_TRANSMISSION ? GameViewMode.NEXT_STOP : GameViewMode.INSTANT_TRANSMISSION;
      main.setViewMode(nextViewMode);
    },

    /* BUILDING */
    clickOnBuilding(building, interactionData) { // Clicking on a building that's already selected
      if (building.buildingName === main.selection.selectedBuilding ?. buildingName) {

        sfx.play("outClickLow");
        sfx.play("outClickBit");
        sfx.play("tinyGears");
        /* sfx.play("tinyGears2"); */
        sfx.play("pDown");
        input.emit("resetSelection");
        main.selection.selectedBuilding = null;
      } else {
        main.selection.selectedBuilding = building;
        sfx.play("outClickLow");
        sfx.play("onClickBit");
        sfx.play("tinyGears");
        /* sfx.play("tinyGears2"); */
        sfx.play("pUp");
        main.setViewMode(GameViewMode.BUILDING);
        switch (main.viewMode) {
          case GameViewMode.NORMAL:
            /**
           * In any case, if the user clicked with any button other than the
           * left mouse button, then there is nothing to do here
           */
            if (interactionData.button !== 0) {
              return;
            }

            /**
          Clears out other pop ups that may be open.
           */
            main.popups.clear();
            break;
        }
      }
    },


    enterBuilding(building) { /*  main.selection.selectedBuilding = building; */
      stageContainers._bldg.removeChildren();

      /* sfx.play("justGears", false, SfxManager.MultipleInstanceStrategy.IgnoreNew, 1, 0); */
      const nextViewMode = main.viewMode === GameViewMode.BUILDING ? GameViewMode.GROUND : GameViewMode.NORMAL;
      main.setViewMode(nextViewMode);

      input.emit("locateBuilding", building).then(() => {
        const groundLvl = new bldgHouse(building ?. buildingName !, building ?. buildingId !, building ?. buildingRegionId !, [])
        context.stageContainers._ground.addChild(groundLvl);
        sfx.play("woosh2");
      });


    },
    returnToMap() {
      sfx.play("clickRegular");
      sfx.play("tinyGears");
      sfx.play("wooshOut", false, SfxManager.MultipleInstanceStrategy.None, 1, 250);

      stageContainers._ground.removeChildren();
      const nextViewMode = main.viewMode === GameViewMode.GROUND ? GameViewMode.NORMAL : GameViewMode.NORMAL;
      main.selection.selectedBuilding = null;
      main.setViewMode(nextViewMode);

    },

    /* Ground Modal IMMERSED MODE */
    immersedMode() {
      main.groundModalImmersiveMode = !main.groundModalImmersiveMode
    },
    restoreHUD(buildingName) {
      main.groundModalImmersiveMode = !main.groundModalImmersiveMode
      main.setViewMode(GameViewMode.GROUND);

    },

    selectDestination(station) {
      main.selection.selectedDestination = station;
      main.popups.dispatchDestination.clear();
      main.popups.editTrainDestination.clear();

      if (main.viewMode === GameViewMode.DISPATCH && main.selection.selectedTrain) {
        main.setViewMode(GameViewMode.NEXT_STOP);
        return;
      }

      if (main.viewMode === GameViewMode.EDIT_TRAIN && main.selection.selectedTrain) {
        return;
      }

      main.setViewMode(GameViewMode.NORMAL);
    },

    selectTrain(train) {
      main.selection.selectedTrain = train;
      if (train) {
        if (train.currentStationId) {
          const station = mapData.stations.get(train.currentStationId) || null;
          main.selection.selectedStation = station;
        }
      } else {
        input.emit("resetSelection");
      }
    },

    selectStation(station) {
      main.selection.selectedTrain = null;
      if (station) {
        main.selection.selectedStation = station;
      } else {
        input.emit("resetSelection");
      }
    },

    resetSelection() {
      main.selection.clear();
      main.popups.clear();
      stageContainers._bldg.removeChildren();
      main.setViewMode(GameViewMode.NORMAL);
    },

    openStationDashboard(station) {
      input.emit("resetSelection");
      main.popups.myStationDashboard.setCurrentStation(station);
    },

    async openStakingPopup(station, addonType) {
      const {main, ticker} = context;
      const popupService = addonType === StakingAddonType.RailYard ? main.popups.addonRailYard : main.popups.addonConductorLounge;
      main.popups.clear();
      await ticker.nextFrame();
      popupService.setCurrentStation(station);
    },

    async claimRunRewards(unclaimedRun : ReadonlyObjectDeep < RailRunEntity >) {
      main.popups.clear();

      await claimRailRunRewards(unclaimedRun);

      context.HACKS.__lastSelectedTrain = userData.trains.get(unclaimedRun.trainName);
      await checkForStoryProgress();
    },
    // //////////////////////////////////////////////////////////////////////////////////////////////

    // //////////////////////////////////////////////////////////////////////////////////////////////
    async clickOnStation(station, interactionData) {
      sfx.play("clickTiny");

      if (station === main.selection.selectedStation) {
        input.emit("resetSelection");
      } else {
        switch (main.viewMode) {
          case GameViewMode.NORMAL:
            /**
             * In any case, if the user clicked with any button other than the
             * left mouse button, then there is nothing to do here
             */
            if (interactionData.button !== 0) {
              if (interactionData.button === 2) {
                openStationContextMenu(station);
              }
              return;
            }

            /**
             * Whether this was a "dismiss" click, or new popups will be
             * spawned as part of the next step in a ux flow, any previously
             * open popups should be cleared in every case.
             */
            main.popups.clear();

            /**
             * First check if there's an unclaimed rail run at this station.
             * If there is, then override any other functionality and perform
             * the claim action for that the rail run, which will include
             * confirmation popups, any potential NPC encounter cinematics,
             * and the rail run report modal.
             */
            const unclaimedRun = main.faq.getUnclaimedRailRun(station) || null;
            if (unclaimedRun) {
              return input.emit("claimRunRewards", unclaimedRun);
            }

            /**
             * Check whether this is a station currently owned by the user.
             * If so, then show the "Station Dashboard" popup.
             */
            const isMyStation = station.ownerName === userData.name;
            if (isMyStation) {
              return input.emit("openStationDashboard", station);
            }

            /**
             * The correct popup service will hear about this change and
             * show the appropriate popup.
             */
            const firstTrain = main.faq.getFirstTrainAtStation(station);
            if (firstTrain) {
              input.emit("selectTrain", firstTrain);
            } else {
              input.emit("selectStation", station);
            }

            break;

          case GameViewMode.NEXT_STOP:
            if (!main.selection.isViablelDestination(station)) {
              break;
            }

            const selectedStation = main.selection.selectedStation;
            if (! selectedStation) {
              throw new Error("No selected station");
            }

            const selectedTrain = main.selection.selectedTrain;
            if (! selectedTrain) {
              throw new Error("No selected train");
            }

            input.emit("enterDispatchView", selectedTrain, station);
            break;

          case GameViewMode.DISPATCH:
            if (main.selection.isViablelDestination(station)) {
              main.selection.selectedDestination = station;
              main.popups.dispatchDestination.setCurrentStation(station);
              break;
            }

            main.setViewMode(GameViewMode.NORMAL);
            main.selection.clear();
            break;

          case GameViewMode.EDIT_TRAIN:
          case GameViewMode.LOADING_DOCK:
            if (main.selection.selectedDestination == station) {
              main.selection.selectedDestination = null;
              main.popups.editTrainDestination.setCurrentStation(null);
            } else if (main.selection.isViablelDestination(station)) {
              main.selection.selectedDestination = station;
              main.popups.editTrainDestination.setCurrentStation(station);
            }
            break;
        }
      }
    },


    async startRun(runStats) {
      const departureStation = runStats.departureStation;
      if (! departureStation) {
        throw new Error("No starting station selected");
      }

      const destinationStation = runStats.destinationStation;
      if (! destinationStation) {
        throw new Error("No destination station selected");
      }

      const train = runStats.train;
      if (! train) {
        throw new Error("No train on starting station");
      }

      // /////////////////////////////////////////////////////////////////////////////

      const hasTrainEmptyRailcar = context.main.faq.hasTrainEmptyRailcar(train);
      if (hasTrainEmptyRailcar == true) {
        const choice = await modals.confirm({
          title: "Empty Rail Car",
          content: "It looks like one or more of your equipped rail cars is empty! If you have extra commodities, add them to make the most of your rail run!",
          acceptButtonText: "Proceed Anyway",
          cancelButtonText: "Stop Rail Run",
          cornerDetailType: null
        });
        if (! choice) 
          return;
        


      }

      main.selection.clear();

      main.setViewMode(GameViewMode.BUSY);
      main.hud.title.setState({
          titleText: `Dispatching from ${
          departureStation.name
        } to ${
          destinationStation.name
        }...`
      });
      await spinner.showDuring(startNewRailRun(train, destinationStation), `Starting new Rail Run`);
      await spinner.showDuring(userDataCtrl.updateAll(), "Updating data after starting a new Rail Run...");

      sfx.play("chooChoo");

      const remotePrefs = await createUserPreferencesFirebaseStorageProxy();
      if (remotePrefs.minigamesEnabled) {
        resolveMinigameMoment();
      }

      // departureStation.trains.splice(departureStation.trains.indexOf(train), 1);
      // train.currentStationId = null;

      // const dot = makeRevolvingTocIcon(main.context);
      // dot.scale.set(0.5);
      // dot.position.x = departureStation.x * 32;
      // dot.position.y = departureStation.y * 32;
      // world.addChild(dot);
      // await animator.tween.from(dot, {
      // pixi: { scale: 0 },
      // duration: 0.15,
      // delay: 0.25,
      // });
      // await animator.tween.to(dot, {
      // x: destinationStation.x * 32,
      // y: destinationStation.y * 32,
      // duration: 2.5,
      // ease: 'linear',
      // });
      // await animator.tween.to(dot, {
      // pixi: { scale: 0 },
      // duration: 0.1,
      // });
      // dot.destroy();
      // world.getStationVisual(destinationStation).playArrivalAnimation();

      // train.currentStationId = destinationStation.uid;
      // destinationStation.trains.push(train);

      main.setViewMode(GameViewMode.NORMAL);
    },

    async clearAndVerifyTrain(train) {
      console.log({
        train
      }, `clearAndVerifyTrain`);
      await spinner.showDuring(context.contracts.clearAndVerifyTrain(train.name), `Clearing and verifying train ${
        train.name
      }`);
      await context.spinner.showDuring(Promise.all([context.input.emit("closeRailRunsWindow"), context.input.emit("closeMarketWindow"), context.userDataCtrl.updateAll(),]), "Updating data after clearing and verifying a train...");
    },

    toggleCardsDrawer() {
      switch (main.viewMode) {
        case GameViewMode.NORMAL:
        case GameViewMode.NEXT_STOP:
          {
            return main.cards.setCardDrawerState(main.cards.getCardDrawerState() ? null : {
              type: "inventory"
            });
          }
      }
    },

    closeCardsDrawer() {
      main.cards.setCardDrawerState(null);
    },

    async locateOnMap(target) {
      await input.emit("closeEverything");
      await ticker.delay(0.35);
      await main.world.moveViewportTo(target);
      sfx.fadeOut("locateClickTiny", 200);
    },

    async locateBuilding(target) {
      const MAINMENU_ZOOM_MULTIPLIER = 1.04;
      const {viewport} = GameSingletons.getGameContext();
      if (target == null) {} else {
        await ticker.delay(0.35);
        await main.world.moveViewportBldg(target);
      }

      viewport.animate({
        scale: viewport.scaled * MAINMENU_ZOOM_MULTIPLIER,
        time: 200
      });
      sfx.fadeOut("locateClick", 400);
    },


    closeEverything() {
      main.closeEverything();
      main.setViewMode(GameViewMode.NORMAL);
    },

    async logOut() {
      const choice = await modals.confirm({
        title: contracts.currentUserName || "",
        content: "Are you sure you want to log out?"
      });

      if (! choice) {
        return;
      }

      for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key && key.startsWith("ual-") && key.startsWith("anchor-link-")) {
          localStorage.removeItem(key);
        }
      }

      context.firebase.auth.signOut();

      context.externals.logout();
    }
  });
}

/**
 *  On VIEW MODE or current active window change
 * - Update the screen title
 */
export function onViewModeChangeUpdateTitlePlate() {
  const {main, stage} = GameSingletons.getGameContext();

  stage.enchantments.watch.array(() => [
    main.viewMode,
    main.marketWindow.currentWindow ?. isOpen,
    main.railRunsWindow.currentWindow ?. isOpen,
    main.hud.railroaderDashboard.isOpen,
    main.immersiveMode,
    main.groundModalImmersiveMode,
    main.stationDashOpen
  ], ([
    viewMode,
    isMarketWindowOpen,
    isRailRunsWindowdOpen,
    isRailroaderDashboardOpen,
    isImmersiveModeActive,
    isGroundModalImmersiveMode,
    isStationDashOpen
  ]) => {
    if (isImmersiveModeActive) {
      return main.hud.title.setState(null);
    }
    if (isGroundModalImmersiveMode) {
      return main.hud.title.setState(null);
    }
    if (isStationDashOpen) {
      return main.hud.title.setState(null);
    }
    if (isRailroaderDashboardOpen) {
      return main.hud.title.setState(null);
    }

    if (isMarketWindowOpen) {
      return main.hud.title.setState({titleText: "Market"});
    }

    if (isRailRunsWindowdOpen) {
      return main.hud.title.setState({titleText: "Rail Runs"});
    }

    switch (viewMode) {
      case GameViewMode.NEXT_STOP:
        return main.hud.title.setState({titleText: "Next Stop", titleAccentColor: ScreenTitle.TITLE_ACCENT_COLOR_NEXT_STOP_CLASSIC, underTitleText: "classic", underTitleAccentColor: ScreenTitle.UNDERTITLE_ACCENT_COLOR_NEXT_STOP_CLASSIC});
      case GameViewMode.INSTANT_TRANSMISSION:
        return main.hud.title.setState({titleText: "Next Stop", titleAccentColor: ScreenTitle.TITLE_ACCENT_COLOR_INSTANT_TRANSMISSION, underTitleText: "instant", underTitleAccentColor: ScreenTitle.UNDERTITLE_ACCENT_COLOR_INSTANT_TRANSMISSION});
      case GameViewMode.DISPATCH:
        return main.hud.title.setState({titleText: "Dispatch"});
      case GameViewMode.EDIT_TRAIN:
        return main.hud.title.setState({titleText: "Edit Train"});
      case GameViewMode.LOADING_DOCK:
        return main.hud.title.setState({titleText: "Loading Dock"});
      case GameViewMode.BUILDING:
        return main.hud.title.setState({
          titleText: main.selection.selectedBuilding ?. buildingName ?? ''
        });
      case GameViewMode.GROUND:
        return main.hud.title.setState({
          titleText: main.selection.selectedBuilding ?. buildingName ?? ''
        });
      case GameViewMode.BUSY:
        return main.hud.title.setState(null);
      default:
        return main.hud.title.setState({titleText: HUD.DEFAULT_HEADER_TEXT});
    }
  });
}

/**
 *  On VIEW MODE or current active window change
 * - Update the main menu wheel (show tick if in edit mode)
 */
export function onViewModeChangeUpdateCogWheelMenu() {
  const {main, stage} = GameSingletons.getGameContext();

  stage.enchantments.watch(() => main.viewMode, viewMode => {
    const editModes = [GameViewMode.EDIT_TRAIN, GameViewMode.LOADING_DOCK];
    if (editModes.includes(viewMode)) {
      main.immersiveMode = false;
      main.groundModalImmersiveMode = false;
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.Confirm);
      main.hud.reasonsToHideCogWheelMenu.clear();
      main.hud.cogWheelMenu.cogWheel.setIcon("tick");
      main.hud.cogWheelMenu.cogWheel.setColor("cyan");
    } else if (viewMode === GameViewMode.NEXT_STOP) {
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.NextStop);
      main.hud.cogWheelMenu.cogWheel.setIcon("switch");
      main.hud.cogWheelMenu.cogWheel.setColor("orange");
      main.hud.reasonsToHideCogWheelMenu.clear();
    } else if (viewMode === GameViewMode.INSTANT_TRANSMISSION) {
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.NextStop);
      main.hud.cogWheelMenu.cogWheel.setIcon("switch");
      main.hud.cogWheelMenu.cogWheel.setColor("cyan");
      main.hud.reasonsToHideCogWheelMenu.clear();
    } else if (viewMode === GameViewMode.BUILDING) {

      main.groundModalImmersiveMode = false;
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.EnterBuilding);
      main.hud.cogWheelMenu.cogWheel.setIcon("dBldg");
      main.hud.cogWheelMenu.cogWheel.setColor("cyan");
      main.hud.reasonsToHideCogWheelMenu.clear();
    } else if (viewMode === GameViewMode.GROUND) {
      main.groundModalImmersiveMode = false;
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.Return);
      main.hud.cogWheelMenu.cogWheel.setIcon("bBldg");
      main.hud.cogWheelMenu.cogWheel.setColor("cyan");
      main.hud.reasonsToHideCogWheelMenu.clear();
    } else {
      console.log({
        viewMode
      }, `onViewModeChangeUpdateCogWheelMenu`);
      main.hud.cogWheelMenu.setCurrentMode(CogWheelMenuMode.Logo);
      main.hud.cogWheelMenu.cogWheel.setIcon("logo");
      main.hud.cogWheelMenu.cogWheel.setColor("default");
    }
  });
}

/**
 *  On VIEW MODE change
 * - Hide all popups
 * - Show the correct new popup(s)
 */
export function onViewModeChangeUpdateStationPopups() {
  const {
    main,
    stageContainers,
    stage,
    ticker,
    mapData
  } = GameSingletons.getGameContext();

  stage.enchantments.watch(() => main.viewMode, async (viewMode, prevViewMode) => {
    main.popups.clear();


    // For Building Click
    if (viewMode === GameViewMode.NORMAL && prevViewMode === GameViewMode.BUILDING) {
      stageContainers._bldg.removeChildren();
      main.selection.selectedBuilding = null;
    }


    await ticker.delay(0.08);

    if (viewMode === GameViewMode.NEXT_STOP) {
      const departureStation = main.selection.selectedStation;
      if (! departureStation) {
        throw new Error("No starting station selected");
      }

      const potentialDestinations = departureStation.links.map(link => mapData.stations.get(link.assetId)!);
      await ticker.delay(0.22);
      main.popups.nextStop.setCurrentStations([... potentialDestinations]);
    } else if (prevViewMode === GameViewMode.NEXT_STOP) {
      main.popups.nextStop.setCurrentStations([]);
    }

    if (viewMode === GameViewMode.DISPATCH) {
      const departureStation = main.selection.selectedStation;
      if (! departureStation) {
        throw new Error("No starting station selected");
      }
      const destinationStation = main.selection.selectedDestination;
      if (! destinationStation) {
        throw new Error("No destination station selected");
      }

      await ticker.delay(0.1);
      main.popups.dispatchDeparture.setCurrentStation(departureStation);
      main.popups.dispatchDestination.setCurrentStation(destinationStation);
    } else if (prevViewMode === GameViewMode.DISPATCH) {
      main.popups.dispatchDeparture.setCurrentStation(null);
      main.popups.dispatchDestination.setCurrentStation(null);
    }

    if (viewMode === GameViewMode.EDIT_TRAIN) {
      await ticker.delay(0.1);

      const departureStation = main.selection.selectedStation;
      const destinationStation = main.selection.selectedDestination;

      await ticker.delay(0.1);
      main.popups.editTrainComposition.setCurrentStation(departureStation);
      main.popups.editTrainDestination.setCurrentStation(destinationStation);
    } else if (prevViewMode === GameViewMode.EDIT_TRAIN) {
      main.popups.editTrainComposition.setCurrentStation(null);
      main.popups.editTrainDestination.setCurrentStation(null);
    }

    if (viewMode === GameViewMode.LOADING_DOCK) {
      await ticker.delay(0.1);

      const departureStation = main.selection.selectedStation;
      const destinationStation = main.selection.selectedDestination;

      await ticker.delay(0.1);
      main.popups.editTrainLoadout.setCurrentStation(departureStation);
      main.popups.editTrainDestination.setCurrentStation(destinationStation);
    } else if (prevViewMode === GameViewMode.EDIT_TRAIN) {
      main.popups.editTrainLoadout.setCurrentStation(null);
      main.popups.editTrainDestination.setCurrentStation(null);
    }
  });
}

/**
 *  On VIEW MODE change
 * - Update RAIL TRACK highlights
 */
export function onViewModeChangeUpdateRailTrackHighlights() {
  const {main, stage, ticker} = GameSingletons.getGameContext();

  stage.enchantments.watch.array(() => [main.viewMode, main.selection.selectedStation, main.selection.selectedDestination, main.selection.hoverStation,] as const, async ([viewMode, departureStation, destinationStation, hoverStation]) => { // // CLEANUP FROM PREVIOUS VIEW MODE

      for (const track of main.world.zoomLayers.operationsLayer.tracksContainer.tracks) {
        if (track.glowColor != null) {
          track.glowColor = null;
          await ticker.delay(0.0167);
        }
      }

      // // //// //// //// //// //// //// //// //// ////

      const canSelectedTrainMakeItBetweenStations = (departureStation : StationEntity, destinationStation : StationEntity) => {
        const train = main.selection.selectedTrain;
        const trainMaxDistance = train ?. maxDistance || 0;
        const stationsDistance = departureStation.getDistanceTo(destinationStation) || 0;
        const trainCanMakeIt = trainMaxDistance >= stationsDistance;
        return trainCanMakeIt;
      };

      if (viewMode === GameViewMode.NEXT_STOP) {
        if (!departureStation) {
          throw new Error("No starting station selected");
        }

        for (const track of main.world.zoomLayers.operationsLayer.tracksContainer.tracks) {
          const shouldHighlight = track.railPath.hasStation(departureStation);
          if (shouldHighlight) {
            const trainCanMakeIt = canSelectedTrainMakeItBetweenStations(track.railPath.stationA !, track.railPath.stationB !);
            track.glowColor = trainCanMakeIt ? 0xffeebb : 0xd83939;
            await ticker.nextFrame();
          } else {
            track.glowColor = null;
          }
        }
      }

      if (departureStation && destinationStation) {
        for (const track of main.world.zoomLayers.operationsLayer.tracksContainer.tracks) {
          if (! track.isVisible) {
            continue;
          }

          const isDestination = track.railPath.hasStations(departureStation, destinationStation);
          const isPotentialDestination = hoverStation && track.railPath.hasStations(departureStation, hoverStation);
          if (isDestination) {
            const trainCanMakeIt = canSelectedTrainMakeItBetweenStations(track.railPath.stationA !, track.railPath.stationB !);
            track.glowColor = trainCanMakeIt ? 0x00ffff : 0xd83939;
          } else if (isPotentialDestination) {
            track.glowColor = 0xffeebb;
          } else {
            track.glowColor = null;
          }
        }
      }
    });
  }

  /**
 */
  export function onAnyChangeUpdateStationHighlights() {
    const {world, selection, viewMode, faq} = GameSingletons.getMainInstance();
    const operationsLayer = world.zoomLayers.operationsLayer;

    operationsLayer.onEnterFrame.add(() => {
      for (const station of operationsLayer.stationsContainer.stationsArray) {
        const {data} = station;

        // // UPDATE NEXT STOP GLOW
        {
          if (viewMode === GameViewMode.NEXT_STOP) {
            const ok = selection.isViablelDestination(data);
            station.nextStopGlow.setActive(ok);
          } else if (data === selection.selectedDestination) {
            const ok = selection.isViablelDestination(data);
            station.nextStopGlow.setActive(ok);
          } else if (data === selection.hoverStation && (viewMode === GameViewMode.DISPATCH || viewMode === GameViewMode.EDIT_TRAIN)) {
            const ok = selection.isViablelDestination(data);
            station.nextStopGlow.setActive(ok);
          } else {
            station.nextStopGlow.setActive(false);
          }
        }

        // // UPDATE HOVER GLOW

        {
          const isSelected = selection.selectedStation === data && viewMode === GameViewMode.NORMAL;
          const isHovered = data === selection.hoverStation;
          station.hoverGlow.setActive(isSelected || isHovered);
        }

        // // UPDATE TRAIN GLOW

        const sittingTrain = faq.getFirstTrainAtStation(data);
        const incomingTrain = faq.getFirstTrainInTransitToStation(data);
        const hasOwnedTrain = !!(sittingTrain || incomingTrain);
        const isSelected = data === selection.selectedStation;
        if (viewMode === GameViewMode.NORMAL) {
          station.highlightGlow.setActive(hasOwnedTrain);
        } else {
          station.highlightGlow.setActive(viewMode != GameViewMode.BUSY && isSelected);
        } station.highlightGlow.pulseSpeed = ! sittingTrain && !! incomingTrain ? 5 : null;
      }
    });
  }

  // // On station selected (normal mode) ////
  export function onStationSelectedUpdateStationPopups() {
    const {
      main,
      world,
      stage,
      ticker,
      userData
    } = GameSingletons.getGameContext();

    const isInOperationsNormalView = () => world.getCurrentZoomLevel() === WorldZoomLevel.OPERATIONS && main.viewMode === GameViewMode.NORMAL;

    stage.enchantments.watch.array(() => isInOperationsNormalView() ? ([main.selection.selectedStation, main.selection.selectedTrain] as const) : ([null, null] as const), async ([station, train]) => {
      main.selection.selectedDestination = null;

      if (station === main.selection.hoverStation) {
        main.selection.hoverStation = null;
        await ticker.delay(0.1);
      }

      if (station) {
        if (train) {
          main.popups.clickWithMyTrain.setCurrentStation(station);
          return;
        }

        /**
         * Else, show the generic expandable station popup by default.
         */
        main.popups.clickUnrelated.setCurrentStation(station);
      }
    });
  }

  // // On station hover (normal mode) ////
  export function onStationHoverUpdateStationPopups() {
    const {main, world, stage, ticker} = GameSingletons.getGameContext();

    stage.enchantments.watch(() => world.getCurrentZoomLevel() === WorldZoomLevel.OPERATIONS && main.viewMode === GameViewMode.NORMAL ? main.selection.hoverStation : null, station => {
      if (station) {
        const train = main.faq.getFirstTrainAtStation(station);
        if (train == null) {
          station = null;
        }
        if (main.selection.selectedStation) {
          station = null;
        }
        if (main.popups.myStationDashboard.currentPopup != null) {
          station = null;
        }
      }
      main.popups.hover.setCurrentStation(station);
    });
  }

  export function onEmptyViewportClickDismissAllStationPopups() {
    const {viewport, main} = GameSingletons.getGameContext();
    const viewModesToIgnoreOn = [GameViewMode.EDIT_TRAIN, GameViewMode.LOADING_DOCK, GameViewMode.INSTANT_TRANSMISSION];
    viewport.on("clicked", () => {
      if (viewModesToIgnoreOn.includes(main.viewMode)) {
        return;
      }
      if (main.stationDashOpen === true) {
        main.stationDashOpen = false;
      }
      main.selection.clear();
      main.popups.clear();
      main.setViewMode(GameViewMode.NORMAL);
      if (main.cards.state ?. type === "inventory") {
        main.cards.setCardDrawerState(null);
      }
    });
  }

  /**
 * On unsaved changes, make the central main menu button glow.
 * On unsaved changes, if unsaved potential train is invalid, gray out the central main menu button
 */
  export function onUnsavedTrainChangesUpdateCogWheelMenu() {
    const {main, stage, tooltips} = GameSingletons.getGameContext();

    stage.enchantments.watch.array(() => [
      main.cards.changes.hasUnsavedDiff,
      main.cards.changes.hasUnsavedDiff ? main.cards.changes.unsavedTrain ?. getInvalidReason() : undefined,
    ], ([hasUnsavedChanges, invalid]) => {
      const editModes = [GameViewMode.EDIT_TRAIN, GameViewMode.LOADING_DOCK];
      if (editModes.includes(main.viewMode)) {
        main.hud.cogWheelMenu.cogWheel.setColor(invalid ? "gray" : hasUnsavedChanges ? "cyanWithGlow" : "cyan");
      }

      // const bigButton = main.hud.cogWheelMenu.bigButton;
      // if (invalid) {
      // tooltips.registerTarget(bigButton, typeof invalid === "string" ? invalid : "Invalid Train Configuration");
      // } else {
      // tooltips.deregisterTarget(bigButton);
      // }
    });
  }

  /**
 * While Market or Railroader Window is up -- Dim the map view
 */
  export function onAnyWindowOpenDimTheScreenBehindIt() {
    const {main, stage, world} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => !!main.anyWindowOpen, hasModalWindowUp => {
      if (hasModalWindowUp) {
        main.setViewMode(GameViewMode.NORMAL);
        main.popups.clear();
        main.selection.clear();
        world.dimmer.show();
      } else {
        world.dimmer.hide();
      }
    });
  }

  export function onAnyWindowHideHUDElements() {
    const {main, stage} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => !!main.anyWindowOpen, hasModalWindowUp => {
      main.hud.reasonsToHideCogWheelMenu.set("MarketOrRailRunsWindowOpen", hasModalWindowUp);
      main.hud.reasonsToHideRadio.set("MarketOrRailRunsWindowOpen", hasModalWindowUp);
    });
  }

  export function onAnyRailroaderDashPanelOpenHideRadio() {
    const {main, stage} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => !!main.hud.railroaderDashboard.currentDashboard ?. hasCentralPanelOpen, hasRRDashPanelUp => {
      main.hud.reasonsToHideRadio.set("RRDashPanelOpen", hasRRDashPanelUp);
    });
  }

  export function onCinematicPlayingHideRadio() {
    const {main, stage, stageContainers} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => stageContainers._cinematic.children.length > 0, hasRRDashPanelUp => {
      main.hud.reasonsToHideRadio.set("CinematicPlaying", hasRRDashPanelUp);
    });
  }

  export function onBuildingPanelHideStuff() {
    const {main, stage, stageContainers} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => stageContainers._bldg.children.length > 0 || stageContainers._ground.children.length > 0, hadBldgPanelOpen => {
      main.hud.reasonsToHideRadio.set("BuildingPanelOpen", hadBldgPanelOpen);
      main.hud.reasonsToHideAppInfo.set("BuildingPanelOpen", hadBldgPanelOpen);
      main.hud.reasonsToHidePins.set("BuildingPanelOpen", hadBldgPanelOpen);

    });
  }

  export function onCardsDrawerOpenHideRadio() {
    const {main, stage} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => main.cards.drawer.isOpen === true, drawerOpen => {
      main.hud.reasonsToHideRadio.set("CardDrawerOpen", drawerOpen);
    });
  }

  export function onBuildingPanelHideFuel() {
    const {main, stage, stageContainers} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => stageContainers._bldg.children.length > 0, hasRRDashPanelUp => {
      main.hud.reasonsToHideFuelCounters.set("BuildingPanelOpen", hasRRDashPanelUp);
    });
  }

  export function onTrainTamperedPromptUserToClearOrVerifyTrain() {
    const {contracts, input, userData, userDataCtrl} = GameSingletons.getGameContext();

    contracts.events.on({
      trainTampered: async (trainName, error) => {
        const choice = confirm(error + `\n\nWould you like to clear the train "${trainName}" and verify it?`
        // errorMessage + `\n\nWould you like to remove all unowned cards from train "${train}" and verify it?`
        );
        if (! choice) {
          return;
        }
        const train = userData.trains.get(trainName);
        if (train == null) {
          throw new Error(`Train "${trainName}" not found`);
        }
        await input.emit("clearAndVerifyTrain", train as TrainEntity);
      }
    });
  }

  export function onDashboardOpenApplyLowPassFilterToMusic() {
    const {stage, main, music} = GameSingletons.getGameContext();

    stage.enchantments.watch(() => main.hud.railroaderDashboard.isOpen, isDashboardOpen => music.setLowPassFilter(isDashboardOpen ? 1.0 : 0.0, 1.65), true);
  }

  export function buildingModeApplyFiltersToMusic() {
    const {stageContainers, stage, main, music} = GameSingletons.getGameContext();

    stage.enchantments.watch(() => stageContainers._ground.children.length > 0, isDashboardOpen => music.setTelephoneFilter(isDashboardOpen), true);
  }

  export function onImmersiveModeHideTheHUD() {
    const {stage, main} = GameSingletons.getGameContext();

    stage.enchantments.watch(() => main.immersiveMode, immersiveMode => main.hud.reasonsToHideHUD.set("ImmersiveModeActive", immersiveMode), true);
  }

  export function onGroundImmersiveModeHideElements() {
    const {stage, main} = GameSingletons.getGameContext();

    stage.enchantments.watch(() => main.groundModalImmersiveMode, groundModalImmersiveMode => main.hud.groundModalHideElements.set("ImmersiveModeActive", groundModalImmersiveMode), true);
  }

  export function onImmersiveModeZoomIn() {
    const {world, main, viewport} = GameSingletons.getGameContext();

    world.enchantments.watch(() => main.immersiveMode, immersiveMode => {
      const MAINMENU_ZOOM_MULTIPLIER = 1.04;
      if (immersiveMode) {
        viewport.animate({
          scale: viewport.scaled * MAINMENU_ZOOM_MULTIPLIER,
          time: 200
        });
      } else {
        viewport.animate({
          scale: viewport.scaled / MAINMENU_ZOOM_MULTIPLIER,
          time: 200
        });
      }
    }, true);
  }

  export function onRailroaderDashboardOpenZoomOut() {
    const {world, main, viewport} = GameSingletons.getGameContext();

    world.enchantments.watch(() => main.hud.railroaderDashboard.isOpen, zoomOut => {
      const MAINMENU_ZOOM_MULTIPLIER = 0.86;
      if (zoomOut) {
        viewport.animate({
          scale: viewport.scaled * MAINMENU_ZOOM_MULTIPLIER,
          time: 500,
          ease: "easeOutQuad"
        });
      } else {
        viewport.animate({
          scale: viewport.scaled / MAINMENU_ZOOM_MULTIPLIER,
          time: 500,
          ease: "easeOutQuad"
        });
      }
    }, true);
  }

  export function onRailroaderDashboardFoldCogWheelButtons() {
    const {world, main} = GameSingletons.getGameContext();

    world.enchantments.watch(() => main.hud.railroaderDashboard.isOpen, dashOpen => main.hud.cogWheelMenu.reasonsToHideRimButtons.set("RailroaderDashboardUp", dashOpen), true);
  }

  export function hideExtraButtonsOnGround() {
    const {stage, stageContainers, world, main} = GameSingletons.getGameContext();
    stage.enchantments.watch(() => stageContainers._groundModals.children.length > 0, noGroundModals => main.hud.cogWheelMenu.reasonsToHideRimButtons.set("RailroaderDashboardUp", noGroundModals), true);

  }

  export function getCurrentHour(): number {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
  
    if (timeZone) {
        const dateWithTimeZone = new Date(date.toLocaleString("en-US", { timeZone }));
        return dateWithTimeZone.getHours();
    }
  
    return date.getHours();
  }

  export function nightMode() {
    const {world, main} = GameSingletons.getGameContext();
  
    const filter = new AdjustmentFilter({
      gamma:1.2,
      saturation: .6,
      contrast: 1.3,
      brightness: 1.4,
      red: .35,
      green: .55,
      blue: .63
    });
  
    const groundFilter = new AdjustmentFilter({
      gamma:.95,
      saturation: .6,
      contrast: 1.3,
      brightness: 1.75,
      red: .3,
      green: .5,
      blue: .5
    });
  
    world.enchantments.watch(() => main.nightModeEnabled, nightModeActive => {
      const hours = getCurrentHour();
      if (!nightModeActive || (hours >= 6 && hours < 20)) { 
        main.world.zoomLayers.operationsLayer.ground.filters = [];
        main.world.zoomLayers.regionsLayer.ground.filters = [];
        main.world.zoomLayers.operationsLayer.tracksContainer.filters = [];
        main.world.zoomLayers.operationsLayer.ongoingRunTracks.filters = [];
        main.world.zoomLayers.operationsLayer.decorationsContainer.filters = [];
        main.world.zoomLayers.operationsLayer.stationsContainer.filters = [];
      } else if (nightModeActive && (hours >= 20 || hours < 6)) {
        main.world.zoomLayers.operationsLayer.ground.filters = [groundFilter];
        main.world.zoomLayers.regionsLayer.ground.filters = [filter];
        main.world.zoomLayers.operationsLayer.tracksContainer.filters = [filter];
        main.world.zoomLayers.operationsLayer.ongoingRunTracks.filters = [filter];
        main.world.zoomLayers.operationsLayer.decorationsContainer.filters = [filter];
        main.world.zoomLayers.operationsLayer.stationsContainer.filters = [filter];
      }
    }, true);
  }
  
