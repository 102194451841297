import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { Texture } from "@pixi/core";

export function __addLabel(to: Container, text: string, scale = 1) {
  const label = new Text(text, { fontSize: 12, fill: 0xffffff });
  label.position.set(-to.pivot.x + 2, -to.pivot.y + 2);
  label.scale.set(scale);
  label.zIndex = 999_999_999;

  const labelPad = new Sprite(Texture.WHITE);
  labelPad.width = label.width + 4;
  labelPad.height = label.height + 4;
  labelPad.tint = 0x000000;
  labelPad.alpha = 0.8;
  labelPad.zIndex = 999_999_998;

  to.addChild(labelPad, label);

  return label;
}
