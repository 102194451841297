import { RailroaderDashComponentFactory } from "../RailroaderDashComponentFactory";
import { AchievementsRRDashPanel } from "./achievements/AchievementsRRDashPanel";
import { ClaimCenterPanel } from "./claim-center/ClaimCenterPanel";
import { GoldenRunsPanel } from "./goldenRuns/GoldenRunsPanel";
import { InventoryPanel } from "./inventory/InventoryPanel";
import { LeaderboardCenterPanel } from "./leaderboard/LeaderboardPanel";
import { LeaderboardCenterPanel_MiniGames } from "./leaderboard/LeaderboardPanel_MiniGames";
import { RailroaderDashPanel, RailroaderDashPanelType } from "./models";
import { SettingsCenterPanel } from "./settings/SettingsPanel";
import { StakingCenterPanel } from "./staking/StakingPanel";
import { StoryCentralPanel } from "./story/StoryCentralPanel";

export class RailroaderDashPanelFactory {
  private readonly componentFactory = new RailroaderDashComponentFactory();

  createFromType(panelType: RailroaderDashPanelType | null) {
    if (panelType == null) return null;

    // TODO: Build singletons and cache when built
    const panels = {
      [RailroaderDashPanelType.Settings]: () => new SettingsCenterPanel(this.componentFactory),
      [RailroaderDashPanelType.Leaderboards]: () => new LeaderboardCenterPanel(this.componentFactory),
      [RailroaderDashPanelType.ClaimCenter]: () => new ClaimCenterPanel(this.componentFactory),
      [RailroaderDashPanelType.Staking]: () => new StakingCenterPanel(this.componentFactory),
      [RailroaderDashPanelType.Events]: () => console.log("TODO: Tab 4"),
      [RailroaderDashPanelType.Story]: () => new StoryCentralPanel(this.componentFactory),
      [RailroaderDashPanelType.Achievements]: () => new AchievementsRRDashPanel(this.componentFactory),
      [RailroaderDashPanelType.Inventory]: () => new InventoryPanel(this.componentFactory),
      [RailroaderDashPanelType.GoldenRuns]: () => new GoldenRunsPanel(this.componentFactory),
      ////
      [RailroaderDashPanelType.ArcadeLeaderboards]: () => new LeaderboardCenterPanel_MiniGames(),
    };

    const key = +panelType as keyof typeof panels;
    return (panels[key]?.() as RailroaderDashPanel) || null;
  }

  getFactory() {
    return this.componentFactory;
  }
}
