import { EventBus } from "@sdk/core/EventBus";
import { AchievementBadgeData } from "../achievements/AchievementBadgeData";

export class FeaturedAchievementsPanelState {
  public readonly featuredAchievements: Array<AchievementBadgeData> = [];
  public readonly allAchievements: Array<AchievementBadgeData> = [];

  public readonly events = new EventBus<{
    onAllAchievementsSet: (achievements: AchievementBadgeData[]) => void;
    onAchievementSelected: (achievement: AchievementBadgeData) => void;
    onAchievementDeselected: (achievement: AchievementBadgeData) => void;
  }>();

  async setAllAchievements(achievements: AchievementBadgeData[]) {
    this.allAchievements.splice(0, this.allAchievements.length, ...achievements);
    await this.events.emit("onAllAchievementsSet", this.allAchievements);
  }

  async selectFeaturedAchievement(achievement: AchievementBadgeData) {
    if (this.isAchievementSelected(achievement)) {
      return console.warn("Achievement already selected");
    }

    if (!this.allAchievements.includes(achievement)) {
      return console.warn("Achievement not found in all achievements");
    }

    this.featuredAchievements.push(achievement);
    await this.events.emit("onAchievementSelected", achievement);
  }

  async deselectFeaturedAchievement(achievement: AchievementBadgeData) {
    const index = this.featuredAchievements.indexOf(achievement);

    if (index === -1) {
      return console.warn("Achievement not found in featured achievements");
    }

    this.featuredAchievements.splice(index, 1);
    await this.events.emit("onAchievementDeselected", achievement);
  }

  isAchievementSelected(achievement: AchievementBadgeData) {
    return this.featuredAchievements.includes(achievement);
  }
}
