const IMGs = [
  "https://public.cx/mock/skills/50-rpg-archer-skill-icons-rpg-archer-skill-icons-18.png",
  "https://public.cx/mock/skills/50-rpg-cryomancer-skill-icon-pack-41.png",
  "https://public.cx/mock/skills/50-rpg-debuff-skill-icons-21.png",
  "https://public.cx/mock/skills/100-skill-icons-pack-for-rpg-skill%20icon%2012.png",
  "https://public.cx/mock/skills/100-skill-game-icons-skill%2086.png",
  "https://public.cx/mock/skills/100-skill-game-icons-skill%2041.png",
  "https://public.cx/mock/skills/50-rpg-paladin-skill-icons-22.png",
];

export function getMockEthereumNFTs() {
  return IMGs.map(textureURL => {
    return {
      ownerUsername: "t1.century",
      textureURL,
      nftData: {
        contract: { address: "0xeef2297e15cd085a0ad33197ce8c2eab6f023c2b" },
        id: {
          tokenId: "0x000000000000000000000000000000000000000000000000000000000000000a",
          tokenMetadata: { tokenType: "ERC721" },
        },
        balance: "1",
        title: "Choo Choo",
        description: "A collection of 10,000 unique Choos.",
        tokenUri: {
          raw: "ipfs://QmRJ6TQpAKUGAm8DHTi8CEHR5z3fpqxfaj1R6LsZhvmt7h",
          gateway: "https://ipfs.io/ipfs/QmRJ6TQpAKUGAm8DHTi8CEHR5z3fpqxfaj1R6LsZhvmt7h",
        },
        media: [
          {
            raw: "ipfs://QmRMC94CY7HvEdDfz1KMFzrBjYRvxyYrve3gs2Lci6M2V5",
            gateway: "https://ipfs.io/ipfs/QmRMC94CY7HvEdDfz1KMFzrBjYRvxyYrve3gs2Lci6M2V5",
          },
        ],
        metadata: {
          name: "Choo Choo #16",
          description:
            "10,000 unique choo choo trains, chillin on the tracks. Life is simple when youre a Choo. Nothin wrong with flexin your Choo Choos in the Centuryverse. Railroaders respect the Choo.",
          image: "ipfs://Qmb9nG*************************/00016.png",
          external_url: "https://trains.cards/choochoos/",
          attributes: [
            { trait_type: "Background", value: "legendary" },
            { trait_type: "Left-arm", value: "wave" },
            { trait_type: "Train", value: "teal" },
            { trait_type: "Wheels", value: "teal" },
            { trait_type: "Streak", value: "flowers" },
            { trait_type: "Mouth", value: "grit" },
            { trait_type: "Eyebase", value: "black" },
            { trait_type: "Eyeballs", value: "suspect" },
            { trait_type: "Wings", value: "wings" },
            { trait_type: "Right-arm", value: "thomas" },
            { trait_type: "Sign", value: "choobastank" },
          ],
        },
        timeLastUpdated: "2022-06-15T20:37:25.944Z",
      },
    };
  });
}
