import { GameSingletons } from "@game/app/GameSingletons";
import { delay } from "@sdk/utils/promises";

export async function playMinigame(url: string) {
  const clear = displayMinigame(url);
  try {
    const score = await waitForMinigameToPostScoreMessage();
    await delay(1);
    return score;
  } finally {
    clear();
  }
}

function displayMinigame(url: string) {
  const context = GameSingletons.getGameContext();
  const minigame = document.createElement("iframe");
  minigame.src = url;
  minigame.style.position = "absolute";
  minigame.style.top = "0";
  minigame.style.left = "0";
  minigame.style.width = "100%";
  minigame.style.height = "100%";
  minigame.style.border = "none";
  minigame.style.zIndex = "1";
  minigame.style.backgroundColor = "#0009";
  document.body.appendChild(minigame);

 
  const exitButton = document.createElement("img");
  exitButton.src = "assets/images-webp/buildings/arcade/mini-games/quitbtn.png";
  exitButton.style.cursor = "pointer";
  exitButton.style.position = "absolute";
  exitButton.style.top = "20px";
  exitButton.style.left = "20px";
  exitButton.style.zIndex = "2";
  document.body.appendChild(exitButton);
  
  
  const clearFn = function clear() {
    context.renderLoop.reasonsToNotRender.remove("Minigame Active");
    document.body.removeChild(minigame);
    document.body.removeChild(exitButton);
    resumeMusic();
  }
  exitButton.addEventListener("click", clearFn);  //call the clear function when button is clicked

 
  context.renderLoop.reasonsToNotRender.add("Minigame Active");
  const resumeMusic = context.music.playSilence();

  return clearFn;
}

function waitForMinigameToPostScoreMessage() {
  return new Promise<number>((resolve, reject) => {
    window.addEventListener("message", handleIframeMessage);
    function handleIframeMessage(event: MessageEvent<{ type: string; payload: { score: number } }>) {
      try {
        if (event.data == undefined) return;
        if (event.data.type != "FINISH_MINIGAME") return;
        if (event.data.payload == undefined) return;
        if (event.data.payload.score == undefined) return;
        const score = event.data.payload.score;
        window.removeEventListener("message", handleIframeMessage);
        resolve(score);
      } catch (error) {
        reject(error);
      }
    }
  });
}
