import type * as WAX from "@sdk-integration/contracts";

import type { LiteralUnion } from "type-fest";
import type { User as UALUser } from "universal-authenticator-library";
import type { ActionName } from ".";

const defaultPermission = "active";

export type WaxContractActionParams = [
  actionName: LiteralUnion<ActionName, string>,
  data: Record<string, unknown>,
  contractName: WAX.ContractName
];

export class WaxContractActionError extends Error {
  constructor(message: string, public readonly actionData: Record<string, any>) {
    super(message);
    this.name = "RRContractActionError";
  }

  messageIncludes(...needles: string[]) {
    return needles.every(needle => this.message.toLowerCase().includes(needle.toLowerCase()));
  }
}

export interface WaxContractActionObject {
  authorization: {
    actor: string;
    permission: any;
  }[];
  account: WAX.ContractName;
  name: LiteralUnion<WAX.ActionName, string>;
  data: Record<string, unknown>;
}

export class WaxContractActionService {
  public onTransactionError: (error: WaxContractActionError, actionObject: WaxContractActionObject[]) => unknown =
    console.error;

  constructor(protected readonly user: UALUser) {}

  async performActionTransaction(...params: WaxContractActionParams) {
    return await this.performActionTransactions([params]);
  }

  async performActionTransactions(actions: WaxContractActionParams[]) {
    const { user } = this;
    const untypedUser = user as any;
    const permission = untypedUser.requestPermission || untypedUser.session?.auth?.permission || defaultPermission;

    const actor = await user.getAccountName();
    const actionObjects = actions.map(([actionName, data, contractName]) => ({
      authorization: [{ actor, permission }],
      account: contractName,
      name: actionName,
      data,
    }));

    console.log({ actionObjects });

    try {
      const actionResult = await user.signTransaction(
        { actions: actionObjects },
        { blocksBehind: 3, expireSeconds: 120 }
      );
      console.log({ actionResult });
      return actionResult;
    } catch (error) {
      const actionError = new WaxContractActionError("" + error, actionObjects);
      this.onTransactionError(actionError, actionObjects);
      throw actionError;
    }
  }
}
