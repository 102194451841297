import { formatDateTimeHumanReadable } from "@game/asorted/formatDateTimeHumanReadable";
import { VerticalDisplayObjectList } from "@game/asorted/VerticalDisplayObjectList";
import { LetterBoxingBars } from "@game/cinematics/components/LetterBoxingBars";
import { RegionIntegerId } from "@game/constants/RegionId";
import { CardEntity } from "@game/data/entities/CardEntity";
import { StakingAddonDataService } from "@game/data/staking/StakingAddonDataService";
import { DashboardButton } from "@game/ui/popups/components/dash/DashboardButton";
import { GreenButton } from "@game/ui/railroader-dash/panels/settings/components/GreenButton";
import { getSpriteFromIPFSHash } from "@game/ui/popups/station-dashboard-components/billboard/utils/getSpriteFromIPFSHash";
import { fitObjectInRectangle } from "@sdk-pixi/layout/fitObjectInRectangle";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { createSimpleTweener } from "@sdk/time/SimpleTweener";
import { checkForStoryProgress } from "@game/gameplay/checkForStoryProgress";
import { UnlockTrainsDataService } from "@game/ui/windows/market/pages/upgrades/unlock-trains/UnlockTrainsDataService";
import { makeParticlesExplosion } from "@game/ui/fx/makeParticlesExplosion";
import { createThomasEncounterRewardModal } from "@game/cinematics/utils/createThomasEncounterRewardModal";
import { playStoryEncounterCinematic } from "@game/gameplay/playStoryEncounterCinematic";
import { createDynamicStoryTextService } from "@game/cinematics/utils/dynamicStoryContentFunctions";
import { FontFamily } from "@game/constants/FontFamily";
import { RailCarStatBox } from "@game/ui/popups/components/RailCarStatBox";
import { FontIcon } from "@game/constants/FontIcon";
import { addDisplayObjectMask, createDisplayObjectMask } from "@game/asorted/createDisplayObjectMask";
import { fadeChangeSpriteTexture } from "@game/asorted/animations/fadeChangeSpriteTexture";
import { LocoBoostJar } from "@game/ui/components/LocoBoostJar";
import { createObservableObjectWrapper } from "@sdk/observers/createObservableObjectWrapper";
import { EmporiumDataService } from "@game/ui/windows/market/pages/emporium/EmporiumDataService";
import { MapDataLoader } from "@game/data/loadMapData";
import { FirebaseServicesWrapper } from "@sdk-integration/firebase/FirebaseServicesWrapper";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { arrangeInStraightLine } from "@sdk-pixi/layout/arrangeInStraightLine";
import { SocialProfileDataService } from "@game/ui/social/SocialProfileDataService";
import { playMinigame } from "@game/minigames/displayMinigame";
import { displayMinigameSelectionScreen } from "@game/minigames/displayMinigameSelectionScreen";
import { resolveMinigameMoment } from "@game/minigames/resolveMinigameMoment";
import { MusicSource } from "@game/constants/paths/MusicSource";
import { CenturyTrainPartsDataService } from "@game/ui/windows/market/pages/ct-parts/CTPartsDataService";
import { EthereumNFTDataService } from "@sdk-integration/ethereum/EthereumNFTDataService";
import { HttpApiService } from "@sdk/http/HttpApiService";
import { TrainCondition } from "@game/constants/TrainCondition";
import prettyMilliseconds from "pretty-ms";
import { claimAllRailRuns } from "@game/ui/windows/railruns/claimAllRailRuns";

Promise.all([
  import("@sdk/helpers/arrays"),
  import("@sdk/helpers/objects"),
  import("@sdk/helpers/promises"),
  import("@sdk/utils/math"),
  import("@sdk/utils/math2D"),
  import("@sdk/utils/promises"),
  import("@sdk/utils/range"),
  import("@sdk/utils/range2D"),
  import("@sdk/utils/MathEuler"),
  import("@sdk/utils/random"),
]).then(imports => Object.assign(window, ...imports));

Promise.all([import("@game/ui/social/achievements/ottoAchievements")]).then(imports =>
  Object.assign(window, ...imports)
);

Object.assign(window, {
  CardEntity,
  DashboardButton,
  GreenButton,
  VerticalDisplayObjectList,
  StakingAddonDataService,
  TemporaryTweeener,
  LetterBoxingBars,
  UnlockTrainsDataService,
  RailCarStatBox,
  FontFamily,
  FontIcon,
  Region: RegionIntegerId,
  checkForStoryProgress,
  formatDateTimeHumanReadable,
  createSimpleTweener,
  getSpriteFromIPFSHash,
  fitObjectInRectangle,
  makeParticlesExplosion,
  createThomasEncounterRewardModal,
  createDynamicStoryTextService,
  playStoryEncounterCinematic,
  createDisplayObjectMask,
  addDisplayObjectMask,
  fadeChangeSpriteTexture,
  createObservableObjectWrapper,
  LocoBoostJar,
  EmporiumDataService,
  MapDataLoader,
  WaxContractsGateway,
  FirebaseServicesWrapper,
  arrangeInStraightLine,
  SocialProfileDataService,
  playMinigame,
  displayMinigameSelectionScreen,
  resolveMinigameMoment,
  MusicSource,
  CenturyTrainPartsDataService,
  EthereumNFTDataService,
  HttpApiService,
  TrainCondition,
  prettyMilliseconds,
  claimAllRailRuns,
});
