import { Color } from "@sdk/utils/color/Color";
import { RarityInteger } from "./Rarity";

interface RarityColors {
  main: Color;
  lightComplementary: Color;
}

export const RarityColors: Record<RarityInteger, RarityColors> = {
  [RarityInteger.Common]: { lightComplementary: Color.frozen(0xd7f6f9), main: Color.frozen(0x6f4f32) },
  [RarityInteger.Uncommon]: { lightComplementary: Color.frozen(0xf2deac), main: Color.frozen(0x1d7a45) },
  [RarityInteger.Rare]: { lightComplementary: Color.frozen(0xf9be75), main: Color.frozen(0x32871) },
  [RarityInteger.Epic]: { lightComplementary: Color.frozen(0xf8f7c3), main: Color.frozen(0x2c0f54) },
  [RarityInteger.Legendary]: { lightComplementary: Color.frozen(0xffffff), main: Color.frozen(0xc69b05) },
  [RarityInteger.Mythic]: { lightComplementary: Color.frozen(0xffd793), main: Color.frozen(0x8f0404) },
  [RarityInteger.CT]: { lightComplementary: Color.frozen(0xffd793), main: Color.frozen(0x034053) },

};

export function getRarityColors(rarity: string | RarityInteger): RarityColors {
  if (typeof rarity === "string") {
    const rarityInt = {
      common: RarityInteger.Common,
      uncommon: RarityInteger.Uncommon,
      rare: RarityInteger.Rare,
      epic: RarityInteger.Epic,
      legendary: RarityInteger.Legendary,
      mythic: RarityInteger.Mythic,
      ct: RarityInteger.CT,

    }[rarity.toLowerCase()];

    if (rarityInt === undefined) {
      throw new Error(`Unknown rarity: ${rarity}`);
    }

    rarity = rarityInt;
  }

  if (typeof rarity !== "number") {
    throw new Error(`Unknown rarity: ${rarity}`);
  }

  if (!(rarity in RarityColors)) {
    throw new Error(`Unknown rarity: ${rarity}`);
  }

  return RarityColors[rarity];
}
