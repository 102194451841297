import { GameSingletons } from "@game/app/GameSingletons";
import { range } from "@sdk/utils/range";
import { AchievementBadgeData } from "./AchievementBadgeData";
import { getAchievementBadgeDataBlueprint } from "./AchievementBadgeDataBlueprint";

export async function injetOttoExtraAchievements(achievementsList: AchievementBadgeData[] = []) {
  try {
    const { firebase } = GameSingletons.getGameContext();
    const userData = await firebase.getUserMainData();
    const extraAchievements = userData.extraAchievements || [];
    for (const [achievementId, enabled] of Object.entries(extraAchievements)) {
      if (!enabled) continue;
      const achievement = {
        ...getAchievementBadgeDataBlueprint(+achievementId),
      }
      if (!achievement) continue;
      achievementsList.push(achievement);
    }
  } catch (error) {
    console.error(`Error loading railroader achievements from Firebase:`, error);
  }
  return achievementsList;
}

const OTTO_ACHIEVEMENTS_STARTING_ID = 10;
export async function setOttoFriendshipLevel(level: number) {
  try {
    const { firebase } = GameSingletons.getGameContext();
    const updates = range(5).reduce((acc, i) => {
      acc[OTTO_ACHIEVEMENTS_STARTING_ID + i] = i < level;
      return acc;
    }, {} as { [key: number]: boolean });
    await firebase.updateUserMainData({ extraAchievements: updates });
    return level;
  } catch (error) {
    console.error(`Error loading railroader achievements from Firebase:`, error);
    return -1;
  }
}

export async function incrementOttoFriendshipLevel() {
  const currentLevel = await getCurrentOttoFriendshipLevel();
  const nextLevel = currentLevel + 1;
  return await setOttoFriendshipLevel(nextLevel);
}

/**
 * @returns A number between 0 and 5 (inclusive).
 */
export async function getCurrentOttoFriendshipLevel() {
  try {
    const { firebase } = GameSingletons.getGameContext();
    const userData = await firebase.getUserMainData();
    const extraAchievements = Object.values(userData.extraAchievements || {}).filter(v => v);
    return extraAchievements.length;
  } catch (error) {
    console.error(`Error loading railroader achievements from Firebase:`, error);
    return 0;
  }
}

