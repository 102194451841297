import { GameSingletons } from "@game/app/GameSingletons";
import { Dropdown } from "@game/ui/components/Dropdown";

export function testDropdownMenu() {
  const context = GameSingletons.getGameContext();

  const dropdown = new Dropdown(
    [
      { value: 0, text: "Test 1" },
      { value: 1, text: "Test 21" },
      { value: 2, text: "A Very Long Long Long Long Long Option" },
    ],
    {
      // boxScale: 0.25,
      boxPadding: 21,
      labelPadding: [5, 5, 5, 15],
      horizontalAlignment: 0.0,
      width: 500,
      height: 100,
      labelStyle: {
        fontSize: 30,
      },
      optionsOffset: [0, -30],
      optionsStyle: {
        // boxScale: 0.25,
        horizontalAlignment: 0.0,
        boxPadding: 21,
        labelPadding: [5, 15],
        labelStyle: {
          fontSize: 30,
        },
      },
    }
  );
  dropdown.position.set(500, 500);
  dropdown.playShowAnimation();
  dropdown.onOptionSelected = console.warn;

  context.stage.addChild(dropdown);
}
