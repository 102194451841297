import { GameSingletons } from "@game/app/GameSingletons";
import { GameViewMode } from "@game/app/main";
import { spawnSpriteWave } from "@game/asorted/animations/spawnSpriteWave";
import { EntityToDisplayObjectMapper } from "@game/asorted/EntityToDisplayObjectMapper";
import { getShortestRouteBetweenStations } from "@game/asorted/getShortestRouteBetweenStations";
import { DefaultTextStyle } from "@game/constants/defaults/DefaultTextStyle";
import { RegionIntegerId } from "@game/constants/RegionId";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { RailPath } from "@game/data/entities/RailPath";
import { StationEntity } from "@game/data/entities/StationEntity";
import { makeRevolvingTocIcon } from "@game/ui/fx/makeRevolvingTocIcon";
import { AdvancedRope } from "@game/world/visuals/AdvancedRope";
import { WorldZoomLevel } from "@game/world/World";
import { BLEND_MODES } from "@pixi/constants";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { ContractName, StationAssetId } from "@sdk-integration/contracts";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { lerp } from "@sdk/utils/math";

const PATH_ANIMATION_STAGGER = 0.05;

export function makeInstantTransmissionOverlay() {
  const context = GameSingletons.getGameContext();
  const { input, mapData } = context;

  const container = new EnchantedContainer();
  container.sortableChildren = true;

  const regionDimmer = new RegionDimmerLayer();
  const trackHighlights = new TrackHighlightsLayer();
  const stationSprites = new ImpactedStationsLayer();

  const trainIcon = new TrainIcon();
  trainIcon.zIndex = 90;
  trainIcon.scale.set(0);
  trainIcon.alpha = 0;
  container.addChild(trainIcon);

  const hint = new SelectDestinationHint();
  hint.position.set(0, -200);
  hint.scale.set(2);
  trainIcon.addChild(hint);

  function clear() {
    regionDimmer.tweenAlpha(0.0);
    trackHighlights.clear();
    stationSprites.clear();
    trainIcon.hide();
    context.main.popups.instantTransmissionDestinationPopup.setCurrentStation(null);
  }

  input.on({
    clickOnStation(station) {
      const main = GameSingletons.getMainInstance();
      if (main.viewMode !== GameViewMode.INSTANT_TRANSMISSION) return;

      if (main.selection.selectedDestination == station) {
        main.selection.selectedDestination = null;
      } else {
        main.selection.selectedDestination = station;
      }
    },
    async startInstantTransmission() {
      if (!context.main.instantTransmission.active) return;

      const { main, viewport, mapData, userDataCtrl, contracts, ticker, spinner } = context;
      await spinner.showDuring(
        contracts.actions.performActionTransaction(
          "instanttrans",
          {
            railroader: contracts.currentUserName,
            train: main.selection.selectedTrain?.name,
            stations_path: main.instantTransmission.pathStationIds.map(stationId => ({
              station_id: stationId,
              owner: mapData.stations.get(stationId)?.ownerName,
            })),
          },
          ContractName.RR
        )
      );

      const updatePromise = userDataCtrl.updateAll();

      context.main.popups.instantTransmissionDestinationPopup.setCurrentStation(null);

      //// ANIMATION ////

      await ticker.delay(0.7);

      if (main.world.getCurrentZoomLevel() === WorldZoomLevel.OPERATIONS) {
        viewport.follow(trainIcon);
      }
      for (const id of main.instantTransmission.pathStationIds) {
        const station = mapData.stations.get(id);
        if (!station) continue;
        await trainIcon.tweeener.to(trainIcon, {
          x: station.x,
          y: station.y,
          duration: 0.3,
          // ease: "power1.inOut",
          ease: "linear",
        });
      }
      viewport.plugins.remove("follow");

      await spinner.showDuring(updatePromise);

      main.selection.clear();
      main.setViewMode(GameViewMode.NORMAL);
    },
  });

  const activate = (startingStation: StationEntity) => {
    // console.warn(`// ACTIVATE //`);

    const { selection, instantTransmission } = GameSingletons.getMainInstance();

    instantTransmission.active = true;

    regionDimmer.setRegion(startingStation.regionId);
    container.addChild(regionDimmer);

    container.addChild(trackHighlights);

    container.addChild(stationSprites);

    trainIcon.position.copyFrom(startingStation);
    trainIcon.show();

    const stopWatching = container.enchantments.watch(
      () => selection.selectedDestination,
      destinationStation => {
        hint.visible = destinationStation == null;

        if (!destinationStation) {
          regionDimmer.tweenAlpha(0.7);
          trackHighlights.clear();
          stationSprites.clear();
          context.main.popups.instantTransmissionDestinationPopup.setCurrentStation(null);
        } else {
          regionDimmer.tweenAlpha(0.55);

          if (!context.main.selection.selectedTrain) return new Error("No train found");

          instantTransmission.pathStationIds = getShortestRouteBetweenStations(
            startingStation,
            destinationStation,
            context.main.selection.selectedTrain.maxDistance
          ).path;
          trackHighlights.highlightPath(instantTransmission.pathStationIds, selection.selectedTrain?.maxDistance);

          instantTransmission.pathStationIds.shift();
          const pathStations = instantTransmission.pathStationIds.map(id => mapData.stations.get(id)!);
          stationSprites.update(pathStations);

          context.main.popups.instantTransmissionDestinationPopup.setCurrentStation(destinationStation);
        }
      },
      true
    );

    return () => {
      // console.warn(`// DEACTIVATE //`);
      instantTransmission.active = false;
      stopWatching();
      clear();
    };
  };

  container.enchantments.watch.andCleanup(() => {
    const { viewMode, selection } = GameSingletons.getMainInstance() || {};
    return viewMode === GameViewMode.INSTANT_TRANSMISSION && selection.selectedStation;
  }, activate);

  return Object.assign(container, { trackHighlights });
}

class SelectDestinationHint extends Container {
  public readonly tweeener = new TemporaryTweeener(this);

  constructor() {
    super();

    const lineAString = "Select a station in another region to instantly arrive there";
    const lnA = new Text(lineAString.toUpperCase(), {
      ...DefaultTextStyle,
      align: "center",
      wordWrap: true,
      wordWrapWidth: 500,
    });
    lnA.anchor.set(0.5, 1.0);
    this.addChild(lnA);

    const lineBString = "(Minimum 5 station transmission)";
    const lnB = new Text(lineBString.toUpperCase(), {
      ...DefaultTextStyle,
      align: "center",
      fontSize: 17,
      lineHeight: 24,
    });
    lnB.anchor.set(0.5, 0.0);
    this.addChild(lnB);
  }
}

class RegionDimmerLayer extends Sprite {
  readonly tweenAlpha;
  private targetAlpha = 0;

  constructor() {
    super();

    this.anchor.set(0.5);
    this.scale.set(64);
    this.alpha = 0.0;
    this.blendMode = BLEND_MODES.MULTIPLY;

    this.tweenAlpha = (v: number) => (this.targetAlpha = v);
  }

  setRegion(region: string | number) {
    if (typeof region === "string") region = RegionIntegerId.fromRegionName(region);
    this.texture = Texture.from(`assets/images/worldmap/region-fogs/${region}.png`);
  }

  onEnterFrame() {
    this.visible = this.alpha > 0.02;

    this.alpha = lerp(this.alpha, this.targetAlpha, 0.03);
  }
}

class TrackHighlightsLayer extends Container {
  readonly trackTexture_Spot = Texture.from("railTrackSpot");
  readonly trackSprites = new EntityToDisplayObjectMapper(
    (track: RailPath, index: number, { maxDistance = NaN } = {} as any) => {
      const rope = new AdvancedRope(this.trackTexture_Spot, track.points, 0);
      const visual = TemporaryTweeener.withTweeener(rope);
      visual.tint = isNaN(maxDistance) || track.length <= maxDistance ? 0x00ffff : 0xff0000;
      visual.blendMode = BLEND_MODES.ADD;
      visual.tweeener.from(visual, {
        pixi: { pivotY: 400, alpha: 0 },
        duration: 0.5,
        delay: index * PATH_ANIMATION_STAGGER,
        ease: "power3.out",
      });
      return this.addChild(visual);
    },
    visual => {
      visual.tweeener
        .to(visual, {
          pixi: { alpha: 0 },
          duration: 0.5,
          overwrite: true,
        })
        .then(() => visual.destroy());
    },
    {
      maxDistance: NaN,
    }
  );

  highlightPath(stationIds: StationAssetId[], maxDistance: number = NaN) {
    const { mapData } = GameSingletons.getGameContext();
    const { faq } = GameSingletons.getMainInstance();
    const tracks = new Array<RailPath>();
    for (let i = 0; i < stationIds.length - 1; i++) {
      const stationA = mapData.stations.get(stationIds[i])!;
      const stationB = mapData.stations.get(stationIds[i + 1])!;
      const track = faq.getStationToStationRailTrack(stationA, stationB);
      if (!track) {
        console.error(`[-] No track between ${stationA.name} and ${stationB.name}`);
        continue;
      }
      tracks.push(track);
    }

    this.trackSprites.update(tracks, { maxDistance });
  }

  clear() {
    this.trackSprites.clear();
  }
}

class ImpactedStationsLayer extends Container {
  stationSprites = new EntityToDisplayObjectMapper(
    (station: StationEntity, index) => {
      const visual = this.makeStationVisual(station);
      visual.zIndex = station.y;
      visual.scale.set(5);
      visual.tweeener.from(visual, {
        pixi: { scale: 4, alpha: 0 },
        duration: 0.5,
        delay: index * PATH_ANIMATION_STAGGER,
        ease: "back.out",
      });
      return this.addChild(visual);
    },
    visual => {
      visual.tweeener
        .to(visual, { pixi: { scale: 4, alpha: 0 }, duration: 0.5, overwrite: true })
        .then(() => visual.destroy());
    }
  );

  update(stations: StationEntity[]) {
    this.stationSprites.update(stations);
  }

  makeStationVisual(data: StationEntity) {
    const { assets } = GameSingletons.getGameContext();
    const { x, y } = data;

    function makeSprite(texturePrefix: string | null, scale: number) {
      function getTexture(texturePrefix: string | null) {
        const textureSuffix = [`common`, `uncommon`, `rare`, `epic`, `legendary`, `mythic`][data.rarityLevel - 1];
        const textureName = texturePrefix ? `${textureSuffix}-${texturePrefix}` : `${textureSuffix}`;
        const texturePath = `station-sprites/${textureName}.png`;
        return assets.getTexture(texturePath);
      }

      const sprite = new Sprite(getTexture(texturePrefix));
      sprite.anchor.set(0.5);
      sprite.scale.set(scale);

      return Object.assign(sprite);
    }

    const MARKER_SCALE = 0.4;
    const building = makeSprite(null, MARKER_SCALE);
    const highlightGlow = makeSprite("highlight", MARKER_SCALE * 2);
    highlightGlow.tint = 0x30ffff;

    if (+data.assetId % 2 === 0) {
      building.scale.x *= -1;
      highlightGlow.scale.x *= -1;
    }

    ////

    const tweeener = new TemporaryTweeener(building);
    const container = Object.assign(new EnchantedContainer(), { data, building, tweeener });
    container.addChild(building, highlightGlow);
    container.position.set(x, y);

    return container;
  }

  clear() {
    this.stationSprites.clear();
  }
}

class TrainIcon extends Container {
  private readonly revolvingIcon;
  public readonly tweeener = new TemporaryTweeener(this);

  constructor() {
    super();

    this.scale.set(8);

    this.revolvingIcon = makeRevolvingTocIcon();
    this.addChild(this.revolvingIcon);

    setInterval(() => {
      if (!this.worldVisible) return;
      if (!this.worldAlpha) return;
      if (!this.parent) return;

      const { ticker } = GameSingletons.getGameContext();
      if (!ticker.started) return;

      spawnSpriteWave(
        "https://public.cx/1/o.png",
        {
          pixi: { scale: 0.4 },
          duration: 2.0,
        },
        {
          parent: this,
          blendMode: BLEND_MODES.SCREEN,
          tint: 0x00fffff,
          angle: Math.random() * 360,
          scaleMagnitude: 0.1,
          alpha: 0.35,
        }
      );
    }, 450);
  }

  show() {
    this.tweeener.to(this, { pixi: { scale: 8, alpha: 1 }, duration: 0.5, overwrite: true });
  }
  hide() {
    this.tweeener.to(this, { pixi: { scale: 0, alpha: 0 }, duration: 0.5, overwrite: true });
  }
}
