import {InteractionEvent} from "@pixi/interaction";
import { IPointData } from "@pixi/math";
import { image } from "d3";

export type Building = {
  name: string;
  buildingId: number;
  regionId: number;
  noTrans?:number[];
  rooms: RoomType[];
};

export type RoomType = {
  id: number;
  name: string;
  image: string;
  backDirection:string;
  hideHUD?:boolean;
  items: {
    id: number;
    name: string;
    image: string;
    zIndex: number;
    effect: string;
    fxMod?: number;
    fxMod2?: number;
    sound: string;
    fixed?:boolean;
    scale:number; //min scale
    itemAction: string;
    hover?: string[],
    screenAnchor: IPointData;
    screenMargin: IPointData;
  }[];
  clickableRegions: ClickableRegion[];
}

export interface ClickableRegion {
  x: number;
  y: number;
  width: number;
  height: number;
  nextRoomId: number;
  cursorImage?: string;
  onTrigger?: (event : InteractionEvent) => void;
}

export const buildingData: Building[] = [
  //Library
  {
    name: 'Centuryville Library',
    buildingId: 1,
    regionId: 1,
    noTrans:[3],
    rooms: [
      {
        id: 1,
        name: 'Main Room',
        image: 'clouds',
        items: [
            {
              id: 1,
              name: 'building',
              image: 'bldgTrees',
              zIndex:1,
              effect: "none",
              sound:"",
              scale: 1, 
              itemAction: '',
              hover: ["none"],
              screenAnchor: {
                x: .5,
                y: .45
              },
              screenMargin: {
                x: 0,
                y: 0
              },
            },

        ],
        backDirection:"",
        clickableRegions: [
          {
            x: 430,
            y: 360,
            width: 450,
            height: 300,
            nextRoomId: 2,
            cursorImage: "assets/images-webp/buildings/enter.png"
          }
        ]
      }, {
        id: 2,
        name: 'Lobby',
        image: 'library2',
        items: [
          /* {
            id: 1,
            name: 'bell',
            image: 'bell',
            zIndex:1,
            effect: "none",
            sound:"bell",
            scale: 1,
            itemAction: '',
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: 0
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          } */
        ],
        backDirection:"down",
        clickableRegions: [
          {
            x: 260,
            y: 190,
            width: 365,
            height: 280,
            nextRoomId: 3,
            cursorImage: "./assets/images/door-cursor.png"
          }
        ]
      },
      {
        id: 3,
        name: 'Reading Room',
        image: 'library3',
        backDirection:"left",
        items: [
          {
            id: 1,
            name: 'Chandelier',
            image: 'item1',
            zIndex:1,
            effect: "none",
            sound:"chain",
            scale: 1,
            itemAction: 'wiggle',
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: .1075
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },
          {
            id: 2,
            name: 'Volume 1',
            image: 'booksL',
            zIndex:1,
            effect: "none",
            sound:"bookBump",
            scale: .6,
            itemAction: "talesModal",
            hover: ["enlarge","tooltip"],
            screenAnchor: {
              x: .35,
              y: .6
            },
            screenMargin: {
              x: 50,
              y: 50
            },
          },
          {
            id: 3,
            name: 'Volume 2',
            image: 'booksM',
            zIndex:1,
            effect: "none",
            sound:"bookBump",
            scale: .5,
            itemAction: "talesModal",
            hover: ["enlarge","tooltip"],
            screenAnchor: {
              x: .55,
              y: .6
            },
            screenMargin: {
              x: 50,
              y: 50
            },
          },
          {
            id: 4,
            name: 'Volume 3',
            image: 'booksR',
            zIndex:1,
            effect: "none",
            sound:"bookBump",
            scale: .5,
            itemAction: "talesModal",
            hover: ["enlarge","tooltip"],
            screenAnchor: {
              x: .75,
              y: .6
            },
            screenMargin: {
              x: 50,
              y: 50
            },
          },
          {
            id: 5,
            name: 'Chandelier',
            image: 'lights',
            zIndex:2,
            effect: "flickering",
            sound:"chain",
            scale: 1,
            itemAction: "wiggle",
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: .1
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },

        ],
        clickableRegions: []
      }
    ]
  },
  //Arcade
  {
    name: 'Choopy McBoopers',
    buildingId:2,
    regionId: 1,
    noTrans:[3,4],
    rooms: [
      //Outside
      {
        id: 1,
        name: 'Outside',
        image: 'a1Sky',
        items: [
            {
              id: 1,
              name: 'building',
              image: 'a1Main',
              zIndex:1,
              effect: "none",
              sound:"",
              scale: .9, 
              itemAction: '',
              hover: ["none"],
              screenAnchor: {
                x: .5,
                y: .5
              },
              screenMargin: {
                x: 0,
                y: 0
              },
            },

        ],
        backDirection:"",
        clickableRegions: [
          {
            x: 450,
            y: 350,
            width: 500,
            height: 300,
            nextRoomId: 2,
            cursorImage: "Choopy Outside"
          }
        ]
      }, 
      //Inside Main
      {
        id: 2,
        name: 'Choopy Inside',
        image: 'a2bg',
        items: [
           {
            id: 1,
            name: 'choopySign',
            image: 'a2sign',
            zIndex:1,
            effect: "none",
            sound:"",
            scale: 1.1,
            itemAction: '',
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: .2
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },
          {
            id: 2,
            name: 'choopySign',
            image: 'a2signLayer2',
            zIndex:2,
            effect: "flickering",
            sound:"choopy8bit",
            scale: 1.1,
            itemAction: 'particle',
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: .2
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          }  
        ],
        backDirection:"down",
        clickableRegions: [
          {
            x: 730,
            y: 370,
            width: 550,
            height: 800,
            nextRoomId: 3,
            cursorImage: "Choopy Inside 1"
          },
          {
            x: 250,
            y: 250,
            width: 300,
            height: 400,
            nextRoomId: 4,
            cursorImage: "Wanted Board"
          }
        ]
      },
      //Arcade Machines
      {
        id: 3,
        name: 'Arcade Machines',
        image: 'a3bg',
        backDirection:"down",
        hideHUD: true,
        items: [
          {
            id: 1, //The Ids match up with the miniGame ids in miniGameData.ts
            name: 'Left Machine',
            image: 'machineL',
            zIndex:1,
            effect: "none",
            sound:"clickTiny",
            scale: .95,
            itemAction: 'miniGameModal',
            hover: ["enlarge"],
            screenAnchor: {
              x: .14,
              y: .55
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },
          {
            id: 2,
            name: 'Middle Machine',
            image: 'machineM',
            zIndex:2,
            effect: "none",
            sound:"clickTiny",
            scale: .95,
            itemAction: "miniGameModal",
            hover: ["enlarge"],
            screenAnchor: {
              x: .5,
              y: .5125
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },
          {
            id: 3,
            name: 'Right Machine',
            image: 'machineR',
            zIndex:1,
            effect: "none",
            sound:"clickTiny",
            scale: .95,
            itemAction: "miniGameModal",
            hover: ["enlarge"],
            screenAnchor: {
              x: .86,
              y: .55
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },
        ],
        clickableRegions: []
      },
      //Wanted
      {
        id: 4,
        name: 'Wanted Board',
        image: 'a4bg',
        backDirection:"down",
        items: [
          {
            id: 1,
            name: 'stickyNote',
            image: 'devsNote',
            zIndex:1,
            effect: "none",
            sound:"clickTiny",
            scale: .95,
            itemAction: 'wantedModal',
            hover: ["enlarge"],
            screenAnchor: {
              x: .3,
              y: .62
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          }
        ],
        clickableRegions: []
      }
    ]
  },
  //Museum
  {
    name: 'Museum of Locomotion',
    buildingId:1,
    regionId: 3,
    noTrans:[2],
    rooms: [
      {
        id: 1,
        name: 'Outside',
        image: 'm1sky',
        items: [
          {
            id: 1,
            name: 'building',
            image: 'm1main',
            zIndex:1,
            effect: "",
            sound:"",
            scale: .9,
            itemAction: '',
            hover: ["none"],
            screenAnchor: {
              x: .5,
              y: .5
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          },

      ],
      backDirection:"down",
        clickableRegions: [
          {
            x: 300,
            y: 400,
            width: 500,
            height: 300,
            nextRoomId: 2,
            cursorImage: "assets/images-webp/buildings/enter.png"
          }
        ]
      }, {
        id: 2,
        name: 'Foyer',
        image: 'm2main',
        backDirection:"down",
        items: [
          {
            id: 1,
            name: 'train',
            image: 'mTrain',
            zIndex:1,
            effect: "",
            sound:"",
            scale: 1,
            itemAction: 'wiggle',
            fxMod:.01, //duration
            fxMod2:.01, //strength
            hover: ["none"],
            screenAnchor: {
              x: .43,
              y: .8
            },
            screenMargin: {
              x: 0,
              y: 0
            },
          }
        ],
        clickableRegions: [
          {
            x: 50,
            y: 100,
            width: 50,
            height: 50,
            nextRoomId: 3,
            cursorImage: "./assets/images/door-cursor.png"
          }
        ]
      }
    ]
  }
  // more buildings here...
];
