import { Application } from "@pixi/app";
import { MultipleReasons } from "@sdk/core/MultipleReasons";
import { CallbackList } from "@sdk/utils/callbacks/CallbackList";

export class RenderLoopService {
  public readonly reasonsToNotRender = new MultipleReasons();
  public readonly beforeRender = new CallbackList();
  public readonly afterRender = new CallbackList();

  public autoPauseOnPageInvisible = false;

  public running = false;

  constructor(private readonly app: Application, private readonly ticker = app.ticker) {}

  start(ticker = this.ticker) {
    if (this.running) throw new Error("RenderLoopService already running");

    this.running = true;

    function frameLoop(this: RenderLoopService) {
      if (this.reasonsToNotRender.hasAny()) return;
      if (this.autoPauseOnPageInvisible && document.visibilityState !== "visible") return;
      this.beforeRender.callAll();
      this.app.render();
      this.afterRender.callAll();
    }

    ticker.add(frameLoop, this, -100);

    return () => {
      this.running = false;
      ticker.remove(frameLoop);
    };
  }

  pause() {
    return this.reasonsToNotRender.add("Manually Paused");
  }

  unpause() {
    this.reasonsToNotRender.remove("Manually Paused");
  }
}
