import { GameSingletons } from "@game/app/GameSingletons";
import { RailroaderDashPanelType } from "@game/ui/railroader-dash/panels/models";
import { StoryDataService } from "@game/ui/railroader-dash/panels/story/StoryDataService";
import { playStoryEncounterCinematic } from "./playStoryEncounterCinematic";
import { showStoryEncounterModal } from "./showStoryEncounterModal";

export async function checkForStoryProgress() {
  const context = GameSingletons.getGameContext();
  const { spinner, firebase } = context;

  const getFirestoreUserData = spinner.bindSpinner(firebase.getUserMainData);
  const getFirestoreStoryConfiguration = spinner.bindSpinner(firebase.getStoryConfiguration);
  const setUserStoryWatched = spinner.bindSpinner(firebase.setUserStoryWatched);

  const firestoreUserData = await getFirestoreUserData();
  console.log({ firestoreUserData });

  const firestoreStoryConfiguration = await getFirestoreStoryConfiguration();
  console.log({ firestoreStoryConfiguration });

  const chapterIds = Object.keys(firestoreStoryConfiguration).filter(
    key => !firestoreStoryConfiguration[key].disabled && firestoreStoryConfiguration[key].startAt.toDate() < new Date()
  );
  console.log({ chapters: chapterIds });

  // let totalDistance: number | null = null;
  const findNextChapter = async () => {
    chapterIds.sort();
    for (const chapterId of chapterIds) {
      if (firestoreUserData.storyUnlocked?.[chapterId] == true) continue;
      if (firestoreUserData.storyWatched?.[chapterId] == true) continue;

      const cfg = firestoreStoryConfiguration[chapterId];

      const storyStartDate = cfg.startAt.toDate();
      console.log({ chapterId, storyStartDate, cfg });

      const dataService = new StoryDataService();
      const totalDistance = (await dataService.getTotalTravelDistance(storyStartDate)) as number;
      if (totalDistance < cfg.minDistance) continue;
      // if (totalDistance === null) {
      //   totalDistance = (await dataService.getTotalTravelDistance(storyStartDate)) as number;
      // }
      // if (totalDistance < cfg.minDistance) continue;

      return chapterId;
    }
  };

  const nextChapter = await findNextChapter();
  console.log({ nextChapter });

  if (nextChapter) {
    const userHasUnwatchedStoryEncounters = Object.keys(firestoreUserData.storyUnlocked ?? []).some(
      chapterId =>
        chapterId != nextChapter &&
        firestoreStoryConfiguration[chapterId]?.disabled != true &&
        firestoreUserData.storyUnlocked?.[chapterId] == true &&
        firestoreUserData.storyWatched?.[chapterId] != true
    );
    console.log({ userHasUnwatchedStoryEncounters });
    const setUserStoryUnlockedPromise = firebase.setUserStoryUnlocked(nextChapter);
    const modalResult = await showStoryEncounterModal(userHasUnwatchedStoryEncounters);
    if (modalResult == "play") {
      await spinner.showDuring(setUserStoryUnlockedPromise);
      await playStoryEncounterCinematic(nextChapter, true);
      await setUserStoryWatched(nextChapter);
    } else if (modalResult == "openDash") {
      await context.input.emit("openRailRoaderDashboard", RailroaderDashPanelType.Story);
    }
  }
}
