import { SolidDimmer } from "@game/ui/common/SolidDimmer";
import { LoaderResource } from "@pixi/loaders";
import { CallbackList } from "@sdk/utils/callbacks/CallbackList";
import { NPCEncounterCinematic } from "../NPCEncounterCinematic";
import { NPCEncounterCinematicData } from "../types/NPCEncounterCinematicData";
import { loadResourcesFromManifest, ResourcesMinifest } from "./loadResourcesFromManifest";

export class NPCEncounterCinematic_FromAirtable extends NPCEncounterCinematic {
  public readonly resources: Record<string, LoaderResource> = {};

  constructor(protected readonly data: NPCEncounterCinematicData, protected readonly manifest: ResourcesMinifest) {
    super(data, () => Promise.resolve());
  }

  public async loadAssets() {
    console.log("Loading NPCEncounterCinematic_FromAirtable assets...");

    const resources = await loadResourcesFromManifest(this.manifest);
    Object.assign(this.resources, resources);

    if (!this.textures) this.textures = {};
    for (const [key, resource] of Object.entries(resources)) {
      if (resource.texture) {
        this.textures[key] = resource.texture;
      }
    }
  }

  async playIntroAnimation() {
    console.log("INTRO DONE!");
  }

  private playMusicTrack() {
    const filename = this.data.music;
    if (!filename) return;

    const sound = this.resources[filename]?.sound;
    if (!sound) return console.error(`Music resource missing from loaded assets: "${filename}"`);

    sound.play();

    return () => sound.stop();
  }

  async play() {
    const { ticker } = this.context;

    const cleanUpCallbacks = new CallbackList();

    /**
     * Even though on construction the dimmer's alpha is zero,
     * we instantiate it all the way back here, to prevent the user
     * from interacting with the world behind the cinematic.
     */
    const dimmer = new SolidDimmer();
    dimmer.interactive = true;
    this.addChildAt(dimmer, 0);
    cleanUpCallbacks.push(() => dimmer.hide().then(() => dimmer.destroy()));

    try {
      await this.letterBox.playShowAnimation();

      await this.playIntroAnimation();

      dimmer.show();

      const stopMusic = this.playMusicTrack();
      if (stopMusic) cleanUpCallbacks.push(stopMusic);

      const backdropData = this.data.background;
      if (backdropData) {
        const backdropTexture = this.resources[backdropData.texture]?.texture;
        if (!backdropTexture) {
          console.error(`Background resource missing from loaded assets: "${backdropData.texture}"`);
        } else {
          const backdrop = this.addStaticCinematicBackdrop(backdropTexture);
          backdrop.position.copyFrom(this.data.background);
          backdrop.scale.set(this.data.background.scale);
          await Promise.all([
            this.tweeener.from(backdrop, {
              pixi: { scale: backdrop.scale.x * 1.2, alpha: 0 },
              duration: 0.7,
              overwrite: "auto",
            }),
          ]);

          cleanUpCallbacks.push(() =>
            this.tweeener.to(backdrop, {
              pixi: { scale: backdrop.scale.x * 1.05, alpha: 0 },
              duration: 0.7,
              ease: "power.in",
              overwrite: "auto",
              onComplete: () => {
                backdrop.destroy();
              },
            })
          );
        }
      }

      await ticker.delay(0.3);

      await this.playStorySlides(this.data, () => Promise.resolve());
    } catch (error) {
      console.error(error);
    } finally {
      cleanUpCallbacks.reverseCallAllAndClear();

      await ticker.delay(0.87);

      await this.letterBox.playHideAnimation();

      this.destroy();
    }
  }
}
