export class HttpApiService {
  public readonly headers: Record<string, string> = {};

  constructor(protected readonly urlBase: string) {}

  async get<T extends {}>(endpoint: string, options: Record<string, string | number | boolean | undefined> = {}) {
    const url = new URL(endpoint, this.urlBase);

    if (options) {
      for (const [key, value] of Object.entries(options)) {
        if (value != undefined) {
          url.searchParams.set(key, value.toString());
        }
      }
    }

    const response = await fetch(url.toString(), { headers: this.headers });

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    try {
      return (await response.json()) as T;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  public setBearerToken(token: string) {
    this.headers["Authorization"] = `Bearer ${token}`;
  }
}
