import "@debug/__";
import { __window__ } from "@debug/__";
import { __startExperiments } from "@debug/__experiment";
import { __urlParams__ } from "@game/app/__urlParams__";
import { editDecorationPlacement } from "@debug/editor/editDecorationPlacement";
import { editStationPlacement } from "@debug/editor/editStationPlacement";
import { overrideStationClick } from "@debug/experiments/overrideStationClick";
import { overrideVoidClick } from "@debug/experiments/overrideVoidClick";
import { addFpsGraph } from "@debug/tweakpane";
import { loadDebugPersistentSettings } from "@debug/__DEBUG_SETTINGS__";
import { GameContext, UninitializedGameContext } from "@game/app/app";
import { delay } from "@sdk/utils/promises";
import { overrideDataServices } from "./mock-data";
import { GameSingletons } from "@game/app/GameSingletons";
import { __startOverrides } from "./__overrides";

//// //// //// //// //// //// //// //// //// //// //// //// ////
//// //// //// //// //// //// //// //// //// //// //// //// ////
//// //// //// //// //// //// //// //// //// //// //// //// ////
export const __DEBUG__ = __urlParams__.debug && !location.hostname.includes("trains.cards");
export const __MOCK__ = __urlParams__.mock && !location.hostname.includes("trains.cards");
//// //// //// //// //// //// //// //// //// //// //// //// ////
//// //// //// //// //// //// //// //// //// //// //// //// ////
//// //// //// //// //// //// //// //// //// //// //// //// ////

export async function __onUninitializedContextCreated(uninitializedContext: UninitializedGameContext) {
  Object.assign(window, uninitializedContext, { context: uninitializedContext });

  await import("./global-space/assign-game-classes-to-window");
  await import("./global-space/assign-misc-classes-to-window");
  await import("./global-space/assign-pixi-classes-to-window");

  loadDebugPersistentSettings();

  uninitializedContext.events.waitFor("ready").then(() => onGameReady());

  GameSingletons.setGameContextRef(uninitializedContext as any);
  if (__MOCK__) overrideDataServices();
}

function onGameReady() {
  const context = GameSingletons.getGameContext();
  Object.assign(window, context, { context });

  const editMode = __urlParams__.edit;

  if (editMode) {
    context.main.hud.reasonsToHideCogWheelMenu.add("EditModeEnabled");
  }

  if (editMode === "decor") {
    editDecorationPlacement();
  } else if (editMode) {
    editStationPlacement();
  } else {
    try {
      addFpsGraph(context.ticker);
      // addInspectionTools(context);

      document.addEventListener("mousedown", event => {
        if (event.button === 1) __window__.__MIDDLE_MOUSE_BUTTON__ = true;
      });
      document.addEventListener("mouseup", () => {
        delay(1.0).then(() => (__window__.__MIDDLE_MOUSE_BUTTON__ = false));
      });

      // debugConfiguration(context);
      overrideStationClick();
      overrideVoidClick();

      __startOverrides();

      __startExperiments(context);
    } catch (e) {
      console.error(e);
    }
  }
}
