import { GameSingletons } from "@game/app/GameSingletons";
import { tweenTintProperty } from "@game/asorted/animations/tweenTintProperty";
import { RailPath } from "@game/data/entities/RailPath";
import { AdvancedRope } from "@game/world/visuals/AdvancedRope";
import { Rectangle } from "@pixi/math";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { findLRTB } from "@sdk/utils/math2D";

export class RailwayTrackVisual {
  /**
   * Pre-baked left, right, top and bottom bounds of the mesh.
   *
   * Useful for determining if a rail-track mesh is likely to intersect with a rectangle.
   *
   * Culling tasks would be a common example for when you would want to skip rendering
   * objects that are not in the viewport.
   */
  readonly lrtb: [number, number, number, number];
  readonly bounds: Rectangle;

  readonly isBridge: boolean;

  readonly layers: AdvancedRope[];
  readonly bridgeLayers: AdvancedRope[] | null;
  readonly dirtLayer: AdvancedRope | null;
  readonly bottomLayer: AdvancedRope;
  readonly topLayer: AdvancedRope;
  readonly glowLayer: AdvancedRope;

  public isMouseOver = false;
  public _glowColor: null | number;

  public get isVisible() {
    return this.topLayer.worldVisible;
  }

  constructor(public readonly railPath: RailPath, private readonly tweeener: TemporaryTweeener) {
    const { points, stationA, stationB } = railPath;

    this.layers = [];
    this.lrtb = findLRTB(points);
    this.bounds = new Rectangle(this.lrtb[0], this.lrtb[2], this.lrtb[1] - this.lrtb[0], this.lrtb[3] - this.lrtb[2]);
    this.isBridge = stationA?.region != stationB?.region;

    const { assets } = GameSingletons.getGameContext();
    const trackTexture_RailTrackDirt = assets.getTexture("railTrackDirt");
    const trackTexture_RailTrack = assets.getTexture("railTrack");
    const trackTexture_Bridge = assets.getTexture("railTrackBridge");
    const trackTexture_BridgeBase = assets.getTexture("railTrackBridgeBase");
    const trackTexture_Abstract = assets.getTexture("railTrackAbstract");

    if (!this.isBridge) {
      this.bridgeLayers = null;
      this.dirtLayer = this.addTrackLayer([trackTexture_RailTrackDirt, points, 1.1], {
        tint: 0x656565,
        y: 10,
        alpha: 0.9,
        zIndex: -1,
      });
    } else {
      this.dirtLayer = null;
      this.bridgeLayers = [
        this.addTrackLayer([trackTexture_BridgeBase, points, 0.7], {
          tint: 0x505050,
          y: 55,
        }),
        this.addTrackLayer([trackTexture_Bridge, points, 0.7], {
          tint: 0x505050,
          y: 35,
        }),
        this.addTrackLayer([trackTexture_Bridge, points, 0.7], {
          tint: 0x656565,
          y: 12,
        }),
      ];
    }

    this.bottomLayer = this.addTrackLayer([trackTexture_RailTrack, points, 0.7], {
      tint: 0x606060,
      y: 10,
    });

    this.topLayer = this.addTrackLayer([trackTexture_RailTrack, points, 0.7], {});

    this.glowLayer = this.addTrackLayer([trackTexture_Abstract, points, 1.2], {});

    this._glowColor = null;
    this.glowLayer.alpha = 0;

    this.topLayer.on("pointerover", () => (this.isMouseOver = true));
    this.topLayer.on("pointerout", () => (this.isMouseOver = false));
  }

  public get pointerOverEnabled() {
    return this.topLayer.interactive;
  }

  public set pointerOverEnabled(value: boolean) {
    this.topLayer.interactive = value;

    if (!value && this.isMouseOver) {
      this.isMouseOver = false;
    }
  }

  public set glowColor(color: number | null) {
    const prevColor = this._glowColor;
    this._glowColor = color;

    if (color == null) {
      this.tweeener.to(this.glowLayer, {
        alpha: 0,
        duration: 0.85,
      });
    } else {
      if (this.glowLayer.alpha > 0) {
        tweenTintProperty(this.glowLayer, color, 0.85);
      } else {
        this.glowLayer.tint = color;
      }
      this.tweeener.to(this.glowLayer, { alpha: 1, duration: 0.45 });
    }
  }

  public get glowColor() {
    return this._glowColor;
  }

  addTrackLayer(args: ConstructorParameters<typeof AdvancedRope>, mods: Partial<AdvancedRope>) {
    const rope = Object.assign(new AdvancedRope(...args), mods);
    rope.name = args[0].textureCacheIds[0];
    this.layers.push(rope);
    return rope;
  }
}
