import { env } from "@game/app/global";
import { SocialProfileDataService } from "@game/ui/social/SocialProfileDataService";

export function __startOverrides() {
  if (env.BLOCKCHAIN === "testnet") {
    // `brhig.wam` `hlzb2.wam` `1s2ug.wam`
    const testUser = `hlzb2.wam`;
    const proto = SocialProfileDataService.prototype;
    proto.getAchievements = proto.getAchievements.bind(null, testUser);
    // proto.getFeaturedAchievements = proto.getFeaturedAchievements.bind(null, testUser);
  }
}
