import { getCardPreviewTextureUrl } from "@game/cards/getCardPreview";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { CardAssetData_CommodityLoadable } from "@sdk-integration/contracts";
import { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";

export type TrainStatusPanelParams = {
  trainName: string;
  trainComposition: string;
  locoTextureId: string | null;
  runs: string;
  distances: string;
  commodityAmounts: Record<string, number>;
  railCars: { rarity: string }[];
  currentCondition: number;
};

export module TrainStatusPanelParams {
  export function fromTrain(train: ReadonlyObjectDeep<TrainEntity>) {
    if (!train.locomotive) throw new Error("Train has no locomotive");
    const locoHeaderTextureId = getCardPreviewTextureUrl(train.locomotive);

    //// Get commodities
    const commodityAmountsMap: Record<string, number> = {};
    for (const card of train.iterateAllLoadedCommodities()) {
      const cardData = card.data as CardAssetData_CommodityLoadable;
      if (!commodityAmountsMap[cardData.type]) {
        commodityAmountsMap[cardData.type] = parseInt(cardData.volume);
      } else {
        commodityAmountsMap[cardData.type] += parseInt(cardData.volume);
      }
    }

    const railCarRarity: { rarity: string }[] = [];
    for (const railCar of train.railCars) {
      railCarRarity.push({ rarity: railCar.rarityString });
    }

    const data: TrainStatusPanelParams = {
      trainName: train.name,
      trainComposition: train.locomotive.data.composition.toLowerCase(),
      locoTextureId: locoHeaderTextureId,
      runs: "", //// train.total runs
      distances: "", //// train.total distance
      commodityAmounts: commodityAmountsMap,
      railCars: railCarRarity,
      currentCondition: train.conditionFraction,
    };

    return data;
  }
}
