import { FontFamily } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { Text } from "@pixi/text";

export const makeRegionLabels = () => {
  const container = new Container();

  type RegionLabelDatum = [name: string, x: number, y: number];
  const data = [
    [`Centuryville`, -2080, 25],
    [`Pemberton Heights`, -17843, -17738],
    [`Trevithick Pines`, 10445, -19186],
    [`Pawpaw Plains`, 15284, 18863],
    [`James Park`, -19275, 22935],
  ] as RegionLabelDatum[];

  const labels = new Array<Text>();
  for (const [name, x, y] of data) {
    const label = new Text(name.toUpperCase(), {
      fontFamily: FontFamily.Default,
      fontSize: 48,
      fill: 0xffffff,
      align: "center",

      stroke: "#08080840",
      strokeThickness: 4,

      dropShadow: true,
      dropShadowAlpha: 0.2,
      dropShadowColor: "#000000",
      dropShadowBlur: 2,
      dropShadowAngle: Math.PI / 2,
      dropShadowDistance: 2,
    });
    label.name = `Region: ` + name;
    label.anchor.set(0.5);
    label.scale.set(36);
    label.position.set(x, y);
    label.alpha = 0.8;

    labels.push(label);
    container.addChild(label);
  }

  return Object.assign(container, { data, labels });
};
