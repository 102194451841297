import { GameSingletons } from "@game/app/GameSingletons";
import { createUpdatesTypeModal } from "@game/ui/asorted/updatesModal/createUpdatesModalBase";
import { Point } from "@pixi/math";
import { DropdownMenuButtonOptions } from "../../popups/components/DropdownMenu";
import { RailroaderDashPanelType } from "../../railroader-dash/panels/models";

export function openMainDropdownMenu(x: number, y: number) {
  const { sfx, music, input, userDataCtrl, spinner } = GameSingletons.getGameContext();

  const buttonsOptions: DropdownMenuButtonOptions[] = [
    {
      text: "Updates",
      disabled: false,
      onClick: async () => createUpdatesTypeModal(false),
      style: {},
    },
    {
      text: "Settings",
      disabled: false,
      onClick: () => {
        input.emit("openRailRoaderDashboard", RailroaderDashPanelType.Settings);
      },
    },
    {
      text: music.muted ? "Unmute Music" : "Mute Music",
      disabled: false,
      onClick: () => {
        if (music.muted) {
          music.unmute();
        } else {
          music.mute();
        }
        menu.destroy();
      },
    },
    {
      text: sfx.isMuted ? "Unmute SFX" : "Mute SFX",
      disabled: false,
      onClick: () => {
        if (sfx.isMuted) {
          sfx.unmute();
        } else {
          sfx.mute();
        }
        menu.destroy();
      },
    },
    {
      text: "Refresh Data",
      disabled: false,
      onClick: async () => spinner.showDuring(userDataCtrl.updateAll()),
      style: {},
    },
    {
      text: "Log Out",
      disabled: false,
      onClick: async () => input.emit("logOut"),
      style: {},
    },
  ];

  if (document.body.requestFullscreen != undefined) {
    buttonsOptions.splice(1, 0, {
      text: "Fullscreen",
      disabled: false,
      onClick: () => {
        document.body.requestFullscreen?.();
      },
    } as DropdownMenuButtonOptions);
  }

  const slightPivotingOffset = new Point(20, 20);
  const contextMenuManager = GameSingletons.getMainInstance().hud.contextMenu;
  const menu = contextMenuManager.setCurrentMenu(buttonsOptions);
  menu.position.set(x - slightPivotingOffset.x, y + slightPivotingOffset.y + 40);
  menu.pivot.set(menu.containerWidth - slightPivotingOffset.x, slightPivotingOffset.y);

  return menu;
}
