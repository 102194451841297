export enum RarityInteger {
  Common = 1,
  Uncommon = 2,
  Rare = 3,
  Epic = 4,
  Legendary = 5,
  Mythic = 6,
  CT =7,
}

export enum Rarity {
  Common = "common",
  Uncommon = "uncommon",
  Rare = "rare",
  Epic = "epic",
  Legendary = "legendary",
  Mythic = "mythic",
  CT ="Century Train",
}

export module Rarity {
  const list = [Rarity.Common, Rarity.Uncommon, Rarity.Rare, Rarity.Epic, Rarity.Legendary, Rarity.Mythic, Rarity.CT] as string[];

  export function toNumberFromZero(rarity: Rarity | string): number {
    rarity = rarity.trim().toLowerCase();
    const index = list.indexOf(rarity);
    if (index === -1) {
      throw new Error(`Unknown rarity: "${rarity}". Valid strings are: [${list}]`);
    }
    return index;
  }

  export function toNumberFromOne(rarity: Rarity): number {
    return toNumberFromZero(rarity) + 1;
  }

  export function fromNumberFromOne(rarity: number): Rarity {
    return list[rarity - 1] as Rarity;
  }

  export function fromNumberFromZero(rarity: number): Rarity {
    return list[rarity] as Rarity;
  }

  export function fromString(rarity: string): Rarity {
    rarity = rarity.trim().toLowerCase();
    const index = list.indexOf(rarity);
    if (index === -1) {
      throw new Error(`Unknown rarity: "${rarity}". Valid strings are: [${list}]`);
    }
    return list[index] as Rarity;
  }
}
