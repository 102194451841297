import { lipsum } from "@debug/utils/lipsum";
import { onKeyPressWithAlt } from "@debug/utils/onKeyPress";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { ToolTipComponent } from "@game/ui/popups/components/ToolTipComponent";
import { InteractionManager } from "@pixi/interaction";

/***************************************\
| Test Tooltip Creation and Positioning |
\***************************************/
export function testTooltips() {
  const context: GameContext = GameSingletons.getGameContext();

  const { app, viewSize } = context;

  const interaction = app.renderer.plugins.interaction as InteractionManager;
  const mousePosition = interaction.mouse.global;

  onKeyPressWithAlt("n", () => {
    // const H = mousePosition.x > viewSize.width * 0.5 ? 1 : -1;
    const H = (mousePosition.x / viewSize.width) * 2 - 1;
    const V = mousePosition.y > viewSize.height * 0.5 ? 1 : -1;
    const tol = new ToolTipComponent(lipsum.line(), H, V);
    tol.position.copyFrom(mousePosition);
    context.stageContainers._debug.addChild(tol);
  });
}
