import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { RailPath } from "@game/data/entities/RailPath";
import { AdvancedRope } from "@game/world/visuals/AdvancedRope";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { EnchantmentGlobals } from "@sdk/pixi/enchant/EnchantmentGlobals";

class TrackVisual extends AdvancedRope {
  pulseAnimationEnabled = true;

  onEnterFrame() {
    if (this.pulseAnimationEnabled) {
      this.alpha = Math.abs(Math.sin(1.87 * EnchantmentGlobals.timeTotal));
    }
  }
}

export const makeOngoingRunTracks = () => {
  const context = GameSingletons.getGameContext();
  const { assets, userData } = context;

  const container = new EnchantedContainer();
  const trackToVisualMap = new Map<RailPath, TrackVisual>();

  const tweeener = new TemporaryTweeener(container);
  const trackTexture_Spot = assets.getTexture("railTrackSpot");

  container.enchantments.watch.array(
    () => {
      return userData.trainsArray.map(train => context.main?.faq.getTrainCurrentTrack(train));
    },
    (tracks: (RailPath | null)[]) => {
      console.log(`[-] Tracks are now: `, tracks);

      for (const [track, visual] of trackToVisualMap.entries()) {
        if (tracks.includes(track)) continue;
        trackToVisualMap.delete(track);

        visual.pulseAnimationEnabled = false;
        tweeener
          .to(visual, {
            alpha: 0,
            duration: 1.2,
          })
          .then(() => visual.destroy({ children: true }));
      }

      for (const track of tracks) {
        if (!track) continue;
        if (trackToVisualMap.has(track)) continue;
        const visual = new TrackVisual(trackTexture_Spot, track.points, 0);
        visual.tint = 0x00ffff;
        container.addChild(visual);
        trackToVisualMap.set(track, visual);
      }
    }
  );

  return container;
};
