import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { EnchantmentGlobals } from "@sdk/pixi/enchant/EnchantmentGlobals";

export function makeRevolvingTocIcon() {
  const assets = GameSingletons.getResources();
  const partInner = assets.makeSprite("tocIconPartInner");
  const partOuter = assets.makeSprite("tocIconPartOuter");

  partInner.anchor.set(0.5);
  partOuter.anchor.set(0.5);

  const container = new Container();
  container.addChild(partInner, partOuter);

  partInner.tint = 0x00fefe;
  partOuter.tint = 0x00fefe;

  Object.assign(container, {
    onEnterFrame() {
      partOuter.angle = -35 * EnchantmentGlobals.timeTotal;
    },
  });

  return container;
}
