import { BLEND_MODES, WRAP_MODES } from "@pixi/constants";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

export function addCloudsToOperationsLayer(operationsLayer: Container) {
  const clouds = new Sprite();
  clouds.anchor.set(0.5, 0.5);
  clouds.scale.set(96, 32);
  clouds.alpha = 0.35;
  clouds.blendMode = BLEND_MODES.MULTIPLY;
  operationsLayer.addChild(clouds);

  const result = Object.assign(clouds, {
    speed: 0.075,
    onEnterFrame() {
      result.texture.frame.x -= result.speed;
      result.texture.updateUvs();
    },
  });

  Texture.fromURL("https://public.cx/mock/clouds.png").then(texture => {
    texture.baseTexture.wrapMode = WRAP_MODES.MIRRORED_REPEAT;
    result.texture = texture;

    const tweeener = new TemporaryTweeener(result);
    tweeener.from(result, { alpha: 0.0, duration: 2.0 });
  });

  return result;
}
