import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { GlowFilter } from "@pixi/filter-glow";
import { Sprite } from "@pixi/sprite";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { RailroaderDashPanelType } from "../models";
import { RailroaderDashPanelBase } from "../RailroaderDashPanelBase";

export class LeaderboardComponentFactory {
  constructor(public readonly panel: RailroaderDashPanelBase) {}

  public addMinigameButtons(selectedType: "normal" | "arcade" | null) {
    const addButton = (type: "normal" | "arcade") => {
      const textureId = `ui-railroader-dashboard/page-leaderboards/arcade/btn-${type}-leaderboard.png`;
      const texture = Texture.from(textureId);
      const button = Sprite.from(texture);
      button.interactive = true;
      button.buttonMode = true;
      button.anchor.set(0.5);
      button.scale.set(0.8);
      if (type === selectedType) {
        button.filters = [
          new GlowFilter({
            color: 0x00ffff,
            distance: 18.2,
            outerStrength: 2,
            // innerStrength: 1,
          }),
        ];
      }
      this.panel.addChild(button);
      buttonizeDisplayObject(button, {
        onTrigger: () => {
          const main = GameSingletons.getMainInstance();
          const panelType =
            type === "normal" ? RailroaderDashPanelType.Leaderboards : RailroaderDashPanelType.ArcadeLeaderboards;
          main.hud.railroaderDashboard.currentDashboard?.setCurrentCentralPanelType(panelType);
        },
      });
      return button;
    };

    const buttonNormalLeaderboard = addButton("normal");
    buttonNormalLeaderboard.position.set(115, 845);

    const buttonArcadeLeaderboard = addButton("arcade");
    buttonArcadeLeaderboard.position.set(855, 845);
  }
}
