import { StationEntity } from "./StationEntity";
import { MathEuler } from "@sdk/utils/MathEuler";
import { lerp } from "@sdk/utils/math";

type Point = { x: number; y: number };
type TwoPoints = [RailPathPoint, RailPathPoint];

export class RailPathPoint {
  declare readonly [0]: number;
  declare readonly [1]: number;
  declare readonly length: 2;
  constructor(x: number, y: number) {
    this[0] = x;
    this[1] = y;
    Object.setPrototypeOf(this, RailPathPoint.prototype);
  }
  get x() {
    return this[0];
  }
  get y() {
    return this[1];
  }

  *[Symbol.iterator]() {
    yield this[0];
    yield this[1];
  }
}

export class RailPathSegment {
  public readonly angle: number = Math.atan2(this.pt2.y - this.pt1.y, this.pt2.x - this.pt1.x);

  public next: RailPathSegment | null = null;
  public prev: RailPathSegment | null = null;

  constructor(public readonly pt1: RailPathPoint, public readonly pt2: RailPathPoint) {}

  public get prevAngle() {
    return this.prev ? this.prev.angle : this.angle;
  }

  public get nextAngle() {
    return this.next ? this.next.angle : this.angle;
  }
}

export class RailPath {
  public readonly points: RailPathPoint[] = [];
  public readonly segments: RailPathSegment[] = [];

  public stationA: StationEntity | undefined;
  public stationB: StationEntity | undefined;
  public stationsLink: StationEntity.StationLink | undefined;

  public segmentsCount: number = 0;

  public get pointA() {
    return this.points[0];
  }
  public get pointB() {
    return this.points[this.points.length - 1];
  }
  public get length() {
    return this.stationsLink?.distance || 0;
  }

  constructor(source: Array<[number, number]>) {
    this.setPoints(source);

    Object.setPrototypeOf(this, RailPath.prototype);
  }

  public setPoints(source: Array<[number, number]>) {
    this.segmentsCount = this.points.length = 0;
    for (const [x, y] of source) {
      this.points.push(new RailPathPoint(x, y));
    }

    this.segments.length = 0;
    let prev = null as RailPathSegment | null;
    for (let i = 1; i < this.points.length; i++) {
      const [pt1, pt2] = [this.points[i - 1], this.points[i]];

      const segment = new RailPathSegment(pt1, pt2);
      if (prev) prev.next = segment;
      segment.prev = prev;
      prev = segment;

      this.segments.push(segment);
    }

    this.segmentsCount = this.segments.length;
  }

  public getPointInfoAtLength(atLength: number) {
    const SEGMENT_LENGTH = 1;
    const segment = this.segments[~~atLength % this.segmentsCount];
    const segmentProgress = atLength % SEGMENT_LENGTH;
    const angle =
      segmentProgress < 0.5
        ? MathEuler.lerp(segment.prevAngle, segment.angle, 0.5 + segmentProgress)
        : MathEuler.lerp(segment.angle, segment.nextAngle, segmentProgress - 0.5);
    const { pt1, pt2 } = segment;
    const turnSharpness = Math.abs(segment.angle - segment.prevAngle) + Math.abs(segment.nextAngle - segment.angle);

    const [x, y] = [lerp(pt1.x, pt2.x, segmentProgress), lerp(pt1.y, pt2.y, segmentProgress)];

    return {
      x,
      y,
      pt1,
      pt2,
      angle,
      segment,
      segmentProgress,
      turnSharpness,
    };
  }

  public getPointLocationAtLength(atLength: number) {
    const SEGMENT_LENGTH = 1;
    const segment = this.segments[~~atLength % this.segmentsCount];
    const segmentProgress = atLength % SEGMENT_LENGTH;
    const { pt1, pt2 } = segment;
    const [x, y] = [lerp(pt1.x, pt2.x, segmentProgress), lerp(pt1.y, pt2.y, segmentProgress)];
    return { x, y };
  }

  /**
   * Returns true if either
   * - Station A is at 'x1' and 'y1', and Station B is at 'x2' and 'y2', or
   * - Station A is at 'x2' and 'y2', and Station B is at 'x1' and 'y1'.
   *
   * Returns false otherwise.
   */
  public hasAB(x1: number, y1: number, x2: number, y2: number) {
    const [ax, ay] = this.pointA;
    const [bx, by] = this.pointB;

    return (ax === x1 && ay === y1 && bx === x2 && by === y2) || (ax === x2 && ay === y2 && bx === x1 && by === y1);
  }

  /**
   * Returns true if station equals either stationA or stationB.
   */
  public hasStation(station: StationEntity) {
    return this.stationA === station || this.stationB === station;
  }

  /**
   * Returns true both station A and B are parts of this path.
   */
  public hasStations(stationA: StationEntity, stationB: StationEntity) {
    return this.hasStation(stationA) && this.hasStation(stationB);
  }
}
