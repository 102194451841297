import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { delay } from "@sdk/utils/promises";
import { playMinigameRouletteSound } from "./playMiniGameRouletteSound";
import { RouletteTemp } from "./rouletteTemp";

export const MINIGAMES = [
  {
    title: "Rail Yard Defender",
    highscoreKey: "rail_yard_defender",
    highscoreHeader: "https://train-of-the-century-media.web.app/minigame-headers/rail-yard-defender.png",
    url: "https://train-of-the-century-minigames.web.app/rail-yard-defender",
  },
  {
    title: "Survive the Century",
    highscoreKey: "survive_the_century",
    highscoreHeader: "https://train-of-the-century-media.web.app/minigame-headers/survive-the-century.png",
    url: "https://train-of-the-century-minigames.web.app/survive-the-century",
  },
  {
    title: "Anomatic Vibes",
    highscoreKey: "anomatic_vibes",
    highscoreHeader: "https://train-of-the-century-media.web.app/minigame-headers/anomatic-vibes.png",
    url: "https://train-of-the-century-minigames.web.app/anomatic-survival",
  },
];

export class MiniGameRouletteScreen extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  roulette: RouletteTemp;

  constructor() {
    super();

    const title = this.factory.createSprite("ui-arcade/title.png", { y: -200 });
    title.anchor.set(0.5);
    this.addChild(title);
    const bg = this.factory.createSprite("ui-arcade/roulette-bg.png", { y: 175 });
    console.log(bg);
    bg.anchor.set(0.5);
    this.addChild(bg);

    this.roulette = new RouletteTemp();
    bg.addChild(this.roulette);

    this.roulette.createSelection(
      MINIGAMES.map(g => g.title),
      -150
    );

    this.scale.set(0.75);
  }

  async selectRandomGameAnimated() {
    const gamesCount = this.roulette.gameList.length;
    const finalGameIndex = Math.floor(Math.random() * gamesCount);

    let i = 0;
    while (i++ < gamesCount * 7) {
      const gameIndex = i % gamesCount;
      this.roulette.setHighlightedItemIndex(gameIndex);
      playMinigameRouletteSound("clickTiny");
      await delay(0.05);
    }

    i = 0;
    while (i++ < gamesCount * 3) {
      const gameIndex = i % gamesCount;
      this.roulette.setHighlightedItemIndex(gameIndex);
      playMinigameRouletteSound("clickTiny");
      await delay(0.1);
    }

    i = 0;
    while (i++ < gamesCount + finalGameIndex) {
      const gameIndex = i % gamesCount;
      this.roulette.setHighlightedItemIndex(gameIndex);
      playMinigameRouletteSound("clickTiny");
      await delay(0.2);
    }

    await delay(0.5);

    for (let i = 0; i < 5; i++) {
      this.roulette.setHighlightedItemIndex(finalGameIndex);
      await delay(0.1);
      this.roulette.setSelectedItemIndex(finalGameIndex);
      playMinigameRouletteSound("boop", false);
      await delay(0.1);
    }

    return MINIGAMES[finalGameIndex];
  }

  displayButtonsAndHint(onClickPlay: () => void, onClickSkip: () => void) {
    //// Play btn
    const playBtn = this.factory.createSprite("ui-arcade/btn.png", { x: -300, y: 500 });
    playBtn.tint = 0x032d3b;
    const playText = this.factory.createText("play".toUpperCase(), { fontSize: 42 });
    playText.anchor.set(0.5);
    playBtn.addChild(playText);
    playBtn.anchor.set(0.5);
    this.addChild(playBtn);

    buttonizeDisplayObject(playBtn, onClickPlay);

    //// Skip btn
    const skipBtn = this.factory.createSprite("ui-arcade/btn.png", { x: 300, y: 500 });
    skipBtn.tint = 0x4f3823;
    const skipText = this.factory.createText("skip".toUpperCase(), { fontSize: 42 });
    skipText.anchor.set(0.5);
    skipBtn.addChild(skipText);
    skipBtn.anchor.set(0.5);
    this.addChild(skipBtn);

    buttonizeDisplayObject(skipBtn, onClickSkip);

    //// Bottom text
    const bottomText1 = this.factory.createText(
      "play to potentially increase your tocium payout".toUpperCase(),
      {
        fontSize: 38,
        dropShadow: true,
      },
      { y: 600 }
    );
    bottomText1.anchor.set(0.5);
    const bottomText2 = this.factory.createText(
      "or skip to receive the standard amount".toUpperCase(),
      {
        fontSize: 28,
        dropShadow: true,
      },
      { y: 640 }
    );
    bottomText2.anchor.set(0.5);
    const bottomText3 = this.factory.createText(
      "(playing could result in a lower tocium payout than skipping)".toUpperCase(),
      { fontSize: 28, dropShadow: true },
      { y: 675 }
    );
    bottomText3.anchor.set(0.5);

    // this.addChild(bottomText1);
    // this.addChild(bottomText2);
    // this.addChild(bottomText3);
  }
}
