import { GameSingletons } from "@game/app/GameSingletons";
import { copycat } from "@game/asorted/copycat";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { Container } from "@pixi/display";
import { makeScreenLayer } from "@sdk/pixi/augmentations/makeScreenLayer";
import { makeOngoingRunTracks } from "../layer_regions/content/makeOngoingRunTracks";
import { WorldLayer } from "../WorldLayer";
import { makeDecorations } from "./content/makeDecorations";
import { makeRailwayTracks } from "./content/makeRailwayTracks";
import { makeStationSprites } from "./content/makeStationSprites";
import { makeOperationsLayerCullingService } from "./makeOperationsLayerCullingService";
import { makeOperationsLayerGroundQuad } from "./makeOperationsLayerGroundQuad";


export function makeOperationsWorldLayer() {
  const { mapData } = GameSingletons.getGameContext();

  if (!mapData) {
    throw new Error("no map data");
  }

  const layer = new WorldLayer();
  layer.name = "WorldLayer//1";

  const culling = makeOperationsLayerCullingService(layer);

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// Make Ground Layer
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  const ground = makeOperationsLayerGroundQuad();
  makeScreenLayer(ground);
  copycat(ground, layer, ["visible", "renderable"]);
  layer.addChild(ground);

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// Make Tracks
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  const tracksContainer = makeRailwayTracks(culling.cullingArea);
  layer.addChild(tracksContainer);

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// Ongoing Run Tracks
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  const ongoingRunTracks = makeOngoingRunTracks();
  layer.addChild(ongoingRunTracks);

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// Make Decoration & Station Sprites
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  /** Shared container for both stations and decoration objects */
  const objectsContainer = new EnchantedContainer();
  layer.addChild(objectsContainer);
  culling.registerContainerForChildrenCulling(objectsContainer);

  const decorationsContainer = makeDecorations(objectsContainer);
  const stationsContainer = makeStationSprites(objectsContainer);

  //// //// //// //// //// ////

  return Object.assign(layer, {
    ground,
    culling,

    tracksContainer,
    ongoingRunTracks,
    decorationsContainer,
    stationsContainer,
    uiElementsContainer: layer.addChild(new Container()),
  });
}
