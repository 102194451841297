import { RarityInteger } from "@game/constants/Rarity";

export enum StationPopupBackgroundType {
  NEXT_STOP = 1,
  EDIT_TRAIN_DESTINATION = 2,
  IDLE_TRAIN_HOVER = 3,
  IDLE_TRAIN_CLICK = 4,
  TRAIN_LOADOUT = 5,
  EDIT_TRAIN = 6,
  UNRELATED_STATION_CLICK = 8,
  MY_STATION_DASHBOARD = 9,
}

export const StationPopupBackgroundMods = {
  [StationPopupBackgroundType.NEXT_STOP]: {
    topHeight: 297,
    bottomHeight: 80,
  },
  [StationPopupBackgroundType.EDIT_TRAIN_DESTINATION]: {
    topHeight: 300,
    bottomHeight: 131,
  },
  [StationPopupBackgroundType.IDLE_TRAIN_HOVER]: {
    topHeight: 360,
    bottomHeight: 131,
  },
  [StationPopupBackgroundType.IDLE_TRAIN_CLICK]: {
    topHeight: 360,
    bottomHeight: 131,
  },
  [StationPopupBackgroundType.TRAIN_LOADOUT]: {
    topHeight: 360,
    bottomHeight: 200,
  },
  [StationPopupBackgroundType.EDIT_TRAIN]: {
    topHeight: 360,
    bottomHeight: 131,
  },
  [StationPopupBackgroundType.UNRELATED_STATION_CLICK]: {
    topHeight: 300,
    bottomHeight: 160,
  },
  [StationPopupBackgroundType.MY_STATION_DASHBOARD]: {
    topHeight: 40,
    bottomHeight: 40,
    leftWidth: 40,
    rightWidth: 40,
  },
};

export const StationPopupBackgroundTexturePrefixIds = {
  [StationPopupBackgroundType.NEXT_STOP]: {
    folder: "next-stop",
  },
  [StationPopupBackgroundType.EDIT_TRAIN_DESTINATION]: {
    folder: "edit-train-destination",
  },
  [StationPopupBackgroundType.IDLE_TRAIN_HOVER]: {
    folder: "hover",
  },
  [StationPopupBackgroundType.IDLE_TRAIN_CLICK]: {
    folder: "edit-train-departure",
    // folder: "click",
  },
  [StationPopupBackgroundType.TRAIN_LOADOUT]: {
    folder: "loading-dock-departure",
  },
  [StationPopupBackgroundType.EDIT_TRAIN]: {
    folder: "edit-train-departure",
    // folder: "click",
  },
  [StationPopupBackgroundType.UNRELATED_STATION_CLICK]: {
    folder: "click-unrelated",
  },
  [StationPopupBackgroundType.MY_STATION_DASHBOARD]: {
    folder: "station-dashboard",
  },
};

export module StationPopupBackgroundType {
  function rarityLevelToSuffix(rarity: number) {
    return [`common`, `uncommon`, `rare`, `epic`, `legendary`, `mythic`][rarity - 1];
  }

  export function getTexturePath(bgType: StationPopupBackgroundType, rarityLevel: RarityInteger) {
    const textureSuffix = rarityLevelToSuffix(rarityLevel);
    const textureFolder = StationPopupBackgroundTexturePrefixIds[bgType].folder;
    const texturePath = `assets/images-webp/ui-station-popups/${textureFolder}/${textureSuffix}.webp`;
    return texturePath;
  }

  export function* getAllTexturesPaths() {
    const rarities = [1, 2, 3, 4, 5, 6];
    for (const rarity of rarities) {
      for (const type of Object.keys(StationPopupBackgroundTexturePrefixIds)) {
        yield getTexturePath(+type, rarity as RarityInteger);
      }
    }
  }
}
