import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { AchievementBadge } from "@game/ui/social/achievements/AchievementBadge";
import { getAchievementBadgeDataBlueprint } from "@game/ui/social/achievements/AchievementBadgeDataBlueprint";
import { Container } from "@pixi/display";
import { Text } from "@pixi/text";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { AchievementPanelRowData, AchievementsRRDashDataService } from "./AchievementsRRDashDataService";
import { AchievementsRRDashUserListSidePanelManager } from "./AchievementsRRDashUserListSidePanelManager";

const reqCommonStyles = {
  fontFamily: FontFamily.Default,
  fontSize: 15,
  fill: 0xffffff,
};

export class AchievementsRRDashPlate extends Container {
  private sidePanelController: AchievementsRRDashUserListSidePanelManager;
  readonly name: string;

  constructor(name: string, sidePanelController: AchievementsRRDashUserListSidePanelManager) {
    super();
    this.name = name;
    this.sidePanelController = sidePanelController;
  }

  async initialize() {
    const dataService = new AchievementsRRDashDataService();
    const data = await dataService.getAllAchievements(this.name);
    if (data == undefined) throw new Error("Achievement data not found!");

    this.makeBadgesPlate(data);
  }

  checkIfBadgeUnlocked(badgeUID: number, userBadgeUIDs: number[]) {
    let isUnlocked: boolean = false;

    for (let userBadgeUID of userBadgeUIDs) {
      if (userBadgeUID == badgeUID) {
        isUnlocked = true;
        return isUnlocked;
      }
    }
    return isUnlocked;
  }

  makeBadgesPlate(data: { row: AchievementPanelRowData; userBadgeIDs: number[] }) {
    const { simpleFactory } = GameSingletons.getGameContext();
    const rowContainer = new Container();
    const divider = simpleFactory.createSprite("ui-railroader-dashboard/page-achievements/divider.png");
    const startX = 25;
    const startY = 15;
    let i = 0;

    ////Add title, bottom bar, and description for whole row
    const title = new Text(data.row.title.toUpperCase(), {
      fontFamily: FontFamily.Default,
      fontSize: 18,
      fill: 0xffffff,
    });
    const description = new Text(data.row.description, {
      fontFamily: FontFamily.DefaultThin,
      fontSize: 12,
      fill: 0xfffffa,
    });
    title.position.set(startX, startY);
    description.position.set(startX + 3, startY + title.height + 3);

    rowContainer.addChild(title, description);

    ////Add Badges and light up unlocked ones
    for (let achievementId of data.row.achievementIds) {
      const datumData = getAchievementBadgeDataBlueprint(achievementId);
      let isUnlocked = this.checkIfBadgeUnlocked(datumData.achievementId, data.userBadgeIDs);
      const badgeIcon = AchievementBadge.create(datumData);
      const requirement = new Text("", { ...reqCommonStyles, align: "center" });

      badgeIcon.scale.set(0.45);
      badgeIcon.position.set(startX + 15 + (badgeIcon.width + 5) * i, startY + 45);
      requirement.anchor.set(0.5);
      requirement.position.set(badgeIcon.x + badgeIcon.width * 0.5, badgeIcon.y + badgeIcon.height + 10);

      if (isUnlocked == undefined) throw new Error("Information not found!");
      else if (isUnlocked == false) {
        badgeIcon.setBrightness(0.5);
        requirement.tint = 0x3a3b3c;
        requirement.alpha = 0.7;
      }

      switch (datumData.tierDot) {
        case 1:
          requirement.text = "5,000";
          break;
        case 2:
          requirement.text = "10,000";
          break;
        case 3:
          requirement.text = "20,000";
          break;
        case 4:
          requirement.text = "35,000";
          break;
        case 5:
          requirement.text = "50,000";
          break;
        case undefined:
          switch (datumData.name) {
            case "Centuryville Connoisseur":
              requirement.text = "Centuryville\nConnoisseur";
              break;
            case "T Pines Traveler":
              requirement.text = "T Pines\nTraveler";
              break;
            case "Pemberton Nights":
              requirement.text = "Pemberton\nNights";
              break;
            case "James Park Juggalo":
              requirement.text = "James Park\nJuggalo";
              break;
            case "Paw Paw Pro":
              requirement.text = "Paw Paw\nPro";
              break;
          }
          break;
      }

      /*buttonizeDisplayObject(badgeIcon, async () => {
        if (this.sidePanelController.panel != null && this.sidePanelController.currentData == datumData) {
          this.sidePanelController.close();
          this.sidePanelController.removeCurrentSelectedTabGlow();
        } else {
          this.sidePanelController.open(datumData, userNameHolder);
          this.sidePanelController.setCurrentSelectedTabGlow(badgeIcon);
        }
      });*/

      rowContainer.addChild(badgeIcon, requirement);
      i++;
    }
    divider.width = 590;
    divider.position.set(startX, rowContainer.height + 35);

    rowContainer.addChild(divider);
    this.addChild(rowContainer);
  }
}
