import { GameSingletons } from "@game/app/GameSingletons";
import { Rarity } from "@game/constants/Rarity";
import { getRarityColors } from "@game/constants/RarityColors";
import { StationEntity } from "@game/data/entities/StationEntity";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";

export function createBubblesLayerFactory() {
  const { assets, mapData } = GameSingletons.getGameContext();

  let stations = mapData.stationsArray;
  let bubblesLayer = createBubbles(stations);

  function createBubbles(stations: StationEntity[]) {
    let bubblesContainer = new Container();

    for (let station of stations) {
      if (station.addonTier_ConductorLounge != null && station.addonTier_ConductorLounge! > 0) {
        const loungeBubble = new Sprite(assets.getTexture("ui-common/stake-bubble-r-white.png"));
        const loungeIcon = new Sprite(assets.getTexture("ui-common/lounge.png"));
        let rarity = Rarity.fromNumberFromOne(station.addonTier_ConductorLounge);
        let color = getRarityColors(rarity).main;
        loungeBubble.tint = color.toInt();
        loungeIcon.scale.set(0.45);
        loungeIcon.anchor.set(0.5, 0.5);
        loungeBubble.pivot.set(0, loungeBubble.texture.height);
        loungeIcon.position.set(loungeBubble.width * 0.5, loungeBubble.height * 0.4);
        loungeBubble.scale.set(6);
        loungeBubble.position.set(station.extraData.x + 85, station.extraData.y - 150);
        loungeBubble.addChild(loungeIcon);
        bubblesContainer.addChild(loungeBubble);
      }
      if (station.addonTier_RailYard != null && station.addonTier_RailYard! > 0) {
        const railyardBubble = new Sprite(assets.getTexture("ui-common/stake-bubble-l-white.png"));
        const ryIcon = new Sprite(assets.getTexture("ui-common/railyard.png"));
        let rarity = Rarity.fromNumberFromOne(station.addonTier_RailYard);
        let color = getRarityColors(rarity).main;
        railyardBubble.tint = color.toInt();
        ryIcon.scale.set(0.45);
        ryIcon.anchor.set(0.5, 0.5);
        railyardBubble.pivot.set(railyardBubble.texture.width, railyardBubble.texture.height);
        ryIcon.position.set(railyardBubble.width * 0.5, railyardBubble.height * 0.4);
        railyardBubble.scale.set(6);
        railyardBubble.position.set(station.extraData.x - 75, station.extraData.y - 150);
        railyardBubble.addChild(ryIcon);
        bubblesContainer.addChild(railyardBubble);
      }
    }
    return bubblesContainer;
  }

  return {
    show() {
      const { world } = GameSingletons.getGameContext();
      bubblesLayer = createBubbles(stations);
      world.zoomLayers.operationsLayer.addChild(bubblesLayer);
    },
    hide() {
      bubblesLayer.destroy();
    },
  };
}
