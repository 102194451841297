import { onKeyPress } from "@debug/utils/onKeyPress";
import { SelectionManager } from "@game/app/services/SelectionManager";
import { MainFAQ } from "@game/app/mainFAQ";
import { initializeDocumentTitleUpdateService } from "@game/app/misc/initializeDocumentTitleUpdateService";
import { InstantTransmissionService } from "@game/gameplay/InstantTransmissionService";
import { CardsDrawer } from "@game/ui/cards/CardsDrawer";
import { CardsDrawerManager } from "@game/ui/cards/CardsDrawerManager";
import { HUD } from "@game/ui/hud/HUD";
import { MapDotsManager } from "@game/ui/in-map/MapDotsManager";
import { StationLabelsManager } from "@game/ui/in-map/StationLabelsManager";
import { StationSignsManager } from "@game/ui/in-map/StationSignsManager";
import { PopupInstancesManager } from "@game/ui/popups/PopupInstancesManager";
import { SocialProfileQuickViewManager } from "@game/ui/social/quickview/SocialProfileQuickViewManager";
import { CogWheelMenuMode } from "@game/ui/wheel/CogWheelMenu";
import { MarketWindowService } from "@game/ui/windows/market/MarketWindowService";
import { RailRunsWindowService } from "@game/ui/windows/railruns/RailRunsWindowService";
import { World } from "@game/world/World";
import { MultipleReasons } from "@sdk/core/MultipleReasons";
import { throttle } from "@sdk/utils/callbacks/throttle";
import { GameSingletons } from "./GameSingletons";
import * as MainReactors from "./mainReactors";
import { prepareEventBannersGalleryData } from "@game/ui/railroader-dash/banner/EventBannersGalleryData";
import { MainMusicPlaylistController } from "@game/main/MainMusicPlaylistController";
import { showUpdatesModalIfUnread } from "@game/ui/asorted/updatesModal/showUpdatesModalIfUnread";
import { BuildingEntity } from "@game/data/entities/BuildingEntity";



export enum GameViewMode {
  NORMAL,
  NEXT_STOP,
  INSTANT_TRANSMISSION,
  DISPATCH,
  EDIT_TRAIN,
  LOADING_DOCK,
  BUSY,
  BUILDING,
  GROUND
}

export class Main {
  private readonly context = GameSingletons.getGameContext();

  public readonly mainMusic: MainMusicPlaylistController;

  public readonly cards: CardsDrawerManager;
  public readonly popups = new PopupInstancesManager();


  public readonly signs: StationSignsManager;
  public readonly stationLabels: StationLabelsManager;
  public readonly dots: MapDotsManager;

  public readonly marketWindow: MarketWindowService;
  public readonly railRunsWindow: RailRunsWindowService;
  public get anyWindowOpen() {
    return this.marketWindow.currentWindow?.isOpen || this.railRunsWindow.currentWindow?.isOpen || false;
  }
  public get dashboardOrWindowOpen() {
    return (
      this.hud.railroaderDashboard.isOpen ||
      this.marketWindow.currentWindow?.isOpen ||
      this.railRunsWindow.currentWindow?.isOpen ||
      false
    );
  }
  public readonly social: SocialProfileQuickViewManager;

  public readonly hud: HUD;

  public readonly world: World;

  public readonly faq = new MainFAQ();

  public viewMode = GameViewMode.NORMAL;
   public nightModeEnabled = true;
  public immersiveMode = false;
 
  public groundModalImmersiveMode = false;
  public stationDashOpen = false;

  public readonly selection: SelectionManager;
  public readonly instantTransmission: InstantTransmissionService;

  public readonly reasonsToDisableWorldInteraction = new MultipleReasons();

  constructor() {
    const { mapData, viewSize } = this.context;

    if (mapData == null) {
      throw new Error("mapData is null");
    }

    initializeDocumentTitleUpdateService();

    this.mainMusic = new MainMusicPlaylistController();

    this.selection = new SelectionManager();
    this.instantTransmission = new InstantTransmissionService();

  

    //// Create the game world
    const world = (this.world = new World());
    
    
    this.context.stageContainers._world.addChild(world);

    this.hud = new HUD();

    this.marketWindow = new MarketWindowService();
    this.railRunsWindow = new RailRunsWindowService();
    this.social = new SocialProfileQuickViewManager();

    this.signs = new StationSignsManager();
    this.dots = new MapDotsManager();
    this.stationLabels = new StationLabelsManager();

    const cardsDrawer = new CardsDrawer(() => {
      const insetLeft = viewSize.width * 0.1;
      const insetRight = 400 * this.hud.cogWheelMenu.scale.x;
      const width = Math.min(viewSize.width - insetLeft - insetRight, viewSize.height);
      return [viewSize.width - insetRight - width, viewSize.width - insetRight];
    });
    cardsDrawer.initialize();
    this.context.stageContainers._worldHud.addChild(cardsDrawer.container);
    this.cards = new CardsDrawerManager(cardsDrawer);

    prepareEventBannersGalleryData();
  }

  async initializeReactors() {
    const { sfx, input, viewSize } = this.context;

    //// REACTORS ::::

    this.context.viewport.on("drag-start", () => {
      this.world.setInteractionEnabled(false);
      this.context.viewport.once("drag-end", () => {
        this.world.setInteractionEnabled(true);
      });
    });

    this.reasonsToDisableWorldInteraction.on({
      change: disableInteraction => {
        this.world.setInteractionEnabled(!disableInteraction);
        this.context.viewport.interactiveChildren = !disableInteraction;
        this.context.viewport.interactive = !disableInteraction;
      },
    });

    this.context.stage.enchantments.watch(
      () => this.dashboardOrWindowOpen,
      disableInteraction => {
        this.reasonsToDisableWorldInteraction.set("RRDashOrSomeWindowOpen", disableInteraction);
      },
      true
    );

    this.context.stage.enchantments.watch(
      () => this.hud.railroaderDashboard.isOpen && this.cards.drawer.isOpen,
      bothOpen => bothOpen && input.emit("closeCardsDrawer"),
      true
    );

    this.context.stage.enchantments.watch(
      () => (this.hud.railroaderDashboard.isOpen || this.cards.drawer.isOpen) && viewSize.width < viewSize.height,
      drawerOrDashOpen => this.hud.reasonsToHideFuelCounters.set("RRDashOrCardsDrawerOpen", drawerOrDashOpen),
      true
    );

    this.context.stage.enchantments.watch(
      () => this.hud.stationOwnerAvatarCtrl.hasInstanceUp,
      ownerAvatarOnScreen =>
        this.hud.reasonsToHideFuelCounters.set("StationOwnerAvatarPreviewOpen", ownerAvatarOnScreen),
      true
    );


    onKeyPress(" ", () => void (this.immersiveMode = !this.immersiveMode));

    this.hud.cogWheelMenu.events.on({
      onClickCards: () => input.emit("toggleCardsDrawer"),
      onClickShop: async () => input.emit("toggleMarketWindow"),
      onClickRuns: async () => input.emit("toggleRailRunsWindow"),
      onClose: () => {
        this.setViewMode(GameViewMode.NORMAL);
        if (this.cards.drawer.isOpen) {
          input.emit("closeCardsDrawer");
        }
      },
      onClickCentralButton: throttle.hardcore(() => {
        this.hud.cogWheelMenu.spinUp(false, true);

        const mode = this.hud.cogWheelMenu.getCurrentMode();
        switch (mode) {
          case CogWheelMenuMode.Logo:
            input.emit("toggleRailRoaderDashboard");
            break;
          case CogWheelMenuMode.NextStop:
            if (this.selection.selectedTrain == null) throw new Error("No train selected");
            input.emit("toggleInstantTransmissionView", this.selection.selectedTrain);
            break;
          case CogWheelMenuMode.Confirm:
            input.emit("confirm");
            break;
          case CogWheelMenuMode.EnterBuilding:
             if (this.selection.selectedBuilding == null) throw new Error("uh oh"); 
             sfx.play("locateClick");
             input.emit("enterBuilding", this.selection.selectedBuilding); 
           
            break;
        case CogWheelMenuMode.Return:
             input.emit("returnToMap"); 
           
            break;
        }
      }, 0.8),
    });

    for (const initializeReactor of Object.values(MainReactors)) {
      initializeReactor();
    }

    /**
     * Fx
     */
    this.hud.cogWheelMenu.enchantments.watch(
      () => this.cards.drawer.isOpen,
      () => this.hud.cogWheelMenu.spinUp()
    );
  }

  async start() {
    const { ticker } = this.context;

    this.mainMusic.playNextTrack();

    const stopHidingTheCogWheelMenu = this.hud.reasonsToHideCogWheelMenu.add("OngoingMainIntro");

    this.hud.billboardCtrl.initialize();
    this.hud.stationOwnerAvatarCtrl.initialize();

    await ticker.delayFrames(10);
    !this.hud.title.currentText && this.hud.title.setState({ titleText: HUD.DEFAULT_HEADER_TEXT });

    await ticker.delay(0.425);
    stopHidingTheCogWheelMenu();

    showUpdatesModalIfUnread();

    await ticker.delay(0.68);
    this.cards.initialize();
  }

  /**
   * @returns True if changed, false if not.
   */
  public setViewMode(nextMode: GameViewMode) {
    if (nextMode === this.viewMode) {
      return false;
    }

    if (this.context.world == null) {
      throw new Error("World is null");
    }

    this.viewMode = nextMode;

    return true;
  }

  public async closeEverything() {
    if (this.cards.drawer.isOpen) this.cards.setCardDrawerState(null);
    if (this.marketWindow.currentWindow) await this.marketWindow.closeWindow();
    if (this.railRunsWindow.currentWindow) await this.railRunsWindow.closeWindow();
    if (this.social.panel) await this.social.close();
    if (this.hud.railroaderDashboard.isOpen) await this.hud.railroaderDashboard.closeDashboard();
  }
}
