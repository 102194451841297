import {GameSingletons} from "@game/app/GameSingletons";
import {TemporaryTweeener} from "@sdk/pixi/animations/TemporaryTweener";
import {EnchantedContainer} from "@game/core/enchanted-classes";
import {Container} from "@pixi/display";
import {Sprite} from "@pixi/sprite";
import {Texture} from "@pixi/core";
import {EnchantmentGlobals} from "@sdk/pixi/enchant/EnchantmentGlobals";
import {TilingSpriteDimmer, TilingSpriteDimmerTemplates} from "../common/TilingSpriteDimmer";
import {NineSlicePlane} from "@pixi/mesh-extras";


type RoomBg = Container & {
  rotationSpeed: number;
  scaleMultiplier: number;
};

const ROOM_ROTATION_SPEED_IDLE = -0.04;

export class RoomBackground extends EnchantedContainer {
  private readonly context = GameSingletons.getGameContext();
  roomBg : RoomBg;
  dimmer : TilingSpriteDimmer;
  tweeener = new TemporaryTweeener(this);
  /* private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory(); */

  constructor(public image : string) {
    super();


    const {assets} = this.context;

    this.dimmer = new TilingSpriteDimmer({
      ...TilingSpriteDimmerTemplates.SCANLINES
    });
    this.addChild(this.dimmer);
    this.image = image;


    const {viewSize} = this.context;
    // Create a new NineSlicePlane instance and set its size to the size of the viewing window
    const nineSlicePlane = new NineSlicePlane(Texture.from('assets/images-webp/buildings/plane.webp'), 100, 100, 100, 100);
    nineSlicePlane.width = viewSize.width;
    nineSlicePlane.height = viewSize.height;
    nineSlicePlane.scale.set(1);
    // Enable interactivity and button mode for the NineSlicePlane
    nineSlicePlane.interactive = true;
    nineSlicePlane.buttonMode = false;
    // Add the NineSlicePlane to the RoomBackground container
    this.addChild(nineSlicePlane);

    const bgTexture = assets.getTexture(this.image);
    this.roomBg = Object.assign(new Sprite(bgTexture), {
      rotationSpeed: ROOM_ROTATION_SPEED_IDLE,
      scaleMultiplier: 1.0
    });
    this.roomBg.scale.set(.95);
    this.roomBg.pivot.set(bgTexture.width / 2, bgTexture.height / 2);
    this.addChild(this.roomBg);

    this.onEnterFrame.add(() => {
      const {viewSize} = this.context;
      const screenCenter = {
        x: viewSize.width / 2,
        y: viewSize.height / 2
      };

      this.roomBg.scale.set((this.roomBg.scaleMultiplier * viewSize.vmax) / 1920);
      // Update the y position of the roomBg object to be at the bottom of the screen
      const imageHeight = this.roomBg.height * this.roomBg.scale.y;
      this.roomBg.position.set(viewSize.width / 2, viewSize.height / 2);
      /* this.roomBg.rotation += this.roomBg.rotationSpeed * EnchantmentGlobals.timeDelta; */
    });
  }

  playShowAnimation(duration: number) {
    this.dimmer.show();
    this.tweeener.delay(1.0);
    return this.tweeener.from(this.roomBg, {
      rotationSpeed: -5,
      scaleMultiplier: 2.0,
      alpha: 0.0,
      duration: duration,
      ease: "power2.out"
    });
  }

  playHideAnimation() {
    this.dimmer.hide();
    return this.tweeener.to(this.roomBg, {
      rotationSpeed: -5,
      scaleMultiplier: 2.0,
      alpha: 0.0,
      duration: 0.478,
      ease: "power2.out"
    });
  }

};
