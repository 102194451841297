import { GameSingletons } from "@game/app/GameSingletons";
import { getRarityColors } from "@game/constants/RarityColors";
import { ThemeColors } from "@game/constants/ThemeColors";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { fitObjectInRectangle } from "@sdk-pixi/layout/fitObjectInRectangle";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { getTrainStatusPanelFrameTextureId } from "./getTrainStatusPanelFrameTextureId";
import { TrainStatusHealthBar } from "./TrainStatusHealthBar";
import { TrainStatusPanelParams } from "./TrainStatusPanelParams";

const commoditiesOrder = [
  "aggregate",
  "automobile",
  "building_materials",
  "crate",
  "gas",
  "grain",
  "granule",
  "liquid",
  "ore",
  "oversized",
  "pallet",
  "perishable",
  "top_secret",
];

export class TrainStatusPanel extends Container {
  private readonly factory = GameSingletons.getSimpleObjectFactory();
  private readonly assets = GameSingletons.getResources();
  public context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);

  private readonly bg: Sprite;
  private readonly frame: Sprite;
  private readonly locoImage: Sprite;
  private readonly trainName: Text;
  private readonly healthBar: TrainStatusHealthBar;
  private readonly runsCount: Text;
  private readonly distanceCount: Text;
  private readonly railCars: Container;
  private readonly commodityAmounts: Map<string, Text> = new Map();

  constructor() {
    super();

    //// Add background
    this.bg = this.factory.createSprite("ui-repair/left-panel/base.png", {});
    this.bg.anchor.set(0.5);
    this.addChild(this.bg);

    //// Add loco image
    this.locoImage = new Sprite(Texture.EMPTY);
    this.locoImage.anchor.set(0.5);
    this.locoImage.position.y = -360;
    this.locoImage.scale.y = 0.9;
    this.addChild(this.locoImage);

    //// Add frame
    this.frame = new Sprite(Texture.EMPTY);
    this.frame.anchor.set(0.5);
    this.addChild(this.frame);

    //// Add nameplate
    const nameplate = this.createNamePlate();
    nameplate.position.y = -240;
    this.addChild(nameplate);
    this.trainName = this.factory.createText("");
    this.addChild(this.trainName);

    //// Add loadout
    this.createLoadout();

    //// Add cars icons
    this.railCars = this.createCarIcons([]);
    this.addChild(this.railCars);

    //// Add commodities
    const commodityContainers = this.createCommodities();
    this.addChild(commodityContainers);

    //// Add stats info panel
    const infoBg = this.factory.createSprite("ui-repair/left-panel/info.png", { x: -140, y: 222 });
    infoBg.scale.set(0.5);
    this.addChild(infoBg);

    //// Add runs text
    const runsText = this.factory.createText("RUNS:", { fontSize: 40 }, { x: 45, y: 50 });
    infoBg.addChild(runsText);
    this.runsCount = this.factory.createText(
      "0",
      { fontSize: 40, fill: ThemeColors.HIGHLIGHT_COLOR.toInt() },
      { x: 50, y: 110 }
    );
    infoBg.addChild(this.runsCount);

    //// Add distance text
    const distanceText = this.factory.createText("DISTANCE:", { fontSize: 40 }, { x: 45, y: 170 });
    infoBg.addChild(distanceText);
    this.distanceCount = this.factory.createText(
      "0",
      { fontSize: 40, fill: ThemeColors.HIGHLIGHT_COLOR.toInt() },
      { x: 50, y: 230 }
    );
    infoBg.addChild(this.distanceCount);

    //// Add health bar
    this.healthBar = new TrainStatusHealthBar();
    this.healthBar.position.set(35, 180);
    this.addChild(this.healthBar);
  }

  addFooterRepairButton(onClick: () => unknown): Container {
    const container = new Container();

    const repairBtn = this.factory.createSprite("ui-repair/left-panel/btn-repair.png", { y: 425 });
    repairBtn.anchor.set(0.5);
    repairBtn.scale.set(0.5);
    container.addChild(repairBtn);

    const repairBtnText = this.factory.createText("REPAIR", {}, { y: 425 });
    repairBtnText.anchor.set(0.5);

    container.addChild(repairBtnText);

    buttonizeDisplayObject(container, { onTrigger: onClick });
    return this.addChild(container);
  }

  addFooterText(text: string): Container {
    const container = new Container();

    const repairBtnText = this.factory.createText(text, {}, { y: 425 });
    repairBtnText.anchor.set(0.5);
    container.addChild(repairBtnText);

    return this.addChild(container);
  }

  addBackArrow(onClick: () => unknown) {
    const textureId = "ui-repair/multi-train-map-view/arrow.png";
    const texture = this.assets.getTexture(textureId);
    const sprite = new Sprite(texture);
    sprite.anchor.set(0.5);
    sprite.position.set(-this.bg.width * 0.5 + 60, 545);
    sprite.scale.set(1.75);
    sprite.scale.x *= -1;
    buttonizeDisplayObject(sprite, {  onTrigger: () => {
      this.context.sfx.play("clickTiny");
      this.context.sfx.play("pDown");
      onClick();  // call the original onClick callback
    } });
    return this.addChild(sprite);
  }

  //// Update repair panel
  update(data: TrainStatusPanelParams) {
    const frameTextureId = getTrainStatusPanelFrameTextureId(data.trainComposition) || `ui-repair/left-panel/iron.png`;
    this.frame.texture = Texture.from(frameTextureId);

    this.locoImage.texture = data.locoTextureId ? this.updateLocoImage(data.locoTextureId) : Texture.EMPTY;
    this.locoImage.scale.set(0.8);

    this.trainName.text = data.trainName.toUpperCase();
    fitObjectInRectangle(this.trainName, {
      x: -160,
      y: -288,
      width: 325,
      height: 94,
    });

    this.updateCommodityContainerValues(data.commodityAmounts);

    this.runsCount.text = data.runs;
    this.distanceCount.text = data.distances;

    this.updateCarRarity(data.railCars);
    this.healthBar.setPercentage(data.currentCondition * 100);
  }

  updateRunsAndDistance(data: { runs: number; distance: number }) {
    this.runsCount.text = String(data.runs);
    this.distanceCount.text = String(data.distance);
  }

  updateLocoImage(locoName: string): Texture {
    const loco = this.assets.getTexture(locoName);
    return loco;
  }

  createNamePlate(): Container {
    const container = new Container();
    const nameBadgePipe = this.factory.createSprite("ui-repair/left-panel/name-badge/pipe.png");
    nameBadgePipe.anchor.set(0.5);
    container.addChild(nameBadgePipe);
    const nameBadgeGearSpin = this.factory.createSprite("ui-repair/left-panel/name-badge/gear-spin.png");
    nameBadgeGearSpin.anchor.set(0.5);
    container.addChild(nameBadgeGearSpin);
    const nameplate = this.factory.createSprite("ui-repair/left-panel/name-badge/nameplate.png");
    nameplate.anchor.set(0.5);
    container.addChild(nameplate);
    return container;
  }

  createCarIcons(railCars: { rarity: string }[]) {
    const container = new Container();
    container.position.set(-150, -135);
    let startY = -2;
    for (let i = 0; i < 8; i++) {
      const car = this.factory.createSprite("ui-railruns-window/rc-slot-multply.png");
      const carFrame = this.factory.createSprite("ui-railruns-window/rc-slot-frame.png");
      const carIcon = this.factory.createSprite("ui-railruns-window/rc-slot-icon.png", { x: 25, y: 25 });
      carIcon.scale.set(0.85);
      car.addChild(carFrame);
      car.addChild(carIcon);
      car.scale.set(0.525);
      car.tint = 0x000000e;
      if (railCars[i]) {
        car.tint = getRarityColors(railCars[i].rarity).main.toInt();
      }
      car.position.set(0, startY);
      container.addChild(car);
      startY += 44;
    }
    return container;
  }

  createCommodities() {
    const container = new Container();
    container.position.set(-150, -100);
    let startY = 8;
    for (const [index, commodityRow] of commoditiesOrder.entries()) {
      if (index % 2 == 0) {
        const commodityLeft = this.createCommodityContainer(commodityRow);
        commodityLeft.position.set(55, startY);
        container.addChild(commodityLeft);
        this.commodityAmounts.set(commodityRow, commodityLeft.children[1] as Text);
      } else {
        const commodityRight = this.createCommodityContainer(commodityRow);
        commodityRight.position.set(182, startY);
        container.addChild(commodityRight);
        this.commodityAmounts.set(commodityRow, commodityRight.children[1] as Text);

        startY += 44;
      }
    }
    return container;
  }

  createLoadout() {
    const loadout = this.factory.createSprite("ui-repair/left-panel/loadout-panel.png");
    loadout.scale.set(0.25);
    loadout.position.set(-100, -140);
    const loadoutText = this.factory.createText("LOADOUT", { fontSize: 85 }, { x: 275, y: 30 });
    loadout.addChild(loadoutText);
    this.addChild(loadout);
  }

  createCommodityContainer(commType: string) {
    const commodityContainer = this.factory.createSprite("ui-repair/left-panel/comm-single.png");
    commodityContainer.tint = 0x000000e;
    const commodityTypeIcon = this.factory.createSprite("ui-icons/commodity/" + commType + ".png", {
      x: 25,
      y: 0,
    });
    commodityTypeIcon.scale.set(0.65);
    commodityTypeIcon.alpha = 0.5;
    commodityContainer.addChild(commodityTypeIcon);

    const commodityRate = this.factory.createText("", { fontSize: 44 }, { x: 130, y: 5 });
    commodityContainer.addChild(commodityRate);

    commodityContainer.scale.set(0.5);
    return commodityContainer;
  }

  updateCommodityContainerValues(values: Record<string, number>) {
    for (const value in values) {
      const text = this.commodityAmounts.get(value);
      if (text) {
        text.text = values[value].toString();
        (text.parent as Sprite).tint = 0xffffff;
        (text.parent.children[0] as Sprite).alpha = 1;
        fitObjectInRectangle(text, { x: 130, y: 5, width: 70, height: 70 });
      }
    }
  }

  updateCarRarity(railCars: { rarity: string }[]) {
    for (const [index, car] of railCars.entries()) {
      (this.railCars.children[index] as Sprite).tint = getRarityColors(car.rarity).main.toInt();
    }
  }

  playShowAnimation() {
    return this.tweeener.from(this, { x: -100, ease: "bounce.out", duration: 0.75 });
  }

  playHideAnimation() {
    return this.tweeener.to(this, { x: 0, alpha: 0, ease: "power1.in", duration: 0.25 });
  }

  addEditButton(onClick: () => unknown) {
    const editBtn = this.factory.createSprite("ui-railruns-window/btn-edit-train.png");
    editBtn.anchor.set(0.5);
    editBtn.position.set(0, 545);
    buttonizeDisplayObject(editBtn, { onTrigger: onClick });
    return this.addChild(editBtn);
  }

  addLocateButton(onClick: () => unknown) {
    const locateBtn = this.factory.createSprite("ui-railruns-window/btn-locator-expanded-row.png");
    locateBtn.anchor.set(0.5);
    locateBtn.position.set(100, 545);
    buttonizeDisplayObject(locateBtn, {  onTrigger: () => {
      this.context.sfx.play("locateClickTiny");

      onClick();  // call the original onClick callback
    } });
    return this.addChild(locateBtn);
  }
}
