import "@debug/experiments/EffectsController";
import {
  addTimeScaleSlider,
  controlTickerSpeedViaKeyboard,
  debugStationLinks,
  makeStationsWithTrainsMine,
  testCullingArea,
} from "@debug/experiments/misc";
import { testAutomaticViewportNavigation } from "@debug/experiments/testAutomaticViewportNavigation";
import { testContextMenu } from "@debug/experiments/testContextMenu";
import { addLineAndBarChartsToStage } from "@debug/experiments/testLineAndBarCharts";
import { testTextInput } from "@debug/experiments/testTextInput";
import type { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { loadCinematicsList } from "@game/cinematics/airtable/loadAirtableStuff";
import { NPCEncounterCinematic_FromAirtable } from "@game/cinematics/airtable/NPCEncounterCinematic_FromAirtable";
import { presentUserDialogueChoiceOptions } from "@game/cinematics/story/presentUserDialogueChoiceOptions";
import { dynamicChoiceActionFunctions } from "@game/cinematics/utils/dynamicChoiceActionFunctions";
import { dynamicSpecialEffectFunctions } from "@game/cinematics/utils/dynamicSpecialEffectFunctions";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { startNewRailRun } from "@game/railruns/startNewRailRun";
import { createUpdatesTypeModal } from "@game/ui/asorted/updatesModal/createUpdatesModalBase";
import { getEventBannersGalleryData } from "@game/ui/railroader-dash/banner/EventBannersGalleryData";
import { TrainPinConditionCircle } from "@game/ui/repair/train-pins/TrainPinConditionCircle";
import { TrainStatusPinsSidebar } from "@game/ui/repair/train-pins/TrainStatusPinsSidebar";
import { TrainStatusPanel } from "@game/ui/repair/train-status-panel/TrainStatusPanel";
import { TrainStatusPanelParams } from "@game/ui/repair/train-status-panel/TrainStatusPanelParams";
import { AchievementBadge } from "@game/ui/social/achievements/AchievementBadge";
import { claimAllRailRuns } from "@game/ui/windows/railruns/claimAllRailRuns";
import { ContractName } from "@sdk-integration/contracts";
import { createStorageProxy } from "@sdk/data/createStorageProxy";
import { getRandomItemFrom } from "@sdk/helpers/arrays";
import { OttoTestPanel } from "./experiments/OttoTestPanel";
import { testTheModals } from "./experiments/testTheModals";
import { testTooltips } from "./experiments/testTooltips";
import {
  testNPCEncounterCinematic_Culprit,
  testNPCEncounterCinematic_MysteriousStranger,
  testNPCEncounterCinematic_Otto,
  testNPCEncounterCinematic_Thomas,
} from "./experiments/__AssetEncounters";
import { testMockCampaigns } from "./experiments/__MockCampaign";
import { testStoryCampaigns } from "./experiments/__StoryCampaign";
import { __ResourceExplorer } from "./explorer/__ResourceExplorer";
import { debugShadedLayer_Ground_ZoomTier1, debugShadedLayer_Ground_ZoomTier2 } from "./glsl/debugGroundLayer";
import { addDebugButton, addOneShotDebugButton } from "./tweakpane";
import { onKeyPress } from "./utils/onKeyPress";
import { __window__ } from "./__";
import { initializeDebuggingSettings } from "./__DEBUG_SETTINGS__";
import { AvatarBadge } from "@game/ui/social/avatar/AvatarBadge";
import { ThemeColors } from "@game/constants/ThemeColors";
import { SocialProfileQuickView } from "@game/ui/social/quickview/SocialProfileQuickView";
import { range } from "@sdk/utils/range";
import { Sprite } from "@pixi/sprite";
import { CardsDrawerFilterOptionsPanel } from "@game/ui/cards/CardsDrawerFilterOptionsPanel";

export async function __startExperiments(context: GameContext = GameSingletons.getGameContext()) {
  const { app, stageContainers, world, modals, mapData, viewSize, userData, spinner } = context;

  import("./experiments/__quickTests").then(functions => Object.assign(window, functions));

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  debugShadedLayer_Ground_ZoomTier1(world.zoomLayers.operationsLayer.ground);

  debugShadedLayer_Ground_ZoomTier2(world.zoomLayers.regionsLayer.ground);

  addDebugButton("🔍 RESOURCE INSPECTOR 🔎", () => stageContainers._hud.addChild(new __ResourceExplorer()));

  initializeDebuggingSettings();

  controlTickerSpeedViaKeyboard();

  addTimeScaleSlider();

  testStoryCampaigns();

  __window__.mockCinematics = testMockCampaigns();

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  testContextMenu();

  testTooltips();

  // addDebugButton("🔥 Authentication 🔥", () => spinner.showDuring(testFirebaseAuth()));

  // addDebugButton("🥇 Golden Run 🥇", () => spinner.showDuring(testGoldenRunCall()));

  addDebugButton("🧪 Century Vials 🧪", async () => {
    for (const i of [1, 2, 6, 12, 18]) await modals.strangerReward_CenturyVial(i);
  });

  // addOneShotDebugButton("Test Page Manager", () => testPageSwitcher());

  // addOneShotDebugButton("Window Background", () => testWindowBackground());

  // addOneShotDebugButton("Music/SFX Slider", testSliderBars);

  addDebugButton("World Object Culling", () => testCullingArea());

  addOneShotDebugButton("Make stations with trains mine", () => makeStationsWithTrainsMine());

  // addOneShotDebugButton("Station Popup Background Animation", () => testStationPopupBackgroundAnimation());

  addOneShotDebugButton("Text Input", () => testTextInput());

  addOneShotDebugButton("Bar & Line Charts", () => addLineAndBarChartsToStage(app.stage));

  addOneShotDebugButton("Viewport Animation", () => testAutomaticViewportNavigation(context));

  addDebugButton("▶ NPC — The Culprit", () => testNPCEncounterCinematic_Culprit());

  addDebugButton("▶ NPC — Mysterious Stranger", () => testNPCEncounterCinematic_MysteriousStranger());

  addDebugButton("▶ NPC — Otto", () => testNPCEncounterCinematic_Otto());

  addDebugButton("▶ NPC — Thomas", () => testNPCEncounterCinematic_Thomas());

  addDebugButton("Generic Modals", () => testTheModals());

  // addDebugButton("Terms of Service", () => testTermsAndConditions());

  // addDebugButton("Change screen title", () => testScreenTitleChange());

  addDebugButton("Train Pins Condition Bar", async () => {
    await modals.alert({
      title: `Debug Train Pin Condition Bar`,
      content: `Write\n\npin.setFraction(<number from 0 to 1>)\n\nin the browser console to test it out.`,
    });

    const pin = new TrainPinConditionCircle(100);
    pin.position.set(viewSize.width / 2, viewSize.height / 2);
    pin.setTargetFraction(0.75);
    context.stageContainers._hud.addChild(pin);

    __window__.pin = pin;
  });

  addDebugButton("Log Train Condition", () => console.log(userData.trainsArray[0].conditionFraction));

  addDebugButton("Achievement modal", () =>
    modals.achievementNotification(
      {
        achievementId: 9,
        name: "MODERN MASTER",
        textureId: "ui-achievements/misc-badges/5-modern-master.png",
        description: "Been to every station in Modern Century",
      },
      true
    )
  );

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  const thomasMarkers = world.zoomLayers.regionsLayer.thomasRegionMarkers;
  // addDebugButton("Test Thomas Heads", () => thomasMarkers.addThomasHeads([1, 2, 3, 4, 5]));
  // addDebugButton("Remove Tomas Heads", () => thomasMarkers.removeThomasHeads());

  // function addBoostJar(sideways: boolean, icon: string, mods: any) {
  //   const jar = Object.assign(
  //     sideways ? LocoBoostJar.createSidewaysJar(icon) : LocoBoostJar.createStandingJar(icon),
  //     mods
  //   );
  //   context.stageContainers._hud.addChild(jar);
  //   __window__.jar = jar;

  //   jar.interactive = true;
  //   jar.buttonMode = true;
  //   jar.on("click", () => {
  //     const newValue = jar.currentValue ? 0 : ~~Math.floor(Math.random() ** 2 * 100);
  //     jar.setImpactValue(newValue);
  //   });

  //   new TemporaryTweeener(jar).from(jar, { pixi: { scale: 0 }, duration: 1.25, ease: "elastic.out" });
  // }

  // addDebugButton("Test avatar", () => {
  //   const avatar = new AvatarBadge();
  //   avatar.position.set(700, 200);
  //   avatar.scale.set(0.5);
  //   context.stageContainers._hud.addChild(avatar);
  //   AvatarBadge.applyMyUserData(avatar);
  // });

  // addDebugButton("🔹 ETHEREUM 🔹", async () => {
  //   const ds = new EthereumNFTDataService();
  //   ds.updateMyEthereumAddress().then(console.warn);
  //   ds.getEthereumNFTs(context.userData.name).then(console.warn);
  // });

  // addDebugButton("Eth pop up", () => {
  //   const eth = new ViewEthereumNFTModal();
  //   eth.fillInfo({
  //     title: "choo choos #149",
  //     titleSub:
  //       "completly unique choo choo trains, chillin' on the tracks. Life's simple when you're a choo. Nothin' wrong with flexin' your choos in the centuryverse. Railraoders respect the choo.",
  //     properties: {
  //       background: "mythic station",
  //       color: "purple",
  //       stripe: "gold",
  //       sign: "choo got served",
  //       eyes: "cyclops",
  //       mouth: "grin",
  //       arms: "wave and chill",
  //     },
  //   });
  //   eth.addAvatarBadge(context.userData.name);
  //   eth.position.set(300, 250);
  //   context.stageContainers._hud.addChild(eth);
  // });

  addDebugButton("Repair Train Modal", () => {
    const data: TrainStatusPanelParams = {
      trainName: "CHOORIZARD",
      trainComposition: "tungsten",
      locoTextureId: "blue-bomber",
      runs: "138",
      distances: "1,354",
      commodityAmounts: {
        aggregate: 0,
        automobile: 5,
        building_materials: 3,
        crate: 3,
        gas: 4,
        grain: 4,
        granule: 0,
        liquid: 5,
        ore: 0,
        oversized: 6,
        pallet: 0,
        perishable: 0,
        top_secret: 0,
      },
      railCars: [{ rarity: "uncommon" }, { rarity: "mythic" }, { rarity: "common" }, { rarity: "rare" }],
      currentCondition: 50,
    };
    const repPanel = new TrainStatusPanel();
    repPanel.scale.set(0.5);
    repPanel.position.set(500, 400);
    repPanel.update(data);
    context.stageContainers._hud.addChild(repPanel);
  });

  addDebugButton("TEST NEW OTTO COMBOS", () => {
    const ottoTestPanel = new OttoTestPanel();
    ottoTestPanel.init();
    context.stageContainers._hud.addChild(ottoTestPanel);
  });

  addDebugButton("TEST UPDATES MODAL", async () => {
    createUpdatesTypeModal(false);
  });

  addDebugButton("TEST READ MORE MODAL", async () => {
    const data = await getEventBannersGalleryData();
    if (!data) throw new Error("AirTable data not found.");

    createUpdatesTypeModal(true, data[0]);
  });

  addDebugButton("Test Forge slices", () => {
    for (const i of range(18)) {
      const tid = `ui-market-window-forge/blueprint-slices/slice-${i}.png`;
      const sprite = Sprite.from(tid);
      sprite.alpha = 0.5;
      sprite.position.set(100);
      context.stageContainers._hud.addChild(sprite);
    }
  });

  // __window__.addThomasHeads = world.zoomLayers.regionsLayer.thomasRegionMarkers.addThomasHeads;
  // __window__.removeThomasHeads = world.zoomLayers.regionsLayer.thomasRegionMarkers.removeThomasHeads;

  // testPathfinding();

  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////
  //// //// //// //// //// //// //// //// //// //// //// //// //// //// ////

  __window__.debugStationLinks = debugStationLinks;

  // const o = context.stage.addChild(new CardsDrawerFilterPanel(console.warn));

  // __window__.__test_fitObjectInRectangle = __test_fitObjectInRectangle;
  // __window__.fitter = __test_fitObjectInRectangle();

  onKeyPress("r", () => modals.confirmRepair(userData.trainsArray[0] as TrainEntity));

  onKeyPress("s", async () => {
    const WAX = await import("@sdk-integration/contracts");

    const transactions = new Array<any>();
    for (const train of userData.trainsArray) {
      if (train.getInvalidReason()) continue;
      if (train.currentOngoingRun) continue;

      const station = mapData.stations.get(train.currentStationId!);
      if (!station) continue;

      const neigbourId = getRandomItemFrom(station.links).assetId;
      const neigbour = mapData.stations.get(neigbourId);
      if (!neigbour) continue;

      transactions.push([
        WAX.ActionName.Transport,
        {
          railroader: context.contracts.currentUserName,
          train: train.name,
          arriving: neigbour.assetId,
        },
        WAX.ContractName.RR,
      ]);
    }

    if (transactions.length) {
      const promise = context.contracts.actions.performActionTransactions(transactions);
      const tx = await context.spinner.showDuring(promise);
      console.warn(tx);

      await context.spinner.showDuring(context.userDataCtrl.updateAll());
    }
  });

  onKeyPress("c", claimAllRailRuns);

  onKeyPress("m", async () => {
    const WAX = await import("@sdk-integration/contracts");
    const userName = context.contracts.currentUserName;

    const promise = context.contracts.actions.performActionTransactions([
      [
        "buysaleitem",
        {
          railroader: context.contracts.currentUserName,
          template_id: 505858,
        },
        WAX.ContractName.M,
      ],
    ]);
    const tx = await context.spinner.showDuring(promise);
    console.warn(tx);

    await context.spinner.showDuring(context.userDataCtrl.updateAll());
  });

  onKeyPress("o", async () => {
    const cinematicCharacter = "mock3";

    const airtableCinematicsList = await loadCinematicsList();
    
    for (const { name, manifest, cinematicData } of airtableCinematicsList) {
      if (name.toLocaleLowerCase() === cinematicCharacter!.toLowerCase()) {
        console.log(`🌟 Found cinematic data for ${cinematicCharacter}`);
        const cinematic = new NPCEncounterCinematic_FromAirtable(cinematicData, manifest);
        cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
        cinematic.dynamicSpecialEffectsService = dynamicSpecialEffectFunctions();
        cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
        await cinematic.loadAssets();
        context.stageContainers._cinematic.addChild(cinematic);
        cinematic.play();
      }
    }

  });
}
