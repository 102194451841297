/**
 * Region id as represented by a number from 1 to 5.
 */
export enum RegionIntegerId {
  Centuryville = 1,
  PembertonHeights = 2,
  TrevithickPines = 3,
  PawpawPlains = 4,
  JamesPark = 5,
}

export module RegionIntegerId {
  export function toRegionName(region: RegionIntegerId) {
    switch (region) {
      case RegionIntegerId.Centuryville:
        return "Centuryville";
      case RegionIntegerId.PembertonHeights:
        return "Pemberton Heights";
      case RegionIntegerId.TrevithickPines:
        return "Trevithick Pines";
      case RegionIntegerId.PawpawPlains:
        return "Pawpaw Plains";
      case RegionIntegerId.JamesPark:
        return "James Park";
      default:
        throw new Error(`Unknown region: ${region}`);
    }
  }

  const regionNameToIdDict = {
    "centuryville": RegionIntegerId.Centuryville,
    "pemberton heights": RegionIntegerId.PembertonHeights,
    "trevithick pines": RegionIntegerId.TrevithickPines,
    "pawpaw plains": RegionIntegerId.PawpawPlains,
    "james park": RegionIntegerId.JamesPark,
  };

  export function fromRegionName(regionName: string) {
    regionName = regionName.trim().toLowerCase();
    return regionNameToIdDict[regionName as keyof typeof regionNameToIdDict];
  }
}
