import { GameSingletons } from "@game/app/GameSingletons";
import { MiniGameRouletteScreen } from "@game/gameplay/minigames/MiniGameRouletteScreen";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

export async function displayMinigameSelectionScreen() {
  const context = GameSingletons.getGameContext();

  const rouletteScreen = new MiniGameRouletteScreen();
  context.stageContainers._hud.addChild(rouletteScreen);

  const tweeener = new TemporaryTweeener(rouletteScreen);
  tweeener.add(() => {
    rouletteScreen.position.set(context.viewSize.width * 0.5, context.viewSize.height * 0.4);
  });

  await context.ticker.delay(0.85);

  const gameCfg = await rouletteScreen.selectRandomGameAnimated();

  await context.ticker.delay(0.15);

  const playerChoseToPlay = await new Promise<boolean>((resolve, reject) => {
    rouletteScreen.displayButtonsAndHint(
      () => resolve(true),
      () => resolve(false)
    );
  });

  rouletteScreen.destroy();

  return playerChoseToPlay ? gameCfg : null;
}
