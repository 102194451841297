export enum FontFamily {
  Default = 'Font_CaviarDreams_Bold, Font_ToC_Icons, Arial, "Helvetica Neue", Helvetica, sans-serif',
  DefaultThin = 'Font_CaviarDreams, Font_ToC_Icons, Arial, "Helvetica Neue", Helvetica, sans-serif',
  Croogla = 'Font_Croogla, Font_CaviarDreams, Font_ToC_Icons, Arial, "Helvetica Neue", Helvetica, sans-serif',
  DanielBlack = 'Font_DanielBlack, Font_ToC_Icons, Arial, "Helvetica Neue", Helvetica, sans-serif',
  Story = 'Font_Story, Font_ToC_Icons, Arial, "Helvetica Neue", Helvetica, sans-serif',
  Orson = 'Font_Orson',
  Tommy = 'Font_Tommy',
  Shayan = 'Font_Shayan',
  Ash = 'Font_Ash',
  Jame = 'Font_Jame',
  Missy = 'Font_Missy',
  Osipov = 'Font_Osipov',
  Paloma = 'Font_Paloma',
  Gutshot = 'Font_Gutshot',
  Kc = 'Font_Kc',
  Mostra ='Font_MostraLight',
  MostraBold ='Font_MostraBold',
}

export enum BitmapFontName {
  CelestialTypeface = "Celestial Typeface",
}
