export function getDecorationScalesByTextureId(textureId: string): number {
  if (/\/Centuryville\/Church/g.test(textureId)) return 1.7;
  if (/\/Centuryville\/Hall/g.test(textureId)) return 1.8;
  if (/\/Centuryville\/stadium/g.test(textureId)) return 2.7;
  if (/\/Pawpaw Plains\/Pawpaw tree/g.test(textureId)) return 1.1;
  if (/\/Trevithick Pines\/Conservatory/g.test(textureId)) return 1.7;
  if (/\/Trevithick Pines\/dirty-flask/g.test(textureId)) return 1.5;
  if (textureId.includes("1-cville-fortune-teller")) return 2.0;
  if (textureId.includes("2-cville-zoo")) return 1.5;
  if (textureId.includes("3-cville-arcade")) return 2.0;
  if (textureId.includes("4-pheights-jewler")) return 1.25;
  if (textureId.includes("5-pheights-cosmea")) return 2.0;
  if (textureId.includes("6-pheights-lifecoach")) return 2.0;
  if (textureId.includes("7-pheights-clothes")) return 1.5;
  if (textureId.includes("8-pheights-bank")) return 2.5;
  if (textureId.includes("10-tpines-restaraunt")) return 1.5;
  if (textureId.includes("11-tpines-firebird")) return 2.5;
  if (textureId.includes("12-tpines-hospital")) return 2.0;
  if (textureId.includes("13-tpines-museum")) return 2.5;
  if (textureId.includes("15-ppp-bleeker")) return 2.0;
  if (textureId.includes("16-ppp-crickmore")) return 2.5;
  if (textureId.includes("17-ppp-natpark")) return 2.5;
  if (textureId.includes("18-ppp-countryclub")) return 2.0;
  if (textureId.includes("19-jp-boxing")) return 2.0;
  if (textureId.includes("20-jp-casino")) return 2.0;
  if (textureId.includes("modern-building-1_")) return 3;
  if (textureId.includes("modern-building-2")) return 3;
  if (textureId.includes("modern-building-3")) return 3;
  if (/\/Buildings\//g.test(textureId)) return 2.0;
  return 0.5;
}
