export enum MusicSource {
  MainModernTheme = "assets/audio/music/radio/main-1.mp3",
  ADayWithMayhew = "assets/audio/music/radio/main-2.mp3",
  DanceWildcat = "assets/audio/music/radio/main-3.mp3",
  Destined = "assets/audio/music/radio/main-4.mp3",
  Lagrimas = "assets/audio/music/radio/main-5.mp3",
  Songbird = "assets/audio/music/radio/main-6.mp3",

  /*
  xmas1 = "assets/audio/music/radio/xmas1.mp3",
  xmas2 = "assets/audio/music/radio/xmas2.mp3",
  xmas3 = "assets/audio/music/radio/xmas3.mp3",
  xmas4 = "assets/audio/music/radio/xmas4.mp3",
  xmas5 = "assets/audio/music/radio/xmas5.mp3",
  xmas6 = "assets/audio/music/radio/xmas6.mp3", 
  */


  NPCEncounterThomas = "assets/audio/music/npc-thomas.mp3",
  NPCEncounterStranger = "assets/audio/music/npc-stranger.mp3",
  NPCEncounterOtto = "assets/audio/music/npc-otto.mp3",
  NPCEncounterCulprit = "assets/audio/music/npc-culprit.mp3",
  StoryEncounter_Chapter01 = "assets/audio/music/story-stranger-a.mp3",
  NPCEncounterMechanic = "assets/audio/music/npc-mechanic.mp3",
}
