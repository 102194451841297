import { makeDraggable } from "@debug/utils/makeDraggable";
import { GreenButton } from "@game/ui/railroader-dash/panels/settings/components/GreenButton";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Text } from "@pixi/text";
import { playOttoCinematic } from "./__AssetEncounters";

const partsArray = [
  "RAD",
  "RADFAN",
  "SANDBOX",
  "AIRCOMP",
  "TRUCK",
  "FTANK",
  "AIRRES",
  "TURBO",
  "DRIVE",
  "MOTBLOW",
  "AUXALT",
  "ALTER",
  "POWER",
  "ELECTRO",
  "BATT",
  "TRACMO",
  "ENGINE",
  "WHEELS",
];

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export class OttoTestPanel extends Container {
  init() {
    let partChosen: string;
    let encounters: number;
    let i = 0;
    const chosenPartDisplay = new Text("Part: ", {
      fill: 0xffffff,
    });
    const chosenFriendLvlDisplay = new Text("Encounters: ", {
      fill: 0xffffff,
    });

    const bg = new Graphics();
    bg.clear();
    bg.beginFill(0x000000);
    bg.drawRect(600, 400, 300, 400);
    bg.endFill();

    chosenPartDisplay.position.set(670, 500);
    chosenFriendLvlDisplay.position.set(670, 550);
    this.addChild(bg);

    bg.addChild(chosenPartDisplay, chosenFriendLvlDisplay);

    for (let part of partsArray) {
      const partButton = new GreenButton(
        part,
        () => {
          partChosen = part;
          chosenPartDisplay.text = "Part: " + part;
        },
        90
      );
      partButton.setLabelTextSize(15);
      partButton.position.set(bg.x + 80 * i, bg.y);

      bg.addChild(partButton);
      i++;
    }
    for (let num of numbers) {
      const numButton = new GreenButton(
        num.toString(),
        () => {
          encounters = num;
          chosenFriendLvlDisplay.text = "Encounters:  " + num.toString();
        },
        60
      );
      numButton.position.set(bg.x + numButton.width * (num - 1), 60);

      bg.addChild(numButton);
    }

    const testButton = new GreenButton(
      "TEST",
      async () => {
        this.destroy();
        await playOttoCinematic(encounters, partChosen);
      },
      100,
      50
    );
    makeDraggable(testButton);
    testButton.position.set(700, 673);
    bg.addChild(testButton);
  }
}
