import { signInWithCustomToken } from "@firebase/auth";
import { env } from "@game/app/global";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { FirebaseServicesWrapper, USE_LOCAL_EMULATOR } from "./FirebaseServicesWrapper";

const FUNCTIONS_URL_BASE = USE_LOCAL_EMULATOR
  ? "http://localhost:5001/train-of-the-century/us-central1"
  : "https://us-central1-train-of-the-century.cloudfunctions.net";

export async function ensureSignedInToFirebase(context: {
  contracts: Readonly<WaxContractsGateway>;
  firebase: Readonly<FirebaseServicesWrapper>;
}) {
  const f = context.firebase;

  console.log(`user`, f.auth.currentUser);

  if (f.auth.currentUser) {
    const supposedFirebaseUid = (env.BLOCKCHAIN === "testnet" ? "testnet::" : "") + context.contracts.currentUserName;
    if (f.auth.currentUser.uid == supposedFirebaseUid) {
      return f;
    }

    console.warn(`
    Firebase user not matching current wallet address. 
    
    Firebase user: ${f.auth.currentUser.uid}
    Wallet address: ${supposedFirebaseUid}

    Signing out.`);
    await f.auth.signOut();
  }

  const channel = context.contracts.currentAuthProvider;
  const publicKeys = await context.contracts.getUserPublicKeys();

  const waxAddress = context.contracts.currentUserName;
  const testnet = env.BLOCKCHAIN === "testnet";

  // const nonceResponse = await fetch(FUNCTIONS_URL_BASE + "/getNonce", {
  //   method: "POST", // *GET, POST, PUT, DELETE, etc.
  //   mode: "cors",
  //   body: JSON.stringify({ waxAddress, testnet, channel, publicKeys }),
  // });
  const nonceResponse = await f.fn_getNonce_onCall({ waxAddress, testnet, channel, publicKeys });
  const nonceResponseJsonData = (await nonceResponse.data) as any;
  const { nonce } = nonceResponseJsonData;

  const transactionData = getTransactionData(nonce, waxAddress);
  const signed = await context.contracts.currentUser.signTransaction(transactionData.data, transactionData.options);

  const transaction = signed.transaction;
  const { serializedTransaction, signatures } = transaction;

  console.log("Signed:\n", signed, [...serializedTransaction], signatures);

  // const verificationResponse = await fetch(FUNCTIONS_URL_BASE + "/verify", {
  //   method: "POST", // *GET, POST, PUT, DELETE, etc.
  //   mode: "cors",
  //   body: JSON.stringify({
  //     waxAddress,
  //     nonce,
  //     proof: { serializedTransaction, signatures },
  //     testnet,
  //   }),
  // });
  const verificationResponse = await f.fn_verify_onCall({
    waxAddress,
    nonce,
    proof: { serializedTransaction, signatures },
    testnet,
  });
  const verificationJsonData = (await verificationResponse.data) as any;
  const { customToken, userUid } = verificationJsonData;

  const userCredential = await signInWithCustomToken(f.auth, customToken);
  const firebaseUser = userCredential.user;
  console.log(userCredential, { firebaseUser });

  return f;
}

function getTransactionData(nonce: string, waxAddress: string) {
  return {
    data: {
      actions: [
        {
          account: "orng.wax",
          name: "requestrand",
          authorization: [
            {
              actor: waxAddress,
              permission: "active",
            },
          ],
          data: {
            caller: waxAddress,
            signing_value: nonce,
            assoc_id: nonce,
          },
        },
      ],
    },
    options: {
      blocksBehind: 3,
      expireSeconds: 30,
      broadcast: false,
      sign: true,
    },
  };
}
