import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { RailroaderDashPanelBase } from "../RailroaderDashPanelBase";
import { AchievementRRDashList } from "./AchievementsRRDashList";
import { AchievementsRRDashUserListSidePanelDataService } from "./AchievementsRRDashUserListSidePanelDataService";
import { AchievementsRRDashUserListSidePanelManager } from "./AchievementsRRDashUserListSidePanelManager";

export class AchievementsRRDashPanel extends RailroaderDashPanelBase {
  private userListPanel: AchievementsRRDashUserListSidePanelManager = new AchievementsRRDashUserListSidePanelManager();
  private dataService: AchievementsRRDashUserListSidePanelDataService =
    new AchievementsRRDashUserListSidePanelDataService();

  async init(): Promise<void> {
    const { main, userData } = GameSingletons.getGameContext();
    this.addAchievementsListPad();
    this.addAchievementsTitleImage("ui-railroader-dashboard/page-achievements/tab-label.png");
    const pageContainer = new AchievementRRDashList();
    const data = await this.dataService.getUserList();
    //const userNameHolder = [];

    /* for (let datum of data) {
      userNameHolder.push(datum.name);
    }*/

    pageContainer.initialize(this.userListPanel);
    pageContainer.position.set(152, 90);
    main.social.open(userData.name);

    this.addChild(pageContainer);
    const textureId = "ui-railroader-dashboard/inventory/inv-infopanel-redeemable.png";
    const message = "There are 83 Achievements to collect. Click on an Achievement to see who’s unlocked it!";

    this.addGreenPlaque({ textureId, message });
  }

  addAchievementsTitleImage(textureId: string) {
    const texture = this.assets.getTexture(textureId);
    const titleImage = new Sprite(texture);
    titleImage.scale.set(0.75);
    titleImage.position.set(337, -50);
    return this.addChild(titleImage);
  }

  addAchievementsListPad() {
    const container = new Container();
    const texture = this.assets.getTexture("ui-railroader-dashboard/page-achievements/ach-bg.png");
    const sprite = new Sprite(texture);

    sprite.scale.set(0.675);
    sprite.position.set(0, 0);

    container.addChild(sprite);
    container.position.set(152, 59);

    this.addChild(container);
    return container;
  }
}
