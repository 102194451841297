import type { MultipleReasons } from "@sdk/core/MultipleReasons";

export function setupLoadingFavicon(reasonsToShow: MultipleReasons) {
  function greateLinkElement() {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    return link;
  }

  const link = greateLinkElement();
  const FAVICON_LOADING = "favicon-loading.ico";
  const FAVICON_NORMAL = "favicon.ico";

  reasonsToShow.on({
    empty: () => (link.href = FAVICON_NORMAL),
    notEmpty: () => (link.href = FAVICON_LOADING),
  });

  if (reasonsToShow.hasAny()) {
    link.href = FAVICON_LOADING;
  }
}
