import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { SimplifiedAvatarBadge } from "@game/ui/social/avatar/SimplifiedAvatarBadge";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Text } from "@pixi/text";

const commonLabelStyle = {
  fill: "#FFFFFF",
  fontFamily: FontFamily.Default,
};

export class AchievementsRRDashUserListRow extends Container {
  get width(): number {
    return this._width;
  }

  get height(): number {
    return this._height;
  }

  applyUserData({ username = "", socialLink = "" } = {}, width: number) {
    this._width = width;
    this._height = 100;

    const fontSize = 26;
    const centerY = this._height / 2;
    const rowXCoordinates = [100, 140, 410];

    /// Simplified avatar badge
    const avatar = new SimplifiedAvatarBadge();
    SimplifiedAvatarBadge.applyUserData(avatar, username);
    SimplifiedAvatarBadge.showWhenFullyLoaded(avatar);
    avatar.scale.set(0.1);
    avatar.position.set(rowXCoordinates[0], centerY);
    this.addChild(avatar);

    /// Name Text
    const accountLabel = new Text(username, { ...commonLabelStyle, fontSize: fontSize });
    accountLabel.position.set(rowXCoordinates[1], centerY);
    accountLabel.anchor.set(0, 0.5);
    this.addChild(accountLabel);

    /// Row Underline
    const graphics = new Graphics()
      .lineStyle(1.25, 0xffffff)
      .moveTo(avatar.x - 25, this._height)
      .lineTo(rowXCoordinates[2], this._height);
    this.addChild(graphics);

    /// Open social profile on click
    if (socialLink) {
      GameSingletons.getMainInstance().social.registerForQuickViewOnClick(accountLabel, socialLink);
    }
  }
}
