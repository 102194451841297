declare type IFetchFunction = (input?: Request | string, init?: RequestInit) => Promise<Response>;

export function createRoundRobinFetch({
  needle,
  replacements,
}: {
  needle: string;
  replacements: string[];
}): IFetchFunction {
  const endpointsCount = replacements.length;

  let lastEndpointIndexThatWorked = 0;

  return (input, init) => {
    return new Promise(async (resolve, reject) => {
      if (input == undefined) {
        return reject('fetch() "input" argument cannot be undefined');
      }

      if (typeof input !== "string") {
        return reject('Only string values are supported as "input" arguments');
      }

      let response: Response | null = null;

      for (let i = lastEndpointIndexThatWorked; i < endpointsCount; i++) {
        const endpoint = replacements[i];
        const url = input.replace(needle, endpoint);
        console.log(`🎡`, `Fetching from endpoint [${i}] — ${endpoint}`);

        try {
          response = await fetch(url, init);

          if (!response.ok) {
            throw new Error(`Response not ok: ${response.status} ${response.statusText}`);
          }

          // Else, we're good. This was a working endpoint.

          lastEndpointIndexThatWorked = i;

          resolve(response);

          return;
        } catch (e) {
          console.warn(`Assets endpoint [${i}] failed:\n${e}`);
          continue;
        }
      }

      // If we got here, we've exhausted all the endpoints.

      lastEndpointIndexThatWorked = 0;

      reject(new Error(`No response received from any endpoint`));
    });
  };
}
