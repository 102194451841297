import { GameContext } from "@game/app/app";
import { ReadonlyDeep } from "type-fest/source/readonly-deep";

export module GameSingletons {
  let context: GameContext | null = null;

  //// The full thing

  export function getGameContext() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context;
  }

  export function setGameContextRef(gameContext: GameContext) {
    context = gameContext;
  }

  //// Grouped singletons

  export function getDataHolders() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context as ReadonlyDeep<Pick<GameContext, "userData" | "mapData" | "gameConfigData">>;
  }

  export function getIntergrationServices() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context as ReadonlyDeep<Pick<GameContext, "contracts" | "firebase">>;
  }

  export function getAudioServices() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context as ReadonlyDeep<Pick<GameContext, "sfx" | "music">>;
  }

  export function getUIServices() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return {
      modals: context.modals,
      hud: context.main.hud,
      contextMenu: context.main.hud.contextMenu,
      tooltips: context.main.hud.tooltips,
    };
  }

  //// Solitary Singletons

  export function getResources() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.assets;
  }

  export function getSimpleObjectFactory() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.simpleFactory;
  }

  export function getPixiApplication() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.app;
  }

  export function getMainInstance() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.main;
  }

  export function getGenericModalsFactory() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.modals;
  }

  export function getTicker() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.ticker;
  }

  export function getInputService() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.input;
  }

  export function getSpinner() {
    if (context == null) {
      throw new Error("GameContext is not initialized");
    }

    return context.spinner;
  }
}
