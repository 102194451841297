import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { GlowFilterService } from "@game/ui/fx/GlowFilterService";
import { Container } from "@pixi/display";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { AchievementBadge } from "../../achievements/AchievementBadge";
import { AchievementBadgeData } from "../../achievements/AchievementBadgeData";
import { AchievementsList } from "../viewComponents/AchievementsList";

export class AllAchievementsListView extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  public readonly badges: Map<number, AchievementBadge> = new Map();

  public onClick: boolean = true;
  public isDesktop: boolean = true;

  public onBadgeClicked?: (info: AchievementBadgeData) => void;

  private readonly achievementList: AchievementsList;

  private readonly glowService: GlowFilterService = new GlowFilterService();

  constructor() {
    super();
    this.achievementList = new AchievementsList(420);
    this.addChild(this.achievementList);
    this.addHint("(CLICK TO ADD)");
  }

  async addAchievements(data: Array<AchievementBadgeData>) {
    await this.achievementList.addAchievements(data);

    //// Add events for the achievement list selection
    for (let i = 0; i < data.length; i++) {
      const achievementId = data[i].achievementId;
      const badge = this.achievementList.content.children[i] as AchievementBadge;
      this.badges.set(achievementId, badge);
      buttonizeDisplayObject(this.achievementList.content.children[i], () => {
        const info = badge.info;
        if (!info) throw Error("Achievement badge doesn't have info property");
        this.onBadgeClicked?.(info);
      });
    }
  }

  addHint(string: string) {
    const hint = this.factory.createText(string, { fill: 0x4f5253, fontSize: 20 }, { x: 238, y: -25 });
    this.addChild(hint);

    if (this.isDesktop) {
      this.interactive = true;
      this.on("mouseover", () => {
        hint.visible = true;
      });
      this.on("mouseout", () => {
        hint.visible = false;
      });
      hint.visible = false;
    }
  }

  clear() {
    this.achievementList.clear();
  }

  setHighlight(info: AchievementBadgeData, isHighlighted: boolean) {
    const badge = this.badges.get(info.achievementId);
    if (!badge) throw Error("Achievement badge not found for #" + info.achievementId);
    if (isHighlighted) {
      this.glowService.addFilter(badge);
    } else {
      this.glowService.removeFrom(badge);
    }
  }
}
