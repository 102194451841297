import { __DEBUG__ } from "@debug/__DEBUG__";
import { __urlParams__ } from "@game/app/__urlParams__";
import { NPCEncounterCinematicData } from "@game/cinematics/types/NPCEncounterCinematicData";
import { GoldenRunCheckResult } from "@game/ui/railroader-dash/panels/goldenRuns/models";
import { AvatarBadgePreferences } from "@game/ui/social/avatar/AvatarBadgePreferences";
import { initializeApp } from "firebase/app";
import { browserLocalPersistence, connectAuthEmulator, getAuth } from "firebase/auth";
import {
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { LiteralUnion } from "type-fest";
import type { RemoteConfiguration } from "./types/RemoteConfiguration";

export const USE_LOCAL_EMULATOR = __DEBUG__ && __urlParams__.emulator;

type CinematicDataKey = LiteralUnion<
  | "debug_encounter_interactive"
  | "debug_encounter_short"
  | "mock_campaign_1"
  | "mock_campaign_2"
  | "mock_campaign_3"
  | "asset_encounter_stranger"
  | "asset_encounter_thomas"
  | "asset_encounter_otto"
  | "asset_encounter_culprit"
  | "asset_encounter_mechanic",
  string
>;

type UserMainData = {
  firstSignIn: { walletType: string; at: Timestamp };
  lastSignIn: { walletType: string; at: Timestamp };
  storyUnlocked?: Record<string, boolean>;
  storyWatched?: Record<string, boolean>;
  storyChoices?: Record<string, any>;
  goldenRunCheckResults?: Record<string, GoldenRunCheckResult>;
  preferences?: {
    minigamesEnabled?: boolean;
    autorepairEnabled?: boolean;
  };
  readUpdates?: Record<string, boolean>;
  readAchievements?: Record<string, boolean>;
  extraAchievements?: Record<string, boolean>;
  encountersCount_Otto?: number;
};

type UserSocialData = {
  avatarBadgePreferences?: Partial<AvatarBadgePreferences>;
  miscProfilePreferences?: Partial<{
    tagline: string;
    featuredAchievements: number[];
  }>;
  ethereum?: Partial<{ address: string }>;
};

export class FirebaseServicesWrapper {
  public readonly app;
  public readonly auth;
  public readonly db;
  public readonly fn;

  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyAHR5a_S6uxlgOAExTsC4nnMTbIJjPHGek",
      authDomain: "train-of-the-century.firebaseapp.com",
      projectId: "train-of-the-century",
      storageBucket: "train-of-the-century.appspot.com",
      messagingSenderId: "746593372300",
      appId: "1:746593372300:web:5a8795b2455ffc0f75d3a9",
      measurementId: "G-9KNMD2B751",
    };

    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.db = getFirestore();
    this.fn = getFunctions();

    this.auth.setPersistence(browserLocalPersistence);

    if (USE_LOCAL_EMULATOR) {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      connectFirestoreEmulator(this.db, "localhost", 60003);
      connectFunctionsEmulator(this.fn, "localhost", 5001);
    }

    this.fn_getNonce = httpsCallable(this.fn, "getNonce");
    this.fn_getNonce_onCall = httpsCallable(this.fn, "getNonceOnCall");
    this.fn_verify = httpsCallable(this.fn, "verify");
    this.fn_verify_onCall = httpsCallable(this.fn, "verifyOnCall");
    this.fn_checkGoldenRunTest = httpsCallable(this.fn, "checkGoldenRunTest");
    this.fn_checkGoldenRunMint = httpsCallable(this.fn, "checkGoldenRunMint");
    this.fn_checkGoldenRunAuth = httpsCallable(this.fn, "checkGoldenRunAuth");
    this.fn_checkGoldenRun = httpsCallable(this.fn, "checkGoldenRun");
  }

  public readonly fn_getNonce;
  public readonly fn_getNonce_onCall;
  public readonly fn_verify;
  public readonly fn_verify_onCall;
  public readonly fn_checkGoldenRunTest;
  public readonly fn_checkGoldenRunMint;
  public readonly fn_checkGoldenRunAuth;
  public readonly fn_checkGoldenRun;

  private async getDocData<T extends {} | undefined>(path: string, ...pathSegments: string[]) {
    const _ref = doc(this.db, path, ...pathSegments);
    const _doc = await getDoc(_ref);
    const _data = _doc.data() as T;
    console.log(`🔥`, _ref.path, _data);
    return _data;
  }

  private async updateDocData<T extends {}>(data: T, path: string, ...pathSegments: string[]) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    const _ref = doc(this.db, path, ...pathSegments);
    const result = await setDoc(_ref, data, { merge: true });
    console.log(`🔥 //UPDATED// `, _ref.path, data, result);
    return result;
  }

  /// User

  async getUserMainData(username?: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    if (!username) username = this.auth.currentUser.uid;
    else if (__DEBUG__) username = `testnet::` + username;
    return await this.getDocData<UserMainData>("users", username);
  }

  async getUserSocialData(username?: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    if (!username) username = this.auth.currentUser.uid;
    if (__DEBUG__) username = /testnet\:\:(.+)/gi.exec(username)?.[1] || username;
    return await this.getDocData<UserSocialData | undefined>("social", username);
  }

  async updateUserMainData(data: Partial<UserMainData>, username?: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    if (!username) username = this.auth.currentUser.uid;
    else if (__DEBUG__) username = `testnet::` + username;
    return await this.updateDocData(data, "users", username);
  }

  async updateUserSocialData(data: Partial<UserSocialData>, username?: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    if (!username) username = this.auth.currentUser.uid;
    if (__DEBUG__) username = /testnet\:\:(.+)/gi.exec(username)?.[1] || username;
    return await this.updateDocData(data, "social", username);
  }

  /// General

  async getFeaturesConfiguration() {
    return await this.getDocData<RemoteConfiguration.Features>("settings/features");
  }

  async getGameplayConfiguration() {
    return await this.getDocData<RemoteConfiguration.Gameplay>("settings/gameplay");
  }

  /// Golden Runs

  async getGoldenRunSocietyMembers() {
    const data = await this.getDocData<{
      members?: Record<
        string,
        {
          commodity: number;
          locomotive: number;
          railcar: number;
          railroader: string;
          rewardId: string;
          trxId: string;
        }
      >;
    }>("goldenruns/society");
    return data.members || {};
  }

  async setUserGoldenRunCheckResult(key: string, result: GoldenRunCheckResult) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    const uid = this.auth.currentUser.uid;
    const _ref = doc(this.db, `users`, uid);
    return await updateDoc(_ref, { [`goldenRunCheckResults.` + key]: result });
    // return await this.updateDocData({ [`goldenRunCheckResults.` + key]: result }, "users", this.auth.currentUser.uid);
  }

  /// Encounters

  async getStoryConfiguration() {
    return await this.getDocData<
      Record<
        string,
        {
          name: string;
          chapterName: string;
          startAt: Timestamp;
          minDistance: number;
          disabled?: boolean;
        }
      >
    >("settings/story");
  }

  async getCinematicsData(key: CinematicDataKey) {
    if (__DEBUG__ && HACK?.[key]) return HACK[key];
    return await this.getDocData<NPCEncounterCinematicData>("cinematics", key);
  }

  async setUserStoryUnlocked(key: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    const uid = this.auth.currentUser.uid;
    const _ref = doc(this.db, `users`, uid);
    return await updateDoc(_ref, { [`storyUnlocked.` + key]: true });
    // return await this.updateDocData({ [`storyUnlocked.` + key]: true }, "users", this.auth.currentUser.uid);
  }

  async setUserStoryWatched(key: string) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    const uid = this.auth.currentUser.uid;
    const _ref = doc(this.db, `users`, uid);
    return await updateDoc(_ref, { [`storyWatched.` + key]: true });
    // return await this.updateDocData({ [`storyWatched.` + key]: true }, "users", this.auth.currentUser.uid);
  }

  async setUserStoryChoices(data: any) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    const uid = this.auth.currentUser.uid;
    const _ref = doc(this.db, `users`, uid);
    return await updateDoc(_ref, { [`storyChoices`]: data });
    // return await this.updateDocData({ [`storyChoices`]: data }, "users", this.auth.currentUser.uid);
  }

  /// Arcade

  async getMinigameHighscores(game: string) {
    collection;

    if (!this.auth.currentUser) throw new Error("User not logged in");
    return await this.getDocData<{ [user: string]: number }>("minigames/highscores_" + game);
  }

  async updateMinigameHighscore(game: string, score: number) {
    if (!this.auth.currentUser) throw new Error("User not logged in");
    let username = this.auth.currentUser.uid;
    username = /testnet\:\:(.+)/gi.exec(username)?.[1] || username;
    return await this.updateDocData({ [username]: score }, "minigames/highscores_" + game);
  }
}

let HACK = null as any;
//if (__DEBUG__) import("../../../data/src/index").then(DATA => console.log({ CINEMATICS_DATA: (HACK = DATA) }));
