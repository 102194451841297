import { GameSingletons } from "@game/app/GameSingletons";
import { SfxManager } from "@game/app/sound/sfxManager";

export function playMinigameRouletteSound(soundString: string, pitch?: boolean) {
  let pitchFunc;
  if (pitch == false) {
    pitchFunc = 0.8;
  } else {
    pitchFunc = generateRandom();
  }
  return GameSingletons.getAudioServices().sfx.play(
    soundString,
    false,
    SfxManager.MultipleInstanceStrategy.None,
    pitchFunc
  );

  function generateRandom(min = 0.5, max = 0.6) {
    return min + Math.random() * (max - min);
  }
}
