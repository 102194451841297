import { lipsum } from "@debug/utils/lipsum";
import { GameSingletons } from "@game/app/GameSingletons";
import { GameConfigurationData } from "@game/data/GameConfigurationData";
import { getRandomItemFrom } from "@sdk/helpers/arrays";
import { delay } from "@sdk/utils/promises";
import { chance, randomIntBetweenIncluding } from "@sdk/utils/random";
import { range } from "@sdk/utils/range";
import { createCenturyVialDataFromAMPAmount } from "../../game/ui/windows/market/pages/emporium/data/createCenturyVialDataFromAMPAmount";
import {
  EmporiumStoreItemData,
  EmporiumStoreItemData_CenturyVial,
  EmporiumStoreItemData_CTPart,
} from "../../game/ui/windows/market/pages/emporium/data/EmporiumStoreItemData.model";

export module MockEmporiumDataService {
  type TokenSymbol = keyof GameConfigurationData["ctPartsData"];

  export function getPurchasableItems(count: number, ampChance?: number): Generator<EmporiumStoreItemData>;
  export function getPurchasableItems(count: number, ampChance: 0): Generator<EmporiumStoreItemData_CTPart>;
  export function getPurchasableItems(count: number, ampChance: 1): Generator<EmporiumStoreItemData_CenturyVial>;
  export function* getPurchasableItems(count: number, ampChance: number = 0.4): Generator<EmporiumStoreItemData> {
    const context = GameSingletons.getGameContext();

    const tokenSymbols = Object.keys(context.gameConfigData.ctPartsData) as TokenSymbol[];

    for (const i of range(count)) {
      if (chance(ampChance)) {
        const ampAmount = getRandomItemFrom([1, 2, 6, 12, 18]);
        yield {
          id: i,
          type: "amp",
          name: lipsum.line(),
          railroader: lipsum.userName(),
          price: 1000 * randomIntBetweenIncluding(1, 80, 2),
          centuryVialData: createCenturyVialDataFromAMPAmount(ampAmount),
          timeCreated: Math.random() * Date.now(),
          amount: 1,
        };
      } else {
        const tokenSymbol = getRandomItemFrom(tokenSymbols);
        const ctPartData = context.gameConfigData.ctPartsData[tokenSymbol];
        yield {
          id: i,
          type: "part",
          name: ctPartData.part,
          railroader: lipsum.userName(),
          price: 1000 * randomIntBetweenIncluding(1, 80, 2),
          ctPartData: context.gameConfigData.ctPartsData[tokenSymbol],
          timeCreated: Math.random() * Date.now(),
          amount: 1,
        };
      }
    }
  }

  export async function getLowestListingPrice() {
    await delay(1.0);
    return Math.floor(Math.random() * 1000);
  }
}
