import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import {Story, conductorData} from "../data/conductorData"

export class ConductorStoryScrollbox extends SafeScrollbox {
  private readonly story: string;
  private readonly conductorName: string;
  private readonly volumeNumber: number;
  private font : FontFamily = FontFamily.Orson;
  private fontSize: number = 28;
  private lineHeight: number = 34;

  constructor(conductorName: string,volumeNumber: number, story:string) {
    super({
      noTicker: true,
      boxWidth: 620,
      boxHeight: 460,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });
    this.conductorName = conductorName;
    this.volumeNumber = volumeNumber;
    this.story = story;

//Fonts
    switch(conductorName) {
      case "Orson Brisk":
          this.font = FontFamily.Orson;
          break;
      case "Piggy Back Ned":
          this.font = FontFamily.Story;
          this.fontSize = 24;
          this.lineHeight = 28;
           break;
      case "Tommy Two-Pair":
          this.font = FontFamily.Tommy;
          this.fontSize = 20;
          this.lineHeight = 24;
           break;
      case "Shayan Kahree":
          this.font = FontFamily.Shayan;
          this.fontSize = 28;
          this.lineHeight = 30;
           break;
      case "Whiplash Ash":
          this.font = FontFamily.Ash;
          this.fontSize = 24;
          this.lineHeight = 26;
          break;
      case "Billy Clover":
          this.fontSize = 32;
          this.lineHeight = 34;
          break;   
      case "Speedy Jame":
          this.font = FontFamily.Jame;
          this.fontSize = 18;
          this.lineHeight = 24;
          break;    
      case "Missy Sweetluck":
          this.font = FontFamily.Missy;
          this.fontSize = 16;
          this.lineHeight = 23;
          break; 
      case "Paloma Haulita":
          this.font = FontFamily.Paloma;
          this.fontSize = 18;
          this.lineHeight = 24;
          break; 
       case "Big Break Osipov":
          this.font = FontFamily.Osipov;
          this.fontSize = 20;
          this.lineHeight = 26;
          break;    
      case "Gutshot Gauthier":
        this.font = FontFamily.Gutshot;
        this.fontSize = 18;
        this.lineHeight = 20;
        break;    
      case "KC Jones":
        this.font = FontFamily.Kc;
        this.fontSize = 32;
        this.lineHeight = 24;
          break;
      // Add more cases as necessary
      default:
          this.font = FontFamily.Orson;
          this.fontSize =28;
          this.lineHeight = 34;
          break;
  }
  }

  async initialize() {
   
    const createStoryContainer = async () => {
      this.clearList();
      const { simpleFactory, ticker } = GameSingletons.getGameContext();
      const storyContainer = new Container();
      this.content.addChild(storyContainer);
      
       //// Add Event Body text
       const storyText = simpleFactory.createText(this.story, {
        fontFamily: this.font,
        fontSize: this.fontSize,
        fill: 0x000000,
        wordWrap: true,
        wordWrapWidth: 515,
        lineHeight: this.lineHeight,
      });

      storyText.anchor.set(0.5, 0);
      storyText.position.set(312,0);
      storyContainer.addChild(storyText);

      await ticker.nextFrame();
      this.content.addChild(this.addInvisibleBox(426));
      this.update();
    };

    createStoryContainer();
  }
  
  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}