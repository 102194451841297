import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";

export class LightDot extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private spriteOff: Sprite;
  private spriteOn: Sprite;

  constructor() {
    super();

    //// Off light
    this.spriteOff = this.factory.createSprite("ui-railroader-dashboard/event-banners/inactive-indicator.png");
    this.spriteOff.anchor.set(0.5);
    this.spriteOff.visible = false;
    this.addChild(this.spriteOff);

    //// On light
    this.spriteOn = this.factory.createSprite("ui-railroader-dashboard/event-banners/active-indicator.png");
    this.spriteOn.anchor.set(0.5);
    this.spriteOn.visible = false;
    this.addChild(this.spriteOn);

    //// set 50 x 40 hitarea around origin
    const hitAreaWidth = 50;
    const hitAreaHeight = 40;
    const hitArea = new Rectangle(-hitAreaWidth / 2, -hitAreaHeight / 2, hitAreaWidth, hitAreaHeight);
    this.hitArea = hitArea;
  }

  active() {
    this.spriteOff.visible = false;
    this.spriteOn.visible = true;
  }

  inactive() {
    this.spriteOff.visible = true;
    this.spriteOn.visible = false;
  }
}
