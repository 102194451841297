import { getRandomIPFSHash } from "@debug/mock/random.ipfs-hash";
import { __window__ } from "@debug/__";
import { __DEBUG_SETTINGS__ } from "@debug/__DEBUG_SETTINGS__";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { env } from "@game/app/global";

/**
 * Adds various behaviors to the "clickOnStation" events for
 * easier debugging.
 *
 * Examples:
 * * Hold shift key while clicking to assign a fake billboard ipfs hash to the station.
 * * Click with the middle mouse button to open up the Station Dashboard regardless of the station's owner.
 */
export function overrideStationClick() {
  const context = GameSingletons.getGameContext();
  const { main, modals, input, userData } = context;

  input.addListener(
    "clickOnStation",
    async (station, interactionData) => {
      try {
        const isShiftKeyHeld = interactionData.originalEvent.shiftKey;

        /**
         * For easy debugging, if the user is holding down the [Shift] key,
         * set a mock IPFS hash for the station.
         */
        if (isShiftKeyHeld && !station.waxAssetData.billboard) {
          station.waxAssetData.billboard = getRandomIPFSHash();
        }

        /**
         * For easy debugging, if the user has clicked on a station with the
         * middle mouse button, then open the "My station dashboard" popup,
         * regardless of whether the station is owned by the user.
         */
        if (interactionData.button === 1) {
          let stationId = station.assetId;
          if (env.BLOCKCHAIN === "testnet" && station.ownerName != userData.name) {
            stationId = "1099521667582" as any;
            station = context.mapData.stations.get(stationId) || station;
          }

          __window__.__myTrainIsEverywhere__ = true;

          if (__DEBUG_SETTINGS__["Station-Dashboard"]) {
            main.popups.clear();
            main.popups.myStationDashboard.setCurrentStation(station);
            return;
          }

          if (__DEBUG_SETTINGS__["Yard"]) {
            main.popups.clear();
            main.popups.addonRailYard.setCurrentStation(station);
            return;
          }

          if (__DEBUG_SETTINGS__["Lounge"]) {
            main.popups.clear();
            main.popups.addonConductorLounge.setCurrentStation(station);
            return;
          }
        }

        // if (interactionData.button === 2) {
        //   const train = main.faq.getFirstTrainAtStation(station);
        //   if (train != null) {
        //     const trainTampered = [...train.iterateAllEquippedCards()].some(c => !c);
        //     if (trainTampered) {
        //       return await contracts.events.dispatch('trainTampered', train.name, new Error(`
        //       This train contains invalid cards.
        //       Did you tranfer one or more of the equipped cards to another user?
        //       `.trim()))
        //     }
        //   }
        // }
      } catch (error) {
        modals.warning(`` + error);
      }
    },
    null,
    100.0
  );
}
