import { GameSingletons } from "@game/app/GameSingletons";
import { GenericModalBaseCornerDetailType } from "@game/ui/generic-modals/GenericModalBase";
import { nextFrame } from "@sdk/utils/promises";
import { playMinigame } from "./displayMinigame";
import { displayMinigameSelectionScreen } from "./displayMinigameSelectionScreen";

export async function resolveMinigameMoment() {
  const { modals, firebase } = GameSingletons.getGameContext();

  const chosenMiniGameCfg = await displayMinigameSelectionScreen();

  if (chosenMiniGameCfg == null) return;

  const score = await playMinigame(chosenMiniGameCfg.url);

  firebase.updateMinigameHighscore(chosenMiniGameCfg.highscoreKey, score).catch(console.warn);

  await nextFrame();

  await modals.alert({
    title: "Minigame Result",
    content: `You scored ${score} points!`,
    cornerDetailType: GenericModalBaseCornerDetailType.Gears,
    okButtonText: "Neat!",
  });
}
