import { GameSingletons } from "@game/app/GameSingletons";
import { AssetsManager } from "@game/app/services/AssetsManager";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { ThemeColors } from "@game/constants/ThemeColors";
import { PageObjectManager } from "@sdk-pixi/ui-helpers/PageObjectManager";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { Container } from "@pixi/display";
import { Text } from "@pixi/text";
import { arrangeInStraightLine } from "@sdk-pixi/layout/arrangeInStraightLine";
import { NavigationTabsManager } from "@sdk-pixi/ui-helpers/NavigationTabsManager";
import { CTPartsFusionSubpage } from "./CTPartsFusionSubpage";
import { CTPartsOttoSubpage } from "./CTPartsOttoSubpage";
import { CTPartsForgeSubpage } from "./CTPartsForgeSubpage ";

export enum CTPartsSubpageKey {
  DiscoveredParts = "discoveredParts",
  Fuse = "fuse",
  Forge = "forge",
}

export class CTPartsPage extends EnchantedContainer {

  public readonly titleString = "Century Train Parts";
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly assets: AssetsManager = GameSingletons.getResources();
  onItemSelected?: (itemType: CTPartsSubpageKey) => unknown;

  async loadAndInitialize(initialSubpage: CTPartsSubpageKey = CTPartsSubpageKey.DiscoveredParts) {

 
    this.onItemSelected = (key: CTPartsSubpageKey) => {
      pageManager.setCurrentPage(key);
    };


    const builderFunctions: Record<CTPartsSubpageKey, () => Container> = {
      [CTPartsSubpageKey.Fuse]: () => {
        const page = new CTPartsFusionSubpage();
        page.onSwitchShopkeeperClick = () => pageManager.setCurrentPage(CTPartsSubpageKey.DiscoveredParts);
        return page;
      },
      [CTPartsSubpageKey.DiscoveredParts]: () => {
        const page = new CTPartsOttoSubpage();
        page.onSwitchShopkeeperClick = () => pageManager.setCurrentPage(CTPartsSubpageKey.Fuse);
        return page;
      },
      [CTPartsSubpageKey.Forge]: () => new CTPartsForgeSubpage(),
    };

// FUSE AND FORGE tabs 

/// FUSE AND FORGE  Navigation
    const panel = this.createItemButtons();
    panel.position.set(550, 225);

   //page manager 
    const pageManager = new PageObjectManager(builderFunctions, this);
    pageManager.events.on({
      beforeChange: pageKey => {
        if (pageKey == CTPartsSubpageKey.DiscoveredParts)  {
          //tabs.visible = false
          //console.log(`Hide the tabs`);
          this.removeChild(panel);
        } else {
          //console.log(`Show the tabs`);
          this.addChild(panel);
        }
      },
    });
    pageManager.setCurrentPage(initialSubpage);
  }

  createModButton(label: string) {
    const btn = this.factory.createSprite("ui-market-window-emporium/buy/filter-assets/lg-filterbg.png");
    const labelText = this.factory.createText(label, { fontSize: 36 }, { x: 185, y: 40 });
    labelText.anchor.set(0.5);
    btn.addChild(labelText);
    return btn;
  }

  createItemButtons() {
    const self = this;
    const tabButtonsContainer = new Container();
    this.addChild(tabButtonsContainer);
    const addTabButton = (label: string) => {
      const button = this.createModButton(label.toUpperCase());
      tabButtonsContainer.addChild(button);
      return button;
    };
    const tabsConfiguration = NavigationTabsManager.tabOptionsFromDictionary({
      [CTPartsSubpageKey.Fuse]: addTabButton("Fuse"),
      [CTPartsSubpageKey.Forge]: addTabButton("Forge"),
    });

    arrangeInStraightLine(tabButtonsContainer.children, { spacing: 23 });

    const tabsManager = new NavigationTabsManager(tabsConfiguration, {
      setSelected(tab, selected) {
        if (selected) {
          tab.texture = self.assets.getTexture("ui-market-window-emporium/mods/highlighted sub-tab.png");
          (tab.children[0] as Text).tint = 0xffffff;
        } else {
          tab.texture = self.assets.getTexture("ui-market-window-emporium/buy/filter-assets/lg-filterbg.png");
          (tab.children[0] as Text).tint = ThemeColors.HIGHLIGHT_COLOR.toInt();
        }
      },
    });
    tabsManager.onSelectionChange = key => {
      this.onItemSelected?.(key);
    };
    tabsManager.setSelectedTabIndex(0);

    return tabButtonsContainer;
  }

}
