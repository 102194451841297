import {GameSingletons} from "@game/app/GameSingletons";
import {TemporaryTweeener} from "@sdk/pixi/animations/TemporaryTweener";
import {Container, DisplayObject} from "@pixi/display";
import {SimpleObjectsFactory} from "@game/app/services/SimpleObjectsFactory";
import {Texture} from "@pixi/core";
import {Rectangle} from "@pixi/math";
import {Sprite} from "@pixi/sprite";
import {buildingData, Building, RoomType, ClickableRegion} from "./data/buildingData";
import {RoomBackground} from "./roomBackground";
import {buttonizeDisplayObject} from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import {flickeringLight, brightnessOsc} from './effects';
import {createItemContainer, addPairedPointerdownListeners, scaleArcade} from '@game/ui/buildings/bldgUtility';
import {InteractionEvent} from "@pixi/interaction";
import {wiggle, sprayParticle} from "./actions";
import {TalesModal} from "./modals/libraryModals";
import {wantedModal} from "./modals/choopyModals";
import {miniGameModal } from "./modals/miniGameModal";
import {InteractionData} from "@pixi/interaction";
import {buttonizeInstance} from "@sdk-ui/buttonize";

export class BackButton extends Sprite {
  public id : number;
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  tweeener = new TemporaryTweeener(this);
  public btnImage : Sprite | null = null;
  public context = GameSingletons.getGameContext();

  constructor(id : number) {
    super();
    this.id = id;
  }
  async init() { // // Add background
    const texture = Texture.from('arrowBtn1')
    this.btnImage = Object.assign(new Sprite(texture), {
      rotationSpeed: -0.04,
      scaleMultiplier: .8
    });
    this.addChild(this.btnImage);
  }

  playButtonAnimation(duration : number) {
    this.tweeener.delay(0);
    return this.tweeener.from(this.btnImage, {
      rotationSpeed: -5,
      scaleMultiplier: 2.0,
      alpha: 0.0,
      duration: duration,
      ease: "power2.out"
    });
  }
}


export class NavBetweenBuildingsArrow extends Sprite {
  public buildingId: number;
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly tweeener = new TemporaryTweeener(this);
  public arrowImage: Sprite | null = null;
  public context = GameSingletons.getGameContext();
  public regionId: number;
  public direction:string;

  constructor(buildingId: number, regionId: number,direction:string) {
    super();
    this.buildingId = buildingId;
    this.regionId = regionId;
    this.direction = direction;
  }

  async init() {
    // Add Image
    if(this.direction === 'left'){
    const texture = Texture.from('lBtn');
    this.arrowImage = Object.assign(new Sprite(texture), {
      rotationSpeed: -0.04,
      scaleMultiplier: 1.0
    });
    this.addChild(this.arrowImage);
  }
  if(this.direction === 'right'){
    const texture = Texture.from('rBtn');
    this.arrowImage = Object.assign(new Sprite(texture), {
      rotationSpeed: -0.04,
      scaleMultiplier: 1.0
    });
    this.addChild(this.arrowImage);

  }
  }

  playNavArrowAnimation(duration: number) {
    this.tweeener.delay(0);
    return this.tweeener.from(this.arrowImage, {
      rotationSpeed: -5,
      scaleMultiplier: 2.0,
      alpha: 0.0,
      duration: duration,
      ease: "power2.out"
    });
  }

  

 
}

export class bldgHouse extends Container {
  public context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  public buildingData = buildingData;
  private backButton : BackButton | null = null;
  private previousRoomId : number | null = null;


  constructor(public name : string, public buildingId:number, public regionId : number, public noTrans? : number[], public rooms : RoomType[] = [], public currentRoom : number = 1,public buildingsInRegion : Building[] = []) {
    super();
  
    // Find the building data from the `buildingData` array
    const building = this.buildingData.find(bldg => bldg.buildingId === buildingId && bldg.regionId === regionId);
    if (! building) {
      console.error(`Building with name "${
        this.name
      }" not found in building data.`);
      return;
    }
    this.regionId = building.regionId;
    this.buildingsInRegion = this.buildingData.filter(building => building.regionId === regionId);
    this.buildingId = building.buildingId;
    this.name = building.name;

    this.rooms = building.rooms;
    this.noTrans = building.noTrans;
    this.currentRoom = this.rooms[0].id;
    this.previousRoomId = this.currentRoom;
    this.tweeener = new TemporaryTweeener(this);

    // Display the first room
    this.displayRoom(this.buildingId,this.regionId,this.currentRoom, this.noTrans, false);
    /* console.log("notrans", this.noTrans); */

  }

  async displayRoom(buildingId: number, regionId:number, roomId : number, noTransition? : number[], isRoomLoaded : boolean = false) {
   
    const building = this.buildingData.find(bldg => bldg.buildingId === buildingId && bldg.regionId === regionId);
if (! building) {
  console.error(`Building with id "${buildingId}" and regionId "${regionId}" not found in building data.`);
  return;
}


   // Find the room data from the building's `rooms` array
    const room = building.rooms.find(rm => rm.id === roomId);
    if (! room) {
      console.error(`Room with id "${roomId}" not found in building data.`);
      return;
    }

    // Create a new Room object for the selected room
    const newRoom = new Room(room.id, room.name, room.hideHUD,room.image, room.items, room.clickableRegions,buildingId,this.regionId);
    this.addChild(newRoom);

    // Initialize the room, passing in a flag indicating whether the room is fully loaded
    await newRoom.init(isRoomLoaded);
    if (!isRoomLoaded) {
     // Set the value of previousRoomId to the current room's id
      this.previousRoomId = this.currentRoom;
    }
    // Update the current room
    this.currentRoom = roomId;

   
    // Filters out Rooms with no Transitions
    if (!this.noTrans || !this.noTrans.includes(newRoom.id)) {
      await newRoom.playShowAnimation(1);
      isRoomLoaded = true;
    }

    // If the current room is not the default room, create the back button
    if (roomId !== this.rooms[0].id && !this.backButton) { // Create the back button
      const backButton = new BackButton(roomId);

      // Rotate the back button depending on room
      if (room.backDirection == "down" || "") { // Scale the clickable regions
        const container = createItemContainer({
          x: .5,
          y: 1
        }, {
          x: 0,
          y: 25
        }, backButton.width, backButton.height, false);
        container.addChild(backButton);
        this.addChild(container);
        backButton.init();
        backButton.rotation = -Math.PI / 2;
      } else if (room.backDirection == "left") { // Scale the clickable regions
        const container = createItemContainer({
          x: 0,
          y: .5
        }, {
          x: 25,
          y: 0
        }, backButton.width, backButton.height, false);
        container.addChild(backButton);
        this.addChild(container);
        backButton.init();
      }

    // Back Btn HOVER
      backButton.interactive = true;
      backButton.buttonMode = true;

      backButton.on("pointerover", (event : InteractionEvent) => { // Animate the size of the itemSprite using the tweener
        this.tweeener.to(backButton, {
          pixi: {
            scale: 1.1
          },
          duration: 0.25,
          ease: "back.out",
          overwrite: true
        });
      });
      backButton.on("pointerout", (event : InteractionEvent) => { // Animate the size of the itemSprite back to its original size using the tweener
        this.tweeener.to(backButton, {
          pixi: {
            scale: 1
          },
          duration: 0.4,
          ease: "back.out",
          overwrite: true
        });
      });
      backButton.on("pointerdown", () => {
        this.context.sfx.play("clickTiny");
      });
      await backButton.playButtonAnimation(.5);

      // Buttonize the back button
      buttonizeDisplayObject(backButton, {
        onTrigger: () => {
          if(room.hideHUD===true){
            this.context.input.emit("restoreHUD", this.name);
          }
          this.goToPreviousRoom();
          const roomId = this.currentRoom;
          this.displayRoom(this.buildingId, this.regionId, roomId);
        }
      });
    }

   //Outside Nav Arrows 
     // Only create navigation arrows on room 1 of each building
     if (roomId === 1) {
      
  
      if (this.buildingsInRegion.length > 1) {

          // Create left arrow
          const leftArrow = new NavBetweenBuildingsArrow(buildingId, regionId, "left");
          const navBtnContainerL = createItemContainer({
            x: 0,
            y: .4
          }, {
            x: 20,
            y: 0
          }, leftArrow.width, leftArrow.height, false);
          navBtnContainerL.addChild(leftArrow);
          this.addChild(navBtnContainerL);     

          leftArrow.init();
         
          leftArrow.interactive = true;
          leftArrow.buttonMode = true;


          leftArrow.on("pointerover", (event : InteractionEvent) => { // Animate the size of the itemSprite using the tweener
            this.tweeener.to(leftArrow, {
              pixi: {
                scale: 1.1
              },
              duration: 0.25,
              ease: "back.out",
              overwrite: true
            });
          });
          leftArrow.on("pointerout", (event : InteractionEvent) => { // Animate the size of the itemSprite back to its original size using the tweener
            this.tweeener.to(leftArrow, {
              pixi: {
                scale: 1
              },
              duration: 0.4,
              ease: "back.out",
              overwrite: true
            });
          });
          leftArrow.on("pointerdown", () => {
            this.context.sfx.play("clickTiny");
          });

            // Buttonize the LEFT Nav Arrow
            buttonizeDisplayObject(leftArrow, {
              onTrigger: () => {
              this.context.sfx.play("woosh2");
               this.goToPreviousBuilding();
               const nextBuildingId = this.buildingId 
                this.displayRoom(nextBuildingId, this.regionId, 1);
              }
            });

          await leftArrow.playNavArrowAnimation(.25);

          // Create right arrow
          const rightArrow = new NavBetweenBuildingsArrow(buildingId, regionId,"right");
          const navBtnContainerR = createItemContainer({
            x: 1,
            y: .4
          }, {
            x: 110,
            y: 0
          }, rightArrow.width, rightArrow.height, false);
          navBtnContainerR.addChild(rightArrow);
          this.addChild(navBtnContainerR); 

          rightArrow.init();
       
          rightArrow.interactive = true; 
          rightArrow.buttonMode = true;


          rightArrow.on("pointerover", (event : InteractionEvent) => { // Animate the size of the itemSprite using the tweener
            this.tweeener.to(rightArrow, {
              pixi: {
                scale: 1.1
              },
              duration: 0.25,
              ease: "back.out",
              overwrite: true
            });
          });
          rightArrow.on("pointerout", (event : InteractionEvent) => { // Animate the size of the itemSprite back to its original size using the tweener
            this.tweeener.to(rightArrow, {
              pixi: {
                scale: 1
              },
              duration: 0.4,
              ease: "back.out",
              overwrite: true
            });
          });
          rightArrow.on("pointerdown", () => {
            this.context.sfx.play("clickTiny");
          });

            // Buttonize the LEFT Nav Arrow
            buttonizeDisplayObject(rightArrow, {
              onTrigger: () => {
              this.context.sfx.play("woosh2");
               this.goToNextBuilding();
               const nextBuildingId = this.buildingId;
                this.displayRoom(nextBuildingId, this.regionId, 1);
              }
            });
          await rightArrow.playNavArrowAnimation(.25);
      }
  }

  }

  goToPreviousRoom(): void { // go to the previous room in the array, or go to the last room if you're already at the beginning
    if (this.currentRoom !== 2 && this.previousRoomId !== null) {
      this.currentRoom = this.previousRoomId;
    } else {
      this.currentRoom = 1;
    }
  }

  goToNextBuilding(): void {
    // Find the next buildingId in the region, or go back to the first buildingId if you're already at the end
    const currentBuildingIndex = this.buildingsInRegion.findIndex(building => building.buildingId === this.buildingId);
    const nextBuildingIndex = (currentBuildingIndex + 1) % this.buildingsInRegion.length;
    this.buildingId = this.buildingsInRegion[nextBuildingIndex].buildingId;
}
  
  goToPreviousBuilding(): void {
  // Find the previous buildingId in the region, or go to the last buildingId if you're already at the beginning
  const currentBuildingIndex = this.buildingsInRegion.findIndex(building => building.buildingId === this.buildingId);
  let previousBuildingIndex = currentBuildingIndex - 1;
  if (previousBuildingIndex < 0) {
      previousBuildingIndex = this.buildingsInRegion.length - 1;
  }
  this.buildingId = this.buildingsInRegion[previousBuildingIndex].buildingId;
}
  async playButtonAnimation(duration : number) {
    const backButton = this.backButton;
    /*  const items = this.items; */
    await Promise.all([
      backButton ?. playButtonAnimation(.5),
    ]);
  }
};




export class Room extends Container {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private background?: RoomBackground;
  public clickableRegions : ClickableRegion[];
  public simpleFactory = GameSingletons.getSimpleObjectFactory();

  constructor(public id : number, public name : string,public hideHud:boolean = false, public image : string, public items : any[] = [], // array to store items in the room
    clickableRegions : ClickableRegion[] = [],public buildingId:number, public regionId:number) {
    super();
    this.id = id;
    this.name = name;
    this.image = image;
    this.items = items;
    this.hideHud = hideHud;
    this.clickableRegions = clickableRegions;
    this.tweeener = new TemporaryTweeener(this);
  
  }
  async init(isRoomLoaded : boolean) { 
    //Check if the HUD is hidden in this room by default, ex: the arcade machines.
    if (this.hideHud ===true) {
      const context = GameSingletons.getGameContext();
      const {input} = context;
      input.emit("immersedMode");
    }
    // Add background
    if (!this.background) {
      this.background = new RoomBackground(this.image);
      this.addChild(this.background);
    }

    // Paired Items - Items that layer eachother and need to have an effect
    // applied to them at the same time. Ex: The chandelier and chandelier light in the library reading room.
    // They need to be seperate layers because the light image has a flicker effect applied to it.
    const pairedItems: {name:string,
      itemSprite:DisplayObject}[] = [];

    // Add items
    this.items.sort((a, b) => a.zIndex - b.zIndex).forEach(item => { // Create a sprite for the item
      const itemSprite = this.factory.createSprite(item.image);
      const {screenAnchor, screenMargin} = item;
      /*  itemSprite.pivot.set(itemSprite.width / 2, itemSprite.height / 2); */
      itemSprite.scale.set(item.scale, item.scale,);

      // Add the itemSprite to the pairedItems array
      pairedItems.push({name: item.name, itemSprite});


      // EFFECTS
      if (item.effect && item.effect.includes("flickering")) {
        flickeringLight(this.context, {
          name: item.name,
          sprite: itemSprite,
          minAlpha: .3,
          maxAlpha: 1,
          frequency: item.fxMod ? item.fxMod : 15
        });
      }
      if (item.effect && item.effect.includes("brightnessOsc")) {
        brightnessOsc(this.context, {
          sprite: itemSprite,
          minBrightness: .1,
          maxBrightness: 2,
          frequency: 500
        });
      }

      // PAIRED ACTION - Wiggle
      if (item.itemAction && item.itemAction.includes("wiggle")) { // Call the addPairedPointerdownListeners function, passing in the pairedItems array and the wiggle function as the callback
        itemSprite.pivot.set(itemSprite.width / 2, 0);
        itemSprite.position.set(item.screenAnchor.x + (itemSprite.width / 2), item.screenAnchor.y)
        addPairedPointerdownListeners(pairedItems, itemSprites => {
          itemSprites.forEach(itemSprite => wiggle(itemSprite));
        });
      }

  // Modals
      // Library Modal
      if (item.itemAction && item.itemAction.includes("talesModal")) {
        const itemBtn = buttonizeInstance(itemSprite);

        itemBtn.behavior.on({
          trigger: (edata : InteractionData) => {
            const context = GameSingletons.getGameContext();
            const {input} = context;
            const popup = new TalesModal(item.id, (this.parent as bldgHouse));

            context.stageContainers._groundModals.addChild(popup);
            input.emit("immersedMode", edata);
          }
        });
      }
      // Choopy Wanted Modals
      if (item.itemAction && item.itemAction.includes("wantedModal")) {
        const itemBtn = buttonizeInstance(itemSprite);

        itemBtn.behavior.on({
          trigger: (edata : InteractionData) => {
            const context = GameSingletons.getGameContext();
            const {input} = context;
            const popup = new wantedModal(item.id, (this.parent as bldgHouse));

            context.stageContainers._groundModals.addChild(popup);
            input.emit("immersedMode", edata);
          }
        });
      }
      // Choopy MiniGame Modals
      if (item.itemAction && item.itemAction.includes("miniGameModal")) {
        const itemBtn = buttonizeInstance(itemSprite);

        itemBtn.behavior.on({
          trigger: (edata : InteractionData) => {
            const context = GameSingletons.getGameContext();
            
            const popup = new miniGameModal(item.id, (this.parent as bldgHouse));

            context.stageContainers._groundModals.addChild(popup);
            
          }
        });
      }

  // SINGLE ACTIONS
      // SFX
      itemSprite.interactive = true;
      itemSprite.buttonMode = true;
      itemSprite.on("pointerdown", () => {
        this.context.sfx.play(item.sound);
      });

      // Wiggle
      if (item.itemAction && item.itemAction.includes("wiggle")) {
        itemSprite.pivot.set(itemSprite.width / 2, 0);
        itemSprite.position.set(item.screenAnchor.x + (itemSprite.width / 2), item.screenAnchor.y)
        itemSprite.on("pointerdown", () => {
          wiggle(itemSprite, item.fxMod ? item.fxMod : 1, item.fxMod2 ? item.fxMod2 : .05);
        });
      }
      // Particle
      if (item.itemAction && item.itemAction.includes("particle")) {
        itemSprite.on("pointerdown", () => {
          const particles = sprayParticle();
          itemSprite.addChild(particles.emitterContainer);
        });
      }

  // HOVER
      // Add the hover effect for each string in the hover array
      // tooltip shows the name of the item
      const hoverArray = item.hover || [];
      for (const hover of hoverArray) {
        if (hover === "enlarge") {
          itemSprite.interactive = true;
          itemSprite.buttonMode = true;

          itemSprite.on("pointerover", (event : InteractionEvent) => { // Animate the size of the itemSprite using the tweener
            this.tweeener.to(itemSprite, {
              pixi: {
                scale: item.scale + .05
              },
              duration: 0.25,
              ease: "back.out",
              overwrite: true
            });
          });
          itemSprite.on("pointerout", (event : InteractionEvent) => { // Animate the size of the itemSprite back to its original size using the tweener
            this.tweeener.to(itemSprite, {
              pixi: {
                scale: item.scale
              },
              duration: 0.4,
              ease: "back.out",
              overwrite: true
            });
          });
        } else if (hover === "tooltip") {
          itemSprite.interactive = true;
          itemSprite.buttonMode = true;

          const itemTooltip = this.context.main.hud.tooltips.registerTarget(itemSprite, item.name.replace(/_/g, " ").toUpperCase());
          itemSprite.on("pointerover", (event : InteractionEvent) => { // Animate the size of the itemSprite using the tweener
            this.tweeener.to(itemTooltip, {
              pixi: {
                scale: item.scale + .05
              },
              duration: 0.25,
              ease: "back.out",
              overwrite: true
            });
          });
        }
      }

      // Fixed Items -- TO DO: add conditional for width to height ratios (portrait)
      if (item.fixed === true) {
        const itemX = (screenAnchor.x * this.context.viewSize.width) - ((itemSprite.width * item.scale) / 2);
        const itemY = (screenAnchor.y * this.context.viewSize.height) - ((itemSprite.height * item.scale) / 2);
        const scaledContainer = scaleArcade(itemX, itemY);

        scaledContainer.addChild(itemSprite);
        this.addChild(scaledContainer);
       } 
       else 
       { // Create a container for the item
        const itemContainer = createItemContainer(screenAnchor, screenMargin, itemSprite.width, itemSprite.height);
        // Add the sprite to the container
        itemContainer.addChild(itemSprite);
        // Update the position of the container
        itemContainer.updateIndicatorPositions(this.context.viewSize);
        // Add the container to the room
        this.addChild(itemContainer);
      }
    });

    // Add clickable region buttons
    this.clickableRegions.forEach(region => {
      const clickRegion = new Sprite();
      clickRegion.hitArea = new Rectangle(region.x, region.y, region.width, region.height);
      const regionX = parseFloat((region.x / 1728).toFixed(2)); // The pixel hardcode X number when originally designed on 1728 width resolution.
      const regionY = parseFloat((region.y / 1080).toFixed(2));
      // The pixel hardcode Y number when originally designed on 968 height resolution.

      // Scale the clickable regions
      const container = createItemContainer({
        x: regionX,
        y: regionY
      }, {
        x: 0,
        y: 0
      }, region.width, region.height, false);
      container.addChild(clickRegion);

      ////////////////////////////////////////////////////////////////////////////////////////////////
      // Show Box - Use for Testing
      /*  const bg = new Graphics();
      bg.clear();
      bg.beginFill(0x000000,.5);
      bg.drawRect(region.x, region.y, region.width, region.height);
      bg.endFill();
      container.addChild(bg);  */
      //////////////////////////////////////////////////////////////////////////////////////////////////
      this.addChild(container);

      clickRegion.on("mouseover", () => {
        clickRegion.cursor = "crosshair";
      });
      clickRegion.on("mouseout", () => {
        clickRegion.cursor = "default";
      });
      clickRegion.on("pointerdown", event => {
        this.context.sfx.play("walking");
        this.context.sfx.play("crystal");
      });
      clickRegion.on("pointerdown", () => {
        this.context.sfx.play("walking");
        this.context.sfx.play("crystal");
      });

      // Buttonize the region
      buttonizeDisplayObject(clickRegion, {
        onTrigger: () => { // Display the room associated with the clickable region
          (this.parent as bldgHouse).displayRoom(this.buildingId,this.regionId,region.nextRoomId);
        }
      });
    });
  }

  async playShowAnimation(duration : number) {
    const background = this.background;
    /*  const items = this.items; */
    await Promise.all([
      background ?. playShowAnimation(1),
      /*   ... items.map(item => item.playShowAnimation()), */
    ]);
  }

  async playHideAnimation() {
    const background = this.background;
    await Promise.all([
      background ?. playHideAnimation(),
    ]);
  }
};
