import { env } from "@game/app/global";
import { createRoundRobinFetch } from "./createRoundRobinFetch";

export const AtomicAssetsEndpointPlaceholder = "{{atomicassets_endpoint}}";

const ENDPOINTS_MAINNET = [
  "wax.api.atomicassets.io",
  "wax-atomic-api.eosphere.io",
  "aa-wax-public1.neftyblocks.com",
  "atomic.wax.eosrio.io",
  "aa-api-wax.eosauthority.com",
];
const ENDPOINTS_TESTNET = [
  // "intentionally-wrong-endpoint.com",
  // "intentionally-wrong-endpoint-2.com",
  "test.wax.api.atomicassets.io",
];

export const customRoundRobinFetch = createRoundRobinFetch({
  needle: AtomicAssetsEndpointPlaceholder,
  replacements: env.BLOCKCHAIN === "mainnet" ? ENDPOINTS_MAINNET : ENDPOINTS_TESTNET,
});
