import { GameSingletons } from "@game/app/GameSingletons";
import { env } from "@game/app/global";
import { HttpApiService } from "@sdk/http/HttpApiService";
import { NFTDataModel } from "./eth-nft-data-models";
import { connnectAndRetrieveEthereumAddress } from "./retrieveEthereumAddress";

export class EthereumNFTDataService {
  async updateMyEthereumAddress() {
    const myEthereumAddress = await connnectAndRetrieveEthereumAddress();

    const { firebase } = GameSingletons.getGameContext();
    await firebase.updateUserSocialData({ ethereum: { address: myEthereumAddress } });

    return myEthereumAddress;
  }

  async getSavedEthereumAddress(user: string) {
    const { firebase } = GameSingletons.getGameContext();
    const socialData =
      (await firebase.getUserSocialData(user)) || (await firebase.getUserSocialData(/testnet\:\:(.+)/gi.exec(user)?.[1]));

    return socialData?.ethereum?.address || null;
  }

  async getEthereumNFTs(user: string) {
    const ethereumAddress = await this.getSavedEthereumAddress(user);

    if (!ethereumAddress) {
      return null;
    }

    const contractAddress = "0xeef2297e15cd085a0ad33197ce8c2eab6f023c2b";

    const httpService = new HttpApiService(`https://eth-mainnet.g.alchemy.com`);
    const result = await httpService.get<{
      ownedNfts: NFTDataModel[];
      totalCount: number;
      blockHash: string;
    }>(`nft/v2/${env.ALCHEMY_API_KEY}/getNFTs`, {
      [`withMetadata`]: true,
      [`contractAddresses[]`]: contractAddress,
      [`owner`]: ethereumAddress,
      [`refreshCache`]: true,
    });

    return result && result.ownedNfts;
  }
}
