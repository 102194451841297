import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { fitObjectInRectangle } from "@sdk-pixi/layout/fitObjectInRectangle";
import { formatToMaxDecimals } from "@sdk-ui/utils/formatToMaxDecimals";
import { ModInfo } from "./ModPlate";

export class ModPlateContent extends Container {
  private readonly simpleFactory = GameSingletons.getSimpleObjectFactory();

  constructor() {
    super();

    //// Add background
    const bg = this.simpleFactory.createSprite("ui-market-window-emporium/mods/info-panel.png");
    bg.width = 315;
    bg.height = 500;
    this.addChild(bg);
  }
  clearLastChild() {
    const childRemoved = this.removeChildAt(this.children.length - 1);
    childRemoved.destroy({ children: true });
  }
  setInfo(info: ModInfo) {
    const infoContainer = new Container();
    //// Name
    const name = this.simpleFactory.createText(info.name.toUpperCase());
    infoContainer.addChild(name);
    fitObjectInRectangle(name, new Rectangle(40, 0, 240, 100));
    //// Description

    // const text = info.description;
    const text =
      `EFFECT: ${info.effect}\n` +
      `MIN MINT: ${info.minMint}\n\n` +
      `DESCRIPTION:\n${info.description}\n` +
      // `BURNT: 🔥${info.burnt}\n` +
      ``;

    const description = this.simpleFactory.createText(text, {
      align: "left",
      fontSize: 18,
      lineHeight: 29,
      wordWrap: true,
      wordWrapWidth: 250,
    });
    infoContainer.addChild(description);
    fitObjectInRectangle(description, new Rectangle(40, 75, 240, 300));

    this.addChild(infoContainer);
  }
  showLastChild(render: boolean) {
    const child = this.getChildAt(this.children.length - 1);
    child.renderable = render;
  }

  async setNftImage(textureUrl: string) {
    const texture = await Texture.fromURL(textureUrl);
    const sprite = new Sprite(texture);
    sprite.width = 315;
    sprite.height = 500;
    this.addChild(sprite);
  }
}
