import { HttpApiService } from "@sdk/http/HttpApiService";

const AIRTABLE_BEARER_TOKEN = "keyxdhyQgb707ovJy";
const AIRTABLE_BASE_ID = "appUU7HZ663bxMgam"; // appUU7HZ663bxMgam appZyTboGgOBptUZx

type AirtableTableResults<T extends Record<string, any> = any> = {
  records: T[];
  offset: string;
};

export class AirtableApiService {
  private readonly apiService = new HttpApiService("https://api.airtable.com");

  constructor() {
    this.apiService.setBearerToken(AIRTABLE_BEARER_TOKEN);
  }

  public async getTableRecords<K extends AirtableTableName>(tableName: K, view?: string) {
    const endpoint = `v0/${AIRTABLE_BASE_ID}/${encodeURI(tableName)}`;

    type Results = AirtableTableResults<{
      id: string;
      createdTime: string;
      fields: AirtableTableRecordTypes[K];
    }>;
    const data = await this.apiService.get<Results>(endpoint, { maxRecords: 99, view });
    if (!data) return null;

    return data.records;
  }

  // private async getTableRecords<T>(tableName: string, view?: string) {
  //   const endpoint = `v0/${AIRTABLE_BASE_ID}/${encodeURI(tableName)}`;
  //   const data = await this.get<AirtableTableResults>(endpoint, { maxRecords: 99, view });
  //   if (!data) return null;
  //   return data.records;
  // }
}

export interface AirtableTableRecordTypes {
  ["Communications"]: AirtableTableRecordTypes.Communications;
}

export module AirtableTableRecordTypes {
  export interface Communications {
    "Title": string;
    "Image": {
      id: string;
      width: number;
      height: number;
      url: string;
      filename: string;
      size: number;
      type: string;
      thumbnails: {
        small: { url: string; width: number; height: number };
        large: { url: string; width: number; height: number };
        full: { url: string; width: number; height: number };
      };
    }[];
    "Text": string;
    "Action": string;
    "Action Text": string;
    "Article Text": string | undefined;
    "Tags": string[];
    "Effects": ("Confetti" | "Other")[];
    "Status": "Mainnet" | "Testnet";
  }
}

export type AirtableTableName = keyof AirtableTableRecordTypes;
