import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { ThemeColors } from "@game/constants/ThemeColors";
import { BLEND_MODES } from "@pixi/constants";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";

export class TrainStatusHealthBar extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private healthPercentage: Text;
  private healthBar: Sprite;
  private healthIcon: Sprite;
  private healthBarBase: Sprite;
  private healthBarDashedLine: Sprite;
  constructor() {
    super();
    this.healthBarBase = this.factory.createSprite("ui-repair/left-panel/base-power-bar.png", { x: 0, y: 0 });
    this.healthBarBase.scale.set(0.33);
    this.addChild(this.healthBarBase);

    this.healthBar = new Sprite(Texture.WHITE);
    this.healthBar.anchor.y = 1;
    this.healthBar.y = this.healthBarBase.height;
    this.healthBar.blendMode = BLEND_MODES.MULTIPLY;
    this.healthBar.width = this.healthBarBase.width;
    this.healthBar.height = this.healthBarBase.height;
    this.addChild(this.healthBar);

    this.healthIcon = this.factory.createSprite("ui-repair/left-panel/loco-heart.png", { y: 90, x: 32 });
    this.healthIcon.scale.set(0.25);
    this.addChild(this.healthIcon);

    this.healthBarDashedLine = this.factory.createSprite("ui-repair/left-panel/line-2.png", { x: 2 });
    this.healthBarDashedLine.scale.set(0.25);
    this.addChild(this.healthBarDashedLine);

    this.healthPercentage = this.factory.createText("0%", { fontSize: 26 }, { y: 180, x: 54 });
    this.healthPercentage.anchor.set(0.5);
    this.addChild(this.healthPercentage);
  }

  setPercentage(healthPercentage: number) {
    this.healthPercentage.text = Math.round(healthPercentage) + "%";
    this.healthBar.height = this.calculatePercentage(100, this.healthBarBase.height, healthPercentage);
    this.healthBarDashedLine.y = this.healthBar.y - this.healthBar.height;
    this.healthBarDashedLine.visible = healthPercentage < 98;
    if (healthPercentage >= 90) {
      this.healthBar.tint = ThemeColors.HEALTH_COLOR_90.toInt();
      this.healthIcon.tint = ThemeColors.HEALTH_COLOR_90.toInt();
      this.healthPercentage.tint = ThemeColors.HEALTH_COLOR_90.toInt();
    } else if (healthPercentage >= 50) {
      this.healthBar.tint = ThemeColors.HEALTH_COLOR_50.toInt();
      this.healthIcon.tint = ThemeColors.HEALTH_COLOR_50.toInt();
      this.healthPercentage.tint = ThemeColors.HEALTH_COLOR_50.toInt();
    } else if (healthPercentage >= 0) {
      this.healthBar.tint = ThemeColors.HEALTH_COLOR_0.toInt();
      this.healthIcon.tint = ThemeColors.HEALTH_COLOR_0.toInt();
      this.healthPercentage.tint = ThemeColors.HEALTH_COLOR_0.toInt();
    }
  }

  calculatePercentage(value1: number, value2: number, value3: number) {
    const result = (value2 * value3) / value1 || 0;
    return result;
  }
}
