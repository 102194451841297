import { getRandomItemFrom } from "@sdk/helpers/arrays";

export function getRandomIPFSHash() {
  return getRandomItemFrom([
    "QmaThT9KBG9iLB9JKBvkWiacTpRaPVo8MSZ147nU218GPi/Gear/BB_Gear_Cards_03_final_RARE.png",
    "QmUnuZc7joySGkU63r6WGJ1fA2sMLaUnxHxR9XV8iWijrh",
    "Qmd2CyHjK5A7PYcSf28TTFKvSKg5B8miA61cRCiNHiGYxe",
    "Qmd8ATxqqkV6Pp2idFQmttQuGbFRUPzJ3cvrHrUwXi52sH",
    "QmWb5XM8YTBvTGRxRqTKeyLkZJVNQkZZWQD1BhhZxD2XDT",
    "QmVw3fDskNRcxQweB9vRoo8W8PchiTjtzaATFuGVfY3WWS/Kog/no_foil/common/1-12-0-35-0_front.png",
    "QmXwPL1Yeu2PgskvhfHAvwqPK7VR88RjgXCCCKbLWn7Qh6",
    "Qmeq4haAYeeLaLBKwqMhcBt5n444sc3swC49jrTZGyvXRb",
    "QmPr9Wdkndoj8218Hs8poE8nKFYwHoQ3R8kmCbNpWA1YHF",
    "QmPHRB226fmiLwbU54QjfotHfXd8VDvR4EfeNoRoDdggAP",
    "QmYdHGwSBE5jhM8xw7DX1J31FuqVuhviG97dbUXB1i3K15",
    "QmYDFkwGY7Q2TvZX4pSCU8kkwbypRUw1Q2Gi6MucbmTqFM",
    "QmVNurRrrJ9VGTx2nvDDJARtDGmi3qX8EEECj96eryhfjk",
    "QmXLvnHaFybrQyBn11mYjj5ba4QFQohrmWAAAFXFNGMbC9",
    "Qma3cVzab1L68WavdFhuAFBBew2zVfFdcsEA2fgZkG2Jhi",
    "QmRFF4SLBgei8br5WKgGhV1nTQjHZCD7hPnZnKTSUQnYQH",
  ]);
}
