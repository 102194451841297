export const ContractStationsData_RR = [
  {
    station_id: "1099521667313",
    connected_stations: [
      {
        station_id: "1099521667605",
        distance: 18,
      },
      {
        station_id: "1099521667350",
        distance: 28,
      },
      {
        station_id: "1099521667607",
        distance: 22,
      },
      {
        station_id: "1099521667606",
        distance: 19,
      },
      {
        station_id: "1099521667335",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 87,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 75,
      },
      {
        type: "gas",
        multiplier: 100,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 83,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 106,
      },
      {
        type: "pallet",
        multiplier: 81,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "mythic",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667314",
    connected_stations: [
      {
        station_id: "1099521667332",
        distance: 23,
      },
      {
        station_id: "1099521667333",
        distance: 27,
      },
      {
        station_id: "1099521667328",
        distance: 17,
      },
      {
        station_id: "1099521667344",
        distance: 37,
      },
      {
        station_id: "1099521667584",
        distance: 48,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667315",
    connected_stations: [
      {
        station_id: "1099521667352",
        distance: 19,
      },
      {
        station_id: "1099521667336",
        distance: 24,
      },
      {
        station_id: "1099521667318",
        distance: 25,
      },
      {
        station_id: "1099521667327",
        distance: 18,
      },
      {
        station_id: "1099521667338",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667316",
    connected_stations: [
      {
        station_id: "1099521667335",
        distance: 19,
      },
      {
        station_id: "1099521667606",
        distance: 36,
      },
      {
        station_id: "1099521667352",
        distance: 36,
      },
      {
        station_id: "1099521667350",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 84,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 65,
      },
      {
        type: "gas",
        multiplier: 93,
      },
      {
        type: "grain",
        multiplier: 79,
      },
      {
        type: "granule",
        multiplier: 79,
      },
      {
        type: "liquid",
        multiplier: 94,
      },
      {
        type: "ore",
        multiplier: 88,
      },
      {
        type: "oversized",
        multiplier: 95,
      },
      {
        type: "pallet",
        multiplier: 94,
      },
      {
        type: "perishable",
        multiplier: 90,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667317",
    connected_stations: [
      {
        station_id: "1099521667606",
        distance: 25,
      },
      {
        station_id: "1099521667607",
        distance: 19,
      },
      {
        station_id: "1099521667343",
        distance: 16,
      },
      {
        station_id: "1099521667341",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 60,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 78,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 82,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 85,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 79,
      },
      {
        type: "perishable",
        multiplier: 45,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667318",
    connected_stations: [
      {
        station_id: "1099521667315",
        distance: 25,
      },
      {
        station_id: "1099521667352",
        distance: 20,
      },
      {
        station_id: "1099521667344",
        distance: 15,
      },
      {
        station_id: "1099521667332",
        distance: 19,
      },
      {
        station_id: "1099521667345",
        distance: 30,
      },
      {
        station_id: "1099521667347",
        distance: 32,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 81,
      },
      {
        type: "building_materials",
        multiplier: 81,
      },
      {
        type: "crate",
        multiplier: 83,
      },
      {
        type: "gas",
        multiplier: 104,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 89,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "rare",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667319",
    connected_stations: [
      {
        station_id: "1099521667345",
        distance: 19,
      },
      {
        station_id: "1099521667330",
        distance: 27,
      },
      {
        station_id: "1099521667342",
        distance: 19,
      },
      {
        station_id: "1099521667348",
        distance: 23,
      },
      {
        station_id: "1099521667335",
        distance: 22,
      },
      {
        station_id: "1099521667347",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667327",
    connected_stations: [
      {
        station_id: "1099521667315",
        distance: 18,
      },
      {
        station_id: "1099521667609",
        distance: 13,
      },
      {
        station_id: "1099521667338",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667328",
    connected_stations: [
      {
        station_id: "1099521667314",
        distance: 17,
      },
      {
        station_id: "1099521667526",
        distance: 37,
      },
      {
        station_id: "1099521667333",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 50,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 38,
      },
      {
        type: "gas",
        multiplier: 84,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 70,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 67,
      },
      {
        type: "perishable",
        multiplier: 86,
      },
      {
        type: "top_secret",
        multiplier: 58,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667329",
    connected_stations: [
      {
        station_id: "1099521667608",
        distance: 19,
      },
      {
        station_id: "1099521667341",
        distance: 13,
      },
      {
        station_id: "1099521667343",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 101,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667330",
    connected_stations: [
      {
        station_id: "1099521667319",
        distance: 27,
      },
      {
        station_id: "1099521667611",
        distance: 17,
      },
      {
        station_id: "1099521667345",
        distance: 42,
      },
      {
        station_id: "1099521667342",
        distance: 20,
      },
      {
        station_id: "1099521667335",
        distance: 24,
      },
      {
        station_id: "1099521667331",
        distance: 20,
      },
      {
        station_id: "1099521667340",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667331",
    connected_stations: [
      {
        station_id: "1099521667610",
        distance: 32,
      },
      {
        station_id: "1099521667606",
        distance: 16,
      },
      {
        station_id: "1099521667350",
        distance: 43,
      },
      {
        station_id: "1099521667330",
        distance: 20,
      },
      {
        station_id: "1099521667340",
        distance: 27,
      },
      {
        station_id: "1099521667335",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667332",
    connected_stations: [
      {
        station_id: "1099521667314",
        distance: 23,
      },
      {
        station_id: "1099521667318",
        distance: 19,
      },
      {
        station_id: "1099521667338",
        distance: 19,
      },
      {
        station_id: "1099521667333",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 100,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 70,
      },
      {
        type: "grain",
        multiplier: 99,
      },
      {
        type: "granule",
        multiplier: 101,
      },
      {
        type: "liquid",
        multiplier: 84,
      },
      {
        type: "ore",
        multiplier: 71,
      },
      {
        type: "oversized",
        multiplier: 84,
      },
      {
        type: "pallet",
        multiplier: 76,
      },
      {
        type: "perishable",
        multiplier: 78,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667333",
    connected_stations: [
      {
        station_id: "1099521667314",
        distance: 27,
      },
      {
        station_id: "1099521667328",
        distance: 18,
      },
      {
        station_id: "1099521667351",
        distance: 23,
      },
      {
        station_id: "1099521667344",
        distance: 17,
      },
      {
        station_id: "1099521667332",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667334",
    connected_stations: [
      {
        station_id: "1099521667605",
        distance: 21,
      },
      {
        station_id: "1099521667607",
        distance: 27,
      },
      {
        station_id: "1099521667691",
        distance: 32,
      },
      {
        station_id: "1099521667337",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667335",
    connected_stations: [
      {
        station_id: "1099521667313",
        distance: 40,
      },
      {
        station_id: "1099521667316",
        distance: 19,
      },
      {
        station_id: "1099521667319",
        distance: 22,
      },
      {
        station_id: "1099521667352",
        distance: 39,
      },
      {
        station_id: "1099521667345",
        distance: 24,
      },
      {
        station_id: "1099521667330",
        distance: 24,
      },
      {
        station_id: "1099521667331",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 83,
      },
      {
        type: "automobile",
        multiplier: 98,
      },
      {
        type: "building_materials",
        multiplier: 73,
      },
      {
        type: "crate",
        multiplier: 63,
      },
      {
        type: "gas",
        multiplier: 93,
      },
      {
        type: "grain",
        multiplier: 96,
      },
      {
        type: "granule",
        multiplier: 99,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 86,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 57,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 65,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667336",
    connected_stations: [
      {
        station_id: "1099521667315",
        distance: 24,
      },
      {
        station_id: "1099521667609",
        distance: 19,
      },
      {
        station_id: "1099521667352",
        distance: 28,
      },
      {
        station_id: "1099521667350",
        distance: 32,
      },
      {
        station_id: "1099521667349",
        distance: 24,
      },
      {
        station_id: "1099521667339",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 48,
      },
      {
        type: "building_materials",
        multiplier: 74,
      },
      {
        type: "crate",
        multiplier: 37,
      },
      {
        type: "gas",
        multiplier: 81,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 71,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 74,
      },
      {
        type: "pallet",
        multiplier: 58,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 58,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667337",
    connected_stations: [
      {
        station_id: "1099521667607",
        distance: 19,
      },
      {
        station_id: "1099521667343",
        distance: 28,
      },
      {
        station_id: "1099521667334",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 69,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 95,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667338",
    connected_stations: [
      {
        station_id: "1099521667315",
        distance: 17,
      },
      {
        station_id: "1099521667327",
        distance: 20,
      },
      {
        station_id: "1099521667332",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 97,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667339",
    connected_stations: [
      {
        station_id: "1099521667609",
        distance: 20,
      },
      {
        station_id: "1099521667349",
        distance: 17,
      },
      {
        station_id: "1099521667336",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 106,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667340",
    connected_stations: [
      {
        station_id: "1099521667610",
        distance: 18,
      },
      {
        station_id: "1099521667606",
        distance: 20,
      },
      {
        station_id: "1099521667608",
        distance: 19,
      },
      {
        station_id: "1099521667607",
        distance: 48,
      },
      {
        station_id: "1099521667330",
        distance: 37,
      },
      {
        station_id: "1099521667341",
        distance: 29,
      },
      {
        station_id: "1099521667331",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 72,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 59,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667341",
    connected_stations: [
      {
        station_id: "1099521667317",
        distance: 15,
      },
      {
        station_id: "1099521667608",
        distance: 18,
      },
      {
        station_id: "1099521667329",
        distance: 13,
      },
      {
        station_id: "1099521667343",
        distance: 20,
      },
      {
        station_id: "1099521667340",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667342",
    connected_stations: [
      {
        station_id: "1099521667319",
        distance: 19,
      },
      {
        station_id: "1099521667348",
        distance: 17,
      },
      {
        station_id: "1099521667346",
        distance: 19,
      },
      {
        station_id: "1099521667330",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 96,
      },
      {
        type: "gas",
        multiplier: 90,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 82,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667343",
    connected_stations: [
      {
        station_id: "1099521667367",
        distance: 46,
      },
      {
        station_id: "1099521667317",
        distance: 16,
      },
      {
        station_id: "1099521667607",
        distance: 22,
      },
      {
        station_id: "1099521667329",
        distance: 27,
      },
      {
        station_id: "1099521667341",
        distance: 20,
      },
      {
        station_id: "1099521667337",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667344",
    connected_stations: [
      {
        station_id: "1099521667314",
        distance: 37,
      },
      {
        station_id: "1099521667318",
        distance: 15,
      },
      {
        station_id: "1099521667351",
        distance: 15,
      },
      {
        station_id: "1099521667345",
        distance: 30,
      },
      {
        station_id: "1099521667333",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667345",
    connected_stations: [
      {
        station_id: "1099521667318",
        distance: 30,
      },
      {
        station_id: "1099521667319",
        distance: 19,
      },
      {
        station_id: "1099521667352",
        distance: 23,
      },
      {
        station_id: "1099521667351",
        distance: 31,
      },
      {
        station_id: "1099521667350",
        distance: 49,
      },
      {
        station_id: "1099521667335",
        distance: 24,
      },
      {
        station_id: "1099521667344",
        distance: 30,
      },
      {
        station_id: "1099521667330",
        distance: 42,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 101,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 87,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667346",
    connected_stations: [
      {
        station_id: "1099521667611",
        distance: 17,
      },
      {
        station_id: "1099521667421",
        distance: 14,
      },
      {
        station_id: "1099521667342",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667347",
    connected_stations: [
      {
        station_id: "1099521667318",
        distance: 32,
      },
      {
        station_id: "1099521667319",
        distance: 16,
      },
      {
        station_id: "1099521667351",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667348",
    connected_stations: [
      {
        station_id: "1099521667319",
        distance: 23,
      },
      {
        station_id: "1099521667661",
        distance: 23,
      },
      {
        station_id: "1099521667342",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 93,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 101,
      },
      {
        type: "crate",
        multiplier: 65,
      },
      {
        type: "gas",
        multiplier: 63,
      },
      {
        type: "grain",
        multiplier: 90,
      },
      {
        type: "granule",
        multiplier: 89,
      },
      {
        type: "liquid",
        multiplier: 81,
      },
      {
        type: "ore",
        multiplier: 62,
      },
      {
        type: "oversized",
        multiplier: 71,
      },
      {
        type: "pallet",
        multiplier: 69,
      },
      {
        type: "perishable",
        multiplier: 89,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667349",
    connected_stations: [
      {
        station_id: "1099521667605",
        distance: 34,
      },
      {
        station_id: "1099521667350",
        distance: 20,
      },
      {
        station_id: "1099521667336",
        distance: 24,
      },
      {
        station_id: "1099521667339",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 101,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667350",
    connected_stations: [
      {
        station_id: "1099521667313",
        distance: 28,
      },
      {
        station_id: "1099521667316",
        distance: 19,
      },
      {
        station_id: "1099521667605",
        distance: 25,
      },
      {
        station_id: "1099521667352",
        distance: 43,
      },
      {
        station_id: "1099521667349",
        distance: 20,
      },
      {
        station_id: "1099521667345",
        distance: 49,
      },
      {
        station_id: "1099521667336",
        distance: 32,
      },
      {
        station_id: "1099521667331",
        distance: 43,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667351",
    connected_stations: [
      {
        station_id: "1099521667333",
        distance: 23,
      },
      {
        station_id: "1099521667344",
        distance: 15,
      },
      {
        station_id: "1099521667345",
        distance: 31,
      },
      {
        station_id: "1099521667347",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 78,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 71,
      },
      {
        type: "crate",
        multiplier: 60,
      },
      {
        type: "gas",
        multiplier: 80,
      },
      {
        type: "grain",
        multiplier: 72,
      },
      {
        type: "granule",
        multiplier: 93,
      },
      {
        type: "liquid",
        multiplier: 81,
      },
      {
        type: "ore",
        multiplier: 85,
      },
      {
        type: "oversized",
        multiplier: 88,
      },
      {
        type: "pallet",
        multiplier: 74,
      },
      {
        type: "perishable",
        multiplier: 90,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667352",
    connected_stations: [
      {
        station_id: "1099521667315",
        distance: 19,
      },
      {
        station_id: "1099521667316",
        distance: 36,
      },
      {
        station_id: "1099521667318",
        distance: 20,
      },
      {
        station_id: "1099521667336",
        distance: 28,
      },
      {
        station_id: "1099521667345",
        distance: 23,
      },
      {
        station_id: "1099521667350",
        distance: 43,
      },
      {
        station_id: "1099521667335",
        distance: 39,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 75,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 97,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 76,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 86,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667354",
    connected_stations: [
      {
        station_id: "1099521667413",
        distance: 22,
      },
      {
        station_id: "1099521667617",
        distance: 17,
      },
      {
        station_id: "1099521667428",
        distance: 30,
      },
      {
        station_id: "1099521667615",
        distance: 44,
      },
      {
        station_id: "1099521667366",
        distance: 44,
      },
      {
        station_id: "1099521667626",
        distance: 35,
      },
      {
        station_id: "1099521667635",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 96,
      },
      {
        type: "gas",
        multiplier: 93,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 85,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "mythic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667355",
    connected_stations: [
      {
        station_id: "1099521667416",
        distance: 48,
      },
      {
        station_id: "1099521667411",
        distance: 20,
      },
      {
        station_id: "1099521667364",
        distance: 32,
      },
      {
        station_id: "1099521667362",
        distance: 77,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 87,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667356",
    connected_stations: [
      {
        station_id: "1099521667421",
        distance: 29,
      },
      {
        station_id: "1099521667441",
        distance: 42,
      },
      {
        station_id: "1099521667642",
        distance: 32,
      },
      {
        station_id: "1099521667430",
        distance: 28,
      },
      {
        station_id: "1099521667407",
        distance: 16,
      },
      {
        station_id: "1099521667446",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667357",
    connected_stations: [
      {
        station_id: "1099521667448",
        distance: 19,
      },
      {
        station_id: "1099521667403",
        distance: 46,
      },
      {
        station_id: "1099521667431",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 100,
      },
      {
        type: "ore",
        multiplier: 92,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667358",
    connected_stations: [
      {
        station_id: "1099521667425",
        distance: 30,
      },
      {
        station_id: "1099521667615",
        distance: 19,
      },
      {
        station_id: "1099521667419",
        distance: 31,
      },
      {
        station_id: "1099521667416",
        distance: 80,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667359",
    connected_stations: [
      {
        station_id: "1099521667627",
        distance: 23,
      },
      {
        station_id: "1099521667425",
        distance: 19,
      },
      {
        station_id: "1099521667631",
        distance: 39,
      },
      {
        station_id: "1099521667626",
        distance: 76,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 103,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667360",
    connected_stations: [
      {
        station_id: "1099521667424",
        distance: 18,
      },
      {
        station_id: "1099521667616",
        distance: 28,
      },
      {
        station_id: "1099521667448",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667361",
    connected_stations: [
      {
        station_id: "1099521667625",
        distance: 16,
      },
      {
        station_id: "1099521667636",
        distance: 19,
      },
      {
        station_id: "1099521667422",
        distance: 26,
      },
      {
        station_id: "1099521667416",
        distance: 30,
      },
      {
        station_id: "1099521667364",
        distance: 36,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667362",
    connected_stations: [
      {
        station_id: "1099521667355",
        distance: 77,
      },
      {
        station_id: "1099521667629",
        distance: 31,
      },
      {
        station_id: "1099521667415",
        distance: 15,
      },
      {
        station_id: "1099521667632",
        distance: 41,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667363",
    connected_stations: [
      {
        station_id: "1099521667624",
        distance: 20,
      },
      {
        station_id: "1099521667367",
        distance: 18,
      },
      {
        station_id: "1099521667639",
        distance: 17,
      },
      {
        station_id: "1099521667434",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 95,
      },
      {
        type: "automobile",
        multiplier: 92,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 65,
      },
      {
        type: "ore",
        multiplier: 88,
      },
      {
        type: "oversized",
        multiplier: 91,
      },
      {
        type: "pallet",
        multiplier: 91,
      },
      {
        type: "perishable",
        multiplier: 94,
      },
      {
        type: "top_secret",
        multiplier: 61,
      },
    ],
    rarity: "epic",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667364",
    connected_stations: [
      {
        station_id: "1099521667355",
        distance: 32,
      },
      {
        station_id: "1099521667361",
        distance: 36,
      },
      {
        station_id: "1099521667411",
        distance: 27,
      },
      {
        station_id: "1099521667416",
        distance: 22,
      },
      {
        station_id: "1099521667369",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667365",
    connected_stations: [
      {
        station_id: "1099521667642",
        distance: 16,
      },
      {
        station_id: "1099521667430",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 65,
      },
      {
        type: "gas",
        multiplier: 90,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 99,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 81,
      },
      {
        type: "perishable",
        multiplier: 88,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667366",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 44,
      },
      {
        station_id: "1099521667612",
        distance: 33,
      },
      {
        station_id: "1099521667613",
        distance: 34,
      },
      {
        station_id: "1099521667626",
        distance: 18,
      },
      {
        station_id: "1099521667625",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667367",
    connected_stations: [
      {
        station_id: "1099521667363",
        distance: 18,
      },
      {
        station_id: "1099521667440",
        distance: 20,
      },
      {
        station_id: "1099521667343",
        distance: 46,
      },
      {
        station_id: "1099521667434",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667368",
    connected_stations: [
      {
        station_id: "1099521667427",
        distance: 18,
      },
      {
        station_id: "1099521667443",
        distance: 20,
      },
      {
        station_id: "1099521667624",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 99,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667369",
    connected_stations: [
      {
        station_id: "1099521667364",
        distance: 23,
      },
      {
        station_id: "1099521667411",
        distance: 37,
      },
      {
        station_id: "1099521667636",
        distance: 20,
      },
      {
        station_id: "1099521667420",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 98,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 82,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 83,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667370",
    connected_stations: [
      {
        station_id: "1099521667445",
        distance: 19,
      },
      {
        station_id: "1099521667447",
        distance: 25,
      },
      {
        station_id: "1099521667405",
        distance: 22,
      },
      {
        station_id: "1099521667441",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 82,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 96,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667371",
    connected_stations: [
      {
        station_id: "1099521667424",
        distance: 20,
      },
      {
        station_id: "1099521667616",
        distance: 19,
      },
      {
        station_id: "1099521667433",
        distance: 23,
      },
      {
        station_id: "1099521667434",
        distance: 77,
      },
      {
        station_id: "1099521667789",
        distance: 78,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667403",
    connected_stations: [
      {
        station_id: "1099521667357",
        distance: 46,
      },
      {
        station_id: "1099521667640",
        distance: 17,
      },
      {
        station_id: "1099521667628",
        distance: 31,
      },
      {
        station_id: "1099521667622",
        distance: 20,
      },
      {
        station_id: "1099521667434",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667404",
    connected_stations: [
      {
        station_id: "1099521667637",
        distance: 16,
      },
      {
        station_id: "1099521667410",
        distance: 21,
      },
      {
        station_id: "1099521667426",
        distance: 13,
      },
      {
        station_id: "1099521667435",
        distance: 10,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 82,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 81,
      },
      {
        type: "gas",
        multiplier: 76,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 91,
      },
      {
        type: "ore",
        multiplier: 78,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 79,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667405",
    connected_stations: [
      {
        station_id: "1099521667370",
        distance: 22,
      },
      {
        station_id: "1099521667612",
        distance: 19,
      },
      {
        station_id: "1099521667428",
        distance: 33,
      },
      {
        station_id: "1099521667429",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667406",
    connected_stations: [
      {
        station_id: "1099521667641",
        distance: 19,
      },
      {
        station_id: "1099521667651",
        distance: 20,
      },
      {
        station_id: "1099521667630",
        distance: 14,
      },
      {
        station_id: "1099521667437",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667407",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 16,
      },
      {
        station_id: "1099521667446",
        distance: 25,
      },
      {
        station_id: "1099521667421",
        distance: 32,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 95,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 103,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667408",
    connected_stations: [
      {
        station_id: "1099521667638",
        distance: 24,
      },
      {
        station_id: "1099521667630",
        distance: 21,
      },
      {
        station_id: "1099521667629",
        distance: 18,
      },
      {
        station_id: "1099521667415",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667409",
    connected_stations: [
      {
        station_id: "1099521667638",
        distance: 59,
      },
      {
        station_id: "1099521667632",
        distance: 19,
      },
      {
        station_id: "1099521667620",
        distance: 27,
      },
      {
        station_id: "1099521667411",
        distance: 34,
      },
      {
        station_id: "1099521667418",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 91,
      },
      {
        type: "building_materials",
        multiplier: 92,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 104,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667410",
    connected_stations: [
      {
        station_id: "1099521667610",
        distance: 39,
      },
      {
        station_id: "1099521667404",
        distance: 21,
      },
      {
        station_id: "1099521667426",
        distance: 14,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667411",
    connected_stations: [
      {
        station_id: "1099521667355",
        distance: 20,
      },
      {
        station_id: "1099521667364",
        distance: 27,
      },
      {
        station_id: "1099521667369",
        distance: 37,
      },
      {
        station_id: "1099521667632",
        distance: 24,
      },
      {
        station_id: "1099521667409",
        distance: 34,
      },
      {
        station_id: "1099521667418",
        distance: 51,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 74,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 83,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 66,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667412",
    connected_stations: [
      {
        station_id: "1099521667641",
        distance: 20,
      },
      {
        station_id: "1099521667438",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667413",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 22,
      },
      {
        station_id: "1099521667635",
        distance: 23,
      },
      {
        station_id: "1099521667617",
        distance: 19,
      },
      {
        station_id: "1099521667436",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667414",
    connected_stations: [
      {
        station_id: "1099521667638",
        distance: 49,
      },
      {
        station_id: "1099521667620",
        distance: 65,
      },
      {
        station_id: "1099521667618",
        distance: 44,
      },
      {
        station_id: "1099521667438",
        distance: 18,
      },
      {
        station_id: "1099521667432",
        distance: 19,
      },
      {
        station_id: "1099521667429",
        distance: 66,
      },
      {
        station_id: "1099521667418",
        distance: 49,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667415",
    connected_stations: [
      {
        station_id: "1099521667362",
        distance: 15,
      },
      {
        station_id: "1099521667638",
        distance: 35,
      },
      {
        station_id: "1099521667620",
        distance: 12,
      },
      {
        station_id: "1099521667408",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 90,
      },
      {
        type: "gas",
        multiplier: 100,
      },
      {
        type: "grain",
        multiplier: 103,
      },
      {
        type: "granule",
        multiplier: 101,
      },
      {
        type: "liquid",
        multiplier: 91,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 74,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667416",
    connected_stations: [
      {
        station_id: "1099521667355",
        distance: 48,
      },
      {
        station_id: "1099521667358",
        distance: 80,
      },
      {
        station_id: "1099521667361",
        distance: 30,
      },
      {
        station_id: "1099521667364",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667417",
    connected_stations: [
      {
        station_id: "1099521667640",
        distance: 20,
      },
      {
        station_id: "1099521667440",
        distance: 43,
      },
      {
        station_id: "1099521667434",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 88,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 73,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 65,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667418",
    connected_stations: [
      {
        station_id: "1099521667638",
        distance: 63,
      },
      {
        station_id: "1099521667618",
        distance: 20,
      },
      {
        station_id: "1099521667409",
        distance: 25,
      },
      {
        station_id: "1099521667420",
        distance: 19,
      },
      {
        station_id: "1099521667414",
        distance: 49,
      },
      {
        station_id: "1099521667411",
        distance: 51,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667419",
    connected_stations: [
      {
        station_id: "1099521667358",
        distance: 31,
      },
      {
        station_id: "1099521667635",
        distance: 27,
      },
      {
        station_id: "1099521667615",
        distance: 18,
      },
      {
        station_id: "1099521667425",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667420",
    connected_stations: [
      {
        station_id: "1099521667369",
        distance: 19,
      },
      {
        station_id: "1099521667636",
        distance: 18,
      },
      {
        station_id: "1099521667618",
        distance: 23,
      },
      {
        station_id: "1099521667418",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 94,
      },
      {
        type: "automobile",
        multiplier: 93,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 62,
      },
      {
        type: "gas",
        multiplier: 90,
      },
      {
        type: "grain",
        multiplier: 103,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 68,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 82,
      },
      {
        type: "perishable",
        multiplier: 82,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667421",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 29,
      },
      {
        station_id: "1099521667346",
        distance: 14,
      },
      {
        station_id: "1099521667407",
        distance: 32,
      },
      {
        station_id: "1099521667430",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 49,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 71,
      },
      {
        type: "pallet",
        multiplier: 77,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 40,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667422",
    connected_stations: [
      {
        station_id: "1099521667361",
        distance: 26,
      },
      {
        station_id: "1099521667625",
        distance: 18,
      },
      {
        station_id: "1099521667623",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667423",
    connected_stations: [
      {
        station_id: "1099521667634",
        distance: 23,
      },
      {
        station_id: "1099521667444",
        distance: 17,
      },
      {
        station_id: "1099521667433",
        distance: 21,
      },
      {
        station_id: "1099521667427",
        distance: 26,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667424",
    connected_stations: [
      {
        station_id: "1099521667360",
        distance: 18,
      },
      {
        station_id: "1099521667371",
        distance: 20,
      },
      {
        station_id: "1099521667616",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667425",
    connected_stations: [
      {
        station_id: "1099521667358",
        distance: 30,
      },
      {
        station_id: "1099521667359",
        distance: 19,
      },
      {
        station_id: "1099521667419",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667426",
    connected_stations: [
      {
        station_id: "1099521667404",
        distance: 13,
      },
      {
        station_id: "1099521667435",
        distance: 15,
      },
      {
        station_id: "1099521667410",
        distance: 14,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667427",
    connected_stations: [
      {
        station_id: "1099521667368",
        distance: 18,
      },
      {
        station_id: "1099521667621",
        distance: 43,
      },
      {
        station_id: "1099521667444",
        distance: 29,
      },
      {
        station_id: "1099521667439",
        distance: 19,
      },
      {
        station_id: "1099521667423",
        distance: 26,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 74,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667428",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 30,
      },
      {
        station_id: "1099521667617",
        distance: 19,
      },
      {
        station_id: "1099521667612",
        distance: 20,
      },
      {
        station_id: "1099521667405",
        distance: 33,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 93,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 91,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 75,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667429",
    connected_stations: [
      {
        station_id: "1099521667613",
        distance: 31,
      },
      {
        station_id: "1099521667612",
        distance: 18,
      },
      {
        station_id: "1099521667405",
        distance: 20,
      },
      {
        station_id: "1099521667445",
        distance: 20,
      },
      {
        station_id: "1099521667414",
        distance: 66,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667430",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 28,
      },
      {
        station_id: "1099521667365",
        distance: 18,
      },
      {
        station_id: "1099521667642",
        distance: 21,
      },
      {
        station_id: "1099521667421",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667431",
    connected_stations: [
      {
        station_id: "1099521667357",
        distance: 20,
      },
      {
        station_id: "1099521667631",
        distance: 25,
      },
      {
        station_id: "1099521667627",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 94,
      },
      {
        type: "grain",
        multiplier: 101,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 97,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667432",
    connected_stations: [
      {
        station_id: "1099521667619",
        distance: 33,
      },
      {
        station_id: "1099521667447",
        distance: 20,
      },
      {
        station_id: "1099521667441",
        distance: 27,
      },
      {
        station_id: "1099521667414",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 91,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667433",
    connected_stations: [
      {
        station_id: "1099521667371",
        distance: 23,
      },
      {
        station_id: "1099521667634",
        distance: 13,
      },
      {
        station_id: "1099521667423",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667434",
    connected_stations: [
      {
        station_id: "1099521667363",
        distance: 28,
      },
      {
        station_id: "1099521667367",
        distance: 37,
      },
      {
        station_id: "1099521667371",
        distance: 77,
      },
      {
        station_id: "1099521667639",
        distance: 27,
      },
      {
        station_id: "1099521667621",
        distance: 20,
      },
      {
        station_id: "1099521667449",
        distance: 61,
      },
      {
        station_id: "1099521667403",
        distance: 28,
      },
      {
        station_id: "1099521667417",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667435",
    connected_stations: [
      {
        station_id: "1099521667637",
        distance: 12,
      },
      {
        station_id: "1099521667404",
        distance: 10,
      },
      {
        station_id: "1099521667440",
        distance: 36,
      },
      {
        station_id: "1099521667426",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667436",
    connected_stations: [
      {
        station_id: "1099521667635",
        distance: 19,
      },
      {
        station_id: "1099521667628",
        distance: 20,
      },
      {
        station_id: "1099521667413",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667437",
    connected_stations: [
      {
        station_id: "1099521667630",
        distance: 16,
      },
      {
        station_id: "1099521667629",
        distance: 19,
      },
      {
        station_id: "1099521667406",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 88,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 72,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 65,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667438",
    connected_stations: [
      {
        station_id: "1099521667459",
        distance: 38,
      },
      {
        station_id: "1099521667638",
        distance: 36,
      },
      {
        station_id: "1099521667414",
        distance: 18,
      },
      {
        station_id: "1099521667412",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667439",
    connected_stations: [
      {
        station_id: "1099521667639",
        distance: 19,
      },
      {
        station_id: "1099521667634",
        distance: 49,
      },
      {
        station_id: "1099521667624",
        distance: 19,
      },
      {
        station_id: "1099521667621",
        distance: 31,
      },
      {
        station_id: "1099521667443",
        distance: 37,
      },
      {
        station_id: "1099521667427",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667440",
    connected_stations: [
      {
        station_id: "1099521667367",
        distance: 20,
      },
      {
        station_id: "1099521667637",
        distance: 30,
      },
      {
        station_id: "1099521667449",
        distance: 20,
      },
      {
        station_id: "1099521667435",
        distance: 36,
      },
      {
        station_id: "1099521667417",
        distance: 43,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667441",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 42,
      },
      {
        station_id: "1099521667370",
        distance: 34,
      },
      {
        station_id: "1099521667642",
        distance: 20,
      },
      {
        station_id: "1099521667633",
        distance: 20,
      },
      {
        station_id: "1099521667447",
        distance: 17,
      },
      {
        station_id: "1099521667432",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 100,
      },
      {
        type: "grain",
        multiplier: 99,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 98,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667442",
    connected_stations: [
      {
        station_id: "1099521667634",
        distance: 41,
      },
      {
        station_id: "1099521667622",
        distance: 18,
      },
      {
        station_id: "1099521667616",
        distance: 20,
      },
      {
        station_id: "1099521667448",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667443",
    connected_stations: [
      {
        station_id: "1099521667368",
        distance: 20,
      },
      {
        station_id: "1099521667614",
        distance: 17,
      },
      {
        station_id: "1099521667751",
        distance: 31,
      },
      {
        station_id: "1099521667439",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667444",
    connected_stations: [
      {
        station_id: "1099521667427",
        distance: 29,
      },
      {
        station_id: "1099521667423",
        distance: 17,
      },
      {
        station_id: "1099521667793",
        distance: 51,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667445",
    connected_stations: [
      {
        station_id: "1099521667370",
        distance: 19,
      },
      {
        station_id: "1099521667619",
        distance: 20,
      },
      {
        station_id: "1099521667447",
        distance: 20,
      },
      {
        station_id: "1099521667429",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667446",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 15,
      },
      {
        station_id: "1099521667633",
        distance: 19,
      },
      {
        station_id: "1099521667407",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667447",
    connected_stations: [
      {
        station_id: "1099521667370",
        distance: 25,
      },
      {
        station_id: "1099521667445",
        distance: 20,
      },
      {
        station_id: "1099521667441",
        distance: 17,
      },
      {
        station_id: "1099521667432",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667448",
    connected_stations: [
      {
        station_id: "1099521667357",
        distance: 19,
      },
      {
        station_id: "1099521667360",
        distance: 37,
      },
      {
        station_id: "1099521667622",
        distance: 29,
      },
      {
        station_id: "1099521667616",
        distance: 37,
      },
      {
        station_id: "1099521667442",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667449",
    connected_stations: [
      {
        station_id: "1099521667637",
        distance: 12,
      },
      {
        station_id: "1099521667440",
        distance: 20,
      },
      {
        station_id: "1099521667434",
        distance: 61,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667450",
    connected_stations: [
      {
        station_id: "1099521667535",
        distance: 14,
      },
      {
        station_id: "1099521667569",
        distance: 13,
      },
      {
        station_id: "1099521667518",
        distance: 15,
      },
      {
        station_id: "1099521667548",
        distance: 14,
      },
      {
        station_id: "1099521667575",
        distance: 43,
      },
      {
        station_id: "1099521667570",
        distance: 44,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 97,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 101,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 101,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 93,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "mythic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667451",
    connected_stations: [
      {
        station_id: "1099521667588",
        distance: 34,
      },
      {
        station_id: "1099521667528",
        distance: 18,
      },
      {
        station_id: "1099521667545",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 44,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 52,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667452",
    connected_stations: [
      {
        station_id: "1099521667695",
        distance: 49,
      },
      {
        station_id: "1099521667526",
        distance: 21,
      },
      {
        station_id: "1099521667579",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667453",
    connected_stations: [
      {
        station_id: "1099521667579",
        distance: 18,
      },
      {
        station_id: "1099521667546",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 93,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 75,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "epic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667454",
    connected_stations: [
      {
        station_id: "1099521667536",
        distance: 31,
      },
      {
        station_id: "1099521667547",
        distance: 18,
      },
      {
        station_id: "1099521667581",
        distance: 15,
      },
      {
        station_id: "1099521667549",
        distance: 23,
      },
      {
        station_id: "1099521667461",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 92,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 95,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 94,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "epic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667455",
    connected_stations: [
      {
        station_id: "1099521667580",
        distance: 62,
      },
      {
        station_id: "1099521667539",
        distance: 63,
      },
      {
        station_id: "1099521667650",
        distance: 32,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 94,
      },
      {
        type: "automobile",
        multiplier: 98,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 73,
      },
      {
        type: "gas",
        multiplier: 85,
      },
      {
        type: "grain",
        multiplier: 97,
      },
      {
        type: "granule",
        multiplier: 92,
      },
      {
        type: "liquid",
        multiplier: 81,
      },
      {
        type: "ore",
        multiplier: 64,
      },
      {
        type: "oversized",
        multiplier: 100,
      },
      {
        type: "pallet",
        multiplier: 68,
      },
      {
        type: "perishable",
        multiplier: 81,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667456",
    connected_stations: [
      {
        station_id: "1099521667655",
        distance: 19,
      },
      {
        station_id: "1099521667538",
        distance: 16,
      },
      {
        station_id: "1099521667528",
        distance: 33,
      },
      {
        station_id: "1099521667545",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 85,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 68,
      },
      {
        type: "perishable",
        multiplier: 79,
      },
      {
        type: "top_secret",
        multiplier: 49,
      },
    ],
    rarity: "epic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667457",
    connected_stations: [
      {
        station_id: "1099521667463",
        distance: 17,
      },
      {
        station_id: "1099521667654",
        distance: 20,
      },
      {
        station_id: "1099521667660",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 89,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 81,
      },
      {
        type: "perishable",
        multiplier: 101,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667458",
    connected_stations: [
      {
        station_id: "1099521667535",
        distance: 19,
      },
      {
        station_id: "1099521667649",
        distance: 43,
      },
      {
        station_id: "1099521667533",
        distance: 25,
      },
      {
        station_id: "1099521667647",
        distance: 33,
      },
      {
        station_id: "1099521667517",
        distance: 41,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667459",
    connected_stations: [
      {
        station_id: "1099521667438",
        distance: 38,
      },
      {
        station_id: "1099521667567",
        distance: 24,
      },
      {
        station_id: "1099521667560",
        distance: 17,
      },
      {
        station_id: "1099521667643",
        distance: 17,
      },
      {
        station_id: "1099521667533",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667460",
    connected_stations: [
      {
        station_id: "1099521667661",
        distance: 15,
      },
      {
        station_id: "1099521667521",
        distance: 17,
      },
      {
        station_id: "1099521667652",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 101,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 100,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667461",
    connected_stations: [
      {
        station_id: "1099521667454",
        distance: 40,
      },
      {
        station_id: "1099521667574",
        distance: 19,
      },
      {
        station_id: "1099521667536",
        distance: 69,
      },
      {
        station_id: "1099521667655",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 94,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 95,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667462",
    connected_stations: [
      {
        station_id: "1099521667561",
        distance: 23,
      },
      {
        station_id: "1099521667650",
        distance: 41,
      },
      {
        station_id: "1099521667539",
        distance: 36,
      },
      {
        station_id: "1099521667646",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667463",
    connected_stations: [
      {
        station_id: "1099521667457",
        distance: 17,
      },
      {
        station_id: "1099521667648",
        distance: 20,
      },
      {
        station_id: "1099521667660",
        distance: 23,
      },
      {
        station_id: "1099521667556",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667464",
    connected_stations: [
      {
        station_id: "1099521667546",
        distance: 20,
      },
      {
        station_id: "1099521667658",
        distance: 19,
      },
      {
        station_id: "1099521667575",
        distance: 20,
      },
      {
        station_id: "1099521667662",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 83,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667517",
    connected_stations: [
      {
        station_id: "1099521667458",
        distance: 41,
      },
      {
        station_id: "1099521667653",
        distance: 16,
      },
      {
        station_id: "1099521667663",
        distance: 19,
      },
      {
        station_id: "1099521667557",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 80,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 80,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 78,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667518",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 15,
      },
      {
        station_id: "1099521667663",
        distance: 21,
      },
      {
        station_id: "1099521667662",
        distance: 15,
      },
      {
        station_id: "1099521667575",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667519",
    connected_stations: [
      {
        station_id: "1099521667644",
        distance: 23,
      },
      {
        station_id: "1099521667645",
        distance: 17,
      },
      {
        station_id: "1099521667522",
        distance: 18,
      },
      {
        station_id: "1099521667543",
        distance: 32,
      },
      {
        station_id: "1099521667553",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 104,
      },
      {
        type: "gas",
        multiplier: 104,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 106,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667520",
    connected_stations: [
      {
        station_id: "1099521667652",
        distance: 16,
      },
      {
        station_id: "1099521667665",
        distance: 20,
      },
      {
        station_id: "1099521667566",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667521",
    connected_stations: [
      {
        station_id: "1099521667460",
        distance: 17,
      },
      {
        station_id: "1099521667541",
        distance: 18,
      },
      {
        station_id: "1099521667552",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 81,
      },
      {
        type: "automobile",
        multiplier: 99,
      },
      {
        type: "building_materials",
        multiplier: 71,
      },
      {
        type: "crate",
        multiplier: 79,
      },
      {
        type: "gas",
        multiplier: 84,
      },
      {
        type: "grain",
        multiplier: 80,
      },
      {
        type: "granule",
        multiplier: 88,
      },
      {
        type: "liquid",
        multiplier: 75,
      },
      {
        type: "ore",
        multiplier: 79,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 77,
      },
      {
        type: "perishable",
        multiplier: 86,
      },
      {
        type: "top_secret",
        multiplier: 99,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667522",
    connected_stations: [
      {
        station_id: "1099521667645",
        distance: 21,
      },
      {
        station_id: "1099521667647",
        distance: 34,
      },
      {
        station_id: "1099521667651",
        distance: 20,
      },
      {
        station_id: "1099521667519",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 92,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667523",
    connected_stations: [
      {
        station_id: "1099521667652",
        distance: 30,
      },
      {
        station_id: "1099521667665",
        distance: 18,
      },
      {
        station_id: "1099521667579",
        distance: 73,
      },
      {
        station_id: "1099521667575",
        distance: 23,
      },
      {
        station_id: "1099521667548",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667524",
    connected_stations: [
      {
        station_id: "1099521667648",
        distance: 12,
      },
      {
        station_id: "1099521667565",
        distance: 19,
      },
      {
        station_id: "1099521667540",
        distance: 24,
      },
      {
        station_id: "1099521667556",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 29,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667525",
    connected_stations: [
      {
        station_id: "1099521667576",
        distance: 19,
      },
      {
        station_id: "1099521667571",
        distance: 48,
      },
      {
        station_id: "1099521667570",
        distance: 25,
      },
      {
        station_id: "1099521667561",
        distance: 67,
      },
      {
        station_id: "1099521667551",
        distance: 22,
      },
      {
        station_id: "1099521667529",
        distance: 19,
      },
      {
        station_id: "1099521667532",
        distance: 36,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667526",
    connected_stations: [
      {
        station_id: "1099521667452",
        distance: 21,
      },
      {
        station_id: "1099521667328",
        distance: 37,
      },
      {
        station_id: "1099521667579",
        distance: 19,
      },
      {
        station_id: "1099521667541",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 102,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 99,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667527",
    connected_stations: [
      {
        station_id: "1099521667654",
        distance: 18,
      },
      {
        station_id: "1099521667574",
        distance: 19,
      },
      {
        station_id: "1099521667562",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 100,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667528",
    connected_stations: [
      {
        station_id: "1099521667451",
        distance: 18,
      },
      {
        station_id: "1099521667456",
        distance: 33,
      },
      {
        station_id: "1099521667545",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667529",
    connected_stations: [
      {
        station_id: "1099521667576",
        distance: 23,
      },
      {
        station_id: "1099521667573",
        distance: 31,
      },
      {
        station_id: "1099521667525",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667530",
    connected_stations: [
      {
        station_id: "1099521667643",
        distance: 52,
      },
      {
        station_id: "1099521667653",
        distance: 23,
      },
      {
        station_id: "1099521667569",
        distance: 35,
      },
      {
        station_id: "1099521667543",
        distance: 13,
      },
      {
        station_id: "1099521667535",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 84,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667531",
    connected_stations: [
      {
        station_id: "1099521667646",
        distance: 20,
      },
      {
        station_id: "1099521667550",
        distance: 21,
      },
      {
        station_id: "1099521667544",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 87,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 82,
      },
      {
        type: "granule",
        multiplier: 90,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 86,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667532",
    connected_stations: [
      {
        station_id: "1099521667571",
        distance: 22,
      },
      {
        station_id: "1099521667561",
        distance: 56,
      },
      {
        station_id: "1099521667525",
        distance: 36,
      },
      {
        station_id: "1099521667554",
        distance: 27,
      },
      {
        station_id: "1099521667551",
        distance: 20,
      },
      {
        station_id: "1099521667540",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 73,
      },
      {
        type: "crate",
        multiplier: 72,
      },
      {
        type: "gas",
        multiplier: 97,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 99,
      },
      {
        type: "ore",
        multiplier: 76,
      },
      {
        type: "oversized",
        multiplier: 100,
      },
      {
        type: "pallet",
        multiplier: 82,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667533",
    connected_stations: [
      {
        station_id: "1099521667458",
        distance: 25,
      },
      {
        station_id: "1099521667459",
        distance: 23,
      },
      {
        station_id: "1099521667649",
        distance: 35,
      },
      {
        station_id: "1099521667560",
        distance: 18,
      },
      {
        station_id: "1099521667548",
        distance: 50,
      },
      {
        station_id: "1099521667542",
        distance: 39,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667534",
    connected_stations: [
      {
        station_id: "1099521667644",
        distance: 19,
      },
      {
        station_id: "1099521667653",
        distance: 25,
      },
      {
        station_id: "1099521667577",
        distance: 24,
      },
      {
        station_id: "1099521667537",
        distance: 24,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 96,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 81,
      },
      {
        type: "gas",
        multiplier: 94,
      },
      {
        type: "grain",
        multiplier: 99,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 71,
      },
      {
        type: "top_secret",
        multiplier: 99,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667535",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 14,
      },
      {
        station_id: "1099521667458",
        distance: 19,
      },
      {
        station_id: "1099521667643",
        distance: 64,
      },
      {
        station_id: "1099521667666",
        distance: 46,
      },
      {
        station_id: "1099521667569",
        distance: 14,
      },
      {
        station_id: "1099521667530",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667536",
    connected_stations: [
      {
        station_id: "1099521667454",
        distance: 31,
      },
      {
        station_id: "1099521667461",
        distance: 69,
      },
      {
        station_id: "1099521667656",
        distance: 33,
      },
      {
        station_id: "1099521667539",
        distance: 64,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667537",
    connected_stations: [
      {
        station_id: "1099521667644",
        distance: 28,
      },
      {
        station_id: "1099521667653",
        distance: 14,
      },
      {
        station_id: "1099521667534",
        distance: 24,
      },
      {
        station_id: "1099521667543",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667538",
    connected_stations: [
      {
        station_id: "1099521667456",
        distance: 16,
      },
      {
        station_id: "1099521667655",
        distance: 17,
      },
      {
        station_id: "1099521667545",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667539",
    connected_stations: [
      {
        station_id: "1099521667455",
        distance: 63,
      },
      {
        station_id: "1099521667462",
        distance: 36,
      },
      {
        station_id: "1099521667646",
        distance: 19,
      },
      {
        station_id: "1099521667656",
        distance: 33,
      },
      {
        station_id: "1099521667536",
        distance: 64,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 85,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667540",
    connected_stations: [
      {
        station_id: "1099521667657",
        distance: 40,
      },
      {
        station_id: "1099521667571",
        distance: 19,
      },
      {
        station_id: "1099521667564",
        distance: 19,
      },
      {
        station_id: "1099521667524",
        distance: 24,
      },
      {
        station_id: "1099521667554",
        distance: 37,
      },
      {
        station_id: "1099521667532",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 89,
      },
      {
        type: "gas",
        multiplier: 82,
      },
      {
        type: "grain",
        multiplier: 101,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667541",
    connected_stations: [
      {
        station_id: "1099521667521",
        distance: 18,
      },
      {
        station_id: "1099521667526",
        distance: 16,
      },
      {
        station_id: "1099521667552",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 51,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667542",
    connected_stations: [
      {
        station_id: "1099521667666",
        distance: 19,
      },
      {
        station_id: "1099521667665",
        distance: 20,
      },
      {
        station_id: "1099521667548",
        distance: 31,
      },
      {
        station_id: "1099521667533",
        distance: 39,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 100,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667543",
    connected_stations: [
      {
        station_id: "1099521667647",
        distance: 17,
      },
      {
        station_id: "1099521667519",
        distance: 32,
      },
      {
        station_id: "1099521667530",
        distance: 13,
      },
      {
        station_id: "1099521667537",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667544",
    connected_stations: [
      {
        station_id: "1099521667657",
        distance: 38,
      },
      {
        station_id: "1099521667571",
        distance: 20,
      },
      {
        station_id: "1099521667564",
        distance: 44,
      },
      {
        station_id: "1099521667561",
        distance: 19,
      },
      {
        station_id: "1099521667531",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 100,
      },
      {
        type: "building_materials",
        multiplier: 83,
      },
      {
        type: "crate",
        multiplier: 86,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 79,
      },
      {
        type: "oversized",
        multiplier: 92,
      },
      {
        type: "pallet",
        multiplier: 91,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 99,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667545",
    connected_stations: [
      {
        station_id: "1099521667451",
        distance: 34,
      },
      {
        station_id: "1099521667456",
        distance: 19,
      },
      {
        station_id: "1099521667528",
        distance: 20,
      },
      {
        station_id: "1099521667538",
        distance: 18,
      },
      {
        station_id: "1099521667682",
        distance: 47,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 96,
      },
      {
        type: "gas",
        multiplier: 99,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 85,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667546",
    connected_stations: [
      {
        station_id: "1099521667453",
        distance: 20,
      },
      {
        station_id: "1099521667464",
        distance: 20,
      },
      {
        station_id: "1099521667652",
        distance: 43,
      },
      {
        station_id: "1099521667575",
        distance: 20,
      },
      {
        station_id: "1099521667552",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 85,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 68,
      },
      {
        type: "perishable",
        multiplier: 79,
      },
      {
        type: "top_secret",
        multiplier: 49,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667547",
    connected_stations: [
      {
        station_id: "1099521667454",
        distance: 18,
      },
      {
        station_id: "1099521667562",
        distance: 14,
      },
      {
        station_id: "1099521667558",
        distance: 19,
      },
      {
        station_id: "1099521667549",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 55,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 87,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 78,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 88,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667548",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 14,
      },
      {
        station_id: "1099521667665",
        distance: 30,
      },
      {
        station_id: "1099521667523",
        distance: 20,
      },
      {
        station_id: "1099521667533",
        distance: 50,
      },
      {
        station_id: "1099521667542",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 96,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 87,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 70,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667549",
    connected_stations: [
      {
        station_id: "1099521667454",
        distance: 23,
      },
      {
        station_id: "1099521667656",
        distance: 35,
      },
      {
        station_id: "1099521667659",
        distance: 27,
      },
      {
        station_id: "1099521667563",
        distance: 15,
      },
      {
        station_id: "1099521667547",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667550",
    connected_stations: [
      {
        station_id: "1099521667646",
        distance: 18,
      },
      {
        station_id: "1099521667656",
        distance: 25,
      },
      {
        station_id: "1099521667657",
        distance: 26,
      },
      {
        station_id: "1099521667659",
        distance: 20,
      },
      {
        station_id: "1099521667531",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 104,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 74,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667551",
    connected_stations: [
      {
        station_id: "1099521667525",
        distance: 22,
      },
      {
        station_id: "1099521667555",
        distance: 29,
      },
      {
        station_id: "1099521667554",
        distance: 20,
      },
      {
        station_id: "1099521667532",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 74,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 86,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 84,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667552",
    connected_stations: [
      {
        station_id: "1099521667652",
        distance: 38,
      },
      {
        station_id: "1099521667579",
        distance: 20,
      },
      {
        station_id: "1099521667521",
        distance: 20,
      },
      {
        station_id: "1099521667541",
        distance: 16,
      },
      {
        station_id: "1099521667546",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 82,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 90,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667553",
    connected_stations: [
      {
        station_id: "1099521667647",
        distance: 15,
      },
      {
        station_id: "1099521667649",
        distance: 15,
      },
      {
        station_id: "1099521667651",
        distance: 17,
      },
      {
        station_id: "1099521667519",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 99,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667554",
    connected_stations: [
      {
        station_id: "1099521667658",
        distance: 20,
      },
      {
        station_id: "1099521667565",
        distance: 18,
      },
      {
        station_id: "1099521667551",
        distance: 20,
      },
      {
        station_id: "1099521667532",
        distance: 27,
      },
      {
        station_id: "1099521667540",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 95,
      },
      {
        type: "perishable",
        multiplier: 89,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667555",
    connected_stations: [
      {
        station_id: "1099521667658",
        distance: 18,
      },
      {
        station_id: "1099521667662",
        distance: 16,
      },
      {
        station_id: "1099521667570",
        distance: 20,
      },
      {
        station_id: "1099521667551",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667556",
    connected_stations: [
      {
        station_id: "1099521667463",
        distance: 19,
      },
      {
        station_id: "1099521667660",
        distance: 17,
      },
      {
        station_id: "1099521667564",
        distance: 17,
      },
      {
        station_id: "1099521667524",
        distance: 20,
      },
      {
        station_id: "1099521667558",
        distance: 36,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667557",
    connected_stations: [
      {
        station_id: "1099521667517",
        distance: 17,
      },
      {
        station_id: "1099521667577",
        distance: 34,
      },
      {
        station_id: "1099521667576",
        distance: 25,
      },
      {
        station_id: "1099521667570",
        distance: 20,
      },
      {
        station_id: "1099521667568",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 87,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 92,
      },
      {
        type: "pallet",
        multiplier: 95,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667558",
    connected_stations: [
      {
        station_id: "1099521667578",
        distance: 19,
      },
      {
        station_id: "1099521667563",
        distance: 18,
      },
      {
        station_id: "1099521667562",
        distance: 16,
      },
      {
        station_id: "1099521667547",
        distance: 19,
      },
      {
        station_id: "1099521667556",
        distance: 36,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667559",
    connected_stations: [
      {
        station_id: "1099521667650",
        distance: 47,
      },
      {
        station_id: "1099521667664",
        distance: 46,
      },
      {
        station_id: "1099521667580",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 91,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 92,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 93,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667560",
    connected_stations: [
      {
        station_id: "1099521667459",
        distance: 17,
      },
      {
        station_id: "1099521667666",
        distance: 18,
      },
      {
        station_id: "1099521667567",
        distance: 19,
      },
      {
        station_id: "1099521667533",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667561",
    connected_stations: [
      {
        station_id: "1099521667462",
        distance: 23,
      },
      {
        station_id: "1099521667573",
        distance: 20,
      },
      {
        station_id: "1099521667544",
        distance: 19,
      },
      {
        station_id: "1099521667525",
        distance: 67,
      },
      {
        station_id: "1099521667532",
        distance: 56,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667562",
    connected_stations: [
      {
        station_id: "1099521667660",
        distance: 28,
      },
      {
        station_id: "1099521667581",
        distance: 17,
      },
      {
        station_id: "1099521667547",
        distance: 14,
      },
      {
        station_id: "1099521667527",
        distance: 21,
      },
      {
        station_id: "1099521667558",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 96,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667563",
    connected_stations: [
      {
        station_id: "1099521667657",
        distance: 23,
      },
      {
        station_id: "1099521667659",
        distance: 19,
      },
      {
        station_id: "1099521667558",
        distance: 18,
      },
      {
        station_id: "1099521667549",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667564",
    connected_stations: [
      {
        station_id: "1099521667578",
        distance: 17,
      },
      {
        station_id: "1099521667540",
        distance: 19,
      },
      {
        station_id: "1099521667556",
        distance: 17,
      },
      {
        station_id: "1099521667544",
        distance: 44,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667565",
    connected_stations: [
      {
        station_id: "1099521667648",
        distance: 19,
      },
      {
        station_id: "1099521667554",
        distance: 18,
      },
      {
        station_id: "1099521667524",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 103,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667566",
    connected_stations: [
      {
        station_id: "1099521667652",
        distance: 18,
      },
      {
        station_id: "1099521667661",
        distance: 14,
      },
      {
        station_id: "1099521667520",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667567",
    connected_stations: [
      {
        station_id: "1099521667459",
        distance: 24,
      },
      {
        station_id: "1099521667633",
        distance: 26,
      },
      {
        station_id: "1099521667560",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 88,
      },
      {
        type: "automobile",
        multiplier: 55,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 72,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 61,
      },
      {
        type: "oversized",
        multiplier: 86,
      },
      {
        type: "pallet",
        multiplier: 74,
      },
      {
        type: "perishable",
        multiplier: 45,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667568",
    connected_stations: [
      {
        station_id: "1099521667577",
        distance: 20,
      },
      {
        station_id: "1099521667576",
        distance: 23,
      },
      {
        station_id: "1099521667557",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 51,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667569",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 13,
      },
      {
        station_id: "1099521667663",
        distance: 14,
      },
      {
        station_id: "1099521667535",
        distance: 14,
      },
      {
        station_id: "1099521667530",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 96,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667570",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 44,
      },
      {
        station_id: "1099521667663",
        distance: 25,
      },
      {
        station_id: "1099521667662",
        distance: 22,
      },
      {
        station_id: "1099521667557",
        distance: 20,
      },
      {
        station_id: "1099521667555",
        distance: 20,
      },
      {
        station_id: "1099521667525",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 103,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667571",
    connected_stations: [
      {
        station_id: "1099521667573",
        distance: 33,
      },
      {
        station_id: "1099521667525",
        distance: 48,
      },
      {
        station_id: "1099521667540",
        distance: 19,
      },
      {
        station_id: "1099521667544",
        distance: 20,
      },
      {
        station_id: "1099521667532",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667572",
    connected_stations: [
      {
        station_id: "1099521667645",
        distance: 61,
      },
      {
        station_id: "1099521667664",
        distance: 20,
      },
      {
        station_id: "1099521667580",
        distance: 73,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 90,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 96,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 95,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667573",
    connected_stations: [
      {
        station_id: "1099521667529",
        distance: 31,
      },
      {
        station_id: "1099521667561",
        distance: 20,
      },
      {
        station_id: "1099521667571",
        distance: 33,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667574",
    connected_stations: [
      {
        station_id: "1099521667461",
        distance: 19,
      },
      {
        station_id: "1099521667581",
        distance: 16,
      },
      {
        station_id: "1099521667527",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 91,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667575",
    connected_stations: [
      {
        station_id: "1099521667450",
        distance: 43,
      },
      {
        station_id: "1099521667464",
        distance: 20,
      },
      {
        station_id: "1099521667523",
        distance: 23,
      },
      {
        station_id: "1099521667546",
        distance: 20,
      },
      {
        station_id: "1099521667518",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 92,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667576",
    connected_stations: [
      {
        station_id: "1099521667557",
        distance: 25,
      },
      {
        station_id: "1099521667568",
        distance: 23,
      },
      {
        station_id: "1099521667525",
        distance: 19,
      },
      {
        station_id: "1099521667529",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667577",
    connected_stations: [
      {
        station_id: "1099521667653",
        distance: 31,
      },
      {
        station_id: "1099521667568",
        distance: 20,
      },
      {
        station_id: "1099521667557",
        distance: 34,
      },
      {
        station_id: "1099521667534",
        distance: 24,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 98,
      },
      {
        type: "pallet",
        multiplier: 103,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667578",
    connected_stations: [
      {
        station_id: "1099521667657",
        distance: 16,
      },
      {
        station_id: "1099521667558",
        distance: 19,
      },
      {
        station_id: "1099521667564",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667579",
    connected_stations: [
      {
        station_id: "1099521667452",
        distance: 22,
      },
      {
        station_id: "1099521667453",
        distance: 18,
      },
      {
        station_id: "1099521667552",
        distance: 20,
      },
      {
        station_id: "1099521667523",
        distance: 73,
      },
      {
        station_id: "1099521667526",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667580",
    connected_stations: [
      {
        station_id: "1099521667455",
        distance: 62,
      },
      {
        station_id: "1099521667572",
        distance: 73,
      },
      {
        station_id: "1099521667559",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 103,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667581",
    connected_stations: [
      {
        station_id: "1099521667454",
        distance: 15,
      },
      {
        station_id: "1099521667562",
        distance: 17,
      },
      {
        station_id: "1099521667574",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 78,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 96,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 65,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667582",
    connected_stations: [
      {
        station_id: "1099521667720",
        distance: 15,
      },
      {
        station_id: "1099521667681",
        distance: 18,
      },
      {
        station_id: "1099521667684",
        distance: 30,
      },
      {
        station_id: "1099521667594",
        distance: 18,
      },
      {
        station_id: "1099521667739",
        distance: 43,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "mythic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667583",
    connected_stations: [
      {
        station_id: "1099521667741",
        distance: 45,
      },
      {
        station_id: "1099521667689",
        distance: 16,
      },
      {
        station_id: "1099521667675",
        distance: 17,
      },
      {
        station_id: "1099521667726",
        distance: 61,
      },
      {
        station_id: "1099521667683",
        distance: 25,
      },
      {
        station_id: "1099521667679",
        distance: 54,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667584",
    connected_stations: [
      {
        station_id: "1099521667314",
        distance: 48,
      },
      {
        station_id: "1099521667672",
        distance: 29,
      },
      {
        station_id: "1099521667723",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667585",
    connected_stations: [
      {
        station_id: "1099521667602",
        distance: 20,
      },
      {
        station_id: "1099521667599",
        distance: 15,
      },
      {
        station_id: "1099521667596",
        distance: 51,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 90,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 101,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667586",
    connected_stations: [
      {
        station_id: "1099521667671",
        distance: 18,
      },
      {
        station_id: "1099521667742",
        distance: 17,
      },
      {
        station_id: "1099521667758",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667587",
    connected_stations: [
      {
        station_id: "1099521667731",
        distance: 37,
      },
      {
        station_id: "1099521667604",
        distance: 36,
      },
      {
        station_id: "1099521667676",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 25,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 54,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 87,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 104,
      },
      {
        type: "pallet",
        multiplier: 76,
      },
      {
        type: "perishable",
        multiplier: 72,
      },
      {
        type: "top_secret",
        multiplier: 84,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667588",
    connected_stations: [
      {
        station_id: "1099521667451",
        distance: 34,
      },
      {
        station_id: "1099521667597",
        distance: 24,
      },
      {
        station_id: "1099521667680",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667589",
    connected_stations: [
      {
        station_id: "1099521667716",
        distance: 19,
      },
      {
        station_id: "1099521667756",
        distance: 31,
      },
      {
        station_id: "1099521667707",
        distance: 45,
      },
      {
        station_id: "1099521667678",
        distance: 51,
      },
      {
        station_id: "1099521667724",
        distance: 46,
      },
      {
        station_id: "1099521667712",
        distance: 33,
      },
      {
        station_id: "1099521667744",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667590",
    connected_stations: [
      {
        station_id: "1099521667695",
        distance: 21,
      },
      {
        station_id: "1099521667709",
        distance: 19,
      },
      {
        station_id: "1099521667719",
        distance: 19,
      },
      {
        station_id: "1099521667723",
        distance: 25,
      },
      {
        station_id: "1099521667718",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667591",
    connected_stations: [
      {
        station_id: "1099521667595",
        distance: 27,
      },
      {
        station_id: "1099521667797",
        distance: 64,
      },
      {
        station_id: "1099521667699",
        distance: 30,
      },
      {
        station_id: "1099521667743",
        distance: 31,
      },
      {
        station_id: "1099521667720",
        distance: 75,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 90,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667592",
    connected_stations: [
      {
        station_id: "1099521667752",
        distance: 21,
      },
      {
        station_id: "1099521667680",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667593",
    connected_stations: [
      {
        station_id: "1099521667725",
        distance: 19,
      },
      {
        station_id: "1099521667677",
        distance: 25,
      },
      {
        station_id: "1099521667722",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667594",
    connected_stations: [
      {
        station_id: "1099521667582",
        distance: 18,
      },
      {
        station_id: "1099521667707",
        distance: 16,
      },
      {
        station_id: "1099521667702",
        distance: 31,
      },
      {
        station_id: "1099521667601",
        distance: 46,
      },
      {
        station_id: "1099521667674",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667595",
    connected_stations: [
      {
        station_id: "1099521667591",
        distance: 27,
      },
      {
        station_id: "1099521667736",
        distance: 24,
      },
      {
        station_id: "1099521667681",
        distance: 36,
      },
      {
        station_id: "1099521667699",
        distance: 30,
      },
      {
        station_id: "1099521667743",
        distance: 32,
      },
      {
        station_id: "1099521667692",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 81,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 93,
      },
      {
        type: "liquid",
        multiplier: 94,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 74,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667596",
    connected_stations: [
      {
        station_id: "1099521667585",
        distance: 51,
      },
      {
        station_id: "1099521667711",
        distance: 20,
      },
      {
        station_id: "1099521667755",
        distance: 19,
      },
      {
        station_id: "1099521667741",
        distance: 17,
      },
      {
        station_id: "1099521667598",
        distance: 42,
      },
      {
        station_id: "1099521667704",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 90,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 101,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667597",
    connected_stations: [
      {
        station_id: "1099521667588",
        distance: 24,
      },
      {
        station_id: "1099521667680",
        distance: 15,
      },
      {
        station_id: "1099521667686",
        distance: 15,
      },
      {
        station_id: "1099521667669",
        distance: 42,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 97,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 89,
      },
      {
        type: "gas",
        multiplier: 84,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 99,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 71,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 40,
      },
      {
        type: "perishable",
        multiplier: 97,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667598",
    connected_stations: [
      {
        station_id: "1099521667596",
        distance: 42,
      },
      {
        station_id: "1099521667600",
        distance: 20,
      },
      {
        station_id: "1099521667679",
        distance: 23,
      },
      {
        station_id: "1099521667669",
        distance: 65,
      },
      {
        station_id: "1099521667703",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 60,
      },
      {
        type: "crate",
        multiplier: 52,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 70,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 82,
      },
      {
        type: "pallet",
        multiplier: 48,
      },
      {
        type: "perishable",
        multiplier: 62,
      },
      {
        type: "top_secret",
        multiplier: 30,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667599",
    connected_stations: [
      {
        station_id: "1099521667585",
        distance: 15,
      },
      {
        station_id: "1099521667755",
        distance: 20,
      },
      {
        station_id: "1099521667703",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667600",
    connected_stations: [
      {
        station_id: "1099521667598",
        distance: 20,
      },
      {
        station_id: "1099521667717",
        distance: 17,
      },
      {
        station_id: "1099521667693",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667601",
    connected_stations: [
      {
        station_id: "1099521667594",
        distance: 46,
      },
      {
        station_id: "1099521667748",
        distance: 21,
      },
      {
        station_id: "1099521667739",
        distance: 19,
      },
      {
        station_id: "1099521667698",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667602",
    connected_stations: [
      {
        station_id: "1099521667585",
        distance: 20,
      },
      {
        station_id: "1099521667706",
        distance: 14,
      },
      {
        station_id: "1099521667711",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 92,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667603",
    connected_stations: [
      {
        station_id: "1099521667690",
        distance: 23,
      },
      {
        station_id: "1099521667697",
        distance: 17,
      },
      {
        station_id: "1099521667687",
        distance: 35,
      },
      {
        station_id: "1099521667671",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667604",
    connected_stations: [
      {
        station_id: "1099521667587",
        distance: 36,
      },
      {
        station_id: "1099521667713",
        distance: 22,
      },
      {
        station_id: "1099521667673",
        distance: 12,
      },
      {
        station_id: "1099521667746",
        distance: 30,
      },
      {
        station_id: "1099521667676",
        distance: 36,
      },
      {
        station_id: "1099521667714",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667605",
    connected_stations: [
      {
        station_id: "1099521667313",
        distance: 18,
      },
      {
        station_id: "1099521667349",
        distance: 34,
      },
      {
        station_id: "1099521667334",
        distance: 21,
      },
      {
        station_id: "1099521667607",
        distance: 18,
      },
      {
        station_id: "1099521667350",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 44,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 52,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667606",
    connected_stations: [
      {
        station_id: "1099521667313",
        distance: 19,
      },
      {
        station_id: "1099521667316",
        distance: 36,
      },
      {
        station_id: "1099521667317",
        distance: 25,
      },
      {
        station_id: "1099521667331",
        distance: 16,
      },
      {
        station_id: "1099521667340",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667607",
    connected_stations: [
      {
        station_id: "1099521667313",
        distance: 22,
      },
      {
        station_id: "1099521667317",
        distance: 19,
      },
      {
        station_id: "1099521667605",
        distance: 18,
      },
      {
        station_id: "1099521667337",
        distance: 19,
      },
      {
        station_id: "1099521667334",
        distance: 27,
      },
      {
        station_id: "1099521667343",
        distance: 22,
      },
      {
        station_id: "1099521667340",
        distance: 48,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 102,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667608",
    connected_stations: [
      {
        station_id: "1099521667340",
        distance: 19,
      },
      {
        station_id: "1099521667341",
        distance: 18,
      },
      {
        station_id: "1099521667329",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667609",
    connected_stations: [
      {
        station_id: "1099521667339",
        distance: 20,
      },
      {
        station_id: "1099521667336",
        distance: 19,
      },
      {
        station_id: "1099521667327",
        distance: 13,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 65,
      },
      {
        type: "gas",
        multiplier: 90,
      },
      {
        type: "grain",
        multiplier: 83,
      },
      {
        type: "granule",
        multiplier: 98,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 80,
      },
      {
        type: "perishable",
        multiplier: 89,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667610",
    connected_stations: [
      {
        station_id: "1099521667611",
        distance: 19,
      },
      {
        station_id: "1099521667340",
        distance: 18,
      },
      {
        station_id: "1099521667331",
        distance: 32,
      },
      {
        station_id: "1099521667410",
        distance: 39,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 94,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 87,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667611",
    connected_stations: [
      {
        station_id: "1099521667330",
        distance: 17,
      },
      {
        station_id: "1099521667346",
        distance: 17,
      },
      {
        station_id: "1099521667610",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 1,
    multiplier: 100,
  },
  {
    station_id: "1099521667612",
    connected_stations: [
      {
        station_id: "1099521667366",
        distance: 33,
      },
      {
        station_id: "1099521667428",
        distance: 20,
      },
      {
        station_id: "1099521667429",
        distance: 18,
      },
      {
        station_id: "1099521667405",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 95,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 106,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667613",
    connected_stations: [
      {
        station_id: "1099521667366",
        distance: 34,
      },
      {
        station_id: "1099521667636",
        distance: 34,
      },
      {
        station_id: "1099521667625",
        distance: 48,
      },
      {
        station_id: "1099521667619",
        distance: 16,
      },
      {
        station_id: "1099521667618",
        distance: 18,
      },
      {
        station_id: "1099521667429",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667614",
    connected_stations: [
      {
        station_id: "1099521667624",
        distance: 20,
      },
      {
        station_id: "1099521667443",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667615",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 44,
      },
      {
        station_id: "1099521667358",
        distance: 19,
      },
      {
        station_id: "1099521667623",
        distance: 20,
      },
      {
        station_id: "1099521667419",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667616",
    connected_stations: [
      {
        station_id: "1099521667360",
        distance: 28,
      },
      {
        station_id: "1099521667371",
        distance: 19,
      },
      {
        station_id: "1099521667634",
        distance: 31,
      },
      {
        station_id: "1099521667424",
        distance: 21,
      },
      {
        station_id: "1099521667442",
        distance: 20,
      },
      {
        station_id: "1099521667448",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667617",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 17,
      },
      {
        station_id: "1099521667413",
        distance: 19,
      },
      {
        station_id: "1099521667428",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 90,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667618",
    connected_stations: [
      {
        station_id: "1099521667636",
        distance: 34,
      },
      {
        station_id: "1099521667619",
        distance: 23,
      },
      {
        station_id: "1099521667613",
        distance: 18,
      },
      {
        station_id: "1099521667418",
        distance: 20,
      },
      {
        station_id: "1099521667414",
        distance: 44,
      },
      {
        station_id: "1099521667420",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667619",
    connected_stations: [
      {
        station_id: "1099521667613",
        distance: 16,
      },
      {
        station_id: "1099521667445",
        distance: 20,
      },
      {
        station_id: "1099521667432",
        distance: 33,
      },
      {
        station_id: "1099521667618",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 103,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667620",
    connected_stations: [
      {
        station_id: "1099521667409",
        distance: 27,
      },
      {
        station_id: "1099521667415",
        distance: 12,
      },
      {
        station_id: "1099521667414",
        distance: 65,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 88,
      },
      {
        type: "crate",
        multiplier: 60,
      },
      {
        type: "gas",
        multiplier: 90,
      },
      {
        type: "grain",
        multiplier: 103,
      },
      {
        type: "granule",
        multiplier: 100,
      },
      {
        type: "liquid",
        multiplier: 99,
      },
      {
        type: "ore",
        multiplier: 96,
      },
      {
        type: "oversized",
        multiplier: 81,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 77,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667621",
    connected_stations: [
      {
        station_id: "1099521667639",
        distance: 18,
      },
      {
        station_id: "1099521667634",
        distance: 36,
      },
      {
        station_id: "1099521667622",
        distance: 41,
      },
      {
        station_id: "1099521667434",
        distance: 20,
      },
      {
        station_id: "1099521667439",
        distance: 31,
      },
      {
        station_id: "1099521667427",
        distance: 43,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 107,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667622",
    connected_stations: [
      {
        station_id: "1099521667634",
        distance: 42,
      },
      {
        station_id: "1099521667631",
        distance: 47,
      },
      {
        station_id: "1099521667448",
        distance: 29,
      },
      {
        station_id: "1099521667621",
        distance: 41,
      },
      {
        station_id: "1099521667442",
        distance: 18,
      },
      {
        station_id: "1099521667403",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 97,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 101,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 99,
      },
      {
        type: "ore",
        multiplier: 94,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667623",
    connected_stations: [
      {
        station_id: "1099521667626",
        distance: 34,
      },
      {
        station_id: "1099521667422",
        distance: 20,
      },
      {
        station_id: "1099521667615",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667624",
    connected_stations: [
      {
        station_id: "1099521667363",
        distance: 20,
      },
      {
        station_id: "1099521667368",
        distance: 40,
      },
      {
        station_id: "1099521667614",
        distance: 20,
      },
      {
        station_id: "1099521667439",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667625",
    connected_stations: [
      {
        station_id: "1099521667361",
        distance: 16,
      },
      {
        station_id: "1099521667366",
        distance: 27,
      },
      {
        station_id: "1099521667636",
        distance: 25,
      },
      {
        station_id: "1099521667626",
        distance: 22,
      },
      {
        station_id: "1099521667422",
        distance: 18,
      },
      {
        station_id: "1099521667613",
        distance: 48,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667626",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 35,
      },
      {
        station_id: "1099521667359",
        distance: 76,
      },
      {
        station_id: "1099521667366",
        distance: 18,
      },
      {
        station_id: "1099521667623",
        distance: 34,
      },
      {
        station_id: "1099521667625",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667627",
    connected_stations: [
      {
        station_id: "1099521667359",
        distance: 23,
      },
      {
        station_id: "1099521667635",
        distance: 34,
      },
      {
        station_id: "1099521667431",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 103,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667628",
    connected_stations: [
      {
        station_id: "1099521667640",
        distance: 17,
      },
      {
        station_id: "1099521667631",
        distance: 19,
      },
      {
        station_id: "1099521667436",
        distance: 20,
      },
      {
        station_id: "1099521667403",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667629",
    connected_stations: [
      {
        station_id: "1099521667362",
        distance: 31,
      },
      {
        station_id: "1099521667437",
        distance: 19,
      },
      {
        station_id: "1099521667408",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667630",
    connected_stations: [
      {
        station_id: "1099521667641",
        distance: 22,
      },
      {
        station_id: "1099521667638",
        distance: 24,
      },
      {
        station_id: "1099521667437",
        distance: 16,
      },
      {
        station_id: "1099521667408",
        distance: 21,
      },
      {
        station_id: "1099521667406",
        distance: 14,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667631",
    connected_stations: [
      {
        station_id: "1099521667359",
        distance: 39,
      },
      {
        station_id: "1099521667635",
        distance: 34,
      },
      {
        station_id: "1099521667628",
        distance: 19,
      },
      {
        station_id: "1099521667431",
        distance: 25,
      },
      {
        station_id: "1099521667622",
        distance: 47,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 104,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667632",
    connected_stations: [
      {
        station_id: "1099521667362",
        distance: 41,
      },
      {
        station_id: "1099521667411",
        distance: 24,
      },
      {
        station_id: "1099521667409",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667633",
    connected_stations: [
      {
        station_id: "1099521667441",
        distance: 20,
      },
      {
        station_id: "1099521667567",
        distance: 26,
      },
      {
        station_id: "1099521667446",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 99,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 96,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667634",
    connected_stations: [
      {
        station_id: "1099521667433",
        distance: 13,
      },
      {
        station_id: "1099521667621",
        distance: 36,
      },
      {
        station_id: "1099521667423",
        distance: 23,
      },
      {
        station_id: "1099521667439",
        distance: 49,
      },
      {
        station_id: "1099521667616",
        distance: 31,
      },
      {
        station_id: "1099521667442",
        distance: 41,
      },
      {
        station_id: "1099521667622",
        distance: 42,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 63,
      },
      {
        type: "building_materials",
        multiplier: 62,
      },
      {
        type: "crate",
        multiplier: 91,
      },
      {
        type: "gas",
        multiplier: 102,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 92,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 77,
      },
      {
        type: "oversized",
        multiplier: 67,
      },
      {
        type: "pallet",
        multiplier: 70,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 45,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667635",
    connected_stations: [
      {
        station_id: "1099521667354",
        distance: 35,
      },
      {
        station_id: "1099521667436",
        distance: 19,
      },
      {
        station_id: "1099521667413",
        distance: 23,
      },
      {
        station_id: "1099521667419",
        distance: 27,
      },
      {
        station_id: "1099521667627",
        distance: 34,
      },
      {
        station_id: "1099521667631",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 101,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 98,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667636",
    connected_stations: [
      {
        station_id: "1099521667361",
        distance: 19,
      },
      {
        station_id: "1099521667369",
        distance: 20,
      },
      {
        station_id: "1099521667618",
        distance: 34,
      },
      {
        station_id: "1099521667625",
        distance: 25,
      },
      {
        station_id: "1099521667420",
        distance: 18,
      },
      {
        station_id: "1099521667613",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667637",
    connected_stations: [
      {
        station_id: "1099521667404",
        distance: 16,
      },
      {
        station_id: "1099521667435",
        distance: 12,
      },
      {
        station_id: "1099521667440",
        distance: 30,
      },
      {
        station_id: "1099521667449",
        distance: 12,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 89,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 98,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 66,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667638",
    connected_stations: [
      {
        station_id: "1099521667641",
        distance: 20,
      },
      {
        station_id: "1099521667415",
        distance: 35,
      },
      {
        station_id: "1099521667408",
        distance: 24,
      },
      {
        station_id: "1099521667414",
        distance: 49,
      },
      {
        station_id: "1099521667438",
        distance: 36,
      },
      {
        station_id: "1099521667409",
        distance: 59,
      },
      {
        station_id: "1099521667418",
        distance: 63,
      },
      {
        station_id: "1099521667630",
        distance: 24,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 85,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 68,
      },
      {
        type: "perishable",
        multiplier: 79,
      },
      {
        type: "top_secret",
        multiplier: 49,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667639",
    connected_stations: [
      {
        station_id: "1099521667363",
        distance: 17,
      },
      {
        station_id: "1099521667439",
        distance: 19,
      },
      {
        station_id: "1099521667434",
        distance: 27,
      },
      {
        station_id: "1099521667621",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667640",
    connected_stations: [
      {
        station_id: "1099521667417",
        distance: 20,
      },
      {
        station_id: "1099521667628",
        distance: 17,
      },
      {
        station_id: "1099521667403",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 76,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667641",
    connected_stations: [
      {
        station_id: "1099521667412",
        distance: 20,
      },
      {
        station_id: "1099521667630",
        distance: 22,
      },
      {
        station_id: "1099521667638",
        distance: 20,
      },
      {
        station_id: "1099521667406",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 44,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 52,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667642",
    connected_stations: [
      {
        station_id: "1099521667356",
        distance: 32,
      },
      {
        station_id: "1099521667365",
        distance: 16,
      },
      {
        station_id: "1099521667441",
        distance: 20,
      },
      {
        station_id: "1099521667430",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 78,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 82,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 89,
      },
      {
        type: "perishable",
        multiplier: 72,
      },
      {
        type: "top_secret",
        multiplier: 52,
      },
    ],
    rarity: "uncommon",
    region_id: 2,
    multiplier: 100,
  },
  {
    station_id: "1099521667643",
    connected_stations: [
      {
        station_id: "1099521667459",
        distance: 17,
      },
      {
        station_id: "1099521667649",
        distance: 16,
      },
      {
        station_id: "1099521667530",
        distance: 52,
      },
      {
        station_id: "1099521667535",
        distance: 64,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 60,
      },
      {
        type: "gas",
        multiplier: 99,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 89,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 85,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667644",
    connected_stations: [
      {
        station_id: "1099521667519",
        distance: 23,
      },
      {
        station_id: "1099521667537",
        distance: 28,
      },
      {
        station_id: "1099521667647",
        distance: 42,
      },
      {
        station_id: "1099521667534",
        distance: 19,
      },
      {
        station_id: "1099521667664",
        distance: 47,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667645",
    connected_stations: [
      {
        station_id: "1099521667522",
        distance: 21,
      },
      {
        station_id: "1099521667519",
        distance: 17,
      },
      {
        station_id: "1099521667572",
        distance: 61,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667646",
    connected_stations: [
      {
        station_id: "1099521667462",
        distance: 27,
      },
      {
        station_id: "1099521667539",
        distance: 19,
      },
      {
        station_id: "1099521667550",
        distance: 18,
      },
      {
        station_id: "1099521667531",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667647",
    connected_stations: [
      {
        station_id: "1099521667458",
        distance: 33,
      },
      {
        station_id: "1099521667644",
        distance: 42,
      },
      {
        station_id: "1099521667553",
        distance: 15,
      },
      {
        station_id: "1099521667543",
        distance: 17,
      },
      {
        station_id: "1099521667522",
        distance: 34,
      },
      {
        station_id: "1099521667649",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667648",
    connected_stations: [
      {
        station_id: "1099521667463",
        distance: 20,
      },
      {
        station_id: "1099521667565",
        distance: 19,
      },
      {
        station_id: "1099521667524",
        distance: 12,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 92,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667649",
    connected_stations: [
      {
        station_id: "1099521667458",
        distance: 43,
      },
      {
        station_id: "1099521667643",
        distance: 16,
      },
      {
        station_id: "1099521667647",
        distance: 21,
      },
      {
        station_id: "1099521667553",
        distance: 15,
      },
      {
        station_id: "1099521667533",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667650",
    connected_stations: [
      {
        station_id: "1099521667455",
        distance: 32,
      },
      {
        station_id: "1099521667462",
        distance: 41,
      },
      {
        station_id: "1099521667559",
        distance: 47,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 95,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667651",
    connected_stations: [
      {
        station_id: "1099521667406",
        distance: 20,
      },
      {
        station_id: "1099521667553",
        distance: 17,
      },
      {
        station_id: "1099521667522",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667652",
    connected_stations: [
      {
        station_id: "1099521667460",
        distance: 23,
      },
      {
        station_id: "1099521667520",
        distance: 16,
      },
      {
        station_id: "1099521667566",
        distance: 18,
      },
      {
        station_id: "1099521667523",
        distance: 30,
      },
      {
        station_id: "1099521667546",
        distance: 43,
      },
      {
        station_id: "1099521667552",
        distance: 38,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667653",
    connected_stations: [
      {
        station_id: "1099521667537",
        distance: 14,
      },
      {
        station_id: "1099521667517",
        distance: 16,
      },
      {
        station_id: "1099521667530",
        distance: 23,
      },
      {
        station_id: "1099521667577",
        distance: 31,
      },
      {
        station_id: "1099521667534",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667654",
    connected_stations: [
      {
        station_id: "1099521667457",
        distance: 20,
      },
      {
        station_id: "1099521667527",
        distance: 18,
      },
      {
        station_id: "1099521667660",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667655",
    connected_stations: [
      {
        station_id: "1099521667456",
        distance: 19,
      },
      {
        station_id: "1099521667461",
        distance: 18,
      },
      {
        station_id: "1099521667538",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 95,
      },
      {
        type: "automobile",
        multiplier: 55,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 76,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 64,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 73,
      },
      {
        type: "perishable",
        multiplier: 43,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667656",
    connected_stations: [
      {
        station_id: "1099521667659",
        distance: 15,
      },
      {
        station_id: "1099521667549",
        distance: 35,
      },
      {
        station_id: "1099521667550",
        distance: 25,
      },
      {
        station_id: "1099521667539",
        distance: 33,
      },
      {
        station_id: "1099521667536",
        distance: 33,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667657",
    connected_stations: [
      {
        station_id: "1099521667578",
        distance: 16,
      },
      {
        station_id: "1099521667563",
        distance: 23,
      },
      {
        station_id: "1099521667659",
        distance: 25,
      },
      {
        station_id: "1099521667544",
        distance: 38,
      },
      {
        station_id: "1099521667550",
        distance: 26,
      },
      {
        station_id: "1099521667540",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 77,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 70,
      },
      {
        type: "gas",
        multiplier: 86,
      },
      {
        type: "grain",
        multiplier: 103,
      },
      {
        type: "granule",
        multiplier: 86,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 64,
      },
      {
        type: "perishable",
        multiplier: 89,
      },
      {
        type: "top_secret",
        multiplier: 68,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667658",
    connected_stations: [
      {
        station_id: "1099521667464",
        distance: 19,
      },
      {
        station_id: "1099521667554",
        distance: 20,
      },
      {
        station_id: "1099521667555",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 88,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 94,
      },
      {
        type: "crate",
        multiplier: 63,
      },
      {
        type: "gas",
        multiplier: 86,
      },
      {
        type: "grain",
        multiplier: 94,
      },
      {
        type: "granule",
        multiplier: 90,
      },
      {
        type: "liquid",
        multiplier: 84,
      },
      {
        type: "ore",
        multiplier: 63,
      },
      {
        type: "oversized",
        multiplier: 102,
      },
      {
        type: "pallet",
        multiplier: 62,
      },
      {
        type: "perishable",
        multiplier: 79,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667659",
    connected_stations: [
      {
        station_id: "1099521667656",
        distance: 15,
      },
      {
        station_id: "1099521667657",
        distance: 25,
      },
      {
        station_id: "1099521667563",
        distance: 19,
      },
      {
        station_id: "1099521667550",
        distance: 20,
      },
      {
        station_id: "1099521667549",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667660",
    connected_stations: [
      {
        station_id: "1099521667457",
        distance: 23,
      },
      {
        station_id: "1099521667463",
        distance: 23,
      },
      {
        station_id: "1099521667654",
        distance: 31,
      },
      {
        station_id: "1099521667556",
        distance: 17,
      },
      {
        station_id: "1099521667562",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 96,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 95,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667661",
    connected_stations: [
      {
        station_id: "1099521667460",
        distance: 15,
      },
      {
        station_id: "1099521667348",
        distance: 23,
      },
      {
        station_id: "1099521667566",
        distance: 14,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 95,
      },
      {
        type: "automobile",
        multiplier: 74,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 86,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 79,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 83,
      },
      {
        type: "perishable",
        multiplier: 60,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667662",
    connected_stations: [
      {
        station_id: "1099521667464",
        distance: 27,
      },
      {
        station_id: "1099521667555",
        distance: 16,
      },
      {
        station_id: "1099521667518",
        distance: 15,
      },
      {
        station_id: "1099521667570",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667663",
    connected_stations: [
      {
        station_id: "1099521667569",
        distance: 14,
      },
      {
        station_id: "1099521667517",
        distance: 19,
      },
      {
        station_id: "1099521667518",
        distance: 21,
      },
      {
        station_id: "1099521667570",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 55,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 87,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 78,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 88,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667664",
    connected_stations: [
      {
        station_id: "1099521667644",
        distance: 47,
      },
      {
        station_id: "1099521667559",
        distance: 46,
      },
      {
        station_id: "1099521667572",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 100,
      },
      {
        type: "automobile",
        multiplier: 73,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 87,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 80,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 80,
      },
      {
        type: "perishable",
        multiplier: 66,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667665",
    connected_stations: [
      {
        station_id: "1099521667542",
        distance: 20,
      },
      {
        station_id: "1099521667520",
        distance: 20,
      },
      {
        station_id: "1099521667548",
        distance: 30,
      },
      {
        station_id: "1099521667523",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 73,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 89,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667666",
    connected_stations: [
      {
        station_id: "1099521667542",
        distance: 19,
      },
      {
        station_id: "1099521667560",
        distance: 18,
      },
      {
        station_id: "1099521667535",
        distance: 46,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 96,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 92,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "uncommon",
    region_id: 3,
    multiplier: 100,
  },
  {
    station_id: "1099521667667",
    connected_stations: [
      {
        station_id: "1099521667705",
        distance: 13,
      },
      {
        station_id: "1099521667721",
        distance: 29,
      },
      {
        station_id: "1099521667748",
        distance: 36,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667668",
    connected_stations: [
      {
        station_id: "1099521667752",
        distance: 15,
      },
      {
        station_id: "1099521667669",
        distance: 17,
      },
      {
        station_id: "1099521667696",
        distance: 18,
      },
      {
        station_id: "1099521667756",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667669",
    connected_stations: [
      {
        station_id: "1099521667598",
        distance: 65,
      },
      {
        station_id: "1099521667597",
        distance: 42,
      },
      {
        station_id: "1099521667668",
        distance: 17,
      },
      {
        station_id: "1099521667693",
        distance: 34,
      },
      {
        station_id: "1099521667680",
        distance: 37,
      },
      {
        station_id: "1099521667717",
        distance: 35,
      },
      {
        station_id: "1099521667689",
        distance: 26,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667670",
    connected_stations: [
      {
        station_id: "1099521667754",
        distance: 16,
      },
      {
        station_id: "1099521667728",
        distance: 16,
      },
      {
        station_id: "1099521667732",
        distance: 38,
      },
      {
        station_id: "1099521667685",
        distance: 19,
      },
      {
        station_id: "1099521667729",
        distance: 50,
      },
      {
        station_id: "1099521667694",
        distance: 68,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 55,
      },
      {
        type: "crate",
        multiplier: 96,
      },
      {
        type: "gas",
        multiplier: 80,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 78,
      },
      {
        type: "liquid",
        multiplier: 98,
      },
      {
        type: "ore",
        multiplier: 85,
      },
      {
        type: "oversized",
        multiplier: 88,
      },
      {
        type: "pallet",
        multiplier: 94,
      },
      {
        type: "perishable",
        multiplier: 95,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667671",
    connected_stations: [
      {
        station_id: "1099521667586",
        distance: 18,
      },
      {
        station_id: "1099521667603",
        distance: 20,
      },
      {
        station_id: "1099521667742",
        distance: 24,
      },
      {
        station_id: "1099521667738",
        distance: 37,
      },
      {
        station_id: "1099521667697",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 101,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 98,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667672",
    connected_stations: [
      {
        station_id: "1099521667584",
        distance: 29,
      },
      {
        station_id: "1099521667712",
        distance: 20,
      },
      {
        station_id: "1099521667716",
        distance: 20,
      },
      {
        station_id: "1099521667718",
        distance: 44,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 93,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667673",
    connected_stations: [
      {
        station_id: "1099521667604",
        distance: 12,
      },
      {
        station_id: "1099521667728",
        distance: 17,
      },
      {
        station_id: "1099521667754",
        distance: 18,
      },
      {
        station_id: "1099521667713",
        distance: 24,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 100,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 100,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667674",
    connected_stations: [
      {
        station_id: "1099521667594",
        distance: 30,
      },
      {
        station_id: "1099521667707",
        distance: 30,
      },
      {
        station_id: "1099521667720",
        distance: 29,
      },
      {
        station_id: "1099521667726",
        distance: 40,
      },
      {
        station_id: "1099521667750",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 99,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 94,
      },
      {
        type: "perishable",
        multiplier: 101,
      },
      {
        type: "top_secret",
        multiplier: 99,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667675",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 17,
      },
      {
        station_id: "1099521667678",
        distance: 20,
      },
      {
        station_id: "1099521667689",
        distance: 20,
      },
      {
        station_id: "1099521667696",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 85,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 89,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667676",
    connected_stations: [
      {
        station_id: "1099521667587",
        distance: 18,
      },
      {
        station_id: "1099521667604",
        distance: 36,
      },
      {
        station_id: "1099521667731",
        distance: 22,
      },
      {
        station_id: "1099521667759",
        distance: 16,
      },
      {
        station_id: "1099521667746",
        distance: 26,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667677",
    connected_stations: [
      {
        station_id: "1099521667593",
        distance: 25,
      },
      {
        station_id: "1099521667751",
        distance: 19,
      },
      {
        station_id: "1099521667691",
        distance: 28,
      },
      {
        station_id: "1099521667722",
        distance: 33,
      },
      {
        station_id: "1099521667727",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667678",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 51,
      },
      {
        station_id: "1099521667675",
        distance: 20,
      },
      {
        station_id: "1099521667715",
        distance: 19,
      },
      {
        station_id: "1099521667683",
        distance: 38,
      },
      {
        station_id: "1099521667724",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 98,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667679",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 54,
      },
      {
        station_id: "1099521667598",
        distance: 23,
      },
      {
        station_id: "1099521667741",
        distance: 20,
      },
      {
        station_id: "1099521667755",
        distance: 30,
      },
      {
        station_id: "1099521667693",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 102,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 101,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667680",
    connected_stations: [
      {
        station_id: "1099521667588",
        distance: 23,
      },
      {
        station_id: "1099521667592",
        distance: 27,
      },
      {
        station_id: "1099521667597",
        distance: 15,
      },
      {
        station_id: "1099521667669",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 83,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 85,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667681",
    connected_stations: [
      {
        station_id: "1099521667582",
        distance: 18,
      },
      {
        station_id: "1099521667595",
        distance: 36,
      },
      {
        station_id: "1099521667698",
        distance: 18,
      },
      {
        station_id: "1099521667702",
        distance: 44,
      },
      {
        station_id: "1099521667736",
        distance: 33,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667682",
    connected_stations: [
      {
        station_id: "1099521667545",
        distance: 47,
      },
      {
        station_id: "1099521667745",
        distance: 27,
      },
      {
        station_id: "1099521667730",
        distance: 42,
      },
      {
        station_id: "1099521667709",
        distance: 64,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 70,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667683",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 25,
      },
      {
        station_id: "1099521667678",
        distance: 38,
      },
      {
        station_id: "1099521667694",
        distance: 42,
      },
      {
        station_id: "1099521667741",
        distance: 44,
      },
      {
        station_id: "1099521667715",
        distance: 40,
      },
      {
        station_id: "1099521667738",
        distance: 32,
      },
      {
        station_id: "1099521667704",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667684",
    connected_stations: [
      {
        station_id: "1099521667582",
        distance: 30,
      },
      {
        station_id: "1099521667753",
        distance: 20,
      },
      {
        station_id: "1099521667736",
        distance: 17,
      },
      {
        station_id: "1099521667757",
        distance: 20,
      },
      {
        station_id: "1099521667749",
        distance: 45,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667685",
    connected_stations: [
      {
        station_id: "1099521667670",
        distance: 19,
      },
      {
        station_id: "1099521667733",
        distance: 18,
      },
      {
        station_id: "1099521667728",
        distance: 19,
      },
      {
        station_id: "1099521667749",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667686",
    connected_stations: [
      {
        station_id: "1099521667597",
        distance: 15,
      },
      {
        station_id: "1099521667717",
        distance: 18,
      },
      {
        station_id: "1099521667752",
        distance: 58,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 87,
      },
      {
        type: "gas",
        multiplier: 94,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 101,
      },
      {
        type: "oversized",
        multiplier: 95,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667687",
    connected_stations: [
      {
        station_id: "1099521667603",
        distance: 35,
      },
      {
        station_id: "1099521667729",
        distance: 19,
      },
      {
        station_id: "1099521667690",
        distance: 17,
      },
      {
        station_id: "1099521667694",
        distance: 39,
      },
      {
        station_id: "1099521667732",
        distance: 54,
      },
      {
        station_id: "1099521667754",
        distance: 55,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667688",
    connected_stations: [
      {
        station_id: "1099521667740",
        distance: 19,
      },
      {
        station_id: "1099521667752",
        distance: 19,
      },
      {
        station_id: "1099521667756",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667689",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 16,
      },
      {
        station_id: "1099521667669",
        distance: 26,
      },
      {
        station_id: "1099521667675",
        distance: 20,
      },
      {
        station_id: "1099521667700",
        distance: 17,
      },
      {
        station_id: "1099521667696",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667690",
    connected_stations: [
      {
        station_id: "1099521667603",
        distance: 23,
      },
      {
        station_id: "1099521667687",
        distance: 17,
      },
      {
        station_id: "1099521667697",
        distance: 15,
      },
      {
        station_id: "1099521667734",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667691",
    connected_stations: [
      {
        station_id: "1099521667677",
        distance: 28,
      },
      {
        station_id: "1099521667334",
        distance: 32,
      },
      {
        station_id: "1099521667705",
        distance: 20,
      },
      {
        station_id: "1099521667727",
        distance: 12,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667692",
    connected_stations: [
      {
        station_id: "1099521667595",
        distance: 16,
      },
      {
        station_id: "1099521667699",
        distance: 18,
      },
      {
        station_id: "1099521667721",
        distance: 23,
      },
      {
        station_id: "1099521667698",
        distance: 28,
      },
      {
        station_id: "1099521667705",
        distance: 48,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 104,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 74,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667693",
    connected_stations: [
      {
        station_id: "1099521667600",
        distance: 27,
      },
      {
        station_id: "1099521667669",
        distance: 34,
      },
      {
        station_id: "1099521667679",
        distance: 23,
      },
      {
        station_id: "1099521667700",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 100,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 73,
      },
      {
        type: "pallet",
        multiplier: 80,
      },
      {
        type: "perishable",
        multiplier: 77,
      },
      {
        type: "top_secret",
        multiplier: 59,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667694",
    connected_stations: [
      {
        station_id: "1099521667687",
        distance: 39,
      },
      {
        station_id: "1099521667670",
        distance: 68,
      },
      {
        station_id: "1099521667683",
        distance: 42,
      },
      {
        station_id: "1099521667734",
        distance: 18,
      },
      {
        station_id: "1099521667697",
        distance: 27,
      },
      {
        station_id: "1099521667738",
        distance: 24,
      },
      {
        station_id: "1099521667726",
        distance: 37,
      },
      {
        station_id: "1099521667715",
        distance: 47,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 44,
      },
      {
        type: "building_materials",
        multiplier: 64,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 61,
      },
      {
        type: "pallet",
        multiplier: 76,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 29,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667695",
    connected_stations: [
      {
        station_id: "1099521667452",
        distance: 49,
      },
      {
        station_id: "1099521667590",
        distance: 21,
      },
      {
        station_id: "1099521667730",
        distance: 21,
      },
      {
        station_id: "1099521667745",
        distance: 37,
      },
      {
        station_id: "1099521667718",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667696",
    connected_stations: [
      {
        station_id: "1099521667668",
        distance: 18,
      },
      {
        station_id: "1099521667675",
        distance: 19,
      },
      {
        station_id: "1099521667689",
        distance: 21,
      },
      {
        station_id: "1099521667724",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667697",
    connected_stations: [
      {
        station_id: "1099521667603",
        distance: 17,
      },
      {
        station_id: "1099521667671",
        distance: 31,
      },
      {
        station_id: "1099521667690",
        distance: 15,
      },
      {
        station_id: "1099521667694",
        distance: 27,
      },
      {
        station_id: "1099521667738",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667698",
    connected_stations: [
      {
        station_id: "1099521667601",
        distance: 20,
      },
      {
        station_id: "1099521667681",
        distance: 18,
      },
      {
        station_id: "1099521667692",
        distance: 28,
      },
      {
        station_id: "1099521667748",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 98,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667699",
    connected_stations: [
      {
        station_id: "1099521667591",
        distance: 30,
      },
      {
        station_id: "1099521667595",
        distance: 30,
      },
      {
        station_id: "1099521667692",
        distance: 18,
      },
      {
        station_id: "1099521667737",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 102,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667700",
    connected_stations: [
      {
        station_id: "1099521667689",
        distance: 17,
      },
      {
        station_id: "1099521667693",
        distance: 17,
      },
      {
        station_id: "1099521667741",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667701",
    connected_stations: [
      {
        station_id: "1099521667710",
        distance: 20,
      },
      {
        station_id: "1099521667726",
        distance: 20,
      },
      {
        station_id: "1099521667732",
        distance: 20,
      },
      {
        station_id: "1099521667750",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 82,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 104,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 94,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667702",
    connected_stations: [
      {
        station_id: "1099521667594",
        distance: 31,
      },
      {
        station_id: "1099521667681",
        distance: 44,
      },
      {
        station_id: "1099521667739",
        distance: 20,
      },
      {
        station_id: "1099521667708",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 101,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667703",
    connected_stations: [
      {
        station_id: "1099521667598",
        distance: 19,
      },
      {
        station_id: "1099521667599",
        distance: 23,
      },
      {
        station_id: "1099521667755",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 101,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667704",
    connected_stations: [
      {
        station_id: "1099521667596",
        distance: 35,
      },
      {
        station_id: "1099521667683",
        distance: 34,
      },
      {
        station_id: "1099521667742",
        distance: 38,
      },
      {
        station_id: "1099521667738",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 71,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 81,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 84,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667705",
    connected_stations: [
      {
        station_id: "1099521667667",
        distance: 13,
      },
      {
        station_id: "1099521667691",
        distance: 20,
      },
      {
        station_id: "1099521667692",
        distance: 48,
      },
      {
        station_id: "1099521667722",
        distance: 24,
      },
      {
        station_id: "1099521667727",
        distance: 18,
      },
      {
        station_id: "1099521667737",
        distance: 23,
      },
      {
        station_id: "1099521667725",
        distance: 54,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667706",
    connected_stations: [
      {
        station_id: "1099521667602",
        distance: 14,
      },
      {
        station_id: "1099521667758",
        distance: 15,
      },
      {
        station_id: "1099521667711",
        distance: 19,
      },
      {
        station_id: "1099521667742",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667707",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 45,
      },
      {
        station_id: "1099521667594",
        distance: 16,
      },
      {
        station_id: "1099521667674",
        distance: 30,
      },
      {
        station_id: "1099521667720",
        distance: 39,
      },
      {
        station_id: "1099521667744",
        distance: 42,
      },
      {
        station_id: "1099521667708",
        distance: 16,
      },
      {
        station_id: "1099521667712",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667708",
    connected_stations: [
      {
        station_id: "1099521667702",
        distance: 17,
      },
      {
        station_id: "1099521667707",
        distance: 16,
      },
      {
        station_id: "1099521667712",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 96,
      },
      {
        type: "building_materials",
        multiplier: 94,
      },
      {
        type: "crate",
        multiplier: 61,
      },
      {
        type: "gas",
        multiplier: 95,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 100,
      },
      {
        type: "liquid",
        multiplier: 100,
      },
      {
        type: "ore",
        multiplier: 101,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 69,
      },
      {
        type: "perishable",
        multiplier: 87,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667709",
    connected_stations: [
      {
        station_id: "1099521667590",
        distance: 19,
      },
      {
        station_id: "1099521667682",
        distance: 64,
      },
      {
        station_id: "1099521667719",
        distance: 20,
      },
      {
        station_id: "1099521667747",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 103,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 93,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667710",
    connected_stations: [
      {
        station_id: "1099521667757",
        distance: 20,
      },
      {
        station_id: "1099521667701",
        distance: 20,
      },
      {
        station_id: "1099521667733",
        distance: 35,
      },
      {
        station_id: "1099521667749",
        distance: 17,
      },
      {
        station_id: "1099521667750",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 98,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 104,
      },
      {
        type: "granule",
        multiplier: 92,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 77,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667711",
    connected_stations: [
      {
        station_id: "1099521667596",
        distance: 20,
      },
      {
        station_id: "1099521667602",
        distance: 22,
      },
      {
        station_id: "1099521667758",
        distance: 29,
      },
      {
        station_id: "1099521667755",
        distance: 20,
      },
      {
        station_id: "1099521667706",
        distance: 19,
      },
      {
        station_id: "1099521667742",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667712",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 33,
      },
      {
        station_id: "1099521667672",
        distance: 20,
      },
      {
        station_id: "1099521667707",
        distance: 29,
      },
      {
        station_id: "1099521667708",
        distance: 19,
      },
      {
        station_id: "1099521667744",
        distance: 53,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667713",
    connected_stations: [
      {
        station_id: "1099521667604",
        distance: 22,
      },
      {
        station_id: "1099521667673",
        distance: 24,
      },
      {
        station_id: "1099521667746",
        distance: 18,
      },
      {
        station_id: "1099521667728",
        distance: 20,
      },
      {
        station_id: "1099521667743",
        distance: 39,
      },
      {
        station_id: "1099521667733",
        distance: 33,
      },
      {
        station_id: "1099521667753",
        distance: 34,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 97,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 101,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 99,
      },
      {
        type: "ore",
        multiplier: 94,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667714",
    connected_stations: [
      {
        station_id: "1099521667604",
        distance: 34,
      },
      {
        station_id: "1099521667754",
        distance: 18,
      },
      {
        station_id: "1099521667735",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 100,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 100,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667715",
    connected_stations: [
      {
        station_id: "1099521667678",
        distance: 19,
      },
      {
        station_id: "1099521667683",
        distance: 40,
      },
      {
        station_id: "1099521667694",
        distance: 47,
      },
      {
        station_id: "1099521667726",
        distance: 19,
      },
      {
        station_id: "1099521667744",
        distance: 26,
      },
      {
        station_id: "1099521667738",
        distance: 58,
      },
      {
        station_id: "1099521667750",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667716",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 19,
      },
      {
        station_id: "1099521667672",
        distance: 20,
      },
      {
        station_id: "1099521667740",
        distance: 20,
      },
      {
        station_id: "1099521667747",
        distance: 19,
      },
      {
        station_id: "1099521667723",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 39,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667717",
    connected_stations: [
      {
        station_id: "1099521667600",
        distance: 17,
      },
      {
        station_id: "1099521667669",
        distance: 35,
      },
      {
        station_id: "1099521667686",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 89,
      },
      {
        type: "automobile",
        multiplier: 96,
      },
      {
        type: "building_materials",
        multiplier: 71,
      },
      {
        type: "crate",
        multiplier: 62,
      },
      {
        type: "gas",
        multiplier: 76,
      },
      {
        type: "grain",
        multiplier: 77,
      },
      {
        type: "granule",
        multiplier: 86,
      },
      {
        type: "liquid",
        multiplier: 75,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 91,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 80,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667718",
    connected_stations: [
      {
        station_id: "1099521667590",
        distance: 20,
      },
      {
        station_id: "1099521667672",
        distance: 44,
      },
      {
        station_id: "1099521667695",
        distance: 31,
      },
      {
        station_id: "1099521667723",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 84,
      },
      {
        type: "crate",
        multiplier: 104,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667719",
    connected_stations: [
      {
        station_id: "1099521667590",
        distance: 19,
      },
      {
        station_id: "1099521667709",
        distance: 20,
      },
      {
        station_id: "1099521667745",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 107,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667720",
    connected_stations: [
      {
        station_id: "1099521667582",
        distance: 15,
      },
      {
        station_id: "1099521667591",
        distance: 75,
      },
      {
        station_id: "1099521667674",
        distance: 29,
      },
      {
        station_id: "1099521667757",
        distance: 12,
      },
      {
        station_id: "1099521667707",
        distance: 39,
      },
      {
        station_id: "1099521667744",
        distance: 62,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 70,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667721",
    connected_stations: [
      {
        station_id: "1099521667667",
        distance: 29,
      },
      {
        station_id: "1099521667692",
        distance: 23,
      },
      {
        station_id: "1099521667748",
        distance: 17,
      },
      {
        station_id: "1099521667737",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 98,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667722",
    connected_stations: [
      {
        station_id: "1099521667593",
        distance: 20,
      },
      {
        station_id: "1099521667677",
        distance: 33,
      },
      {
        station_id: "1099521667705",
        distance: 24,
      },
      {
        station_id: "1099521667727",
        distance: 26,
      },
      {
        station_id: "1099521667737",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 94,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667723",
    connected_stations: [
      {
        station_id: "1099521667584",
        distance: 20,
      },
      {
        station_id: "1099521667590",
        distance: 25,
      },
      {
        station_id: "1099521667716",
        distance: 25,
      },
      {
        station_id: "1099521667718",
        distance: 18,
      },
      {
        station_id: "1099521667747",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 101,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667724",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 46,
      },
      {
        station_id: "1099521667678",
        distance: 18,
      },
      {
        station_id: "1099521667696",
        distance: 19,
      },
      {
        station_id: "1099521667756",
        distance: 23,
      },
      {
        station_id: "1099521667744",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667725",
    connected_stations: [
      {
        station_id: "1099521667760",
        distance: 43,
      },
      {
        station_id: "1099521667593",
        distance: 19,
      },
      {
        station_id: "1099521667705",
        distance: 54,
      },
      {
        station_id: "1099521667751",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 29,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 63,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 80,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 76,
      },
      {
        type: "perishable",
        multiplier: 81,
      },
      {
        type: "top_secret",
        multiplier: 64,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667726",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 61,
      },
      {
        station_id: "1099521667674",
        distance: 40,
      },
      {
        station_id: "1099521667694",
        distance: 37,
      },
      {
        station_id: "1099521667701",
        distance: 20,
      },
      {
        station_id: "1099521667715",
        distance: 19,
      },
      {
        station_id: "1099521667734",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667727",
    connected_stations: [
      {
        station_id: "1099521667677",
        distance: 19,
      },
      {
        station_id: "1099521667691",
        distance: 12,
      },
      {
        station_id: "1099521667705",
        distance: 18,
      },
      {
        station_id: "1099521667722",
        distance: 26,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667728",
    connected_stations: [
      {
        station_id: "1099521667670",
        distance: 16,
      },
      {
        station_id: "1099521667673",
        distance: 17,
      },
      {
        station_id: "1099521667685",
        distance: 19,
      },
      {
        station_id: "1099521667713",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667729",
    connected_stations: [
      {
        station_id: "1099521667687",
        distance: 19,
      },
      {
        station_id: "1099521667670",
        distance: 50,
      },
      {
        station_id: "1099521667735",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 86,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667730",
    connected_stations: [
      {
        station_id: "1099521667682",
        distance: 42,
      },
      {
        station_id: "1099521667695",
        distance: 21,
      },
      {
        station_id: "1099521667745",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 94,
      },
      {
        type: "gas",
        multiplier: 80,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 89,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 95,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667731",
    connected_stations: [
      {
        station_id: "1099521667587",
        distance: 37,
      },
      {
        station_id: "1099521667676",
        distance: 22,
      },
      {
        station_id: "1099521667775",
        distance: 38,
      },
      {
        station_id: "1099521667759",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667732",
    connected_stations: [
      {
        station_id: "1099521667687",
        distance: 54,
      },
      {
        station_id: "1099521667670",
        distance: 38,
      },
      {
        station_id: "1099521667701",
        distance: 20,
      },
      {
        station_id: "1099521667749",
        distance: 18,
      },
      {
        station_id: "1099521667734",
        distance: 20,
      },
      {
        station_id: "1099521667735",
        distance: 60,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667733",
    connected_stations: [
      {
        station_id: "1099521667685",
        distance: 18,
      },
      {
        station_id: "1099521667710",
        distance: 35,
      },
      {
        station_id: "1099521667713",
        distance: 33,
      },
      {
        station_id: "1099521667753",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 94,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667734",
    connected_stations: [
      {
        station_id: "1099521667690",
        distance: 40,
      },
      {
        station_id: "1099521667694",
        distance: 18,
      },
      {
        station_id: "1099521667726",
        distance: 27,
      },
      {
        station_id: "1099521667732",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 74,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667735",
    connected_stations: [
      {
        station_id: "1099521667754",
        distance: 28,
      },
      {
        station_id: "1099521667714",
        distance: 17,
      },
      {
        station_id: "1099521667732",
        distance: 60,
      },
      {
        station_id: "1099521667729",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 100,
      },
      {
        type: "automobile",
        multiplier: 79,
      },
      {
        type: "building_materials",
        multiplier: 78,
      },
      {
        type: "crate",
        multiplier: 73,
      },
      {
        type: "gas",
        multiplier: 91,
      },
      {
        type: "grain",
        multiplier: 96,
      },
      {
        type: "granule",
        multiplier: 89,
      },
      {
        type: "liquid",
        multiplier: 94,
      },
      {
        type: "ore",
        multiplier: 94,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 71,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 78,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667736",
    connected_stations: [
      {
        station_id: "1099521667595",
        distance: 24,
      },
      {
        station_id: "1099521667681",
        distance: 33,
      },
      {
        station_id: "1099521667684",
        distance: 17,
      },
      {
        station_id: "1099521667743",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 83,
      },
      {
        type: "crate",
        multiplier: 101,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 102,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 83,
      },
      {
        type: "oversized",
        multiplier: 100,
      },
      {
        type: "pallet",
        multiplier: 98,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667737",
    connected_stations: [
      {
        station_id: "1099521667699",
        distance: 17,
      },
      {
        station_id: "1099521667705",
        distance: 23,
      },
      {
        station_id: "1099521667722",
        distance: 20,
      },
      {
        station_id: "1099521667721",
        distance: 27,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 99,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 83,
      },
      {
        type: "gas",
        multiplier: 100,
      },
      {
        type: "grain",
        multiplier: 85,
      },
      {
        type: "granule",
        multiplier: 103,
      },
      {
        type: "liquid",
        multiplier: 103,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 102,
      },
      {
        type: "pallet",
        multiplier: 95,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667738",
    connected_stations: [
      {
        station_id: "1099521667671",
        distance: 37,
      },
      {
        station_id: "1099521667683",
        distance: 32,
      },
      {
        station_id: "1099521667694",
        distance: 24,
      },
      {
        station_id: "1099521667697",
        distance: 19,
      },
      {
        station_id: "1099521667704",
        distance: 25,
      },
      {
        station_id: "1099521667715",
        distance: 58,
      },
      {
        station_id: "1099521667741",
        distance: 56,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667739",
    connected_stations: [
      {
        station_id: "1099521667582",
        distance: 43,
      },
      {
        station_id: "1099521667601",
        distance: 19,
      },
      {
        station_id: "1099521667702",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 97,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667740",
    connected_stations: [
      {
        station_id: "1099521667688",
        distance: 19,
      },
      {
        station_id: "1099521667756",
        distance: 17,
      },
      {
        station_id: "1099521667716",
        distance: 20,
      },
      {
        station_id: "1099521667747",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667741",
    connected_stations: [
      {
        station_id: "1099521667583",
        distance: 45,
      },
      {
        station_id: "1099521667596",
        distance: 17,
      },
      {
        station_id: "1099521667679",
        distance: 20,
      },
      {
        station_id: "1099521667683",
        distance: 44,
      },
      {
        station_id: "1099521667700",
        distance: 37,
      },
      {
        station_id: "1099521667738",
        distance: 56,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 44,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 51,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667742",
    connected_stations: [
      {
        station_id: "1099521667586",
        distance: 17,
      },
      {
        station_id: "1099521667671",
        distance: 24,
      },
      {
        station_id: "1099521667758",
        distance: 19,
      },
      {
        station_id: "1099521667704",
        distance: 38,
      },
      {
        station_id: "1099521667706",
        distance: 27,
      },
      {
        station_id: "1099521667711",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 52,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 37,
      },
      {
        type: "gas",
        multiplier: 85,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 70,
      },
      {
        type: "ore",
        multiplier: 84,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 67,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 58,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667743",
    connected_stations: [
      {
        station_id: "1099521667591",
        distance: 31,
      },
      {
        station_id: "1099521667595",
        distance: 32,
      },
      {
        station_id: "1099521667713",
        distance: 39,
      },
      {
        station_id: "1099521667753",
        distance: 19,
      },
      {
        station_id: "1099521667746",
        distance: 40,
      },
      {
        station_id: "1099521667736",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667744",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 35,
      },
      {
        station_id: "1099521667707",
        distance: 42,
      },
      {
        station_id: "1099521667712",
        distance: 53,
      },
      {
        station_id: "1099521667715",
        distance: 26,
      },
      {
        station_id: "1099521667720",
        distance: 62,
      },
      {
        station_id: "1099521667724",
        distance: 40,
      },
      {
        station_id: "1099521667752",
        distance: 73,
      },
      {
        station_id: "1099521667750",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 81,
      },
      {
        type: "gas",
        multiplier: 95,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 95,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667745",
    connected_stations: [
      {
        station_id: "1099521667682",
        distance: 27,
      },
      {
        station_id: "1099521667695",
        distance: 37,
      },
      {
        station_id: "1099521667719",
        distance: 19,
      },
      {
        station_id: "1099521667730",
        distance: 21,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667746",
    connected_stations: [
      {
        station_id: "1099521667604",
        distance: 30,
      },
      {
        station_id: "1099521667676",
        distance: 26,
      },
      {
        station_id: "1099521667759",
        distance: 18,
      },
      {
        station_id: "1099521667713",
        distance: 18,
      },
      {
        station_id: "1099521667743",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 106,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667747",
    connected_stations: [
      {
        station_id: "1099521667709",
        distance: 19,
      },
      {
        station_id: "1099521667716",
        distance: 19,
      },
      {
        station_id: "1099521667723",
        distance: 20,
      },
      {
        station_id: "1099521667740",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 79,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 98,
      },
      {
        type: "pallet",
        multiplier: 102,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667748",
    connected_stations: [
      {
        station_id: "1099521667601",
        distance: 21,
      },
      {
        station_id: "1099521667667",
        distance: 36,
      },
      {
        station_id: "1099521667698",
        distance: 22,
      },
      {
        station_id: "1099521667721",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 92,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667749",
    connected_stations: [
      {
        station_id: "1099521667684",
        distance: 45,
      },
      {
        station_id: "1099521667685",
        distance: 20,
      },
      {
        station_id: "1099521667710",
        distance: 17,
      },
      {
        station_id: "1099521667732",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 95,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 72,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667750",
    connected_stations: [
      {
        station_id: "1099521667674",
        distance: 19,
      },
      {
        station_id: "1099521667701",
        distance: 28,
      },
      {
        station_id: "1099521667710",
        distance: 34,
      },
      {
        station_id: "1099521667715",
        distance: 29,
      },
      {
        station_id: "1099521667744",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 85,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 73,
      },
      {
        type: "top_secret",
        multiplier: 52,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667751",
    connected_stations: [
      {
        station_id: "1099521667677",
        distance: 19,
      },
      {
        station_id: "1099521667443",
        distance: 31,
      },
      {
        station_id: "1099521667725",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 100,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667752",
    connected_stations: [
      {
        station_id: "1099521667592",
        distance: 21,
      },
      {
        station_id: "1099521667668",
        distance: 15,
      },
      {
        station_id: "1099521667686",
        distance: 58,
      },
      {
        station_id: "1099521667688",
        distance: 19,
      },
      {
        station_id: "1099521667744",
        distance: 73,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667753",
    connected_stations: [
      {
        station_id: "1099521667684",
        distance: 20,
      },
      {
        station_id: "1099521667713",
        distance: 34,
      },
      {
        station_id: "1099521667743",
        distance: 19,
      },
      {
        station_id: "1099521667733",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 76,
      },
      {
        type: "crate",
        multiplier: 81,
      },
      {
        type: "gas",
        multiplier: 96,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 100,
      },
      {
        type: "ore",
        multiplier: 75,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 79,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667754",
    connected_stations: [
      {
        station_id: "1099521667687",
        distance: 55,
      },
      {
        station_id: "1099521667670",
        distance: 16,
      },
      {
        station_id: "1099521667673",
        distance: 18,
      },
      {
        station_id: "1099521667714",
        distance: 18,
      },
      {
        station_id: "1099521667735",
        distance: 28,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 97,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667755",
    connected_stations: [
      {
        station_id: "1099521667596",
        distance: 19,
      },
      {
        station_id: "1099521667599",
        distance: 20,
      },
      {
        station_id: "1099521667679",
        distance: 30,
      },
      {
        station_id: "1099521667703",
        distance: 18,
      },
      {
        station_id: "1099521667711",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 85,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 44,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 52,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667756",
    connected_stations: [
      {
        station_id: "1099521667589",
        distance: 31,
      },
      {
        station_id: "1099521667668",
        distance: 40,
      },
      {
        station_id: "1099521667688",
        distance: 20,
      },
      {
        station_id: "1099521667740",
        distance: 17,
      },
      {
        station_id: "1099521667724",
        distance: 23,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667757",
    connected_stations: [
      {
        station_id: "1099521667684",
        distance: 20,
      },
      {
        station_id: "1099521667720",
        distance: 12,
      },
      {
        station_id: "1099521667710",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667758",
    connected_stations: [
      {
        station_id: "1099521667586",
        distance: 28,
      },
      {
        station_id: "1099521667742",
        distance: 19,
      },
      {
        station_id: "1099521667706",
        distance: 15,
      },
      {
        station_id: "1099521667711",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 107,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667759",
    connected_stations: [
      {
        station_id: "1099521667676",
        distance: 16,
      },
      {
        station_id: "1099521667746",
        distance: 18,
      },
      {
        station_id: "1099521667731",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 4,
    multiplier: 100,
  },
  {
    station_id: "1099521667760",
    connected_stations: [
      {
        station_id: "1099521667803",
        distance: 19,
      },
      {
        station_id: "1099521667779",
        distance: 20,
      },
      {
        station_id: "1099521667725",
        distance: 43,
      },
      {
        station_id: "1099521667788",
        distance: 38,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 103,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "mythic",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667761",
    connected_stations: [
      {
        station_id: "1099521667795",
        distance: 17,
      },
      {
        station_id: "1099521667796",
        distance: 20,
      },
      {
        station_id: "1099521667786",
        distance: 22,
      },
      {
        station_id: "1099521667770",
        distance: 25,
      },
      {
        station_id: "1099521667783",
        distance: 34,
      },
      {
        station_id: "1099521667764",
        distance: 22,
      },
      {
        station_id: "1099521667765",
        distance: 59,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 104,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 107,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "legendary",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667762",
    connected_stations: [
      {
        station_id: "1099521667794",
        distance: 27,
      },
      {
        station_id: "1099521667774",
        distance: 19,
      },
      {
        station_id: "1099521667766",
        distance: 56,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 103,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 77,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667763",
    connected_stations: [
      {
        station_id: "1099521667774",
        distance: 20,
      },
      {
        station_id: "1099521667782",
        distance: 20,
      },
      {
        station_id: "1099521667792",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "epic",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667764",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 22,
      },
      {
        station_id: "1099521667801",
        distance: 18,
      },
      {
        station_id: "1099521667795",
        distance: 20,
      },
      {
        station_id: "1099521667796",
        distance: 25,
      },
      {
        station_id: "1099521667787",
        distance: 23,
      },
      {
        station_id: "1099521667791",
        distance: 17,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667765",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 59,
      },
      {
        station_id: "1099521667784",
        distance: 20,
      },
      {
        station_id: "1099521667771",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 105,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667766",
    connected_stations: [
      {
        station_id: "1099521667762",
        distance: 56,
      },
      {
        station_id: "1099521667799",
        distance: 19,
      },
      {
        station_id: "1099521667798",
        distance: 17,
      },
      {
        station_id: "1099521667776",
        distance: 20,
      },
      {
        station_id: "1099521667785",
        distance: 33,
      },
      {
        station_id: "1099521667777",
        distance: 33,
      },
      {
        station_id: "1099521667797",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "rare",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667767",
    connected_stations: [
      {
        station_id: "1099521667789",
        distance: 20,
      },
      {
        station_id: "1099521667780",
        distance: 20,
      },
      {
        station_id: "1099521667802",
        distance: 26,
      },
      {
        station_id: "1099521667793",
        distance: 35,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667768",
    connected_stations: [
      {
        station_id: "1099521667773",
        distance: 15,
      },
      {
        station_id: "1099521667803",
        distance: 23,
      },
      {
        station_id: "1099521667772",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 107,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 97,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667769",
    connected_stations: [
      {
        station_id: "1099521667771",
        distance: 19,
      },
      {
        station_id: "1099521667786",
        distance: 19,
      },
      {
        station_id: "1099521667796",
        distance: 31,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 70,
      },
      {
        type: "building_materials",
        multiplier: 69,
      },
      {
        type: "crate",
        multiplier: 78,
      },
      {
        type: "gas",
        multiplier: 99,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 67,
      },
      {
        type: "ore",
        multiplier: 81,
      },
      {
        type: "oversized",
        multiplier: 95,
      },
      {
        type: "pallet",
        multiplier: 82,
      },
      {
        type: "perishable",
        multiplier: 89,
      },
      {
        type: "top_secret",
        multiplier: 51,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667770",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 25,
      },
      {
        station_id: "1099521667786",
        distance: 19,
      },
      {
        station_id: "1099521667780",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 106,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667771",
    connected_stations: [
      {
        station_id: "1099521667765",
        distance: 19,
      },
      {
        station_id: "1099521667769",
        distance: 19,
      },
      {
        station_id: "1099521667787",
        distance: 55,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 103,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 105,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667772",
    connected_stations: [
      {
        station_id: "1099521667768",
        distance: 18,
      },
      {
        station_id: "1099521667788",
        distance: 20,
      },
      {
        station_id: "1099521667778",
        distance: 20,
      },
      {
        station_id: "1099521667779",
        distance: 42,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667773",
    connected_stations: [
      {
        station_id: "1099521667768",
        distance: 15,
      },
      {
        station_id: "1099521667778",
        distance: 20,
      },
      {
        station_id: "1099521667791",
        distance: 61,
      },
      {
        station_id: "1099521667789",
        distance: 64,
      },
      {
        station_id: "1099521667802",
        distance: 29,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 78,
      },
      {
        type: "building_materials",
        multiplier: 84,
      },
      {
        type: "crate",
        multiplier: 91,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 102,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 104,
      },
      {
        type: "pallet",
        multiplier: 85,
      },
      {
        type: "perishable",
        multiplier: 99,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667774",
    connected_stations: [
      {
        station_id: "1099521667762",
        distance: 19,
      },
      {
        station_id: "1099521667763",
        distance: 20,
      },
      {
        station_id: "1099521667785",
        distance: 39,
      },
      {
        station_id: "1099521667798",
        distance: 40,
      },
      {
        station_id: "1099521667784",
        distance: 70,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 37,
      },
      {
        type: "building_materials",
        multiplier: 55,
      },
      {
        type: "crate",
        multiplier: 35,
      },
      {
        type: "gas",
        multiplier: 81,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 92,
      },
      {
        type: "liquid",
        multiplier: 72,
      },
      {
        type: "ore",
        multiplier: 66,
      },
      {
        type: "oversized",
        multiplier: 67,
      },
      {
        type: "pallet",
        multiplier: 47,
      },
      {
        type: "perishable",
        multiplier: 96,
      },
      {
        type: "top_secret",
        multiplier: 32,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667775",
    connected_stations: [
      {
        station_id: "1099521667777",
        distance: 18,
      },
      {
        station_id: "1099521667794",
        distance: 28,
      },
      {
        station_id: "1099521667785",
        distance: 27,
      },
      {
        station_id: "1099521667731",
        distance: 38,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 86,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 83,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667776",
    connected_stations: [
      {
        station_id: "1099521667766",
        distance: 20,
      },
      {
        station_id: "1099521667790",
        distance: 30,
      },
      {
        station_id: "1099521667800",
        distance: 19,
      },
      {
        station_id: "1099521667779",
        distance: 20,
      },
      {
        station_id: "1099521667799",
        distance: 18,
      },
      {
        station_id: "1099521667791",
        distance: 45,
      },
      {
        station_id: "1099521667798",
        distance: 25,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 101,
      },
      {
        type: "automobile",
        multiplier: 87,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 104,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 105,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 73,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 59,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667777",
    connected_stations: [
      {
        station_id: "1099521667766",
        distance: 33,
      },
      {
        station_id: "1099521667775",
        distance: 18,
      },
      {
        station_id: "1099521667797",
        distance: 20,
      },
      {
        station_id: "1099521667785",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 95,
      },
      {
        type: "automobile",
        multiplier: 104,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 89,
      },
      {
        type: "gas",
        multiplier: 91,
      },
      {
        type: "grain",
        multiplier: 100,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 88,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 89,
      },
      {
        type: "perishable",
        multiplier: 84,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667778",
    connected_stations: [
      {
        station_id: "1099521667772",
        distance: 20,
      },
      {
        station_id: "1099521667773",
        distance: 20,
      },
      {
        station_id: "1099521667783",
        distance: 26,
      },
      {
        station_id: "1099521667795",
        distance: 22,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "uncommon",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667779",
    connected_stations: [
      {
        station_id: "1099521667760",
        distance: 20,
      },
      {
        station_id: "1099521667772",
        distance: 42,
      },
      {
        station_id: "1099521667776",
        distance: 20,
      },
      {
        station_id: "1099521667790",
        distance: 20,
      },
      {
        station_id: "1099521667800",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 49,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 100,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 73,
      },
      {
        type: "pallet",
        multiplier: 82,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 40,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667780",
    connected_stations: [
      {
        station_id: "1099521667767",
        distance: 20,
      },
      {
        station_id: "1099521667770",
        distance: 16,
      },
      {
        station_id: "1099521667783",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 99,
      },
      {
        type: "building_materials",
        multiplier: 105,
      },
      {
        type: "crate",
        multiplier: 103,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 103,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667781",
    connected_stations: [
      {
        station_id: "1099521667787",
        distance: 19,
      },
      {
        station_id: "1099521667782",
        distance: 19,
      },
      {
        station_id: "1099521667784",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 103,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 100,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667782",
    connected_stations: [
      {
        station_id: "1099521667763",
        distance: 20,
      },
      {
        station_id: "1099521667781",
        distance: 19,
      },
      {
        station_id: "1099521667801",
        distance: 24,
      },
      {
        station_id: "1099521667798",
        distance: 41,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 102,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 101,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 104,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667783",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 34,
      },
      {
        station_id: "1099521667778",
        distance: 26,
      },
      {
        station_id: "1099521667802",
        distance: 18,
      },
      {
        station_id: "1099521667780",
        distance: 16,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667784",
    connected_stations: [
      {
        station_id: "1099521667765",
        distance: 20,
      },
      {
        station_id: "1099521667774",
        distance: 70,
      },
      {
        station_id: "1099521667781",
        distance: 20,
      },
      {
        station_id: "1099521667796",
        distance: 37,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 74,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 88,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 83,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 66,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667785",
    connected_stations: [
      {
        station_id: "1099521667766",
        distance: 33,
      },
      {
        station_id: "1099521667774",
        distance: 39,
      },
      {
        station_id: "1099521667775",
        distance: 27,
      },
      {
        station_id: "1099521667777",
        distance: 20,
      },
      {
        station_id: "1099521667794",
        distance: 15,
      },
      {
        station_id: "1099521667798",
        distance: 41,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 97,
      },
      {
        type: "gas",
        multiplier: 98,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 95,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 87,
      },
      {
        type: "perishable",
        multiplier: 90,
      },
      {
        type: "top_secret",
        multiplier: 69,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667786",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 22,
      },
      {
        station_id: "1099521667769",
        distance: 19,
      },
      {
        station_id: "1099521667770",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 85,
      },
      {
        type: "building_materials",
        multiplier: 79,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 105,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 102,
      },
      {
        type: "liquid",
        multiplier: 106,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 70,
      },
      {
        type: "pallet",
        multiplier: 78,
      },
      {
        type: "perishable",
        multiplier: 92,
      },
      {
        type: "top_secret",
        multiplier: 59,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667787",
    connected_stations: [
      {
        station_id: "1099521667764",
        distance: 23,
      },
      {
        station_id: "1099521667771",
        distance: 55,
      },
      {
        station_id: "1099521667781",
        distance: 19,
      },
      {
        station_id: "1099521667796",
        distance: 20,
      },
      {
        station_id: "1099521667801",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 93,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667788",
    connected_stations: [
      {
        station_id: "1099521667760",
        distance: 38,
      },
      {
        station_id: "1099521667772",
        distance: 20,
      },
      {
        station_id: "1099521667790",
        distance: 13,
      },
      {
        station_id: "1099521667795",
        distance: 30,
      },
      {
        station_id: "1099521667791",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 101,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667789",
    connected_stations: [
      {
        station_id: "1099521667371",
        distance: 78,
      },
      {
        station_id: "1099521667767",
        distance: 20,
      },
      {
        station_id: "1099521667773",
        distance: 64,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 108,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667790",
    connected_stations: [
      {
        station_id: "1099521667776",
        distance: 30,
      },
      {
        station_id: "1099521667803",
        distance: 32,
      },
      {
        station_id: "1099521667779",
        distance: 20,
      },
      {
        station_id: "1099521667788",
        distance: 13,
      },
      {
        station_id: "1099521667798",
        distance: 40,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 109,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667791",
    connected_stations: [
      {
        station_id: "1099521667764",
        distance: 17,
      },
      {
        station_id: "1099521667773",
        distance: 61,
      },
      {
        station_id: "1099521667776",
        distance: 45,
      },
      {
        station_id: "1099521667788",
        distance: 20,
      },
      {
        station_id: "1099521667801",
        distance: 14,
      },
      {
        station_id: "1099521667792",
        distance: 22,
      },
      {
        station_id: "1099521667797",
        distance: 76,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 105,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 93,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 97,
      },
      {
        type: "pallet",
        multiplier: 99,
      },
      {
        type: "perishable",
        multiplier: 102,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667792",
    connected_stations: [
      {
        station_id: "1099521667763",
        distance: 31,
      },
      {
        station_id: "1099521667791",
        distance: 22,
      },
      {
        station_id: "1099521667798",
        distance: 18,
      },
      {
        station_id: "1099521667801",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 88,
      },
      {
        type: "building_materials",
        multiplier: 96,
      },
      {
        type: "crate",
        multiplier: 85,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 96,
      },
      {
        type: "pallet",
        multiplier: 71,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 91,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667793",
    connected_stations: [
      {
        station_id: "1099521667767",
        distance: 35,
      },
      {
        station_id: "1099521667802",
        distance: 20,
      },
      {
        station_id: "1099521667444",
        distance: 51,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 107,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 107,
      },
      {
        type: "crate",
        multiplier: 107,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 100,
      },
      {
        type: "ore",
        multiplier: 92,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 106,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667794",
    connected_stations: [
      {
        station_id: "1099521667762",
        distance: 27,
      },
      {
        station_id: "1099521667775",
        distance: 28,
      },
      {
        station_id: "1099521667785",
        distance: 15,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 104,
      },
      {
        type: "automobile",
        multiplier: 102,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 102,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 107,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 96,
      },
      {
        type: "perishable",
        multiplier: 108,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667795",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 17,
      },
      {
        station_id: "1099521667764",
        distance: 20,
      },
      {
        station_id: "1099521667778",
        distance: 22,
      },
      {
        station_id: "1099521667788",
        distance: 30,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 90,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 98,
      },
      {
        type: "gas",
        multiplier: 88,
      },
      {
        type: "grain",
        multiplier: 81,
      },
      {
        type: "granule",
        multiplier: 108,
      },
      {
        type: "liquid",
        multiplier: 97,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 77,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667796",
    connected_stations: [
      {
        station_id: "1099521667761",
        distance: 20,
      },
      {
        station_id: "1099521667764",
        distance: 25,
      },
      {
        station_id: "1099521667769",
        distance: 31,
      },
      {
        station_id: "1099521667784",
        distance: 37,
      },
      {
        station_id: "1099521667787",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 105,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 107,
      },
      {
        type: "pallet",
        multiplier: 109,
      },
      {
        type: "perishable",
        multiplier: 109,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667797",
    connected_stations: [
      {
        station_id: "1099521667591",
        distance: 64,
      },
      {
        station_id: "1099521667766",
        distance: 25,
      },
      {
        station_id: "1099521667777",
        distance: 20,
      },
      {
        station_id: "1099521667791",
        distance: 76,
      },
      {
        station_id: "1099521667799",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 96,
      },
      {
        type: "automobile",
        multiplier: 101,
      },
      {
        type: "building_materials",
        multiplier: 97,
      },
      {
        type: "crate",
        multiplier: 94,
      },
      {
        type: "gas",
        multiplier: 80,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 101,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 90,
      },
      {
        type: "perishable",
        multiplier: 97,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667798",
    connected_stations: [
      {
        station_id: "1099521667766",
        distance: 17,
      },
      {
        station_id: "1099521667774",
        distance: 40,
      },
      {
        station_id: "1099521667776",
        distance: 25,
      },
      {
        station_id: "1099521667782",
        distance: 41,
      },
      {
        station_id: "1099521667785",
        distance: 41,
      },
      {
        station_id: "1099521667790",
        distance: 40,
      },
      {
        station_id: "1099521667792",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 96,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 99,
      },
      {
        type: "gas",
        multiplier: 107,
      },
      {
        type: "grain",
        multiplier: 106,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 105,
      },
      {
        type: "ore",
        multiplier: 105,
      },
      {
        type: "oversized",
        multiplier: 93,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 103,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667799",
    connected_stations: [
      {
        station_id: "1099521667766",
        distance: 19,
      },
      {
        station_id: "1099521667776",
        distance: 18,
      },
      {
        station_id: "1099521667800",
        distance: 20,
      },
      {
        station_id: "1099521667797",
        distance: 18,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 103,
      },
      {
        type: "automobile",
        multiplier: 108,
      },
      {
        type: "building_materials",
        multiplier: 84,
      },
      {
        type: "crate",
        multiplier: 80,
      },
      {
        type: "gas",
        multiplier: 97,
      },
      {
        type: "grain",
        multiplier: 84,
      },
      {
        type: "granule",
        multiplier: 105,
      },
      {
        type: "liquid",
        multiplier: 93,
      },
      {
        type: "ore",
        multiplier: 99,
      },
      {
        type: "oversized",
        multiplier: 90,
      },
      {
        type: "pallet",
        multiplier: 84,
      },
      {
        type: "perishable",
        multiplier: 75,
      },
      {
        type: "top_secret",
        multiplier: 70,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667800",
    connected_stations: [
      {
        station_id: "1099521667776",
        distance: 19,
      },
      {
        station_id: "1099521667779",
        distance: 19,
      },
      {
        station_id: "1099521667799",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 109,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 108,
      },
      {
        type: "gas",
        multiplier: 108,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 109,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 106,
      },
      {
        type: "perishable",
        multiplier: 105,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667801",
    connected_stations: [
      {
        station_id: "1099521667764",
        distance: 18,
      },
      {
        station_id: "1099521667782",
        distance: 24,
      },
      {
        station_id: "1099521667787",
        distance: 19,
      },
      {
        station_id: "1099521667791",
        distance: 14,
      },
      {
        station_id: "1099521667792",
        distance: 19,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 108,
      },
      {
        type: "automobile",
        multiplier: 78,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 92,
      },
      {
        type: "gas",
        multiplier: 106,
      },
      {
        type: "grain",
        multiplier: 109,
      },
      {
        type: "granule",
        multiplier: 94,
      },
      {
        type: "liquid",
        multiplier: 109,
      },
      {
        type: "ore",
        multiplier: 96,
      },
      {
        type: "oversized",
        multiplier: 109,
      },
      {
        type: "pallet",
        multiplier: 93,
      },
      {
        type: "perishable",
        multiplier: 65,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667802",
    connected_stations: [
      {
        station_id: "1099521667767",
        distance: 26,
      },
      {
        station_id: "1099521667773",
        distance: 29,
      },
      {
        station_id: "1099521667783",
        distance: 18,
      },
      {
        station_id: "1099521667793",
        distance: 20,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 106,
      },
      {
        type: "automobile",
        multiplier: 97,
      },
      {
        type: "building_materials",
        multiplier: 109,
      },
      {
        type: "crate",
        multiplier: 101,
      },
      {
        type: "gas",
        multiplier: 101,
      },
      {
        type: "grain",
        multiplier: 108,
      },
      {
        type: "granule",
        multiplier: 106,
      },
      {
        type: "liquid",
        multiplier: 104,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 105,
      },
      {
        type: "pallet",
        multiplier: 104,
      },
      {
        type: "perishable",
        multiplier: 91,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
  {
    station_id: "1099521667803",
    connected_stations: [
      {
        station_id: "1099521667760",
        distance: 19,
      },
      {
        station_id: "1099521667768",
        distance: 23,
      },
      {
        station_id: "1099521667790",
        distance: 32,
      },
    ],
    type_rates: [
      {
        type: "aggregate",
        multiplier: 109,
      },
      {
        type: "automobile",
        multiplier: 94,
      },
      {
        type: "building_materials",
        multiplier: 99,
      },
      {
        type: "crate",
        multiplier: 81,
      },
      {
        type: "gas",
        multiplier: 109,
      },
      {
        type: "grain",
        multiplier: 107,
      },
      {
        type: "granule",
        multiplier: 107,
      },
      {
        type: "liquid",
        multiplier: 108,
      },
      {
        type: "ore",
        multiplier: 109,
      },
      {
        type: "oversized",
        multiplier: 99,
      },
      {
        type: "pallet",
        multiplier: 107,
      },
      {
        type: "perishable",
        multiplier: 107,
      },
      {
        type: "top_secret",
        multiplier: 109,
      },
    ],
    rarity: "common",
    region_id: 5,
    multiplier: 100,
  },
];

export const ContractStationsData_S = [
  {
    asset_id: "1099521667313",
    rarity: "mythic",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Winter's Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667314",
    rarity: "legendary",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Fury Landing",
    billboard: "QmRucGzDNSfP5XpPoh3Qx3efrndmJP5tYosZNGS3tFpjzF",
    name_change_count: 0,
    bb_change_count: 2,
    last_name_change: 0,
    last_bb_change: 1650456804,
    railyard_tier: 3,
    lounge_tier: 1,
    railyard_comm: 10,
    lounge_comm: 0,
    railyard_comm_vip: 5,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667315",
    rarity: "epic",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ebon Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667316",
    rarity: "rare",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dark Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667317",
    rarity: "rare",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starlight Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667318",
    rarity: "rare",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Gloom Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667319",
    rarity: "rare",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Cinder Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667327",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dark Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667328",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Third Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667329",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ebon Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667330",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Whitekeep Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667331",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Scarlet Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667332",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Iron Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667333",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dark Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667334",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Garden Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667335",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Venom Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667336",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Talon Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667337",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sorrow Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667338",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Terror Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667339",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Triumph Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667340",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain-Foot Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667341",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forsaken Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667342",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Boulder Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667343",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Azure Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667344",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eco-Dome Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667345",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Nightmare Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667346",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusk Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667347",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunderstorm Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667348",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forward Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667349",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "River Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667350",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twilight Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667351",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Watcher's Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667352",
    rarity: "common",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marshal Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667354",
    rarity: "mythic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Spring's Park",
    billboard: "QmVYhHEDFQGLb27ZxnRD15cyDy9TfMT8qbKCmerjmxcvs1",
    name_change_count: 0,
    bb_change_count: 2,
    last_name_change: 0,
    last_bb_change: 1644621225,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667355",
    rarity: "legendary",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Iron Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667356",
    rarity: "legendary",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dire Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 4,
    lounge_tier: 3,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667357",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Terror Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667358",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sea Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667359",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summer's Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 2,
    lounge_tier: 4,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667360",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Burning Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667361",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hunter Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667362",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Phantom Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667363",
    rarity: "epic",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Light's Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667364",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starlight Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667365",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Victor Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667366",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Anvilcross Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667367",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lake Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667368",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oasis Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667369",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Phantom Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667370",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Solitude Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667371",
    rarity: "rare",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ember Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667403",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Bone Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667404",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Third Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667405",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sabremill Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667406",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vortex Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667407",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Razor Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667408",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Venom Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667409",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Winter's Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667410",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wildling Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667411",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Solitude Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667412",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Pinnacle Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667413",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forest Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667414",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vendetta Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667415",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lagoon Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667416",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Blood Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667417",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dire Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667418",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crossroad Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667419",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Winter's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667420",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Anvilcross Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667421",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ocean Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667422",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Fury Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667423",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dire Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667424",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eco-Dome Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667425",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain-Foot Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667426",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Honor Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667427",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Obsidian Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667428",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Writhing Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667429",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eclipse Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667430",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eternal Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667431",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dreamer's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667432",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Seabreeze Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667433",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Cinder Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667434",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Third Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667435",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Bone Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667436",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summer's Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667437",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valor Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667438",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Canyon Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667439",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Shadow Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667440",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vortex Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667441",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Doom Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667442",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crown Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667443",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sorrow Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667444",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Blood Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667445",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Barren Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667446",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Bone Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667447",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Desolation Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667448",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Raven Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667449",
    rarity: "common",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forest Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667450",
    rarity: "mythic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Echo Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667451",
    rarity: "legendary",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Desolation Gardens",
    billboard: "QmPHRASxdm5GpMLBBMjQXrEpP1VXyB7LQQQrWcMKk9TWYC",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1644608080,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667452",
    rarity: "legendary",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Death Landing",
    billboard:
      "QmbYzvFYM4o19NHibr5DkdJvXtL78G8wP87fcWD4n6k32XQmbYzvFYM4o19NHibr5DkdJvXtL78G8wP87fcWD4n6k32XQmbYzvFYM4o19NHibr5DkdJvXtL78G8wP87fcWD4n6k32XQmbYzvFYM4o19NHibr5DkdJvXtL78G8wP87fcWD4n6k32XQmbYzvFYM4o19NHibr5DkdJvXtL78G8wP87fcWD4n6k32XQmbYzvFYM4o19Nasfafaffadd",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1644602967,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 10,
    lounge_comm: 0,
    railyard_comm_vip: 30,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667453",
    rarity: "epic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusty Landing",
    billboard: "QmQeepiPL73RLNLnUPrGeVPX3czyRqWrrKqoC7VoGHRGrg",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1644949707,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667454",
    rarity: "epic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dreamer's Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667455",
    rarity: "epic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sandy Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667456",
    rarity: "epic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain-Foot Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667457",
    rarity: "epic",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Shadow Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667458",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Skeleton Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667459",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "River Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667460",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oracle Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667461",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Timber Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667462",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Pinnacle Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667463",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oasis Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667464",
    rarity: "rare",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Venom Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667517",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sea Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667518",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crown Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667519",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Gloom Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667520",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Shadow Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667521",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forsaken Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667522",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sorrow Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667523",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sea Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667524",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Iron Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667525",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sorrow Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667526",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oasis Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667527",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Skeleton Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667528",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusk Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667529",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Anvilcross Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667530",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sleeping Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667531",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valor Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667532",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hidden Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667533",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vortex Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667534",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sabremill Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667535",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667536",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunderstorm Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667537",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "First Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667538",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sabremill Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667539",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Solitude Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667540",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Whitekeep Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667541",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Autumn's Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667542",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Pinnacle Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667543",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eco-Dome Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667544",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marsh Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667545",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sabremill Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667546",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Timber Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667547",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wild Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667548",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valley Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667549",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Writhing Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667550",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Seabreeze Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667551",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Fury Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667552",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Honor Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667553",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Echo Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667554",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starfall Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667555",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Final Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667556",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Brownkeep Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667557",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hunter Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667558",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Warden Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667559",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Seabreeze Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667560",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Burning Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667561",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forest Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667562",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dead Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667563",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Azure Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667564",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Raven Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667565",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Writhing Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667566",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sandy Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667567",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Shadow Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667568",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Night Park",
    billboard: "QmYVutVP22Bqw1AJ81iTNMtxGmY76XHssbPwSPdtPq7Qhi",
    name_change_count: 0,
    bb_change_count: 5,
    last_name_change: 0,
    last_bb_change: 1645881600,
    railyard_tier: 1,
    lounge_tier: 1,
    railyard_comm: 10,
    lounge_comm: 25,
    railyard_comm_vip: 45,
    lounge_comm_vip: 30,
    message: "",
  },
  {
    asset_id: "1099521667569",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusty Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667570",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eternal Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667571",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Tempest Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667572",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Storm Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667573",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lake Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667574",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Night Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667575",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667576",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Third Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667577",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Garden Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667578",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Night Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667579",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ember Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667580",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Nightmare Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667581",
    rarity: "common",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusk Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667582",
    rarity: "mythic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650445949,
    railyard_tier: 1,
    lounge_tier: 1,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667583",
    rarity: "legendary",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Boulder Depot",
    billboard: "Qmcg3JSqJo3Ed9pRLnKNCgBTYfyNqYN4b8wfdKMnycxtCs",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650460483,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667584",
    rarity: "legendary",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Boulder Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667585",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Triumph Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650460317,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667586",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Bone Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667587",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eco-Dome Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667588",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wildling Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667589",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Barren Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667590",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Solitude Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667591",
    rarity: "epic",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Nightmare Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667592",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eclipse Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667593",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Third Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667594",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sleeping Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650447299,
    railyard_tier: 3,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667595",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Storm Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667596",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forsaken Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667597",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Triumph Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667598",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Spring's Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667599",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Falcon Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667600",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Final Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667601",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Echo Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667602",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vengeance Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650460243,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667603",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Seabreeze Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667604",
    rarity: "rare",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667605",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunder Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667606",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Doom Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667607",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "First Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667608",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summit Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667609",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunderstorm Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667610",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starfall Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667611",
    rarity: "uncommon",
    region_id: 1,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusty Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667612",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Death Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667613",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "First Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667614",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Winter's Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667615",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Timber Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667616",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lake Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667617",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Terror Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667618",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crystal Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667619",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Talon Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667620",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sea Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667621",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dawn Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667622",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crown Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667623",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dire Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667624",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Iron Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667625",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Garden Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667626",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Burning Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667627",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sleeping Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667628",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667629",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Second Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667630",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starfall Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667631",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Pinnacle Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667632",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crystal Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667633",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Gloom Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667634",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crystal Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667635",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lagoon Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667636",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crown Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667637",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Brownkeep Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667638",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summer's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667639",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dead Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667640",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marshal Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 2,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667641",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Honor Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667642",
    rarity: "uncommon",
    region_id: 2,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Blood Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 2,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667643",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Writhing Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667644",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Talon Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667645",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Bone Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667646",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eclipse Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667647",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twin Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667648",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "River Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667649",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Desolation Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667650",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Death Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667651",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Watcher's Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667652",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vengeance Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667653",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crossroad Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667654",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oasis Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667655",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valor Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667656",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summit Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667657",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eternal Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667658",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valor Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667659",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forest Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667660",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunder Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 2,
    lounge_tier: 0,
    railyard_comm: 15,
    lounge_comm: 25,
    railyard_comm_vip: 0,
    lounge_comm_vip: 8,
    message: "",
  },
  {
    asset_id: "1099521667661",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summit Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667662",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Canyon Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667663",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crossroad Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667664",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Raven Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667665",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marsh Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667666",
    rarity: "uncommon",
    region_id: 3,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eco-Dome Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667667",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oracle Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667668",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wildling Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667669",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Flamelight Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667670",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Fury Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667671",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dead Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667672",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Gloom Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667673",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Second Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667674",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twin Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667675",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sandy Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667676",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Falcon Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667677",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Skeleton Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667678",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Honor Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667679",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twin Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667680",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Timber Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667681",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Venom Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667682",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sleeping Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667683",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Brownkeep Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667684",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crown Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667685",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Solitude Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667686",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oracle Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667687",
    rarity: "uncommon",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oasis Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667688",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Arid Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667689",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Arid Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667690",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wild Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667691",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Arid Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667692",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Night Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667693",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Final Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667694",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twilight Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667695",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Gloom Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667696",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Cinder Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667697",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Venom Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667698",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marsh Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667699",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Obsidian Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667700",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dreamer's Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667701",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eclipse Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667702",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Razor Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667703",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Terror Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667704",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twin Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667705",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Barren Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667706",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Canyon Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667707",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hidden Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667708",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wildling Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667709",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hunter Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667710",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Watcher's Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667711",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Echo Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667712",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Anvilcross Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667713",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Falcon Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667714",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Death Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667715",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Skeleton Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667716",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wildling Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667717",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Raven Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667718",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Obsidian Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667719",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lagoon Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667720",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sea Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667721",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Doom Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667722",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ocean Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667723",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Whitekeep Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667724",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Thunderstorm Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667725",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Second Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667726",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Victor Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667727",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Final Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667728",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Mountain-Foot Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667729",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Oracle Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667730",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Victor Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667731",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ember Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667732",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vortex Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667733",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Azure Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667734",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Final Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667735",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Watcher's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667736",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Winter's Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667737",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Phantom Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667738",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Marshal Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667739",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Burning Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667740",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Desolation Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667741",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusty Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667742",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Flamelight Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667743",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twilight Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667744",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Fury Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667745",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Storm Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667746",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Warden Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667747",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sandy Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667748",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "First Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667749",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Boulder Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667750",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valley Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667751",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Echo Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667752",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Flamelight Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667753",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Autumn's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667754",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Blood Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1649761458,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667755",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Shadow Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667756",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Tempest Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667757",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Tempest Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667758",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Spring's Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667759",
    rarity: "common",
    region_id: 4,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Razor Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667760",
    rarity: "mythic",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Second Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667761",
    rarity: "legendary",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Raven Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667762",
    rarity: "epic",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Second Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667763",
    rarity: "epic",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vengeance Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667764",
    rarity: "rare",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lagoon Station",
    billboard: "QmPHRASxdm5GpMLBBMjQXrEpP1VXyB7LQQQrWcMKk9TWYC",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1649841999,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667765",
    rarity: "rare",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Storm Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667766",
    rarity: "rare",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Triumph Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667767",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Anvilcross Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667768",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Seabreeze Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 1,
    railyard_comm: 25,
    lounge_comm: 0,
    railyard_comm_vip: 15,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667769",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Starlight Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667770",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Lagoon Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667771",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Light's Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667772",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Scarlet Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 1,
    last_name_change: 0,
    last_bb_change: 1650461926,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667773",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Ocean Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 10,
    lounge_comm: 0,
    railyard_comm_vip: 5,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667774",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Iron Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667775",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Forward Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667776",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twin Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667777",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Crystal Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667778",
    rarity: "uncommon",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Scarlet Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667779",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dusk Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667780",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Pinnacle Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667781",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sandy Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667782",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vengeance Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667783",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Obsidian Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667784",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Wild Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667785",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Victor Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667786",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Timber Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667787",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Twilight Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667788",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Flamelight Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667789",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Eternal Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667790",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Sleeping Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667791",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Doom Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667792",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Falcon Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667793",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Cinder Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667794",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summer's Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667795",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Dawn Park",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667796",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Brownkeep Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667797",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Hunter Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667798",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Summit Depot",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667799",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Vendetta Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667800",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Victor Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667801",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valley Gardens",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667802",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Autumn's Station",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 1,
    lounge_tier: 1,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
  {
    asset_id: "1099521667803",
    rarity: "common",
    region_id: 5,
    boost_amount: 0,
    boost_rates: [],
    station_name: "Valor Landing",
    billboard: "",
    name_change_count: 0,
    bb_change_count: 0,
    last_name_change: 0,
    last_bb_change: 0,
    railyard_tier: 0,
    lounge_tier: 0,
    railyard_comm: 0,
    lounge_comm: 0,
    railyard_comm_vip: 0,
    lounge_comm_vip: 0,
    message: "",
  },
];
