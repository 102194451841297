import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Container } from "@pixi/display";
import { NineSlicePlane } from "@pixi/mesh-extras";
import { Text } from "@pixi/text";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";

export class GreenButton extends Container {
  constructor(
    label: string,
    public onClick: (() => unknown) | null,
    width: number,
    heightPadding?: number,
    color: "green" | "blue" | "red" = "green"
  ) {
    super();

    const { assets } = GameSingletons.getGameContext();
    const texture = assets.getTexture(`assets/images/ui-common/btn-${color}.png`);
    const greenButton = new NineSlicePlane(texture, 15, 15, 15, 15);
    const text = new Text(label, {
      fill: 0xffffff,
      fontSize: 30,
      fontFamily: FontFamily.Default,
    });

    greenButton.width = width;
    //height padding is added to top and bottom of text height
    if (heightPadding != undefined) {
      greenButton.height = heightPadding + text.height + heightPadding;
    }

    text.anchor.set(0.5, 0.5);
    text.position.set(greenButton.width / 2, greenButton.height / 2);

    greenButton.interactive = true;
    greenButton.buttonMode = true;

    greenButton.addChild(text);

    this.addChild(greenButton);

    this.setLabelText = (string: string) => (text.text = string);

    this.setLabelTextSize = (number: number) => (text.style.fontSize = number);

    this.setColor = (color: "green" | "blue" | "red") => {
      greenButton.texture = assets.getTexture(`assets/images/ui-common/btn-${color}.png`);
    };

    this.setDisabled(false, greenButton, this.onClick);

    buttonizeDisplayObject(greenButton, () => this.onClick?.());
  }

  setDisabled = (value: boolean, greenButton: NineSlicePlane | GreenButton, onClick: (() => unknown) | null) => {
    if (value === true) {
      greenButton.alpha = 0.15;
      this.onClick = onClick;
    } else if (value === false) {
      greenButton.alpha = 1;
      this.onClick = onClick;
    }
  };

  readonly setLabelText: (string: string) => string;
  readonly setLabelTextSize: (number: number) => number;
  readonly setColor: (color: "green" | "blue" | "red") => void;
}
