import { GameSingletons } from "@game/app/GameSingletons";
import { createTickButton } from "@game/ui/components/createTickButton";
import { RailroaderDashPanelBase } from "../../railroader-dash/panels/RailroaderDashPanelBase";
import { AchievementBadgeData } from "../achievements/AchievementBadgeData";
import { AllAchievementsListView } from "../components/achievementsComponents/AllAchievementsListView";
import { SelectedFeaturedAchievementsView } from "../components/achievementsComponents/SelectedFeaturedAchievementsView";
import { SocialProfileDataService } from "../SocialProfileDataService";
import { FeaturedAchievementsPanelState } from "./FeaturedAchievementsPanelState";

export class FeaturedAchievementsPanel extends RailroaderDashPanelBase {
  private readonly context = GameSingletons.getGameContext();

  dataService: SocialProfileDataService = new SocialProfileDataService();
  username: string = this.context.userData.name;

  allAchievementsView?: AllAchievementsListView;
  featuredAchievementsView?: SelectedFeaturedAchievementsView;

  currentlySavedFeaturedAchievementsJson?: string;

  private readonly state = new FeaturedAchievementsPanelState();

  async init(): Promise<void> {
    this.pad.texture = this.assets.getTexture("ui-social/center-panel.png");

    //// Featured Achievements Background
    const padTextureId = "ui-railroader-dashboard/page-featured-achievements/layer2-bg.png";
    const pad = this.factory.createSprite(padTextureId, { x: 223, y: 88 });
    this.pad.addChild(pad);

    //// Featured Achievements List
    this.featuredAchievementsView = new SelectedFeaturedAchievementsView();
    this.featuredAchievementsView.scale.set(0.7);
    this.featuredAchievementsView.position.set(180, 75);
    this.addChild(this.featuredAchievementsView);

    //// All Achievements List
    this.allAchievementsView = new AllAchievementsListView();
    this.allAchievementsView.position.set(165, 360);
    this.addChild(this.allAchievementsView);

    //// Hint Plaque
    const plaqueTextureId = "ui-railroader-dashboard/inventory/inv-infopanel-redeemable.png";
    const plaqueMessage =
      "Featuring achievements can be a fun flex.\n" + "Don't be afraid to show them off in your Railroader Profile!";
    const plaque = this.addGreenPlaque({ textureId: plaqueTextureId, message: plaqueMessage });
    this.addChild(plaque);

    //// 

    this.handleViewInteractions();
    this.handleStateChanges();
    await this.updateAchievementData();

    //// Save Button
    //// (we're instantiating it after initialization so it doesn't light up during it)

    const updateSaveButtonVisibility = () => {
      const json = JSON.stringify(this.state.featuredAchievements);
      const hasChanges = json !== this.currentlySavedFeaturedAchievementsJson;
      saveButton.visible = hasChanges;
      console.log({
        hasChanges,
        json,
        currentlySavedFeaturedAchievementsJson: this.currentlySavedFeaturedAchievementsJson,
      })
    };
    const saveButton = this.addSaveButton();
    saveButton.visible = false;
    this.state.events.on({
      onAchievementSelected: updateSaveButtonVisibility,
      onAchievementDeselected: updateSaveButtonVisibility,
    });
  }

  handleStateChanges() {
    this.state.events.on({
      onAllAchievementsSet: async (achievements: AchievementBadgeData[]) => {
        this.allAchievementsView!.clear();
        await this.allAchievementsView!.addAchievements(achievements);
      },
      onAchievementDeselected: async (badgeInfo: AchievementBadgeData) => {
        this.featuredAchievementsView!.removeAchievement(badgeInfo);
        this.allAchievementsView!.setHighlight(badgeInfo, false);

        this.updateFeaturedView();
      },
      onAchievementSelected: async (badgeInfo: AchievementBadgeData) => {
        this.featuredAchievementsView!.addAchievement(badgeInfo);
        this.allAchievementsView!.setHighlight(badgeInfo, true);

        this.updateFeaturedView();
      },
    });
  }

  handleViewInteractions() {
    if (!this.allAchievementsView || !this.featuredAchievementsView) throw Error("Views not defined");
    this.allAchievementsView.onBadgeClicked = (badgeInfo: AchievementBadgeData) => {
      if (this.state.isAchievementSelected(badgeInfo)) {
        this.state.deselectFeaturedAchievement(badgeInfo);
        return;
      }
      if (this.state.featuredAchievements.length >= 9) {
        console.log("Max featured achievements reached");
        return;
      }
      this.state.selectFeaturedAchievement(badgeInfo);
    };
    this.featuredAchievementsView.onBadgeClicked = (badgeInfo: AchievementBadgeData) => {
      this.state.deselectFeaturedAchievement(badgeInfo);
    };
  }

  async updateAchievementData() {
    if (!this.allAchievementsView) throw new Error("allAchievementsView not initialized");
    if (!this.featuredAchievementsView) throw new Error("featuredAchievementsView not initialized");

    const allAchievements = await this.dataService.getAchievements(this.username);
    allAchievements.sort((a, b) => a.achievementId - b.achievementId);

    const featuredAchievements = await this.dataService.getFeaturedAchievements(this.username);

    await this.context.ticker.delay(0.35);

    await this.state.setAllAchievements(allAchievements);

    for (const { achievementId } of featuredAchievements) {
      const achievement = allAchievements.find(a => a.achievementId === achievementId);
      if (!achievement) {
        console.warn(`Achievement ${achievementId} not found in all achievements`);
        continue;
      }
      this.state.selectFeaturedAchievement(achievement);
      await this.context.ticker.nextFrame();
    }

    this.currentlySavedFeaturedAchievementsJson = JSON.stringify(this.state.featuredAchievements);
  }

  addSaveButton() {
    const saveFeaturedAchievementsToFirebase = async () => {
      const promise = this.dataService.setFeaturedAchievements(this.state.featuredAchievements);
      await this.context.spinner.showDuring(promise);
      this.currentlySavedFeaturedAchievementsJson = JSON.stringify(this.state.featuredAchievements);
      saveButton.visible = false;
    };
    const saveButton = createTickButton(0x168f99);
    saveButton.position.set(765, 100);
    saveButton.behavior.on({ trigger: saveFeaturedAchievementsToFirebase });
    return this.addChild(saveButton);
  }

  getSelectedAchievementIds() {
    return this.state.featuredAchievements.map(a => a.achievementId);
  }

  updateFeaturedView() {
    this.featuredAchievementsView?.clear();
    console.log(this.state.featuredAchievements);
    for (const badge of this.state.featuredAchievements) {
      this.featuredAchievementsView?.addAchievement(badge, -1);
    }
  }
}
