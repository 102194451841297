import { makeDraggable } from "@debug/utils/makeDraggable";
import { Container } from "@pixi/display";
import { InteractionEvent } from "@pixi/interaction";
import { Sprite } from "@pixi/sprite";
import { EventBus } from "@sdk/core/EventBus";
import { debuggingPane } from "@debug/tweakpane";
import { getDecorationScalesByTextureId } from "@game/world/layer_operations/util/getDecorationScalesByTextureId";
import { Texture } from "@pixi/core";
import { Text } from "@pixi/text";
import { GameSingletons } from "@game/app/GameSingletons";
import DecorationPlacement from "@game/data/raw-mainnet/decoration.json";

//// ///// /////

const STORAGE_KEY = "__DECO";

const ev = new EventBus<{
  change: () => void;
}>();

function createPreview() {
  const { assets, viewSize } = GameSingletons.getGameContext();

  const size = {
    width: 0.1 * viewSize.vmin,
    height: 0.1 * viewSize.vmin,
  };

  const textures = new Array<[string, Texture]>();

  {
    // Extra Buildings (V1)

    const res = assets.resources["assets/atlases-png/atlas-map-extra-decoration-sprites.json"];
    textures.push(...Object.entries(res?.textures ?? {}).sort((a, b) => (a[0] > b[0] ? 1 : -1)));
  }

  {
    // Old Buildings

    const res = assets.resources["assets/atlases-png/atlas-map-decoration-sprites.json"];
    textures.push(...Object.entries(res?.textures ?? {}).sort((a, b) => (a[0] > b[0] ? 1 : -1)));
  }

  {
    const textures2 = [
      "assets/debug/map-points/1-common.png",
      "assets/debug/map-points/2-uncommon.png",
      "assets/debug/map-points/3-rare.png",
      "assets/debug/map-points/4-epic.png",
      "assets/debug/map-points/5-legendary.png",
      "assets/debug/map-points/6-mythic.png",
    ];
    textures.push(...textures2.map(s => [s, assets.getTexture(s)] as [string, Texture<any>]));

    // const tempExtraTextures = textures.map(([s, t]) => [s, Object.assign(t.clone(), { __tint__: 0xFF0000 })] as [string, Texture<any>]);
    // textures.push(...tempExtraTextures);
  }

  const pad = new Sprite(Texture.WHITE);
  pad.tint = 0x000000;
  pad.width = size.width;
  pad.height = size.height;

  const sprite = new Sprite(Texture.WHITE);
  pad.addChild(sprite);

  const label = new Text("", { fontSize: 12, fill: 0xffffff });
  label.position.set(16, 0);
  label.scale.set(1 / pad.scale.x);
  pad.addChild(label);

  let currentIndex = 0;
  const maxIndex = textures.length;

  const preview = Object.assign(pad, {
    get currentIndex() {
      return currentIndex;
    },
    getCurrentTextureId() {
      return textures[currentIndex][0];
    },
    getCurrentTexture() {
      return textures[currentIndex][1];
    },
    next() {
      try {
        if (currentIndex >= maxIndex) return currentIndex;
        return ++currentIndex;
      } finally {
        preview.update();
      }
    },
    prev() {
      try {
        if (currentIndex - 1 < 0) return currentIndex;
        return --currentIndex;
      } finally {
        preview.update();
      }
    },
    set(index: number) {
      if (index > maxIndex) index = maxIndex;
      if (index < 0) index = 0;
      currentIndex = index;
      preview.update();
    },

    update() {
      console.log(`📍 ${currentIndex}/${maxIndex}`);
      label.text = this.getCurrentTextureId();
      sprite.texture = this.getCurrentTexture();
      sprite.scale.set(16 / sprite.texture.width, 16 / sprite.texture.height);
    },
  });
  preview.set(0);

  document.addEventListener("keydown", e => {
    switch (e.key) {
      case "ArrowRight":
        preview.next();
        break;
      case "ArrowLeft":
        preview.prev();
        break;
    }
  });

  preview.interactive = true;
  preview.buttonMode = true;
  preview.on("pointerdown", (e: InteractionEvent) => {
    e?.data?.button === 0 && preview.next();
    e?.data?.button === 2 && preview.prev();
  });

  return preview;
}

export async function editDecorationPlacement() {
  console.log(`// EDIT DECORATION PLACEMENT //`);

  const { app, assets, tooltips, world, ticker } = GameSingletons.getGameContext();

  const preview = createPreview();
  app.stage.addChild(preview);
  tooltips.registerTarget(
    preview,
    `[CTRL] + CLICK to place on map\n[ALT] + CLICK to remove\n[SHIFT] + CLICK to flip horizontally`
  );

  const _container = world.addChild(new Container());
  const container = Object.assign(_container, {
    get objects() {
      return _container.children as O[];
    },
  });

  function addObject(data: { x: number; y: number; textureId: string; flipped: boolean }) {
    const s = Object.assign(new Sprite(assets.getTexture(data.textureId)), {
      textureId: data.textureId,
      flipped: data.flipped,
    });
    s.position.copyFrom(data);
    container.addChild(s);

    s.anchor.set(0.5, 0.75);
    s.scale.set(0.1);

    s.interactive = true;
    s.buttonMode = true;
    s.on("click", (e: any) => {
      if (e.data?.originalEvent?.altKey || e.data?.originalEvent?.button === 1) s.destroy();
      if (e.data?.originalEvent?.shiftKey) s.flipped = !s.flipped;
      // e.stopPropagation();
      ev.emit("change");
    });

    makeDraggable(s, () => 1, {
      onDragEnd: () => {
        ev.emit("change");
      },
    });

    return s;
  }
  type O = ReturnType<typeof addObject>;

  app.stage.interactive = true;
  app.stage.on("pointerup", e => {
    const correctInput = !e.data?.originalEvent?.altKey && e.data?.originalEvent?.ctrlKey;
    if (correctInput) {
      const p = e.data.global;
      world.toLocal(p, app.stage, p);
      addObject({
        x: p.x,
        y: p.y,
        textureId: preview.getCurrentTextureId(),
        flipped: false,
      });
      ev.emit("change");
    }
  });

  function serialize() {
    const objects = container.objects.map(o => {
      return {
        textureId: o.textureId,
        x: Math.round(o.x),
        y: Math.round(o.y),
        flipped: o.flipped,
      };
    });
    return objects;
  }

  for (const c of debuggingPane.children) {
    debuggingPane.remove(c);
  }

  debuggingPane.addButton({ title: "Clear Cache" }).on("click", () => {
    localStorage.removeItem(STORAGE_KEY);
    location.reload();
  });

  for (const [id, s] of world.zoomLayers.operationsLayer.stationsContainer.stations) {
    s.events.clear();
    s.events.on({
      click: () => {
        navigator.clipboard.writeText(s.data.assetId);
        s.renderable = !s.renderable;
      },
      // hoverIn: () => s.label.setActive(true),
      // hoverOut: () => s.label.setActive(false),
    });

    // s.renderable = !__.includes(+s.data.assetId);
  }

  debuggingPane.addButton({ title: "Export to Clipboard" }).on("click", () => {
    const data = serialize();
    const json = JSON.stringify(data);
    console.log({ data });
    localStorage.setItem(STORAGE_KEY, json);
    navigator.clipboard.writeText(json);
  });

  debuggingPane.addButton({ title: "Export to File" }).on("click", () => {
    const data = serialize();
    const json = JSON.stringify(data);
    console.log({ data });
    localStorage.setItem(STORAGE_KEY, json);
    downloadTextFile(json, "decoration-placement.json");
  });

  debuggingPane.hidden = false;
  debuggingPane.expanded = true;

  for (const d of world.zoomLayers.operationsLayer.decorationsContainer.decorations) {
    d.destroy();
  }
  world.zoomLayers.operationsLayer.decorationsContainer.decorations.length = 0;

  const saved = localStorage.getItem(STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}")
    : DecorationPlacement;
  for (const o of saved) {
    addObject(o);
  }

  ev.on({
    change: () => {
      const data = serialize();
      localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    },
  });

  const SCALE_MUL = 2.4;
  ticker.add(() => {
    for (const c of container.objects) {
      c.scale.set(SCALE_MUL * getDecorationScalesByTextureId(c.textureId));
      c.scale.x *= c.flipped ? -1 : 1;
      c.zIndex = c.y;
    }
  });
}

function downloadTextFile(text: string, filename: string, type = "text/plain") {
  const a = document.createElement("a");
  const file = new Blob([text], { type: type });
  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
  a.remove();
}
