import { GameSingletons } from "@game/app/GameSingletons";
import { AssetsManager } from "@game/app/services/AssetsManager";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { ThemeColors } from "@game/constants/ThemeColors";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { arrangeInStraightLine } from "@sdk-pixi/layout/arrangeInStraightLine";
import { NavigationTabsManager } from "@sdk-pixi/ui-helpers/NavigationTabsManager";
import { PageObjectManager } from "@sdk-pixi/ui-helpers/PageObjectManager";
import { ModInfo, ModPlate } from "./ModPlate";
import { ModsContainer } from "./ModsContainer";
import { RunModDataService } from "./RunModDataService";

enum PageKey {
  Essences = 0,
  Summons = 1,
}

export class ModPage extends EnchantedContainer {
  public readonly titleString = "Mods";
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly assets: AssetsManager = GameSingletons.getResources();
  onItemSelected?: (itemType: PageKey) => unknown;

  selectedBtn?: Sprite;

  dataService: RunModDataService = new RunModDataService();
  constructor() {
    super();
  }

  async loadAndInitialize() {
    //// Navigation
    const panel = this.createItemButtons();
    panel.position.set(750, 250);
    this.addChild(panel);

    //// Page manager
    const pageContainer = new Container();
    this.addChild(pageContainer);

    const pageManager = new PageObjectManager(
      {
        [PageKey.Essences]: () => {
          const container = new ModsContainer();
          const initList = async () => {
            container.initList(await this.generateEssencesList());
          };
          initList();
          return container;
        },
        [PageKey.Summons]: () => {
          const container = new ModsContainer();
          const initList = async () => {
            container.initList(await this.generateSummonsList());
          };
          initList();
          return container;
        },
      },
      pageContainer
    );
    pageManager.setCurrentPage(0);

    this.onItemSelected = (key: PageKey) => {
      pageManager.setCurrentPage(key);
    };
  }

  public async playShowAnimation() {}

  public async playHideAnimation() {}

  createModButton(label: string) {
    const btn = this.factory.createSprite("ui-market-window-emporium/buy/filter-assets/lg-filterbg.png");
    const labelText = this.factory.createText(label, { fontSize: 36 }, { x: 185, y: 40 });
    labelText.anchor.set(0.5);
    btn.addChild(labelText);
    return btn;
  }

  createItemButtons() {
    const self = this;
    const tabButtonsContainer = new Container();
    this.addChild(tabButtonsContainer);
    const addTabButton = (label: string) => {
      const button = this.createModButton(label.toUpperCase());
      tabButtonsContainer.addChild(button);
      return button;
    };
    const tabsConfiguration = NavigationTabsManager.tabOptionsFromDictionary({
      [PageKey.Essences]: addTabButton("Essences"),
      [PageKey.Summons]: addTabButton("Summons"),
    });

    arrangeInStraightLine(tabButtonsContainer.children, { spacing: 23 });

    const tabsManager = new NavigationTabsManager(tabsConfiguration, {
      setSelected(tab, selected) {
        if (selected) {
          tab.texture = self.assets.getTexture("ui-market-window-emporium/mods/highlighted sub-tab.png");
          (tab.children[0] as Text).tint = 0xffffff;
        } else {
          tab.texture = self.assets.getTexture("ui-market-window-emporium/buy/filter-assets/lg-filterbg.png");
          (tab.children[0] as Text).tint = ThemeColors.HIGHLIGHT_COLOR.toInt();
        }
      },
    });
    tabsManager.onSelectionChange = key => {
      this.onItemSelected?.(key);
    };
    tabsManager.setSelectedTabIndex(0);

    return tabButtonsContainer;
  }

  private async performPurchase(templateId: number) {
    const WAX = await import("@sdk-integration/contracts");
    const { contracts, userDataCtrl, gameConfigData, modals, spinner } = GameSingletons.getGameContext();

    {
      const marketCfg = gameConfigData.runModsMarketData.get(templateId);
      if (!marketCfg) throw new Error(`No market config for ${templateId}`);

      const price = marketCfg.priceAmount;
      const promise = contracts.actions.performActionTransactions([
        [
          "transfer",
          {
            from: contracts.currentUserName,
            to: WAX.ContractName.M,
            quantity: price.toFixed(4) + " TOCIUM",
            memo: "DEPOSIT|" + contracts.currentUserName,
          },
          WAX.ContractName.Toc,
        ],
        [
          "buysaleitem",
          {
            railroader: contracts.currentUserName,
            template_id: templateId,
          },
          WAX.ContractName.M,
        ],
      ]);

      await spinner.showDuring(promise);
    }
    
    {
      const promise = userDataCtrl.updateAll();
      await modals.alert("Purchase successful!");
      await spinner.showDuring(promise);
    }
  }

  async generateEssencesList() {
    const modData: ModInfo[] = await this.dataService.getEssencesData();
    const mods = [];
    for (const modInfo of modData) {
      mods.push(
        ModPlate.createPurchaseableItem(modInfo, () => {
          return this.performPurchase(modInfo.templateId);
        })
      );
    }
    return mods;
  }

  async generateSummonsList() {
    const modData: ModInfo[] = await this.dataService.getSummonsData();
    const mods = [];
    for (const modInfo of modData) {
      mods.push(
        ModPlate.createPurchaseableItem(modInfo, () => {
          return this.performPurchase(modInfo.templateId);
        })
      );
    }
    return mods;
  }
}
