import {
  animateObjectsInViaTimeline,
  animateObjectsOutViaTimeline,
} from "@game/asorted/animations/animateObjectsInViaTimeline";
import { modifyPivotWithoutChangingPosition } from "@game/asorted/centerPivotWithoutChangingPosition";
import { createEnchantedFrameLoop } from "@game/asorted/createEnchantedFrameLoop";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { SocialProfileDataService } from "../SocialProfileDataService";
import { AvatarBadgeElements } from "./AvatarBadgeElements";
import { AvatarBadgePreferences } from "./AvatarBadgePreferences";

const DESIGN_WIDTH = 960;
const DESIGN_HEIGHT = 960;

/**
 * A veriant of the AvatarBadge class with only the foreground and background (no nameplate and no frame)
 */
export class SimplifiedAvatarBadge extends Container {
  public readonly tweeener = new TemporaryTweeener(this);
  public readonly onEnterFrame = createEnchantedFrameLoop(this);

  private readonly loadingJobs = new Set<Promise<any>>();

  private readonly foregroundImage: Sprite;
  private readonly backgroundImage: Sprite;

  public get width(): number {
    return DESIGN_WIDTH * this.scale.x;
  }
  public get height(): number {
    return DESIGN_HEIGHT * this.scale.y;
  }

  constructor() {
    super();

    //// Create background
    this.backgroundImage = new Sprite(Texture.EMPTY);
    this.backgroundImage.anchor.set(0.5);
    this.addChild(this.backgroundImage);

    //// Create foreground
    this.foregroundImage = new Sprite(Texture.EMPTY);
    this.foregroundImage.anchor.set(0.5);
    this.foregroundImage.position.y = 25;
    this.addChild(this.foregroundImage);
  }

  //// Elements

  private async loadAndApplyNewTexture(target: Sprite, url: string) {
    /**
     * Start loading the texture right away.
     */
    const loaderPromise = Texture.fromURL(url).catch(() => Texture.EMPTY);

    /**
     * Add the loading job to the list, so that we can query if any part is currently busy being loaded.
     */
    this.loadingJobs.add(loaderPromise);

    /**
     * Fade out the current version of the part, while the new texture is being loaded.
     */
    target.alpha = 0.3;
    const texture = await loaderPromise;
    target.texture = texture;
    target.alpha = 1;

    /**
     * Remove the loading job from the list.
     */
    this.loadingJobs.delete(loaderPromise);

    modifyPivotWithoutChangingPosition(target, 0.5);
  }

  addPlaceholderGraphic() {
    const placeholder = Sprite.from("https://public.cx/toc/placeholder.png");
    placeholder.anchor.set(0.5);
    placeholder.scale.set(1.7);
    this.addChild(placeholder);
  }

  async setForegroundImage(textureId: string) {
    await this.loadAndApplyNewTexture(this.foregroundImage, textureId);
  }

  async setBackgroundImage(textureId: string) {
    await this.loadAndApplyNewTexture(this.backgroundImage, textureId);
  }

  setBackgroundTint(color: number) {
    this.backgroundImage.tint = color;
  }

  waitUntilAllPartsLoaded() {
    return Promise.all(this.loadingJobs);
  }

  playShowAnimation(delay: number = 0) {
    return animateObjectsInViaTimeline(
      [
        [this.backgroundImage, { pixi: { scale: 1.2, alpha: 0.0 }, duration: 0.3, ease: "power2.out" }, 0.22],
        [this.foregroundImage, { pixi: { scale: 0.3, pivotY: -50 }, duration: 0.3, ease: "power2.out" }, 0.11],
        [this.foregroundImage, { pixi: { alpha: 0.0 }, duration: 0.3, ease: "power4.out" }],
      ],
      this.tweeener,
      delay
    );
  }

  playHideAnimation() {
    return animateObjectsOutViaTimeline(
      [
        [this.foregroundImage, { pixi: { scale: 0.0, alpha: 0.0 }, duration: 0.22, ease: "power2.in" }],
        [this.backgroundImage, { pixi: { scale: 0.0, alpha: 0.0 }, duration: 0.22, ease: "power2.in" }],
      ],
      this.tweeener
    );
  }
}

export module SimplifiedAvatarBadge {
  export function applyPreferences(avatar: SimplifiedAvatarBadge, preferences: Partial<AvatarBadgePreferences>) {
    preferences.foregroundImage != undefined &&
      avatar.setForegroundImage(AvatarBadgeElements.getForegroundTextureId(preferences.foregroundImage));
    preferences.backgroundImage != undefined &&
      avatar.setBackgroundImage(AvatarBadgeElements.getBackgroundTextureId(preferences.backgroundImage));
    preferences.backgroundColor != undefined && avatar.setBackgroundTint(preferences.backgroundColor);
    return avatar;
  }

  export async function applyUserData(
    avatar: SimplifiedAvatarBadge,
    username: string,
    preferences?: AvatarBadgePreferences
  ) {
    if (!preferences) {
      const dataService = new SocialProfileDataService();
      preferences = await dataService.getAvatarBadgePreferences(username);
    }

    if (avatar.destroyed) return;

    if (preferences) applyPreferences(avatar, preferences);
    else avatar.addPlaceholderGraphic();

    return avatar;
  }

  export async function showWhenFullyLoaded(avatar: SimplifiedAvatarBadge, animate: boolean = true) {
    await avatar.onEnterFrame.waitUntil(() => avatar.worldVisible);
    await avatar.waitUntilAllPartsLoaded();
    if (avatar.destroyed) return;
    if (animate) await avatar.playShowAnimation();
  }
}
