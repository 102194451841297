import { makeURLsFromIPFSHash } from "@game/ui/popups/station-dashboard-components/billboard/utils/makeURLsFromIPFSHash";
import * as WAX from "@sdk-integration/contracts";
import type { WalletUser } from "./typing/WalletUser";
import type { WaxContractActionService } from "./WaxContractActionService";
import type { WaxContractTableService } from "./WaxContractTableService";

export class WaxContractMyStationService {
  readonly currentCenturyName;
  readonly actions;
  readonly tables;

  constructor(
    public readonly user: WalletUser,
    services: {
      actions: WaxContractActionService;
      tables: WaxContractTableService;
      currentCenturyName: WAX.CenturyName;
    }
  ) {
    const { actions, tables, currentCenturyName } = services;
    this.currentCenturyName = currentCenturyName;
    this.actions = actions;
    this.tables = tables;
  }

  async setStationBillboardIpfsHash(stationId: WAX.StationAssetId, ipfsHash: string | null) {
    if (ipfsHash) {
      return this.actions.performActionTransaction(
        "setbb",
        {
          railroader: this.user.accountName,
          century: this.currentCenturyName,
          asset_id: stationId,
          ipfs_hash: ipfsHash,
        },
        WAX.ContractName.S
      );
    } else {
      return this.actions.performActionTransaction(
        "clearbb",
        {
          railroader: this.user.accountName,
          century: this.currentCenturyName,
          asset_id: stationId,
        },
        WAX.ContractName.S
      );
    }
  }

  async getStationBillboardIpfsHash(stationId: WAX.StationAssetId) {
    const [row] = await this.tables.loadRows<{
      billboard: string;
    }>(
      "stations",
      {
        scope: this.currentCenturyName,
        lower_bound: stationId,
        upper_bound: stationId,
        limit: 1,
      },
      WAX.ContractName.S
    );

    console.log({
      row,
    });

    if (!row) {
      throw new Error(`Station ${stationId} not found`);
    }

    return row.billboard;
  }

  async getStationBillboardIpfsImageUrls(stationId: WAX.StationAssetId) {
    const ipfsHash = await this.getStationBillboardIpfsHash(stationId);
    return makeURLsFromIPFSHash(ipfsHash);
  }

  async getStationBillboardChangeTime(stationId: WAX.StationAssetId) {
    const [row] = await this.tables.loadRows<{
      last_bb_change: number;
    }>(
      "stations",
      {
        scope: this.currentCenturyName,
        lower_bound: stationId,
        upper_bound: stationId,
        limit: 1,
      },
      WAX.ContractName.S
    );

    console.log({
      row,
    });

    if (!row) {
      throw new Error(`Station ${stationId} not found`);
    }

    return row.last_bb_change;
  }
}
