import { __ResourceExplorer } from "@debug/explorer/__ResourceExplorer";
import { __window__ } from "@debug/__";
import { __DEBUG_SETTINGS__ } from "@debug/__DEBUG_SETTINGS__";
import { GameSingletons } from "@game/app/GameSingletons";
import { RailroaderDashPanelType } from "@game/ui/railroader-dash/panels/models";
import { CTPartsSubpageKey } from "@game/ui/windows/market/pages/ct-parts/CTPartsPage";
import { EmporiumSectionKey } from "@game/ui/windows/market/pages/emporium/EmporiumPage";
import type { StationAssetId } from "@sdk-integration/contracts";

export function overrideVoidClick() {
  const context = GameSingletons.getGameContext();
  const { stageContainers, main, mapData, viewport } = context;

  // if (env.BLOCKCHAIN === "testnet" && station.ownerName != userData.name) {
  //   stationId = ("" + 1099577692345) as any;
  // }

  let isRightMouseButtonDown = false;
  document.addEventListener("mousedown", e => {
    if (e.button === 2) isRightMouseButtonDown = true;
  });
  document.addEventListener("mouseup", e => {
    if (e.button === 2) isRightMouseButtonDown = false;
  });

  viewport.on("clicked", () => {
    if (!isRightMouseButtonDown) return;

    const stationId = (__DEBUG_SETTINGS__["Default-Station-Id"] || "1099521667582") as StationAssetId;
    const station = mapData.stations.get(stationId)!;

    context.main.hud.contextMenu.setCurrentMenu([
      {
        text: `Debug: Regular Leaderboard`,
        onClick: () => {
          main.popups.clear();
          main.hud.railroaderDashboard.openDashboard(RailroaderDashPanelType.Leaderboards);
        },
        disabled: false,
      },
      {
        text: `Debug: The Mechanic — FORGE`,
        onClick: () => {
          main.popups.clear();
          main.marketWindow.openWindow({ page: "ctparts", subpage: CTPartsSubpageKey.Forge });
        },
        disabled: false,
      },
      {
        text: `Debug: The Mechanic — FUSION`,
        onClick: () => {
          main.popups.clear();
          main.marketWindow.openWindow({ page: "ctparts", subpage: CTPartsSubpageKey.Fuse });
        },
        disabled: false,
      },
      {
        text: `Debug: The Emporium — Buy`,
        onClick: () => {
          main.popups.clear();
          main.marketWindow.openWindow({ page: "emporium", subpage: EmporiumSectionKey.Buy });
        },
        disabled: false,
      },
      {
        text: `Debug: The Emporium — Sell`,
        onClick: () => {
          main.popups.clear();
          main.marketWindow.openWindow({ page: "emporium", subpage: EmporiumSectionKey.Sell });
        },
        disabled: false,
      },
      {
        text: `Debug: The Emporium — My Listings`,
        onClick: () => {
          main.popups.clear();
          main.marketWindow.openWindow({ page: "emporium", subpage: EmporiumSectionKey.MyListing });
        },
        disabled: false,
      },
      {
        text: `Debug: Station Dash`,
        onClick: () => {
          main.popups.clear();
          main.popups.myStationDashboard.setCurrentStation(station);
        },
        disabled: false,
      },
      {
        text: `Debug: Rail Yard`,
        onClick: () => {
          __window__.__myTrainIsEverywhere__ = true;
          main.popups.clear();
          main.popups.addonRailYard.setCurrentStation(station);
        },
        disabled: false,
      },
      {
        text: `Debug: Conductor Lounge`,
        onClick: () => {
          __window__.__myTrainIsEverywhere__ = true;
          main.popups.clear();
          main.popups.addonConductorLounge.setCurrentStation(station);
        },
        disabled: false,
      },
      {
        text: `Debug: Resource Explorer 🔍`,
        onClick: () => {
          stageContainers._hud.addChild(new __ResourceExplorer());
        },
        disabled: false,
      },
    ]);
  });
}
