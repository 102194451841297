import { onKeyPress } from "@debug/utils/onKeyPress";
import { __window__ } from "@debug/__";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { NPCEncounterCinematic_Story } from "@game/cinematics/NPCEncounterCinematic_Story";
import { presentUserDialogueChoiceOptions } from "@game/cinematics/story/presentUserDialogueChoiceOptions";
import { dynamicChoiceActionFunctions } from "@game/cinematics/utils/dynamicChoiceActionFunctions";
import { dynamicSpecialEffectFunctions } from "@game/cinematics/utils/dynamicSpecialEffectFunctions";
import { createDynamicStoryTextService } from "@game/cinematics/utils/dynamicStoryContentFunctions";

const userChoicesSavedInMemory: Record<string, any> = {};

export function testStoryCampaigns(context: GameContext = GameSingletons.getGameContext()) {
  async function playCinematic(cinematicDataKey: string) {
    const { userData, firebase, spinner } = context;
    const getCinematicsData = spinner.bindSpinner(firebase.getCinematicsData);
    const cinematicData = await getCinematicsData(cinematicDataKey as any);
    const cinematic = new NPCEncounterCinematic_Story(cinematicData, () => context.ticker.delay(0.5));
    const train = context.HACKS.__lastSelectedTrain || userData.trains.values().next().value;
    cinematic.userChoicesDictionary = userChoicesSavedInMemory;
    cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
    cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
    cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
    cinematic.dynamicSpecialEffectsService = dynamicSpecialEffectFunctions();
    cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
    // cinematic.shouldShowIntroModal = false;

    __window__.cinematic = context.stageContainers._cinematic.addChild(cinematic);

    const updatedUserChoices = await cinematic.play();

    //// Copy all properties from the updated user choices object into the user choices saved in memory object.
    Object.assign(userChoicesSavedInMemory, updatedUserChoices);
  }

  /*async function playResearcherCinematic(cinematicDataKey: string) {
    const { userData, firebase, spinner } = context;
    const getCinematicsData = spinner.bindSpinner(firebase.getCinematicsData);
    const cinematicData = await getCinematicsData(cinematicDataKey as any);
    const cinematic = new NPCEncounterCinematic_Story_Researcher(cinematicData, () => context.ticker.delay(0.5));
    const train = context.HACKS.__lastSelectedTrain || userData.trains.values().next().value;
    cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
    cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
    cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
    cinematic.shouldShowIntroModal = false;*/

  // onKeyPress("7", () => playMysteriousStrangerCinematic("story_encounter_chapter_01a"));
  // onKeyPress("8", () => playMysteriousStrangerCinematic("story_encounter_chapter_01b"));
  // onKeyPress("9", () => playMysteriousStrangerCinematic("story_encounter_chapter_01c"));

  onKeyPress("7", () => playCinematic("story_encounter_chapter_05a"));
  onKeyPress("8", () => playCinematic("story_encounter_chapter_05b"));
  onKeyPress("9", () => playCinematic("story_encounter_chapter_05c"));
}
