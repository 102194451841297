import { __window__ } from "@debug/__";
import * as WAX from "@sdk-integration/contracts";

import { ExplorerApi } from "atomicassets";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";

const validCardSchemaNames: Record<string, true> = {
  locomotive: true,
  conductor: true,
  railcar: true,
  commodity: true,
};

const AtomicAssetsNamespace = "atomicassets";
const RRCollectionName = "centurytrain";

export class __Explorer {
  constructor(public readonly endpoint: string) {}

  private readonly api = new ExplorerApi(this.endpoint, AtomicAssetsNamespace, {
    fetch: fetch as any,
  });

  async stations(
    page?: number,
    map = (asset: IAsset) => {
      const asset_id = asset.asset_id as WAX.StationAssetId;
      const owner = asset.owner as WAX.AccountName;
      return {
        ...(asset.data as Omit<WAX.StationAssetData, "asset_id" | "owner">),
        asset_id,
        owner,
      };
    }
  ) {
    const assets = await this.api.getAssets(
      {
        collection_name: RRCollectionName,
        schema_name: "station",
        limit: 1000,
      },
      page
    );

    console.log(`⚛ ⚛ ⚛ Stations`, { assets });

    return assets.map<WAX.StationAssetData>(map);
  }

  async cards(
    page?: number,
    map = (asset: IAsset) => {
      const asset_id = asset.asset_id as WAX.CardAssetId;
      const owner = asset.owner as WAX.AccountName;
      const card_schema_type = asset.schema.schema_name as WAX.CardAssetData.SchemaType;
      return {
        ...(asset.data as any),
        card_schema_type,
        asset_id,
        owner,
      };
    }
  ) {
    const results = await this.api.getAssets(
      {
        collection_name: RRCollectionName,
        limit: 1000,
      },
      page
    );

    console.log(`⚛ ⚛ ⚛ Cards`, { assets: results });

    return results.filter(asset => validCardSchemaNames[asset.schema.schema_name]).map<WAX.CardData>(map);
  }
}

export module __explore {
  export const test = new __Explorer("https://test.wax.api.atomicassets.io");
  export const main = new __Explorer("https://wax.api.atomicassets.io");
}
