import { GameSingletons } from "@game/app/GameSingletons";
import { AvatarBadgeElements } from "@game/ui/social/avatar/AvatarBadgeElements";
import { AvatarBadgePreferences } from "@game/ui/social/avatar/AvatarBadgePreferences";
import { getRandomItemFrom } from "@sdk/helpers/arrays";
import { chance, randomIntBetweenIncluding } from "@sdk/utils/random";

export module MockProfileDataService {
  export function getRandomAchievements(count: number = 50) {
    return [
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        iconTextureId: "ui-achievements/misc-badges/0-beta.png",
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        iconTextureId: "ui-achievements/region-badges/p-heights.png",
        tier: 2,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        iconTextureId: "ui-achievements/region-badges/centuryville.png",
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        tier: 1,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        tier: 2,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        tier: 3,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        tier: 4,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
        tier: 5,
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
      {
        id: -1,
        uid: "--",
        name: "--",
        backgroundTextureId: "ui-achievements/Badge.png",
        backgroundTint: Math.floor(Math.random() * (1 << 24)),
      },
    ];
  }

  export async function getEmptyAchievements(username: string) {
    return [] as { color: number; label: string }[];
  }

  export async function getHighlightedMedals(username: string) {
    const test = [chance(0.5), chance(0.5), chance(0.5), chance(0.5), chance(0.5)];
    return test;
  }

  export async function getProfileInfoData(username: string) {
    const { mapData, userData } = GameSingletons.getGameContext();
    const ownedStations = mapData.stationsArray.filter(s => s.ownerName === username);
    const trains = randomIntBetweenIncluding(0, 30, 2);

    const railroaderSince = new Date();
    railroaderSince.setFullYear(randomIntBetweenIncluding(1990, 2020));

    const infoData = {
      railroaderSince,
      npcEncounters: "--",
      achievements: "--",
      numberOfStations: `${ownedStations.length}`,
      distanceTraveled: "--",
      numberOfTrains: `${trains} trains`,
      stations: ownedStations,
    };
    return infoData;
  }

  export async function getMiscProfilePreferences(username: string, editable: boolean = false) {
    return { tagline: "" };
  }

  export async function getMyAvatarBadgePreferences(editable: boolean = false) {
    return getRandomAvatarBadgePreferences();
  }

  export async function getAvatarBadgePreferences(username: string) {
    return chance(0.25) ? undefined : getRandomAvatarBadgePreferences();
  }
}

function getRandomAvatarBadgePreferences(): AvatarBadgePreferences {
  const colors = AvatarBadgeElements.Colors[Math.round(Math.random())];
  const allowedFrames = AvatarBadgeElements.Frames;
  const allowedForegrounds = AvatarBadgeElements.Foregrounds;
  const allowedBackgrounds = AvatarBadgeElements.Backgrounds;
  const allowedNameplateBases = AvatarBadgeElements.NameplateBases;
  const allowedNameplateRims = AvatarBadgeElements.NameplateRims;
  return {
    foregroundImage: getRandomItemFrom(allowedForegrounds),
    backgroundImage: getRandomItemFrom(allowedBackgrounds),
    backgroundColor: getRandomItemFrom(colors),
    frameImage: getRandomItemFrom(allowedFrames),
    frameColor: getRandomItemFrom(colors),
    nameplateBaseImage: getRandomItemFrom(allowedNameplateBases),
    nameplateBaseColor: getRandomItemFrom(colors),
    nameplateRimImage: getRandomItemFrom(allowedNameplateRims),
    nameplateRimColor: getRandomItemFrom(colors),
  };
}
