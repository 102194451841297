import * as WAX from "@sdk-integration/contracts";

import type { JsonRpc } from "eosjs";
import type { LiteralUnion } from "type-fest";
import type { TableName } from ".";

export module WaxContractTableService {
  export type LoadRowsOptions = {
    scope: string;
    limit?: number;
    lower_bound?: string;
    upper_bound?: string;
    index_position?: number;
    key_type?: string;
    reverse?: boolean;
    show_payer?: boolean;
    code?: WAX.ContractName;
  };
}

export class WaxContractTableService {
  constructor(public readonly rpc: JsonRpc) {}

  async loadRows<T>(
    table: LiteralUnion<TableName, string>,
    options: WaxContractTableService.LoadRowsOptions,
    contractName: WAX.ContractName
  ) {
    interface Result {
      rows: T[];
      more: boolean;
      next_key: string;
    }

    const requestOptions = {
      json: true, // Get the response as json
      code: contractName, // Contract that we target
      limit: 1, // Maximum number of rows that we want to get
      reverse: false, // Optional: Get reversed data
      show_payer: false, // Optional: Show ram payer
      ...options,
      table,
    };

    const result: Result = await this.rpc.get_table_rows(requestOptions);

    if (!result) {
      throw new Error("No data returned from table.");
    }

    if (!result.rows) {
      throw new Error("No rows property in returned object.\n\n" + JSON.stringify(result));
    }

    const rows = [...result.rows];
    let more = result.more;
    let next_key = result.next_key;

    while (more) {
      console.warn(`Paging through table "${table}" with next_key: ${next_key}`, `Current rows: ${rows.length}`);

      const nextOptions = { ...requestOptions, lower_bound: next_key };
      const nextResult: Result = await this.rpc.get_table_rows(nextOptions);
      rows.push(...nextResult.rows);

      more = nextResult.more;
      next_key = nextResult.next_key;
    }

    console.log(`Table results (${table})`, rows);

    return rows as Readonly<T>[];
  }
}
