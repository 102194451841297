import { makeDraggable } from "@debug/utils/makeDraggable";
import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { AirtableDataService } from "@sdk-integration/airtable/AirtableDataService";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { createUpdatesTypeModal } from "./createUpdatesModalBase";

export class UpdatesModalDataRowsScrollbox extends SafeScrollbox {
  constructor() {
    super({
      noTicker: true,
      boxWidth: 620,
      boxHeight: 535,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });
  }

  async initialize() {
    const openArticleModal = async (article: any) => {
    }
    const createUpdatesDataContainer = async () => {
      this.clearList();

      const dataService = new AirtableDataService();
      const data = await dataService.getUpdateNotesData();

      const { simpleFactory, ticker } = GameSingletons.getGameContext();
      let startY = 5;
      let i = 0;

      console.log({
        data,
      });

      if (!data) throw new Error("AirTable Data not found");

      for (let row of data) {
        const rowContainer = new Container();
        const invisBG = this.createInvisibleBGForRow(600, 120);
        invisBG.anchor.set(0.5);
        rowContainer.addChild(invisBG);

        //// Add Event Title Card
        const image = simpleFactory.createSprite(row.textureId, { x: 5, y: startY + 140 * i });
        image.scale.set(0.43);
        rowContainer.addChildAt(image, 0);

        //// Add Event Title text
        const title = simpleFactory.createText(row.title, {
          fontFamily: FontFamily.Default,
          fontSize: 20,
          fill: 0xffffff,
        });
        title.position.set(315, image.y + 20);
        rowContainer.addChild(title);

        //// Add Event Body text
        const description = simpleFactory.createText(row.summary, {
          fontFamily: FontFamily.DefaultThin,
          fontSize: 13,
          fill: 0xffffff,
          wordWrap: true,
          wordWrapWidth: 275,
          lineHeight: 15,
        });
        description.position.set(319, title.y + title.height);
        rowContainer.addChild(description);

        //// Add Event Footer text
        const footer = simpleFactory.createText(row.footer, {
          fontFamily: FontFamily.Default,
          fontSize: 12,
          fill: 0xf0b503,
          align: "right",
        });
        if (!row.actionURL && row.function) {
          buttonizeDisplayObject(footer, row.function);
        }
        footer.position.set(315, description.y + description.height + 18);
        rowContainer.addChild(footer);

        invisBG.position.set(title.position.x, image.position.y + 79);

        this.content.addChild(rowContainer);

        if (row.articleText) {
          console.log(row.title + " ||| " + row.articleText);
          buttonizeDisplayObject(invisBG, () => {
            this.parent.destroy();
            createUpdatesTypeModal(true, row);
          });
        } else if (row.function) {
          console.log(row.title + " | " + row.articleText);
          buttonizeDisplayObject(invisBG, row.function);
        }

        i++;
      }

      this.update();

      await ticker.nextFrame();

      this.content.addChild(this.addInvisibleBox(426));
      this.update();
    };

    createUpdatesDataContainer();
  }

  createInvisibleBGForRow(width: number, height: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = width;
    box.height = height;

    return box;
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
