export enum FontIcon {
  Tocium = "🌌",
  AnomaticParticles = "🧪",
  LocoLegacy = "🚂",
  Loco = "🚃",
  LocoAlt = "🚙",
  Fuel = "⛽",
  Duration = "🕒",
  Distance = "⏩",
  Railroader = "🙂",
  CenturyTrain = "🌞",
}
