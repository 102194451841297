import { __MOCK__ } from "@debug";
import { GameSingletons } from "@game/app/GameSingletons";
import { MusicSource } from "@game/constants/paths/MusicSource";
import { Sound } from "@pixi/sound";
import { EventBus } from "@sdk/core/EventBus";
import { waitUntil } from "@sdk/utils/frameloops/waitUntil";

type TrackInfo = {
  soundRef: Sound;
  title: string;
};

export class MainMusicPlaylistController {
  private readonly context = GameSingletons.getGameContext();

  private readonly queue = new Array<TrackInfo>();
  private currentSelectedTrack: TrackInfo | null = null;

  public readonly events = new EventBus<{
    trackChanged: (track: TrackInfo) => void;
  }>();

  public playNextTrack() {
    const { music } = this.context;

    if (this.queue.length <= 0) {
      this.queue.push(...this.getShuffledTracks());
    }

    const track = this.queue.shift();

    if (!track) throw new Error("No track to play");

    const result = music.playTrack(track.soundRef, false);
    waitUntil(() => result.instance).then(instance => instance.on("end", this.playNextTrack.bind(this)));

    this.currentSelectedTrack = track;
    this.events.emit("trackChanged", track);
  }

  public getCurrentTitle() {
    return this.currentSelectedTrack?.title || "...";
  }

  private getShuffledTracks(): TrackInfo[] {
    return cloneAndShuffleArray(getTrackInfos());
  }
}

function getTrackInfos() {
  if (__MOCK__) {
    return [
      { soundRef: Sound.from("assets/audio/sfx/choo-choo.wav"), title: "Choo!" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-culprit/culprit-mmm.wav"), title: "Culprit Mmm" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-mechanic/sigh.wav"), title: "Mechanic Sigh" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-mechanic/footsteps.wav"), title: "Mechanic Steps" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-mechanic/repair_fx.wav"), title: "Mechanic Repair" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-stories/new-story.wav"), title: "New Story" },
      { soundRef: Sound.from("assets/audio/sfx/npc-cinematic-stories/ticking.wav"), title: "Tick-tock!" },
    ];
  }
  
  return [
    //Normal Music
    { soundRef: Sound.from(MusicSource.ADayWithMayhew), title: "A Day With Mayhew" },
    { soundRef: Sound.from(MusicSource.DanceWildcat), title: "Dance of the Wildcat" },
    { soundRef: Sound.from(MusicSource.Destined), title: "Destined" },
    { soundRef: Sound.from(MusicSource.Lagrimas), title: "Lagrimas" },
    { soundRef: Sound.from(MusicSource.Songbird), title: "Songbird" },
    { soundRef: Sound.from(MusicSource.MainModernTheme), title: "Modern Century Theme" },
    { soundRef: Sound.from(MusicSource.MainModernTheme), title: "Modern Century Theme" },
/*
    { soundRef: Sound.from(MusicSource.xmas1), title: "Bottomless Choos" },
    { soundRef: Sound.from(MusicSource.xmas2), title: "Carol of the Choo" },
    { soundRef: Sound.from(MusicSource.xmas3), title: "The Choo Choo Elves" },
    { soundRef: Sound.from(MusicSource.xmas4), title: "Lil Santa Choo Choo" },
    { soundRef: Sound.from(MusicSource.xmas5), title: "Reindeer Caboose" },
    { soundRef: Sound.from(MusicSource.xmas6), title: "Come All Ye Choo Choos" }, */
  ];
}

function cloneAndShuffleArray<T>(arr: T[]) {
  const newArr = arr.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
}
