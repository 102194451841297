import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { MINIGAMES } from "@game/gameplay/minigames/MiniGameRouletteScreen";
import { Container } from "@pixi/display";
import { Rectangle } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { AnimatedSprite } from "@pixi/sprite-animated";
import { BitmapText, IBitmapTextStyle } from "@pixi/text-bitmap";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { PageObjectManager } from "@sdk-pixi/ui-helpers/PageObjectManager";
import { RailroaderDashComponentFactory } from "../../RailroaderDashComponentFactory";
import { RailroaderDashPanelBase } from "../RailroaderDashPanelBase";
import { LeaderboardList } from "./LeaderboardList";
import { LeaderboardComponentFactory } from "./LoaderboardComponentFactory";

const commonLabelStyle = {
  fill: "#FFFFFF",
  fontFamily: FontFamily.Default,
};

const headerTextCoordinates = [
  { x: 255, y: 292.5 },
  { x: 365, y: 292.5 },
  { x: 615, y: 292.5 },
];

export class LeaderboardCenterPanel_MiniGames extends RailroaderDashPanelBase {
  private selectedGameIndex = 0;

  public clickCog?: (right: boolean) => unknown;

  init() {}

  constructor() {
    super(new RailroaderDashComponentFactory());

    //// Logo image
    const title = this.addTitleImage("ui-railroader-dashboard/page-leaderboards/img-leaderboards.png");
    title.scale.set(0.45);
    title.anchor.set(0.54, 0.5);
    title.position.set(this.pad.width / 2, 20);

    /// Tables background
    this.addListPad();

    //// Yellow plaque
    this.addYellowPlaque();

    //// Yellow plaque text
    const yellowPlaque = this.addChild(this.createYellowPlaqueText(0, 805));

    //// Add cogs
    this.addChild(this.createCogs(280, 858, 0.5));

    //// Add clickCog function
    this.clickCog = (right: boolean) => {
      let indexDelta = right ? 1 : -1;
      let newIndex = this.selectedGameIndex + indexDelta;

      while (newIndex > MINIGAMES.length - 1) newIndex -= MINIGAMES.length;
      while (newIndex < 0) newIndex += MINIGAMES.length;

      this.selectedGameIndex = newIndex;

      //// Update lists
      yellowPlaque.setSelectedGameTitle(MINIGAMES[newIndex].title);

      pageManager.setCurrentPage(newIndex as any);
    };

    const factory = new LeaderboardComponentFactory(this);
    factory.addMinigameButtons("arcade");

    //// //// //// //// //// //// //// //// ////
    //// Pages
    //// //// //// //// //// //// //// //// ////

    const createListForGame = (gameIndex: number) => {
      const cfg = MINIGAMES[gameIndex];

      const container = new Container();

      const headerImage = Sprite.from(cfg.highscoreHeader);
      headerImage.scale.set(0.7);
      headerImage.anchor.set(0.5, 0);
      headerImage.position.set(this.pad.width / 2, 80);
      container.addChild(headerImage);

      const list = new LeaderboardList();
      list.position.set(210, 325);
      list.fillMinigameHighscores(cfg.highscoreKey);
      container.addChild(list);

      return container;
    };

    const pageManager = new PageObjectManager(
      {
        0: () => createListForGame(0),
        1: () => createListForGame(1),
        2: () => createListForGame(2),
      },
      this
    );

    pageManager.setCurrentPage(0);
  }

  createYellowPlaqueText(x: number = 0, y: number = 0, scale: number = 1) {
    const container = new Container();

    const selectedGameCfg = MINIGAMES[this.selectedGameIndex];

    const titleLabelStyle = {
      fontName: "Celestial Typeface",
      align: "center",
      fontSize: 30,
    } as IBitmapTextStyle;
    const gameTitleLabel = new BitmapText(selectedGameCfg.title, titleLabelStyle);
    gameTitleLabel.anchor.set(0.5, 0);
    gameTitleLabel.position.set(this.pad.width / 2, 0);
    container.addChild(gameTitleLabel);

    container.position.set(x, y);
    container.scale.set(scale);

    return Object.assign(container, {
      setSelectedGameTitle: (title: string) => {
        gameTitleLabel.text = title;
      },
    });
  }

  createCogs(x: number = 0, y: number = 0, scale: number = 1) {
    const assets = GameSingletons.getResources();
    const ticker = GameSingletons.getTicker();
    const container = new Container();
    let startX = x;
    for (let i = 1; i < 2; i++) {
      startX += 172;

      //// Add cog slot
      const cogSlot = this.factory.createSprite(
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/cog-slot.png"),
        {
          x: startX,
          y: y,
        }
      );
      cogSlot.scale.set(scale);
      container.addChild(cogSlot);

      //// Add left arrow
      const leftArrow = this.factory.createSprite(
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/l-arrow.png"),
        {
          x: startX - 10,
          y: y + 8,
        }
      );
      leftArrow.scale.set(scale);
      container.addChild(leftArrow);

      //// Add events for cogs
      leftArrow.interactive = true;
      leftArrow.buttonMode = true;
      leftArrow.hitArea = new Rectangle(-80, -80, 160, 160);
      buttonizeDisplayObject(leftArrow, () => {
        this.clickCog?.(false);
        cog.play();
        ticker.delay(0.2).then(() => cog.stop());
      });

      //// Add right arrow
      const rightArrow = this.factory.createSprite(
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/r-arrow.png"),
        {
          x: startX + 167 / 2 + 4,
          y: y + 8,
        }
      );
      rightArrow.scale.set(scale);
      container.addChild(rightArrow);
      rightArrow.interactive = true;
      rightArrow.buttonMode = true;
      rightArrow.hitArea = new Rectangle(-80, -80, 160, 160);
      buttonizeDisplayObject(rightArrow, () => {
        this.clickCog?.(true);
        cog.play();
        ticker.delay(0.2).then(() => cog.stop());
      });

      //// Add cog wheel
      const cogTextures = [
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/gear-sprites/1.png"),
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/gear-sprites/2.png"),
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/gear-sprites/3.png"),
        assets.getTexture("ui-railroader-dashboard/page-leaderboards/gear-sprites/4.png"),
      ];
      const cog = new AnimatedSprite(cogTextures);
      cog.position.set(startX + 3, y - 3);
      cog.scale.set(scale - 0.2);
      container.addChild(cog);
    }

    return container;
  }
}
