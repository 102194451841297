import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { BevelFilter } from "@pixi/filter-bevel";
import { Text } from "@pixi/text";
import { createXButton } from "../../components/createXButton";
import { RailroaderDashPanelBase } from "../../railroader-dash/panels/RailroaderDashPanelBase";
import { displayUnreadAchievementModals } from "../achievements/displayUnreadAchievementModals";
import { AchievementsList } from "../components/viewComponents/AchievementsList";
import { ViewAvatarSection } from "../components/viewComponents/ViewAvatarSection";
import { ViewInfoSection } from "../components/viewComponents/ViewInfoSection";
import { SocialProfileDataService } from "../SocialProfileDataService";
import { AchievementsProgress } from "./AchievementsProgress";

export class ViewProfilePanel extends RailroaderDashPanelBase {
  dataService: SocialProfileDataService = new SocialProfileDataService();

  async init(
    username: string = GameSingletons.getGameContext().userData.name,
    withBase: boolean = true
  ): Promise<void> {
    if (withBase) {
      this.pad.texture = this.assets.getTexture("ui-social/center-panel.png");
      const textureId = "ui-railroader-dashboard/inventory/inv-infopanel-redeemable.png";
      const message =
        "Featuring achievements can be a fun flex. Don't be afraid to show them off in your Railroader Profile!";
      const plaque = this.addGreenPlaque({ textureId, message });
      this.addChild(plaque);
    } else {
      this.pad.texture = this.assets.getTexture("ui-social/panel-pad.png");
      this.shelf.renderable = false;
    }

    const textFilter = new BevelFilter({
      lightColor: 0x4d4d4c,
    });
    //// Top section
    const topSection = new ViewAvatarSection(username);
    const data = await this.dataService.getHighlightedMedals(username);
    for (let [index, element] of data.entries()) {
      topSection.setIconHighlight(element, index);
      topSection.setMedalArcHightlight(element, index);
    }
    topSection.position.set(175, 65);
    this.addChild(topSection);

    const { tagline } = await this.dataService.getMiscProfilePreferences(username);
    if (tagline) {
      topSection.setRailroaderTagline(tagline);
    }

    //end Top section

    // Mid section
    const titleMid = this.factory.createText(
      "ACHIEVEMENTS",
      { fontSize: 36, fontFamily: FontFamily.Croogla, fill: 0x363634 },
      { x: 485, y: 335 }
    );
    titleMid.anchor.set(0.5);
    titleMid.filters = [textFilter];
    this.addChild(titleMid);

    ////Progress Bar
    const progressBar = new AchievementsProgress(username);
    progressBar.initialize();
    progressBar.pivot.set(0.5);
    progressBar.position.set(titleMid.x - titleMid.width / 2, titleMid.y + 25);
    this.addChild(progressBar);

    ////Ach Icon
    const achIcon = this.factory.createSprite("assets/images/ui-social/ach-icon.png");
    achIcon.alpha = 0.3;
    achIcon.scale.set(0.69);
    achIcon.anchor.set(0.5);
    achIcon.position.set(progressBar.x - 35, titleMid.y + 12);

    this.addChild(achIcon);

    ////Progress Labels
    const percentageLabel = new Text((await progressBar.progressData).percentage.toFixed(0) + "%", {
      fontSize: 14,
      fontFamily: FontFamily.Default,
      fill: 0xffffff,
    });
    const numberOfAchievementsEarned = new Text(
      (await progressBar.progressData).countUnlocked.toFixed(0) +
        "/" +
        (await progressBar.progressData).countTotal.toString(),
      {
        fontSize: 14,
        fontFamily: FontFamily.Default,
        fill: 0x363634,
      }
    );
    numberOfAchievementsEarned.anchor.set(0.5);
    numberOfAchievementsEarned.position.set(titleMid.x + titleMid.width, titleMid.y);
    percentageLabel.position.set(progressBar.x + 245 + 5, progressBar.y - 5);
    this.addChild(percentageLabel, numberOfAchievementsEarned);

    ////Ach List

    const achievements = await this.dataService.getAchievements(username);
    const midSection = new AchievementsList(173);
    midSection.position.set(165, 385);
    midSection.addAchievements(achievements);
    this.addChild(midSection);

    const userIsMe = username === GameSingletons.getGameContext().userData.name;
    if (userIsMe) {
      await displayUnreadAchievementModals(achievements);
    }

    //end Mid Section

    // Lower section
    const statsTitle = this.factory.createText(
      "STATS",
      { fontSize: 30, fontFamily: FontFamily.Croogla, fill: 0x363634 },
      { x: 285, y: 585 }
    );
    statsTitle.anchor.set(0.5);
    statsTitle.filters = [textFilter];
    this.addChild(statsTitle);

    const stationsTitle = this.factory.createText(
      "STATIONS",
      { fontSize: 30, fontFamily: FontFamily.Croogla, fill: 0x363634 },
      { x: 485, y: 585 }
    );
    stationsTitle.anchor.set(0.5);
    stationsTitle.filters = [textFilter];
    this.addChild(stationsTitle);

    const collectionTitle = this.factory.createText(
      "COLLECTION",
      { fontSize: 30, fontFamily: FontFamily.Croogla, fill: 0x363634 },
      { x: 685, y: 585 }
    );
    collectionTitle.anchor.set(0.5);
    collectionTitle.filters = [textFilter];
    this.addChild(collectionTitle);

    const centralInfoSection = new ViewInfoSection();
    centralInfoSection.position.set(185, 600);
    centralInfoSection.addInfoTitle();

    const rrData = await this.dataService.getProfileInfoData(username);
    const nftData = await this.dataService.getEthereumNFTs(username);
    centralInfoSection.fillInfo(rrData);
    centralInfoSection.fillCollectionInfo(nftData);
    this.addChild(centralInfoSection);
  }

  addCloseButton(trigger: () => void): void {
    const button = createXButton();
    button.position.set(140, 60);
    button.scale.set(0.6);
    button.behavior.on({ trigger });
    this.addChild(button);
  }
}
