import { GameSingletons } from "@game/app/GameSingletons";
import { makeStationVisual, StationVisual } from "@game/world/visuals/StationVisual";
import { Container } from "@pixi/display";
import { InteractionManager } from "@pixi/interaction";
import { StationAssetId } from "@sdk-integration/contracts";
import { EnchantedInstance } from "@sdk/pixi/enchant/interfaces";

const stationVisualScale = 4;

export function makeStationSprites(objectsContainer: EnchantedInstance<Container>) {
  const context = GameSingletons.getGameContext();
  const { app, assets, mapData, input, viewport, viewSize } = context;
  const interaction = app.renderer.plugins.interaction as InteractionManager;

  const stations = new Map<StationAssetId, StationVisual>();
  for (const [stationId, stationData] of mapData.stations) {
    const sprite = makeStationVisual(stationData);
    sprite.zIndex = sprite.y;

    sprite.events.on({
      click: edata => {
        input.emit("clickOnStation", stationData, edata);
      },
      hoverIn: () => {
        const state = context.main!.selection;
        state.hoverStation = stationData;
      },
      hoverOut: () => {
        const state = context.main!.selection;
        if (state.hoverStation === stationData) {
          state.hoverStation = null;
        }
      },
    });

    stations.set(stationId, sprite);
  }
  const stationsArray = [...stations.values()] as StationVisual[];

  const onEnterFrame = () => {
    const globalMouseXY = interaction.mouse.global;
    const mouseXY = viewport.toLocal(globalMouseXY);

    const main = context.main!;
    const { selection: state, viewMode, popups, faq } = main;
    const { stationLabels } = main;

    let hoverStation = null as null | StationVisual;

    for (const station of stationsArray) {
      if (station.worldAlpha > 0) {
        // station.visible = cullingArea.isInView(station);
        if (station.visible) {
          station.scale.set(stationVisualScale);
          if (state.hoverStation === station.data && !popups.hasPopupsAttached(station)) {
            hoverStation = station;
          }
        }
      }
    }

    if (hoverStation) {
      stationLabels.applyState([
        {
          at: hoverStation.data.assetId,
          text: hoverStation.data.name,
        },
      ]);
    } else {
      stationLabels.applyState([]);
    }
  };
  objectsContainer.onEnterFrame.add(onEnterFrame);

  const stationsContainer = Object.assign(objectsContainer, {
    stations,
    stationsArray,
  });
  stationsContainer.addChild(...stationsArray);

  stationsContainer.sortChildren();

  return stationsContainer;
}
