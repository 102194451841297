import { __MOCK__ } from "@debug";
import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { GlowFilter } from "@pixi/filter-glow";
import { Graphics } from "@pixi/graphics";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { clamp } from "@sdk/utils/math";
import { nextFrame } from "@sdk/utils/promises";
import { LeaderboardListRow } from "./LeaderboardListRow";
import { DataTimeframe, LeaderboardsDataService } from "./LeaderboardsDataService";

export class LeaderboardList extends SafeScrollbox {
  protected readonly context: GameContext = GameSingletons.getGameContext();
  protected readonly dataService: LeaderboardsDataService = new LeaderboardsDataService();

  constructor() {
    super({
      noTicker: true,
      boxWidth: 555,
      boxHeight: 425,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflowX: "none",
    });
  }

  async fillStationsByTociumEarnings(timeframe: DataTimeframe, limit: number) {
    this.clearData();
    const data = await this.dataService.getStationsByTociumEarnings(timeframe, limit);
    await this.addRows(data, datum => {
      const rowContainer = new LeaderboardListRow();
      rowContainer.applyStationData(
        {
          placement: String(datum.placement),
          stationName: datum.name,
          value: datum.tocium,
          socialLink: datum.socialLink,
        },
        555
      );
      return rowContainer;
    });
  }

  async fillStationsByTotalRailruns(timeframe: DataTimeframe, limit: number) {
    this.clearData();
    const data = await this.dataService.getStationsByTotalRailruns(timeframe, limit);
    await this.addRows(data, datum => {
      const rowContainer = new LeaderboardListRow();
      rowContainer.applyStationData(
        {
          placement: String(datum.placement),
          stationName: datum.name,
          value: datum.runs,
          socialLink: datum.socialLink,
        },
        555
      );
      return rowContainer;
    });
  }

  async fillRailRoadersByTociumEarnings(timeframe: DataTimeframe, limit: number) {
    this.clearData();
    const data = await this.dataService.getRailRoadersByTociumEarnings(timeframe, limit);
    await this.addRows(data, datum => {
      const rowContainer = new LeaderboardListRow();
      rowContainer.applyRailroaderData(
        {
          placement: String(datum.placement),
          username: datum.name,
          value: datum.tocium,
          socialLink: datum.socialLink,
        },
        555
      );
      return rowContainer;
    });
  }

  async fillRailRoadersByTotalRailruns(timeframe: DataTimeframe, limit: number) {
    this.clearData();
    const data = await this.dataService.getRailRoadersByTotalRailruns(timeframe, limit);
    await this.addRows(data, datum => {
      const rowContainer = new LeaderboardListRow();
      rowContainer.applyRailroaderData(
        {
          placement: String(datum.placement),
          username: datum.name,
          value: datum.runs,
          socialLink: datum.socialLink,
        },
        555
      );
      return rowContainer;
    });
  }

  async fillMinigameHighscores(minigameKey: string) {
    this.clearData();
    const data = await this.context.firebase.getMinigameHighscores(minigameKey);
    const entries = Object.entries(data || {})
      .sort((a, b) => b[1] - a[1])
      .slice(0, 200)
      .map(([username, score]) => ({ name: username, score }));
    await this.addRows(entries, ({ name, score }, index) => {
      const rowContainer = new LeaderboardListRow();
      rowContainer.applyRailroaderData(
        {
          placement: String(index + 1),
          username: name,
          value: score,
          socialLink: name,
        },
        555
      );
      return rowContainer;
    });
  }

  private async addRows<T>(rowsData: T[], constructRow: (rowData: T, index: number) => Container) {
    const myUserName = this.context.userData.name;

    let startY = 10;
    for (let index in rowsData) {
      const rowData = rowsData[index];

      const row = constructRow(rowData, +index);
      this.content.addChild(row);
      row.position.y = startY;
      startY += row.height;
      await nextFrame();
      this.update();

      const sticky = __MOCK__ ? +index == 10 : (rowData as any).name == myUserName;
      if (sticky) this.addStickyRow(rowData, constructRow, row.position.y, +index);
    }

    this.content.addChild(this.addInvisibleBox(426));
    this.update();
  }

  private async addStickyRow<T>(
    rowData: T,
    constructRow: (rowData: T, index: number) => Container,
    y: number,
    index: number
  ) {
    const row = constructRow(rowData, index);
    row.zIndex = 999;
    this.addChild(row);

    const g = new Graphics();
    g.beginFill(0x01272a);
    g.drawRect(20, 5, this.boxWidth - 40, row.height - 10);
    g.endFill();
    g.filters = [new GlowFilter({ color: 0x00ffff, distance: 12, outerStrength: 2 })];
    row.addChildAt(g, 0);

    Object.assign(window, { box: this });

    Object.assign(row, {
      onEnterFrame: () => {
        const pos = clamp(y - this.scrollTop, 0, this.boxHeight - row.height + 10);
        row.position.y = pos;
      },
    });

    await nextFrame();
    this.update();
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clearData() {
    const children = [...this.content.children];
    for (const child of children) {
      /**
       * The only exception is the invisible box, which we
       * don't want to destroy.
       */
      child.destroy({ children: true });
    }
  }
}
