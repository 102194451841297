import { GameSingletons } from "@game/app/GameSingletons";
import { NPCEncounterCinematic_Story } from "@game/cinematics/NPCEncounterCinematic_Story";
import { presentUserDialogueChoiceOptions } from "@game/cinematics/story/presentUserDialogueChoiceOptions";
import { dynamicChoiceActionFunctions } from "@game/cinematics/utils/dynamicChoiceActionFunctions";
import { dynamicSpecialEffectFunctions } from "@game/cinematics/utils/dynamicSpecialEffectFunctions";
import { createDynamicStoryTextService } from "@game/cinematics/utils/dynamicStoryContentFunctions";

export async function playStoryEncounterCinematic(encounterId: string, shouldShowIntroModal: boolean) {
  const context = GameSingletons.getGameContext();
  const { spinner, firebase } = context;

  const getFirestoreUserData = spinner.bindSpinner(firebase.getUserMainData);
  const firestoreUserData = await getFirestoreUserData();

  async function playMysteriousStrangerCinematic(cinematicDataKey: string) {
    const { userData, firebase, spinner } = context;
    const getCinematicsData = spinner.bindSpinner(firebase.getCinematicsData);
    const cinematicData = await getCinematicsData(cinematicDataKey as any);
    const cinematic = new NPCEncounterCinematic_Story(cinematicData, () => context.ticker.delay(0.5));
    cinematic.shouldShowIntroModal = shouldShowIntroModal;
    cinematic.doTheUserChoiceThing = presentUserDialogueChoiceOptions;
    cinematic.userChoicesDictionary = firestoreUserData.storyChoices ?? null;

    const train = context.HACKS.__lastSelectedTrain || userData.trains.values().next().value;
    cinematic.dynamicStoryTextService = createDynamicStoryTextService(train);
    cinematic.dynamicChoiceActionsService = dynamicChoiceActionFunctions();
    cinematic.dynamicSpecialEffectsService = dynamicSpecialEffectFunctions();

    context.stageContainers._cinematic.addChild(cinematic);

    const choices = await cinematic.play();

    if (choices) {
      console.log({ choices });
      const setUserStoryChoices = spinner.bindSpinner(firebase.setUserStoryChoices);
      await setUserStoryChoices(choices);
    }
  }

  await playMysteriousStrangerCinematic(encounterId);
}
