import { GameSingletons } from "@game/app/GameSingletons";
import { RegionIntegerId } from "@game/constants/RegionId";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { IPointData } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

const thomasHeadPositions: Record<RegionIntegerId, IPointData> = {
  [RegionIntegerId.Centuryville]: { x: -2080, y: -2777 },
  [RegionIntegerId.PembertonHeights]: { x: -17843, y: -20695 },
  [RegionIntegerId.TrevithickPines]: { x: 10445, y: -21945 },
  [RegionIntegerId.PawpawPlains]: { x: 15284, y: 16066 },
  [RegionIntegerId.JamesPark]: { x: -19275, y: 20323 },
};

export const makeThomasRegionMarkers = () => {
  const container = new EnchantedContainer();

  const headScale = 16;

  const headSprites: Sprite[] = [];

  const service = {
    addThomasHeads(regionIds: readonly RegionIntegerId[]) {
      const assets = GameSingletons.getResources();

      const textureId = "overview-map-markers/face-active-icon-thomas.png";
      const headTexture = assets.getTexture(textureId);

      for (const headRegionId of regionIds) {
        const head = new Sprite(headTexture);
        head.name = "head_" + headRegionId;
        head.anchor.set(0.5, 0.5);
        head.scale.set(headScale);
       
       /*  GameSingletons.getGameContext().tooltips.registerTarget(head, 'Find Thomas Here!'); */

        const position = thomasHeadPositions[headRegionId];
        if (!position) {
          throw new Error(`Unknown region: ${headRegionId}`);
        }

        head.position.copyFrom(position);

        headSprites.push(head);
        container.addChild(head);
      }
    },

    removeThomasHeads() {
      for (const head of headSprites) {
        head.destroy();
      }
      headSprites.length = 0;
    },

    playShowAnimation() {
      for (const [index, head] of headSprites.entries()) {
        new TemporaryTweeener(head).fromTo(
          head,
          { pixi: { scale: 0, alpha: 0 } },
          { pixi: { scale: headScale, alpha: 1 }, duration: 0.5, delay: 0.1 * index, ease: "elastic.out" }
        );
      }
    },

    playHideAnimation() {
      for (const head of headSprites) {
        new TemporaryTweeener(head).to(head, { pixi: { alpha: 0 }, duration: 0.2 });
      }
    },

    instantlyHideAllHeads() {
      for (const head of headSprites) {
        head.alpha = 0;
      }
    },
  };

  return Object.assign(container, service);
};
