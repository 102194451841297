import { Texture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";

import { MultipleReasons } from "../../sdk/core/MultipleReasons";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

export function makeScreenDimmer(
  getScreenSize: () => { width: number; height: number },
  ticker: { add: (fn: () => void) => void },
  { zIndex = 999_999_999, tint = 0x000000, animationDuration = 1.0 } = {}
) {
  const texture = Texture.WHITE;

  const reasons = new MultipleReasons();
  reasons.on({
    empty: () => tweeener.to(solid, { alpha: 0, duration: solid.animationDuration, overwrite: true }),
    notEmpty: () => tweeener.to(solid, { alpha: 1, duration: solid.animationDuration, overwrite: true }),
  });

  const solid = Object.assign(new Sprite(texture), {
    forceHide: () => {
      tweeener.killTweensOf(solid);
      solid.alpha = 0;
    },
    forceShow: () => {
      tweeener.killTweensOf(solid);
      solid.alpha = 1;
    },
    reasonsToShow: reasons,
    animationDuration,
    zIndex,
    tint,
  });

  const tweeener = new TemporaryTweeener(solid);

  ticker.add(() => {
    solid.renderable = solid.alpha > 0;
    if (solid.renderable) {
      const { width, height } = getScreenSize();
      solid.width = width;
      solid.height = height;
    }
  });

  return solid;
}
