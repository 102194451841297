import { __window__ } from "@debug";
import { ethers } from "ethers";

export async function connnectAndRetrieveEthereumAddress() {
  const provider = new ethers.providers.Web3Provider(__window__.ethereum);
  await provider.send("eth_requestAccounts", []);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
}
