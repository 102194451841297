import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { GameSingletons } from "@game/app/GameSingletons";
import { FontIcon } from "@game/constants/FontIcon";
import { GreenButton } from "@game/ui/railroader-dash/panels/settings/components/GreenButton";
import { Container } from "@pixi/display";
import { CallbackList } from "@sdk/utils/callbacks/CallbackList";
import { GenericModalBase } from "../../../../../../ui/generic-modals/GenericModalBase";
import { GenericModalBackgroundOptions } from "../../../../../../ui/generic-modals/GenericModalFactory";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { GameContext } from "@game/app/app";
import { Sprite } from "@pixi/sprite";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { Texture } from "@pixi/core";

export class animate extends Container {
  private readonly assets = GameSingletons.getResources();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly context = GameSingletons.getGameContext();
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  constructor() {
    super();

  }

  public playShowAnimation() {
    return this.tweeener.from(this, {
      pixi: {
        pivotX: 100,
        alpha: 0,
      },
      duration: 0.37,
      ease: "power3.out",
    });
  }

  public playHideAnimation() {
    return this.tweeener.to(this, {
      pixi: {
        pivotX: 100,
        alpha: 0,
      },
      duration: 0.19,
      ease: "power.in",
    });
  }
}



export class ForgeModal extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly modals = GameSingletons.getGenericModalsFactory();
  private bg;
  private char;
  constructor() {
    super();
    //// Add background
    this.bg = this.factory.createSprite("ui-ct-bg/ct-bg.png");
    this.bg.anchor.set(.5,.5);
    this.bg.scale.set (3);

    this.addChild(this.bg);

    this.createModal();

    //// Add character
    this.char = this.createCharacter();
    this.char.position.set(-100, 400);
    this.addChild(this.char); 

    this.addChild(new animate());

  }

  async createModal() {
    const onClose = new CallbackList();

    const modal = new GenericModalBase({
      cornerDetailType: null,
      background: GenericModalBackgroundOptions.GREEN,
      content: () => {
        const container = new Container();
        //// Text
        const text = this.factory.createText(
          "congratulations, you're the proud owner of a shiny new century train".toUpperCase(),
          { wordWrap: true, wordWrapWidth: 815, align: "center" }
        );
        text.anchor.set(0.5);
        container.addChild(text);
        //// Tweet text
        ///// Font icon temp
        /* const tweetText = this.factory.createText(
          `${FontIcon.Loco} ` + `tweet about it!`.toUpperCase(),
          {},
          { y: 100 }
        );
        tweetText.anchor.set(0.5);
        container.addChild(tweetText); */
        //// Button
        const button = new GreenButton(
          "DISMISS",
          async () => {
            
            await modal.hideAndDestroy();
            this.removeChild(this.bg);
            this.removeChild(this.char);
            return onClose.callAllAndClear();
          },
          300
        );
        button.position.set(-button.width / 2, 150);
        container.addChild(button);
        return container;
      },
      title: "Century Train Forged",
    });
    modal.position.set(this.width / 5, this.height / 6);
    this.addChild(modal);
    modal.scale.set(1);
    //// Add card to modal
    const card = this.createCard("https://atomichub-ipfs.com/ipfs/QmSqZyXqrvDdvnZ2Pz9tTz22xvDVfQGbGkhL7aqUkiXxAT");
    card.position.set(1200, 0);
    modal.addChild(card);
  }

  createCard(texture: string) {
    const card = this.factory.createSprite(texture);
    card.scale.set(0.325);
    card.rotation = 0.175;
    return card;
  }

  createCharacter() {
    const char = this.factory.createSprite("ui-market-window-character/mechanic-forge-success.png");
    return char;
  }
}



