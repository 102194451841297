import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedSprite } from "@game/core/enchanted-classes";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { createHUDElementContainer } from "./hud-utils";

export function createAudioIndicator() {
  const { assets, music, sfx } = GameSingletons.getGameContext();
  const mutedTexture = assets.getTexture("mutedSoundIcon");
  const unmutedTexture = assets.getTexture("playingSoundIcon");

  const container = createHUDElementContainer({ x: 1.0, y: 0.0 }, { x: 20, y: 100 });

  const button = new EnchantedSprite(mutedTexture);
  button.zIndex = 100;
  button.alpha = 0.4;
  button.scale.set(0.4);
  button.anchor.copyFrom(container.screenAnchor);
  button.enchantments.watch(
    () => music.muted && sfx.isMuted,
    everythingMuted => (button.texture = everythingMuted ? mutedTexture : unmutedTexture),
    true
  );

  buttonizeDisplayObject(button, () => {
    if (music.muted && sfx.isMuted) {
      music.unmute();
      sfx.unmute();
    } else {
      music.mute();
      sfx.mute();
    }
  });

  container.addChild(button);

  return container;
}

export type AudioIndicator = ReturnType<typeof createAudioIndicator>;
