import * as WAX from "@sdk-integration/contracts";

import { UninitializedGameContext } from "@game/app/app";
import { RailPath } from "./entities/RailPath";
import { StationEntity } from "./entities/StationEntity";
import { BuildingEntity } from "@game/data/entities/BuildingEntity";

import DecorationPlacement from "./raw-mainnet/decoration.json";
import SecsOfCent from "./raw-mainnet/secret.json";
import RailpathCurves from "./raw-mainnet/railpaths.json";
import SpecialObjectsPlacement from "./raw-mainnet/special.json";
import StationsExtraProperties_Mainnet from "./raw-mainnet/stations.json";
import StationsExtraProperties_Testnet from "./raw-testnet/stations.json";

import { __window__ } from "@debug/__";
import { __DEBUG__ } from "@debug/__DEBUG__";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { delay } from "@sdk/utils/promises";

const extraData = {
  testnet: StationsExtraProperties_Testnet,
  mainnet: StationsExtraProperties_Mainnet,
};

export class MapDataLoader {
  private async loadStationsTableData(contracts: Readonly<WaxContractsGateway>) {
    const map = new Map<WAX.StationAssetId, Readonly<WAX.StationData_RRCentury>>();

    async function loadFromTable() {
      const results = await contracts.getStations();
      for (const result of results) {
        map.set(result.station_id, result);
      }
      return results;
    }

    await loadFromTable();

    if (map.size < 400) {
      throw new Error(`Failed to load stations table data`);
    }

    return map.values();
  }

  private async leadStationsTableData_SCentury(contracts: Readonly<WaxContractsGateway>) {
    const stationRows = await contracts.tables.loadRows<WAX.StationData_SCentury>(
      "stations",
      {
        scope: contracts.currentCenturyName,
        limit: 400,
      },
      WAX.ContractName.S
    );
    return stationRows;
  }

  async load(context: UninitializedGameContext) {
    const { contracts } = context;

    const stations_extraDataMap = extraData[context.env.BLOCKCHAIN] as Record<
      WAX.StationAssetId,
      [x: number, y: number]
    >;
    // const allStationAssetIds = Object.keys(stations_extraDataMap);

    const stations_waxTableData = await this.loadStationsTableData(contracts);
    const stations_waxSTableData = await this.leadStationsTableData_SCentury(contracts);
    const stations_waxAssetData = await contracts.assets.getAllStationAssets();

    function createStationEntity(waxTableData: WAX.StationData_RRCentury): StationEntity {
      const assetId = waxTableData.station_id;

      const waxSContractData = stations_waxSTableData.find(row => row.asset_id === assetId);

      const waxAssetData = stations_waxAssetData.find(asset => asset.asset_id === assetId);
      if (!waxAssetData) {
        throw new Error(`Station ${assetId} has no asset data`);
      }

      const extraDataList = stations_extraDataMap[assetId];
      if (!extraDataList) {
        throw new Error(`Station ${assetId} has no extra data`);
      }
      const [x, y] = extraDataList;
      const extraData = { x, y };
      const entity = new StationEntity(waxTableData, waxSContractData || {}, waxAssetData, extraData);
      return entity;
    }

    const stations = new Map<WAX.StationAssetId, StationEntity>();
    for (const waxTableData of stations_waxTableData) {
      try {
        const entity = createStationEntity(waxTableData);
        stations.set(entity.assetId, entity);
      } catch (e) {
        console.error(e);
        continue;
      }
    }
    const stationsArray = [...stations.values()];

    console.log(`Total stations count: ${stations.size}`);
    console.log(`Total station links count: ${stationsArray.reduce((acc, station) => acc + station.links.length, 0)}`);

    let curvyPaths = RailpathCurves;
    if (__DEBUG__ && __window__.extractMapDataFromSVGString) {
      curvyPaths = await __window__.extractMapDataFromSVGString();
      console.log({ curvyPaths });
    }

    const railPaths = new Array<RailPath>();
    for (const [, station] of stations) {
      for (const link of station.links) {
        try {
          const stationA = station;
          const stationB = stations.get(link.assetId)!;
          if (railPaths.some(predicate => predicate.stationA === stationB && predicate.stationB === stationA)) continue;
          const [ax, ay] = [stationA.x, stationA.y];
          const [bx, by] = [stationB.x, stationB.y];
          const path = new RailPath([
            [ax, ay],
            [bx, by],
          ]);
          path.stationA = stationA;
          path.stationB = stationB;
          path.stationsLink = link;
          railPaths.push(path);

          function manhattanDistance(x1: number, y1: number, x2: number, y2: number) {
            return Math.abs(x1 - x2) + Math.abs(y1 - y2);
          }
          function isCloseEnough(x1: number, y1: number, x2: number, y2: number) {
            const MAX_DISTANCE = 100;
            return (
              (manhattanDistance(x1, y1, ax, ay) < MAX_DISTANCE && manhattanDistance(x2, y2, bx, by) < MAX_DISTANCE) ||
              (manhattanDistance(x1, y1, bx, by) < MAX_DISTANCE && manhattanDistance(x2, y2, ax, ay) < MAX_DISTANCE)
            );
          }

          for (const c of curvyPaths) {
            const first = c[0] as [number, number];
            const last = c[c.length - 1] as [number, number];
            if (isCloseEnough(...first, ...last)) {
              path.setPoints(c as [number, number][]);
            }
          }
        } catch (e) {
          console.error(e);
          continue;
        }
      }
    }

    console.log(`Total rail paths count: ${railPaths.length}`);

    for (const railpath of railPaths) {
      railpath.stationA = stationsArray.find(s => s.x === railpath.pointA.x && s.y === railpath.pointA.y);
      railpath.stationB = stationsArray.find(s => s.x === railpath.pointB.x && s.y === railpath.pointB.y);
    }

    //// ADD CURVATURE TO RAIL PATHS ////

    const miscInfo = {
      bestCommodityRateMultiplierEver: stationsArray.reduce((acc, station) => {
        for (const { multiplier } of station.waxRRContractData.type_rates) {
          if (multiplier > acc) {
            acc = multiplier;
          }
        }
        return acc;
      }, 0),
    };

    const decorations = DecorationPlacement;
    const specialObjects = SpecialObjectsPlacement;
    const superSpecial = SecsOfCent;

    return {
      railPaths,
      stations,
      stationsArray,
      decorations,
      specialObjects,
      superSpecial,
      miscInfo,
    };
  }
}

export type MapData = {
  railPaths: RailPath[];
  stations: Map<WAX.StationAssetId, StationEntity>;
  stationsArray: StationEntity[];
  decorations: {
    textureId: string;
    x: number;
    y: number;
    flipped?: boolean;
  }[];
  specialObjects: {
    textureId: string;
    x: number;
    y: number;
    flipped?: boolean;
    dotColor: number;
    buildingName: string;
    buildingSprite: string;
    buildingRegionId: number;
    buildingImage: string;
    buildingDesc: string;
}[];
  superSpecial: {
    textureId: string;
    x: number;
    y: number;
    flipped?: boolean;
    clickable: boolean;
    url: string;
  }[];
  miscInfo: {
    bestCommodityRateMultiplierEver: number;
  };
};
