import { GameSingletons } from "@game/app/GameSingletons";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { ReadonlyObjectDeep } from "type-fest/source/readonly-deep";

export module TrainCondition {
  export const OK_LEVEL = 0.9;
  export const LOW_LEVEL = 0.5;

  export function shouldRepair(target: ReadonlyObjectDeep<TrainEntity>): boolean {
    return target.conditionFraction < OK_LEVEL;
  }

  export function calculateRepairPrice(train: ReadonlyObjectDeep<TrainEntity>) {
    const { max_cond, repair_price } = GameSingletons.getGameContext().gameConfigData.vars;

    const pointsToRepair = Math.round(max_cond - train.conditionPoints);
    const repairPrice = Math.round(pointsToRepair / repair_price);

    return repairPrice * 0.0001;
  }

  export function calculateLocomotiveSpeedMultiplier(train: ReadonlyObjectDeep<TrainEntity>) {
    const { max_cond } = GameSingletons.getGameContext().gameConfigData.vars;
    const fraction = train.conditionPoints / max_cond;
    if (fraction < 0.01) {
      return Math.pow(0.01, 0.75);
    } else if (fraction < 0.9) {
      return Math.pow(fraction, 0.75);
    } else {
      return 1.0;
    }
  }
}
