import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { StationEntity } from "@game/data/entities/StationEntity";
import { makeSpinnyRays } from "@game/fx/makeSpinnyRays";
import { InteractionData, InteractionEvent } from "@pixi/interaction";
import { Sprite } from "@pixi/sprite";
import { EventBus } from "@sdk/core/EventBus";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

export class SpriteWithPulsingAlpha extends Sprite {
  pulseSpeed: number | null = null;

  // render(renderer: Renderer) {
  // if (this.pulseSpeed) {
  //   this.alpha = Math.sin(this.pulseSpeed * performance.now() * 0.001) * 0.5 + 0.5;
  // } else {
  //   this.alpha = 1;
  // }
  // super.render(renderer);
  // }
}

export function makeStationVisual(data: StationEntity) {
  const { assets, ticker, animator, userData } = GameSingletons.getGameContext();

  const { x, y, name } = data;

  //// Build components

  const RAYS_SCALE = 1.1;
  const RAYS_ALPHA = 0.75;
  const commonRaysProps = {
    texture: assets.getTexture("station-sprites/rays.png"),
    tint: 0x00ffff,
    // blendMode: BLEND_MODES.ADD,
    scale: RAYS_SCALE,
    alpha: RAYS_ALPHA,
  };
  const [raysA, raysB] = makeSpinnyRays(
    [
      { ...commonRaysProps, speed: +0.41 },
      { ...commonRaysProps, speed: -0.37 },
    ],
    ticker
  );
  raysA.visible = false;
  raysB.visible = false;

  function makeSprite(texturePrefix: string | null, scale: number) {
    function getTexture(texturePrefix: string | null) {
      const textureSuffix = [`common`, `uncommon`, `rare`, `epic`, `legendary`, `mythic`][data.rarityLevel - 1];
      const textureName = texturePrefix ? `${textureSuffix}-${texturePrefix}` : `${textureSuffix}`;
      const texturePath = `station-sprites/${textureName}.png`;
      return assets.getTexture(texturePath);
    }

    const sprite = new SpriteWithPulsingAlpha(getTexture(texturePrefix));
    sprite.anchor.set(0.5);
    sprite.scale.set(scale);

    const tweeener = new TemporaryTweeener(sprite);

    let _active = false;
    return Object.assign(sprite, {
      isActive() {
        return _active;
      },
      setActive(active: boolean, skipAnimation: boolean = false) {
        if (skipAnimation) {
          sprite.alpha = active ? 1 : 0;
          sprite.visible = active;
        } else {
          if (_active === active) return;
          if (active) {
            sprite.visible = true;
            tweeener.to(sprite, {
              alpha: 1,
              duration: 0.25,
              overwrite: true,
            });
          } else {
            tweeener.to(sprite, {
              alpha: 0,
              duration: 0.7,
              overwrite: true,
              onComplete: () => {
                sprite.visible = false;
              },
            });
          }
        }
        _active = active;
      },
    });
  }

  const MARKER_SCALE = 0.4;
  const building = makeSprite(null, MARKER_SCALE);

  const hoverGlow = makeSprite("hover", MARKER_SCALE * 2);
  const highlightGlow = makeSprite("highlight", MARKER_SCALE * 2);
  const nextStopGlow = makeSprite("highlight", MARKER_SCALE * 2);

  highlightGlow.tint = 0x30ffff;
  nextStopGlow.tint = 0xffeebb;

  hoverGlow.setActive(false, true);
  highlightGlow.setActive(false, true);
  nextStopGlow.setActive(false, true);

  if (+data.assetId % 2 === 0) {
    building.scale.x *= -1;
    hoverGlow.scale.x *= -1;
    highlightGlow.scale.x *= -1;
    nextStopGlow.scale.x *= -1;
  }

  //// Events

  const events = new EventBus<{
    click: (edata: InteractionData) => void;
    hoverIn: () => void;
    hoverOut: () => void;
  }>();

  ////

  const container = Object.assign(new EnchantedContainer(), {
    data,

    building,

    hasTrains: false,
    nextStopMode: false,

    hasMenuOpen: false,

    hoverGlow,
    highlightGlow,
    nextStopGlow,

    raysA,
    raysB,

    events,

    playArrivalAnimation() {
      animator.tween.fromTo(
        [raysA, raysB],
        { pixi: { alpha: 0, scale: 0 } },
        {
          pixi: { alpha: 1, scale: RAYS_SCALE },
          duration: 0.55,
          ease: "back(6).out",
        }
      );
    },
  });
  container.addChild(raysA, raysB);
  container.addChild(building, highlightGlow, hoverGlow, nextStopGlow);
  container.position.set(x, y);

  ////

  const onClick = (e: InteractionEvent) => {
    events.emit("click", e.data);
  };
  const onHoverIn = () => {
    events.emit("hoverIn");
  };
  const onHoverOut = () => {
    events.emit("hoverOut");
  };
  container.enchantments.watch(
    () => building.worldVisible && building.worldAlpha > 0,
    onScreen => {
      onScreen ? building.on("tap", onClick) : building.off("tap", onClick);
      onScreen ? building.on("click", onClick) : building.off("click", onClick);
      onScreen ? building.on("rightclick", onClick) : building.off("rightclick", onClick);
      onScreen ? building.on("pointerover", onHoverIn) : building.off("pointerover", onHoverIn);
      onScreen ? building.on("pointerout", onHoverOut) : building.off("pointerout", onHoverOut);
      building.interactive = building.buttonMode = onScreen;
    },
    true
  );
  container.enchantments.watch(
    () => highlightGlow.isActive() && highlightGlow.pulseSpeed === null,
    showrays => {
      raysA.visible = showrays;
      raysB.visible = showrays;
    },
    true
  );

  return container;
}

export type StationVisual = ReturnType<typeof makeStationVisual>;
