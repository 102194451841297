import { StationAssetId, TrainName } from "@sdk-integration/contracts";
import { HistoryApiResponse } from "./HistoryApiResult";
import { HistoryApiResultData } from "./HistoryApiResultData";
import { HistoryApiResponseError } from "./HistoryApiServiceError";

export enum HistoryApiServiceEndpoint {
  Status = "/status",
  Stations = "/stations",
  Station = "/station",
  Railroaders = "/railroaders",
  Railroader = "/railroader",
  AdminDash = "/admin_dash",
  UseFuel = "/usefuel",
  NPCEncounter = "/npcencounter",
  RailRunLogs = "/logrun",
  PassengerTipLogs = "/logtips",
}

export class HistoryApiService {
  readonly urlBase = "https://history.api.trains.cards";
  //readonly urlBase = "https://api.trains.cards";

  async get<T extends {}>(endpoint: string, options: Record<string, string | number | boolean | undefined> = {}) {
    const url = new URL(endpoint, this.urlBase);

    if (options) {
      for (const [key, value] of Object.entries(options)) {
        if (value != undefined) {
          url.searchParams.set(key, value.toString());
        }
      }
    }

    const response = await fetch(url.toString());

    let result = null as null | HistoryApiResponse<T>;
    try {
      result = await response.json();
    } catch (e) {
      console.error(e);
    }

    const errorDetails = result && "detail" in result ? result.detail : null;

    if (!response.ok || errorDetails) {
      throw new HistoryApiResponseError(response, errorDetails);
    }

    if (result && "data" in result) {
      return result.data;
    }

    return null;
  }

  /**
   * @param station The station asset id.
   * @param hrTimeframe The timeframe param is hours integer, default is 24 hours.
   *
   * Examples:
   * * **7 days** would be timeframe=168
   * * **30 days** would be timeframe=720
   * * **ALL** data would be timeframe=0
   *
   * @returns An object of type HistoryApiResultData.StationDatum
   */
  async getStatationStats(station: StationAssetId, hrTimeframe: number) {
    return await this.get<HistoryApiResultData.StationDatum>(HistoryApiServiceEndpoint.Station, {
      station,
      timeframe: hrTimeframe,
    });
  }

  /**
   * @param station The station asset id.
   * @param hrTimeframe The timeframe param is hours integer, default is 24 hours.
   *
   * Examples:
   * * **7 days** would be timeframe=168
   * * **30 days** would be timeframe=720
   * * **ALL** data would be timeframe=0
   *
   * @returns An object of type HistoryApiResultData.StationDatum
   */
  async getRailRunsStats(options: {
    railroader?: string;
    arrive_station?: string;
    depart_station?: string;
    station_owner?: string;
    century?: string;
    train_name?: string;
    before?: string;
    after?: string;
    limit?: number;
    simple?: boolean;
    order?: "asc" | "dec";
  }) {
    return await this.get<HistoryApiResultData.RailRunDatum[]>(HistoryApiServiceEndpoint.RailRunLogs, options);
  }

  /**
   *
   */
  async getRailroaders(options: { before?: string; after?: string; limit?: number }) {
    return await this.get<any>(HistoryApiServiceEndpoint.Railroaders, options);
  }

  async getRailroaderStats(railroader: string, since?: string, train?: TrainName) {
    const results = await this.get<
      {
        name: string;
        npc_encounters: number;
        total_transports: number;
        total_distance: number;
        total_reward: number;
        avg_reward: number;
        total_weight: number;
        avg_weight: number;
        total_coal: number;
        avg_coal: number;
        total_diesel: number;
        avg_diesel: number;
        visited_stations: { [key: string]: number };
      }[]
    >(HistoryApiServiceEndpoint.Railroader, {
      railroader,
      after: since,
      timeframe: 0,
      train: train,
    });
    return results?.[0];
  }

  /**
   *
   */
  async getStations(options: { before?: string; after?: string; timeframe?: number; limit?: number }) {
    return await this.get<any>(HistoryApiServiceEndpoint.Stations, options);
  }
}
