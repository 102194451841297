/**
 * Peforms an AJAX request to the specified URL, via fetch API.
 */
export async function ajax(sourceUrl: string, destinationDom?: HTMLElement) {
  const response = await fetch(sourceUrl);
  const text = await response.text();
  if (destinationDom) {
    destinationDom.innerHTML = text;
  }
  return text;
}

export async function ajaxIntoNewDiv(sourceUrl: string) {
  const response = await fetch(sourceUrl);
  const text = await response.text();
  const div = document.createElement("div");
  div.innerHTML = text;
  return div;
}
