import { Rectangle } from "@pixi/math";
import { IPoint, IRect, IWithLRTB, Vec4 } from "@sdk/core/common.types";

export class CullingArea {
  public readonly area = new Rectangle();
  public tolerance = [0, 0, 0, 0] as Vec4;

  updateArea({ x, y, width, height }: IRect): void {
    this.area.x = x - this.tolerance[0];
    this.area.width = width + this.tolerance[1] * 2;
    this.area.y = y - this.tolerance[2];
    this.area.height = height + this.tolerance[3] * 2;
  }

  isInView(target: IWithLRTB | IPoint): boolean {
    if ("lrtb" in target) {
      return (
        target.lrtb[0] <= this.area.right &&
        target.lrtb[1] >= this.area.left &&
        target.lrtb[2] <= this.area.bottom &&
        target.lrtb[3] >= this.area.top
      );
    } else {
      return (
        target.x <= this.area.right &&
        target.x >= this.area.left &&
        target.y <= this.area.bottom &&
        target.y >= this.area.top
      );
    }
  }
}
