import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { FontFamily } from "@game/constants/FontFamily";
import { Container, DisplayObject } from "@pixi/display";
import { AssetsManager } from "@game/app/services/AssetsManager";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import {createItemContainer, addPairedPointerdownListeners} from '@game/ui/buildings/bldgUtility';
import { ViewSize } from "@game/app/services/ViewSize";


export class buildingPanel extends EnchantedContainer {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly context = GameSingletons.getGameContext();
  private readonly assets: AssetsManager = GameSingletons.getResources();
  private readonly tweeener = new TemporaryTweeener(this);
  public readonly bg:any;
  public readonly parent = this.context.stageContainers._bldg;


  interactive = true;
  interactiveChildren = true;
  protected unfoldOnShowAnimation = true;
  public fullyShownAndUnfolded = false;

  constructor(
    name:string, 
    sprite:string,
    regionId:number,
    image:string,
    desc:string
    ) {
    super();
/* console.log(this); */

const { sfx, viewSize, ticker, animator } = GameSingletons.getGameContext();

    //// bg
    const bg = this.factory.createSprite("bottom panel.png");
    bg.scale.set(.9);
    bg.interactive = true;
   

     //// text bg
     const textBg = this.factory.createSprite("text BG.png");
     textBg.scale.set(1);
     textBg.position.set((bg.width/2-textBg.width/3.5),205);
   

     //// Building Image
     const buildingImage = this.factory.createSprite(image);
     buildingImage.scale.set(.9);
     buildingImage.position.set((bg.width/1.725-textBg.width),85);
    

    //name badge
    const nameBadge = this.factory.createSprite("location-name.png");
    nameBadge.scale.set(.9);
    nameBadge.position.set((bg.width/1.75-textBg.width),300);
   

    //name
    const buildingName = this.factory.createText(name.toUpperCase(), 
    { fontSize: 20, fontFamily: FontFamily.Default, dropShadow:true },{ x: nameBadge.x + (nameBadge.width/4), y: nameBadge.y+(nameBadge.height/3) });
   

    //Description
    const buildingDesc = this.factory.createText(desc, 
    { fontSize: 18, fontFamily: FontFamily.Default},{ x: textBg.x+(textBg.width/4), y: textBg.y+20 });


  // Panel Container
const panelContainer = createItemContainer({
  x: .5,
  y: 0
}, {
  x: 0,
  y: 0
}, bg.width);
panelContainer.addChild(bg,textBg,buildingImage,nameBadge,buildingName,buildingDesc);
this.addChild(panelContainer);

  }

}


