import { GameSingletons } from "@game/app/GameSingletons";
import { createXButton, XButton } from "@game/ui/components/createXButton";
import { AchievementBadgeData } from "@game/ui/social/achievements/AchievementBadgeData";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { AchievementsRRDashUserList } from "./AchievementsRRDashSidePanelUserList";

const sidePanelCommonStyles = {
  fontName: "Celestial Typeface",
  fontSize: 24,
  fill: 0xffffff,
};

export class AchievementsRRDashUserListSidePanel extends Container {
  private readonly factory = GameSingletons.getSimpleObjectFactory();
  private readonly context = GameSingletons.getGameContext();

  private usersPad: Sprite = this.context.simpleFactory.createSprite(
    "ui-railroader-dashboard/page-achievements/player-list-bg.png"
  );

  public readonly background;
  public closeButton?: XButton;

  constructor(badge: AchievementBadgeData, userNameHolder: string[]) {
    super();

    //// Add background
    this.background = this.factory.createSprite("ui-social/right-panel/expanded/base.png");
    this.addChild(this.background);
    const { simpleFactory } = GameSingletons.getGameContext();
    const titlePad = simpleFactory.createSprite("ui-railroader-dashboard/page-achievements/ach-type bg.png");
    const title = new Text(badge.name, {
      ...sidePanelCommonStyles,
    });
    const tweeener = new TemporaryTweeener(this.background);
    let titleIcon: Sprite;

    titlePad.anchor.set(0.5);
    titlePad.position.set(271, 73);

    // if (badge.iconTextureId != undefined) {
    //   titleIcon = simpleFactory.createSprite(badge.iconTextureId);
    //   titleIcon.scale.set(0.66);
    // } else 
    {
      titleIcon = simpleFactory.createSprite(badge.textureId);
      titleIcon.scale.set(0.33);
    }

    titleIcon.anchor.set(0.5);
    titleIcon.position.set(titlePad.x - 130, titlePad.y);

    title.anchor.set(0.5);

    // if (badge.backgroundTint != 16777215 && badge.backgroundTint != undefined) titlePad.tint = badge.backgroundTint;
    // else 
    titlePad.tint = 0x02215d;

    this.background.addChild(titlePad, titleIcon, title);

    if (badge.tierDot != undefined) {
      const tierLevel = new Text("LVL " + badge.tierDot?.toString(), {
        ...sidePanelCommonStyles,
      });
      tierLevel.anchor.set(0.5);
      tierLevel.position.set(titlePad.x - 70 + titlePad.width / 2, titlePad.y);
      this.background.addChild(tierLevel);
      title.position.set(titlePad.x, titleIcon.y);
      if (title.width > 130) {
        title.width = 130;
      }
    } else {
      if (title.width > 210) {
        title.width = 210;
      }
      title.position.set(titlePad.x + 15, titleIcon.y);
    }

    ////Add UsersListPad And Data
    const usersPad = this.usersPad;

    usersPad.anchor.set(0.5);
    usersPad.position.set(titlePad.x, 422);

    const usersList = new AchievementsRRDashUserList(badge); ////needs real userlist
    usersList.initialize();
    usersList.pivot.set(0.5);
    usersList.position.set(titlePad.x - titlePad.width * 0.6 + 2, titlePad.y + 60);

    this.background.addChild(usersPad);
    this.background.addChild(usersList);
  }

  public addCloseButton = (onClick: () => unknown) => {
    const button = createXButton();
    button.scale.set(0.8);
    button.position.set(45, 22);
    button.behavior.on({
      trigger: () => {
        onClick();
        button?.destroy({ children: true });
      },
    });
    return this.addChild(button);
  };
}
