import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { FontFamily } from "@game/constants/FontFamily";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { NFTDataModel } from "@sdk-integration/ethereum/eth-nft-data-models";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { AvatarBadge } from "../social/avatar/AvatarBadge";

export class ViewEthereumNFTModal extends Container {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();

  constructor() {
    super();

    //// bg
    const bg = this.factory.createSprite("ui-ethereum-integration/base-with-icons.png");
    bg.scale.set(0.75);
    this.addChild(bg);

    //// x
    const empty = this.factory.createSprite(Texture.EMPTY, { x: 25, y: 25 });
    empty.width = 25;
    empty.height = 35;
    this.addChild(empty);
    buttonizeDisplayObject(empty, () => {
      this.destroy();
    });

    //// profile
    const ownedBy = this.factory.createSprite("ui-ethereum-integration/owned-by.png", { x: 750, y: 550 });
    ownedBy.scale.set(0.75);
    this.addChild(ownedBy);
  }

  fillInfo(info: { title: string; titleSub: string; properties: Record<string, string> }) {
    const titleText = this.factory.createText(
      info.title.toUpperCase(),
      { fontSize: 46, fontFamily: FontFamily.Croogla },
      { x: 75, y: 100 }
    );
    this.addChild(titleText);

    const titleSubText = this.factory.createText(
      info.titleSub.toUpperCase(),
      {
        fontSize: 14,
        wordWrap: true,
        wordWrapWidth: 385,
        align: "center",
        lineHeight: 18,
      },
      {
        x: 85,
        y: 165,
      }
    );
    this.addChild(titleSubText);

    const propertiesText = this.factory.createText(
      "properties".toUpperCase(),
      { fontSize: 32, fontFamily: FontFamily.Croogla },
      { x: 75, y: 255 }
    );
    this.addChild(propertiesText);

    let attributesListString = "";
    for (const [propertyKey, propertyValue] of Object.entries(info.properties)) {
      attributesListString += `${propertyKey} - ${propertyValue}\n`.toUpperCase();
    }
    const attributesList = this.factory.createText(
      attributesListString,
      { fontSize: 16, lineHeight: 19 },
      { x: 100, y: 300 }
    );
    this.addChild(attributesList);

    const maxAttributesListHeight = 220;
    if (attributesList.height > maxAttributesListHeight) {
      attributesList.scale.set(maxAttributesListHeight / attributesList.height);
    }
  }

  async addNftImage(textureId: string) {
    const texture = await Texture.fromURL(textureId);
    const nft = this.factory.createSprite(texture, { x: 550, y: 105 });
    nft.width = 400;
    nft.height = 400;
    this.addChild(nft);
  }

  async addAvatarBadge(username: string) {
    const avatar = new AvatarBadge();
    avatar.scale.set(0.225);
    avatar.position.set(875, 425);
    await AvatarBadge.applyUserData(avatar, username);
    avatar.showMedalArc(true);
    this.addChild(avatar);
  }

  async addOpenSeaButton(nftData: NFTDataModel) {
    //// opensea button
    const openSeaButton = this.factory.createSprite("ui-ethereum-integration/btn.png");
    openSeaButton.position.set(90, 525);
    openSeaButton.scale.set(0.75);
    const textBtn = this.factory.createText("view on opensea".toUpperCase(), { fontSize: 28 }, { x: 120, y: 18 });
    openSeaButton.addChild(textBtn);
    this.addChild(openSeaButton);

    const tokenId = parseInt(nftData.id.tokenId, 16);
    const openSeaUrl = `https://opensea.io/assets/ethereum/${nftData.contract.address}/${tokenId}`;
    buttonizeDisplayObject(openSeaButton, () => window.open(openSeaUrl, "_blank")?.focus());
  }
}

export module ViewEthereumNFTModal {
  export function fromNFTData(data: {
    nftData: NFTDataModel;
    textureURL?: string;
    ownerUsername?: string;
  }): ViewEthereumNFTModal {
    const { metadata } = data.nftData;
    const textureURL = data.textureURL || `https://ipfs.trains.cards/ipfs/${metadata.image?.replace(`ipfs://`, ``)}`;

    const popup = new ViewEthereumNFTModal();
    popup.addNftImage(textureURL);
    popup.fillInfo({
      title: metadata.name,
      titleSub: metadata.description,
      properties: metadata.attributes.reduce((acc, cur) => {
        acc[cur.trait_type] = cur.value;
        return acc;
      }, {} as Record<string, string>),
    });

    popup.addOpenSeaButton(data.nftData);

    if (data.ownerUsername) popup.addAvatarBadge(data.ownerUsername);

    return popup;
  }
}
