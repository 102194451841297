import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { EnchantedSprite } from "@game/core/enchanted-classes";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Graphics } from "@pixi/graphics";
import { Point } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { waitUntil } from "@sdk/utils/frameloops/waitUntil";
import { createDistanceLayerFactory } from "./factories/distanceDisplayFactory";
import { createRadioDisplayFactory } from "./factories/radioControllerFactory";
import { createBubblesLayerFactory } from "./factories/stakigBubbleFactory";

export class CheckBox extends Sprite {
  public context = GameSingletons.getGameContext();
  private textureTicked: Texture;
  private textureUnticked: Texture;

  constructor() {
    super();
    const { assets } = this.context;
    this.textureTicked = assets.getTexture("ui-common/checked.png");
    this.textureUnticked = assets.getTexture("ui-common/unchecked.png");
    this.texture = this.textureUnticked;
  }
  setTicked(ticked: boolean) {
    this.texture = ticked ? this.textureTicked : this.textureUnticked;
  }
}

export class ToggleMenuEntry extends Container {
  public checkBox = new CheckBox();
  public ticked: boolean = false;

  constructor(text: string, ticked: boolean, onToggle: () => unknown) {
    super();

    let stationToggleText = new Text(text, { fill: 0xffffff, fontSize: 15, fontFamily: FontFamily.Default });
    this.ticked = ticked;

    buttonizeDisplayObject(stationToggleText, onToggle);

    this.checkBox.setTicked(this.ticked);
    this.checkBox.position.set(-15, 10);
    this.checkBox.anchor.set(0.5);

    this.addChild(stationToggleText);
    stationToggleText.addChild(this.checkBox);
  }
}

export function createMapToggleMenu() {
  const { main, app, assets, events, storage } = GameSingletons.getGameContext();

  let toggleMenuOpen = false;
  let toggleStakingBubblesButton: ToggleMenuEntry;
  let toggleDistancesButton: ToggleMenuEntry;
  let toggleRadioDisplayButton: ToggleMenuEntry;
  let toggleNightMode: ToggleMenuEntry;

  let bubblesToggle = createBubblesLayerFactory();
  let distanceToggle = createDistanceLayerFactory();
  let menuLine: Graphics;

  let stakingButtonToggled = false;
  let distanceButtonToggled = false;
  let radioButtonToggled = true;
  let nightModeButtonToggled = true;

  const visibleTexture = assets.getTexture("ui-common/eye.png");
  const padding = new Point(25, 140);
  const screenAnchor = new Point(1.0, 0.0);
  const button = new EnchantedSprite(visibleTexture);
  const radioToggle = createRadioDisplayFactory();

  events.on({ resize: updateIndicatorPositions });

  waitUntil(() => GameSingletons.getMainInstance()).then(() => {
    if (storage.showRadio) radioToggle.show();
    if (!!toggleRadioDisplayButton) {
      toggleRadioDisplayButton.checkBox.setTicked(radioButtonToggled);
    }
 
    
  });

  updateIndicatorPositions(app.screen);

  button.zIndex = 100;
  button.alpha = 0.4;
  button.scale.set(0.25);
  button.anchor.copyFrom(screenAnchor);
  buttonizeDisplayObject(button, () => {
    if (toggleMenuOpen == false) {
      toggleStakingBubblesButton = createMapToggleMenuButton(
        "Staking Hubs",
        stakingButtonToggled,
        setStationAddonBubblesVisibility
      );
      toggleDistancesButton = createMapToggleMenuButton(
        "Distances",
        distanceButtonToggled,
        setStationDistancesVisibility
      );
      toggleRadioDisplayButton = createMapToggleMenuButton("Radio", radioButtonToggled, setRadioDisplayVisibility);

      toggleNightMode = createMapToggleMenuButton("Night Mode", nightModeButtonToggled, setNightModeVisibility);
      menuLine = createMenuLine();

      toggleStakingBubblesButton.position.set(button.x - 80, button.y + 45);
      toggleDistancesButton.position.set(button.x - 80, button.y + 65);
      toggleRadioDisplayButton.position.set(button.x - 80, button.y + 85);
      toggleNightMode.position.set(button.x - 80, button.y + 105);
      menuLine.position.set(button.x - 48, button.y + 30);

      app.stage.addChild(toggleStakingBubblesButton, toggleDistancesButton, toggleRadioDisplayButton,toggleNightMode, menuLine);
      toggleMenuOpen = true;
    } else {
      destroyMapToggleMenu();
      toggleMenuOpen = false;
    }
  });

  function createMapToggleMenuButton(text: string, ticked: boolean, onToggle: () => unknown) {
    let stationToggleText = new ToggleMenuEntry(text, ticked, onToggle);
    button.alpha = 0.8;

    return stationToggleText;
  }

  function createMenuLine() {
    let menuLine = new Graphics();
    menuLine.clear();
    menuLine.beginFill(0xffffff);
    menuLine.drawRect(0, 0, 60, 3);
    menuLine.endFill();

    return menuLine;
  }

  function destroyMapToggleMenu() {
    if (toggleStakingBubblesButton != undefined) {
      button.alpha = 0.4;
      toggleStakingBubblesButton.destroy();
      toggleDistancesButton.destroy();
      toggleRadioDisplayButton.destroy();
      toggleNightMode.destroy();
      menuLine.destroy();
      toggleMenuOpen = false;
    }
  }
  
  function setRadioDisplayVisibility() {
    if (!radioButtonToggled) {
      radioButtonToggled = true;
      toggleRadioDisplayButton.checkBox.setTicked(radioButtonToggled);
      radioToggle.show();
      storage.showRadio = true;
    } else {
      radioButtonToggled = false;
      toggleRadioDisplayButton.checkBox.setTicked(radioButtonToggled);
      radioToggle.hide();
      storage.showRadio = false;
    }
  }

  function setNightModeVisibility() {
    const { main } = GameSingletons.getGameContext();
    if (!nightModeButtonToggled) {
      nightModeButtonToggled = true;
      toggleNightMode.checkBox.setTicked(nightModeButtonToggled);
      main.nightModeEnabled =!main.nightModeEnabled;
      console.log('NIGHTMODE-',main.nightModeEnabled);
    } else {
      nightModeButtonToggled = false;
      toggleNightMode.checkBox.setTicked(nightModeButtonToggled);
      main.nightModeEnabled =!main.nightModeEnabled;
      console.log('NIGHTMODE-',main.nightModeEnabled);
    }
  }

  function setStationAddonBubblesVisibility() {
    if (!stakingButtonToggled) {
      stakingButtonToggled = true;
      toggleStakingBubblesButton.checkBox.setTicked(stakingButtonToggled);
      bubblesToggle.show();
    } else {
      stakingButtonToggled = false;
      toggleStakingBubblesButton.checkBox.setTicked(stakingButtonToggled);
      bubblesToggle.hide();
    }
  }

  function setStationDistancesVisibility() {
    if (!distanceButtonToggled) {
      distanceButtonToggled = true;
      toggleDistancesButton.checkBox.setTicked(distanceButtonToggled);
      distanceToggle.show();
    } else {
      distanceButtonToggled = false;
      toggleDistancesButton.checkBox.setTicked(distanceButtonToggled);
      distanceToggle.hide();
    }
  }

  function updateIndicatorPositions({ width, height }: { width: number; height: number }) {
    button.position.set(
      padding.x + (width - padding.x - padding.x) * screenAnchor.x,
      padding.y + (height - padding.y - padding.y) * screenAnchor.y
    );
  }
  return button;
}

export type MapToggle = ReturnType<typeof createMapToggleMenu>;
