import { GameSingletons } from "@game/app/GameSingletons";
import { AirtableDataService } from "@sdk-integration/airtable/AirtableDataService";
import { createUpdatesTypeModal } from "./createUpdatesModalBase";

export async function showUpdatesModalIfUnread() {
  const { firebase } = GameSingletons.getGameContext();
  const userData = await firebase.getUserMainData();
  const readUpdates = userData.readUpdates || {} as { [key: string]: boolean };
  
  const dataService = new AirtableDataService();
  const updates = await dataService.getUpdateNotesData();

  if (!updates) return;

  let hasUnreadUpdates = false;

  for (let update of updates) {
    const { uuid } = update;
    if (!readUpdates[uuid]) {
      hasUnreadUpdates = true;
      firebase.updateUserMainData({ readUpdates: { [uuid]: true } });
    }
  }

  if (hasUnreadUpdates) {
    createUpdatesTypeModal(false);
  }
}
