import { HttpApiService } from "@sdk/http/HttpApiService";
import { AchievementBadgeData } from "./AchievementBadgeData";
import { getAchievementBadgeDataBlueprint } from "./AchievementBadgeDataBlueprint";
import { injetOttoExtraAchievements } from "./ottoAchievements";

type AchievementApiItemData = {
  id: number;
  achv_id: number;
  criteria: string;
  name: string;
  railroader_id: number;
  reached: true;
  reached_date_timestamp: number;
  tier: number;
  type: string;
  value: number;
};

type AchievementsApiResponseJsonData = {
  data: {
    achievements: AchievementApiItemData[];
    roader_meta: {
      id: number;
      total_miles_pallet: number;
      otto_meets: number;
      name: string;
      total_miles_perishable: number;
      total_miles_crate: number;
      stranger_meets: number;
      total_miles_oversized: number;
      first_run_stamp: number;
      total_miles_liquid: number;
      total_miles: number;
      total_miles_building_materials: number;
      total_miles_gas: number;
      total_runs: number;
      total_miles_automobile: number;
      total_miles_aggregate: number;
      conseq_day: number;
      total_miles_top_secret: number;
      total_miles_ore: number;
      last_run_stamp: number;
      npc_encounter: number;
      total_miles_granule: number;
      total_miles_grain: number;
    };
  }[];
};

// const ENDPOINT = `https://dev.train.api.cmstats.net`;
const ENDPOINT = `https://achv.api.trains.cards`;

const achievementIdBlacklist = [10, 11, 12, 13, 14];

export async function loadRailroaderAchievementsApiData(railroader: string) {
  try {
    const dataService = new HttpApiService(ENDPOINT);
    const response = await dataService.get<AchievementsApiResponseJsonData>(`roader`, { railroader });
    return response?.data[0];
  } catch (error) {
    console.error(`Error loading railroader achievements:`, error);
    return null;
  }
}

export async function loadRailroaderAchievementsData(railroader: string): Promise<AchievementBadgeData[]> {
  try {
    const data = await loadRailroaderAchievementsApiData(railroader);
    if (!data) return [];
    if (!data.achievements) return [];
    const achievements = data.achievements.filter(achievement => !achievementIdBlacklist.includes(achievement.achv_id));
    const achievementBadgeDataObjects = achievements.map(convertAchievementApiItemDataToBadgeProperties);
    await injetOttoExtraAchievements(achievementBadgeDataObjects);
    achievementBadgeDataObjects.sort((a, b) => a.achievementId - b.achievementId);
    return achievementBadgeDataObjects;
  } catch (error) {
    console.error(`Error loading railroader achievements:`, error);
    return [];
  }
}

function convertAchievementApiItemDataToBadgeProperties(data: AchievementApiItemData): AchievementBadgeData {
  const DEFAULT_PAD = `Badge`;

  const blueprint = getAchievementBadgeDataBlueprint(data.achv_id);

  if (!blueprint) {
    console.warn(`Unknown badge: ${data.name} (${data.achv_id})`);
    return {
      achievementId: -1,
      name: `Error!`,
      description: `Unknown badge: "${data.name}" (achv_id: ${data.achv_id})`,
      textureId: `ui-achievements/${DEFAULT_PAD}.png`,
    };
  }

  return blueprint;
}
