import type * as WAX from "@sdk-integration/contracts";

import { CardType } from "@game/constants/CardType";
import { Rarity, RarityInteger } from "@game/constants/Rarity";

const maps = {
  waxCardSchemaToType: {
    ["locomotive"]: CardType.Locomotive,
    ["conductor"]: CardType.Conductor,
    ["railcar"]: CardType.RailCar,
    ["passengercar"]: CardType.RailCar,
    ["commodity"]: CardType.Loadable,
    ["passenger"]: CardType.Loadable,
  },
  waxRarityToRarity: {
    ["Common"]: RarityInteger.Common,
    ["Uncommon"]: RarityInteger.Uncommon,
    ["Rare"]: RarityInteger.Rare,
    ["Epic"]: RarityInteger.Epic,
    ["Legendary"]: RarityInteger.Legendary,
    ["Mythic"]: RarityInteger.Mythic,
    ["Century Train"]: RarityInteger.CT,
  },
};

export module CardEntity {
  export type AssetId = WAX.CardAssetId;
  export type TemplateId = WAX.AssetTemplateId;
}

export class CardEntity<T extends WAX.CardData = WAX.CardData> {
  constructor(public readonly data: T) {}

  public readonly assetId: CardEntity.AssetId = this.data.asset_id; // unique id
  public readonly templateId: CardEntity.TemplateId = this.data.asset_template.template_id as CardEntity.TemplateId;
  public readonly type: CardType = maps.waxCardSchemaToType[this.data.asset_schema_type];
  public readonly title: WAX.CardAssetData.Name = this.data.name;
  public readonly rarity: number = maps.waxRarityToRarity[this.data.rarity];
  public readonly rarityString: Rarity = this.data.rarity.toLowerCase() as Rarity & WAX.AssetRarity;

  public readonly stats = this.data;

  public get owner(): WAX.AccountName {
    return this.data.owner;
  }

  public get schemaType(): T["asset_schema_type"] {
    return this.data.asset_schema_type;
  }

  public get imageIPFSHash() {
    return this.data.img;
  }

  public assetError: Error | null = null;
  get isInvalid(): boolean {
    return this.assetError != null;
  }

  public assignedTrain: any | null = null;

  public __texturePathOverride?: string;
}

export module CardEntity {
  export function isTypeConductor(card: CardEntity): card is CardEntity<WAX.CardAssetData_Conductor> {
    return card.type === CardType.Conductor;
  }

  export function isTypeLocomotive(card: CardEntity): card is CardEntity<WAX.CardAssetData_Locomotive> {
    return card.type === CardType.Locomotive;
  }

  export function isTypeWagon(card: CardEntity): card is CardEntity<WAX.CardAssetData_Wagon> {
    return card.type === CardType.RailCar;
  }
  export function isTypeCommodityWagon(card: CardEntity): card is CardEntity<WAX.CardAssetData_CommodityWagon> {
    return card.type === CardType.RailCar && card.data.asset_schema_type === "railcar";
  }
  export function isTypePassengerWagon(card: CardEntity): card is CardEntity<WAX.CardAssetData_PassengerWagon> {
    return card.type === CardType.RailCar && card.data.asset_schema_type === "passengercar";
  }

  export function isTypeLoadable(card: CardEntity): card is CardEntity<WAX.CardAssetData_Loadable> {
    return card.type === CardType.Loadable;
  }
  export function isTypeCommodityLoadable(card: CardEntity): card is CardEntity<WAX.CardAssetData_CommodityLoadable> {
    return card.type === CardType.Loadable && card.data.asset_schema_type === "commodity";
  }
  export function isTypePassengerLoadable(card: CardEntity): card is CardEntity<WAX.CardAssetData_PassengerLoadable> {
    return card.type === CardType.Loadable && card.data.asset_schema_type === "passenger";
  }
}

export module CardEntity {
  const cache = new Map<WAX.CardAssetId, CardEntity>();

  export async function addToCache(...cards: CardEntity[]) {
    for (const card of cards) {
      cache.set(card.assetId, card);
    }
  }

  async function loadCardEntity(cardAssetId: WAX.CardAssetId) {
    const { GameSingletons } = await import("@game/app/GameSingletons");
    const { contracts } = GameSingletons.getIntergrationServices();
    const result = await contracts.assets.getSingleCardAsset(cardAssetId);

    console.log("loadCardEntity", result);

    const entity = new CardEntity(result);
    cache.set(cardAssetId, entity);
    return entity;
  }

  // async function loadCardEntities(...cardAssetIds: WAX.CardAssetId[]) {
  //   const { GameSingletons } = await import("@game/app/GameSingletons");
  //   const { contracts } = GameSingletons.getIntergrationServices();
  //   const results = await contracts.assets.getCardAssets(cardAssetIds);
  //   const entities = results.map(result => new CardEntity(result));
  //   return entities;
  // }

  // export async function getCardEntities(...cardAssetIds: WAX.CardAssetId[]) {
  //   const cardAssetIdsMissingFromCache = cardAssetIds.filter(id => !cache.has(id));
  //   if (cardAssetIdsMissingFromCache.length > 0) {
  //     const cards = await loadCardEntities(...cardAssetIdsMissingFromCache);
  //     await addToCache(...cards);
  //   }
  //   return cardAssetIds.map(id => cache.get(id));
  // }

  export async function fromAssetId(cardAssetId: WAX.CardAssetId) {
    if (!cache.has(cardAssetId)) {
      await loadCardEntity(cardAssetId);
    }

    if (!cache.has(cardAssetId)) {
      throw new Error(`CardEntity not found for id: ${cardAssetId}`);
    }

    return cache.get(cardAssetId)!;
  }
}
