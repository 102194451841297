import { CardEntity } from "@game/data/entities/CardEntity";
import { CardAssetData_Locomotive } from "@sdk-integration/contracts";

const LOCO_COMPOSITION_TO_FRAME_FILENAME: Record<string, string> = {
  "iron": "iron",
  "steel": "steel",
  "carbon weave": "carbon",
  "tungsten": "tungsten",
};

export function getTrainStatusPanelFrameTextureId(
  cardOrComposition: CardEntity<CardAssetData_Locomotive> | string | null
) {
  if (cardOrComposition == null) {
    return `ui-repair/left-panel/iron.png`;
  }

  if (cardOrComposition instanceof CardEntity) {
    cardOrComposition = cardOrComposition.data.composition;
  }

  const filename = LOCO_COMPOSITION_TO_FRAME_FILENAME[cardOrComposition];
  if (filename) {
    return `ui-repair/left-panel/${filename}.png`;
  }

  return null;
}
