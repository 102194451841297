import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Texture } from "@pixi/core";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";

export class textScroll extends SafeScrollbox {
  private readonly text: string;
  private readonly fontChoice: string;
  private font : FontFamily = FontFamily.Orson;
  private fontSize: number = 28;
  private lineHeight: number = 34;

  constructor(text:string,fontChoice:string) {
    super({
      noTicker: true,
      boxWidth: 620,
      boxHeight: 460,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflow: "none",
    });
    this.text = text;
    this.fontChoice = fontChoice;
//Fonts
    switch(fontChoice) {
      case "Default":
          this.font = FontFamily.Default;
          break;
      case "DefaultThin":
          this.font = FontFamily.DefaultThin;
          this.fontSize = 20;
          this.lineHeight = 26;
           break;
      default:
          this.font = FontFamily.Orson;
          this.fontSize =28;
          this.lineHeight = 34;
          break;
  }
  }

  async initialize() {
   
    const createTextContainer = async () => {
      this.clearList();
      const { simpleFactory, ticker } = GameSingletons.getGameContext();
      const textContainer = new Container();
      this.content.addChild(textContainer);
      
       //// Add Event Body text
       const scrollingText = simpleFactory.createText(this.text, {
        fontFamily: this.font,
        fontSize: this.fontSize,
        fill: 0xFFFFFF,
        wordWrap: true,
        wordWrapWidth: 515,
        lineHeight: this.lineHeight,
      });

      scrollingText.anchor.set(0.5, 0);
      scrollingText.position.set(312,0);
      textContainer.addChild(scrollingText);

      await ticker.nextFrame();
      this.content.addChild(this.addInvisibleBox(426));
      this.update();
    };

    createTextContainer();
  }
  
  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth + 1;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}