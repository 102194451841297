import { env } from "@game/app/global";
import { openLink } from "@game/gameplay/externals/openLink";
import { AirtableApiService } from "./AirtableApiService";

enum CommunicationsTableView {
  RRDash = "In RRDash",
  Updates = "In Updates",
}

export class AirtableDataService {
  private readonly apiService = new AirtableApiService();

  private async getCommunicationsRecords(view: CommunicationsTableView) {
    const records = await this.apiService.getTableRecords("Communications", view);
    if (!records) return null;

    const filterByStatus = (record: typeof records[number]) => {
      const status = record.fields.Status;
      if (env.BLOCKCHAIN === "mainnet") {
        return status === "Mainnet";
      }
      if (env.BLOCKCHAIN === "testnet") {
        return status === "Testnet" || status === "Mainnet";
      }
      return false;
    };

    function processPropertyValue<T = string>(
      value: string | undefined,
      process?: (value: string) => T
    ): T | undefined {
      if (typeof value === "string") value = value.trim();
      if (!value) return undefined;
      return process ? process(value) : (value as any as T);
    }

    const adaptedData = records.filter(filterByStatus).map(record => {
      const { fields } = record;
      return {
        uuid: record.id,
        title: processPropertyValue(fields["Title"]) || "",
        summary: processPropertyValue(fields["Text"]) || "",
        footer: processPropertyValue(fields["Action Text"]),
        textureId: fields["Image"][0].url,
        actionURL: processPropertyValue(fields["Action"]),
        function: processPropertyValue(fields["Action"], v => () => openLink(v)),
        articleText: processPropertyValue(fields["Article Text"]),
        altFooter: "Read More...",
        effects: fields["Effects"],
      };
    });

    return adaptedData;
  }

  /**
   * @returns List of data objects for the event banner slides
   * featured in the Railroader Dashboard side menu.
   */
  async getRRDashEventBannersGalleryData() {
    return this.getCommunicationsRecords(CommunicationsTableView.RRDash);
  }

  /**
   * @returns List of data objects for the list of updates in the UPDATES modal.
   */
  async getUpdateNotesData() {
    return this.getCommunicationsRecords(CommunicationsTableView.Updates);
  }
}

export type EventBannersSlideData = NonNullable<
  Awaited<ReturnType<AirtableDataService["getCommunicationsRecords"]>>
>[number];
