import { __DEBUG_SETTINGS__ } from "@debug/__DEBUG_SETTINGS__";
import { __DEBUG__ } from "@debug/__DEBUG__";
import { UninitializedGameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { getUserSimpleAssetData } from "@game/asorted/data/getUserSimpleAssetData";
import { RegionIntegerId } from "@game/constants/RegionId";
import { CardAssetId, TrainData } from "@sdk-integration/contracts";
import { delay } from "@sdk/utils/promises";
import { CardEntity } from "./entities/CardEntity";
import { RailRunEntity } from "./entities/RailRunEntity";
import { TrainEntity } from "./entities/TrainEntity";
import type { UserDataHolder } from "./UserDataHolder";

export class UserDataController {
  public minimumAutoUpdateInterval = 60;
  public lastUpdateTime: number = 0;

  constructor(public readonly userData: UserDataHolder) {
    window.addEventListener("focus", () => {
      if (this.secondsSinceLastUpdate > this.minimumAutoUpdateInterval) {
        console.log("⚡ Auto update triggered");
        this.updateAll();
      }
    });
  }

  get secondsSinceLastUpdate(): number {
    return (performance.now() - this.lastUpdateTime) / 1000;
  }

  public async updateAll(context: UninitializedGameContext = GameSingletons.getGameContext()): Promise<void> {
    const { contracts } = context;

    this.lastUpdateTime = performance.now();

    await delay(0.95);

    const railroaderName = contracts.currentUserName;
    const [tocium, anomaticParticles, fuel, trains, trainUpgrades, assets, ongoingRailRuns, railroaderTableData] =
      await Promise.all([
        contracts.getRailroaderCurrentTocium(),
        getUserSimpleAssetData("AMP", context),
        contracts.getRailroaderFuelStatus(),
        contracts.getRailroaderTrains(),
        contracts.getRailroaderTrainUpgrades(),
        contracts.assets.getAccountAssets(),
        contracts.getOngoingRailRuns(),
        contracts.getRegisteredRailroaderData(),
      ]);

    const { userData } = this;

    userData.redeemableAssets.length = 0;
    userData.redeemableAssets.push(...assets.redeemables);

    const cardAssets = assets?.cards || [];

    userData.name = railroaderName;
    userData.tocium = tocium;
    userData.anomaticParticles = anomaticParticles;
    userData.fuel.coal = fuel["COAL"];
    userData.fuel.diesel = fuel["DIESEL"];

    userData.trainsOngoingRuns.clear();
    userData.stationsIncomingRuns.clear();
    for (const runData of ongoingRailRuns) {
      const entity = new RailRunEntity(runData);
      userData.trainsOngoingRuns.set(runData.train, entity);
      userData.stationsIncomingRuns.set(runData.arrive_station, entity);
    }

    userData.cards.clear();
    for (const waxCardData of cardAssets) {
      const cardEntity = new CardEntity(waxCardData);
      CardEntity.addToCache(cardEntity);
      userData.cards.set(waxCardData.asset_id, cardEntity);
    }

    userData.modifiers = assets.modifiers;

    //// TRAINS

    function greateTrain(waxTrainData: Readonly<TrainData>) {
      let train = userData.trains.get(waxTrainData.train);
      if (train != undefined) {
        Object.assign(train.waxData, waxTrainData);
      } else {
        train = new TrainEntity(waxTrainData, userData);
        userData.trains.set(train.name, train);
      }
      return train;
    }

    for (const [index, waxTrainData] of trains.entries()) {
      const train: TrainEntity = greateTrain(waxTrainData);
      train.orderIndex = index;

      if (__DEBUG_SETTINGS__["Evil-Twin-Train"]) {
        const evilTwinTrain: TrainEntity = new TrainEntity(
          { ...waxTrainData, train: (train.name + "'s evil twin") as any },
          userData
        );
        evilTwinTrain.orderIndex = index - 100;
        userData.trains.set(evilTwinTrain.name, evilTwinTrain);
      }

      const trainUpgrade = trainUpgrades.find(t => t.train === train.name);
      if (trainUpgrade) {
        train.extraSlots.rc = trainUpgrade.extra_rc_slot;
      }
    }

    userData.trainsArray.length = 0;
    userData.trainsArray.push(...userData.trains.values());

    /**
     * Check for cards that are assigned to the user's trains,
     * but are also not in the user's card inventory for any reason.
     */

    userData.invalidCards.clear();
    const trainCardAssetIds = [] as CardAssetId[];
    for (const waxTrainData of trains) {
      trainCardAssetIds.push(...waxTrainData.locomotives);
      trainCardAssetIds.push(...waxTrainData.conductors);
      for (const load of waxTrainData.load) {
        trainCardAssetIds.push(load.railcar_asset_id);
        trainCardAssetIds.push(...load.load_ids);
      }
    }
    const missingTrainCardsAssetIds = trainCardAssetIds.filter(id => !userData.cards.has(id));

    if (missingTrainCardsAssetIds.length > 0) {
      console.log("⛔ Missing train cards:", missingTrainCardsAssetIds);

      /**
       * If there are missing cards, we need to fetch their data as well from the nft assets api.
       */
      for await (const missingTrainCardAssetData of contracts.assets.iterateCardAssets(missingTrainCardsAssetIds)) {
        const { asset_id, owner } = missingTrainCardAssetData;
        const cardEntity = new CardEntity(missingTrainCardAssetData);
        CardEntity.addToCache(cardEntity);

        if (owner == railroaderName && !__DEBUG__) {
          /**
           * In theory there is no way to get to this point if both APIs work correctly, otherwise this card
           * would have been added to the user data already.
           *
           * But since we're here anyway, might as well be safe.
           */
          userData.cards.set(asset_id, cardEntity);
        } else {
          /**
           * This is a card that belongs to someone else.
           *
           * What likely happened, is that they owned the card, assigned it to one of their trains,
           * but then put the card up for sale somewhere on the open market, making it no longer theirs.
           *
           * That train is now tampered and unusable until its composition is fixed and verified.
           *
           * However we still need to show some kind of "invalid" card to the user, so that they can
           * interact with the ui properly, so we're adding it to the list of invalid cards and
           * marking it with a proper error instance.
           */
          const errorMessage = "Not owned by you";
          cardEntity.assetError = new Error(errorMessage);
          userData.invalidCards.set(asset_id, cardEntity);
        }
      }
      console.log("⛔ Invalid Cards:", userData.invalidCards.values());
    }

    //// ASSIGN TRAIN REFS TO CARDS //// TODO: Find a way to avoid this

    for (const train of userData.trains.values()) {
      for (const card of train.iterateAllEquippedCards(false)) {
        card.assignedTrain = train;
      }
    }

    //// Thomas Active Regions

    if (railroaderTableData) {
      userData.thomasActiveRegions.length = 0;
      for (const region of getThomasActiveRegionsFromTableData(
        railroaderTableData.t_regions,
        contracts.currentCenturyName
      )) {
        userData.thomasActiveRegions.push(region);
      }
      userData.thomasActiveRegions.sort();
    } else {
      console.warn("⛔ Could not fetch Thomas Active Regions for " + railroaderName);
    }

    //// Culprit Active Regions

    if (railroaderTableData) {
      userData.culpritActiveRegions.length = 0;
      for (const region of getThomasActiveRegionsFromTableData(
        railroaderTableData.c_regions,
        contracts.currentCenturyName
      )) {
        userData.culpritActiveRegions.push(region);
      }
      userData.culpritActiveRegions.sort();
    } else {
      console.warn("⛔ Could not fetch Thomas Active Regions for " + railroaderName);
    }
  }
}

const allRegions = [
  RegionIntegerId.Centuryville,
  RegionIntegerId.PembertonHeights,
  RegionIntegerId.TrevithickPines,
  RegionIntegerId.PawpawPlains,
  RegionIntegerId.JamesPark,
];
function getThomasActiveRegionsFromTableData(t_regions: undefined | { key: string; value: number }[], century: string) {
  console.log({ t_regions });
  if (!t_regions) return allRegions;
  if (!t_regions.length) return allRegions;
  try {
    t_regions = t_regions.filter(o => o.key === century && o.value in RegionIntegerId);
    if (!t_regions.length) return allRegions;
    return t_regions.map(o => o.value as RegionIntegerId);
  } catch (error) {
    console.error(error);
    return allRegions;
  }
}
function getCulpritActiveRegionsFromTableData(
  c_regions: undefined | { key: string; value: number }[],
  century: string
) {
  console.log({ c_regions });
  if (!c_regions) return allRegions;
  if (!c_regions.length) return allRegions;
  try {
    c_regions = c_regions.filter(o => o.key === century && o.value in RegionIntegerId);
    if (!c_regions.length) return allRegions;
    return c_regions.map(o => o.value as RegionIntegerId);
  } catch (error) {
    console.error(error);
    return allRegions;
  }
}
