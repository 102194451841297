import { debuggingPane } from "@debug/tweakpane";
import { lipsum } from "@debug/utils/lipsum";
import { onKeyDownWithCleanup } from "@debug/utils/onKeyPress";
import type { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { CardsDrawer } from "@game/ui/cards/CardsDrawer";
import { createValueAnimator_Counter } from "@game/ui/common/createValueAnimator_Counter";
import { ResourceCounter } from "@game/ui/hud/components/ResourceCounter";
import { Graphics } from "@pixi/graphics";
import type { InteractionManager } from "@pixi/interaction";
import { lerp2D } from "@sdk/utils/math2D";
import { getWorldScaleX } from "@sdk/utils/pixi/getWorldScaleX";

export async function addTociumCounter() {
  const context = GameSingletons.getGameContext();
  const getFormattedUserTocium = () => context.userData.tocium.toFixed(4);

  //init resource counters
  //tocium counter
  const counter = new ResourceCounter({
    iconTexture: "ui-common/icon-x.png",
  });
  //set tocium counter value
  counter.setValue(+context.userData.tocium.toFixed(4));

  //positioning and scale for debug
  counter.y += 30;
  //counter.scale.set(1.5);

  //animate increase and decrease of resource total
  createValueAnimator_Counter(
    () => +getFormattedUserTocium(),
    value => counter.setValue(+value.toFixed(4))
  );

  //add resource counters
  //tocium
  context.app.stage.addChild(counter);
}

export async function testCullingArea() {
  const context = GameSingletons.getGameContext();
  console.log({
    context,
  });
  const operationsLayer = context.world.zoomLayers.operationsLayer;
  const cullingArea = operationsLayer.culling.cullingArea;

  const g = new Graphics();
  operationsLayer.addChild(g);

  context.ticker.add(() => {
    g.clear();
    g.lineStyle(4 / getWorldScaleX(operationsLayer), 0xff0000);
    g.drawRect(cullingArea.area.x, cullingArea.area.y, cullingArea.area.width, cullingArea.area.height);
  });
}

export async function makeStationsWithTrainsMine() {
  const context = GameSingletons.getGameContext();
  const { main, mapData, userData } = context;
  const myTrains = userData.trains.values();
  for (const train of myTrains) {
    const station = mapData.stations.get(train.currentStationId!)!;
    station.waxAssetData.owner = userData.name;
  }
  main.dots.refreshSpecialDots();
}

export async function testScreenTitleChange() {
  const context = GameSingletons.getGameContext();
  const interaction = context.app.renderer.plugins.interaction as InteractionManager;
  const shiftKeyDown = interaction.eventData.data?.originalEvent.shiftKey;
  context.main.hud.title.setState(shiftKeyDown ? null : { titleText: lipsum.line() });
}

export async function debugStationLinks() {
  const context = GameSingletons.getGameContext();
  const stations = [...context.mapData.stations.values()];

  const g = new Graphics();
  context.world.addChild(g);

  const onEnterFrame = () => {
    g.clear();
    for (const stationA of stations) {
      for (const link of stationA.links) {
        const stationB = stations.find(s => s.assetId === link.assetId);
        if (!stationB) {
          continue;
        }
        const middle = lerp2D(stationA, stationB, 0.45);
        g.lineStyle(72, 0x0000ff, 0.5);
        g.moveTo(stationA.x, stationA.y);
        g.lineTo(middle.x, middle.y);
      }
    }
  };

  context.world.onEnterFrame.add(onEnterFrame);
}

/*
 * Speed up the ticker while [Q] is held down
 */
export async function controlTickerSpeedViaKeyboard() {
  const context = GameSingletons.getGameContext();
  const { ticker } = context;
  const FF = 10.0;
  onKeyDownWithCleanup("q", () => {
    ticker.speed = FF;
    gsap.globalTimeline.timeScale(FF);
    return () => {
      ticker.speed = 1;
      gsap.globalTimeline.timeScale(1);
    };
  });
}
export async function addTimeScaleSlider() {
  const context = GameSingletons.getGameContext();
  const PARAMS = {
    get timeScale() {
      return context.ticker.speed;
    },
    set timeScale(value) {
      context.ticker.speed = value;
      gsap.globalTimeline.timeScale(value);
    },
  };
  debuggingPane.addInput(PARAMS, "timeScale", {
    min: 0.5,
    max: 10,
  });
}

export function debugCardsDrawer(drawer: CardsDrawer) {
  const context = GameSingletons.getGameContext();
  const { viewSize, stage } = context;

  // __markBounds({
  //   getBounds() {
  //     const [boundryLeft, boundryRight] = drawer.getDrawerRangeX();
  //     return {
  //       x: boundryLeft,
  //       y: viewSize.height * 0.88,
  //       width: boundryRight - boundryLeft,
  //       height: 10,
  //     };
  //   },
  // });

  stage.enchantments.watch(
    () => drawer.groups?.[0],
    () => {
      for (const [, group] of drawer.groups.entries()) {
        (group as any).__getBounds = () => {
          return group.zone;
        };
      }
    },
    true
  );
}
