import { GameSingletons } from "@game/app/GameSingletons";
import { loadRailroaderAchievementsData } from "@game/ui/social/achievements/loadRailroaderAchievements";

export type AchievementPanelRowData = {
  title: string;
  description: string;
  achievementIds: number[];
};

const achievementsRRDashPanelRows: AchievementPanelRowData[] = [
  {
    title: "Streak Badges",
    description: "Signify lengths of time where Rail Runs were made on consecutive days.",
    achievementIds: [15, 16, 17, 18, 19],
  },
  {
    title: "Region Badges",
    description: "Visited every station in a given region.",
    achievementIds: [4, 5, 6, 7, 8],
  },
  {
    title: "Pallet Pusher",
    description: "Hauled palleted commodities over x distance.",
    achievementIds: [22, 23, 24, 25, 26],
  },
  {
    title: "Crate Carrier",
    description: "Hauled crated commodities over x distance.",
    achievementIds: [27, 28, 29, 30, 31],
  },
  {
    title: "Liquid Lifter",
    description: "Hauled liquid commodities over x distance.",
    achievementIds: [32, 33, 34, 35, 36],
  },
  {
    title: "Mr. Gas",
    description: "Hauled gas commodities over x distance.",
    achievementIds: [37, 38, 39, 40, 41],
  },
  {
    title: "Woodchip King",
    description: "Hauled aggregate commodities over x distance.",
    achievementIds: [42, 43, 44, 45, 46],
  },
  {
    title: "Rock Hustler",
    description: "Hauled ore commodities over x distance.",
    achievementIds: [47, 48, 49, 50, 51],
  },
  {
    title: "Sugar Daddy",
    description: "Hauled granule commodities over x distance.",
    achievementIds: [52, 53, 54, 55, 56],
  },
  {
    title: "Grainasaurus Rex",
    description: "Hauled grain commodities over x distance.",
    achievementIds: [57, 58, 59, 60, 61],
  },
  {
    title: "Icicle Jones",
    description: "Hauled perishable commodities over x distance.",
    achievementIds: [62, 63, 64, 65, 66],
  },
  {
    title: "Big Shit Express",
    description: "Hauled oversized commodities over x distance.",
    achievementIds: [67, 68, 69, 70, 71],
  },
  {
    title: "Rob the Builder",
    description: "Hauled building material commodities over x distance.",
    achievementIds: [72, 73, 74, 75, 76],
  },
  {
    title: "OttoMobile",
    description: "Hauled automobile commodities over x distance.",
    achievementIds: [77, 78, 79, 80, 81],
  },
  {
    title: "Entity 9’s BFF",
    description: "Hauled top secret commodities over x distance.",
    achievementIds: [82, 83, 84, 85, 86],
  },
  {
    title: "Otto Friendship Badges",
    description: "Being the complicated man that he is, there are many levels to Otto’s friendship.",
    achievementIds: [10, 11, 12, 13, 14],
  },
  {
    title: "Modern Master",
    description: "Visited every station in the Modern Century.",
    achievementIds: [9],
  },
  {
    title: "Beta Badge",
    description: "Must have travelled at least 100 Distance during the beta phase of the game.",
    achievementIds: [1],
  },
  {
    title: "Golden Railroader",
    description: "The first railroader to make a Conductor's Golden Run may bare this coveted badge.",
    achievementIds: [2],
  },
  {
    title: "Golden Runner",
    description: "Must have made at least one of each conductor’s golden runs.",
    achievementIds: [3],
  },
  {
    title: "Train Maestro",
    description: "Unlocked 10 trains (9 additional after first)",
    achievementIds: [20],
  },
  {
    title: "Full Train",
    description: "Unlocked all Rail Car slots for one train",
    achievementIds: [21],
  },
];

async function getUserAchievements() {
  const { userData } = GameSingletons.getGameContext();
  const username: string = userData.name;
  const allAchievements = await loadRailroaderAchievementsData(username); // `brhig.wam`
  const userBadgeUIDs = [];

  for (let single of allAchievements) {
    const UID = single.achievementId;

    userBadgeUIDs.push(UID);
  }

  return userBadgeUIDs;
}

export class AchievementsRRDashDataService {
  async getAllAchievements(name: string) {
    const data = achievementsRRDashPanelRows;
    const userBadgeIDs = await getUserAchievements();
    let row: AchievementPanelRowData;

    for (let ach of data) {
      if (ach.title == name) {
        row = ach;
        return { row, userBadgeIDs };
      }
    }
  }
}
