import { TransactionActionTrace, TransactionResult } from "../typing/TransactonResult";

type SomethingWithActionTraces =
  | TransactionResult
  | { action_traces: TransactionActionTrace[] }
  | { inline_traces: TransactionActionTrace[] }
  | TransactionActionTrace[];

function findTrace<T>(
  target: SomethingWithActionTraces,
  condition: (trace: TransactionActionTrace) => boolean
): TransactionActionTrace | null {
  const traces = Array.isArray(target)
    ? target
    : "processed" in target
    ? target.processed.action_traces
    : "action_traces" in target
    ? target.action_traces
    : "inline_traces" in target
    ? target.inline_traces
    : [];
  if (!traces) {
    return null;
  }
  for (const trace of traces) {
    if (condition(trace)) {
      return trace;
    } else {
      const result = findTransactionActionTrace(trace, condition);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

export const findTransactionActionTrace = Object.assign(findTrace, {
  byName: (target: SomethingWithActionTraces, actionName: string) => {
    return findTrace(target, t => t.act.name === actionName);
  },
});
