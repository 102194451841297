import { GameContext } from "@game/app/app";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { GameSingletons } from "@game/app/GameSingletons";
import { EnchantedContainer } from "@game/core/enchanted-classes";
import { Container } from "@pixi/display";
import { AdjustmentFilter } from "@pixi/filter-adjustment";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { CTPartsListBackground, CTPartThumbnail } from "./components";
import { ForgeModal } from "./components/ForgeModal";
import { ForgeTrainSlices } from "./components/ForgeTrainSlices";
import { CenturyTrainPartsDataService } from "./CTPartsDataService";
import { MarketCharacter } from "./components/MarketCharacter";
import { WaxContractsGateway } from "@sdk-integration/contracts/WaxContractsGateway";
import { GlowFilter } from "@pixi/filter-glow";

export class CTPartsForgeSubpage extends EnchantedContainer {
  private readonly factory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private readonly context: GameContext = GameSingletons.getGameContext();
  private readonly dataService = new CenturyTrainPartsDataService();
  private characterSprite?: MarketCharacter;
  sliceIndices: number[] = [];
  totalParts: number = 18;
  slices = new ForgeTrainSlices();
  constructor() {
    super();

    //// Background
    this.addChild(new CTPartsListBackground());
    this.characterSprite = this.addChild(new MarketCharacter());
    this.characterSprite.setCharacterPose(MarketCharacter.TheMechanicPose.Idle);
    this.characterSprite.setCharacterName("The Mechanic");

    //// Bottom Panel
    const bottomPanel = this.factory.createSprite("ui-market-window-forge/panel.png");
    bottomPanel.scale.set(2);
    bottomPanel.anchor.x = 0.5;
    bottomPanel.position.set(932, 965);
    this.addChild(bottomPanel);
    //// Add train slices container
    this.addChild(this.slices);
    //// Badge tag
    const badge = this.factory.createSprite("ui-social/badge-tag.png");
    badge.scale.set(1.5);
    badge.anchor.x = 0.5;
    badge.position.set(932, 324);
    this.addChild(badge);
    //// Add thumbnails
    this.addPartsRow();
    //// Add slices train background
    this.slices.addTrainBackground();
  }

  async addPartsRow() {
    const allCtPart = [
      this.context.gameConfigData.ctPartsData.RAD,
      this.context.gameConfigData.ctPartsData.RADFAN,
      this.context.gameConfigData.ctPartsData.SANDBOX,
      this.context.gameConfigData.ctPartsData.BATT,
      this.context.gameConfigData.ctPartsData.FTANK,
      this.context.gameConfigData.ctPartsData.AIRREZ,
      this.context.gameConfigData.ctPartsData.DRIVE,
      this.context.gameConfigData.ctPartsData.MOTBLOW,
      this.context.gameConfigData.ctPartsData.ALTER,
      this.context.gameConfigData.ctPartsData.WHEELS,
      this.context.gameConfigData.ctPartsData.ELECTRO,
      this.context.gameConfigData.ctPartsData.TRUCK,
      this.context.gameConfigData.ctPartsData.TRACMO,
      this.context.gameConfigData.ctPartsData.AUXALT,
      this.context.gameConfigData.ctPartsData.AIRCOMP,
      this.context.gameConfigData.ctPartsData.TURBO,
      this.context.gameConfigData.ctPartsData.POWER,
      this.context.gameConfigData.ctPartsData.ENGINE,
    ];
    const ownedData = await this.dataService.getMyOwnedPartsList();
    const rowContainer = new Container();
    let startX = 0;
    const filter = new AdjustmentFilter({ brightness: 0.5 });
    for (const [index, ctPart] of allCtPart.entries()) {
      const trainPart = new CTPartThumbnail(ctPart, false);
      trainPart.filters = [filter];
      trainPart.position.set(startX, 0);
      rowContainer.addChild(trainPart);
      for (const part of ownedData) {
        if (ctPart.tokenSymbol === part.tokenSymbol) {
          trainPart.addInventoryBadge(part.inventoryCount);
          this.sliceIndices.push(index);
          trainPart.filters = [];
          break;
        }
      }
      startX += 125;
    }

    rowContainer.scale.set(0.5);
    rowContainer.position.set(325, 875);
    this.addChild(rowContainer);
    //// Hightlight existing slices
    this.slices.setHighlightedSlices(this.sliceIndices);

    this.checkLocoStatus();
    this.setCounter();
  }

  checkLocoStatus() {
    if (this.sliceIndices.length >= 18) {
      this.slices.setGlowEnabled(true);
      //// Add forge button
      const btn = this.factory.createSprite("ui-market-window-forge/big-btn.png", { x: 932, y: 1125 });
      btn.anchor.set(0.5);
      btn.filters = [
        new GlowFilter({
          outerStrength: 2.6,
          distance: 13,
          color: 0x00ffff,
        }),
      ];
      const btnText = this.factory.createText("FORGE CENTURY TRAIN - 500,000 TOCIUM", { fontSize: 24 }, { y: 5 });
      btnText.anchor.set(0.5);
      btn.addChild(btnText);
      btn.scale.set(2);
      this.addChild(btn);
      buttonizeDisplayObject(btn, () => {
        return this.ForgeCenturyTrain();
        
      });
    }
  }

  setCounter() {
    //// Badge counter
    const badgeText = this.factory.createText(
      this.sliceIndices.length + "/" + this.totalParts + " PARTS",
      { fontSize: 38 },
      {
        x: 932,
        y: 332,
      }
    );
    badgeText.anchor.x = 0.5;
    this.addChild(badgeText);
    if (this.sliceIndices.length < 18) {
      this.slices.setGlowEnabled(false);
      //// Bottom text
      const bottomText = this.factory.createText(
        "Collect all " + this.totalParts + " Century Train Parts to forge a Century Train!",
        {
          fontSize: 38,
        },
        { x: 932, y: 1100 }
      );
      bottomText.anchor.x = 0.5;
      this.addChild(bottomText);
    }
  }


    //Century Train Forge

    private async ForgeCenturyTrain(template_id = "620891"){
      const WAX = await import("@sdk-integration/contracts");
      const { contracts, userDataCtrl,modals, spinner } = GameSingletons.getGameContext();
      const price = 500000;
      {
      const promise = contracts.actions.performActionTransactions([
       [
        "transfer",
        {
          from: contracts.currentUserName,
          to: WAX.ContractName.M,
          quantity: price.toFixed(4) + " TOCIUM",
          memo: "DEPOSIT|" + contracts.currentUserName,
        },
        WAX.ContractName.Toc,
      ],
      [
        "forgect",
        {
          railroader: contracts.currentUserName,
          template_id: template_id,
        },
        WAX.ContractName.M,
      ],
    ]);

     await spinner.showDuring(promise);
    }

    {
      const promise = userDataCtrl.updateAll();
      
      const forgeModal = new ForgeModal();
      this.addChild(forgeModal);
      await spinner.showDuring(promise);
    }
    }
}
