import { GameSingletons } from "@game/app/GameSingletons";
import { Texture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";
import { SafeScrollbox } from "@sdk-pixi/display/SafeScrollbox";
import { AchievementsRRDashPlate } from "./AchievementsRRDashPlate";
import { AchievementsRRDashUserListSidePanelManager } from "./AchievementsRRDashUserListSidePanelManager";

const achNames = [
  "Streak Badges",
  "Region Badges",
  "Pallet Pusher",
  "Crate Carrier",
  "Liquid Lifter",
  "Mr. Gas",
  "Woodchip King",
  "Rock Hustler",
  "Sugar Daddy",
  "Grainasaurus Rex",
  "Icicle Jones",
  "Big Shit Express",
  "Rob the Builder",
  "OttoMobile",
  "Entity 9’s BFF",
  "Otto Friendship Badges",
  "Modern Master",
  // "Beta Badge",
  //  "Golden Railroader",
  // "Golden Runner",
  // "Train Maestro",
  // "Full Train",
];

export class AchievementRRDashList extends SafeScrollbox {
  constructor() {
    super({
      noTicker: true,
      boxWidth: 645,
      boxHeight: 688,
      stopPropagation: true,
      divWheel: GameSingletons.getGameContext().app.view,
      overflowX: "none",
    });
  }

  async initialize(sidePanelController: AchievementsRRDashUserListSidePanelManager) {
    const { spinner, ticker } = GameSingletons.getGameContext();
    const showSpinnerDuring = spinner.showDuring.bind(spinner);
    this.update();

    const populateList = async () => {
      for (let i = 0; i < achNames.length; i++) {
        const achRow = new AchievementsRRDashPlate(achNames[i], sidePanelController);
        achRow.initialize();
        achRow.addChild(this.addInvisibleBox(80 + 1));
        achRow.y = (achRow.height + 140) * i;

        this.content.addChild(achRow);

        this.update();

        await ticker.nextFrame();
      }
    };
    await showSpinnerDuring(populateList());
  }

  addInvisibleBox(px: number) {
    const box = new Sprite(Texture.EMPTY);
    box.width = this.boxWidth;
    box.height = px;
    return box;
  }

  clearList() {
    const children = [...this.content.children];
    for (const child of children) {
      child.destroy({ children: true });
    }
  }
}
