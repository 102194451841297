import { GameSingletons } from "@game/app/GameSingletons";
import { StationEntity } from "@game/data/entities/StationEntity";
import { TrainEntity } from "@game/data/entities/TrainEntity";
import { ReadonlyDeep } from "type-fest";

export async function startNewRailRun(train: ReadonlyDeep<TrainEntity>, destination: ReadonlyDeep<StationEntity>) {
  const { contracts, userData, userDataCtrl, ticker } = GameSingletons.getGameContext();

  await contracts.startRailRun(train.name, destination.assetId);

  const getNewRun = () => userData.trainsOngoingRuns.get(train.name);
  while (getNewRun() == undefined) {
    await ticker.delay(1.0);
    await userDataCtrl.updateAll();
  }
}
