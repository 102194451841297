import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { range } from "@sdk/utils/range";

export function createCogWheelMenuCogWheel() {
  const { assets } = GameSingletons.getGameContext();

  const container = new Container();

  const texturesPrefix = "ui-cog-wheel-menu/";
  const textures = assets.mapTextures({
    cogLayer0: texturesPrefix + "cog-0.png",
    cogLayer1: texturesPrefix + "cog-1.png",
    cogLayer2: texturesPrefix + "cog-2.png",
    cogLayer3: texturesPrefix + "cog-3.png",
    cogLayer4: texturesPrefix + "cog-4.png",
    cogLayer4_Special: texturesPrefix + "cog-4-green.png",
    cogLayer4_Special2: texturesPrefix + "cog-4-green-glow.png",
    cogLayer4_Special3: texturesPrefix + "cog-4-orange.png",
    cogLayer4_Disabled: texturesPrefix + "cog-4-gray.png",
    iconTocLogo: texturesPrefix + "toc-logo.png",
    iconSwitch: texturesPrefix + "switch-portal.png",
    iconTick: texturesPrefix + "check.png",
    iconDownToBldg: "arrow-down-to-building.png",
    iconBackToMap: "arrow-back-to-map.png"
  });
  type TextureKey = keyof typeof textures;

  const cogs = range.fromToIncluding(0, 4).map(j => {
    const cogTexture = textures[`cogLayer${j}`];
    const cog = new Sprite(cogTexture);
    cog.anchor.set(0.5);
    cog.scale.set(1.2);
    cog.zIndex = j * 10;
    return cog;
  });
  container.addChild(...cogs);

  function addHighlight(textureName: TextureKey) {
    const highlightTexture = textures[textureName];
    const highlight = new Sprite(highlightTexture);
    highlight.anchor.set(0.5);
    highlight.scale.set(1.2);
    highlight.zIndex = 100;
    highlight.alpha = 0;
    container.addChild(highlight);

    const tweenAlpha = new TemporaryTweeener(highlight).quickTo(highlight, "alpha", { ease: "power2.out" });
    // const tweenAlpha = (n:any) => highlight.alpha = n;
    return Object.assign(highlight, { tweenAlpha });
  }

  function addIcon(textureName: TextureKey, scale: number = 1) {
    const icon = new Sprite(textures[textureName]);
    icon.anchor.set(0.5);
    icon.scale.set(scale);
    icon.zIndex = 1000;
    container.addChild(icon);

    const animationState = {
      _shownProgress: 1,
      get shownProgress() {
        return this._shownProgress;
      },
      set shownProgress(value) {
        this._shownProgress = value;
        icon.scale.set(value * scale);
        icon.alpha = value;
      },
    };

    return Object.assign(icon, { animationState });
  }

  const highlightCyan = addHighlight("cogLayer4_Special");
  const highlightCyanGlow = addHighlight("cogLayer4_Special2");
  const highlightOrange = addHighlight("cogLayer4_Special3");
  const grayedOutOverlay = addHighlight("cogLayer4_Disabled");

  const icons = {
    logo: addIcon("iconTocLogo", 0.4),
    switch: addIcon("iconSwitch", 1.42),
    tick: addIcon("iconTick"),
    dBldg: addIcon("iconDownToBldg"),
    bBldg: addIcon("iconBackToMap"),
  };

  icons.switch.visible = false;

  //// ANIMATIONS (SHOW / HIDE HIGHLIGHT) ////

  const result = Object.assign(container, {
    sortableChildren: true,
    cogs,
    icons,
    setColor(color: "cyan" | "cyanWithGlow" | "orange" | "gray" | "default") {
      highlightOrange.tweenAlpha(color === "orange" ? 1 : 0);
      grayedOutOverlay.tweenAlpha(color === "gray" ? 1 : 0);
      highlightCyan.tweenAlpha(color === "cyan" ? 1 : 0);
      highlightCyanGlow.tweenAlpha(color === "cyanWithGlow" ? 1 : 0);
    },
    setIcon(icon: "logo" | "switch" | "tick" | "dBldg" | "bBldg") {
      icons.switch.visible = icon === "switch";
      icons.tick.visible = icon === "tick";
      icons.logo.visible = icon === "logo";
      icons.dBldg.visible = icon === "dBldg";
      icons.bBldg.visible = icon === "bBldg";
    },
  });

  result.setColor("default");
  result.setIcon("logo");

  return result;
}
