import { GameSingletons } from "@game/app/GameSingletons";
import { ResourceCounter } from "@game/ui/hud/components/ResourceCounter";
import { createHUDElementContainer } from "./hud-utils";

export function createResourceBalanceIndicators() {
  const { gameConfigData, userData } = GameSingletons.getGameContext();

  const container = createHUDElementContainer({ x: 0.0, y: 0.0 }, { x: 10, y: 20 });

  let y = 0;
  const cfg = gameConfigData.features.hud.balanceIndicators;
  if (cfg.tocium) {
    const counter = new ResourceCounter({
      iconTexture: "ui-main/tocium-sm.png",
      iconGlowTexture: "ui-main/tocium-sm-glow.png",
      iconScaleMultiplier: 0.6,
      showDecimalsOnHover: true,
    });
    ResourceCounter.attachAnimatedValueGetter(counter, () => userData.tocium);
    container.addChild(counter);

    counter.position.y = y;
    y += counter.height;
  }

  if (cfg.animaticParticles) {
    const counter = new ResourceCounter({
      iconTexture: "ui-main/anomatter-sm.png",
      iconGlowTexture: "ui-main/tocium-sm-glow.png",
      iconScaleMultiplier: 0.9,
    });
    ResourceCounter.attachAnimatedValueGetter(counter, () => userData.anomaticParticles);
    container.addChild(counter);

    counter.position.y = y;
    y += counter.height;
  }

  return container;
}

export type ResourceBalanceIndicators = ReturnType<typeof createResourceBalanceIndicators>;
