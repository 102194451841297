export enum ActionName {
  // New user
  AcceptTermsAndConditions = "acceptterms",
  CreateRailroader = "login",
  CreateTrain = "createtrain",

  // Gameplay
  SetTrain = "settrain",
  ClearTrain = "cleartrain",
  VerifyTrain = "verifytrain",
  Transport = "transport",
  ClaimRailRunReward = "claim",
  ClaimSpecialReward = "claimreward",
  ForgeCt = "forgect",

  // Initialization, Configuration & Administration
  Init = "init",
  InitRates = "initrates",
  InitVars = "initvars",
  SetVars = "setvars",
  SetVersion = "setversion",
  SetWhitelistUse = "setwluse",
  SetCenturies = "setcenturies",
  SetTermsAndConditionsVersion = "settermsver",
  SetRailCarLimit = "setrclmt",
  SetLocomotiveLimit = "setlocolmt",
  SetConductorLimit = "setcondlmt",
  SetTrainLimit = "settrainlmt",
  SetRate = "setrate",
  SetStation = "setstation",
  AddWhitelist = "addwhitelist",
  RemoveWhitelist = "rmvwhitelist",
  AddBlacklist = "addblacklist",
  RemoveBlacklist = "rmvblacklist",

  // Other
  SetTemplate = "settemplate",
  SetLock = "setlock",

  // God-mode
  RemoveAssets = "removeassets",
  RemoveRR = "removerr",
  RemoveRun = "removerun",
  RemoveTrain = "removetrain",
  RemoveStation = "rmvstation",
  ClearRR = "clearrr",
  ClearVars = "clearvars",

  // external (appear only in action results' inline traces)

  XTransfer = "xtransf",
  Transfer = "transfer",
  Issue = "issue",
  IssueAmp ="issueamp",
}
