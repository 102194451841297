import { Texture } from "@pixi/core";
import { Sprite } from "@pixi/sprite";

export const makeRiverLabels = () => {
  const sprite = new Sprite(Texture.from("worldMapRiverNames"));
  sprite.anchor.set(0.5, 0.5);
  sprite.scale.set(32);
  sprite.alpha = 0.2;
  return Object.assign(sprite);
};
