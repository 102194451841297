import { GameSingletons } from "@game/app/GameSingletons";
import { Container } from "@pixi/display";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { delay } from "@sdk/utils/promises";
import { AchievementBadge } from "../achievements/AchievementBadge";
import { AchievementBadgeData } from "../achievements/AchievementBadgeData";

export class FeaturedAchievementsSection extends Container {
  async populate(achievementsData: AchievementBadgeData[]) {
    //// Add first row of badges
    let startX = 0;
    let startY = 0;
    for (const [index, info] of achievementsData.entries()) {
      const badge = AchievementBadge.create(info);
      badge.position.set(startX, startY);
      badge.scale.set(0.25);

      if (info.name) {
        GameSingletons.getGameContext().tooltips.registerTarget(badge, info.name);
      }

      this.addChild(badge);
      startX += 75;
      if (index == 4) {
        startY += 60;
        startX = 40;
      }

      const tweeener = new TemporaryTweeener(badge);
      tweeener.from(badge, { pixi: { pivotY: 50 }, ease: "bounce.out" });
      tweeener.from(badge, { pixi: { alpha: 0 }, ease: "power2.out" });

      await delay(0.05);
    }
  }
}
