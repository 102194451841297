import { GameSingletons } from "@game/app/GameSingletons";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";

const texturePrefix = "cinematics/story/";

type StoryEncounterModalResult = "skip" | "play" | "openDash";

export async function showStoryEncounterModal(
  userHasUnwatchedStoryEncounters: boolean
): Promise<StoryEncounterModalResult> {
  const context = GameSingletons.getGameContext();

  const { simpleFactory, assets, ticker, modals, viewSize, sfx } = context;

  const container = context.stageContainers._cinematic;
  const tweeener = new TemporaryTweeener(container);

  await ticker.delay(0.8);

  const viewRatio = (0.75 * viewSize.width) / viewSize.height;

  const curtainTexture = assets.getTexture(texturePrefix + "curtain.png");
  const curtainScaleX = (viewRatio * viewSize.height) / curtainTexture.height;
  const curtainScaleY = viewSize.height / curtainTexture.height;

  const curtainLeft = simpleFactory.createSprite(curtainTexture);
  curtainLeft.scale.set(curtainScaleX, curtainScaleY);
  container.addChild(curtainLeft);

  const curtainRight = simpleFactory.createSprite(curtainTexture);
  curtainRight.position.set(viewSize.width, 0);
  curtainRight.scale.set(-curtainScaleX, curtainScaleY);
  container.addChild(curtainRight);

  tweeener.from([curtainLeft, curtainRight], {
    pixi: {
      scaleX: 0.0,
    },
    duration: 1.22,
    ease: "elastic.out",
  });

  await ticker.delay(0.5);

  sfx.play("newStory");
  const stopTicking = sfx.play("ticking", true);

  async function showModal() {
    if (userHasUnwatchedStoryEncounters) {
      const choice = await modals.confirm({
        title: "Story Encounter Available",
        content:
          "You've unlocked the next part of the Modern century story.\nGet caught up on the Railroader Dashboard to see what\nhappens next!",
        acceptButtonText: "Open Dash",
        cancelButtonText: "Skip",
        cornerDetailType: null,
      });
      if (choice) {
        return "openDash";
      }
    } else {
      const choice = await modals.confirm({
        title: "Story Encounter Available",
        content:
          "You've unlocked the next part of the Modern century story.\nWould you like to view it now?\n\n(Skipped encounters can be viewed from the Railroader Dashboard)",
        acceptButtonText: "Begin",
        cancelButtonText: "Skip",
        cornerDetailType: null,
      });
      if (choice) {
        return "play";
      }
    }
    return "skip";
  }

  const result = await showModal();

  await ticker.delay(0.05);

  await tweeener.to([curtainLeft, curtainRight], {
    pixi: {
      scaleX: 0.0,
    },
    duration: 0.22,
    ease: "back.in",
  });

  stopTicking();

  return result;
}
