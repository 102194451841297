import { GameSingletons } from "@game/app/GameSingletons";
import { GlowFilterService } from "@game/ui/fx/GlowFilterService";
import { AchievementBadge } from "@game/ui/social/achievements/AchievementBadge";
import { AchievementBadgeData } from "@game/ui/social/achievements/AchievementBadgeData";
import { Container } from "@pixi/display";
import { TemporaryTweeener } from "@sdk/pixi/animations/TemporaryTweener";
import { AchievementsRRDashUserListSidePanel } from "./AchievementRRDashUserListSidePanel";

export class AchievementsRRDashUserListSidePanelManager {
  panel: AchievementsRRDashUserListSidePanel | null = null;
  currentData: AchievementBadgeData | null = null;
  private readonly glowFilterService: GlowFilterService = new GlowFilterService();
  currentSelectedBadge?: Container;

  async open(badge: AchievementBadgeData, userNameHolder: string[]) {
    this.currentData = badge;
    if (this.panel) {
      this.close();
    }

    const { stageContainers, viewSize } = GameSingletons.getGameContext();
    const container = stageContainers._hud;
    const panel = (this.panel = new AchievementsRRDashUserListSidePanel(badge, userNameHolder));
    container.addChild(panel);
    panel.pivot.set(panel.background.width, panel.background.height * 0.5);
    const updatePanelPosition = () => {
      const scale = viewSize.vmin / 1640;
      panel.scale.set(scale);
      panel.position.set(viewSize.width, viewSize.height * 0.42);
    };
    const tweeener = new TemporaryTweeener(panel);

    tweeener.onEveryFrame(updatePanelPosition);

    //// //// //// //// //// //// //// //// ////

    tweeener.delay(0.2).then(() => panel.addCloseButton(() => this.close()).playShowAnimation());

    updatePanelPosition();
    await tweeener.from(panel, {
      pixi: { pivotX: -panel.width },
      duration: 0.53,
      ease: "power4.out",
    });
  }

  async close() {
    const previousPanel = this.panel;
    if (previousPanel) {
      this.panel = null;

      const tweeener = new TemporaryTweeener(previousPanel);
      await tweeener
        .to(previousPanel, {
          pixi: { pivotX: -previousPanel.width },
          duration: 0.375,
          ease: "power.in",
        })
        .then(() => previousPanel.destroy({ children: true }));
    }
  }

  setCurrentSelectedTabGlow(badge: AchievementBadge) {
    this.removeCurrentSelectedTabGlow();
    this.glowFilterService.addFilter(badge);
    this.currentSelectedBadge = badge as Container;
  }

  //// Remove glow from exisiting selected tab
  removeCurrentSelectedTabGlow() {
    if (this.currentSelectedBadge) {
      this.glowFilterService.removeFrom(this.currentSelectedBadge);
    }
  }
}
