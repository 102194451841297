import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { TextInput } from "@game/asorted/TextInput";

export function testTextInput() {
  const context: GameContext = GameSingletons.getGameContext();

  const ti = new TextInput({
    input: {
      fontFamily: "Arial",
      fontSize: "36px",
      padding: 12,
      width: "1500px",
      color: "#ff00ff",
    } as any,
    box: {
      default: {
        fill: 0xe8e9f3,
        rounded: 12,
        stroke: { color: 0xcbcee0, width: 3 },
      },
      focused: {
        fill: 0xe1e3ee,
        rounded: 12,
        stroke: { color: 0xabafc6, width: 3 },
      },
      disabled: { fill: 0xdbdbdb, rounded: 12 },
    },
  });
  ti.placeholder = "Type something here";
  context.app.stage.addChild(ti);
}
