import {GameSingletons} from "@game/app/GameSingletons";
import {TemporaryTweeener} from "@sdk/pixi/animations/TemporaryTweener";
import {Container, DisplayObject} from "@pixi/display";
import {SimpleObjectsFactory} from "@game/app/services/SimpleObjectsFactory";
import {Rectangle} from "@pixi/math";
import {Sprite} from "@pixi/sprite";
import {Text} from "@pixi/text";
import {buttonizeDisplayObject} from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import {flickeringLight} from '../effects';
import {createItemContainer, addPairedPointerdownListeners, scaleItem} from '@game/ui/buildings/bldgUtility';
import {InteractionEvent} from "@pixi/interaction";
import {wiggle} from "../actions";
import {IPointData} from "@pixi/math";
import {Texture} from "@pixi/core";
import {FontFamily} from "@game/constants/FontFamily";
import {EnchantedContainer} from "@game/core/enchanted-classes";
import {Conductor, conductorData} from "../data/conductorData"
import {textScroll} from "../data/textScrolls";
import { bldgHouse } from "../bldgHouse";
import { TilingSpriteDimmer, TilingSpriteDimmerTemplates } from "@game/ui/common/TilingSpriteDimmer";
import { BitmapText } from "@pixi/text-bitmap";

// for createItemContainer setting the intitial position to one object, like bg, will make all the screenAnchor properties relative to that (bg).

export class wantedModal extends EnchantedContainer {
  private readonly context = GameSingletons.getGameContext();
  private readonly tweeener = new TemporaryTweeener(this);
  private readonly factory : SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  private paperImage : Sprite ;
  private headerImage : Sprite;
  private teaserContainer : any;
  public bgContainer : any; 
  public textContainer : any;
  public bg : Sprite;
  public buildingName: string;
  dimmer : TilingSpriteDimmer;

  constructor(public id : number, parent : bldgHouse) {
    super();

    const { assets } = this.context;

    this.dimmer = new TilingSpriteDimmer({
      ...TilingSpriteDimmerTemplates.SCANLINES
    });
    this.addChild(this.dimmer);
    
    this.dimmer.show();

    this.id = id - 2;
    this.tweeener = new TemporaryTweeener(this);
    this.buildingName = parent.name;

    // Empty Container behind modal, so no clickthrough
    const fullScreenContainer = this.factory.createSprite(Texture.EMPTY, {
      x: 0,
      y: 0
    });
    fullScreenContainer.width = this.context.viewSize.width;;
    fullScreenContainer.height = this.context.viewSize.height;;
    fullScreenContainer.interactive = true;
    this.addChild(fullScreenContainer);

    const {viewSize} = parent.context;
   
    // bg
    this.bg = this.factory.createSprite("modal-bg.png");
    this.bg.scale.set(.75);
    // Create a container for the item
    this.bgContainer = createItemContainer({
      x: .5,
      y: .5
    }, {
      x: 0,
      y: 50
    }, this.bg.width, this.bg.height);
    this.bgContainer.addChild(this.bg);
    this.bgContainer.updateIndicatorPositions(this.context.viewSize);
    this.addChild(this.bgContainer);
   
    // Paper Image
    this.paperImage =  new Sprite(assets.getTexture("wantedPaper"));
    this.paperImage.scale.set(1);
    this.addChild(this.paperImage);
    this.bgContainer.addChild(this.paperImage);

      // Text Container
      this.textContainer = createItemContainer({
        x: .5,
        y: .5
      }, {
        x: 0,
        y: 50
      }, this.bg.width, this.bg.height);
      this.textContainer.updateIndicatorPositions(this.context.viewSize);
      this.addChild(this.textContainer);

      const text = 'Calling all game developers! \'Train of the Century\' is seeking indie developers who\' like to contribute to the Centuryverse Arcade, Choopy McBooper\'s! \n\nSubmit your game information by clicking the button below.';

      const stickyNoteText = new textScroll(text, "DefaultThin");
       //Initialize
       stickyNoteText.initialize();
       stickyNoteText.position.set(((this.bg.width - 620) / 2), this.bg.height * .4);
      this.textContainer.addChild(stickyNoteText);

    // Header Image
    this.headerImage = new Sprite(assets.getTexture("wantedHeader"));
    this.headerImage.scale.set(1);
    this.addChild(this.headerImage);
    this.bgContainer.addChild(this.headerImage);

    //Button
    const googleFormBtn = this.context.simpleFactory.createSprite("ui-social/right-panel/expanded/btn.png");
    googleFormBtn.tint = 0x008080;
    googleFormBtn.scale.set(.7);
    googleFormBtn.position.set(this.context.viewSize.width/2-googleFormBtn.width/2,(this.bg.y+this.bg.height-150));
    this.addChild(googleFormBtn);


    const viewBtnText = new BitmapText("google form", { fontName: "Celestial Typeface", align: "center" });
    viewBtnText.scale.set(0.75);
    viewBtnText.position.set(75, 0);

    googleFormBtn.addChild(viewBtnText);

    buttonizeDisplayObject(googleFormBtn, async ()=> {
      const url ='https://forms.gle/ynBjgr12JopG6giV8';
      window.open(url, "_blank")?.focus();
  });

/*   const btn = buttonizeInstance(googleFormBtn);
  btn.behavior.on({
    
    trigger: () => {
      const url = `https://forms.gle/ynBjgr12JopG6giV8`;
      window.open(url, "_blank")?.focus();
    },
  }); */

    const xBtn = this.factory.createText("X".toUpperCase(), {
      fontSize: 32,
      fontFamily: FontFamily.Default
    }, {
      x: 30,
      y: 30
    });


    const btnXmiddleX = xBtn.x -(xBtn.width / 2);
    const btnXmiddleY = xBtn.y -(xBtn.height / 1.5);

    // Empty Dismisal
    const empty = this.factory.createSprite(Texture.EMPTY, {
      x: btnXmiddleX,
      y: btnXmiddleY
    });
    empty.width = 60;
    empty.height = 60;

    buttonizeDisplayObject(empty, async ()=> {
      await this.dimmer.hideFast();
      this.destroy();
      this.context.input.emit("restoreHUD", this.buildingName);
  });


  

 const scaledContainer = scaleItem();
 scaledContainer.position.set(this.bgContainer.x - 30,this.bgContainer.y);
 scaledContainer.addChild(xBtn);
 scaledContainer.addChild(empty);
 this.addChild(scaledContainer);

 
 
  }




};
