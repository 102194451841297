import { GameSingletons } from "@game/app/GameSingletons";
import { env } from "@game/app/global";
import { FontFamily } from "@game/constants/FontFamily";
import { FontIcon } from "@game/constants/FontIcon";
import { Text } from "@pixi/text";
import { createHUDElementContainer } from "./hud-utils";

export function createAppVersionIndicator() {
  const { VERSION } = GameSingletons.getGameContext();

  const container = createHUDElementContainer({ x: 0.0, y: 1.0 }, { x: 25, y: 20 });

  const version = env.ENVIRONMENT === "production" ? `${FontIcon.CenturyTrain} ${VERSION}` : env.COMMIT_HASH;

  const tf = new Text(version.toUpperCase(), {
    fontFamily: FontFamily.Default,
    fontSize: 10,
    fill: 0xffffff,

    stroke: "#080808",
    strokeThickness: 1,

    // dropShadow: true,
    // dropShadowAngle: 1.57079632679,
    // dropShadowColor: 0x010101,
    // dropShadowDistance: 2,
    // dropShadowAlpha: 0.75,
  });
  tf.alpha = 0.4;
  tf.zIndex = 100;
  tf.anchor.copyFrom(container.screenAnchor);
  container.addChild(tf);

  return container;
}

export type AppVersionIndicator = ReturnType<typeof createAppVersionIndicator>;
