import { GameSingletons } from "@game/app/GameSingletons";
import { SimpleObjectsFactory } from "@game/app/services/SimpleObjectsFactory";
import { Container } from "@pixi/display";
import { ModList } from "./ModList";
import { ModPlate } from "./ModPlate";

export class ModsContainer extends Container {
  private readonly simpleFactory: SimpleObjectsFactory = GameSingletons.getSimpleObjectFactory();
  modList: ModList = new ModList();
  constructor() {
    super();
    const background = this.simpleFactory.createSprite("ui-market-window-ct-parts/list-background.png");
    background.position.set(200, 350);
    background.scale.set(2.68, 2);
    this.addChild(background);
    this.modList.position.set(500, 375);
    this.addChild(this.modList);
  }

  initList(mods: ModPlate[]) {
    this.modList.initList(mods);
  }
}
