import { GameSingletons } from "@game/app/GameSingletons";
import { FontFamily } from "@game/constants/FontFamily";
import { Dropdown } from "@game/ui/components/Dropdown";
import { Slider } from "@game/ui/components/slider/Slider";
import { CheckBox } from "@game/ui/hud/MapToggleMenu";
import { Container } from "@pixi/display";
import { Sprite } from "@pixi/sprite";
import { Text } from "@pixi/text";
import { getRpcEndpointHost, RPC_ENDPOINT_HOST_OPTIONS, setRpcEndpointHost } from "@sdk-integration/configuration";
import { EthereumNFTDataService } from "@sdk-integration/ethereum/EthereumNFTDataService";
import { retrieveEthereumAddress } from "@sdk-integration/ethereum/truncateEthereumAddress";
import { buttonizeDisplayObject } from "@sdk-pixi/ui-helpers/buttonizeDisplayObject";
import { RailroaderDashPanelBase } from "../RailroaderDashPanelBase";
import { GreenButton } from "./components/GreenButton";
import { createUserPreferencesFirebaseStorageProxy } from "./createUserPreferencesFirebaseStorageProxy";

export class SettingsCenterPanel extends RailroaderDashPanelBase {
  private readonly controlsContainer = new Container();
  private readonly context = GameSingletons.getGameContext();

  async init() {
    const { music, sfx } = this.context;

    this.controlsContainer.sortableChildren = true;

    //// Logo image
    this.addTitleImage("ui-railroader-dashboard/page-settings/img-settings.png");

    this.controlsContainer.position.set(88, -50);
    this.addChild(this.controlsContainer);

    //// SFX Text
    const sfxText = this.context.simpleFactory.createText("SFX", {
      fontFamily: FontFamily.Default,
      fontSize: 30,
      fill: 0xffffff,
    });
    sfxText.position.set(120, 400);
    this.controlsContainer.addChild(sfxText);

    //// SFX Slider Placeholder
    const sfxPlaceholder = this.createSlider(
      VolumeCurver.uncurve(sfx.getVolume()),
      value => {
        sfx.setVolume(VolumeCurver.curve(value), true);
      },
      150
    );
    sfxPlaceholder.position.set(210, 415);
    this.controlsContainer.addChild(sfxPlaceholder);

    //// Music Text
    const musicText = this.context.simpleFactory.createText("MUSIC", {
      fontFamily: FontFamily.Default,
      fontSize: 30,
      fill: 0xffffff,
    });
    musicText.position.set(405, 400);
    this.controlsContainer.addChild(musicText);

    //// FX Music Placeholder
    const musicPlaceholder = this.createSlider(
      VolumeCurver.uncurve(music.getVolume()),
      value => {
        music.volume = VolumeCurver.curve(value);
      },
      150
    );
    musicPlaceholder.position.set(525, 415);
    this.controlsContainer.addChild(musicPlaceholder);

    //// WAX Endpoint Text
    const waxText = this.context.simpleFactory.createText("WAX ENDPOINT", {
      fontFamily: FontFamily.Default,
      fontSize: 30,
      fill: 0xffffff,
    });
    waxText.position.set(100, 335);
    this.controlsContainer.addChild(waxText);

    //// WAX Endpoint Placeholder
    {
      const currentEndpoint = getRpcEndpointHost();
      const endpoints = RPC_ENDPOINT_HOST_OPTIONS;
      const endpointDropdown = new Dropdown(
        endpoints.map(endpoint => ({ text: endpoint, value: endpoint })),
        {
          // boxScale: 0.25,
          boxPadding: 21,
          labelPadding: [5, 5, 5, 15],
          horizontalAlignment: 0.0,
          width: 500,
          height: 100,
          labelStyle: {
            fontSize: 22,
          },
          optionsOffset: [0, -30],
          optionsStyle: {
            // boxScale: 0.25,
            horizontalAlignment: 0.0,
            boxPadding: 21,
            labelPadding: [5, 15],
            labelStyle: {
              fontSize: 22,
            },
          },
        },
        endpoints.indexOf(currentEndpoint)
      );
      endpointDropdown.zIndex = 99;
      endpointDropdown.scale.set(0.8);
      endpointDropdown.position.set(335, 315);
      endpointDropdown.onOptionSelected = async endpoint => {
        const choice = await this.context.modals.confirm({
          title: "",
          content: `
          Are you sure you want to change the WAX endpoint to ${endpoint}?
          
          The page will need to refresh to apply the change.`,
        });
        if (choice) {
          setRpcEndpointHost(endpoint);
          location.reload();
        }
      };
      this.controlsContainer.addChild(endpointDropdown);
    }

    const remotePrefs = await createUserPreferencesFirebaseStorageProxy();

    //// Mini-games Button
    {
      const checkBox = new CheckBox();

      const leftLabel = new Text("MINI-GAMES", {
        fill: 0xffffff,
        fontSize: 30,
        fontFamily: FontFamily.Default,
      });

      function updateCheckbox() {
        const minigamesEnabled = !!remotePrefs.minigamesEnabled;
        const ticked = minigamesEnabled ? true : false;
        checkBox.setTicked(ticked);
      }

      const padWidth = this.pad.width - this.controlsContainer.x - this.controlsContainer.x;
      const leftX = ~~(padWidth / 4);

      buttonizeDisplayObject(checkBox, () => {
        remotePrefs.minigamesEnabled = !remotePrefs.minigamesEnabled;
        updateCheckbox();
      });

      checkBox.scale.set(2);
      checkBox.anchor.set(0.5);
      checkBox.position.set(leftX + 150, 500);
      leftLabel.pivot.set(leftLabel.width / 2, leftLabel.height / 2);
      leftLabel.position.set(leftX, 500);

      this.controlsContainer.addChild(leftLabel);
      this.controlsContainer.addChildAt(checkBox, 0);

      updateCheckbox();
    }

    //// Auto-Repair Button
    {
      const checkBox = new CheckBox();

      const leftLabel = new Text("AUTO-REPAIR", {
        fill: 0xffffff,
        fontSize: 30,
        fontFamily: FontFamily.Default,
      });

      function updateCheckbox() {
        const autorepairEnabled = !!remotePrefs.autorepairEnabled;
        const ticked = autorepairEnabled ? true : false;
        checkBox.setTicked(ticked);
      }

      const padWidth = this.pad.width - this.controlsContainer.x - this.controlsContainer.x;
      const rightX = ~~(padWidth / 1.55);

      buttonizeDisplayObject(checkBox, () => {
        remotePrefs.autorepairEnabled = !remotePrefs.autorepairEnabled;
        updateCheckbox();
      });

      checkBox.scale.set(2);
      checkBox.anchor.set(0.5);
      checkBox.position.set(rightX + 150, 500);
      leftLabel.pivot.set(leftLabel.width / 2, leftLabel.height / 2);
      leftLabel.position.set(rightX, 500);

      this.controlsContainer.addChild(leftLabel);
      this.controlsContainer.addChildAt(checkBox, 0);

      updateCheckbox();
    }

    //// ETH wallet Button

    const addEthereumIntegration = async () => {
      const { userData, spinner, tooltips } = this.context;

      const dataService = new EthereumNFTDataService();
      let connectedEthereumAddress: string | null = await dataService.getSavedEthereumAddress(userData.name);

      function updateButtonVisible() {
        if (connectedEthereumAddress) {
          connectButton.visible = false;
          addressBox.visible = true;
          addressBox.label.text = retrieveEthereumAddress(String(connectedEthereumAddress), 6, 8);
        } else {
          connectButton.visible = true;
          addressBox.visible = false;
          addressBox.label.text = "";
        }
      }

      function createDescriptionLabel() {
        const label = new Text("ETH ADDRESS", { fill: 0xffffff, fontSize: 30, fontFamily: FontFamily.Default });
        label.name = "descriptionLabel";
        return label;
      }

      function createConnectButton() {
        const buttonContainer = new Container();
        buttonContainer.name = "connectButtonContainer";

        const buttonSprite = Sprite.from("ui-railroader-dashboard/page-settings/link-btn.png");
        buttonSprite.name = "connectButtonSprite";
        buttonSprite.interactive = true;
        buttonSprite.buttonMode = true;
        buttonSprite.scale.set(0.7);
        buttonContainer.addChild(buttonSprite);

        const buttonLabel = new Text("CONNECT TO METAMASK", {
          fill: 0xffffff,
          fontSize: 18,
          fontFamily: FontFamily.Default,
        });
        buttonLabel.anchor.set(0.5);
        buttonLabel.position.set(buttonSprite.width * 0.57, buttonSprite.height * 0.5);
        buttonContainer.addChild(buttonLabel);

        buttonizeDisplayObject(buttonContainer, async () => {
          connectedEthereumAddress = await spinner.showDuring(dataService.updateMyEthereumAddress());
          updateButtonVisible();
        });

        return buttonContainer;
      }

      function createCurrentAddressBox() {
        const container = new Container();
        container.name = "addressBox";

        const background = Sprite.from("ui-railroader-dashboard/page-settings/field.png");
        background.width = 345;
        container.addChild(background);

        const label = new Text("", { fill: 0xffffff, fontFamily: FontFamily.Default });
        label.anchor.set(0.5, 0.5);
        label.position.set(
          background.position.x + background.width * 0.49,
          background.position.y + background.height * 0.39
        );
        container.addChild(label);

        tooltips.registerTarget(background, `Click to update your Ethereum address.`);
        buttonizeDisplayObject(background, async () => {
          connectedEthereumAddress = await spinner.showDuring(dataService.updateMyEthereumAddress());
          updateButtonVisible();
        });

        return Object.assign(container, { background, label });
      }

      const descriptionLabel = createDescriptionLabel();
      descriptionLabel.position.set(109, 554);
      this.controlsContainer.addChild(descriptionLabel);

      const connectButton = createConnectButton();
      connectButton.position.set(370, 543);
      this.controlsContainer.addChildAt(connectButton, 0);

      const addressBox = createCurrentAddressBox();
      addressBox.position.set(374, 543);
      this.controlsContainer.addChild(addressBox);

      updateButtonVisible();
    };

    addEthereumIntegration();

    //// Log Out Button

    {
      const label = "LOG OUT";
      const width = 480;
      const heightPadding = 15; //height padding is added to top and bottom of text height

      const padWidth = this.pad.width - this.controlsContainer.x - this.controlsContainer.x;
      const centerX = ~~(padWidth / 2);
      const logOutButton = new GreenButton(
        label,
        () => {
          const { sfx, input } = this.context;
          sfx.play("clickRegular");
          input.emit("logOut");
        },
        width,
        heightPadding,
        "red"
      );
      logOutButton.pivot.set(logOutButton.width / 2, logOutButton.height / 2);
      logOutButton.position.set(centerX, 770);

      this.controlsContainer.addChildAt(logOutButton, 0);
    }
  }

  createSlider(initialValue: number, onChange: (value: number) => void, width: number = 100, height: number = 30) {
    const { assets, sfx } = this.context;

    const knobSpriteTexture = assets.getTexture("ui-railroader-dashboard/page-settings/slider-gear.png");
    const knobSprite = new Sprite(knobSpriteTexture);

    const barOptions = {
      fill: 0x054053,
      x: 0,
      y: 0,
      width: width,
      height: 5,
    };

    const knobOptions = {
      knobSprite: knobSprite,
    };

    const sliderOptions = {
      barOptions: barOptions,
      knobOptions: knobOptions,
    };

    const slider = new Slider(sliderOptions);

    slider.setValue(initialValue);
    slider.on("change", onChange);
    slider.on("startDrag", () => sfx.play("clickTiny"));

    return slider;
  }
}

/**
 * https://www.dr-lex.be/info-stuff/volumecontrols.html
 *
 */
class VolumeCurver {
  public static curve(value: number) {
    // return Math.sin(value * Math.PI * 0.5);
    return value * value;
  }
  public static uncurve(value: number) {
    // return Math.asin(value) / Math.PI * 0.5;
    return Math.sqrt(value);
  }
}
