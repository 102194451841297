import { GameContext } from "@game/app/app";
import { GameSingletons } from "@game/app/GameSingletons";
import { PassengerTipsData } from "@sdk-integration/contracts/WaxContractsGateway";
import * as WAX from "@sdk-integration/contracts";

export const testTheModals = async () => {
  const context: GameContext = GameSingletons.getGameContext();

  const { modals } = context;

  const waxData: Parameters<typeof modals.railRunReport>[0] = {
    origin: 1099521667526 as any,
    destination: 1099521667526 as any,
    isReadyToClaim: true,
    secondsLeft: 0,
    trainName: "Loremipsum" as any,
    reward: 1234567.89,
    waxData: {
      depart_station: 1099521667452 as any,
      arrive_station: 1099521667526 as any,
      run_complete: 0 as any,
      run_start: 0 as any,
      train: "Loremipsum" as any,
    },
  };
  await modals.railRunReport(waxData);

  const tipsData: PassengerTipsData = {
    totalTipAmount: 999.5654654,
    totalTipPercentage: 50,
    balanceBeforeTips: 0,
    balanceAfterTips: 9999999,
    tips: [
      { passengerTemplateId: ("" + 324875) as WAX.AssetTemplateId, percentage: 5, amount: 500, criterion: "Time" },
      {
        passengerTemplateId: ("" + 324876) as WAX.AssetTemplateId,
        percentage: 25,
        amount: 2500,
        criterion: "Weight Hauled",
      },
      { passengerTemplateId: ("" + 324877) as WAX.AssetTemplateId, percentage: 75, amount: 7500, criterion: "Cretin" },
      {
        passengerTemplateId: ("" + 324878) as WAX.AssetTemplateId,
        percentage: 125,
        amount: 12500,
        criterion: "Cretin",
      },
      {
        passengerTemplateId: ("" + 326461) as WAX.AssetTemplateId,
        percentage: 125,
        amount: 12500,
        criterion: "Cretin",
      },
      { passengerTemplateId: ("" + 326462) as WAX.AssetTemplateId, percentage: 10, amount: 1000, criterion: "Cretin" },
      { passengerTemplateId: ("" + 326463) as WAX.AssetTemplateId, percentage: 50, amount: 5000, criterion: "Cretin" },
    ],
  };
  await modals.claimPassengerTips(tipsData as any);

  await modals.warning("You must select a conductor for a valid train composition.");

  {
    const choice = await modals.verifyOrClearTrain(`axalon`);

    console.log({ choice });
  }

  await modals.claimRailRunTocium(`axalon`, `Buckleberry Station`);

  const choice = await modals.confirm({
    title: `Welcome to the Modern Century,\nRailroader!`,
    content: `This is an example modal.`,
  });

  await modals.alert({
    title: `You made your choice!`,
    content: `It was: ${choice}`,
  });
};
